export const profileConstants = {
    GETALL_PROFILE_REQUEST: 'PROFILE_GETALL_REQUEST',
    GETALL_PROFILE_SUCCESS: 'PROFILE_GETALL_SUCCESS',
    GETALL_PROFILE_FAILURE: 'PROFILE_GETALL_FAILURE',

    GETALL_INSTANCE_REQUEST: 'INSTANCE_GETALL_REQUEST',
    GETALL_INSTANCE_SUCCESS: 'INSTANCE_GETALL_SUCCESS',
    GETALL_INSTANCE_FAILURE: 'INSTANCE_GETALL_FAILURE',

    GETALL_TRANSACTION_REQUEST: 'TRANSACTION_GETALL_REQUEST',
    GETALL_TRANSACTION_SUCCESS: 'TRANSACTION_GETALL_SUCCESS',
    GETALL_TRANSACTION_FAILURE: 'TRANSACTION_GETALL_FAILURE',

    UPDATE_REQUEST: 'ABOUT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ABOUT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ABOUT_UPDATE_FAILURE',

    CREATE_REQUEST: 'PROFILE_CREATE_REQUEST',
    CREATE_SUCCESS: 'PROFILE_CREATE_SUCCESS',
    CREATE_FAILURE: 'PROFILE_CREATE_FAILURE',
};
