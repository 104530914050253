// 
import { authHeader } from '../_helpers'
import axios from '../_helpers/interceptor'
import { getAccessLog } from 'helpers';

export const profileRetailService = {
    getMyAccount,
    getMyTransactionRetail,
    createData,
    changePass,
    updateData,
};

async function getMyAccount(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "CUSTOMER_FILTER": param?.CUSTOMER_FILTER,
                "CUSTOMER_NAME": param?.CUSTOMER_NAME,
                "CUSTOMER_NO": param?.CUSTOMER_NO,
                "ADDRESS_NAME": param?.ADDRESS_NAME,
                "USERNAME": param?.USERNAME,
                "CONF_LANGUAGE": param?.CONF_LANGUAGE,
                "IS_DUMP": false,
                "ACTIVE_STATUS": true
            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/customer/retail/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getMyTransactionRetail(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "CUSTOMER_FILTER": param?.CUSTOMER_FILTER,
                "USERNAME": param?.USERNAME,

                "PURCHASE_STATUS": param?.PURCHASE_STATUS,
                // "CUSTOMER_NAME": param?.CUSTOMER_NAME,
                "START_DATE": param?.START_DATE,
                "END_DATE": param?.END_DATE,
                "CREATED_BY": param?.CREATED_BY,

                "IS_DUMP": false,
                "ACTIVE_STATUS": true

            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/shipping/retail/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function createData(param) {
    const bodyOptions = {
        "CUSTOMER_NAME": param?.CUSTOMER_NAME,
        "USERNAME_ID": param?.USERNAME_ID,
        "CONF_LANGUAGE": param?.CONF_LANGUAGE,

        "CP": [
            {
                "REFERENCE_OF": "SHIPPING",
                "CP_MODE": "PHONE",
                "CP_CONTACT": param?.PHONE,
                "IS_CURRENT": true
            },
            {
                "REFERENCE_OF": "SHIPPING",
                "CP_MODE": "EMAIL",
                "CP_CONTACT": param?.EMAIL,
                "IS_CURRENT": true
            }
        ],

        "ADDRESS": param?.ADDRESS,
        "ACTION_BY": param?.ACTION_BY,
        "ACTION_DATE": param?.ACTION_DATE,
        "ADDITIONAL_INFO": param?.ADDITIONAL_INFO,
        "IS_DUMP": false,
        "ACTIVE_STATUS": true
    }
    const lang = param?.lang ?? "en";
    const requestUrl = `/clarity-be/customer/retail/create?lang=${lang}`
    const checkHeader = await authHeader(requestUrl, bodyOptions, "not mandatory")
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        axios.post(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response save area adalah : ', response);
                const paramList = {
                    CUSTOMER_FILTER: "IS NULL",
                    // CUSTOMER_NAME: "Maudy A",
                    // CUSTOMER_NO: "00000009",
                    USERNAME: getAccessLog('username'),
                    page: 1,
                    limit: 100
                }
                getMyAccount(paramList)
                // if (response.data.response_code === 'S1') {
                return {
                    data: response.data?.DATA,
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
            console.log("🚀 ~ file: profile.service.js ~ line 320 ~ createData ~ error",  error)
                if(error?.status === 400 && error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE?.includes('FAILED Create new Customer. ERROR: duplicate key')) {
                    console.log('ai aku disini')
                    return updateData(param)
                }
                // Error 😨
                console.log(error?.response);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };

            });
    return response;
}

async function changePass(param) {
    console.log("🚀 ~ file: user.service.js ~ line 975 ~ changePassword ~ param", param)
    const email = getAccessLog("email")
    console.log("🚀 ~ file: user.service.js ~ line 977 ~ changePassword ~ email", email)
  
    const nacl = require("tweetnacl")
    const naclUtil = require('tweetnacl-util');
  
    const decodeUTF8OldPass = naclUtil.decodeUTF8(param?.password)
    const decodeUTF8NewPass = naclUtil?.decodeUTF8(param?.newPassword)
  
    const hashOldPass = nacl.hash(decodeUTF8OldPass);
    const hashNewPass = nacl?.hash(decodeUTF8NewPass);
  
    const endcodedBase64OldPass = naclUtil.encodeBase64(hashOldPass);
    const endcodedBase64NewPass = naclUtil.encodeBase64(hashNewPass);
    // const bodyOptions = {
    //     "name": param.name,
    //     "user_id": getAccessLog("user_id"),
    //     "username": getAccessLog("username"),
    //     "old_password": endcodedBase64OldPass,
    //     "password": endcodedBase64NewPass,
    //     "request_code": "Update"
    // };
  
    // const email = getAccessLog("email")
    const bodyOptions = {
      password: endcodedBase64NewPass,
    };
    const requestUrl = `/usr/new-password`;
    const checkHeader = await authHeader(requestUrl, bodyOptions);
    const requestOptions = {
      // headers: checkHeader,
      auth: {
        username: email,
        password: endcodedBase64OldPass
      }
    };
  
    const response = await axios
      .post(`${requestUrl}`, bodyOptions, requestOptions)
      .then((response) => {
        console.log("Response save data user adalah : ", response);
        return {
          // data: response.data.data,
          status_code: response.status,
          message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
        };
      })
      .catch((error) => {
        // Error 😨
        console.log(error.response);
        if (error?.data) {
          return {
            // data: !response.data ? [] : response.data.data,
            status_code: error?.status,
            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
          };
        } else
        if (error?.messageError === 'Session Expired') {
          return {
            // data: !response.data ? [] : response.data.data,
            status_code: 401,
            message: "Session Expired Please Re-Login",
          };
        } else if (error.response) {
          return {
            // data: !response.data ? [] : response.data.data,
            status_code: error.response.data.response_code,
            message: error.response.data.message,
          };
        } else if (!error.response) {
          return {
            // data: !response.data ? [] : response.data.data,
            status_code: error?.data?.response_code ?? error.status,
            message:
              error?.data?.message ??
              "There is a problem with your internal connection. Please try again later or call your provider",
          };
        } else if (error?.response?.status === 401) {
          return {
            // data: !response.data ? [] : response.data.data,
            status_code: error?.response?.status,
            message: "Unauthorized login!",
          };
        } else {
          return {
            // data: !response.data ? [] : response.data.data,
            status_code: error.response.response_code,
            message: error.response.message,
          };
        }
      });
    return response;
}
async function updateData(param) {
    const bodyOptions = {
        "CUSTOMER_NO": param?.CUSTOMER_NO,
        "CUSTOMER_NAME": param?.CUSTOMER_NAME,
        "USERNAME_ID": param?.USERNAME_ID,
        "CONF_LANGUAGE": param?.CONF_LANGUAGE,
        "CP": [
            {
                "REFERENCE_OF": "SHIPPING",
                "CP_MODE": "PHONE",
                "CP_CONTACT": param?.PHONE,
                "IS_CURRENT": true
            },
            {
                "REFERENCE_OF": "SHIPPING",
                "CP_MODE": "EMAIL",
                "CP_CONTACT": param?.EMAIL,
                "IS_CURRENT": true
            }
        ],
        "ADDRESS": param?.ADDRESS,

        "ACTION_BY": param?.ACTION_BY,
        "ACTION_DATE": param?.ACTION_DATE,
        "ADDITIONAL_INFO": param?.ADDITIONAL_INFO,
        "IS_DUMP": false,
        "ACTIVE_STATUS": true
    }
    const lang = param?.lang ?? "en";
    const requestUrl = `/clarity-be/customer/retail/update`
    const checkHeader = await authHeader(requestUrl, bodyOptions, "not mandatory")
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        axios.put(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response save area adalah : ', response);
                // if (response.data.response_code === 'S1') {
                const paramList = {
                    CUSTOMER_FILTER: "IS NULL",
                    // CUSTOMER_NAME: "Maudy A",
                    // CUSTOMER_NO: "00000009",
                    USERNAME: getAccessLog('username'),
                    page: 1,
                    limit: 100
                }
                getMyAccount(paramList)
                return {
                    data: response.data?.DATA,
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
            console.log("🚀 ~ file: profile.service.js ~ line 485 ~ updateData ~ error", error)
                // Error 😨
                console.log(error?.response);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };

            });
    return response;
}