import React, { useState, useCallback, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import Alert from '@mui/lab/Alert';
import iconUpload from '../../assets/images/upload.png'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

}))
export default function MyDropzone(props) {
  const classes = useStyles()
  const {
    id,
    onChange,
    label,
    documentFiles,
    onlyImage,
    noPreview
  } = props

  const config = onlyImage
    ? {
      accept: 'image/jpeg, image/png'
    }
    : {
      maxFiles: 1
    }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone(config);

  console.log("🚀 ~ file: index.jsx ~ line 26 ~ MyDropzone ~ props", props)

  const [files, setFiles] = useState([])
  console.log("🚀 ~ file: index.jsx ~ line 28 ~ MyDropzone ~ files", files)
  const [imgData, setImgData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFiles(documentFiles)
    console.log("MyDropzone -> documentFiles", documentFiles)
    const reader = new FileReader();
    if (documentFiles[0]) {
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(documentFiles[0]);
    }
    return () => {
      if (documentFiles[0]) {
        reader.removeEventListener("load", () => {
          setImgData(reader.result);
        });
      }
    }
  }, [documentFiles])


  const onDrop = useCallback(acceptedFiles => {
    console.log("🚀 ~ file: index.jsx ~ line 53 ~ MyDropzone ~ acceptedFiles", acceptedFiles)
    setError(null)
    if (onlyImage) {
      if (acceptedFiles[0]) {
        if (acceptedFiles[0].type === "image/png" || acceptedFiles[0].type === "image/jpg" || acceptedFiles[0].type === "image/jpeg") {
          console.log("🚀 ~ file: index.jsx ~ line 57 ~ MyDropzone ~ acceptedFiles[0].type ", acceptedFiles[0].type)
          onChange(id, acceptedFiles)
          setFiles(acceptedFiles)

          const reader = new FileReader();
          reader.addEventListener("load", () => {
            setImgData(reader.result);
          });
          reader.readAsDataURL(acceptedFiles[0]);
        } else {
          setError('Not to be allowed to upload file with this type')
          setFiles([])
        }
      }
    } else {
      onChange(id, acceptedFiles)
      setFiles(acceptedFiles)
      if (acceptedFiles[0]) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(acceptedFiles[0]);
      }
    }

  }, [id, onChange, onlyImage])

  const uploadfiles = files?.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const typeValid = files?.find(file => file.type)
  const typeData = !typeValid ? [] : typeValid.type;

  const ValidType = (type) => {
    switch (type) {
      case "image/png":
        return true
      case "image/jpg":
        return true
      case "image/jpeg":
        return true
      default:
        return false
    }
  }
  const maxSize = 1048576 * 10;
  // console.log(!typeValid ? [] : typeValid.type)
  // const { classes } = this.props
  const PreviewImage = () => {
    return (
      <React.Fragment>
        {error ?
          <div
            style={{
              width: '100%',
              maxHeight: '100%'
            }}
          >
            <Alert severity="warning">{error}</Alert>
          </div>
          : noPreview
            ? <div
              style={{
                width: '100%',
                maxHeight: '100%'
              }}
            >
              <Alert severity="success">Document is ready</Alert>
            </div>
            : <div
              style={{
                width: '100%',
                maxHeight: '100%'
              }}
            >
              <img
                src={imgData}
                alt="prodia"
                style={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '400px'
                }}
              />
            </div>}
      </React.Fragment>
    )
  }
  return (
    <Dropzone
      onDrop={onDrop}
      minSize={0}
      maxSize={maxSize}
    >
      {({ getRootProps, getInputProps, fileRejections, acceptedFiles }) => {
        const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].file.size > maxSize;
        const isAccept = acceptedFiles.length > 0;
        return (
          <Grid
            container
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Typography color="primary"><b>{label}</b></Typography>
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              {/* <aside style={{ paddingLeft: 20 }}> */}
              <br />
              <ul >{uploadfiles}</ul>
              {/* </aside> */}
            </Grid>
            <Grid
              className={classes.root}
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <div {...getRootProps({ className: 'dropzone' })} style={{
                // minHeight: `100%`,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                textAlign: "center",
                border: `3px solid ${isFileTooLarge ? "#FF0000" : isAccept ? "transparent" : "transparent"}`
              }}>
                <input {...getInputProps()} />
                {isFileTooLarge
                  ? <div
                    style={{
                      width: '100%',
                      maxHeight: '100%'
                    }}
                  >
                    <Alert severity="error">File is too large. Maximum size: {maxSize} byte</Alert>
                  </div>
                  : (isAccept && ValidType(typeData) === true && onlyImage)
                    ? <PreviewImage />
                    : (isAccept && ValidType(typeData) === false && !onlyImage)
                      ? <div
                        style={{
                          width: '100%',
                          maxHeight: '100%'
                        }}
                      >
                        <Alert severity="success">Document is ready</Alert>
                      </div>
                      : (documentFiles.length > 0 && ValidType(typeData) === true)
                        ? <PreviewImage />
                        : <div
                          style={{
                            width: '100%',
                            maxHeight: '100%'
                          }}
                        >
                          {error
                            ? <Alert className={classes.alert} severity="error">{error}</Alert>
                            : null
                          }
                          <img
                            src={iconUpload}
                            alt="hihihi"
                            style={{
                              marginTop: '5px',
                              height: '100px',
                              width: 'auto'
                            }}
                          />
                          <Typography>
                            <b>Upload your document</b>
                            <br />
                            Drag & drop or browse your file here.
                          </Typography>

                        </div>
                }
              </div>
            </Grid>
          </Grid>
        )
      }
      }
    </Dropzone >
  );
}


MyDropzone.propTypes = {
  className: PropTypes.string,
  bottomLabel: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  documentFiles: PropTypes.array.isRequired
};

MyDropzone.defaultProps = {
  bottomLabel: 'File',
  label: 'Document Upload',
  documentFiles: [],
  onChange: () => { }
};
