import React, { useState, useEffect, useCallback } from 'react';
import {CurrencyField, InputComponent} from 'components'
import DefaultStructure from './Schema/default'
import { Typography, Grid, Button, Box , FormLabel} from '@mui/material';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useLanguage } from 'hooks';
import { rupiahFormat } from 'helpers'

function General(props) {
  const [language, lagDic, setLang] = useLanguage()
  const selected = useSelector(state => state.selected)
  const initValue = {
    CUSTOMER_NAME: selected?.CUSTOMER_NAME ?? '',
    ADDRESS: selected?.SHIPPING?.[0]?.ADDRESS ?? '',
    SHIPPING_NO: selected?.SHIPPING?.[0]?.SHIPPING_NO ?? '',
    SHIPPING_NEGO:  selected?.SHIPPING?.[0]?.SHIPPING_NEGO ?? '',
    ADDRESS_KABUPATEN: selected?.SHIPPING?.[0]?.ADDRESS_KABUPATEN ?? '',
    ADDRESS_PROVINSI: selected?.SHIPPING?.[0]?.ADDRESS_PROVINSI ?? '',
    ZIPCODE: selected?.SHIPPING?.[0]?.ZIPCODE ?? '',
    PHONE: selected?.SHIPPING?.[0]?.PHONE ?? '',
    SHIPPING_ITEM: selected?.SHIPPING?.[0]?.SHIPPING_ITEM ?? []

  }
  const [values, setValues] = useState(initValue ?? {})
  

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F1F5F8",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#F1F5F8',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  return (
      <div>
          <Grid container spacing={2} sx={{px:1, display: 'flex', justifyContent: 'space-between'}}>
            <Grid>
              <Typography variant='h4' sx={{mb: 3}}>{language.COMMON.CHECK_OUT.YOUR_SHIPPING_ADDRESS}</Typography>
              <Typography variant="h6" color="initial"><strong>{values?.CUSTOMER_NAME}</strong></Typography>
              <Typography variant='h6' >{values?.PHONE}</Typography>
              <Typography variant="h6" color="initial">
                {`${values?.ADDRESS}, ${values?.ADDRESS_KABUPATEN}, ${values?.ADDRESS_PROVINSI} ${values?.ZIPCODE}`}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant='h4' sx={{mb: 3}}>{language.COMMON.MY_TRANSACTION_DETAIL.SHIPPING_COST}</Typography>
              {/* <Typography variant="h6" color="initial"><strong>Gaido Express</strong></Typography> */}
              <Typography>{rupiahFormat(`${values?.SHIPPING_NEGO}`, 'Rp')}</Typography>
</Grid>
          </Grid>
      </div>
  );
}

export default General;
