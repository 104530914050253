import palette from '../palette';

const MuiInputBase = {
  root: {
    color: palette.text.primary,
    margin: 'dense','&:disabled': {
      // color: palette.common.muted,
      background: '#f2f5fa',
    },
  }
};

export default MuiInputBase
