import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ourProjectActions } from '../../controllers/_actions'

export default function useOurProject(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const ourProject = useSelector(state => state.ourProject);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(ourProjectActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'detail') {
      dispatch(ourProjectActions.getDetail(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);


  useEffect(() => {
    const DataLoaded = ourProject?.items?.data || []
    setData(DataLoaded)
  }, [ourProject])

  return [data, initFetch, crudAction]
}
