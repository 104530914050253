import { getAccessLog } from 'helpers';
import { userConstants } from '../_constants';
import { userService } from '../_services';
// import { alertActions } from './';

export const userActions = {
    login,
    logout,
    getAll,
    createData,
    updateData,
    newPassword,
    externalMiddlewareRefreshToken,
    refreshToken,
    resetData,
    confirmData,
    deactivateData,
};

function login(values, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, history) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(values));
        const res = await userService.login(values)
        console.log("🚀 ~ file: user.actions.js ~ line 25 ~ login ~ res", res)

        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
            // handleChangeParam('title', 'Login Success')
            // handleChangeParam('message', 'Redirecting to dashboard')
            // handleChangeParam('dialog_id', 'success')
            // handleChangeParam('oneAction', true)
            // handleChangeParam('handleError', null)
            // setIsOpen()
            history('/furnitur')
        } else if (res.status_code === "E400") {
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Info")
            setTitle("Information")
            setMessage(`${res.message}`)
            setOpen(true)
            // setIsOpen(true)
        } else if (res.status_code === 'S0') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Info")
            setTitle("Information")
            setMessage(`${res.message}`)
            setOpen(true)
            // setIsOpen(true)
        } else if (res.status_code === 'E0') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            // setIsOpen(true)
        } else if (res.status_code === 202) {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 'E1') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res?.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res?.message === "no more documents" ? `${"Akun anda tidak terdaftar, daftarkan terlebih dahulu akun anda"}`: res?.message)
            setOpen(true)
            // setIsOpen(true)
            localStorage.setItem('isAuthenticated', false);
        } else if (res.status_code === 'E3') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            localStorage.setItem('isAuthenticated', false);
        } else if (res.status_code === 'E4') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            localStorage.setItem('isAuthenticated', false);
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            localStorage.setItem('isAuthenticated', false);
        }

    };

    function request(users) { return { type: userConstants.LOGIN_REQUEST, users } }
    function success(users) { return { type: userConstants.LOGIN_SUCCESS, users } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

async function logout() {
    await userService.logout();
    return { type: userConstants.LOGOUT };
}

function newPassword(values, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, history) {
    return async dispatch => {
        dispatch(request(values));
        const res = await userService.newPassword(values)
        console.log(res)

        if ((res.status_code === "S1" && res.data?.status === 'Active') || res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(`Success to reset password, please relogin.`)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
            history('/furnitur')

        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            history('/furnitur')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            localStorage.setItem('isAuthenticated', false);
        }

    };

    function request(users) { return { type: userConstants.LOGIN_REQUEST, users } }
    function success(users) { return { type: userConstants.LOGIN_SUCCESS, users } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await userService.getAll(param)
        console.log(res)
        if (res.status_code === 'S1') {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: userConstants.GETALL_REQUEST, } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function createData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, history) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await userService.createData(param)
        console.log("🚀 ~ file: user.actions.js ~ line 25 ~ login ~ res", res)

        if (res.status_code === 200) {
            // dispatch(success(res));
            setIsLoading(false)
            setError(null)
            setDialogType("success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
            // handleChangeParam('title', 'Login Success')
            // handleChangeParam('message', 'Redirecting to dashboard')
            // handleChangeParam('dialog_id', 'success')
            // handleChangeParam('oneAction', true)
            // handleChangeParam('handleError', null)
            // setIsOpen()
            history('/furnitur')
        } else if (res.status_code === 400) {
            console.log("Status =", res.status_code)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("info")
            setTitle("Information")
            setMessage(`${res.message}`)
            setOpen(true)
            // setIsOpen(true)
        }
        // else if (res.status_code === 'S0') {
        //     dispatch(failure(res));
        //     setIsLoading(false)
        //     setError(`${res.message}`)
        //     setDialogType("Info")
        //     setTitle("Information")
        //     setMessage(`${res.message}`)
        //     setOpen(true)
        //     // setIsOpen(true)
        // } else if (res.status_code === 'E0') {
        //     dispatch(failure(res));
        //     setIsLoading(false)
        //     setError(`${res.message}`)
        //     setDialogType("Error")
        //     setTitle("Failed")
        //     setMessage(`${res.message}`)
        //     setOpen(true)
        //     // setIsOpen(true)
        // } else if (res.status_code === 'E2') {
        //     dispatch(failure(res));
        //     setIsLoading(false)
        //     setError(`${res.message}`)
        //     setDialogType("NewUser")
        //     setTitle("Setup Credential")
        //     setMessage(`${res.message}`)
        //     setOpen(true)
        // } else if (res.status_code === 'E1') {
        //     dispatch(failure(res));
        //     setIsLoading(false)
        //     setError(`${res.message}`)
        //     setDialogType("Error")
        //     setTitle("Failed")
        //     setMessage(`${res.message}`)
        //     setOpen(true)
        //     // setIsOpen(true)
        //     localStorage.setItem('isAuthenticated', false);
        // } else if (res.status_code === 'E3') {
        //     dispatch(failure(res));
        //     setIsLoading(false)
        //     setError(`${res.message}`)
        //     setDialogType("Error")
        //     setTitle("Failed")
        //     setMessage(`${res.message}`)
        //     setOpen(true)
        //     localStorage.setItem('isAuthenticated', false);
        // } else if (res.status_code === 'E4') {
        //     dispatch(failure(res));
        //     setIsLoading(false)
        //     setError(`${res.message}`)
        //     setDialogType("Error")
        //     setTitle("Failed")
        //     setMessage(`${res.message}`)
        //     setOpen(true)
        //     localStorage.setItem('isAuthenticated', false);
        // } else if (res.status_code === 500) {
        //     dispatch(failure(res));
        //     setIsLoading(false)
        //     setError(`${res.message}`)
        //     setDialogType("Error")
        //     setTitle("Failed")
        //     setMessage(`${res.message}`)
        //     setOpen(true)
        // } else {
        //     dispatch(failure(res));
        //     setIsLoading(false)
        //     setError(`${res.message}`)
        //     setDialogType("Error")
        //     setTitle("Failed")
        //     setMessage(`${res.message}`)
        //     setOpen(true)
        //     localStorage.setItem('isAuthenticated', false);
        // }

    };

    function request(users) { return { type: userConstants.LOGIN_REQUEST, users } }
    function success(users) { return { type: userConstants.LOGIN_SUCCESS, users } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function resetData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await userService.resetData(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`Email confirmation will be sent to your email`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: userConstants.UPDATE_REQUEST, } }
    function success(users) { return { type: userConstants.UPDATE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function deactivateData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await userService.deactivateData(param)
        console.log(res)
        if (res.status_code === 'S1') {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`User has been deactivated.`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: userConstants.UPDATE_REQUEST, } }
    function success(users) { return { type: userConstants.UPDATE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function confirmData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await userService.confirmData(param)
        console.log(res)
        if (res.status_code === 'S1') {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: userConstants.UPDATE_REQUEST, } }
    function success(users) { return { type: userConstants.UPDATE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function updateData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await userService.updateData(param)
        console.log(res)
        if (res.status_code === 'S1') {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        }
    };

    function request() { return { type: userConstants.UPDATE_REQUEST, } }
    function success(users) { return { type: userConstants.UPDATE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function refreshToken(refreshOldToken) {
    return async dispatch => {
        dispatch(request({}));
        const res = await userService.refreshToken(refreshOldToken)
        if (res.status_code === 'S1') {
            dispatch(success(res));
            // setTitle("Sukses")
            // setMessage("Registrasi anda sukses. Silahkan melakukan verifikasi email anda.")
            // setDialogType("Success")
            // setOpen(true)
            // setError(null)
            // setIsLoading(false)
            // console.log('Register successfully', history);
            // if (history) {
            //     history.push('/verify')
            // }
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            // setTitle("Gagal")
            // setMessage(res.message)
            // setDialogType("Error")
            // setOpen(true)
            // setError(null)
            // setIsLoading(false)
            sessionStorage.setItem('isAuthenticated', false);
        } else {
            dispatch(failure(res));
            // setTitle("Gagal")
            // setMessage(res.message)
            // setDialogType("Error")
            // setOpen(true)
            // setError(null)
            // setIsLoading(false)
            sessionStorage.setItem('isAuthenticated', false);
        }

    };

    function request() { return { type: userConstants.TOKEN_REQUEST, } }
    function success(token) { return { type: userConstants.TOKEN_SUCCESS, token } }
    function failure(error) { return { type: userConstants.TOKEN_FAILURE, error } }
}

function externalMiddlewareRefreshToken(res) {
    console.log("🚀 ~ file: user.actions.js ~ line 561 ~ externalMiddlewareRefreshToken ~ res", res)
    return async dispatch => {
        dispatch(request({}));
        const resp = await dispatch(success(res));
        return {
            response_code: 'S1',
            message: 'Refresh token succeeded',
            data: resp?.token
        }
    };

    function request() { return { type: userConstants.TOKEN_REQUEST, } }
    function success(token) { return { type: userConstants.TOKEN_SUCCESS, token } }
    // function failure(error) { return { type: userConstants.TOKEN_FAILURE, error } }
}

