import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiFab from './MuiFab';
import MuiFilledInput from './MuiFilledInput';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
import palette from '../palette'

const override = {
  MuiButton,
  MuiChip,
  MuiFab,
  MuiFilledInput,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: palette.secondary.main,
    },
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      // backgroundColor: lightBlue.A200,
      // color: "white",
    },
  },
  MuiPickersDay: {
    day: {
      color: palette.secondary.main,
    },
    daySelected: {
      backgroundColor: palette.secondary.main,
    },
    dayDisabled: {
      color: palette.secondary.light,
    },
    current: {
      color: palette.secondary.main,
    },
  },
  MuiPickersModal: {
    dialogAction: {
      color: palette.secondary.main,
    },
  },
};

export default override
