import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { productActions } from '../../controllers/_actions'

export default function useProduct(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const product = useSelector(state => state.product);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(productActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'getDetail') {
      dispatch(productActions.getDetail(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'getPopularProduct') {
      dispatch(productActions.getPopularProduct(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'getBanner') {
      dispatch(productActions.getBanner(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'getSale') {
      dispatch(productActions.getSale(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'getSize') {
      dispatch(productActions.getSize(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'getLimit') {
      dispatch(productActions.getLimit(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);


  useEffect(() => {
    const DataLoaded = product?.items?.data || []
    setData(DataLoaded)
  }, [product])

  return [data, initFetch, crudAction]
}
