import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { profileRetailActions } from '../../controllers/_actions'

export default function useProfileRetail(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const profileRetail = useSelector(state => state.profileRetail);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(profileRetailActions.getMyAccount(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'create') {
      dispatch(profileRetailActions.createData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'myTransactionRetail') {
      dispatch(profileRetailActions.getMyTransactionRetail(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'changePass') {
      dispatch(profileRetailActions.changePass(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } if (type === 'update') {
      dispatch(profileRetailActions.updateData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);


  useEffect(() => {
    const DataLoaded = profileRetail?.items?.data || []
    setData(DataLoaded)
  }, [profileRetail])

  return [data, initFetch, crudAction]
}
