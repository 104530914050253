import { profileRetailConstants } from '../_constants';
import { profileRetailService } from '../_services';

export const profileRetailActions = {
    getMyAccount,
    getMyTransactionRetail,
    createData,
    changePass,
    updateData,
};

function getMyAccount(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await profileRetailService.getMyAccount(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: profileRetailConstants.GETALL_PROFILE_RETAIL_REQUEST, } }
    function success(profileRetail) { return { type: profileRetailConstants.GETALL_PROFILE_RETAIL_SUCCESS, profileRetail } }
    function failure(error) { return { type: profileRetailConstants.GETALL_PROFILE_RETAIL_FAILURE, error } }
}

function changePass(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await profileRetailService.changePass(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        }
    };

    function request() { return { type: profileRetailConstants.UPDATE_REQUEST, } }
    function success(profileRetail) { return { type: profileRetailConstants.UPDATE_SUCCESS, profileRetail } }
    function failure(error) { return { type: profileRetailConstants.UPDATE_FAILURE, error } }
}

function getMyTransactionRetail(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await profileRetailService.getMyTransactionRetail(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: profileRetailConstants.GETALL_TRANSACTION_RETAIL_REQUEST, } }
    function success(profileRetail) { return { type: profileRetailConstants.GETALL_TRANSACTION_RETAIL_SUCCESS, profileRetail } }
    function failure(error) { return { type: profileRetailConstants.GETALL_TRANSACTION_RETAIL_FAILURE, error } }
}

function createData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await profileRetailService.createData(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`Update data success`)
            setDialogType("Success")
            setTitle("Completed")
            setMessage(`Update data success`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: profileRetailConstants.CREATE_REQUEST, } }
    function success(profileRetail) { return { type: profileRetailConstants.CREATE_SUCCESS, profileRetail } }
    function failure(error) { return { type: profileRetailConstants.CREATE_FAILURE, error } }
}

function updateData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await profileRetailService.updateData(param)
        console.log(res)
        if (res?.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`Update Data Success`)
            setDialogType("Success")
            setTitle("Completed")
            setMessage(`Update Data Success`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: profileRetailConstants.UPDATE_REQUEST, } }
    function success(profileRetail) { return { type: profileRetailConstants.UPDATE_SUCCESS, profileRetail } }
    function failure(error) { return { type: profileRetailConstants.UPDATE_FAILURE, error } }
}