import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { termsActions } from '../../controllers/_actions'

export default function useTerms(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const terms = useSelector(state => state.terms);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(termsActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  // const another = useCallback((param, type) => {
  //   if (type === 'detail') {
  //     dispatch(ourProjectActions.getDetail(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  //   }
  // }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);


  useEffect(() => {
    const DataLoaded = terms?.items?.data || []
    setData(DataLoaded)
  }, [terms])

  return [data, initFetch]
}
