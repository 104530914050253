import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Button, FormControl, Box} from '@mui/material';
import {
  CustomBackdrop,
  HandlerModal,
} from 'components'
import { getAccessLog, getDayNow, getFormattedDay } from 'helpers'
import getDayPicker from 'helpers/getDayPickerNew';
import {
  useInput, useLkppOrder, useNegotiation, useRetailOrder,
} from 'hooks'
import { validateSign } from 'controllers/_validate'
import GeneralInformation from './components/General'
import ShippingInformation from './components/Shipping'
import PaymentInformation from './components/PaymentDetail'
import ProductInformation from './components/ProductDetail'
import ShippingAddress from './components/ShippingAddress'
import { useCategory, useLookup } from 'hooks';
import { CustomModal } from 'components';
import AddComponent from './components/ModalApprove';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    // paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(10)
    // alignContent: 'flex-end'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(3),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
        width: '100%'
    }
  },
  header: {
    backgroundColor: '#fff'
  },
  container: {
    maxHeight: '100%',
  },
  inputLeft: {
    paddingRight: theme.spacing(1.5)
  },
  inputRight: {
    paddingLeft: theme.spacing(1.5)
  },
  buttonCenter: {
    marginTop: theme.spacing(5)
  },
  trailCenter: {
    marginTop: theme.spacing(3)
  }
}));

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const {
    changeStep,
    // step,
    // selected,
    // loading
  } = props
  const selected = useSelector(state => state.selected)
  const selectedDetail = useSelector(state => state.selectedDetail)
  const readStatus = (stat) => {
    if (stat === '1:Admin Initiate') {
      return 'Waiting Admin add Delivery Time'
    } else if (stat === '2:Customer Approval') {
      return 'Waiting for Customer Approval'
    } else if (stat === '3:Admin Approval') {
      return 'Waiting for Admin Approval'
    } else if (stat === '4:Deal') {
      return 'Deal'
    } else if (stat === '5:Cancelled') {
      return 'Cancelled'
    }  else {
      return 'Null'
    }
  }
  const initValue = {
    INVOICE_NO:  selected?.INVOICE_NO,
    CUSTOMER_NAME: selected?.CUSTOMER_NAME,
    PURCHASE_DATE: selected?.PURCHASE_DATE,
    PRICE_FULL: selected?.PRICE_FULL,
    PURCHASE_STATUS: readStatus(selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS ?? 'null'),
    PAYMENT_DATE: selected?.PAYMENT_DATE,
    PHONE_NO: selected?.SHIPPING?.[0]?.PHONE ?? '',
    ADDRESS: selected?.SHIPPING?.[0]?.ADDRESS ?? '',
    SHIPPING_COURIER: selected?.SHIPPING?.[0]?.SHIPPING_COURIER ?? '',
    SHIPPING_ETD: selected?.SHIPPING?.[0]?.SHIPPING_ETD ?? '',
    COMPANY_NAME: selected?.COMPANY_NAME,
    SHIPPING_NO: selected?.SHIPPING?.[0]?.SHIPPING_NO ?? '',
    SHIPPING_PRICE:  selected?.SHIPPING?.[0]?.SHIPPING_PRICE ?? '',
    ADDRESS_KABUPATEN: selected?.SHIPPING?.[0]?.ADDRESS_KABUPATEN ?? '',
    ADDRESS_PROVINSI: selected?.SHIPPING?.[0]?.ADDRESS_PROVINSI ?? '',
    LATEST_NEGOTIATION:  selected?.LATEST_NEGOTIATION,
    // PRICE_ITEMS: Number(selected?.PRICE_ITEMS),
    PRICE_ITEMS: selectedDetail?.NEW_PRICE,
    // PRICE_SHIPPING: Number(selected?.PRICE_SHIPPING),
    PRICE_SHIPPING: selectedDetail?.NEW_SHIPPING,
    PRICE_FULL: selectedDetail?.NEW_PRICE + selectedDetail?.NEW_SHIPPING,
    BERGAIN_SHIPPING: 0
  }
  
  // const [shippValue, setShipValue] = useState(initValue)
  // console.log("🚀 ~ file: index.jsx ~ line 88 ~ StickyHeadTable ~ shippValue", shippValue)
  const [handleFieldChange, values] = useInput(validateSign, initValue ?? {})
  // console.log("🚀 ~ file: index.jsx ~ line 90 ~ StickyHeadTable ~ values", values)
  const [imageUrl, setImageUrl] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("Error");
  const username = getAccessLog('username')
  const [data, setData] = useLookup(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage);
  const [negotiation, setNegotiation, makeNegotiation] = useNegotiation(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage);
  const [datas, setDatas, makeDatas] = useLkppOrder(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage);
  const [isSubmit, setIsSubmit] = useState(false)
  const [isBergain, setIsBergain] = useState(false)
  const [emptyImage, setEmptyImage] = useState({image1: false,
    image2: false,
    image3: false,
    image4: false,
    image5: false,})
  const [label, setLabel] = useState({
    positive: 'Close',
    negative: 'Close',
    })
  const [purchaseItem, setPurchaseItem] = useState(selected?.PURCHASE_ITEM??[])
  const [deliveryDate, setDeliveryDate] = useState([])
  const [shippingBergain, setShippingBergain] = useState([])
  const [productBergain, setProductBergain] = useState([])
  const [bergainPrice, setBergainPrice] = useState([])
  const [shippingDeal, setShippingDeal] = useState([])
  const [productDeal, setProductDeal] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [list, setList] = useState({})

  return (
    
    <Grid
    sx={{
      m: 2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px'
    }}
    container
    className={classes.root}
    >
    <CustomModal open={openModal} body={<AddComponent close={setOpenModal} setList={setList} list={list}/>}></CustomModal>
     
      <CustomBackdrop loading={isLoading} label={'Collecting Resources'} />
      
      <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>
        
        <GeneralInformation
         values={values}
         handleFieldChange={handleFieldChange}
         isLoading={isLoading}
         error={error}
         IMG_MAIN={selected?.IMG_MAIN}
         IMG_PATH={selected?.IMG_PATH}
         setImageUrl={setImageUrl}
        ></GeneralInformation>
        </Paper>
      </Grid>
      {/* <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>
        
        <ShippingInformation
         values={values}
         handleFieldChange={handleFieldChange}
         isLoading={isLoading}
         error={error}
       
        ></ShippingInformation>
        </Paper>
      </Grid> */}
      <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>
        
        <ProductInformation
         values={values ?? {}}
         handleFieldChange={handleFieldChange}
         isLoading={isLoading}
         error={error}
         data={purchaseItem}
         setPurchaseItem={setPurchaseItem}
         PURCHASE_STATUS={selectedDetail?.LIST_COMPONENT?.filter((val) => val.COMPONENT_TYPE === "PRODUCT")}
         isBergain={isBergain}
        ></ProductInformation>
        </Paper>
      </Grid>
      <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>
        
        <ShippingAddress
         values={selectedDetail?.LIST_COMPONENT?.filter((val) => val.COMPONENT_TYPE === "SHIPPING")?? {}}
         handleFieldChange={handleFieldChange}
         isLoading={isLoading}
         error={error}
         setBergainPrice={setBergainPrice}
         isBergain={isBergain}
        ></ShippingAddress>
        </Paper>
      </Grid>
      <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>
        
        <PaymentInformation
         values={values}
         handleFieldChange={handleFieldChange}
         isLoading={isLoading}
         error={error}
         data={values}
         negotiation={negotiation?? []}
         historyNego={data?.historyNego?.data ?? []}
        ></PaymentInformation>
        </Paper>
      </Grid>
    
    </Grid >
  );
}