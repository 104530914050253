// Colors
import { primary } from '../../colors';
import palette from '../palette';

const MuiFilledInput = {
  root: {
    backgroundColor: palette.background.default,
    '&:hover': {
      backgroundColor: primary.light
    },
    '&$focused': {
      backgroundColor: primary.light
    },
    margin: 'dense'
  }
};
export default MuiFilledInput