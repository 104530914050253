import { userConstants } from '../_constants';
import { sessionService } from '../_services';

export const sessionActions = {
    getAll,
};

function getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, values, history) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(values));
        const res = await sessionService.getAll(values)
        console.log("🚀 ~ file: user.actions.js ~ line 25 ~ login ~ res", res)

        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
            // handleChangeParam('title', 'Login Success')
            // handleChangeParam('message', 'Redirecting to dashboard')
            // handleChangeParam('dialog_id', 'success')
            // handleChangeParam('oneAction', true)
            // handleChangeParam('handleError', null)
            // setIsOpen()
            history('/furnitur')
        } else if (res.status_code === "E400") {
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Info")
            setTitle("Information")
            setMessage(`${res.message}`)
            setOpen(true)
            // setIsOpen(true)
        } else if (res.status_code === 'S0') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Info")
            setTitle("Information")
            setMessage(`${res.message}`)
            setOpen(true)
            // setIsOpen(true)
        } else if (res.status_code === 'E0') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            // setIsOpen(true)
        } else if (res.status_code === 'E2') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("NewUser")
            setTitle("Setup Credential")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 'E1') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            // setIsOpen(true)
            localStorage.setItem('isAuthenticated', false);
        } else if (res.status_code === 'E3') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            localStorage.setItem('isAuthenticated', false);
        } else if (res.status_code === 'E4') {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            localStorage.setItem('isAuthenticated', false);
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
            localStorage.setItem('isAuthenticated', false);
        }

    };

    function request(users) { return { type: userConstants.LOGIN_REQUEST, users } }
    function success(users) { return { type: userConstants.LOGIN_SUCCESS, users } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
