import { Button, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableCustom from './TableComponent';
import { validateSign } from 'controllers/_validate'
import {
    useInput,
  } from 'hooks'
  import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/styles';
import { CustomModal } from 'components';
import PrintHistory from './printHistory'
function AddComponent(props) {
    const [handleFieldChange, values] = useInput(validateSign, {})
    const [state, setState] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [list, setList] = useState([])
  console.log(props?.historyNego,'negooooo')
    const handleOpenModal = () => {
      setOpenModal(true)
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F1F5F8",
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(even)': {
        backgroundColor: '#F1F5F8',
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));
    // console.log(props?.negotiation,'negot')
    const checkingApprove = async(e) => {
      try {
        let result = {}
        let resultArr1 = []
        let resultArr2 = []
        let arr = await props?.negotiation.filter((val) => val.REVISION_CODE === e.REVISION_CODE )
        
        // setList({...list, REVISION_CODE: e.REVISION_CODE, ORDER_ID: props?.data?.INVOICE_NO})
        console.log("🚀 ~ file: index.jsx ~ line 51 ~ checkingApprove ~ arr", e)
        let product = await arr?.[0]?.LIST_COMPONENT?.filter((val) => val.COMPONENT_TYPE === "PRODUCT")
        console.log("🚀 ~ file: index.jsx ~ line 53 ~ checkingApprove ~ product", product)
        await product?.map((val, idx) =>{
          const temp = {
            No: idx + 1,
            "ID Produk": val?.COMPONENT_ID,
            "Nama Produk": val?.ITEM_NAME,
            "Harga Sebelum Nego (satuan)": val?.PRICE_LAST,
            "Qty": val?.ITEM_TOTAL,
            "Harga Setelah Nego (satuan)": val?.PRICE_PROPOSED,
            // "Qty Sesudah Nego": val?.ITEM_TOTAL,
          }
          resultArr1?.push(temp)
        })
        // await setList({...list, PRODUCT: resultArr1})
        const prev_total_product = e?.PREV_PRICE ?? 0
        const new_total_product = e?.NEW_PRICE ?? 0
        // await setList({...list, PREV_TOTAL_PRODUCT: prev_total_product, NEW_TOTAL_PRODUCT: new_total_product})
        let shipping = await arr?.[0]?.LIST_COMPONENT?.filter((val) => val.COMPONENT_TYPE === "SHIPPING")
        await shipping?.map((val, idx) =>{
          const temp = {
            No: idx + 1,
            "Tujuang Pengiriman": val?.ADDRESS_KABUPATEN,
            "Harga Pengiriman Sebelum Nego": val?.PRICE_LAST,
            "Harga Pengiriman Setelah Nego (satuan)": val?.PRICE_PROPOSED,
          }
          resultArr2?.push(temp)
        })
        // await setList({...list, SHIPPING: resultArr2})
        const prev_total_shipping = e?.PREV_SHIPPING ?? 0
        const new_total_shipping = e?.NEW_SHIPPING ?? 0
        await setList({
          REVISION_CODE: e.REVISION_CODE, 
          ORDER_ID: props?.data?.INVOICE_NO,
          PRODUCT: resultArr1,
          PREV_TOTAL_PRODUCT: prev_total_product,
          NEW_TOTAL_PRODUCT: new_total_product,
          SHIPPING: resultArr2, 
          PREV_TOTAL_SHIPPING: prev_total_shipping, 
          NEW_TOTAL_SHIPPING: new_total_shipping})
        setOpenModal(true)
        console.log("🚀 ~ file: index.jsx ~ line 52 ~ checkingApprove ~ e", e)
      } catch (error) {
      console.log("🚀 ~ file: index.jsx ~ line 49 ~ checkingApprove ~ error", error)
      }
    }
  return (
    <Box sx={{width: '100%'}}>
        <CustomModal open={openModal} body={<PrintHistory data={[]} close={setOpenModal} setList={setList} list={list}/>}></CustomModal>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h5'>Negotiation History</Typography>
            <Button onClick={()=> {props?.close(false)}} endIcon={<CloseIcon></CloseIcon>}></Button>        
           </Box>
           <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h5'>Order ID: {props?.data?.INVOICE_NO} </Typography>
            </Box>
          <Box>
          
          <TableCustom
          data={props?.negotiation ?? []}
          historyNego={props?.historyNego}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          checkingApprove={(body, type) => checkingApprove(body, type)}              
          >
              
          </TableCustom>
        </Box>
      {/* <FormControl sx={{mt: 2}}>
        <Button variant='contained' color='primary' onClick={handleConfirm}>Close</Button>
      </FormControl> */}

    </Box>
  )
}

export default AddComponent