import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  container: {
    padding: theme.spacing(10),
    height: 'auto',
    width: '100%',
    overflow: "hidden",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    
  },
  card: {
    width: 200,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    '&:hover': {
      border: '1px solid #E2E2E2'
    },
    [theme.breakpoints.down('md')]: {
      width: 180,
    },
    [theme.breakpoints.down('sm')]: {
      width: 150,
      padding: theme.spacing(1, 0),
    },
  },
  image: {
    width: 150,
    height: 150,
    [theme.breakpoints.down('md')]: {
      width: 120,
      height: 120,
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
      height: 100,
    },
  },
  text: {
    //    marginLeft: theme.spacing(-8)
    width: 150,

    [theme.breakpoints.down('md')]: {
      width: 120,
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical"
  }
}));
