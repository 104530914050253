import ListStep from './list'
import CheckoutStep from './checkout'
import ShippingStep from './shipping'
import ShippingAddressStep from './shippingAddress'
import ShippingInfoStep from './shippingInfo'

export default function ConditionalRender(props) {
    const {
        changeStep,
        data,
        loading,
        error,
        step,
        selected,
        onSelect,
        checkingApprove,
        initFetch,
        filter,
        language,
        setFilter,
        handleSubmit,
        pageType,
        totalPage,
        setPage,
        selectedType,
        subCategory,
        handleFilter,
        setFilterSpec,
        filterSpec,
        setFilterPrice,
        filterPrice,
        catCode,
        clearFilter,
        setafterUpdate,
        afterUpdate,
        dataProfile,
        dataVendor
    } = props
    if (step === 'Checkout') {
        return <CheckoutStep
            data={data}
            loading={loading}
            selected={selected}
            error={error}
            step={step}
            initFetch={initFetch}
            changeStep={changeStep}
            onSelect={onSelect}
            language={language}
        />
    }
    if (step === 'Shipping') {
        return <ShippingStep
            data={data}
            loading={loading}
            selected={selected}
            error={error}
            step={step}
            initFetch={initFetch}
            changeStep={changeStep}
            onSelect={onSelect}
            language={language}
            handleSubmit={handleSubmit}
            dataProfile={dataProfile}
            setafterUpdate={setafterUpdate}
            afterUpdate={afterUpdate}
        />
    }
    if (step === 'ShippingAddress') {
        return <ShippingAddressStep
            data={data}
            loading={loading}
            selected={selected}
            error={error}
            step={step}
            initFetch={initFetch}
            changeStep={changeStep}
            onSelect={onSelect}
            language={language}
            handleSubmit={handleSubmit}
            dataProfile={dataProfile}
            setafterUpdate={setafterUpdate}
            afterUpdate={afterUpdate}
        />
    }
    if (step === 'ShippingInfo') {
        return <ShippingInfoStep
            data={data}
            loading={loading}
            selected={selected}
            error={error}
            step={step}
            initFetch={initFetch}
            changeStep={changeStep}
            onSelect={onSelect}
            language={language}
            handleSubmit={handleSubmit}
            dataProfile={dataProfile}
            dataVendor={dataVendor}
        />
    }
    return <ListStep
        changeStep={changeStep}
        handleSubmit={handleSubmit}
        data={data}
        loading={loading}
        error={error}
        step={step}
        selected={selected}
        onSelect={onSelect}
        checkingApprove={(body, type) => checkingApprove(body, type)}
        setFilter={setFilter}
        // filter={filter}
        language={language}
        setafterUpdate={setafterUpdate}
        afterUpdate={afterUpdate}
        dataProfile={dataProfile}
    />
}
