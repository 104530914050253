import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export default function useCleanRestData() {
  const dispatch = useDispatch()

  const initFetch = useCallback(() => {
    dispatch({ type: 'CLEAN_REST_DATA' });
  }, [dispatch])

  return [initFetch]
}
