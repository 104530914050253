import React, { useState, useEffect } from 'react'
import {
  Grid,
  Box,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Typography,
  ButtonGroup,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Tooltip,
} from '@mui/material'
import noImage from "assets/images/noImage.jpeg"
import { rupiahFormat } from 'helpers'
import { BreadCrumbs } from 'components'
import { makeStyles } from "@mui/styles";
import { useProduct } from 'hooks'
import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { Tab, Cart, Checkout } from '../component';


const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  image: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 5

  },
  imageContent: {
    width: 400,
    height: 400,
    borderRadius: '10px',
    [theme.breakpoints.down('lg')]: {
      width: 220,
      height: 220,
    },
    [theme.breakpoints.down('md')]: {
      width: 150,
      height: 150,
    },

  },
  otherImageContent: {
    borderRadius: '10px',
    marginLeft: 3,
    marginRight: 3,
    width: 70,
    height: "auto",
    cursor: 'pointer',
    "&:hover": {
      border: 'solid 1px #20376D'
    },
    [theme.breakpoints.down('lg')]: {
      width: 40,
      height: "auto",
    },
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: "auto",
    },
  },
  content: {
    paddingTop: '10px'
  },
  textContent: {
    textAlign: 'left'
  }
}))

export default function ViewStep(props) {
  // window.scrollTo(0, 0);                                         // Auto Scroll to Top
  const {
    // type,
    // changeStep,
    // step,
    // onSelect,
    selected,
    dataProfile,
    // language,
    // pageType,
    // catCode
  } = props

  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  console.log("🚀 ~ file: view.jsx ~ line 106 ~ ViewStep ~ queryParse", queryParse.act)

  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);
  // const [open, setOpen] = useState(false);
  // const [title, setTitle] = useState("");
  // const [message, setMessage] = useState("");
  // const [dialogType, setDialogType] = useState("error");
  // const [datatables, setDatatables, getDetail] = useProduct(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  // const detailProduct = useSelector(state => state.detailProduct);
  // const [rows, setRows] = React.useState([])

  // useEffect(() => {
  //   if (queryParse?.act === 'Detail') {
  //     const param = {
  //       PRODUCT_CODE: queryParse?.PRODUCT_CODE,
  //       PRODUCT_NAME: queryParse?.PRODUCT_NAME,
  //       page: 1,
  //       limit: 10
  //     }
  //     getDetail(param, 'getDetail')

  //   }

  // }, [])

  // React.useEffect(() => {
  //   const rowData = detailProduct?.items?.data ?? []
  //   if (rowData.length > 0) setRows(rowData)
  //   else setRows([])
  // }, [detailProduct])

  const classes = useStyles()

  const navigate = useNavigate()
  return (
    <div className={classes.container}>
      <Checkout
        selected={selected}
        dataProfile={dataProfile}
      />
    </div >
  );
}