import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
   container : {
       background: '#F7F8F9',
       height: 'auto',
       width: '100%',
       padding: theme.spacing(5,10,1,10)
   },
   subContain: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
   },
   subContain2: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
   },
   title: {
       fontSize: '20px',
       fontWeight: 'bold'
   },
   body: {
       fontSize: '15px',
        margin: '1px 0px'
   },
   bodyIcon: {
    fontSize: '15px',
    //  margin: '5px 0px',
     display: 'flex',
    //  justifyContent: 'center',
    whiteSpace: 'noWrap',
     alignItems: 'center',
     '& > *': {
         marginRight: theme.spacing(2)
     }
    },
    link: {
        fontSize: '15px',
        cursor: 'pointer',
        '&:hover': {
            color: 'blue',
        } 
    },
    copyRight: {
        fontSize: '15px',
        textAlign: 'center',
    }
  }));
