import axios from 'axios';
import { getAccessLog } from 'helpers';
import { baseURL } from '../../config/base/base.config'
import { userActions } from '../_actions'
import { store } from './store'
// import authHeader from './auth-header'

function historyPush(val) {
  // const history = useHistory()
  return window.location.replace(val)
}

const service = axios.create({ baseURL: baseURL.common });
const newService = axios.create()


function handleRefreshToken(error, originalRequest) {
  console.log("🚀 ~ file: interceptor.js ~ line 16 ~ handleRefreshToken ~ error", error)
  const bodyReq = {
    'refresh_token': `${getAccessLog('refresh_token')}`
  }
  const HeaderReq = {
    headers: {
      Authorization: `Bearer ${getAccessLog('refresh_token')}`
    }
  }
  return new Promise(function (resolve, reject) {
    service.post('/usr/refreshtoken', bodyReq, HeaderReq)
      .then(async resdata => {
        if (resdata.status === 200) {
          // Save token to localStorage
          window.sessionStorage.setItem('token', resdata?.data?.DATAS?.access_token);
          window.sessionStorage.setItem('refreshToken', resdata?.data?.DATAS?.refresh_token);

          // send token to redux
          const subdata = await store.dispatch(userActions.externalMiddlewareRefreshToken(resdata?.data?.DATAS))
          console.log("🚀 ~ file: interceptor.js ~ line 40 ~ subdata", subdata)
          // retry request to original 
          if (subdata.response_code === 'S1') {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + resdata.data?.DATAS?.access_token;
            originalRequest.headers['Authorization'] = 'Bearer ' + resdata.data?.DATAS?.access_token;
            processQueue(null, resdata?.data?.DATAS?.access_token);
            resolve(newService(originalRequest));
          } else {
            processQueue(resdata.data, null);
            reject(resdata.data);
          }
        } else {
          console.log("🚀 ~ file: interceptor.js ~ line 31 ~ err", resdata.data)
          processQueue(resdata.data, null);
          reject(resdata.data);
          historyPush('/signin')
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: interceptor.js ~ line 31 ~ err", err.response, err)
        processQueue(err.response, null);
        reject({ ...err.response, messageError: 'Session Expired' });
        // historyPush('/login')
      })
      .finally(() => { isRefreshing = false })
  })
}


axios.create({
  baseURL: `${baseURL.common}`
});

axios.interceptors.request.use(
  config => {
    console.log(`Interceptor ~ config ${config.url}`, config)
    const url = config.url
    config.url = baseURL.common + url
    // config.headers["Access-Control-Allow-Origin"] = "*"
    // config.headers["Host"] = baseURL.common
    // config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    // config.rejectUnauthorized = false

    return config;
  },
  error => {
    Promise.reject(error)
  });

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}


axios.interceptors.response.use((response) => {
  console.log(`Interceptor ~ response ${response.config.url}`, response)

  if (response.status === 200) {
    console.log("Success to request");
    return response;
  }

  return response;

}, async (err) => {
  console.log("Interceptor ~ Error Response", err.response)
  console.log("Interceptor ~ Error", err)
  const originalRequest = err.config;
  console.log("🚀 ~ file: interceptor.js ~ line 95 ~ axios.interceptors.response.use ~ originalRequest", originalRequest)

  if (err?.response?.config?.url === "/usr/login") {
    return err.response
  } else {
    if (err?.response?.config?.url !== `${baseURL.common}/usr/login` && err?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        console.log(err.response)
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        }).then(token => {
          console.log("🚀 ~ file: interceptor.js ~ line 124 ~ token", token)
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return newService(originalRequest);
        }).catch(err => {
          console.log("🚀 ~ file: interceptor.js ~ line 128 ~ err", err)
          return Promise.reject(err);
        })
      } else {
        originalRequest._retry = true;
        isRefreshing = true;

        const testres = await handleRefreshToken(err.response, originalRequest)
        console.log("🚀 ~ file: interceptor.js ~ line 139 ~ axios.interceptors.response.use ~ testres", testres)
        return testres;
      }
    }

    if (err?.response?.config?.url !== `${baseURL.common}/usr/login` && err?.response?.status === 403) {
      console.log(err.response)
      historyPush('/signin')
      return err.response;
    }

    console.log(err)
    const resp = {
      data: {
        message: 'Network Error'
      },
      status: 400,
      message: 'Network Error'
    }
    // return err.response;
    return Promise.reject(err?.response ?? resp);

  }

});

export default axios;