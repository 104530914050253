import dateformat from 'dateformat';
export default function getFormattedDay(date, type) {
  if (date) {
    // const dateformat = require('dateformat')

    const year = date?.substr(0, 4);
    const month = date?.substr(5, 2);
    const day = date?.substr(8, 2);
    const hour = date?.substr(11, 2)
    const minute = date?.substr(14, 2)
    const second = date?.substr(17, 2)
    const convertdate = new Date(year, month - 1, day, hour, minute, second)
    // const convertFulldate = new Date()
    let app_date
    // console.log("🚀 ~ file: getFormattedDay.js ~ line 8 ~ convertdate", convertdate)
    if (type === 'justDate') {
      app_date = dateformat(convertdate, "yyyy-mm-dd")
    } else if (type === 'normal') {
      app_date = dateformat(convertdate, "dd mmm yyyy")
    } else if (type === 'justMonth') {
      app_date = dateformat(convertdate, "mmmm")
    } else if( type === 'withoutSecond') {
      app_date = dateformat(convertdate, "yyyy-mm-dd, HH:MM")
    }
    else {
      app_date = dateformat(convertdate, "yyyy-mm-dd, HH:MM:ss")
    }
    // console.log("🚀 ~ file: getFormattedDay.js ~ line 11 ~ app_date", app_date)

    return app_date
  } else {
    return "-"
  }
}
