import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import {
  Route,
  // Switch,
  Navigate,
  // withRouter,
} from "react-router-dom";
// import { getAccessLog } from "helpers";

// pages
// import NotFound from "../../pages/General/CommonNotFound";
import { GeneralLayout } from "../../components";
import { Home } from 'pages'
import NotFound from "pages/General/CommonNotFound";
import StoragePage from "pages/Core/Storage";
import ProductDetailPage from "pages/Core/Product";
import CartPage from "pages/Core/Cart";
import ProfilePage from "pages/Core/Profile";
import AboutUsPage from "pages/Core/AboutUs";
import OfficeFitsOutPage from "pages/Core/OfficeFitsOut";
import TermsPage from "pages/Core/Terms";
import ContactUsPage from "pages/Core/ContactUs";
import OurProjectPage from "pages/Core/OurProject";
import SalePage from "pages/Core/Sale";
import ShopByRangePage from "pages/Core/ShopByRange";
import BlankPage from "pages/Core/Blank";
import VerifyPage from "pages/Core/Verify";

const checkValidation = () => {
  // const isAuthenticated = getAccessLog('user_data');
  // const [authCheck, setAuthCheck] = useState(isAuthenticated?._key ? true : false);
  // const authentication = useSelector(state => state.authentication)

  // useEffect(() => {
  //   const checkMoreData = authentication?.user?.data?._key ? true : false
  //   setAuthCheck(checkMoreData);
  // }, [authentication]);
  const authCheck = true
  return authCheck
};


export const privateRoute = [
  { path: "/", element: <Navigate to="/furnitur" /> },
  { path: "/furnitur", element: checkValidation ? <GeneralLayout child={<Home />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/sale", element: checkValidation ? <GeneralLayout child={<SalePage page={true} />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/blank", element: <BlankPage /> },
  { path: "/verify", element: <GeneralLayout child={<VerifyPage />} /> },



  { path: "/furnitur/product", element: checkValidation ? <GeneralLayout child={<ProductDetailPage page={true} />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/shop-by-range", element: checkValidation ? <GeneralLayout child={<ShopByRangePage page={true} />} /> : <Navigate to="/signin" /> },
  // { path: "/furnitur/storage", element: checkValidation ? <GeneralLayout child={<ProductDetailPage page={true} type={'Storage'} />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/cart", element: checkValidation ? <GeneralLayout child={<CartPage page={true} />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/profile", element: checkValidation ? <GeneralLayout child={<ProfilePage page={true} />} /> : <Navigate to="/signin" /> },

  // on footer
  { path: "/furnitur/about-us", element: checkValidation ? <GeneralLayout child={<AboutUsPage page={true} />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/office-fits-out", element: checkValidation ? <GeneralLayout child={<OfficeFitsOutPage page={true} />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/terms", element: checkValidation ? <GeneralLayout child={<TermsPage page={true} />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/contact-us", element: checkValidation ? <GeneralLayout child={<ContactUsPage page={true} />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/our-project", element: checkValidation ? <GeneralLayout child={<OurProjectPage page={true} />} /> : <Navigate to="/signin" /> },
  { path: "/furnitur/our-project", element: checkValidation ? <GeneralLayout child={<OurProjectPage page={true} />} /> : <Navigate to="/signin" /> },


  { path: '*', element: <NotFound /> }
]
// export default privateRoute;
