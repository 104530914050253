import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CustomTable, CustomBackdrop, HandlerModal } from 'components'
import { Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/FindInPageRounded';
import EditIcon from '@mui/icons-material/Edit';
import { formatCurrency, getDayNow, getFormattedDay } from 'helpers';
import { InsertDriveFile } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PrintIcon from '@mui/icons-material/Print';
import catalogImg from "assets/images/catalog.jpg"
import terbilang from 'helpers/terbilang';
import { useLookup, useLanguage } from 'hooks';
const useStyles = makeStyles(theme => ({
  root: {},
  statusError: {
    color: theme.palette.danger.main,
    fontWeight: 'bold'
  },
  statusSuccess: {
    color: theme.palette.success.main,
    fontWeight: 'bold'
  },
  statusUndefined: {
    color: theme.palette.info.main,
    fontWeight: 'bold'
  },
  iconBlue: {
    color: '#8DA4B5',
    '&:hover': {
      color: "#378BCC"
    }
  },
  iconRed: {
    color: '#8DA4B5',
    '&:hover': {
      color: "#378BCC"
    }
  },
  iconDel: {
    color: '#8DA4B5',
    '&:hover': {
      color: "#D32F2F"
    }
  }
}))


export default function TableComponent(props) {
  const {
    data,
    // error,
    loading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    onSelect,
    selected,
    // step,
    changeStep,
    // handleDelete,
    checkingApprove
  } = props
  const [language, lagDic, setLang] = useLanguage()
  const classes = useStyles()
  const areaGroups = useSelector(state => state.lkppOrder);
  const countLoaded = Number(areaGroups?.items?.completed_response?.data?.INFO_PAGE?.TOTAL_RECORD ?? 0) ?? 0
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [splitter, setSplitter] = useState(1)
  const [option, setOption] = useState({})
  const [body, setbody] = useState()
  const [print, setPrint] = useLookup(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage);

  function StatusHandler(stat) {
    if (stat === '1:Admin Initiate') {
      return (
        <Typography className={classes.statusInfo}>Waiting Admin add Delivery Time</Typography>
      )
    } else if (stat === '2:Customer Approval') {
      return (
        <Typography className={classes.statusInfo}>Waiting for Customer Approval</Typography>
      )
    } else if (stat === '3:Admin Approval') {
      return (
        <Typography className={classes.statusInfo}>Waiting for Admin Approval</Typography>
      )
    } else if (stat === '4:Deal') {
      return (
        <Typography className={classes.statusInfo}>Deal</Typography>
      )
    } else if (stat === '5:Cancelled') {
      return (
        <Typography className={classes.statusInfo}>Cancelled</Typography>
      )
    } else {
      return (
        <Typography className={classes.statusInfo}>Null</Typography>
      )
    }

  }

  useEffect(() => {
    if (print?.pdfTransaction?.data) {
      const newBody = staticRender(print?.pdfTransaction?.data?.[0])
      setbody(renderBody(newBody))
    }
  }, [print?.pdfTransaction])

  function nameHandle(value, type) {
    let stat = ''
    if (type === 'PURCHASE_DATE') {
      stat = `${getFormattedDay(value?.PURCHASE_DATE, 'withoutSecond') ?? '-'}`
    }
    if (type === 'MODIFIED_DATE') {
      stat = `${getFormattedDay(value?.META?.MODIFIED_DATE, 'withoutSecond') ?? '-'}`
    }
    if (type === 'PRICE_FULL') {
      const PRICE_ITEMS = value?.PURCHASE_ITEM.reduce((n, { SUB_TOTAL_NEGO }) => n + SUB_TOTAL_NEGO, 0)
      const PRICE_SHIPPING = value?.SHIPPING.reduce((n, { SHIPPING_NEGO }) => n + SHIPPING_NEGO, 0)
      const PRICE_FULL = PRICE_ITEMS + PRICE_SHIPPING

      stat = `Rp. ${formatCurrency(PRICE_FULL)}`
    }


    return `${stat}`
  }

  const columns = [
    { id: 'INVOICE_NO', value: 'INVOICE_NO', label: language.COMMON.TABLE_MY_TRANSACTION.ORDERID.toUpperCase(), minWidth: 100 },
    { id: 'PURCHASE_DATE', value: 'PURCHASE_DATE', label: language.COMMON.TABLE_MY_TRANSACTION.PURCHASED_DATE.toUpperCase(), minWidth: 100, customFormat: (value) => nameHandle(value, 'PURCHASE_DATE') },
    { id: 'COMPANY_NAME', value: 'COMPANY_NAME', label: language.COMMON.TABLE_MY_TRANSACTION.INSTANCE.toUpperCase(), minWidth: 100 },
    { id: 'CUSTOMER_NAME', value: 'CUSTOMER_NAME', label: language.COMMON.TABLE_MY_TRANSACTION.PIC.toUpperCase(), minWidth: 100 },
    { id: 'PRICE_FULL', value: 'PRICE_FULL', label: language.COMMON.TABLE_MY_TRANSACTION.TRANSACTION_AMOUNT.toUpperCase(), minWidth: 100, customFormat: (value) => nameHandle(value, 'PRICE_FULL'), numeric: true },
    { id: 'MODIFIED_DATE', value: 'MODIFIED_DATE', label: language.COMMON.TABLE_MY_TRANSACTION.LAST_STATUS.toUpperCase(), minWidth: 100, customFormat: (value) => nameHandle(value, 'MODIFIED_DATE') },
    {
      id: 'PURCHASE_STATUS', value: 'PURCHASE_STATUS', label: language.COMMON.TABLE_MY_TRANSACTION.STATUS.toUpperCase(), minWidth: 120, customFormat: (value) => StatusHandler(value?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS ?? 'null')

    },
  ];

  const handlePrint = (data, type) => {

    const param2 = {
      INVOICE_NO: data.INVOICE_NO,
    }
    setPrint(param2, 'pdfTransaction')

    console.log("🚀 ~ file: index.jsx ~ line 297 ~ handlePrint ~ data", data)
    setOpen(true)
    setTitle(language.COMMON.MESSAGE.PRINT_OPTION)
    setMessage(language.COMMON.MESSAGE.PRINT)
    setDialogType("ConfirmationPrint")
  }
  const moveStep = () => {
    changeStep('edit-store')
  }

  const actionStructure = [
    {
      id: '1', value: '_key', label: 'View Detail', icon: <InsertDriveFile className={classes.iconRed} />, variant: 'default', color: 'primary',
      onAction: (body) => checkingApprove(body, "transactionDetail")
    },
    {
      id: '2', value: 'name', label: 'View History', icon: <SummarizeIcon className={classes.iconBlue} />, variant: 'default', color: 'primary',
      onAction: (body) => checkingApprove(body, "transactionInfo")
    },
    {
      disabled: (row) => {
        if (row?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS === '4:Deal') return false
        else return true
      },
      id: '2', value: 'name', label: language.COMMON.MY_TRANSACTION.PRINT, icon: <PrintIcon className={classes.iconBlue} />, variant: 'default', color: 'primary',
      onAction: (body) => handlePrint(body, "view")
    }
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const staticRender = (item, renderType) => {
    const styleTable = {
      border: '1px solid black',
      borderCollapse: 'collapse',
      textAlign: 'center',
      paddingLeft: '8px',
      paddingRight: '8px',
    }
    console.log("🚀 ~ file: index.jsx ~ line 371 ~ staticRender ~ item", item)
    let loc = "LOC2"
    return (
      <React.Fragment>
        <div style={{ textAlign: 'left', width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <p>Dicetak Menggunakan Aplikasi e-Purchasing Pemerintah Pada Tanggal {getDayNow()} </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <img src={catalogImg}></img>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>

          </div>
          <p>Informasi Paket</p>
          <p>Komoditas: Peralatan Perkantoran Tanggal Buat: {item?.PURCHASE_DATE ?? '-'}ID Paket: {item?.INVOICE_NO ?? '-'} Tanggal Ubah: 29 September 2021</p>
          <p>Nama Paket: {item?.PACKAGE_NAME ?? '-'} Jumlah Produk: {item?.PRODUCT_QTY ?? '-'}</p>
          <p>Instansi: {item?.INSTANCE ?? '-'} Total Harga: Rp {formatCurrency(item?.TOTAL_TRANSACTION ?? 0)} {terbilang(item?.TOTAL_TRANSACTION) ?? 0}</p>
          <p>Satuan Kerja: {item?.WORK_UNIT ?? ''}</p>
          <p>Alamat Satuan Kerja: {item?.WORK_UNIT_ADDRESS}</p>
          <p>Kurs: IDR</p>
          <p>NPWP: {item?.WORK_UNIT_NPWP ?? ''} Nilai Kurs: 1.0</p>
          <p>Tahun Anggaran: {item?.BUDGET_YEAR ?? ''} Tanggal Kurs: {getDayNow()}</p>
          <p>Sumber Dana: {item?.BUDGET_SOURCE ?? ''}</p>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <tr>
              <td style={{ width: '25%', textAlign: 'left', verticalAlign: 'top' }}>
                <p>PP/Pemesan<br />
                  {item?.CUSTOMER_INFO?.[0]?.CUSTOMER_NAME ?? ''}<br />
                  {item?.CUSTOMER_INFO?.[0]?.CP?.find(v => v?.CP_MODE === 'EMAIL')?.CP_CONTACT ?? ''}<br />
                  {item?.CUSTOMER_INFO?.[0]?.CP?.find(v => v?.CP_MODE === 'PHONE')?.CP_CONTACT ?? ''}</p>
              </td>
              <td style={{ width: '25%', textAlign: 'left', verticalAlign: 'top' }}>
                <p>Daftar Pesanan Produk PPK<br />
                  {item?.PPK_NAME ?? ''}</p>
              </td>
              <td style={{ width: '25%', textAlign: 'left', verticalAlign: 'top', paddingRight: '5px' }}>
                <p>{item?.COMPANY_INFO?.[0]?.VALUE_07 ?? ''}<br />
                  Penyedia<br />
                  {item?.COMPANY_INFO?.[0]?.VALUE_01 ?? ''}<br />
                  {item?.COMPANY_INFO?.[0]?.VALUE_09 ?? ''}<br />
                  {item?.COMPANY_INFO?.[0]?.VALUE_06 ?? ''}</p>

              </td>
              <td style={{ width: '25%%', textAlign: 'left', verticalAlign: 'top', paddingLeft: '5px' }}>
                <p>
                  Distributor/Reseller/Pelaksana Pekerjaan/Pengirim Barang
                  <br />
                  {item?.COMPANY_INFO?.[0]?.VALUE_01 ?? ''}<br />
                  {item?.COMPANY_INFO?.[0]?.VALUE_09 ?? ''}<br />
                  {item?.COMPANY_INFO?.[0]?.VALUE_06 ?? ''}</p>
              </td>
            </tr>
          </table>
          {
            item?.SHIPPING_ITEM?.map((val, idx) => {
              function getSum(total, num) {
                console.log("🚀 ~ file: index.jsx ~ line 328 ~ getSum ~ num", num)
                console.log("🚀 ~ file: index.jsx ~ line 328 ~ getSum ~ (total", total)
                return total + (Number(num?.INFO_DIMENSION) * num?.ITEM_TOTAL);
              }
              let shipCost = val?.PURCHASE_ITEM.reduce(getSum, 0)
              return (
                <table className="table-print" style={{
                  width: '100%',
                  pageBreakInside: 'auto',
                  marginBottom: '30px',
                  ...styleTable
                }}>
                  <thead>
                    <tr style={{ backgroundColor: '#BFBFBF', textAlign: 'left' }}>
                      <th colSpan={8} style={{ textAlign: 'left', paddingLeft: '10px' }}>
                        {val?.ADDRESS_NAME ?? ''}
                      </th>
                    </tr>
                    <tr>
                      <th style={styleTable}>Nama Produk</th>
                      <th style={styleTable}>Manufaktur</th>
                      <th style={styleTable}>Kuantitas</th>
                      <th style={styleTable}>Harga Satuan</th>
                      <th style={styleTable}>Perkiraan Ongkos Kirim</th>
                      <th style={styleTable}>Tanggal Pengiriman Produk</th>
                      <th style={styleTable}>Total Harga</th>
                      <th style={styleTable}>Catatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {val?.PURCHASE_ITEM?.map((i, index) => {
                      return (
                        <tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
                          <td style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                          }}>
                            {i?.ITEM_NAME ?? ''}
                          </td>
                          <td style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                          }}>
                            {i?.BRAND_NAME ?? ''}
                          </td>
                          <td style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                          }}>
                            {i?.ITEM_TOTAL ?? ''}
                          </td>
                          <td style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                          }}>
                            Rp.{formatCurrency(i?.ITEM_PRICE ?? 0) ?? ''}
                          </td>

                          <td style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                          }}>
                            Rp.{formatCurrency(Math?.ceil((val?.SHIPPING_COST / shipCost) * (i?.INFO_DIMENSION * i?.ITEM_TOTAL)) ?? 0) ?? ''}
                          </td>

                          <td style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                          }}>
                            {getFormattedDay(i?.DELIVERY_TIME, 'justDate') ?? ''}
                          </td>

                          <td style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                          }}>
                            <p>
                              Rp.{formatCurrency(i?.PRICE_TOTAL_ITEM ?? 0) ?? ''}
                            </p>
                          </td>
                          <td style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                          }}>
                            {item?.NOTE ?? ''}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )
            }
            )
          }

        </div>
      </React.Fragment>
    )
  }

  const renderBody = (val) => {
    return (
      <div style={{ width: '100%' }}>
        <Grid container component="div" className={classes.toPrint} id="divToPrint">
          <div style={{
            // padding: '10px',
            // display: 'flex',
            display: 'block',
            // justifyContent: 'flex-start',
            alignItems: 'center',
            // justifyContent: 'center',
            width: '100vw',
            pageBreakInside: 'avoid',
            breakInside: 'avoid',
            // flexWrap: 'wrap',
          }}>

            <Grid
              component="div"
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: 'block',
                padding: '10px',
                width: `${100}%`
              }}
            >
              <div style={{
                pageBreakInside: 'avoid',
                breakInside: 'avoid',
                padding: '10px',
                textAlign: 'center'
              }}>
                {val}
              </div>
            </Grid>

          </div>
        </Grid>
      </div>
    )
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handlePrintFrame = async () => {
    var printContents = document.getElementById("divToPrint").innerHTML;
    const orderHtml = printContents
    document.body.innerHTML = orderHtml;
    document.title = 'clarity';
    window.print();
    window.location.reload()
    // var divContents = await document.getElementById("divToPrint").innerHTML;
    // var a = window.open('', '');
    // a.document.write('<html>');
    // a.document.write('<head><title>Clarity</title></head>');
    // a.document.write('<body>');
    // a.document.write(divContents);
    // a.document.write('</body></html>');
    // a.document.close();
    // a.print();
  }

  return (
    <React.Fragment>
      <CustomBackdrop loading={isLoading}></CustomBackdrop>
      <HandlerModal
        open={open}
        onClose={handleClose}
        dialogType={dialogType}
        message={message}
        title={title}
        label={{
          positive: "Print",
          negative: language.COMMON.COMMON_BUTTON.CLOSE,
        }}
        action={{
          onClose: () => handleClose(),
          positive: (body, type) => handlePrintFrame(body, type),
          negative: () => handleClose()
        }}
        body={() => (body)}
        customWidth={"lg"}
        error={error}
        loading={isLoading}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <CustomTable
        rows={data}
        loading={loading}
        error={error}
        selectable
        onSelect={onSelect}
        changeStep={moveStep}
        selected={selected}
        multiAction
        actionStructure={actionStructure}
        action={(body, type) => props.checkingApprove(body, type)}
        columns={columns}
        rowsPerPage={rowsPerPage || 10}
        dbPagination
        totalCount={data?.length}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        folderImage={'product-subcategory'}
      />
    </React.Fragment>
  );
}
