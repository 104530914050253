import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#F7F8F9',
    height: '73vh',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      height: '35vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '25vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '25vh',
    },
  },
  item: {
    position: 'relative',
  },
  itemImg: {
    position: 'absolute',
    width: '100%',
    height: '70vh',
    [theme.breakpoints.down('lg')]: {
      height: '30vh',
    },
    [theme.breakpoints.down('md')]: {
      height: '20vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '20vh',
    },
  },
  itemText: {
    textAlign: 'center',
    paddingTop: '10vh',
    color: 'gray',
    fontSize: '50px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
  }
}));
