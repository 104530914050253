import common from './common';
import login from './login.en';
import token from './token.en';
import dashboard from './dashboard';
import account from './account';
import logout from './logout';
import payroll from './payroll';
import transfer from './transfer';
import bundle from './bundle';
import shortcut from './shortcut';
import table from './tabel';
import bulkTransfer from './bulkTransfer';

export default function Indonesia() {
  const Lang = {
    COMMON: common,
    SHORTCUT: shortcut,
    LANDING_LOGIN: login,
    LANDING_TOKEN: token,
    DASHBOARD_ROOT: dashboard,
    DASHBOARD_ACCOUNT: account,
    LANDING_LOGOUT: logout,
    DASHBOARD_PAYROLL: payroll,
    DASHBOARD_TRANSFER: transfer,
    EMPLOYEE_BUNDLE: bundle,
    TABLE: table,
	DASHBOARD_BULK_TRANSFER: bulkTransfer,
  }
  return Lang
}