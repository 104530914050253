import { shippingConstants } from '../_constants';

export function shipping(state = {}, action) {
    switch (action.type) {
        case shippingConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case shippingConstants.GETALL_SUCCESS:
            return {
                items: action.shipping
            };
        case shippingConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        case shippingConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                items: state.items.map(user =>
                    user.id === action.id
                        ? { ...user, deleting: true }
                        : user
                )
            };
        case shippingConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                items: state.items.filter(user => user.id !== action.id)
            };
        case shippingConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                })
            };
        // case contactUsConstants.CREATE_REQUEST:
        //     return {
        //         loading: true
        //     };
        // case contactUsConstants.CREATE_SUCCESS:
        //     return {
        //         items: action.contactUs
        //     };
        // case contactUsConstants.CREATE_FAILURE:
        //     return {
        //         error: action.error
        //     };
        default:
            return state
    }
}