const dashboard = {
  TITLE: 'TOKEN',
  DESC: 'Sign with your registred account',
  HEADER: {
    HEADER_1: 'Hello,',
    MY_ACCOUNT: 'My Account',
  },
  LABEL: {
    TRANSACTION: 'Transaction',
    TODAY_TRANSACTION: 'Today Transaction',
    OLDER_TRANSACTION: 'Older Transaction',
    TOTAL_TASK: 'Total Transaction Task',
    TOTAL_REVIEW: 'Total Pending For Review',
    TOTAL_APPROVE: 'Total Pending For Approve',
    TOTAL_TRANSFER_TASK: 'Total Transfer Task',
    TOTAL_PAYROLL_TASK: 'Total Payroll Task',
    TOTAL_PAYMENT_TASK: 'Total Payment Task',
    TOTAL_CANCEL_UPCOMING_TASK: 'Total Cancel Upcoming Task',
    TOTAL_CANCEL_SCHEDULER_TASK: 'Total Cancel Scheduler Task',
    HELLO1_NAME: 'User Name',
    REFRESH_DATA: 'Refresh Account Info',
    EDIT_DATA: 'Edit',
    DELETE_DATA: 'Delete',
    INFO_DATA: 'Info',
    FULL_NAME: 'Full Name',
    ACCOUNT_NUMBER: 'Account Number',
    ACCOUNT_TYPE: 'Account Type',
    BALANCE: 'Balance',
    ARRANGEMENT_ID: 'Arrangement ID',
    TIME_PERIOD: 'Time Period',
    PRODUCT_NAME: 'Product Name',
    INTEREST: 'Interest',
    EFFECTIVE_DATE: 'Effective Date',
    LOAN_NUMBER: 'Loan Number',
    LOAN_VALUE: 'Loan Value',
    REMAINDER: 'Remainder',
    DUE_DATE: 'Due Date',
    INSTALLMENTS: 'Installments',
    NEXT_PAYMENT: 'Next Payment',
    BUTTON_PRINT_RECEIPT: 'Print Receipt',
  },
  PLACEHOLDER: {
    HELLO1_NAME: 'User Name',
    FULL_NAME: 'Full Name',
    ACCOUNT_NUMBER: 'Account Number',
    ACCOUNT_TYPE: 'Account Type',
    BALANCE: 'Ballance',
  },
  INFO: {
    THANKS: 'Thank You',
    ALL_RIGHT_RESERVED: 'Internet Banking Bank Capital All Right Reserved',
  },
}

export default dashboard