export const NAME = /^[a-z ,.'-()"-]+$/i;
export const NUMBER = /^[0-9]*$/;
export const NOSPACE = /^([A-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:])*[^\s]\1*$/;
export const EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const NOWHITESPACE = /^[\S]+$/;
export const upperLowerValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).*$/;
export const numericValidator = /^(?=.*\d).*$/;
export const nameRegex = /^([a-z]|[A-Z]|[ ])+$/;
export const phoneRegex = /^[0-9]*$/;
export const CharSet = /^[a-zA-Z ,.'-()+"-\d\s\_\@)(/.-]+$/;
export const PassValidate = /^(?=.*\d)(?=.*[!@#_$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;