export default function getStageToUse() {
  let STAGETOUSE = {};

  // STAGETOUSE = {
  //   TO_BASEURL: "https://dev-wakafapi.socialbanking.id"
  // }

  // const localHost = "http://192.168.100.4:3200"
  const localHost = "https://dev-clarityapi.idr.digital"
  const prodHost = "https://dev-clarityapi.idr.digital"
  const sitHost = "https://dev-clarityapi.idr.digital"

  window._env_?.APP_MODE_ACCESS === '_UNRELEASED/$26446789' && window._env_?.APP_LOCAL_ACCESS === '_OK/$78872' && window._env_?.APP_MULTISERVER === "_OK/$3324"
    ? STAGETOUSE = {
      TO_BASEURL: window._env_?.BASEURL_BE ?? localHost
    }
    : window._env_?.APP_MODE_ACCESS === '_UNRELEASED/$26446789' && window._env_?.APP_LOCAL_ACCESS === '_FAULT/$78872' && window._env_?.APP_MULTISERVER === "_OK/$3324"
      ? STAGETOUSE = {
        TO_BASEURL: localHost
      }
      : window._env_?.APP_MODE_ACCESS === '_RELEASED/$78872' && window._env_?.APP_LOCAL_ACCESS === '_OK/$78872' && window._env_?.APP_MULTISERVER === "_OK/$3324"
        ? STAGETOUSE = {
          TO_BASEURL: sitHost
        }
        : window._env_?.APP_MODE_ACCESS === '_RELEASED/$78872' && window._env_?.APP_LOCAL_ACCESS === '_FAULT/$78872' && window._env_?.APP_MULTISERVER === "_OK/$3324"
          ? STAGETOUSE = {
            TO_BASEURL: sitHost
          }
          : window._env_?.APP_MODE_ACCESS === 'RELEASED' && window._env_?.APP_LOCAL_ACCESS === 'NO' && window._env_?.APP_MULTISERVER === "OK"
            ? STAGETOUSE = {
              TO_BASEURL: window._env_?.BASEURL_BE ?? prodHost
            }
            : STAGETOUSE = {
              TO_BASEURL: ""
            };

  return STAGETOUSE;
}
