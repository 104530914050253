import React, { Fragment, useState } from "react";
// import { DatePicker } from "@mui/lab";
import { Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import {
//   getReformattedDay,
//   getFormattedDay,
//   // getDayNow,
// } from '../../helpers'

export default function BasicDatePicker(props) {
  const {
    param,
    name,
    value,
    onChange,
    className,
    disableFuture,
    label,
    disablePast,
  } = props

  const initialDate = !param?.value ? null : new Date(param?.value)
  const [date, setDate] = useState(initialDate)

  const handleChange = (val) => {
    console.log("🚀 ~ file: DatePickers.jsx ~ line 25 ~ handleChange ~ val", val)
    // const convertVal = val?.toISOString()
    param?.onChange(param?.id, val)
    setDate(val)
  }

  React.useEffect(() => {
    const checkInit = !param?.value ? null : new Date(param?.value)
    setDate(checkInit)
  }, [param?.value])


  return (
    <div style={{width: '100%'}}>
      <LocalizationProvider  dateAdapter={AdapterDateFns}>
       <DatePicker
          style={{width: '100%'}}
          // label="Date&Time picker"
          minDate={param?.minDate? new Date() : null}
          disabled={param?.disabled}
          value={value}
          inputFormat={'yyyy-MM-dd'}
          onChange={handleChange}
          renderInput={(params) => <TextField sx={{width: '100%'}} {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
}