import React from 'react'
import {
  Route,
  Routes,
  BrowserRouter,
  useRoutes
} from 'react-router-dom'
// import NotFound from "../../pages/General/CommonNotFound";
import MainAdminRoute from "./PrivateRoute"
import {privateRoute} from './private'
console.log(privateRoute,'ini')
const AdminRoute = () => {
  let element = useRoutes(privateRoute)
  return (
      <>
      {element}
      </>
  )
};

export default AdminRoute;