export const cartConstants = {
    GETALL_REQUEST: 'CART_GETALL_REQUEST',
    GETALL_SUCCESS: 'CART_GETALL_SUCCESS',
    GETALL_FAILURE: 'CART_GETALL_FAILURE',

    CREATE_REQUEST: 'CART_CREATE_REQUEST',
    CREATE_SUCCESS: 'CART_CREATE_SUCCESS',
    CREATE_FAILURE: 'CART_CREATE_FAILURE',

    DELETE_REQUEST: 'CART_DELETE_REQUEST',
    DELETE_SUCCESS: 'CART_DELETE_SUCCESS',
    DELETE_FAILURE: 'CART_DELETE_FAILURE',

    UPDATE_REQUEST: 'CART_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CART_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CART_UPDATE_FAILURE'
};