import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.users
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.users
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loggedIn: false,
                user: action.error
            };
        case userConstants.TOKEN_REQUEST:
            return { ...state };
        case userConstants.TOKEN_SUCCESS:
            const newState = { ...state };
            newState['user']['token'] = action.token?.token ?? action.token
            return {
                loggedIn: newState.loggedIn,
                user: newState.users
            };
        case userConstants.TOKEN_FAILURE:
            return { ...state };
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}