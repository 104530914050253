import React, { useState, useEffect } from 'react';
import {InputComponent} from 'components'
import DefaultStructure from './Schema/default'
import { Typography, Grid, Button } from '@mui/material';
import { CustomModal } from 'components';
import AddComponent from './components';
import addImage from 'assets/images/notfoud.jpg'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
function General(props) {
  const [openModal, setOpenModal] = useState(false)
  const [list, setList] = useState([])

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const arrayImage = ['image1']
  return (
      <div>
          <CustomModal open={openModal} body={<AddComponent close={setOpenModal} setList={setList} list={list}/>}></CustomModal>
          <Typography variant='h4'>Transaction</Typography>
          <InputComponent
          structure={DefaultStructure(props?.values,props?.handleFieldChange, handleOpenModal, props?.categoryOption)}
          isLoading={props?.isLoading}
          error={props?.error}
          divider={2}
          padding={1}
          minScreen="md"
        // readOnlyAll
        />
      </div>
  );
}

export default General;
