import { lkppOrderConstants } from '../_constants';
import { lkppOrderService } from '../_services';

export const lkppOrderActions = {
    getAll,
    createData,
    updateData,
    addData,
    cancelData,
    resetData
};

function getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await lkppOrderService.getAll(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: lkppOrderConstants.GETALL_REQUEST, } }
    function success(lkppOrder) { return { type: lkppOrderConstants.GETALL_SUCCESS, lkppOrder } }
    function failure(error) { return { type: lkppOrderConstants.GETALL_FAILURE, error } }
}

function createData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await lkppOrderService.createData(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Completed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: lkppOrderConstants.REGISTER_REQUEST, } }
    function success(lkppOrder) { return { type: lkppOrderConstants.REGISTER_SUCCESS, lkppOrder } }
    function failure(error) { return { type: lkppOrderConstants.REGISTER_FAILURE, error } }
}

function addData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await lkppOrderService.addData(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Completed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: lkppOrderConstants.REGISTER_REQUEST, } }
    function success(lkppOrder) { return { type: lkppOrderConstants.REGISTER_SUCCESS, lkppOrder } }
    function failure(error) { return { type: lkppOrderConstants.REGISTER_FAILURE, error } }
}

function updateData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await lkppOrderService.updateData(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        }
    };

    function request() { return { type: lkppOrderConstants.UPDATE_REQUEST, } }
    function success(lkppOrder) { return { type: lkppOrderConstants.UPDATE_SUCCESS, lkppOrder } }
    function failure(error) { return { type: lkppOrderConstants.UPDATE_FAILURE, error } }
}
function cancelData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await lkppOrderService.cancelData(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        }
    };

    function request() { return { type: lkppOrderConstants.UPDATE_REQUEST, } }
    function success(lkppOrder) { return { type: lkppOrderConstants.UPDATE_SUCCESS, lkppOrder } }
    function failure(error) { return { type: lkppOrderConstants.UPDATE_FAILURE, error } }
}

function resetData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await lkppOrderService.deactivateData(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`Delete Success`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        }
    };

    function request() { return { type: lkppOrderConstants.UPDATE_REQUEST, } }
    function success(lkppOrder) { return { type: lkppOrderConstants.UPDATE_SUCCESS, lkppOrder } }
    function failure(error) { return { type: lkppOrderConstants.UPDATE_FAILURE, error } }
}