
import { combineReducers } from 'redux';
import { selected, selectedDetail } from './select.reducer';
import { aboutUs } from './aboutUs.reducer';
import { users, verify } from './users.reducer';
import { officeFitsOut } from './officeFitsOut.reducer';
import { ourProject, ourProjectDetail } from './ourProject.reducer'
import { terms } from './terms.reducer'
import { contactUs } from './contactUs.reducer'
import { profile, transaction, instance } from './profile.reducer'
import { product, detailProduct, popularProduct, homeBanner, getSize,getLimit } from './product.reducer'
import { subCategory } from './subCategory.reducer'
import { functionality } from './functionality.reducer'
import { range } from './range.reducer'
import { specification } from './specification.reducer'
import { category } from './category.reducer'
import { external } from './external.reducer'
import { cart } from './cart.reducer'
import { address } from './address.reducer'
import { lookups } from './lookup.reducer'
import { permission } from './permission.reducer'
import { authentication } from './authentication.reducer'
import { order } from './order.reducer'
import { shipping } from './shippingCost.reducer'
import { lkppOrder } from './lkppOrder.reducer'
import { negotiation } from './negotiation.reducer'
import { profileRetail, transactionRetail } from './profileRetail.reducer'
import { retailOrder } from './retailOrder.reducer'
import { gaido } from './gaido.reducer'
import { statusHistory } from './statusHistory.reducer'


const allReducers = combineReducers({
    order,
    shipping,
    selected,
    aboutUs,
    users,
    verify,
    officeFitsOut,
    ourProject,
    ourProjectDetail,
    terms,
    contactUs,
    profile,
    transaction,
    instance,
    product,
    detailProduct,
    popularProduct,
    homeBanner,
    getSize,
    subCategory,
    functionality,
    range,
    specification,
    category,
    external,
    cart,
    address,
    lookups,
    permission,
    authentication,
    negotiation,
    lkppOrder, 
    selectedDetail,
    getLimit,
    profileRetail,
    transactionRetail,
    retailOrder,
    gaido,
    statusHistory,
});

const rootReducer = (state, action) => {
    if (action.type === 'USERS_LOGOUT') {
        // const { } = state;
        state = {};
    }
    if (action.type === 'CLEAN_REST_DATA') {
        const {
            authentication,
        } = state;
        state = {
            authentication,
        };
    }

    return allReducers(state, action);
};

export default rootReducer;