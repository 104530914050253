import {Grid, TableRow, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { validateSign } from 'controllers/_validate'
import {
    useInput,
  } from 'hooks'
  import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/styles';
import { formatCurrency } from 'helpers';
import { useLanguage } from 'hooks';
function AddComponent(props) {
  const [language, lagDic, setLang] = useLanguage()
  const [handleFieldChange, values] = useInput(validateSign, {})
  const [openModal, setOpenModal] = useState(false)
  const [state, setState] = useState([]);
  const [list, setList] = useState([])
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleConfirm = () => {
      props.setList(values)
      props.close(false)
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F1F5F8",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#F1F5F8',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  // console.log(props?.negotiation,'negot')
  return (
    <Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h4'>Payment Detail</Typography>
        </Box>
        <Grid container direction="row" justifyContent="flex-end" sx={{ border: '1px dashed #B3BDCC', padding:'10px', background: '#F2F5F8'}}>
          <Grid item xl={2} lg={2} md={2} sm={6} xs={6} sx={{textAlign: 'right'}}>
            <Typography>{language.COMMON.MY_TRANSACTION_DETAIL.PAYMENT_METHOD}</Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={2} sm={6} xs={6} sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: 'bold'}}>Bank Mandiri - Transfer Bank</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" sx={{ border: '1px dashed #B3BDCC', padding:'10px'}}>
          <Grid item xl={2} lg={2} md={2} sm={6} xs={6} sx={{textAlign: 'right'}}>
            <Typography>Total Product Price</Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={2} sm={6} xs={6} sx={{textAlign: 'right'}}>
            <Typography>Rp {formatCurrency(Number(props?.data["PRICE_ITEMS"]))  ?? '-'}</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" sx={{ border: '1px dashed #B3BDCC', padding:'10px'}}>
          <Grid item xl={2} lg={2} md={2} sm={6} xs={6} sx={{textAlign: 'right'}}>
            <Typography>Shipping Cost</Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={2} sm={6} xs={6} sx={{textAlign: 'right'}}>
            <Typography>Rp {formatCurrency(Number(props?.data["PRICE_SHIPPING"]))  ?? '-'} </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" sx={{ border: '1px dashed #B3BDCC', padding:'10px', background: '#F2F5F8'}}>
          <Grid item xl={2} lg={2} md={2} sm={6} xs={6} sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: 'bold'}}>Total Payment</Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={2} sm={6} xs={6} sx={{textAlign: 'right'}}>
            <Typography variant='h5' sx={{fontWeight: 'bold'}}>Rp {formatCurrency(Number(props?.data["PRICE_FULL"])) ?? '-'} </Typography>
          </Grid>
        </Grid>
    </Box>
  )
}

export default AddComponent