const prod = [
  {
    id: 1,
    _key: 'YES',
    name: 'YES'
  },
  {
    id: 2,
    _key: 'NO',
    name: 'NO'
  }
]
export default function structuredefault(values, handleFieldChange, provinsiOptions, kabupatenOptions, lang) {
  const data = [
    {
      id: 'ADDRESS_NAME',
      label: lang.COMMON.MY_SHIPPING_ADDRESS.LABEL_ADDRESS,
      type: 'text',
      fieldtype: 'textfield',
      required: true,
      // disabled: true,
      placeholder: 'Insert Nama Alamat',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["ADDRESS_NAME"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'ADDRESS_PROVINSI',
      label: lang.COMMON.MY_SHIPPING_ADDRESS.PROVINCE,
      type: 'select',
      fieldtype: 'suggestfieldArray',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["ADDRESS_PROVINSI"] ?? '',
      minWidth: 170,
      hide: false,
      option: provinsiOptions?.length === 0 ? [] : provinsiOptions,
      placeholder: 'Select Provinsi',
      required: true,
    },
    {
      id: 'ADDRESS_KABUPATEN',
      label: lang.COMMON.MY_SHIPPING_ADDRESS.CITY_DISTRICT,
      type: 'select',
      fieldtype: 'suggestfieldArray',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["ADDRESS_KABUPATEN"] ?? '',
      minWidth: 170,
      hide: false,
      option: kabupatenOptions?.length === 0 ? [] : kabupatenOptions,
      placeholder: 'Select Kota/Kabupaten',
      required: true,
    },
    {
      id: 'ZIPCODE',
      label: lang.COMMON.CHANGE_ADDRESS.POSTAL_CODE,
      type: 'number',
      fieldtype: 'textfield',
      required: true,
      // disabled: true,
      placeholder: 'Insert Zipcode',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["ZIPCODE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'ADDRESS',
      label: lang.COMMON.MY_SHIPPING_ADDRESS.FULL_ADDRESS,
      type: 'text',
      fieldtype: 'textArea',
      // disabled: true,
      required: true,
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["ADDRESS"] || '',
      minWidth: 170,
      // hide: isHide
      placeholder: 'Insert Address',
      required: true,
    },

    {
      id: 'IS_CURRENT',
      label: 'Jadikan Alamat Utama',
      type: 'select',
      fieldtype: 'suggestfield',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["IS_CURRENT"] ?? '',
      minWidth: 170,
      hide: false,
      option: prod?.length === 0 ? [] : prod?.map(data => {
        return {
          id: data.id,
          value: data._key,
          label: data.name,
          data: data
        }
      }),
      placeholder: 'Select Jadikan Alamat Utama',
    },
  ]

  return data
};