const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export function structuredefault(values, handleFieldChange, open, categoryOption, language) {
  const data = [
    {
      id: 'VENDOR_NAME',
      label: language.COMMON.MY_TRANSACTION_DETAIL.NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_NAME"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_NPWP',
      label: language.COMMON.MY_TRANSACTION_DETAIL.NPWP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert NPWP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_NPWP"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_ADDRESS',
      label: language.COMMON.MY_TRANSACTION_DETAIL.ADDRESS,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Address',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_ADDRESS"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_WEBSITE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.WEBSITE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Website',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_WEBSITE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_EMAIL',
      label: language.COMMON.MY_TRANSACTION_DETAIL.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Email',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_EMAIL"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_PHONE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.PHONE_NO,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Phone Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_PHONE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_FAX',
      label: language.COMMON.MY_TRANSACTION_DETAIL.FAX,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Fax',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_FAX"] || '',
      minWidth: 170,
      // hide: isHide
    },

  ]

  return data
};
export function structuredefault2(values, handleFieldChange, open, categoryOption, language) {
  const data = [
    {
      id: 'VENDOR_NAME',
      label: language.COMMON.MY_TRANSACTION_DETAIL.NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_NAME"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_NPWP',
      label: language.COMMON.MY_TRANSACTION_DETAIL.NPWP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert NPWP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_NPWP"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_ADDRESS',
      label: language.COMMON.MY_TRANSACTION_DETAIL.ADDRESS,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Address',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_ADDRESS"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_WEBSITE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.WEBSITE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Website',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_WEBSITE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_EMAIL',
      label: language.COMMON.MY_TRANSACTION_DETAIL.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Email',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_EMAIL"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_PHONE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.PHONE_NO,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Phone Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_PHONE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'VENDOR_FAX',
      label: language.COMMON.MY_TRANSACTION_DETAIL.FAX,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Fax',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["VENDOR_FAX"] || '',
      minWidth: 170,
      // hide: isHide
    },

  ]

  return data
};