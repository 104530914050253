import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'
import Home from '@mui/icons-material/Home';
import { Skeleton } from '@mui/material';
import useStyles from './styles'
import underConstructionsImage from 'assets/images/underConstructions.svg'
import { useNavigate } from 'react-router'
// import underConstructionsImage from 'assets/images/banner.png'
export default function Example(props) {
    const {
        rows
    } = props
    console.log("🚀 ~ file: index.jsx ~ line 11 ~ props", props)
    const classes = useStyles()
    return (
        <Carousel
            className={classes.container}
            // IndicatorIcon={<Home/>} // Previous Example
            indicatorIconButtonProps={{
                style: {
                    cursor: "pointer",    // 1
                    color: "#afafaf"       // 3
                }
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    color: "#494949" // 2
                }
            }}
            // indicators={false}
            // indicatorContainerProps={{
            //     style: {
            //         marginTop: '69vh', // 5
            //         textAlign: 'center' // 4
            //     }

            // }}
            // {
            //     indicators: {
            //         width: "100%",
            //         marginTop: "10px",
            //         textAlign: "center"
            //     },
            //     indicator: {
            //         cursor: "pointer",
            //         transition: "200ms",
            //         padding: 0,
            //         color: "#afafaf",
            //         '&:hover': {
            //             color: "#1f1f1f"
            //         },
            //         '&:active': {
            //             color: "#1f1f1f"
            //         }
            //     },
            //     indicatorIcon: {
            //         fontSize: "15px",
            //     },
            //     // Applies to the active indicator
            //     active: {           
            //         color: "#494949"
            //     }
            // }
            navButtonsAlwaysVisible
            animation='slide'
            duration={500}
        // next={ (next, active) => console.log(`we left ${active}, and are now at ${next}`) }
        // prev={ (prev, active) => console.log(`we left ${active}, and are now at ${prev}`) }
        >
            {
                rows.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    )
}

function Item(props) {
    console.log("🚀 ~ file: index.jsx ~ line 67 ~ Item ~ props", props)
    const navigate = useNavigate()
    const baseUrlImage = window._env_?.BASEURL_IMAGE
    const classes = useStyles()
    console.log(`${baseUrlImage}${props?.item?.VALUE_05}${props?.item?.VALUE_04}`)
    return (
        <div className={classes.item}>
            {props?.item?.VALUE_03 === "" ?
                <img
                    // src={props.item.image}
                    src={`${baseUrlImage}${props?.item?.VALUE_05}${props?.item?.VALUE_04}`}
                    style={{ objectFit: 'contain' }}
                    alt="" className={classes.itemImg}
                // onClick={() => { navigate(`${props?.item?.VALUE_03}`) }}
                />
                :
                <a href={props?.item?.VALUE_03} target="_blank">

                    <img
                        // src={props.item.image}
                        src={`${baseUrlImage}${props?.item?.VALUE_05}${props?.item?.VALUE_04}`}
                        style={{ objectFit: 'contain', cursor: 'pointer' }}
                        alt="" className={classes.itemImg}
                    // onClick={() => { navigate(`${props?.item?.VALUE_03}`) }}
                    />
                </a>
            }
            {/* <Skeleton animation="wave" variant="rectangular" style={{position: 'absolute', width: '100%', height: '50vh'}} /> */}
            {/* <h1 className={classes.itemText} >This Website Is Under Construction!</h1> */}
        </div>
    )
}