const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export function structuredefault(values, handleFieldChange, open, categoryOption, lang) {
  const data = [
    {
      id: 'NameVendor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["NameVendor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'NPWPVendor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.NPWP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert NPWP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["NPWPVendor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'AddressVendor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.ADDRESS,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Address',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["AddressVendor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'WebsiteVendor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.WEBSITE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Website',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["WebsiteVendor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'EmailVendor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Email',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["EmailVendor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Phone NumberVendor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.PHONE_NO,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Phone Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Phone NumberVendor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'FaxVendor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.FAX,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Fax',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["FaxVendor"] || '',
      minWidth: 170,
      // hide: isHide
    },

  ]

  return data
};
export function structuredefault2(values, handleFieldChange, open, categoryOption, lang) {
  const data = [
    {
      id: 'NameDistributor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["NameDistributor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'NPWPDistributor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.NPWP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert NPWP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["NPWPDistributor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'AddressDistributor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.ADDRESS,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Address',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["AddressDistributor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'WebsiteDistributor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.WEBSITE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Website',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["WebsiteDistributor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'EmailDistributor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Email',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["EmailDistributor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Phone NumberDistributor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.PHONE_NO,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Phone Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Phone NumberDistributor"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'FaxDistributor',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.FAX,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Fax',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["FaxDistributor"] || '',
      minWidth: 170,
      // hide: isHide
    },
  ]

  return data
};