import React, { useState, useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BasicBreadcrumbs(props) {
  const {
    open,
    setOpen,
    severity,
    message
  } = props

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={3000} onClose={() => { setOpen(false) }}>
      <Alert onClose={handleClose} severity={severity ? severity : "success"} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
