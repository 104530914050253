import { productConstants } from '../_constants';

export function product(state = {}, action) {
    switch (action.type) {
        case productConstants.GETALL_PRODUCT_REQUEST:
            return {
                loading: true
            };
        case productConstants.GETALL_PRODUCT_SUCCESS:
            return {
                items: action.product
            };
        case productConstants.GETALL_PRODUCT_FAILURE:
            return {
                error: action.error
            };
        case productConstants.GETALL_SALE_REQUEST:
            return {
                loading: true
            };
        case productConstants.GETALL_SALE_SUCCESS:
            return {
                items: action.product
            };
        case productConstants.GETALL_SALE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function detailProduct(state = {}, action) {
    switch (action.type) {
        case productConstants.GETALL_DETAIL_PRODUCT_REQUEST:
            return {
                loading: true
            };
        case productConstants.GETALL_DETAIL_PRODUCT_SUCCESS:
            return {
                items: action.product
            };
        case productConstants.GETALL_DETAIL_PRODUCT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function popularProduct(state = {}, action) {
    switch (action.type) {
        case productConstants.GETALL_POPULAR_PRODUCT_REQUEST:
            return {
                loading: true
            };
        case productConstants.GETALL_POPULAR_PRODUCT_SUCCESS:
            return {
                items: action.product
            };
        case productConstants.GETALL_POPULAR_PRODUCT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function homeBanner(state = {}, action) {
    switch (action.type) {
        case productConstants.GETALL_BANNER_REQUEST:
            return {
                loading: true
            };
        case productConstants.GETALL_BANNER_SUCCESS:
            return {
                items: action.product
            };
        case productConstants.GETALL_BANNER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function getSize(state = {}, action) {
    switch (action.type) {
        case productConstants.GETALL_SIZE_REQUEST:
            return {
                loading: true
            };
        case productConstants.GETALL_SIZE_SUCCESS:
            return {
                items: action.product
            };
        case productConstants.GETALL_SIZE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function getLimit(state = {}, action) {
    switch (action.type) {
        case productConstants.GETALL_LIMIT_REQUEST:
            return {
                loading: true
            };
        case productConstants.GETALL_LIMIT_SUCCESS:
            return {
                items: action.product
            };
        case productConstants.GETALL_LIMIT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}