import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import parse from 'html-react-parser';
import noImage from "assets/images/noImage.jpeg"
// import useStyles from './styles'
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CardActionArea,
  CardMedia
} from '@mui/material'
import { BreadCrumbs } from 'components'
import { useNavigate } from 'react-router'
import { useLanguage } from 'hooks';
const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },

}))

const breadcrumb = [
  {
    title: "Home", to: "/furnitur"
  }
]


function ListStep(props) {
  window.scrollTo(0, 0);                                         // Auto Scroll to Top

  const {
    titlePage,
    type,
    buttonTitle,
    changeStep,
    step,
    data,
    loading,
    error,
    selected,
    setSelected,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    noCrud,
    checkingApprove,
    filter,
    setFilter,
    handleSubmit
  } = props
  const navigate = useNavigate()
  const classes = useStyles()
  const baseUrlImage = window._env_?.BASEURL_IMAGE
  const [language, lagDic, setLang] = useLanguage()

  // const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div style={{ paddingBottom: 20 }}>
          <BreadCrumbs
            breadcrumb={breadcrumb}
            current={language.COMMON.MENU.OUR_PROJECT}
          />
        </div>
        <Typography variant="body1" color="initial"><strong>{language.COMMON.MENU.OUR_PROJECT}</strong></Typography>
        <Grid container sx={{ paddingTop: 2 }} spacing={2}>
          {data.map((item, index) => {
            //    console.log("🚀 ~ file: list.jsx ~ line 78 ~ {data.map ~ item2", item)
            // console.log("🚀 ~ file: list.jsx ~ line 78 ~ {data.map ~ item", `${baseUrlImage}${item?.EXTRA_IMAGES?.[0]?.IMG_PATH}${item?.EXTRA_IMAGES?.[0]?.IMG_MAIN}`)

            return (
              <Grid item lg={4} sm={12}>
                <Card >
                  <CardActionArea onClick={(e) => handleSubmit(item, 'detail')}>
                    <CardMedia
                      component="img"
                      sx={{ width: '100%', height: 250, objectFit: 'contain' }}
                      src={`${baseUrlImage}${item?.EXTRA_IMAGES?.[0]?.IMG_PATH}${item?.EXTRA_IMAGES?.[0]?.IMG_THUMB}`}
                      onError={event => {
                        event.target.src = noImage
                        event.onerror = null
                      }}
                      // image="https://www.rukita.co/stories/wp-content/uploads/2020/01/coworking-space-in-jakarta-1360x907.jpg"
                      alt={``}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {item?.VALUE_02}
                      </Typography>
                      {/* <Typography noWrap variant="body2" color="text.secondary">
                        {parse(`${item?.VALUE_03}`)}
                      </Typography> */}
                    </CardContent>
                  </CardActionArea>
                  {/* <CardContent>
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      src={`${baseUrlImage}${item?.EXTRA_IMAGES?.[0]?.IMG_PATH}${item?.EXTRA_IMAGES?.[0]?.IMG_MAIN}`}
                      alt="" />
                    <Typography variant="body1">
                      {item?.VALUE_02}
                    </Typography>
                  </CardContent> */}
                  <CardActions>
                    <Button
                      // onClick={() => { navigate('/our-project/detail') }} 
                      onClick={(e) => handleSubmit(item, 'detail')}
                      color='info' size="small">{language.COMMON.PROJECT.READ}</Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          }
          )}

        </Grid>

      </div>
    </React.Fragment>

  );
}

export default ListStep;
