const dashboard = {
  TITLE: 'TOKEN',
  DESC: 'Masuk dengan akun Anda yang terdaftar',
  HEADER: {
    HEADER_1: 'Halo,',
    MY_ACCOUNT: 'Rekening saya',
  },
  LABEL: {
    TRANSACTION: 'Transaksi',
    TODAY_TRANSACTION: 'Transaksi hari ini',
    OLDER_TRANSACTION: 'Transaksi yang lebih lama',
    TOTAL_TASK: 'Total Tugas Transaksi',
    TOTAL_REVIEW: 'Total Tertunda Untuk ditinjau',
    TOTAL_APPROVE: 'Total Tertunda Untuk disetujui',
    TOTAL_TRANSFER_TASK: 'Total Tugas Transfer',
    TOTAL_PAYROLL_TASK: 'Total Tugas Penggajian',
    TOTAL_PAYMENT_TASK: 'Total Tugas Pembayaran',
    TOTAL_CANCEL_UPCOMING_TASK: 'Total Tugas Pembatalan Transaksi Mendatang',
    TOTAL_CANCEL_SCHEDULER_TASK: 'Total Tugas Pembatalan Transaksi Mendatang',
    HELLO1_NAME: 'User Name',
    REFRESH_DATA: 'Perbarui Info Rekening',
    FULL_NAME: 'Nama Lengkap',
    EDIT_DATA: 'Ubah',
    DELETE_DATA: 'Hapus',
    INFO_DATA: 'Informasi',
    ACCOUNT_NUMBER: 'Nomor Rekening',
    ACCOUNT_TYPE: 'Jenis Rekening',
    BALANCE: 'Saldo',
    ARRANGEMENT_ID: 'Arrangement ID',
    TIME_PERIOD: 'Periode Waktu',
    PRODUCT_NAME: 'Nama Produk',
    INTEREST: 'Bunga',
    EFFECTIVE_DATE: 'Tanggal Efektif',
    LOAN_NUMBER: 'Nomor Pinjaman',
    LOAN_VALUE: 'Nilai Pinjaman',
    REMAINDER: 'Sisa',
    DUE_DATE: 'Jatuh Tempo',
    INSTALLMENTS: 'Angsuran',
    NEXT_PAYMENT: 'Pembayaran Selanjutnya',
    BUTTON_PRINT_RECEIPT: 'Cetak',
  },
  PLACEHOLDER: {
    HELLO1_NAME: 'User Name',
    FULL_NAME: 'Nama Lengkap',
    ACCOUNT_NUMBER: 'Nomor Rekening',
    ACCOUNT_TYPE: 'Jenis Rekening',
    BALANCE: 'Saldo',
  },
  INFO: {
    THANKS: 'Terima Kasih',
    ALL_RIGHT_RESERVED: 'Hak Cipta Internet Business Banking oleh Bank Capital Dilindungi Undang-Undang',
  },
}

export default dashboard