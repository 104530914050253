
export default function requestMiddleware({ dispatch, getState }) {
  return next => action => {
    // console.log("Action to do", action)

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)
    const token = getState().authentication?.user?.token
    console.log('state token after dispatch', token)
    // console.log('state data user after dispatch', getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}