import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { validateSign } from 'controllers/_validate'
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import clarityLogo from 'assets/images/logo.png'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router'
import { useLogin, useInput, useLanguage, useUsers } from 'hooks'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, InputAdornment } from '@mui/material';
import { HandlerModal } from 'components';
import useStyles from './styles';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export default function LoginDialog(props) {
  const [language, lagDic, setLang] = useLanguage()
  const classes = useStyles()
  const navigate = useNavigate()
  const [setValues, values, setValidation, errors] = useInput(validateSign)

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  console.log("🚀 ~ file: index.jsx ~ line 34 ~ LoginDialog ~ error", error)
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("Error");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [setLogin] = useLogin(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage, navigate)
  const [datas, setDatas, makeDatas] = useUsers(setIsLoading, setError, setOpen3, setDialogType, setTitle, setMessage, navigate)

  const {
    open,
    setOpen,
    handleRegister
  } = props
  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose4 = () => {
    setOpen4(false);
    setOpen2(false);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  function handleSubmit() {
    setLogin(values, "login");
    handleClose();
  };

  function handlePasswordVisible() {
    setPasswordVisible(!passwordVisible)
  };

  useEffect(() => {
    if (error == null) {

    } else {
      setOpen(false)
    }
  }, [error])
  const [label, setLabel] = useState({
    positive: "Close",
    negative: "Close",
  })
  const [onAction, setOnAction] = useState({
    onClose: () => handleClose3(),
    positive: (body, type) => handleClose3(body, type),
    negative: () => handleClose3()
  })
  const [isForgotPass, setIsForgotPass] = useState(false)
  useEffect(() => {
    if (isForgotPass && values?.username) {
      makeDatas(values, 'reset')
      setIsForgotPass(false)
    }
  }, [isForgotPass, values])

  function handleSumbitForgot() {
    setIsForgotPass(true)
  }
  const handlePasswordSubmit = (e, val) => {
    if (val.confirmPassword?.length > 0 || val.newPassword?.length > 0 || errors.newPassword || errors.confirmPassword) {
      e.preventDefault()
      const newValues = { ...val }
      const param = {
        password: newValues.confirmPassword ?? "",
        oldPassword: newValues.password ?? "",
        username: newValues.username ?? ""
      }
      console.log("🚀 ~ file: index.jsx ~ line 91 ~ handlePasswordSubmit ~ param", param, values)
      setLogin(param, "NewUser")
    }
    setError("Password not complete")
  }
  const handleForgotPassword = () => {
    setIsLoading(false)
    setError(null)
    setDialogType("ConfirmationApprove")
    setTitle("Forget Password")
    setOpen3(true)
    setOpen(false)
  }
  const handleClose3 = () => {
    setOpen3(false);
    setOpen2(false);
  };

  useEffect(() => {
    if (open2 === true) {
      setOpen5(true)
    } else {
      setOpen5(false)
    }

  }, [open2])


  React.useEffect(() => {
    if (dialogType === 'NewUser') {
      setLabel({
        positive: "Submit",
        negative: "Close",
      })
      setOnAction({
        onClose: () => handleClose4(),
        positive: (e, value) => handlePasswordSubmit(e, value),
        negative: () => handleClose4(),
        onChange: (name, value) => setValues(name, value),
        setValidation: (val) => setValidation(val)
      })
    } else if (dialogType === 'ConfirmationApprove') {
      setLabel({
        positive: "Submit",
        negative: "Close",
      })
      setOnAction({
        onClose: () => handleClose3(),
        positive: (e, value) => handleSumbitForgot(),
        negative: () => handleClose3(),
        onChange: (name, value) => setValues(name, value),
        setValidation: (val) => setValidation(val)
      })
    } else if (dialogType === 'Success') {
      setLabel({
        positive: "Submit",
        negative: "Close",
      })
      setOnAction({
        onClose: () => handleClose3(),
        positive: (e, value) => handleSumbitForgot(),
        negative: () => handleClose3(),
        onChange: (name, value) => setValues(name, value),
        setValidation: (val) => setValidation(val)
      })
    }
    else {
      setLabel({
        positive: "Close",
        negative: "Close",
      })
      setOnAction({
        onClose: () => handleClose4(),
        positive: (body, type) => handleClose4(body, type),
        negative: () => handleClose4(),
        onChange: (name, value) => setValues(name, value)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogType])
  const bodyForgotPassord = () => {
    return (
      <div className={classes.fields}>
        <FormControl className={classes.form}>
          <Typography className={classes.fieldLabel}>{"Please enter a username associated to your account"}</Typography>
          <TextField
            name="username"
            autoComplete="username"
            onChange={event => {
              setError(null);
              setValues('username', event.target.value)
            }}
            onBlur={(e) => setValidation(values)}
            required
            disabled={isLoading}
            inputProps={{
              style: {
                backgroundColor: "#fff"
              },
            }}
            InputLabelProps={{
              style: { marginTop: 10 }
            }}
            fullWidth
            type="text"
            value={values.username}
            variant="outlined"
            autoFocus
          />
        </FormControl>
        <div className={classes.validateError}>
          {!errors.username ? null : (
            <Typography
              className={classes.fieldError}
              variant="caption"
            >
              {errors.username}
            </Typography>
          )}
        </div>
      </div>
    )
  }
  return (
    <div>
      <HandlerModal
        open={open2}
        onClose={handleClose2}
        body={bodyForgotPassord}
        dialogType={dialogType}
        message={message}
        title={title}
        label={label}
        action={onAction}
        error={error}
        fieldError={errors}
        loading={isLoading}
        values={values}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <HandlerModal
        open={open3}
        onClose={handleClose3}
        body={bodyForgotPassord}
        dialogType={dialogType}
        message={message}
        title={title}
        label={label}
        action={onAction}
        error={error}
        fieldError={errors}
        loading={isLoading}
        values={values}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <HandlerModal
        open={open4}
        onClose={handleClose4}
        body={bodyForgotPassord}
        dialogType={dialogType}
        message={message}
        title={title}
        label={label}
        action={onAction}
        error={error}
        fieldError={errors}
        loading={isLoading}
        values={values}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open5} autoHideDuration={3000} onClose={() => { setOpen5(false) }}>
        <Alert onClose={handleClose5} severity={error ? "warning" : "success"} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {props?.isAddToChart ?
        <Box sx={{padding: "10px"}}>
          <Typography align='center' variant='h4' fontWeight={"bold"} color={"red"}>Penambahan Produk Tidak Dapat Dilakukan</Typography>
          <Typography align='center' variant='body1'>Masuklah kedalam account anda terlebih dahulu untuk dapat menambahkan produk kedalam keranjang</Typography>
        </Box>
        :
        <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: 40, paddingRight: 40 }}>
          <div>
            <img style={{ width: '220px', paddingTop: 30, height: 'auto' }} src={clarityLogo} alt="" />

          </div>
        </div>
        }
        <DialogTitle id="alert-dialog-title">
          <strong>
            {language.COMMON.LOGIN.MASUK}
          </strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ paddingBottom: 3 }} id="alert-dialog-description">
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <FormLabel component="legend">{language.COMMON.LOGIN.EMAIL}</FormLabel>
              <TextField
                fullWidth
                id="Email"
                size='small'
                onChange={event => {
                  setError(null);
                  setValues('email', event.target.value)
                }}
                onBlur={(e) => setValidation(values)}
                required
                disabled={isLoading}
                value={values.email}
                autoFocus
                placeholder='Enter your email'
                name='Email'
              />
              <FormLabel sx={{ paddingTop: 1 }} component="legend">{language.COMMON.LOGIN.PASSWORD}</FormLabel>
              <TextField
                fullWidth
                id="Password"
                type={!passwordVisible ? 'password' : 'text'}
                onChange={event => {
                  setError(null);
                  setValues('password', event.target.value)
                }}
                onBlur={(e) => setValidation(values)}
                required
                disabled={isLoading}
                value={values.password}
                autoFocus
                size='small'
                placeholder='Enter your password'
                name='password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" onClick={handlePasswordVisible} sx={{ cursor: 'pointer' }}>
                      {!passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            <Link sx={{ cursor: 'pointer' }} color='#1070ca' onClick={handleForgotPassword}> {language.COMMON.LOGIN.FORGOT}</Link>
          </DialogContentText>
          <Button fullWidth variant='contained' color='info' onClick={handleSubmit} autoFocus>
            {language.COMMON.LOGIN.LOGIN}
          </Button>
          <Typography variant="body1" color="initial">{language.COMMON.LOGIN.DONT_HAVE_ACCOUNT}
            <Link sx={{ cursor: 'pointer' }} color='#1070ca' onClick={handleRegister}> {language.COMMON.LOGIN.REGISTER_HERE}</Link>
          </Typography>
        </DialogContent>
      </Dialog>
    </div >
  );
}
