const bulkTransfer = {
  TITLE: 'LOGIN',
  DESC: 'Masuk dengan akun Anda yang terdaftar',
  HEADER: {
    HEADER_1: 'Status Transaksi Bulk Transfer',
    HEADER_2: 'Daftar Gaji - Generate Checksum',
    HEADER_3: 'Bulk Transfer - Unggah',
    HEADER_4: 'Bulk Transfer - Konfirmasi Unggah',
    HEADER_5: 'Bulk Transfer - Hasil Unggah',
    TARGET_LIST: 'Daftar Tujuan Bulk Transfer Anda',
    INQUIRY_ADD_TARGET_LIST: 'Tambah Daftar Bulk Transfer',
    INQUIRY_ADD_TARGET: 'Tambah Data Karyawan',
    INQUIRY_EDIT_TARGET: 'Ubah Data Karyawan',
    INQUIRY_ADD_BUNDLE: 'Tambah Data Paket',
    INQUIRY_EDIT_BUNDLE: 'Ubah Data Paket',
    INQUIRY_DETAIL_BUNDLE: 'Detail Data Paket',
    BULK_TRANSFER_DETAIL: 'Detail Daftar Bulk Transfer',
    BULK_TRANSFER_TARGET: 'Tujuan Bulk Transfer',
    EDIT_TARGET_LIST: 'Edit Daftar Tujuan Bulk Transfer',
    NOTE: 'Catatan : ',
    BULK_TRANSFER: 'Bulk Transfer',
    BULK_TRANSFER_CONFIRM: 'Konfirmasi Bulk Transfer',
    BULK_TRANSFER_RESULT: 'Hasil Bulk Transfer',
    BULK_TRANSFER_HISTORY: 'Riwayat Bulk Transfer',
    BULK_TRANSFER_SCHEDULE: 'Bulk Transfer Terjadwal',
    CANCEL_SCHEDULE: 'Batalkan Bulk Transfer Terjadwal',
    DELETE_GROUP: 'Hapus Daftar Bulk Transfer',
    PROGRAM_DETAIL: 'Daftar Auto Debit',
  },
  LABEL: {
    SOURCE_FUND: 'Source Of Fund',
    ACCOUNT_NUMBER: 'Nomor Rekening',
    ACTION_HISTORY: 'Riwayat Aksi',
    BUTTON_DOWNLOAD: 'Unduh Contoh File',
    DESCRIPTION: 'Deskripsi',
    EFFECTIVE_DATE: 'Tanggal Efektif',
    RECORD: 'Total Karyawan',
    TOTAL_AMOUNT: 'Total Nominal',
    STATUS: 'Status',
    AUTODEBET: 'Autodebet',
    DOWNLOAD: 'Unduh',
    FILE_BULK_TRANSFER: 'File Daftar Bulk Transfer',
    BUTTON_GENERATE: 'Generate',
    CHECKSUM: 'Checksum',
    COPY: 'Salin',
    BUTTON_SUBMIT: 'Kirim',
    SOURCE_FUND: 'Sumber Dana',
    RECURRING_NEXT_DATE: 'Tanggal Berulang Berikutnya',
    TOTAL_RECORD: 'Total Karyawan',
    REF_NUMBER: 'No. Ref',
    BUTTON_BACK: 'Kembali',
    BULK_TRANSFER_NAME: 'Nama Bulk Transfer',
    CREATED_TIME: 'Tgl Pembuatan',
    BULK_TRANSFER_TYPE: 'Jenis Bulk Transfer',
    MEMBERS: 'Anggota',
    ACTIONS: 'Aksi',
    BUTTON_ADD_TARGET_LIST: 'Tambahkan Daftar Bulk Transfer',
    BUTTON_ADD_TRANSFER_LIST: 'Tambahkan Bulk Transfer',
    BUTTON_ADD_TRANSFER: 'Tambahkan Bulk Transfer',
    TARGET_LIST: 'Daftar Tujuan Bulk Transfer',
    TARGET: 'Tujuan Bulk Transfer',
    BULK_TRANSFER_DETAIL: 'Detail Bulk Transfer',
    ACCOUNT_BANK: 'Akun Bank',
    ACCOUNT_NAME: 'Nama Akun',
    CUSTOMER_ID: 'ID Nasabah',
    UPLOAD_STATUS: 'Status Upload Terakhir',
    AMOUNT: 'Nominal',
    ADD_NEW_TARGET: 'Tambah Karyawan',
    EXPORT_FILE_EMPLOYEE: 'Download Data Karyawan',
    UPLOAD_FILE: 'Unggah Data Karyawan',
    BUTTON_BACK2: 'Kembali',
    BULK_TRANSFER_UPLOAD: 'Unggah Target Bulk Transfer.',
    EXAMPLE_FILE: 'Contoh File',
    LAST_MODIFIED: 'Waktu Update Terakhir',
    SUCCESS_BULK_TRANSFER: 'Bulk Transfer Sukses',
    BULK_TRANSFER_DATE: 'Tanggal Bulk Transfer',
    ADMIN_FEE: 'Biaya admin ke',
    DATE_TIME: 'Tanggal / Waktu',
    MAKER: 'Maker',
    REVIEWER: 'Reviewer',
    APPROVER: 'Approver',
    TRANSFER_TYPE: 'Jenis Transfer',
    SCHEDULED_TYPE: 'Jenis Jadwal',
    NEAREST_SCHEDULED_DATE: 'Tanggal Transaksi Terdekat',
    BUNDLE_ACTIVE: 'Auto Debit Aktif',
    ADD_NEW_PROGRAM: 'Tambah Auto Debit',
  },
  PLACEHOLDER: {
    FILE_BULK_TRANSFER_1: 'Klik Atau Geser File Kesini Untuk Upload File Daftar Bulk Transfer',
    FILE_BULK_TRANSFER_2: 'Hanya menerima format file .xlsx',
    SOURCE_FUND: 'Pilih sumber dana anda',
    DESCRIPTION: 'Masukan Deskripsi',
    CHECKSUM: 'Masukan File Checksum',
    RECURRING_NEXT_DATE: 'Tanggal Berulang Berikutnya',
    TOTAL_RECORD: 'Masukan Total Karyawan',
    TOTAL_AMOUNT: 'Nominal harus lebih dari 0',
    BULK_TRANSFER_NAME: 'Masukan nama Bulk Transfer',
    AMOUNT: 'Nominal',
  },
  INFO: {
    NO_DATA: `Tidak ada data untuk ditampilkan!`,
    NOTE1: 'Maksimal Bulk Transfer adalah 1000, jika sudah terdapat 3 data terdaftar, maka sisa yag dapat ditambahkan adalah 997.',
    NOTE2: 'Format dokumen yang digunakan adalah xlsx.',
    NOTE3: 'Penambahan massal akan mengganti daftar karyawan sebelumnya.',
    ADMIN_FEE: ' * Per Rekening',
  },
  MESSAGE: {
    DELETE: 'Apakah anda yakin akan menghapus'
  }
}

export default bulkTransfer