export const orderConstants = {
    GETALL_REQUEST: 'ORDER_GETALL_REQUEST',
    GETALL_SUCCESS: 'ORDER_GETALL_SUCCESS',
    GETALL_FAILURE: 'ORDER_GETALL_FAILURE',

    CREATE_REQUEST: 'ORDER_CREATE_REQUEST',
    CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
    CREATE_FAILURE: 'ORDER_CREATE_FAILURE',

    DELETE_REQUEST: 'ORDER_DELETE_REQUEST',
    DELETE_SUCCESS: 'ORDER_DELETE_SUCCESS',
    DELETE_FAILURE: 'ORDER_DELETE_FAILURE',

    UPDATE_REQUEST: 'ORDER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ORDER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ORDER_UPDATE_FAILURE'
};