const logout = {
  TITLE: 'LOGIN',
  DESC: 'Sign with your registred account',
  HEADER: {
    HEADER_1: 'Thank you for using internet Banking of Bank Capital Indonesia',
  },
  LABEL: {
    BUTTON_LOGIN: 'Login',
  },
  PLACEHOLDER: {},
  INFO: {},
}

export default logout