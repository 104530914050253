import { white, black } from '../colors';

const palette = {
  clarity:{
    contrastText: white,
    main : '#20376D',
    light: "#4A84AA"
  },
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4'
  },
  primary: {
    contrastText: white,
    dark: "#ae0025",
    main: "#2966C4",
    light: "#ff7679"
  },
  secondary: {
    contrastText: white,
    main: '#1d2438',
    light: '#454c62',
    dark: '#000013'
  },
  success: {
    contrastText: white,
    main: '#024F39',
    light: '#00AC4F',
    dark: '#F7FFFB'
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489'
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#885C00'
  },
  danger: {
    contrastText: white,
    main: '#ED6840',
    light: '#FFE5DD',
    dark: '#7E1D00'
  },
  text: {
    primary: '#212121',
    secondary: '#000000',
    disabled: '#000000'
  },
  background: {
    default: white,
    main: '#F7F5F7',
    dark: '#172B4D',
    paper: white
  },
  extended: {
    main: '#58595B',
    half: '#A7A9AC',
    border: '#E3E3E3',
    backgrounds: '#F7F7F8',
    white: '#ffffff'
  },
  customShadows: {
    widget:
      "-1px 6px 30px 9px rgba(49,48,48,0.3)",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  customGradient: {
    background: 'radial-gradient(circle, rgba(77, 146, 247, 1) 0%, rgba(5, 56, 156, 1) 100%)',
    main: 'linear-gradient(to right, rgba(159, 66, 75, 1), rgba(18, 9, 12, 1))',
    secondary: 'linear-gradient(180deg, rgba(59,146,211,1) 0%, rgba(20,63,117,1) 100%)',
    drawer: 'linear-gradient(to top, rgba(20, 27, 90, 0.9), rgba(255, 0, 34, 0.9))'
  },
  border: '#DFE3E8',
  divider: '#DFE3E8'
};

export default palette
