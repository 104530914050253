const tokenEn = {
  TITLE: 'TOKEN',
  DESC: 'Masuk dengan akun Anda yang terdaftar',
  HEADER: {
    HEADER_1: 'Kami telah mengirimkan Token ke email anda',
    HEADER_2: 'SIlahkan masukan Token di bawah ini',
  },
  LABEL: {
    INPUT_TOKEN: 'Masukan respon token AAPLI 1 (8 Digit)',
    BUTTON_SUBMIT: 'Kirimkan',
    RESEND_TOKEN: 'Kirim Ulang'
  },
  PLACEHOLDER: {
    INPUT_TOKEN: 'Masukan Token',
  },
  INFO: {
    INFO_1: 'Tidak menerima Token ?'
  },
}

export default tokenEn