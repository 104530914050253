import { Button, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import {InputComponent} from 'components'
import { validateSign } from 'controllers/_validate'
import {
    useInput,
  } from 'hooks'
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/styles';
import XLSX from 'xlsx'

function AddComponent(props) {
    const {list} = props
   const lengthShippingTotal = list?.SHIPPING?.length+5
   const lengthProduct = lengthShippingTotal + 2
   const lengthProductTotal = list?.PRODUCT?.length + lengthProduct + 1
   const totalPriceShipping = list?.NEW_TOTAL_SHIPPING 
   const totalPriceProduct = list?.NEW_TOTAL_PRODUCT
    const handleExport = async() => {
        await list
        let wb = XLSX.utils.book_new()
        let header = XLSX.utils.aoa_to_sheet([['Order ID',list?.ORDER_ID],['Revision ID',list?.REVISION_CODE]])
        // let ws = XLSX.utils.json_to_sheet(list?.PRODUCT)
        let shipping = XLSX.utils.sheet_add_json(header,list?.SHIPPING , { origin: `A4` });
        let shipping_total = XLSX.utils.sheet_add_aoa(shipping, [["Total Harga Ongkos Kirim",list?.PREV_TOTAL_SHIPPING,list?.NEW_TOTAL_SHIPPING ]] , { origin: `B${lengthShippingTotal}` });
        let product = XLSX.utils.sheet_add_json(shipping_total,list?.PRODUCT , { origin: `A${lengthProduct}` });
        let product_total = XLSX.utils.sheet_add_aoa(product, [["Total Harga Barang",'',list?.PREV_TOTAL_PRODUCT]] , { origin: `C${lengthProductTotal}` });
        // let product_total = XLSX.utils.sheet_add_aoa(product, [["Total Harga Barang",'',list?.PREV_TOTAL_PRODUCT,'',list?.NEW_TOTAL_PRODUCT]] , { origin: `C${lengthProductTotal}` });
        let total = XLSX.utils.sheet_add_aoa(product_total, [["Total Harga Ongkos Kirim",totalPriceShipping],["Total Harga Barang",totalPriceProduct],["Grand Total ",totalPriceProduct+totalPriceShipping]] , { origin: `B${lengthProductTotal+2}` });
     
        XLSX.utils.book_append_sheet(wb,total,'sheet1')
        await XLSX.writeFile(wb,"history.xlsx")
        props.close(false)
    }
  
    const handleConfirm = (e) => {
    }
  return (
    <Box sx={{width: 400}}>
      

        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h5'>Export Negotiation</Typography>
            <Button onClick={()=> {props?.close(false)}} endIcon={<CloseIcon></CloseIcon>}></Button>        
           </Box>
          
          <Box>
         
        </Box>
      <FormControl fullWidth sx={{mt: 2}}>
     
        <Button onClick={handleExport} variant='contained' color='primary'>EXPORT</Button>
      </FormControl>
     
    </Box>
  )
}

export default AddComponent