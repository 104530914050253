export const white = '#FFFFFF';

export const grey = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121'
};

export const black = '#000000';

export const blue = '#009CF9';

export const green = '#388E3C';

export const orange = '#FFB822';

export const red = '#EC4C49';

export const primary = {
  dark: "#143F75",
  main: "#1B8382",
  light: "#A7DCFF"
};

export const secondary = {
  dark: "#b8000d",
  main: "#F1A946",
  light: "#ff595d"
};

export const customGradient = {
  background: 'radial-gradient(circle, rgba(0,27,91,1) 0%, rgba(35,64,137,1) 100%)',
  main: 'linear-gradient(90deg, rgba(148,0,0,1) 0%, rgba(0,0,0,1) 100%)',
  secondary: 'linear-gradient(180deg, rgba(148,0,0,1) 0%, rgba(0,0,0,1) 100%)',
  mainReverse: 'linear-gradient(270deg, rgba(148,0,0,1) 0%, rgba(0,0,0,1) 100%)',
  linearLight: 'linear-gradient(180deg, rgba(148,0,0,0) 0%, rgba(255,255,255,1) 100%)',
  secondaryMain: 'linear-gradient(270deg, rgba(231,65,77,1) 0%, rgba(134,44,56,1) 100%)',
  mainDarktoDis: 'linear-gradient(180deg, rgba(148,0,0,1) 0%, rgba(148,0,0,0) 100%)'
};
