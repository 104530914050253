import { profileRetailConstants } from '../_constants';

export function profileRetail(state = {}, action) {
    switch (action.type) {
        case profileRetailConstants.GETALL_PROFILE_RETAIL_REQUEST:
            return {
                loading: true
            };
        case profileRetailConstants.GETALL_PROFILE_RETAIL_SUCCESS:
            return {
                items: action.profileRetail
            };
        case profileRetailConstants.GETALL_PROFILE_RETAIL_FAILURE:
            return {
                error: action.error
            };
        case profileRetailConstants.CREATE_REQUEST:
            return {
                loading: true
            };
        case profileRetailConstants.CREATE_SUCCESS:
            return {
                items: action.profile
            };
        case profileRetailConstants.CREATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function transactionRetail(state = {}, action) {
    switch (action.type) {
        case profileRetailConstants.GETALL_TRANSACTION_REQUEST:
            return {
                loading: true
            };
        case profileRetailConstants.GETALL_TRANSACTION_SUCCESS:
            return {
                items: action.transaction
            };
        case profileRetailConstants.GETALL_TRANSACTION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}