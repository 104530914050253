import { profileConstants } from '../_constants';
import { profileService } from '../_services';

export const profileActions = {
    getMyAccount,
    getMyTransaction,
    getInstance,
    createData,
    changePass,
    updateData,
};

function getMyAccount(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await profileService.getMyAccount(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: profileConstants.GETALL_PROFILE_REQUEST, } }
    function success(profile) { return { type: profileConstants.GETALL_PROFILE_SUCCESS, profile } }
    function failure(error) { return { type: profileConstants.GETALL_PROFILE_FAILURE, error } }
}
function getInstance(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await profileService.getInstance(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: profileConstants.GETALL_INSTANCE_REQUEST, } }
    function success(instance) { return { type: profileConstants.GETALL_INSTANCE_SUCCESS, instance } }
    function failure(error) { return { type: profileConstants.GETALL_INSTANCE_FAILURE, error } }
}

function changePass(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await profileService.changePass(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Success")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        }
    };

    function request() { return { type: profileConstants.UPDATE_REQUEST, } }
    function success(profile) { return { type: profileConstants.UPDATE_SUCCESS, profile } }
    function failure(error) { return { type: profileConstants.UPDATE_FAILURE, error } }
}

function getMyTransaction(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await profileService.getMyTransaction(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: profileConstants.GETALL_TRANSACTION_REQUEST, } }
    function success(profile) { return { type: profileConstants.GETALL_TRANSACTION_SUCCESS, profile } }
    function failure(error) { return { type: profileConstants.GETALL_TRANSACTION_FAILURE, error } }
}

function createData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await profileService.createData(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`Update data success`)
            setDialogType("Success")
            setTitle("Completed")
            setMessage(`Update data success`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: profileConstants.CREATE_REQUEST, } }
    function success(profile) { return { type: profileConstants.CREATE_SUCCESS, profile } }
    function failure(error) { return { type: profileConstants.CREATE_FAILURE, error } }
}

function updateData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await profileService.updateData(param)
        console.log(res)
        if (res?.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`Update Data Success`)
            setDialogType("Success")
            setTitle("Completed")
            setMessage(`Update Data Success`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: profileConstants.UPDATE_REQUEST, } }
    function success(profile) { return { type: profileConstants.UPDATE_SUCCESS, profile } }
    function failure(error) { return { type: profileConstants.UPDATE_FAILURE, error } }
}