import { aboutUsConstants } from '../_constants';

export function aboutUs(state = {}, action) {
    switch (action.type) {
        case aboutUsConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case aboutUsConstants.GETALL_SUCCESS:
            return {
                items: action.aboutUs
            };
        case aboutUsConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}