import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import { makeStyles } from '@mui/styles';
// import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import logoHonda from 'assets/images/logowhite.png'
import './spinner.css'
// import './texting.css'

const useStyles = makeStyles((theme) => ({
  root: {

  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    zIndex: theme.zIndex.modal + 1000,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  backdropContainer: {
    position: 'absolute',
  },
  label: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center'
    // marginLeft: '220px'
    // marginLeft: '50px',
  },
  font: {
    fontSize: '16px',
    color: theme.palette.secondary.main
    // fontWeight: 'bold'
  },
  fontChild: {
    fontSize: '12px',
    color: theme.palette.secondary.main
    // fontWeight: 'bold'
  },
  box: {
    // marginLeft: '170px',
    // borderTop: `2px solid ${theme.palette.common.white}`,
    // borderBottom: `2px solid ${theme.palette.common.white}`,
    // borderRadius: '20px',
    padding: theme.spacing(4),
    position: 'relative'
  },
  logoStyle: {
    height: '80px',
    width: 'auto',
    marginBottom: theme.spacing(3)
  },
  fullWidth: {
    width: '100%'
  }
}));

export default function BackDrop(props) {
  const {
    loading,
    label
  } = props
  const classes = useStyles();
  return (
    <Backdrop id="single" className={classes.backdrop} open={loading}>
      {/* <div id="strictloader">
        <div id="strictbox"></div>
        <div id="hill"></div>
      </div> */}
      <Grid container className={classes.box}>
        <Grid sx={{position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center'}} item container className={classes.label}>
          <img style={{ height: '100px',
    width: 'auto'}} src={logoHonda}  alt="spinner-logo" className={`${classes.logoStyle} fade-in`} />
          <Typography color="inherit" className={classes.font}>{label}</Typography>
          <Typography color="inherit" className={classes.fontChild}>please wait...</Typography>
        </Grid>
      </Grid>
    </Backdrop>
  )
}
