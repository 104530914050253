import { Button,Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import {InputComponent} from 'components'
import {structuredefault,structuredefault2} from './Schema/default'
import { validateSign } from 'controllers/_validate'
import {
    useInput,
  } from 'hooks'
  import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/styles';
function AddComponent(props) {
    const [handleFieldChange, values] = useInput(validateSign, {})
    const [state, setState] = useState([]);
  
    const handleConfirm = (e) => {
      e?.preventDefault()
      if(values?.SETUJU === 'SETUJU' && values?.WAKTU_PEMBAYARAN) {
        props.setList(values)
        props.close(false)
      }
    }
  return (
    <Box sx={{width: 420}}>
      <form onSubmit={handleConfirm}>

        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h5'>Approve Negotiation</Typography>
            <Button onClick={()=> {props?.close(false)}} endIcon={<CloseIcon></CloseIcon>}></Button>        
           </Box>
        
          <Box>
          <InputComponent
          structure={structuredefault2(values,handleFieldChange)}
          isLoading={props?.isLoading}
          error={props?.error}
          divider={1}
          padding={1}
          minScreen="md"></InputComponent>
          <Grid container spacing={1} sx={{
            background: '#EBF2FF', 
            border: '1px dashed #305FAC',
            width: '98%', ml:1, mt:1, p:1}}>
            <Grid item 
             xl={4}
             lg={4}
             md={4}
             sm={4}
             xs={4}>
                <Typography>Metode Pembayaran</Typography>
                <Typography>Nama Bank</Typography>
                <Typography>Atas Nama</Typography>
                <Typography>Nomor Rekening</Typography>
            </Grid>
            <Grid item 
             xl={8}
             lg={8}
             md={8}
             sm={8}
             xs={8}>
                <Typography>Bank Transfer</Typography>
                <Typography>BRI</Typography>
                <Typography>FURNI KARYA MANDARI</Typography>
                <Typography>1145-01-000858</Typography>
            </Grid>
          </Grid>
          <Typography sx={{textAlign: 'center', mt: 1}}>Ketik "SETUJU" lalu terkait tombol Approve untuk, menyetujui negosiasi</Typography>
          <InputComponent
            structure={structuredefault(values, handleFieldChange)}
            divider={1}
            padding={1}
            minScreen="md"
            // readOnlyAll
            />
        </Box>
      <FormControl fullWidth sx={{mt: 2}}>
        <Button variant='contained' color='primary' type='submit'>APPROVE</Button>
      </FormControl>
            </form>

    </Box>
  )
}

export default AddComponent