import palette from '../palette';

const MuiOutlinedInput = {
  root: {
    '&:hover:not($disabled)': {
      backgroundColor: palette.background.default
    },
    '&:hover:($disabled)': {
      // backgroundColor: '#E3E6FC'
    },
    // '&:$disabled': {
    // backgroundColor: '#E3E6FC'
    // },
    "&$disabled": {
      // backgroundColor: '#E3E6FC',
      color: '#7A7C7E'
    },
    margin: 'dense'
  },
  // disabled: {
  // backgroundColor: '#E3E6FC',
  // color: '#7A7C7E'
  // },
};

export default MuiOutlinedInput