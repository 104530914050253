import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import ClearSharpIcon from '@mui/icons-material/ErrorRounded';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleRounded';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import SecurityIcon from '@mui/icons-material/Security';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { Typography } from '@mui/material';
import {
    SetupCredential
} from './components'
import useStyles from './styles'
import { styleCommon } from './stylesCommon';
export default function DraggableDialog(props) {
    const {
        open,
        dialogType,
        action,
        label,
        message,
        title,
        body,
        disableBackdropClick,
        disableEscapeKeyDown,
        disablePortal,
        oneAction,
        customWidth,
        fieldError,
        scroll,
        values,
    } = props

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isMedium = useMediaQuery(theme.breakpoints.down('lg'))
    const [fullScreen, setFullScreen] = React.useState(true);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState(null);
    const classes = useStyles()

    React.useEffect(() => {
        setMaxWidth(isMedium ? "sm" : "sm")
    }, [isMedium])

    React.useEffect(() => {
        setFullScreen(isSmall ? true : false)
    }, [isSmall])

    React.useEffect(() => {
        setFullWidth(isMedium ? true : false)
    }, [isMedium])

    const takeUrlCam = (newstate) => {
        action.handleChange("captureUrl", newstate);
        action.handleChange("objectFile", URL.createObjectURL(newstate))
        action.handleChange("filenya", URL.createObjectURL(newstate))
        action.handleChange("url", URL.createObjectURL(newstate))
        console.log("Image take :", newstate);
    }

    const conditionalButton = (type) => {
        switch (type) {
            case 'Success':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                    </DialogActions>
                );
            case 'SuccessReset':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                    </DialogActions>
                );
            case 'Error':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                    </DialogActions>
                );
            case 'image':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                    </DialogActions>
                );
            case 'Info':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                    </DialogActions>
                );
            case 'Camera':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                    </DialogActions>
                );
            case 'Warning':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                    </DialogActions>
                );
            case 'Nota':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                    </DialogActions>
                );
            case 'NewUser':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                        <Button disableFocusRipple variant="contained" autoFocus disabled={fieldError.newPassword || fieldError.confirmPassword || values.newPassword?.length <= 0 || values.confirmPassword?.length <= 0} onClick={(e) => action.positive(e, values)} color="primary">
                            {label.positive}
                        </Button>
                    </DialogActions>
                );
            case 'ConfirmationApprove':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                        <Button disableFocusRipple variant="contained" autoFocus onClick={action.positive} color="primary">
                            {label.positive}
                        </Button>
                    </DialogActions>
                );
            case 'ConfirmationPrint':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                        <Button disableFocusRipple variant="contained" autoFocus onClick={action.positive} color="primary">
                            {label.positive}
                        </Button>
                    </DialogActions>
                );
            case 'ConfirmationDelete':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                        <Button disableFocusRipple variant="contained" autoFocus onClick={action.positive} color="primary">
                            {label.positive}
                        </Button>
                    </DialogActions>
                );
            case 'input':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                        <Button disableFocusRipple variant="contained" autoFocus onClick={action.positive} color="primary">
                            {label.positive}
                        </Button>
                    </DialogActions>
                );
            case 'ConfirmationPayment':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                        <Button disableFocusRipple variant="contained" autoFocus onClick={action.positive} color="primary">
                            {label.positive}
                        </Button>
                    </DialogActions>
                );
            case 'ConfirmationReset':
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" autoFocus onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                        <Button disableFocusRipple variant="contained" onClick={action.positive} color="primary">
                            {label.positive}
                        </Button>
                    </DialogActions>
                );
            default:
                return (
                    <DialogActions className={classes.action}>
                        <Button disableFocusRipple variant="contained" onClick={action.negative} color="secondary">
                            {label.negative}
                        </Button>
                        <Button disableFocusRipple variant="contained" autoFocus onClick={action.positive} color="primary">
                            {label.positive}
                        </Button>
                    </DialogActions>
                );
        }
    }

    const conditionalContent = (type) => {
        switch (type) {
            // case 'Camera':
            //     return (
            //         <div className={classes.paddingUp}>
            //             <CaptureImage
            //                 onClose={action.onClose}
            //                 takeImageUrl={takeUrlCam}
            //                 hideDialog={action.handleClose}
            //                 changeImageUrl={action.handleChange}
            //             />
            //         </div>
            //     );
            case 'Nota':
                return (
                    <div className={classes.paddingUp}>
                        {body()}
                    </div>
                );
            case 'image':
                return (
                    <div className={classes.paddingUp}>
                        {body(values)}
                    </div>
                );
            case 'input':
                return (
                    <div className={classes.paddingUp}>
                        {body(values)}
                    </div>
                );
            case 'ConfirmationApprove':
                return (
                    <div className={classes.paddingUp}>
                        {body()}
                    </div>
                );
            case 'ConfirmationPrint':
                return (
                    <div style={{display:'flex', justifyContent:'center', width: '70%'}}>
                        {body()}
                    </div>
                );
            case 'ConfirmationDelete':
                return (
                    <div className={classes.paddingUp}>
                        {body()}
                    </div>
                );
            case 'InputOtp':
                return (
                    <div className={classes.paddingUp}>
                        <SetupCredential
                            classes={classes}
                            onAction={action}
                            values={values}
                            fieldError={fieldError}
                        />
                    </div>
                );
            case 'ResetCode':
                return (
                    <div className={classes.paddingUp}>
                        <SetupCredential
                            classes={classes}
                            onAction={action}
                            values={values}
                            fieldError={fieldError}
                        />
                    </div>
                );
            case 'NewUser':
                return (
                    <div className={classes.paddingUp}>
                        <SetupCredential
                            classes={classes}
                            onAction={action}
                            values={values}
                            fieldError={fieldError}
                        />
                    </div>
                );
            default:
                return null
        }
    }

    return (
        <Dialog
            open={open}
            onClose={action.onClose}
            aria-labelledby={dialogType}
            scroll={scroll ?? "body"}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            fullScreen={fullScreen}
            maxWidth={!customWidth ? maxWidth : customWidth}
            fullWidth={fullWidth}
            disablePortal={disablePortal}
            PaperProps={{
                className: classes.root
            }}
        >
            <div className={classes.titleRoot}>
                <Hidden smDown>
                    <BlurOnIcon className={classes.iconHead} />
                </Hidden>
                <div
                    id={props.dialogId}
                    className={clsx(classes.icontitle, {
                        [classes.icontitleError]: props.dialogType === "Error" || props.dialogType === "Warning" || props.dialogType === "ConfirmationDelete"  ,
                        [classes.icontitleSuccess]: props.dialogType === "Success",
                        [classes.icontitleInfo]: props.dialogType === "Info" || props.dialogType === "ConfirmationApprove" || props.dialogType === "Confirmation" || props.dialogType === "NewUser" || props?.dialogType === "ConfirmationPrint",
                       
                    })}
                >
                    <div
                     className={classes.IconContainer}
                     >
                        {/* <Typography component="span" variant="h3">{props.title}</Typography> */}
                        <div>
                            {
                            props.dialogType === "Warning"
                                ? <ErrorOutlineIcon className={classes.iconError} />
                                : props.dialogType === "Info"
                                    ? <InfoIcon className={classes.iconInfo} />
                                    : props.dialogType === "ConfirmationApprove"
                                    ? <InfoIcon className={classes.iconInfo} />
                                    : props.dialogType === "ConfirmationPrint"
                                    ? <InfoIcon sx={styleCommon?.iconInfo} />
                                    : props.dialogType === "Confirmation"
                                    ? <InfoIcon sx={styleCommon?.iconInfo} />
                                    : props.dialogType === "Error"
                                        ? <ClearSharpIcon sx={styleCommon?.iconError} />
                                        : props.dialogType === "ConfirmationDelete"
                                        ? <ClearSharpIcon sx={styleCommon?.iconError} />
                                        : props.dialogType === "Success"
                                            ? <CheckCircleOutlineIcon sx={styleCommon.iconSuccess} />
                                            : props.dialogType === "Camera"
                                                ? <CheckCircleOutlineIcon className={classes.icon} />
                                                : props.dialogType === "NewUser"
                                                    ? <InfoIcon sx={styleCommon?.iconInfo} />
                                                    : props.dialogType === "reschedule"
                                                        ? <AlarmOnIcon className={classes.icon} />
                                                        : null
                            }
                        </div>
                        {/* <div id={dialogType}> */}
                        <Typography
                            // component="div"
                            // variant="h4"
                            sx={
                               
                                props.dialogType === "Warning" || 
                                props.dialogType === "ConfirmationApprove" ||
                                props.dialogType === "ConfirmationPrint" ||
                                props.dialogType === "Confirmation" ||
                                props.dialogType === "Info" ?
                                styleCommon.titleDark : 
                                props.dialogType === "Success" ?
                                styleCommon.titleSuccess :
                                props.dialogType === "Error" ||
                                props.dialogType === "ConfirmationDelete"?
                                styleCommon.titleError :
                                styleCommon.titleDark
                            }
                            className={clsx(classes.title, {
                                [classes.titleDark]: props.dialogType === "Error" || props.dialogType === "Warning" || props.dialogType === "ConfirmationDelete",
                                [classes.titleDark]: props.dialogType === "Info",
                                [classes.titleWhite]: props.dialogType === "Success",
                                [classes.titleDark]: props.dialogType === "ConfirmationApprove" || props.dialogType === "ConfirmationPrint" ,
                            })}
                            > {title}</Typography>
                        {/* </div> */}
                    </div>
                </div>
            </div>
            <div className={classes.content}>
                <div sx={styleCommon.messageBox}>
                    <Typography variant="h6" sx={styleCommon.message}>
                        {message}
                    </Typography>
                </div>
                <div className={classes.contentBox}>
                    {conditionalContent(dialogType)}
                </div>
            </div>
            {
                oneAction && <DialogActions className={classes.action}>
                    <Button disableFocusRipple variant="contained" autoFocus onClick={action.negative} color="primary">
                        {label.negative}
                    </Button>
                </DialogActions>
            }
            {conditionalButton(dialogType)}
            {/* <div className={classes.footerRoot}>
                <BlurOnIcon className={classes.footerIcon} />
            </div> */}
        </Dialog >
    );
}

DraggableDialog.propTypes = {
    open: PropTypes.bool,
    action: PropTypes.object.isRequired,
    label: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

DraggableDialog.defaultProps = {
    open: false,
    label: {
        negative: "Close",
        positive: 'Continue'
    },
    action: {
        onClose: () => { }
    }
};