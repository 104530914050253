import { lookupConstants } from '../_constants';
import { lookupService } from '../_services';

export const lookupActions = {
    getLookupProvinsi,
    getLookupKabupaten,
    getLookupZipcode,
    getHistoryNego,
    getPdfTransaction
};

function getLookupProvinsi(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await lookupService.getLookupProvinsi(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            // dispatch(failure(res));
            dispatch(success(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: lookupConstants.GETALL_REQUEST, } }
    function success(provinsi) { return { type: lookupConstants.PROVINSI_SUCCESS, provinsi } }
    function failure(error) { return { type: lookupConstants.GETALL_FAILURE, error } }
}

function getLookupKabupaten(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await lookupService.getLookupKabupaten(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(success(res));
            // dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: lookupConstants.GETALL_REQUEST, } }
    function success(kabupaten) { return { type: lookupConstants.KABUPATEN_SUCCESS, kabupaten } }
    function failure(error) { return { type: lookupConstants.GETALL_FAILURE, error } }
}

function getLookupZipcode(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await lookupService.getLookupZipcode(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(success(res));
            // dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: lookupConstants.GETALL_REQUEST, } }
    function success(zipcode) { return { type: lookupConstants.ZIPCODE_SUCCESS, zipcode } }
    function failure(error) { return { type: lookupConstants.GETALL_FAILURE, error } }
}

function getHistoryNego(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await lookupService.getHistoryNego(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            // dispatch(failure(res));
            dispatch(success(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: lookupConstants.GETALL_REQUEST, } }
    function success(historyNego) { return { type: lookupConstants.HISTORY_SUCCESS, historyNego } }
    function failure(error) { return { type: lookupConstants.GETALL_FAILURE, error } }
}

function getPdfTransaction(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await lookupService.getPdfTransaction(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            // dispatch(failure(res));
            dispatch(success(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: lookupConstants.GETALL_REQUEST, } }
    function success(pdfTransaction) { return { type: lookupConstants.TRANSACTION_SUCCESS, pdfTransaction } }
    function failure(error) { return { type: lookupConstants.GETALL_FAILURE, error } }
}