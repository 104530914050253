import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise';
import rootReducer from '../_reducers';
import requestMiddleware from './requestMiddleware';
import {
    getAccessLog
} from '../../helpers'

const loggerMiddleware = createLogger({
    predicate: () => process.env.NODE_ENV !== 'production'
});

console.log("🚀 ~ file: store.js ~ line 17 ~  getAccessLog('data_log')", getAccessLog('data_log'))

let persistedState = { "authentication": {} }

if (localStorage.getItem('click_point')) {
    persistedState.authentication = getAccessLog('data_log')
} else {
    persistedState.authentication = {}
}



console.log("%cSystem", "color: yellow; font-family:serif; font-size: 20px");
console.log("%c________________________", "color: yellow; font-family:serif; font-size: 20px");
console.log(persistedState)
console.log("%c________________________", "color: yellow; font-family:serif; font-size: 20px");

export const store = createStore(
    rootReducer,
    persistedState,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        requestMiddleware,
        promise,
    )
);