import { contactUsConstants } from '../_constants';

export function contactUs(state = {}, action) {
    switch (action.type) {
        case contactUsConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case contactUsConstants.GETALL_SUCCESS:
            return {
                items: action.contactUs
            };
        case contactUsConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        // case contactUsConstants.CREATE_REQUEST:
        //     return {
        //         loading: true
        //     };
        // case contactUsConstants.CREATE_SUCCESS:
        //     return {
        //         items: action.contactUs
        //     };
        // case contactUsConstants.CREATE_FAILURE:
        //     return {
        //         error: action.error
        //     };
        default:
            return state
    }
}