import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { lkppOrderActions } from '../../controllers/_actions'

export default function useLkppOrder(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const lkppOrder = useSelector(state => state.lkppOrder);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(lkppOrderActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'create') {
      dispatch(lkppOrderActions.createData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    } else if (type === 'update') {
      dispatch(lkppOrderActions.updateData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    } else if (type === 'cancel') {
      dispatch(lkppOrderActions.cancelData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
  
    } else if (type === 'delete') {
      dispatch(lkppOrderActions.resetData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  useEffect(() => {
    const DataLoaded = lkppOrder?.items?.data || []
    setData(DataLoaded)
  }, [lkppOrder])

  return [data, initFetch, crudAction]
}
