const rows1 = [
  {id: 'Bayar di Muka', name: 'Bayar di Muka'},
  
  {id: 'Bayar Setelah Produksi', name: 'Bayar Setelah Produksi'},

];
export function structuredefault(values, handleFieldChange) {
  const data = [
    {
      id: 'SETUJU',
      label: '',
      type: 'text',
      fieldtype: 'textfield',
      // disabled: true,
      required:true,
      noSign: true,
      placeholder: 'insert "SETUJU"',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["SETUJU"] || '',
      minWidth: 170,
      // hide: isHide
    },
  ]

  return data
};

export function structuredefault2(values, handleFieldChange) {
  const data = [
    {
      id: 'WAKTU_PEMBAYARAN',
      label: 'Waktu Pembayaran',
      type: 'select',
      fieldtype: 'suggestfield',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["WAKTU_PEMBAYARAN"],
      minWidth: 170,
      // hide: false,
      required: true,
      option:rows1?.length === 0 ? [] :rows1?.map(data => {
        return {
          id: data.id,
          value: data.id,
          label: data.name,
          data: data
        }
      }),
      placeholder: 'Pilih Waktu Pembayaran',
      // disabled: true
    },
  ]

  return data
};