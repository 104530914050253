import * as React from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function BasicDateRangePicker(props) {
    const {
    classes,
    param,
    } = props

  const [value, setValue] = React.useState(param?.value);
  React.useEffect(() => {
    if(param?.value?.length > 0) {
      setValue(param?.value)
    }
  },[param?.value])

  const handleOnChange = (e) => {
    param.onChange(param?.id, e)
    setValue(e);
  }
console.log(value)
  return (
    <LocalizationProvider  dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText={param?.startText}
        endText={param?.startText}
        value={value}
        onChange={handleOnChange}
        inputFormat={param?.format ?? 'yyyy-MM-dd'}
        mask='__/__/____ __:__'
        disabled={param?.disabled}
        renderInput={(startProps, endProps) => (
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <TextField {...startProps} required={param.required} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} required={param.required} />
          </Box>
        )}
      />
      <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
    </LocalizationProvider>
  );
}
