import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
	container: {
		paddingLeft: theme.spacing(10),
		paddingRight: theme.spacing(10),
		paddingTop: theme.spacing(7),
		paddingBottom: theme.spacing(5),
		height: 'auto',
		width: '100%',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(5),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		},
	},
	content: {
		paddingTop: '10px'
	},
	filter: {
		width: '100%',
		height: "auto",
		paddingLeft: '10px',

	},
	filterPrice: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: '10px',
		paddingRight: theme.spacing(10)
	},
	breadcrumb: {
		fontSize: '24px'
	},
	card: {
		width: 200,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.spacing(2),
		'&:hover': {
			border: '1px solid #E2E2E2'
		},
		[theme.breakpoints.down('md')]: {
			width: 180,
		},
		[theme.breakpoints.down('sm')]: {
			width: 150,
			padding: theme.spacing(1, 0),
		},
	},
	image: {
		width: 150,
		height: 150,
		[theme.breakpoints.down('md')]: {
			width: 120,
			height: 120,
		},
		[theme.breakpoints.down('xs')]: {
			width: 100,
			height: 100,
		},
	},
}));
