import { lookupConstants } from '../_constants';

export function lookups(state = {}, action) {
    switch (action.type) {
        case lookupConstants.PROVINSI_SUCCESS:
            return {
                ...state,
                provinsi: action.provinsi
            };
        case lookupConstants.KABUPATEN_SUCCESS:
            return {
                ...state,
                kabupaten: action.kabupaten
            };
        case lookupConstants.ZIPCODE_SUCCESS:
            return {
                ...state,
                zipcode: action.zipcode
            };
        case lookupConstants.HISTORY_SUCCESS:
            return {
                ...state,
                historyNego: action.historyNego
            };
        case lookupConstants.TRANSACTION_SUCCESS:
            return {
                ...state,
                pdfTransaction: action.pdfTransaction
            };
        default:
            return state
    }
}