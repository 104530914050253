import React, { useState, useEffect } from 'react';
import { InputComponent } from 'components'
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { formatCurrency } from 'helpers';
import { useLanguage } from 'hooks';

function General(props) {
  const [openModal, setOpenModal] = useState(false)
  const [list, setList] = useState([])

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const [language, lagDic, setLang] = useLanguage()
  const arrayImage = ['image1']
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    maxWidth: 100,
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F1F5F8",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#F1F5F8',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const state = [
    {
      product: 'fsakdfhaks kdshfksa shdfkahf assafhkasf sdfhkahsdf ',
      harga: 10000,
      jumlah: 'x2',
      total: 20000,
    },
    {
      product: 'ORD-123',
      harga: 10000,
      jumlah: 'x2',
      total: 20000,
    },
    {
      product: 'ORD-123',
      harga: 10000,
      jumlah: 'x2',
      total: 20000,
    },
  ]
  return (
    <div style={{ margin: '20px 20px 20px 20px' }}>
      <Typography variant='h5' sx={{ mb: 1 }}>{language.COMMON.MY_TRANSACTION_DETAIL.SHIPPING}</Typography>
      {
        props?.data?.map((val, idx) =>
          <Box key={idx} sx={{ border: '1px solid #B3BDCC', mb: 1 }}>
            <Box onClick={() => { setOpenModal({ ...openModal, [idx]: !openModal[idx] }) }} sx={{ display: 'flex', justifyContent: 'space-between', background: '#F1F5F8', p: 1 }}>
              <Typography sx={{ fontWeight: 'bold', color: '#4D4F5C', }}>{val?.ADDRESS_NAME}</Typography>
              {
                openModal[idx] ?
                  <ArrowDropDownIcon></ArrowDropDownIcon>
                  :
                  <ArrowRightIcon></ArrowRightIcon>
              }
            </Box>
            {
              openModal[idx] ?
                <Box sx={{ height: 350, overflowY: 'scroll' }}>
                  <Grid container spacing={1} sx={{ mt: 2, pr: 2, pl: 2 }}>
                    <Grid
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                    >
                      <Typography sx={{ color: '#4D4F5C' }} >{language.COMMON.MY_SHIPPING_ADDRESS.PROVINCE}</Typography>
                    </Grid>
                    <Grid
                      xl={9}
                      lg={9}
                      md={9}
                      sm={12}
                      xs={12}
                    >
                      <Typography sx={{ color: '#4D4F5C' }}>{val?.ADDRESS_PROVINSI}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{ mt: 2, pr: 2, pl: 2 }}>
                    <Grid
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                    >
                      <Typography sx={{ color: '#4D4F5C' }} >{language.COMMON.MY_SHIPPING_ADDRESS.CITY_DISTRICT}</Typography>
                    </Grid>
                    <Grid
                      xl={9}
                      lg={9}
                      md={9}
                      sm={12}
                      xs={12}
                    >
                      <Typography sx={{ color: '#4D4F5C' }}>{val?.ADDRESS_KABUPATEN}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{ mt: 2, pr: 2, pl: 2 }}>
                    <Grid
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                    >
                      <Typography sx={{ color: '#4D4F5C' }}>{language.COMMON.MY_SHIPPING_ADDRESS.FULL_ADDRESS}</Typography>
                    </Grid>
                    <Grid
                      xl={9}
                      lg={9}
                      md={9}
                      sm={12}
                      xs={12}
                    >
                      <Typography sx={{ color: '#4D4F5C' }}>{val?.ADDRESS}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{ mt: 2, pr: 2, pl: 2, mb: 2 }}>
                    <Grid
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                    >
                      <Typography sx={{ color: '#4D4F5C' }}>{language.COMMON.MY_TRANSACTION_DETAIL.SHIPPING_COST}</Typography>
                    </Grid>
                    <Grid
                      xl={9}
                      lg={9}
                      md={9}
                      sm={12}
                      xs={12}
                    >
                      <Typography sx={{ color: '#4D4F5C' }}>Rp. {formatCurrency(val?.SHIPPING_NEGO)}</Typography>
                    </Grid>
                  </Grid>
                  <TableContainer sx={{ height: 'auto' }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>{language.COMMON.MY_SHIPPING_ADDRESS.PRODUCT}</StyledTableCell>
                          <StyledTableCell>{language.COMMON.MY_SHIPPING_ADDRESS.PRODUCT_PRICE}</StyledTableCell>
                          <StyledTableCell>{language.COMMON.MY_SHIPPING_ADDRESS.QUANTITY}</StyledTableCell>
                          <StyledTableCell>{language.COMMON.MY_SHIPPING_ADDRESS.TOTAL_PRICE}</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {val?.SHIPPING_ITEM?.map((row) => (
                          <StyledTableRow key={row.SPEC}>
                            <StyledTableCell component="th" scope="row">
                              {row?.ITEM_NAME}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align='right'>
                              Rp. {formatCurrency(row?.SELL_PRICE)}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              x{row?.ITEM_TOTAL}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row" align='right'>
                              Rp. {formatCurrency(row?.SELL_PRICE * row?.ITEM_TOTAL)}
                            </StyledTableCell>

                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box> :
                null
            }
          </Box>
        )
      }
    </div>
  );
}

export default General;
