import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { externalActions } from '../../controllers/_actions'

export default function useExternal(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const external = useSelector(state => state.external);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(externalActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'create') {
      dispatch(externalActions.createData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);


  useEffect(() => {
    const DataLoaded = external?.items?.data || []
    setData(DataLoaded)
  }, [external])

  return [data, initFetch, crudAction]
}
