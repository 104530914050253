import { officeFitsOutConstants } from '../_constants';

export function officeFitsOut(state = {}, action) {
    switch (action.type) {
        case officeFitsOutConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case officeFitsOutConstants.GETALL_SUCCESS:
            return {
                items: action.officeFitsOut
            };
        case officeFitsOutConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        // case officeFitsOutConstants.CREATE_REQUEST:
        //     return {
        //         loading: true
        //     };
        // case officeFitsOutConstants.CREATE_SUCCESS:
        //     return {
        //         items: action.officeFitsOut
        //     };
        // case officeFitsOutConstants.CREATE_FAILURE:
        //     return {
        //         error: action.error
        //     };
        default:
            return state
    }
}