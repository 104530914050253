import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { negotiationActions } from '../../controllers/_actions'

export default function useNegotiation(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const negotiation = useSelector(state => state.negotiation);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(negotiationActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'create') {
      dispatch(negotiationActions.createData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    } else if (type === 'update') {
      dispatch(negotiationActions.updateData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    } else if (type === 'cancel') {
      dispatch(negotiationActions.cancelData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
  
    } else if (type === 'delete') {
      dispatch(negotiationActions.resetData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  useEffect(() => {
    const DataLoaded = negotiation?.items?.data || []
    setData(DataLoaded)
  }, [negotiation])

  return [data, initFetch, crudAction]
}
