import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Typography,
  Grid
} from '@mui/material'
import { BreadCrumbs } from 'components'
import { useAboutUs, useLanguage } from 'hooks'
import parse from 'html-react-parser';



function Index() {
  window.scrollTo(0, 0);                                         // Auto Scroll to Top

  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [language, lagDic, setLang] = useLanguage()
  const [datatables, setDatatables] = useAboutUs(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  console.log("🚀 ~ file: index.jsx ~ line 25 ~ Index ~ datatables", datatables)

  const baseUrlImage = window._env_?.BASEURL_IMAGE

  const breadcrumb = [
    {
      title: "Home", to: "/furnitur"
    }
  ]


  useEffect(() => {

    const param = {
      page: 1,
      limit: 10
    }
    setDatatables(param)
  }, [])

  return (
    <div className={classes.container}>
      <div style={{ paddingBottom: 20 }}>
        <BreadCrumbs
          breadcrumb={breadcrumb}
          current={language.COMMON.FOOTER.ABOUT_US}
        />
      </div>
      <Typography variant="body1" color="initial"><strong>{language.COMMON.FOOTER.ABOUT_US}</strong></Typography>
      <Grid container spacing={5}>
        <Grid item lg={6} sm={12}>
          {datatables ?
            parse(`${datatables?.[0]?.VALUE_02}`)
            // <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
            //   {datatables?.[0]?.VALUE_02}
            // </Typography>
            :
            <React.Fragment>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                Operating in Brisbane, Australia since 2015,
                our team has grown from small beginnings to become one of the largest suppliers of office furniture in the country.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                Our goal is to be the easy solution when it comes to finding the office furniture you need to get the job done.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                We strive to offer our customers a wide range of products that are built to the highest commercial standards
                through a helpful, easy-to-use online store. It’s all part of our commitment to quality service,
                delivered by our friendly staff who are always happy to help out with your enquiries.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                We are also committed to giving you the best value for money.
                If you happen to find a cheaper price on a stocked item – we’ll match it! We offer competitive flat rate shipping around the country,
                working with our logistics partners to ensure your order arrives as soon as possible.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                We also specialise in custom-built furniture and large projects, offering on-site consultation to identify your needs and
                an inhouse design and installation team to make the process as easy as possible for you.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                As a 100% Australian-owned and operated company, we pride ourselves on quality products, affordable prices and
                great customer service. Whether you’re buying for a multi-storey workplace, home office or anything in between,
                Epic Office Furniture is the easy solution.
              </Typography>
            </React.Fragment>
          }
        </Grid>
        <Grid item lg sm>
          {datatables
            ?
            <img
              style={{ width: '100%', height: 'auto' }}
              src={`${baseUrlImage}${datatables?.[0]?.VALUE_04}${datatables?.[0]?.VALUE_03}`}
              alt="" />
            :
            <img
              style={{ width: '100%', height: 'auto' }}
              src="https://www.rukita.co/stories/wp-content/uploads/2020/01/coworking-space-in-jakarta-1360x907.jpg"
              alt="" />
          }
        </Grid>
      </Grid>

    </div>
  )
}

export default Index
