const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export function structuredefault(values, handleFieldChange, open, categoryOption, language) {
  const data = [
    {
      id: 'PP_NAME',
      label: language.COMMON.MY_TRANSACTION_DETAIL.NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PP_NAME"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PP_POSITION',
      label: language.COMMON.MY_TRANSACTION_DETAIL.POSITION,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Position',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PP_POSITION"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PP_NIP',
      label: language.COMMON.MY_TRANSACTION_DETAIL.NIP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert NIP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PP_NIP"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PP_EMAIL',
      label: language.COMMON.MY_TRANSACTION_DETAIL.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Email',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PP_EMAIL"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PP_PHONE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.PHONE_NO,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Phone Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PP_PHONE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PP_PBJ',
      label: language.COMMON.MY_TRANSACTION_DETAIL.CERTIFICATE_PJB,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Cerificate PBJ Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PP_PBJ"] || '',
      minWidth: 170,
      // hide: isHide
    },

  ]

  return data
};
export function structuredefault2(values, handleFieldChange, open, categoryOption, language) {
  const data = [
    {
      id: 'PPK_NAME',
      label: language.COMMON.MY_TRANSACTION_DETAIL.NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PPK_NAME"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PPK_POSITION',
      label: language.COMMON.MY_TRANSACTION_DETAIL.POSITION,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Position',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PPK_POSITION"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PPK_NIP',
      label: language.COMMON.MY_TRANSACTION_DETAIL.NIP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert NIP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PPK_NIP"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PPK_EMAIL',
      label: language.COMMON.MY_TRANSACTION_DETAIL.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Email',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PPK_EMAIL"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PPK_PHONE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.PHONE_NO,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Phone Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PPK_PHONE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PPK_PBJ',
      label: language.COMMON.MY_TRANSACTION_DETAIL.CERTIFICATE_PJB,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Cerificate PBJ Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PPK_PBJ"] || '',
      minWidth: 170,
      // hide: isHide
    },
  ]

  return data
};