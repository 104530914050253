import React, { useState, useEffect } from 'react';
import {InputComponent} from 'components'
import { Button, Divider, FormControl, Grid,Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { formatCurrency } from 'helpers';
import { useLanguage } from 'hooks';

function General(props) {
  console.log("🚀 ~ file: index.jsx ~ line 18 ~ General ~ props", props)
  const [language, lagDic, setLang] = useLanguage()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [list, setList] = useState([])

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const arrayImage = ['image1']
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    maxWidth: 100,
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F1F5F8",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#F1F5F8',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const state = [ 
    {
    product: 'fsakdfhaks kdshfksa shdfkahf assafhkasf sdfhkahsdf ',
    harga: 10000,
    jumlah: 'x2',
    total: 20000,
  },
  {
    product: 'ORD-123',
    harga: 10000,
    jumlah: 'x2',
    total: 20000,
  },
  {
    product: 'ORD-123',
    harga: 10000,
    jumlah: 'x2',
    total: 20000,
  },
]
  return (
      <div style={{padding: '20px 20px 20px 20px' ,width: '100%' }}>
        <Typography variant='h5' sx={{mb:2,color: '#4D4F5C'}}>{language.COMMON.MY_TRANSACTION_DETAIL.PRODUCT}</Typography>
        <Grid container spacing={1} sx={{pl: 1, mb:2}}>
          <Grid
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
          >
            <Typography variant='h5' sx={{color: '#4D4F5C'}} >{language.COMMON.MY_TRANSACTION_DETAIL.PRODUCT_QUANTITY}</Typography>
          </Grid>
          <Grid
            xl={9}
            lg={9}
            md={9}
            sm={12}
            xs={12}
            
          >
            <Typography variant='h5' sx={{color: '#4D4F5C',textAlign: 'right'}}>{props?.data?.PURCHASE_ITEM?.reduce((n, {ITEM_TOTAL}) => n + ITEM_TOTAL, 0)}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{height: '2px', color: '#B3BDCC', mb: 3}}></Divider>
        <Typography variant='h5' sx={{mb:2,color: '#4D4F5C'}}>{language.COMMON.MY_TRANSACTION_DETAIL.CART_TOTAL}</Typography>
        <Grid container spacing={1} sx={{pl: 1,mb:2}}>
          <Grid
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
          >
            <Typography variant='h5' sx={{color: '#4D4F5C'}} >{language.COMMON.MY_TRANSACTION_DETAIL.SUB_TOTAL}</Typography>
          </Grid>
          <Grid
            xl={9}
            lg={9}
            md={9}
            sm={12}
            xs={12}
            
          >
            <Typography variant='h5' sx={{color: '#4D4F5C',textAlign: 'right'}}>Rp. {formatCurrency(props?.data?.PURCHASE_ITEM?.reduce((n, {SUB_TOTAL_NEGO}) => n + SUB_TOTAL_NEGO, 0))}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{pl: 1,mb:2}}>
          <Grid
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
          >
            <Typography variant='h5' sx={{color: '#4D4F5C'}} >{language.COMMON.MY_TRANSACTION_DETAIL.SHIPPING_TOTAL}</Typography>
          </Grid>
          <Grid
            xl={9}
            lg={9}
            md={9}
            sm={12}
            xs={12}
            
          >
            <Typography variant='h5' sx={{color: '#4D4F5C',textAlign: 'right'}}>Rp. {formatCurrency(props?.data?.SHIPPING?.reduce((n, {SHIPPING_NEGO}) => n + SHIPPING_NEGO, 0))}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{height: '2px', color: '#B3BDCC', mb: 3}}></Divider>
        <Grid container spacing={1} sx={{pl: 1,mb:2}}>
          <Grid
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
          >
            <Typography variant='h5' sx={{color: '#4D4F5C', fontWeight: 'bold'}} >{language.COMMON.MY_TRANSACTION_DETAIL.TOTAL_PAYMENT}</Typography>
          </Grid>
          <Grid
            xl={9}
            lg={9}
            md={9}
            sm={12}
            xs={12}
            
          >
            <Typography variant='h4' sx={{color: '#4D4F5C',textAlign: 'right', fontWeight: 'bold'}}>Rp. {formatCurrency(props?.data?.PURCHASE_ITEM?.reduce((n, {SUB_TOTAL_NEGO}) => n + SUB_TOTAL_NEGO, 0)+props?.data?.SHIPPING?.reduce((n, {SHIPPING_NEGO}) => n + SHIPPING_NEGO, 0))}</Typography>
          </Grid>
        </Grid>
        <FormControl fullWidth>
          <Button onClick={()=> {navigate(-1)}} sx={{mb: 2, color: '#2966C4', background: 'white', border: '1px solid #2966C4', '&:hover': {background: 'white',color: '#2966C4'}}} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.BACK_TO_TRANSACTION}</Button>
        </FormControl>
      </div>
  );
}

export default General;
