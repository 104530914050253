export const productConstants = {
    GETALL_PRODUCT_REQUEST: 'PRODUCT_GETALL_REQUEST',
    GETALL_PRODUCT_SUCCESS: 'PRODUCT_GETALL_SUCCESS',
    GETALL_PRODUCT_FAILURE: 'PRODUCT_GETALL_FAILURE',

    GETALL_DETAIL_PRODUCT_REQUEST: 'PRODUCT_DETAIL_GETALL_REQUEST',
    GETALL_DETAIL_PRODUCT_SUCCESS: 'PRODUCT_DETAIL_GETALL_SUCCESS',
    GETALL_DETAIL_PRODUCT_FAILURE: 'PRODUCT_DETAIL_GETALL_FAILURE',

    GETALL_POPULAR_PRODUCT_REQUEST: 'PRODUCT_POPULAR_GETALL_REQUEST',
    GETALL_POPULAR_PRODUCT_SUCCESS: 'PRODUCT_POPULAR_GETALL_SUCCESS',
    GETALL_POPULAR_PRODUCT_FAILURE: 'PRODUCT_POPULAR_GETALL_FAILURE',

    GETALL_BANNER_REQUEST: 'BANNER_GETALL_REQUEST',
    GETALL_BANNER_SUCCESS: 'BANNER_GETALL_SUCCESS',
    GETALL_BANNER_FAILURE: 'BANNER_GETALL_FAILURE',

    GETALL_SALE_REQUEST: 'SALE_GETALL_REQUEST',
    GETALL_SALE_SUCCESS: 'SALE_GETALL_SUCCESS',
    GETALL_SALE_FAILURE: 'SALE_GETALL_FAILURE',

    GETALL_SIZE_REQUEST: 'SIZE_GETALL_REQUEST',
    GETALL_SIZE_SUCCESS: 'SIZE_GETALL_SUCCESS',
    GETALL_SIZE_FAILURE: 'SIZE_GETALL_FAILURE',

    GETALL_LIMIT_REQUEST: 'LIMIT_GETALL_REQUEST',
    GETALL_LIMIT_SUCCESS: 'LIMIT_GETALL_SUCCESS',
    GETALL_LIMIT_FAILURE: 'LIMIT_GETALL_FAILURE',
};
