// 
import { authHeader } from '../_helpers'
import axios from '../_helpers/interceptor'
import { getAccessLog } from 'helpers';

export const profileService = {
    getMyAccount,
    getMyTransaction,
    createData,
    getInstance,
    changePass,
    updateData,
};

async function getMyAccount(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "CUSTOMER_FILTER": param?.CUSTOMER_FILTER,
                "CUSTOMER_NAME": param?.CUSTOMER_NAME,
                "CUSTOMER_NO": param?.CUSTOMER_NO,
                "ADDRESS_NAME": param?.ADDRESS_NAME,
                "USERNAME": param?.USERNAME,
                "IS_DUMP": false,
                "ACTIVE_STATUS": true
            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/customer/retail/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getInstance(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "COMPANY_TYPE": "INSTANCE",

                "IS_DUMP": false,
                "ACTIVE_STATUS": true,
            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/product/instance/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}


async function getMyTransaction(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "CUSTOMER_FILTER": param?.CUSTOMER_FILTER,
                "USERNAME": param?.USERNAME,

                "PURCHASE_STATUS": param?.PURCHASE_STATUS,
                // "CUSTOMER_NAME": param?.CUSTOMER_NAME,
                "START_DATE": param?.START_DATE,
                "END_DATE": param?.END_DATE,
                "CREATED_BY": param?.CREATED_BY,

                "IS_DUMP": false,
                "ACTIVE_STATUS": true

            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/shipping/retail/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function createData(param) {
    const bodyOptions = {
        "CUSTOMER_NAME": param?.CUSTOMER_NAME,
        "USERNAME_ID": param?.USERNAME_ID,

        "CP": [
            {
                "REFERENCE_OF": "SHIPPING",
                "CP_MODE": "PHONE",
                "CP_CONTACT": param?.PHONE,
                "IS_CURRENT": true
            },
            {
                "REFERENCE_OF": "SHIPPING",
                "CP_MODE": "EMAIL",
                "CP_CONTACT": param?.EMAIL,
                "IS_CURRENT": true
            }
        ],
        "_id":  param?._id,
        "_key":  param?._key,
        "_rev":  param?._rev,
        "ADDRESS": param?.ADDRESS,

        "CUSTOMERCOMPANY": [
            {
                "COMPANY_CODE": param?.COMPANY_CODE,
                "COMPANY_USERNAME": param?.COMPANY_USERNAME,
                "COMPANY_ROLE": param?.COMPANY_ROLE,

                "TOKEN": param?.TOKEN,

                "INSTANCE_CODE": param?.INSTANCE_CODE,
                "INSTANCE_NIP": param?.INSTANCE_NIP,
                "INSTANCE_POSITION": param?.INSTANCE_POSITION,

                "CERTIFICATE_NO": param?.CERTIFICATE_NO,

                "WORK_UNIT": param?.WORK_UNIT,
                "WORK_NPWP": param?.WORK_NPWP,
                "WORK_ADDRESS": param?.WORK_ADDRESS
            }
        ],

        "ACTION_BY": param?.ACTION_BY,
        "ACTION_DATE": param?.ACTION_DATE,
        "ADDITIONAL_INFO": param?.ADDITIONAL_INFO,
        "IS_DUMP": false,
        "ACTIVE_STATUS": true
    }
    const lang = param?.lang ?? "en";
    const requestUrl = `/clarity-be/customer/retail/create?lang=${lang}`
    const checkHeader = await authHeader(requestUrl, bodyOptions, "not mandatory")
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        axios.post(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response save area adalah : ', response);
                const paramList = {
                    CUSTOMER_FILTER: "IS NOT NULL",
                    // CUSTOMER_NAME: "Maudy A",
                    // CUSTOMER_NO: "00000009",
                    USERNAME: getAccessLog('username'),
                    page: 1,
                    limit: 100
                }
                getMyAccount(paramList)
                // if (response.data.response_code === 'S1') {
                return {
                    data: response.data?.DATA,
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
            console.log("🚀 ~ file: profile.service.js ~ line 320 ~ createData ~ error",  error)
                if(error?.status === 400 && error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE?.includes('FAILED Create new Customer. ERROR: duplicate key')) {
                    console.log('ai aku disini')
                    return updateData(param)
                }
                // Error 😨
                console.log(error?.response);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };

            });
    return response;
}

async function changePass(param) {
    const bodyOptions = {
        "CURRENT_PASS": param?.CURRENT_PASS,
        "NEW_PASS": param?.NEW_PASS,
    };;
    const lang = param?.lang ?? "en";
    const requestUrl = `&lang=${lang}`
    const checkHeader = await authHeader(requestUrl, bodyOptions)
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        axios.put(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response update data area adalah : ', response);
                return {
                    data: response.data?.DATA,
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
                // Error 😨
                console.log(error);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };
            });
    return response;
}
async function updateData(param) {
    const bodyOptions = {
        "CUSTOMER_NAME": param?.CUSTOMER_NAME,
        "USERNAME_ID": param?.USERNAME_ID,
        "CUSTOMER_NO": param?.CUSTOMER_NO,
        "CP": [
            {
                "REFERENCE_OF": "SHIPPING",
                "CP_MODE": "PHONE",
                "CP_CONTACT": param?.PHONE,
                "IS_CURRENT": true
            },
            {
                "REFERENCE_OF": "SHIPPING",
                "CP_MODE": "EMAIL",
                "CP_CONTACT": param?.EMAIL,
                "IS_CURRENT": true
            }
        ],
        "ADDRESS": param?.ADDRESS,
        "_id":  param?._id,
        "_key":  param?._key,
        "_rev":  param?._rev,
        // "ADDRESS": [
        //     {
        //         "REFERENCE_OF": "COMPANY",
        //         "ADDRESS_PROVINSI": "Banten",
        //         "ADDRESS_KABUPATEN": "Kabupaten Tangerang",
        //         "ZIPCODE": "15810",
        //         "ADDRESS": "One Pm Building, Jl. Gading Serpong Boulevard No.18, Pakulonan Bar., Kec. Klp. Dua",
        //         "IS_CURRENT": true
        //     }
        // ],

        "CUSTOMERCOMPANY": [
            {
                "COMPANY_CODE": param?.COMPANY_CODE,
                "COMPANY_USERNAME": param?.COMPANY_USERNAME,
                "COMPANY_ROLE": param?.COMPANY_ROLE,

                "TOKEN": param?.TOKEN,

                "INSTANCE_CODE": param?.INSTANCE_CODE,
                "INSTANCE_NIP": param?.INSTANCE_NIP,
                "INSTANCE_POSITION": param?.INSTANCE_POSITION,

                "CERTIFICATE_NO": param?.CERTIFICATE_NO,

                "WORK_UNIT": param?.WORK_UNIT,
                "WORK_NPWP": param?.WORK_NPWP,
                "WORK_ADDRESS": param?.WORK_ADDRESS
            }
        ],

        "ACTION_BY": param?.ACTION_BY,
        "ACTION_DATE": param?.ACTION_DATE,
        "ADDITIONAL_INFO": param?.ADDITIONAL_INFO,
        "IS_DUMP": false,
        "ACTIVE_STATUS": true
    }
    const lang = param?.lang ?? "en";
    const requestUrl = `/clarity-be/customer/retail/update`
    const checkHeader = await authHeader(requestUrl, bodyOptions, "not mandatory")
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        axios.put(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response save area adalah : ', response);
                // if (response.data.response_code === 'S1') {
                const paramList = {
                    CUSTOMER_FILTER: "IS NOT NULL",
                    // CUSTOMER_NAME: "Maudy A",
                    // CUSTOMER_NO: "00000009",
                    USERNAME: getAccessLog('username'),
                    page: 1,
                    limit: 100
                }
                getMyAccount(paramList)
                return {
                    data: response.data?.DATA,
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
            console.log("🚀 ~ file: profile.service.js ~ line 485 ~ updateData ~ error", error)
                // Error 😨
                console.log(error?.response);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };

            });
    return response;
}