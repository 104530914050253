// Palette
import palette from '../palette';

const MuiTableCell = {
  root: {
    // borderBottom: `1px solid ${palette.divider}`,
    alignItem: 'center',
    // padding: '0px'
  },
  head: {
    backgroundColor: palette.background.main,
    fontSize: '11px',
    color: palette.common.black,
    fontWeight: 700
  },
  body: {
    fontSize: '11px',

  }
};

export default MuiTableCell
