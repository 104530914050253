import { ourProjectConstants } from '../_constants';
import { ourProjectService } from '../_services';

export const ourProjectActions = {
    getAll,
    getDetail
};

function getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await ourProjectService.getAll(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: ourProjectConstants.GETALL_REQUEST, } }
    function success(ourProject) { return { type: ourProjectConstants.GETALL_SUCCESS, ourProject } }
    function failure(error) { return { type: ourProjectConstants.GETALL_FAILURE, error } }
}

function getDetail(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await ourProjectService.getDetail(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: ourProjectConstants.GETALL_DETAIL_REQUEST, } }
    function success(ourProject) { return { type: ourProjectConstants.GETALL_DETAIL_SUCCESS, ourProject } }
    function failure(error) { return { type: ourProjectConstants.GETALL_DETAIL_FAILURE, error } }
}