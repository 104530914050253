import React from 'react';
import { useSelector } from 'react-redux';
import { CustomTable } from 'components'
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { formatCurrency } from 'helpers';

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
const useStyles = makeStyles(theme => ({
  root: {},
  statusError: {
    color: theme.palette.danger.main,
    fontWeight: 'bold'
  },
  statusSuccess: {
    color: theme.palette.success.main,
    fontWeight: 'bold'
  },
  statusUndefined: {
    color: theme.palette.info.main,
    fontWeight: 'bold'
  },
  iconBlue: {
    color: '#8DA4B5',
    '&:hover': {
      color: "#378BCC"
    }
  
  },
  iconRed: {
    color: '#D32F2F',
    '&:hover': {
      color: "#D32F2F"
    }
  },
  iconDel: {
    color: '#8DA4B5',
    '&:hover': {
      color: "#D32F2F"
    }
  }
}))


export default function TableComponent(props) {
  const {
    data,
    error,
    loading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    onSelect,
    selected,
    // step,
    changeStep,
    // handleDelete,
    checkingApprove,
    historyNego
  } = props
  const classes = useStyles()
  const areaGroups = useSelector(state => state.spesification);
  const countLoaded = Number(areaGroups?.items?.completed_response?.data?.INFO_PAGE?.TOTAL_RECORD ?? 0) ?? 0

  function StatusHandler(stat) {
    if (stat === true) {
      return (
        <Typography className={classes.statusSuccess}>ACTIVE</Typography>
      )
    } else {
      return (
        <Typography className={classes.statusError}>INACTIVE</Typography>
      )
    }
  }
  function nameHandle(value, type) {
    let stat = ''
    if(type === 'PREV_PRICE') {
      stat = `Rp.${formatCurrency(value?.PREV_PRICE)}`
    }
    if(type === 'PREV_SHIPPING') {
      stat = `Rp.${formatCurrency(value?.NEW_PRICE)}`
    }
    if(type === 'NEW_PRICE') {
      stat = `Rp.${formatCurrency(value?.PREV_SHIPPING)}`
    }
    if(type === 'NEW_SHIPPING') {
      stat = `Rp.${formatCurrency(value?.NEW_SHIPPING)}`
    }
    if(type === 'TOTAL_PAYMENT') {
      stat = `Rp.${formatCurrency(value?.PREV_PRICE + value?.PREV_SHIPPING)}`
    }
    if(type === 'NEW_TOTAL_PAYMENT') {
      stat = `Rp.${formatCurrency(value?.NEW_PRICE + value?.NEW_SHIPPING)}`
    }
    if(type === 'OFFER_BY') {
      stat = `${value?.CREATED_BY}`
    }
    return `${stat}`
  }

  const columns = [
    { id: 'REVISION_CODE', value: 'REVISION_CODE', label: 'REVISSION ID', minWidth: 100 },
    // { id: 'REF_ID', value: 'REF_ID', label: 'CATEGORY', minWidth: 100 },
    { id: 'PREV_PRICE', value: 'PREV_PRICE', label: 'PREV PRODUCT PRICE',minWidth: 100, minWidth: 100,minWidth: 100, minWidth: 100,customFormat: (value) => nameHandle(value, 'PREV_PRICE') },
    { id: 'NEW_PRICE', value: 'NEW_PRICE', label: 'NEW PRODUCT PRICE',minWidth: 100, minWidth: 100,minWidth: 100, minWidth: 100,customFormat: (value) => nameHandle(value, 'NEW_PRICE') },
    { id: 'PREV_SHIPPING', value: 'PREV_SHIPPING', label: 'PREV SHIPPING FEE',minWidth: 100, minWidth: 100,minWidth: 100, minWidth: 100,customFormat: (value) => nameHandle(value, 'PREV_SHIPPING') },
    { id: 'NEW_SHIPPING', value: 'NEW_SHIPPING', label: 'NEW SHIPPING FEE',minWidth: 100, minWidth: 100,minWidth: 100, minWidth: 100,customFormat: (value) => nameHandle(value, 'NEW_SHIPPING') },
    { id: 'PRICE_LAST', value: 'PRICE_LAST', label: 'PREV TOTAL PAYMENT', minWidth: 100, minWidth: 100,minWidth: 100, minWidth: 100,customFormat: (value) => nameHandle(value, 'TOTAL_PAYMENT') },
    { id: 'PRICE_PROPOSED', value: 'PRICE_PROPOSED', label: 'NEW TOTAL PAYMENT', minWidth: 100, minWidth: 100,minWidth: 100, minWidth: 100,customFormat: (value) => nameHandle(value, 'NEW_TOTAL_PAYMENT') },
    { id: 'OFFER_BY', value: 'OFFER_BY', label: 'OFFER BY', minWidth: 100, minWidth: 100,customFormat: (value) => nameHandle(value, 'OFFER_BY') },
   

  ];


  const moveStep = () => {
    changeStep('edit-store')
  }

  const actionStructure = [
    {
      id: '1', value: '_key', label: 'Export Excel', icon: <LocalPrintshopIcon className={classes.iconBlue} />, variant: 'default', color: 'primary', type: 'detail',
      onAction: (body) => checkingApprove(body, "update")
    },
    {
      id: '2', value: 'name', label: 'View detail', icon: <VisibilityIcon className={classes.iconBlue} />, variant: 'default', color: 'primary',
      onAction: (body) => checkingApprove(body, "view")
    }
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <CustomTable
      rows={historyNego}
      loading={loading}
      error={error}
      selectableDetail
      onSelect={onSelect}
      changeStep={moveStep}
      selected={selected}
      multiAction
      actionStructure={actionStructure}
      action={(body, type) => props.checkingApprove(body, type)}
      columns={columns}
      rowsPerPage={rowsPerPage || 10}
      dbPagination
      totalCount={historyNego?.length}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noIndex
      // noPagination
    />
  );
}
