const common = {
  SYSTEM: {
    NAME: 'Sistem Pengelolaan Bahan Baku Produk',
    DESCRIPTION: 'WMS for Honda Indonesia',
    VERSION: '0.1.0',
    COMPANY: 'Honda Indonesia',
    WELCOME: 'Selamat datang di',
    WELCOME1: 'Selamat datang',
    PUBLISHER: 'Inovasi DIgital Ritel',
    ADVANCE_FILTER: 'Pencarian Lebih Lanjut'
  },
  MENU: {
    PROFILE: 'Hai',
    HOME: 'Beranda',
    SHOP_BY_PRODUCT: 'Sortir Berdasarkan Produk',
    SHOP_BY_RANGE: 'Sortir Berdasarkan Range',
    SALE: 'Diskon',
    OFFICE_FITS_OUT: 'Penyesuaian Ruang Kantor',
    OUR_PROJECT: 'Projek Kami',
    CONTACT_US: 'Kontak Kami',
    CHAT_WITH_US: 'Diskusi dengan Kami',
    LOGOUT: 'Keluar'
  },
  CONTENT: {
    SEARCH: 'Cari',
    SIGN_IN: 'Masuk',
    ACCOUNT: 'Akun',
    ITEMS: 'Produk',
    POPULAR_PRODUCT: 'Produk Populer',
    EMPTY_PRODUCT: 'Tidak Ada Porduk Populer Terdaftar',
  },
  FIELDTEXT: {
    FIRST: 'Nama Depan',
    LAST: 'Nama Belakang',
    EMAIL: 'Email',
    COMMENT: 'Komentar'
  },
  FOOTER: {
    CONTACT_INFORMATION: 'Informasi Kontak',
    SEE_WHY: 'Lihat mengapa layanan pelanggan kami benar-benar membedakan kami. Tim kami siap membantu dengan pertanyaan apa pun yang Anda miliki.',
    CONSUMER_COMPLAINT: 'Layanan Pengaduan Konsumen',
    HELP_SUPPORT: 'Bantuan dan Dukungan',
    TERM_CONDITION: 'Syarat dan Ketentuan',
    CONTACT_US: 'Kontak Kami',
    MENU: 'Menu',
    HOME: 'Beranda',
    MY_ACCOUNT: 'Akun Saya',
    ABOUT_US: 'Tentang Kami',
    OFFICE_FITS_OUT: 'Penyesuaian Ruang Kantor',
    OUR_PROJECT: 'Projek Kami',
    PAYMENT: 'Pembayaran'
  },
  REGISTER: {
    REGISTRATION: 'Pendaftaran',
    EMAIL: 'Email',
    PASSWORD: 'Kata Sandi',
    RE_TYPE_PASSWORD: 'Ketik Ulang Kata Sandi',
    REGISTER: 'Daftar',
    ALREADY_HAVE_ACCOUNT: 'Sudah Memiliki Akun?',
    LOGIN_HERE: 'Masuk Di Sini',
    VERIFY: 'Verifikasi alamat email Anda untuk mengakses akun Clarity Anda, Anda harus melakukan konfirmasi email terlebih dahulu'

  },
  LOGIN: {
    MASUK: 'Masuk',
    EMAIL: 'Email',
    FORGOT: 'Lupa Kata Sandi',
    PASSWORD: 'Kata Sandi',
    LOGIN: 'Masuk',
    DONT_HAVE_ACCOUNT: 'Belum Memiliki Akun?',
    REGISTER_HERE: 'Daftar Di Sini'
  },
  PRODUCT_LIST: {
    FILTER: 'Filter',
    CLEAR_FILTER: 'Hapus Filter',
    SHOW: 'Menampilkan',
    ITEMS: 'produk',
    FILTER_BY_PRICE: 'Filter Berdasarkan Harga',
    FILTER_BY_FUNCTIONALITY: 'Filter Berdasarkan Fungsi',
    RANGE: 'Rentang',
    FILTER_BY_SPECIFICATION: 'Filter Berdasarkan Spesifikasi',
    FILTER_BY_CATEGORY: 'Filter Berdasarkan Kategori',
    PRICE_ASC: 'Harga Terendah',
    PRICE_DESC: 'Harga Tertinggi',
    ALPHA: 'Alfabet',
    DEFAULT: 'Sortir Bawaan'
  },
  PRODUCT_INFORMATION_DETAIL: {
    SIZE: 'Ukuran',
    QUANTITY: 'Kuantitas',
    ADD_TO_CHART: 'Masukan Keranjang',
    OPTIONAL_EXTRAS: 'Tambahan Optional',
    DESCRIPTION: 'Deskripsi'
  },
  SUBMITTED_PRODUCT_TO_CART: {
    SUCCESSFULLY_ADD_ITEM: 'Berhasil Menambahkan Produk ke Keranjang',
    HAS_BEEN_ADDED: 'berhasil ditambahkan ke keranjang',
    SHOP_MORE: 'Belanja Lagi',
    VIEW_CHART: 'Cek Keranjang'
  },
  CART: {
    PRODUCT: 'Produk',
    DIALOG_TITLE_CONFIRM: 'Tidak Dapat Melanjutkan Proses',
    DIALOG_BODY_CONFIRM: 'Nominal transaksi maksimal untuk kategori PP adalah Rp.200.000.000,-. Mohon untuk tinjau kembali keranjang belanja Anda.',
    PRICE: 'Harga',
    QUANTITY: 'Kuantitas',
    SUBTOTAL: 'Subtotal',
    SHIPPING_ADDRESS: 'Alamat Pengiriman',
    CHANGE_ADDRESS: 'Ubah Alamat',
    CART_TOTAL: 'Total Pesanan',
    SUB_TOTAL: 'Subtotal',
    SHIPPING: 'Subtotal Pengiriman',
    TOTAL_PAYMENT: 'Total Pembayaran',
    PROCEED_TO_CHECK: 'Lanjutkan ke Pembayaran',
    PROCEED_TO_TRANSACTION: 'Lanjut Transaksi',
    BUYER_INFORMATION: 'Informasi Buyer',
    BUYER_NAME: 'Nama Buyer',
    ROLE: 'Role',
    ORDER_CONFIRMATION: 'Konfirmasi Pesanan',
    TRANSACTION_CONFIRMATION: 'Konfirmasi Transaksi',
    SELECT_ADDRESS: 'Pilih Alamat',
    ADD_ADDRESS: 'Tambah Alamat',
    PROVINCE: 'Provinsi',
    DISTRICT_CITY: 'Kabupaten/Kota',
    FULL_ADDRESS: 'Alamat Lengkap',
    SEND_ALL_ITEMS: 'Kirim semua barang ke satu alamat',
    IN_CART: 'Di Keranjang',
    ADD_SHIPPING: 'Tambah Pengiriman',
    SHIPPING: 'Pengiriman',
    LABEL_ADDRESS: 'Label Alamat',
    DISTRICT: 'Kabupaten',
    SHIPPING_COST: 'Biaya Pengiriman',
    TOTAL: 'Total',
    DELETE: 'Hapus',
    PRODUCT_QUANTITY: 'Kuantitas Produk',
    SHIPPING_TOTAL: 'Total Pengiriman',
    SHIPPING_CONFIRMATION: 'Konfirmasi Pengiriman'
  },
  CHANGE_ADDRESS: {
    UPDATE_ADDRESS: 'Perbarui Alamat',
    PROVINCE: 'Provinsi',
    CITY: 'Kota',
    POSTAL_CODE: 'Kode Pos',
    FULL_ADDRESS: 'Alamat Lengkap',
    UPDATE: 'Perbaharui'
  },
  CHECK_OUT: {
    YOUR_ORDER: 'Pesanan Anda',
    PRODUCT: 'Produk',
    QUANTITY: 'Kuantitas',
    PRODUCT_PRICE: 'Harga Produk',
    YOUR_SHIPPING_ADDRESS: 'Alamat Pengiriman Anda',
    SHIPPING_COST: 'Estimasi Biaya Kirim',
    PAYMENT_SUMMARY: 'Ringkasan Pembayaran',
    TOTAL_PRODUCT_PRICE: 'Total Harga Produk',
    TOTAL_PAYMENT: 'Total Pembayaran',
    PAYMENT_CONFIRMATION: 'Konfirmasi Pembayaran',
    BACK_TO_HOME: 'Kembali ke halaman utama',
  },
  PAYMENT_CONFIRMATION_MIDTRANS: {
    PAYMENT: 'Pembayaran',
    AMOUNT: 'Jumlah',
    ORDER_ID: 'ID Pemesanan',
    ORDER_DETAILS: 'Detail Pemesanan',
    ITEM: 'Produk',
    AMMOUNT: 'Jumlah',
    SHIPPING_DETAILS: 'Alamat Detail',
    CONTINUE: 'Lanjutkan',
    TRANFER_BANK: 'Transfer Bank (Verifikasi Manual)',
    TRANFER_BANK_BCA: 'Transfer Bank BCA',
    TRANFER_BANK_MANDIRI: 'Transfer Bank Mandiri',
    TRANFER_BANK_BNI: 'Transfer Bank BNI',
    TRANFER_BANK_BRI: 'Transfer Bank BRI',
    TRANFER_VIRTUAL_ACCOUNT: 'Transfer Virtual Account',
    VIRTUAL_ACCOUNT_BANK_BCA: 'Virtual Account Bank BCA',
    VIRTUAL_ACCOUNT_BANK_MANDIRI: 'Virtual Account Bank Mandiri',
    BCA_VITUAL_ACCOUNT: 'BCA Virtual Account',
    VIRTUAL_ACCOUNT_NUMBER: 'Virtual Account Number',
    COPY: 'Copy',
    TOTAL_PAYMENT: 'Total Payment',
    ATM_BCA: 'ATM BCA',
    M_BCA: 'm-BCA (BCA Mobile)',
    INTERNET_BANKING_BCA: 'Internet Banking BCA',
    BCA_BANK_OFFICE: 'BCA BANK OFFICE',
    PLEASE_COMPLATE_PAYMENT: 'Silahkan Selesaikan Pembayaran'
  },
  MY_ACCOUNT: {
    NAVIGATION: 'Navigasi',
    MY_ACCOUNT: 'Akun Saya',
    MY_ADDRESS: 'Alamat Saya',
    MY_TRANSACTION: 'Transaksi Saya',
    SEARCH_ADDRESS: 'Cari Alamat',
    YOUR_NAME: 'Nama Anda',
    EMAIL: 'Email',
    PHONE_NO: 'Nomor Telepon',
    PREFERRED_LANGUAGE: 'Pilih Bahasa',
    ENGLISH: 'Inggris',
    CHANGE_PASSWORD: 'Ubah Kata Sandi',
    PROVINCE: 'Provinsi',
    CITY: 'Kota',
    POSTAL_CODE: 'Kode Pos',
    FULL_ADDRESS: 'Alamat Lengkap',
    SAVE_CHANGES: 'Simpan Perubahan',
    GENERAL_INFORMATION: 'Informasi Umum',
    COMPANY_NAME: 'Nama Perusahaan',
    USERNAME: 'Nama Pengguna',
    FULL_NAME: 'Nama Lengkap',
    ROLE: 'Jabatan',
    USER_ID: 'Identitas Pengguna',
    PERSONAL_INFORMATION: 'Informasi Pribadi',
    DIRECTORATE: 'Direktoreat',
    NIP: 'NIP',
    POSITION: 'Jabatan',
    CERTIFICATE_PJB: 'Nomor Sertifikat PBJ',
    WORK_UNIT_INFORMATION: 'Informasi Unit Kerja',
    WORK_UNIT: 'Unit Kerja',
    WORK_UNIT_NPWP: 'NPWP Unit Kerja',
    WORK_UNIT_ADDRESS: 'Alamat Unit Kerja',
    SHIPPING_ADDRESS: 'Alamat Pengiriman',
    SHIPPING_FULL_ADDRESS: 'Alamat Lengkap Pengiriman'
  },
  MY_TRANSACTION: {
    NAVIGATION: 'Navigasi',
    MY_ACCOUNT: 'Akun Saya',
    MY_TRANSACTION: 'Transaksi Saya',
    SEARCH_TRANSACTION_CODE: 'Cari Kode Transaksi',
    SELECT_TRANSACTION_DATE: 'Pilih Tanggal Transaksi',
    STATUS: 'Status',
    ALL_STATUS: 'Semua Status',
    WAITING_FOR_PAYMENT: 'Menunggu Pembayaran',
    ON_PROGRESS: 'Dalam Proses',
    ON_SHIPPING: 'Dalam Pengiriman',
    COMPLATED: 'Selesai',
    CANCELED: 'Batal',
    ORDER_ID: 'ID Pemesanan',
    ORDER_DATE: 'Tanggal Pemesanan',
    TOTAL_PRODUCT: 'Total Produk',
    QUANTITY: 'Kuantitas',
    TOTAL_PAYMENT: 'Jumlah Total',
    ACTION: 'Aksi',
    MY_SHIPPING_ADDRESS: 'Alamat Pengiriman Saya',
    PROGGRESS: 'Progress',
    ALL_PROGRESS: 'Semua Perkembangan',
    NEGOTIATION_PROGGRESS: 'Perkembangan Negosiasi',
    NEGOTIATED: 'Dinegosiasikan',
    SHIPPING_FEE: 'Biaya Pengiriman',
    TOTAL_AMOUNT: 'Jumlah Total',
    TRANSACTION_DETAIL: 'Detail Transaksi',
    TRANSACTION_SUMMARY: 'Ringkasan Transaksi',
    PRINT: 'Cetak',
  },
  TABLE_MY_TRANSACTION: {
    ORDERID: 'ID Pesanan',
    PURCHASED_DATE: 'Tanggal Pembelian',
    INSTANCE: 'Instansi',
    PIC: 'Penanggung Jawab',
    TRANSACTION_AMOUNT: 'Jumlah Transaksi',
    LAST_STATUS: 'Tanggal Status Terakhir',
    STATUS: 'Status',
    ACTION: 'Aksi'
  },
  MY_TRANSACTION_DETAIL: {
    NAVIGATION: 'Navigasi',
    MY_ACCOUNT: 'Akun Saya',
    MY_TRANSACTION: 'Transaksi Saya',
    TRANSACTION: 'Transaksi',
    ORDER_ID: 'ID Pemesanan',
    PURCHASED_DATE: 'Tanggal/Waktu Pembelian',
    RECEIPT_NO: 'Nomor Tanda Terima',
    STATUS: 'Status',
    YOUR_ORDER: 'Pesanan Anda',
    PRODUCT: 'Produk',
    QUANTITY: 'Kuantitas',
    PRODUCT_PRICE: 'Harga Produk',
    TOTAL_PRICE: 'Total Harga',
    YOUR_SHIPPING_ADDRESS: 'Alamat Pengiriman Anda',
    PAYMENT_DETAILS: 'Detail Pembayaran',
    PAYMENT_METHOD: 'Metode Pembayaran',
    TOTAL_PRODUCT_PRICE: 'Total Harga Produk',
    SHIPPING_COST: 'Biaya Pengiriman',
    TOTAL_PAYMENT: 'Total Pembayaran',
    MAIN_INFORMATION: 'Showcase Produk',
    PRODUCT_SHOWCASE: 'Menampilkan Produk',
    PACKAGE_ID: 'ID Paket',
    RUP_ID: 'ID RUP',
    PACKAGE_NAME: 'Nama Paket',
    INSTANCE: 'Instansi',
    WORK_UNIT: 'Unit Kerja',
    WORK_UNIT_NPWP: 'NPWP Unit Kerja',
    WORK_UNIT_ADDRESS: 'Alamat Unit Kerja',
    SHIPPING_ADDRESS: 'Alamat Pengiriman',
    CREATED_DATE: 'Tanggal Pembuatan',
    MODIFIED_DATE: 'Tanggal Diubah',
    BUDGET_YEAR: 'Tahun Anggaran',
    TOTAL_PRODUCT: 'Total Produk',
    NEGOTIAION_PRICE: 'Negotiation Price',
    NEGOTIAION_HISTORY: 'Harga Negosiasi',
    REVISION: 'Revisi',
    BY: 'Oleh',
    NEXT: 'Selanjutnya',
    BUYER_INFORMATION: 'Informasi Buyer',
    BUYER_NAME: 'Nama Buyer',
    ROLE: 'Role',
    PRODUCT_QUANTITY: 'Kuantitas Produk',
    CART_TOTAL: 'Total Keranjang',
    SUB_TOTAL: 'Subtotal',
    SHIPPING: 'Pengiriman',
    BACK_TO_TRANSACTION: 'Kembali ke Transaksi',
    BUYER_PPK: 'Buyer & PPK',
    PP_COMMITTEE: 'PP/Komite',
    NAME: 'Nama',
    POSITION: 'Jabatan',
    NIP: 'NIP',
    EMAIL: 'Email',
    PHONE_NO: 'Nomor Telepon',
    CERTIFICATE_PJB: 'Nomor Sertifikat PBJ',
    BACK: 'Kembali',
    VENDOR_DISTRIBUTOR: 'Vendor/Distributor',
    VENDOR: 'Vendor',
    NPWP: 'NPWP',
    FAX: 'FAX',
    DISTRIBUTOR_RESELLER: 'Distrubutor/Pengecer/Pelaksana Pekerjaan/Kurir',
    ADDRESS: 'Alamat',
    WEBSITE: 'Website',
    MY_SHIPPING_ADDRESS: 'Alamat Pengiriman Saya',
    REV_ID: 'Rev ID',
    WAITING_ADMIN_ADD: 'Menunggu Admin Menambahkan Biaya',
    DELIVERY_TIME: 'Waktu Pengiriman',
    PRODUCT_DETAIL: 'Detail Produk',
    NOTE: 'Catatan',
    PPK: 'PPK',
    LABEL_ADDRESS: 'Label Alamat',
    SHIPPING_TOTAL: 'Total Pengiriman',
    NEGOTIAION_FEE_HISTORY: 'Riwayat Negosiasi Biaya',
    BARGAIN_PRICE: 'Negosiasi Harga',
    APPROVE: 'Menyetujui'
  },
  MY_SHIPPING_ADDRESS: {
    SEARCH_ADDRESS: 'Cari Alamat',
    ADD_NEW_ADDRESS: 'Tambahkan Alamat Baru',
    CREATE_NEW_ADDRESS: 'Tambah Alamat Baru',
    MAIN_ADDRESS: 'Alamat Utama',
    SET_MAIN_ADDRESS: 'Jadikan Alamat Utama',
    DELETE: 'Hapus',
    EDIT: 'Edit',
    LABEL_ADDRESS: 'Label Alamat',
    PROVINCE: 'Provinsi',
    CITY_DISTRICT: 'Kota/Kabupaten',
    FULL_ADDRESS: 'Full Address',
    STATUS: 'Status',
    ADD_EDIT: 'Tambahkan / Edit',
    FUND_SOURCE: 'Sumber Dana',
    BUDGET_SOURCE: 'Sumber Anggaran',
    BUDGET_CODE: 'Kode Anggaran',
    NEGOTIAION_PRICE: 'Harga Negosiasi',
    NEGOTIAION_HISTORY: 'Riwayat Negosiasi',
    REVISION: 'Revisi',
    BY: 'Oleh',
    TOTAL_PRICE: 'Total Harga',
    BACK: 'Kembali',
    YOUR_ORDER: 'Pesanan Anda',
    PRODUCT: 'Produk',
    QUANTITY: 'Kuantitas',
    PRODUCT_PRICE: 'Harga Produk',
    BUYER_INFORMATION: 'Informasi Buyer',
    BUYER_NAME: 'Nama Buyer',
    ROLE: 'Role',
    PRODUCT_QUANTITY: 'Kuantitas Produk',
    CART_TOTAL: 'Total Keranjang',
    SUB_TOTAL: 'Subtotal',
    SHIPPING: 'Pengiriman',
    TOTAL_PAYMENT: 'TTotal Pembayaran',
    BACK_TO_TRANSACTION: 'Kembali ke Transaksi'
  },
  MY_TRANSACTION_TRACKING: {
    SHIPPING_HISTORY: 'Riwayat Pengiriman',
    UPDATE_DATE_TIME: 'Tanggal Waktu Pembaruan',
    LOCATION: 'Lokasi',
    MESSAGE_FROM_LOGISTIC: 'Pesan dari Logistik',
    CLOSE: 'Tutup'
  },
  MY_TRANSACTION_DONE: {
    NAVIGATION: 'Navigasi',
    MY_ACCOUNT: 'Akun Saya',
    MY_TRANSACTION: 'Transaksi Saya',
    TRANSACTION: 'Transaksi',
    ORDER_ID: 'ID Pemesanan',
    PURCHASED_DATE: 'Tanggal/Waktu Pembelian',
    RECEIPT_NO: 'Nomor Tanda Terima',
    STATUS: 'Status',
    YOUR_ORDER: 'Pesanan Anda',
    PRODUCT: 'Produk',
    QUANTITY: 'Kuantitas',
    PRODUCT_PRICE: 'Harga Produk',
    TOTAL_PRICE: 'Total Harga',
    YOUR_SHIPPING_ADDRESS: 'Alamat Pengiriman Anda',
    BACK: 'Kembali'
  },
  MY_TRANSACTION_CANCELED: {
    NAVIGATION: 'Navigasi',
    MY_ACCOUNT: 'Akun Saya',
    MY_TRANSACTION: 'Transaksi Saya',
    TRANSACTION: 'Transaksi',
    ORDER_ID: 'ID Pemesanan',
    PURCHASED_DATE: 'Tanggal/Waktu Pembelian',
    RECEIPT_NO: 'Nomor Tanda Terima',
    STATUS: 'Status',
    YOUR_ORDER: 'Pesanan Anda',
    PRODUCT: 'Produk',
    QUANTITY: 'Kuantitas',
    PRODUCT_PRICE: 'Harga Produk',
    TOTAL_PRICE: 'Total Harga',
    YOUR_SHIPPING_ADDRESS: 'Alamat Pengiriman Anda',
    BACK: 'Kembali',
    LKPP_TRANSACTION: 'Transaksi LKPP',
    SEARCH_TRANSACTION_CODE: 'Cari Kode Transaksi',
    SELECT_TRANSACTION_DATE: 'Pilih Tanggal Transaksi',
    ALL_PROGRESS: 'Semua Perkembangan',
    PROGGRESS: 'Perkembangan',
    NEGOTIATION_PROGGRESS: 'Perkembangan Negosiasi',
    NEGOTIATED: 'Dinegosiasikan',
    CANCELED: 'Batal',
    ORDER_DATE: 'Tanggal Pemesanan',
    TOTAL_PRODUCT: 'Jumlah Product',
    TOTAL_AMOUNT: 'Jumlah Total',
    ACTION: 'Aksi',
    SHIPPING_COST: 'Biaya Pengiriman',
  },
  MY_TRANSACTION_APPROVE: {
    APPROVE_NEGOTIATION: 'Menyetujui Negosiasi',
    TYPE_TEXT: 'Ketiik “SETUJU” lalu klik tombol Menyetujui, untuk menyetujui negosiasi',
    TYPE_TEXT_BUTTON: 'Ketik “SETUJU” untuk konfirmasi',
    APPROVE: 'Menyetujui'
  },
  MY_TRANSACTION_NEGOTIATION: {
    NAVIGATION: 'Navigasi',
    MY_ACCOUNT: 'Akun Saya',
    MY_TRANSACTION: 'Transaksi Saya',
    LKPP_TRANSACTION: 'Transaksi LKPP',
    SEARCH_TRANSACTION_CODE: 'Cari Kode Transaksi',
    SELECT_TRANSACTION_DATE: 'Pilih Tanggal Transaksi',
    PROGGRESS: 'Perkembangan',
    NEGOTIATION_PROGGRESS: 'Perkembangan Negosiasi',
    NEGOTIATED: 'Dinegosiasikan',
    STATUS: 'Status',
    ALL_STATUS: 'Semua Status',
    WAITING_FOR_PAYMENT: 'Menunggu Pembayaran',
    ON_SHIPPING: 'Dalam Pengiriman',
    COMPLATED: 'Selesai',
    CANCELED: 'Batal',
    ORDER_ID: 'ID Pemesanan',
    ORDER_DATE: 'Tanggal Pemesanan',
    TOTAL_PRODUCT: 'Jumlah Product',
    QUANTITY: 'Kuantitas',
    TOTAL_AMOUNT: 'Jumlah Total',
    ACTION: 'Aksi',
    SHIPPING_FEE: 'Biaya Pengiriman',
  },
  MY_TRANSACTION_NEGOTIATION_DETAIL: {
    NAVIGATION: 'Navigasi',
    MY_ACCOUNT: 'Akun Saya',
    MY_TRANSACTION: 'Transaksi Saya',
    LKPP_TRANSACTION: 'Transaksi LKPP',
    TRANSACTION: 'Transaksi',
    REV_ID: 'Rev ID',
    PURCHASED_DATE: 'Tanggal/Waktu Pembelian',
    WAITING_ADMIN_ADD: 'Menunggu Admin Menambahkan Biaya',
    DELIVERY_TIME: 'Waktu Pengiriman',
    YOUR_ORDER: 'Pesanan Anda',
    PRODUCT: 'Produk',
    PRODUCT_NAME: 'Nama Product',
    PRODUCT_PRICE: 'Harga Produk',
    PROGGRESS: 'Progress',
    NEGOTIATION_PROGGRESS: 'Perkembangan Negosiasi',
    NEGOTIATED: 'Dinegosiasikan',
    STATUS: 'Status',
    ALL_STATUS: 'Semua Status',
    WAITING_FOR_PAYMENT: 'Menunggu Pembayaran',
    ON_SHIPPING: 'Dalam Pengiriman',
    COMPLATED: 'Selesai',
    CANCELED: 'Batal',
    ORDER_ID: 'ID Pemesanan',
    ORDER_DATE: 'Tanggal Pemesanan',
    TOTAL_PRODUCT: 'Jumlah Product',
    QUANTITY: 'Kuantitas',
    TOTAL_AMOUNT: 'Jumlah Total',
    ACTION: 'Aksi',
    SHIPPING_FEE: 'Biaya Pengiriman',
    SHIPPING_COST: 'Biaya Pengiriman',
    TOTAL_PAYMENT: 'TTotal Pembayaran',
    BARGAIN_PRICE: 'Negosiasi Harga',
    APPROVE: 'Menyetujui',
    TOTAL_PRICE: 'Total Harga',
    NOTE: 'Catatan',
    YOUR_SHIPPING_ADDRESS: 'Alamat Pengiriman Anda',
    PAYMENT_DETAILS: 'Detail Pembayaran',
    NEGOTIAION_FEE_HISTORY: 'Riwayat Negosiasi Biaya',
    TOTAL_PRODUCT_PRICE: 'Total Harga Produk',
    CANCEL_BERGAIN: 'Batalkan Negosiasi',
    SUBMIT_BARGAIN_PRICE: 'Ajukan Harga Negosiasi'
  },
  MY_TRANSACTION_NEGOTIATED: {
    NAVIGATION: 'Navigasi',
    MY_ACCOUNT: 'Akun Saya',
    MY_TRANSACTION: 'Transaksi Saya',
    LKPP_TRANSACTION: 'Transaksi LKPP',
    SEARCH_TRANSACTION_CODE: 'Cari Kode Transaksi',
    SELECT_TRANSACTION_DATE: 'Pilih Tanggal Transaksi',
    ALL_PROGRESS: 'Semua Perkembangan',
    TRANSACTION: 'Transaksi',
    REV_ID: 'Rev ID',
    PURCHASED_DATE: 'Tanggal/Waktu Pembelian',
    WAITING_ADMIN_ADD: 'Menunggu Admin Menambahkan Biaya',
    DELIVERY_TIME: 'Waktu Pengiriman',
    YOUR_ORDER: 'Pesanan Anda',
    PRODUCT: 'Produk',
    PRODUCT_NAME: 'Nama Product',
    PRODUCT_PRICE: 'Harga Produk',
    PROGGRESS: 'Progress',
    NEGOTIATION_PROGGRESS: 'Perkembangan Negosiasi',
    NEGOTIATED: 'Dinegosiasikan',
    STATUS: 'Status',
    ALL_STATUS: 'Semua Status',
    WAITING_FOR_PAYMENT: 'Menunggu Pembayaran',
    ON_SHIPPING: 'Dalam Pengiriman',
    COMPLATED: 'Selesai',
    CANCELED: 'Batal',
    ORDER_ID: 'ID Pemesanan',
    ORDER_DATE: 'Tanggal Pemesanan',
    TOTAL_PRODUCT: 'Jumlah Product',
    QUANTITY: 'Kuantitas',
    TOTAL_AMOUNT: 'Jumlah Total',
    ACTION: 'Aksi',
    SHIPPING_FEE: 'Biaya Pengiriman',
    SHIPPING_COST: 'Biaya Pengiriman',
    TOTAL_PAYMENT: 'TTotal Pembayaran',
    BARGAIN_PRICE: 'Negosiasi Harga',
    APPROVE: 'Menyetujui',
    TOTAL_PRICE: 'Total Harga',
    NOTE: 'Catatan',
    YOUR_SHIPPING_ADDRESS: 'Alamat Pengiriman Anda',
    PAYMENT_DETAILS: 'Detail Pembayaran',
    NEGOTIAION_FEE_HISTORY: 'Riwayat Negosiasi Biaya',
    TOTAL_PRODUCT_PRICE: 'Total Harga Produk',
    CANCEL_BERGAIN: 'Batalkan Negosiasi',
    SUBMIT_BARGAIN_PRICE: 'Ajukan Harga Negosiasi'
  },
  OFFICE_FITOUT: {
    HOME: 'Beranda',
    SHOP_BY_PRODUCT: 'Sortir Berdasarkan Produk',
    SHOP_BY_RANGE: 'Sortir Berdasarkan Harga',
    SALE: 'Diskon',
    OFFICE_FITS_OUT: 'Penyesuaian Ruang Kantor',
    OUR_PROJECT: 'Projek Kmai',
    CONTACT_US: 'Kontak Kami',
    CHAT_WITH_US: 'Diskusi dengan Kami',
    MAKE_US_PROJECT: 'Jadikan Kami Bagian dari Projek Anda',
    OUR_FRIEND: 'Tim kami akan mengKontak Anda.',
    SEND_INQUIRY: 'Kirimkan Permintaan',
  },
  PROJECT: {
    HOME: 'Beranda',
    SHOP_BY_PRODUCT: 'Sortir Berdasarkan Produk',
    SHOP_BY_RANGE: 'Sortir Berdasarkan Harga',
    SALE: 'Diskon',
    OFFICE_FITS_OUT: 'Penyesuaian Ruang Kantor',
    OUR_PROJECT: 'Projek Kami',
    READ: 'Baca Selengkapnya...',
    CONTACT_US: 'Kontak Kami',
    CHAT_WITH_US: 'Diskusi dengan Kami',
    CORPORATE_OFFICE_SPACE: 'Ruang Kantor Perusahaan'
  },
  ABOUT_US: {
    HOME: 'Beranda',
    SHOP_BY_PRODUCT: 'Sortir Berdasarkan Produk',
    SHOP_BY_RANGE: 'Sortir Berdasarkan Harga',
    SALE: 'Diskon',
    OFFICE_FITS_OUT: 'Penyesuaian Ruang Kantor',
    OUR_PROJECT: 'Projek Kami',
    CONTACT_US: 'Kontak Kami',
    CHAT_WITH_US: 'Diskusi dengan Kami',
    CORPORATE_OFFICE_SPACE: 'Ruang Kantor Perusahaan'
  },
  CONTACT_US: {
    HOME: 'Beranda',
    SHOP_BY_PRODUCT: 'Sortir Berdasarkan Produk',
    SHOP_BY_RANGE: 'Sortir Berdasarkan Harga',
    SALE: 'Diskon',
    OFFICE_FITS_OUT: 'Penyesuaian Ruang Kantor',
    OUR_PROJECT: 'Projek Kami',
    CONTACT_US: 'Kontak Kami',
    CHAT_WITH_US: 'Diskusi dengan Kami',
    CORPORATE_OFFICE_SPACE: 'Ruang Kantor Perusahaan',
    HEAD_OFFICE: 'Kantor Pusat',
    EMAIL: 'Email',
    PHONE_NO: 'Nomor Telepon',
    ADDRESS: 'Alamat',
    SEND_US_MEDDAGE: 'Kirim Pesan Kepada Kami',
    SEND_MESSAGE: 'Kirim Pesan',
    SEE_WHY: 'Lihat mengapa layanan pelanggan kami benar-benar membedakan kami. Tim kami siap membantu dengan pertanyaan apa pun yang Anda miliki!'
  },

  DAYS: {
    DAY1: 'Sunday',
    DAY2: 'Monday',
    DAY3: 'Tuesday',
    DAY4: 'Wednesday',
    DAY5: 'Thursday',
    DAY6: 'Friday',
    DAY7: 'Saturday'
  },
  MESSAGE_TITLE: {
    CONFIRMATION: 'Confirmation',
  },
  MESSAGE: {
    DELETE: 'Are you sure you want to Delete?',
    DELETE_FAILED: 'Failed to Delete',
    DELETE_SUCCESS: 'Success Delete',
    CANCEL: 'Are you sure you want to Cancel?',
    CANCEL_FAILED: 'Failed to Cancel',
    CANCEL_SUCCESS: 'Success to Cancel',
    LOGOUT: "Are you sure want to Logout?",
    TOKEN_FAILED: "Token is numeric combination with 8 characters.",
    NO_FILES: "",
    PRINT: 'Make sure your printer device is already connected as default print. This setting will be your setting for printing in this session.',
    PRINT_OPTION: 'PRINT OPTION',
    CREATE_LOCATION: 'Create New Location',
    ADD_LOCATION: 'Add Location',
    DETAIL_LOCATION: 'Detail Location',
    EDIT_LOCATION: 'Edit Location',
    NEW_SUPPLIER: 'New Supplier',
    ADD_SUPPLIER: 'Add Supplier',
    DETAIL_SUPPLIER: 'Detail Supplier',
    EDIT_SUPPLIER: 'Edit Supplier',
    ADD_PACKTYPE: 'Add Packing Type',
    DETAIL_PACKTYPE: 'Detail Packing Type',
    EDIT_PACKTYPE: 'Edit Packing Type',
    IMAGE_NOTFOUND: 'Sorry, image not found!',
    IMAGE_PREVIEW: 'Image Preview',
    ADD_AREAGROUP: 'Add Area Grouping',
    DETAIL_AREAGROUP: 'Detail Area Grouping',
    EDIT_AREAGROUP: 'Edit Area Grouping',
    NEW_PRODUCT: 'New Product',
    ADD_PRODUCT: 'Add Product',
    DETAIL_PRODUCT: 'Detail Product',
    EDIT_PRODUCT: 'Edit Product',
    CATEGORY_PRODUCT: 'Product Category',
    SELECT_PRODUCT: 'Select Product',
    SELECT_PRODUCT_TYPE: 'Select Product Type',
    ADD_PARTPOINT: 'Add Part Point',
    NEW_PARTPOINT: 'New Part Point',
    ADD_PALLET: 'Add Pallet Id',
    ADD_BUSSINESS: 'Add Bussiness Closure',
    NEW_BUSSINESS: 'New Bussiness Closure',
    WORKING_DAY: 'Working Day',
    ADD_EVENT: 'Add Event',
    NEW_EVENT: 'New Event',
    APPROVE_MSG: 'Are you sure want to approve this data?',
    REJECT_MSG: 'Are you sure want to reject this data?',
    APPROVE_CONF: 'Approval Confirmation',
    REJECT_CONF: 'Reject Confirmation'
  },
  DAYS: {
    DAY1: 'Minggu',
    DAY2: 'Senin',
    DAY3: 'Selasa',
    DAY4: 'Rabu',
    DAY5: 'Kamis',
    DAY6: 'Jumat',
    DAY7: 'Sabtu'
  },
  MESSAGE_TITLE: {
    CONFIRMATION: 'Konfirmasi',
  },
  MESSAGE: {
    DELETE: 'Apakah anda yakin ingin melakukan penghapusan?',
    DELETE_FAILED: 'Penghapusan Gagal',
    DELETE_SUCCESS: 'Penghapusan Berhasil',
    CANCEL: 'Apakah anda yakin ingin melakukan pembatalan?',
    CANCEL_FAILED: 'Pembatalan Gagal',
    CANCEL_SUCCESS: 'Pembatalan Berhasil',
    TOKEN_FAILED: "Token harus merupakan akan dengan 8 karakter.",
    LOGOUT: "Apakah anda yakin ingin keluar?",
    PRINT: 'Pastikan perangkat printer anda telah terhubung sebagai default print. Setingan ini akan menjadi setingan mu pada saat mengeprint.',
    PRINT_OPTION: 'PENGATURAN PRINT',
    CREATE_LOCATION: 'Tambah Lokasi Terbaru',
    ADD_LOCATION: 'Tambah Lokasi',
    DETAIL_LOCATION: 'Detil Lokasi',
    EDIT_LOCATION: 'Edit Lokasi',
    NEW_SUPPLIER: 'Supplier Baru',
    ADD_SUPPLIER: 'Tambah Supplier',
    DETAIL_SUPPLIER: 'Detail Supplier',
    EDIT_SUPPLIER: 'Edit Supplier',
    NEW_SUPPLIER: 'Supplier Baru',
    ADD_SUPPLIER: 'Tambah Supplier',
    DETAIL_SUPPLIER: 'Detail Supplier',
    EDIT_SUPPLIER: 'Edit Supplier',
    ADD_PACKTYPE: 'Tambah Packing Type',
    DETAIL_PACKTYPE: 'Detail Packing Type',
    EDIT_PACKTYPE: 'Edit Packing Type',
    IMAGE_NOTFOUND: 'Maaf, Gambar Tidak Ditemukan!',
    IMAGE_PREVIEW: 'Pratinjau Gambar',
    ADD_AREAGROUP: 'Tambah Area Grouping',
    DETAIL_AREAGROUP: 'Detail Area Grouping',
    EDIT_AREAGROUP: 'Edit Area Grouping',
    NEW_PRODUCT: 'Produk Baru',
    ADD_PRODUCT: 'Tambah Produk',
    DETAIL_PRODUCT: 'Detail Produk',
    EDIT_PRODUCT: 'Edit Produk',
    CATEGORY_PRODUCT: 'Kategori Produk',
    SELECT_PRODUCT: 'Pilih Produk',
    SELECT_PRODUCT_TYPE: 'Pilih Tipe Produk',
    ADD_PARTPOINT: 'Tambah Part Point',
    NEW_PARTPOINT: 'Part Point Baru',
    ADD_PALLET: 'Tambah Pallet Id',
    ADD_BUSSINESS: 'Tambah Bussiness Closure',
    NEW_BUSSINESS: 'Bussiness Closure Baru',
    WORKING_DAY: 'Hari Kerja',
    ADD_EVENT: 'Tambah Event',
    NEW_EVENT: 'Event Baru',
    APPROVE_MSG: 'Apakah anda yakin ingin meng approve data ini?',
    REJECT_MSG: 'Apakah anda yakin ingin mereject data ini?',
    APPROVE_CONF: 'Konfirmasi Approval',
    REJECT_CONF: 'Konfirmasi Reject'
  },
  COMMON_BUTTON: {
    BACK: "Kembali",
    SUBMIT: "Kirim",
    OK: "OK",
    NO: "TIDAK",
    YES: "YA",
    LOGOUT: 'Keluar',
    FILTER: 'CARI',
    RESET: 'ATUR ULANG',
    SAVE: 'SIMPAN',
    CLOSE: 'TUTUP',
    CREATE: 'Kirim',
    UPDATE: "Perbarui",
    DELETE: 'Hapus',
    CONFIRM: 'Konfirmasi',
    SEARCH: "Pencarian",
    SETTING: 'Pengaturan'
  },
  TABLE: {
    DATA_NOT_FOUND: 'Data tidak ditemukan'
  },
  PAGETITLE: {
    DASHBOARD: {
      ROOT: 'Halaman Utama',
      TASK_ONGOING: {
        TRANSFER: 'Transfer Berlangsung',
        PAYROLL: 'Penggajian Berlangsung',
        REVIEW: 'Peninjauan Berlangsung',
        APPROVE: 'Persetujuan Berlangsung',
        BULK_TRANSFER: 'Bulk Transfer Berlangsung',
      },
      TASK_PAST: {
        TRANSFER: 'Transfer Masa lalu',
        PAYROLL: 'Penggajian Masa lalu',
        REVIEW: 'Peninjauan Masa lalu',
        APPROVE: 'Persetujuan Masa lalu',
        BULK_TRANSFER: 'Bulk Transfer Masa lalu',
      }
    },
    ACCOUNT: {
      DETAIL: "Akun Detail",
      HISTORY: {
        ROOT: 'Riwayat Transaksi Elektronik',
        TRANSFER: 'Transfer',
        PAYROLL: 'Penggajian',
        PAYROLL_DETAIL: 'Detail Riwayat Penggajian',
        PAYROLL_TARGET: 'Tujuan Penggajian',
      },
      STATEMENT: "Mutasi Rekening",
      SCHEDULED: {
        ROOT: "Transaksi Terjadwal",
        TRANSFER: "Transfer Terjadwal",
        PAYROLL: "Penggajian Terjadwal",
        CANCEL: "Pembatalan Transaksi Terjadwal",
      },
    },
    TRANSFER: {
      ROOT: 'Transfer',
      BENEFICIARY: {
        ROOT: 'Daftar Penerima Transfer',
        EDIT: "Edit Daftar Penerima Transfer",
        ADD: "Tambah Daftar Penerima Transfer",
        DETAIL: 'Detail Daftar Penerima Transfer',
        CONFIRM: 'Konfirmasi Daftar Penerima Transfer',
        RESULT: 'Hasil Konfirmasi Daftar Penerima Transfer',
      },
      MAKE_TRANSFER: {
        ADD: "Tambah Transfer",
        NOTE: "Catatan",
        CONFIRM: "Konfirmasi Transfer",
        RESULT: "Hasil Konfirmasi",
        DETAIL: 'Detail Transfer'
      },
    },
    BULK_TRANSFER: {
      ROOT: "Bulk Transfer",
      LIST: 'Daftar Bulk Transfer',
      ADD: 'Tambah Daftar Bulk Transfer',
      EDIT: 'Edit Daftar Bulk Transfer',
      DETAIL: 'Detail Bulk Transfer',
      STATUS: 'Status Upload Bulk Transfer',
      STATUS_LIST: 'Daftar Status',
      EMPLOYEE: 'Konfirmasi Bulk Transfer',
      EMPLOYEE_LIST: 'Daftar Penerima',
      EMPLOYEE_DETAIL: 'Detail Penerima',
      EMPLOYEE_ADD: 'Tambah Penerima',
      EMPLOYEE_EDIT: 'Edit Penerima',
      AUTODEBIT: 'Auto Debit',
      AUTODEBIT_LIST: ' Daftar Auto Debit',
      AUTODEBIT_ADD: 'Tambah Auto Debit',
      AUTODEBIT_EDIT: 'Edit Auto Debit',
      AUTODEBIT_DETAIL: 'Detail Auto Debit',
      BULK: 'Penambahan Massal',
      BULK_NOTE: 'Catatan'
    },
    PAYROLL: {
      ROOT: "Penggajian",
      LIST: 'Daftar Penggajian',
      ADD: 'Tambah Daftar Penggajian',
      EDIT: 'Edit Daftar Penggajian',
      DETAIL: 'Detail Penggajian',
      STATUS: 'Status Upload Penggajian',
      STATUS_LIST: 'Daftar Status',
      EMPLOYEE: 'Konfirmasi Pembayaran Penggajian',
      EMPLOYEE_LIST: 'Daftar Karyawan',
      EMPLOYEE_DETAIL: 'Detail Karyawan',
      EMPLOYEE_ADD: 'Tambah Karyawan',
      EMPLOYEE_EDIT: 'Edit Karyawan',
      AUTODEBIT: 'Auto Debit',
      AUTODEBIT_LIST: ' Daftar Auto Debit',
      AUTODEBIT_ADD: 'Tambah Auto Debit',
      AUTODEBIT_EDIT: 'Edit Auto Debit',
      AUTODEBIT_DETAIL: 'Detail Auto Debit',
      BULK: 'Penambahan Massal',
      BULK_NOTE: 'Catatan'
    },
    DISBURSEMENT: {
      ROOT: "Pembayaran Penggajian",
      CONFIRM: 'Konfirmasi Pembayaran Penggajian',
      RESULT: 'Pembayaran Penggajian'
    },
    SETTING: {
      CHANGE_PASSWORD: "Ganti Sandi",
      BRANCH_LOCATION: "Lokasi Cabang",
      TERM_CONDITION: "Syarat dan Ketentuan",
      FAQ: "Pertanyaan umum (FAQ)",
      CONTACT_US: "Kontak Kami",
    }

  },
  LOADING: {
    TITLE: "Mengumpulkan data.",
    WAIT: "Harap menunggu."
  },
  VERIFY: {
    TITLE_SUCCESS: "Akun Anda telah Terverifikasi",
    BODY_SUCCESS: "Anda sudah bisa masuk dengan akun Anda",
    TITLE_FAILED: "Akun Anda belum terverifikasi",
    BODY_FAILED: "Silahkan Kontak Administrator",
    HOME: 'Masuk ke Beranda',

  }
}

export default common