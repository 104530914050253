import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box

} from '@mui/material'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { BreadCrumbs } from 'components'
import { useContactUs, useLanguage } from 'hooks'
import moment from 'moment'
import Slide from '@mui/material/Slide';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function Index() {
  const [language, lagDic, setLang] = useLanguage()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form, setform] = useState({});
  const [open, setOpen] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, createData] = useContactUs(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  const classes = useStyles()
  const [isSubmitted, setisSubmitted] = useState(false);
  const today = moment().format('YYYY-MM-DD');


  const breadcrumb = [
    {
      title: "Home", to: "/furnitur"
    }
  ]
  const handleChange = (event) => {
    setisSubmitted(false)
    setform({
      ...form,
      [event.target.name]: event.target.value
    })
  }
  const handleChangePhone = (value, data, event, formattedValue) => {
    const number = formattedValue.replace(/\s/g, '')
    let phone = number
    // if (number.charAt(3) === '0') {
    //   phone = replaceWith(number, 3)
    // }
    setisSubmitted(false)
    setform({
      ...form,
      [event.target.name]: phone
    })
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if (form?.phone.length >= 10) {
      setisSubmitted(true)
      const param = {
        ...form,
        ACTION_DATE: today
      }
      console.log("🚀 ~ file: index.jsx ~ line 63 ~ useEffect ~ param", param)
      createData(param, 'create')

      Object.keys(form).forEach(function (key) {
        form[key] = '';
        form['phone'] = '+62'
      })
    } else {
      setOpenFailed(true)
    }
  }

  useEffect(() => {

    const param = {
      page: 1,
      limit: 10
    }
    setDatatables(param)
  }, [])

  // useEffect(() => {
  //   if (isSubmitted) {
  //     const param = {
  //       ...form,
  //       ACTION_DATE: today
  //     }
  //     console.log("🚀 ~ file: index.jsx ~ line 63 ~ useEffect ~ param", param)
  //     createData(param, 'create')
  //   }

  // }, [isSubmitted, form])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setOpenFailed(false);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <div className={classes.container}>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Send Message Successful
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openFailed} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Send Message Failed
        </Alert>
      </Snackbar>
      <div style={{ paddingBottom: 20 }}>
        <BreadCrumbs
          breadcrumb={breadcrumb}
          current={language.COMMON.MENU.CONTACT_US}
        />
      </div>
      <Typography variant="body1" color="initial"><strong>{language.COMMON.CONTACT_US.CONTACT_US}</strong></Typography>
      <Grid container spacing={10}>
        <Grid item xl={7} lg={7} md={6} sm={12}>
          {datatables ?
            <React.Fragment>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                <strong>
                  {language.COMMON.CONTACT_US.HEAD_OFFICE}
                </strong>
              </Typography>
              <Typography variant="body1" color="initial" >
                {datatables?.[0]?.VALUE_01}
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                <strong>{language.COMMON.CONTACT_US.EMAIL}</strong>
              </Typography>
              <Typography variant="body1" color="initial" >
                {datatables?.[0]?.VALUE_06}
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                <strong>{language.COMMON.CONTACT_US.PHONE_NO}</strong>
              </Typography>
              <Typography variant="body1" color="initial" >
                {datatables?.[0]?.VALUE_04}
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                <strong>{language.COMMON.CONTACT_US.ADDRESS}</strong>
              </Typography>
              <Typography variant="body1" color="initial" >
                {datatables?.[0]?.VALUE_09}
              </Typography>
            </React.Fragment>
            :
            <React.Fragment>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                empty
              </Typography>
            </React.Fragment>
          }
        </Grid>
        <Grid direction={'column'} item xl lg sm md>
          <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
            <strong>
              {language.COMMON.CONTACT_US.SEND_US_MEDDAGE}
            </strong>
          </Typography>
          <Typography variant="body1" color="initial" sx={{  width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"}, paddingTop: 2 }}>
            {language.COMMON.CONTACT_US.SEE_WHY}
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              sx={{ width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"}, paddingTop: 2 }}
              id="firstname"
              required
              value={form.firstname || ''}
              color='info'
              placeholder={language.COMMON.FIELDTEXT.FIRST}
              name='firstname'
              size='small'
              onChange={handleChange}
            />
            {/* <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">Email <strong style={{ color: 'red' }}>*</strong></Typography> */}
            <TextField
              sx={{ width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"}, paddingTop: 2, paddingBottom: 2 }}
              id="lastname"
              required
              value={form.lastname || ''}
              color='info'
              name='lastname'
              placeholder={language.COMMON.FIELDTEXT.LAST}
              size='small'
              onChange={handleChange}
            />
            {/* <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">Phone Number <strong style={{ color: 'red' }}>*</strong></Typography> */}
            <Box sx={{ width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"} }}>
              <PhoneInput
                country={'id'}
                disableDropdown
                onlyCountries={['id']}
                countryCodeEditable={false}
                inputStyle={{ width: '100%' }}
                inputProps={{
                  required: true,
                  autoFocus: true,
                  value: form.phone || '+62',
                  name: 'phone',
                  id: "phone"
                }}
                onChange={handleChangePhone}
                isValid={(value) => {
                  if (value.length <= 10) {
                    return 'Phone number contain at least 10 digit';
                  } else {
                    return true;
                  }
                }}
              />
            </Box>
            <TextField
              sx={{ width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"}, paddingTop: 2 }}
              id="email"
              required
              type={'email'}
              color='info'
              value={form.email || ''}
              name='email'
              placeholder={language.COMMON.FIELDTEXT.EMAIL}
              size='small'
              onChange={handleChange}
            />
            <TextField
              sx={{width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"}, paddingTop: 2 }}
              id="comment"
              required
              color='info'
              value={form.comment || ''}
              name='comment'
              multiline
              placeholder={language.COMMON.FIELDTEXT.COMMENT}
              size='small'
              onChange={handleChange}
            />
            <Button type='submit' sx={{ marginTop: 2, width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"} }} color='info' variant="contained" >
              {language.COMMON.CONTACT_US.SEND_MESSAGE}
            </Button>
          </form>
        </Grid>
      </Grid>

    </div>
  )
}

export default Index
