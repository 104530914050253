import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { Grid, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import useStyles from "./styles"
import chairsExample from "assets/images/chair.jpg"
import chairsExample2 from "assets/images/chair2.jpg"
import { useNavigate } from 'react-router';
import noImage from "assets/images/noImage.jpeg"

import { useSubCategory, useRange } from 'hooks'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    padding: theme.spacing(2),
    width: '100%',
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus({ anchorEl, close, multiple, setMultiple, setAnchorEl }) {
  const open = Boolean(anchorEl);
  const classes = useStyles()
  const [selectedMenu, setSelectedMenu] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [catCode, setCatCode] = useState('');
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getDetail] = useSubCategory(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [isLoading2, setIsLoading2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [datatables2, setDatatables2] = useRange(setIsLoading2, setError, setOpen3, setDialogType, setTitle, setMessage)

  const handleClose = () => {
    close()
    setSelectedMenu([])
  };
  const handleSelectedMenu = (val) => {
    setSelectedMenu([])
    setTimeout(() => {
      setSelectedMenu(val)
    },  100);
  }

  const handlePageType = (code, name, type, ref, functionality) => {
    navigate({
      pathname: '/furnitur/product',
      search: `?act=List&subCategory=${code}&category=${name}&ref=${ref}&selectedType=${type}${functionality ? '&functionality=' + functionality : ''}`
    })
  }

  const handlePageShopByRange = (code, name) => {
    navigate({
      pathname: '/furnitur/shop-by-range',
      search: `?act=List&subCode=${code}&category=${name}`
    })
  }

  useEffect(() => {

    const param = {
      page: 1,
      limit: 100
    }
    setDatatables(param,)
  }, [])

  useEffect(() => {

    const param2 = {
      page: 1,
      limit: 100
    }
    setDatatables2(param2)
  }, [])



  const navigate = useNavigate()
  const baseUrlImage = window._env_?.BASEURL_IMAGE

  return (
    <div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      > {
          multiple ?
            <Grid container spacing={3}>
              <Grid item xl={2} lg={2} md={2} sm={12} xs={12} >
                {datatables?.map((item, idx) =>
                  item?.LIST_FUNCTIONALITY?.length > 0 ?
                    <MenuItem
                      onMouseOver={() => {
                        setCatCode(item?.REF_ID)
                        handleSelectedMenu(item?.LIST_FUNCTIONALITY)
                      }}
                      onClick={() => {
                        setCatCode(item?.REF_ID)
                        handleSelectedMenu(item?.LIST_FUNCTIONALITY)
                     }}
                      disableRipple
                      style={{ justifyContent: 'space-between' }}>
                      {item?.CATEGORY_NAME}
                      <ArrowRight />
                    </MenuItem>
                    :
                    <MenuItem
                      onMouseOver={() => { setSelectedMenu() }}
                      onClick={() => {
                        handlePageType(item?.CATEGORY_CODE, item?.CATEGORY_NAME, 'none', catCode)
                        setAnchorEl(false)
                      }} disableRipple>
                      {item?.CATEGORY_NAME}
                    </MenuItem>
                )}
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <Divider orientation='vertical'></Divider>
              </Grid>
              {
                selectedMenu?.length > 0 &&
                <Grid item xl={9} lg={9} md={9} sm={12} xs={12} className={classes.subMenuMultiple}>
                  <Grid container sx={{width: "100%"}}>
                    <ArrowLeft onClick={() =>{setSelectedMenu([])}} sx={{ display: { xs: 'block', md: 'none', lg: 'none' , xl: "none"}, position: 'absolute', top: '0' }}></ArrowLeft>
                    {
                      selectedMenu?.map((item, idx) =>
                        <Grid
                          onClick={() => {
                            handlePageType(item?.REF_ID, item?.REF_NAME, 'functionality', catCode, item?.CATEGORY_CODE)
                            setAnchorEl(false)
                          }}
                          item xl={4} lg={4} md={6} sm={6} xs={6} className={classes.button}
                          >

                          <img
                            style={{ width: 50, height: 'auto', paddingRight: 10 }}
                            src={`${baseUrlImage}${item?.IMG_PATH}${item?.IMG_THUMB}`}
                            onError={event => {
                              event.target.src = noImage
                              event.onerror = null
                            }}
                            alt="" />
                          <Typography sx={{ overflowWrap: 'normal', maxWidth: '300px', overflow: 'hidden' }}>{item?.CATEGORY_NAME}</Typography>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              }
            </Grid>
            :
            <Grid container >
              {
                datatables2?.map((item, idx) =>
                  <Grid
                    onClick={() => {
                      handlePageShopByRange(item?.CATEGORY_CODE, item?.CATEGORY_NAME)
                      setAnchorEl(false)
                    }}
                    item xl={3} lg={3} md={4} sm={6} xs={6} className={classes.button}>
                    <img
                      src={`${baseUrlImage}${item?.IMG_PATH}${item?.IMG_THUMB}`}
                      height={120}
                      width={60}
                      style={{ objectFit: 'scale-down', paddingRight: 10 }}
                      onError={event => {
                        event.target.src = noImage
                        event.onerror = null
                      }}
                      alt="" />
                    <Typography >{item?.CATEGORY_NAME}</Typography>
                  </Grid>
                )
              }
            </Grid>
        }
      </StyledMenu>
    </div>
  );
}
