import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Grid,
  TextField,
  Typography,
  ButtonGroup,
  Button,
  Box,
  Divider,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  IconButton,
  Select
} from '@mui/material'
import NumberFormat from 'react-number-format';
import SearchIcon from '@mui/icons-material/Search';
import noImage from "assets/images/noImage.jpeg"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { rupiahFormat, getAccessLog, getDayNow } from 'helpers'
import { InputComponent, Snackbar } from 'components';
import queryString from 'query-string'
import { useLocation } from 'react-router'
import { HandlerModal } from 'components';
import { useProfile, useShippingCost, useLookup, useAddress, useInput, useLanguage, useCart } from 'hooks'
import { validateSign } from 'controllers/_validate';
import DefaultStructure from "./defaultStructure"

function Index(props) {
  const {
    setisCheckedout,
    handleSubmit,
    data,
    dataProfile,
    setafterUpdate,
    afterUpdate
  } = props
  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  const [handleFieldChange, values] = useInput(validateSign, {})
  console.log("🚀 ~ file: index.jsx ~ line 64 ~ Index ~ values", values)
  const classes = useStyles()
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);
  const baseUrlImage = window._env_?.BASEURL_IMAGE
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [costShipping, setCostShipping] = useState([]);
  const [totalShip, setTotalShip] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datas, setDatas, makeDatas] = useAddress(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage);
  const [datatables, setDatatables] = useShippingCost(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables2, setDatatables2, getData] = useProfile(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables3, setDatatables3, getDetail] = useCart(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [lookup, setLookup] = useLookup(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage);
  const [provinsiOptions, setProvinsiOptions] = useState([])
  const [kabupatenOptions, setKabupatenOptions] = useState([])
  const [formCreate, setformCreate] = useState({})
  console.log("🚀 ~ file: index.jsx ~ line 84 ~ Index ~ formCreate", formCreate)
  console.log("🚀 ~ file: index.jsx ~ line 80 ~ Index ~ lookup", lookup)

  console.log("🚀 ~ file: index.jsx ~ line 74 ~ Index ~ datatables", datatables)

  const [open, setOpen] = useState(false);
  const [openSB, setOpenSB] = useState(false);
  const [messageSB, setMessageSB] = useState("");
  const [openShip, setOpenShip] = useState({});
  console.log("🚀 ~ file: index.jsx ~ line 81 ~ Index ~ openShip", openShip)
  const [cartQty, setCartQty] = useState({});
  const [cartQtyAfterShipping, setCartQtyAfterShipping] = useState({});
  console.log("🚀 ~ file: index.jsx ~ line 69 ~ Index ~ cartQty", cartQty)
  const [rows, setRows] = useState([]);
  const [actualAddress, setActualAddress] = useState([])
  console.log("🚀 ~ file: index.jsx ~ line 101 ~ Index ~ actualAddress", actualAddress)
  // console.log("🚀 ~ file: index.jsx ~ line 68 ~ Index ~ addresses", addresses, dataProfile?.[0]?.ADDRESS)
  const [checkAllProduct, setCheckAllProduct] = useState(false)
  const [onCreateAddress, setOnCreateAddress] = useState(false)
  const [cartList, setCartList] = useState([])
  console.log("🚀 ~ file: index.jsx ~ line 104 ~ Index ~ checkAllProduct", checkAllProduct)
  useEffect(() => {
    if (datatables3?.length > 0 && onCreateAddress == false) {
      setCartList(datatables3.sort((a, b) => a.PRODUCT_INFO?.[0]?.ITEM_NAME.localeCompare(b.PRODUCT_INFO?.[0]?.ITEM_NAME)))
    }
  }, [datatables3])
  useEffect(() => {
    const param = {
      CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
      // CUSTOMER_NAME: "Maudy A",
      // CUSTOMER_NO: "00000009",
      USERNAME: getAccessLog('username'),
      page: 1,
      limit: 100
    }
    setDatatables2(param)
  }, [datas])
  useEffect(() => {
    if (datatables2.length > 0 && onCreateAddress === false) {
      const param = {
        CUSTOMER_NO: datatables2?.[0]?.CUSTOMER_NO,
        USERNAME: getAccessLog('username'),
        IS_CONTRACT: true,
        page: 1,
        limit: 100
      }
      setDatatables3(param)
    }
  }, [datatables2])
  useEffect(() => {
    if (addresses?.length > 0) {
      let result = []
      let addressesAddress = []
      addresses?.map((val) => {
        addressesAddress.push(val?.ID)
      })
      result = datatables2?.[0]?.ADDRESS?.filter( function( el ) {
        return addressesAddress.indexOf( el.ID ) < 0;
      } );
      setActualAddress(result)
    } else {
      setActualAddress(datatables2?.[0]?.ADDRESS)
    }
  }, [addresses, datatables2])
  React.useEffect(() => {
    const rowData = cartList ?? []
    if (rowData.length > 0) setRows(rowData)
    else setRows([])
  }, [])

  useEffect(() => {
    const param = {
      page: 1,
      limit: 1000
    }
    setLookup(param, 'provinsi')
  }, [setLookup])

  useEffect(() => {
    if (values?.ADDRESS_PROVINSI) {
      const param = {
        "ADDRESS_PROVINSI": values?.ADDRESS_PROVINSI
      }
      setLookup(param, 'kabupaten')
    }
  }, [values?.ADDRESS_PROVINSI])

  const handleChange = (event) => {
    setformCreate((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }))
  }

  useEffect(() => {
    if (lookup?.provinsi) {
      setProvinsiOptions(lookup?.provinsi?.data ?? [])
    }
    if (lookup?.kabupaten) {
      setKabupatenOptions(lookup?.kabupaten?.data ?? [])
    }
  }, [lookup])

  const handleCreate = (e) => {
    e.preventDefault()

    setOnCreateAddress(true)
    const param = {
      "CUSTOMER_NO": datatables2?.[0]?.CUSTOMER_NO,
      "REFERENCE_OF": "CUSTOMER",

      "ADDRESS_NAME": values?.ADDRESS_NAME,

      "ADDRESS_PROVINSI": values?.ADDRESS_PROVINSI,
      "ADDRESS_KABUPATEN": values?.ADDRESS_KABUPATEN,
      "ADDRESS_KECAMATAN": "",
      "ADDRESS_KELURAHAN": "",
      "ADDRESS": values?.ADDRESS,
      "ZIPCODE": values?.ZIPCODE,
      "IS_CURRENT": values?.IS_CURRENT ? values?.IS_CURRENT === 'YES' ? true : false : false,

      "ACTION_BY": getAccessLog('username'),
      "ACTION_DATE": getDayNow('complate'),
      "ADDITIONAL_INFO": "",
      "IS_DUMP": false,
      "ACTIVE_STATUS": true
    }
    console.log("🚀 ~ file: index.jsx ~ line 141 ~ handleCreateAddress ~ param", param)
    makeDatas(param, 'create')
    const param2 = {
      CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
      // CUSTOMER_NAME: "Maudy A",
      // CUSTOMER_NO: "00000009",
      USERNAME: getAccessLog('username'),
      page: 1,
      limit: 100
    }
    setDatatables2(param2)
    if (!error) {
      setOpen(false);
    }

  }

  useEffect(() => {

    values["ADDRESS_NAME"] = ""
    values["ADDRESS_PROVINSI"] = ""
    values["ADDRESS_KABUPATEN"] = ""
    values["ADDRESS_KECAMATAN"] = ""
    values["ADDRESS_KELURAHAN"] = ""
    values["ADDRESS"] = ""
    values["ZIPCODE"] = ""

  }, [afterUpdate])

  useEffect(() => {
    actualAddress?.map((item) => {
      if (item.IS_CURRENT === true) {
        setSelectedAddress(item)
      }
    })
  }, [actualAddress])


  const shippingLocation = (loc) => {
    let cost = 0
    costShipping.map((item) => {
      if (item?.LOC.toLowerCase() === loc.toLowerCase()) {
        cost = item.COST_PERQUBIC
      }
    })
    return cost
  }

  const sumShipment = () => {
    if (addresses.length > 0) {
      let total = 0
      for (let i = 0, _len = addresses.length; i < _len; i++) {
        total += addresses[i]['TOTAL_SHIPMENT_COST']
      }
      return total
    }
  }
  const sumPrice = () => {
    if (addresses.length > 0) {
      let total = 0
      for (let i = 0, _len = addresses.length; i < _len; i++) {
        for (let j = 0, _lenp = addresses[i]['PRODUCTS'].length; j < _lenp; j++) {
          total += (addresses[i]['PRODUCTS'][j]['ITEM_TOTAL'] * addresses[i]['PRODUCTS'][j]['ITEM_PRICE'])
        }
      }
      return total
    }
  }
  const sumQubic = (i) => {
    if (addresses.length > 0) {
      let total = 0
      for (let j = 0, _lenp = addresses[i]['PRODUCTS'].length; j < _lenp; j++) {
        total += (addresses[i]['PRODUCTS'][j]['ITEM_TOTAL'] * addresses[i]['PRODUCTS'][j]['PRODUCT_INFO'][0]['INFO_DIMENSION'])
      }
      return total.toFixed(2)
    }
  }

  const sumQty = () => {
    if (addresses.length > 0) {
      let total = 0
      for (let i = 0, _len = addresses.length; i < _len; i++) {
        for (let j = 0, _lenp = addresses[i]['PRODUCTS'].length; j < _lenp; j++) {
          total += addresses[i]['PRODUCTS'][j]['ITEM_TOTAL']
        }
      }
      return total
    }
  }
  const sumCartListQty = () => {
    if (cartList.length > 0) {
      let total = 0
      for (let i = 0, _len = cartList.length; i < _len; i++) {
        total += cartList[i]['ITEM_TOTAL']
      }
      return total
    }
  }

  const totalShipment = (index, qubic, cost, loc) => {
    console.log("🚀 ~ file: index.jsx ~ line 132 ~ totalShipment ~ cost", cost)
    console.log("🚀 ~ file: index.jsx ~ line 132 ~ totalShipment ~ qubic", qubic)
    let total = rupiahFormat(`${Math.round(qubic * cost)}`, 'Rp')
    console.log("🚀 ~ file: index.jsx ~ line 135 ~ totalShipment ~ total", total)

    if (costShipping.length !== 0) {
      costShipping.map((item) => {
        if (item.LOC === loc) {
          addresses[index]['SHIPMENT_ID'] = item?.ID
        }
      })
    }
    addresses[index]['SHIPMENT_COST'] = cost
    addresses[index]['TOTAL_SHIPMENT_COST'] = Math.round(qubic * cost)

    return total

  }
  const cartTotalShipment = addresses.length > 0 ? sumShipment() : 0
  const cartTotalQty = addresses.length > 0 ? sumQty() : 0
  const cartListItemTotalQty = cartList.length > 0 ? sumCartListQty() : 0
  const cartTotalPrice = addresses.length > 0 ? sumPrice() : 0
  console.log("🚀 ~ file: index.jsx ~ line 95 ~ Index ~ cartTotalPrice", cartTotalPrice)

  const handleConfirmShipping = () => {
    if (cartTotalShipment + cartTotalPrice >= 200000000 && getAccessLog('role') === 'PP') {
      setOpenValidate(true)
    } else {
      if (addresses.length > 0) {
        handleSubmit('shippinginfo', addresses)
      } else {
        setMessageSB("Add Shipping First!")
        setOpenSB(true)
      }
    }
  }

  useEffect(() => {
    if (Object.keys(selectedAddress).length !== 0) {
      console.log("🚀 ~ file: index.jsx ~ line 122 ~ useEffect ~ Object.keys(selectedAddress).length", Object.keys(selectedAddress).length)
      const param = {
        KEYWORD: selectedAddress?.ADDRESS_KABUPATEN,
        page: 1,
        limit: 100
      }
      setDatatables(param)

    }
  }, [selectedAddress])

  useEffect(() => {
    if (Object.keys(selectedAddress).length !== 0) {
      if (datatables.length > 0) {
        setCostShipping(prevState =>
          [
            ...prevState,
            {
              LOC: datatables?.[0]?.ADDRESS_KABUPATEN,
              COST_PERQUBIC: datatables?.[0]?.COST_PERQUBIC,
              ID: datatables?.[0]?.ID
            }
          ]
        )
      }
    }
  }, [selectedAddress, datatables])

  const handleDeleteShipping = (val) => {
    if (checkAllProduct !== true) {
      cartList.map((item) => {
        setCartQty((prevState) => ({
          ...prevState,
          [item?.ITEM_NO]: cartQty[item?.ITEM_NO] + counter[item?.ITEM_NO]
        }))
        setCartQtyAfterShipping((prevState) => ({
          ...prevState,
          [item?.ITEM_NO]: cartQty[item?.ITEM_NO] + counter[item?.ITEM_NO]
        }))
      })
      val?.PRODUCTS.map((prod) => {
        console.log("🚀 ~ file: index.jsx ~ line 194 ~ val?.PRODUCTS.map ~ item", cartQty[prod?.ITEM_NO] + prod?.ITEM_TOTAL)
        setCartQty((prevState) => ({
          ...prevState,
          [prod?.ITEM_NO]: cartQty[prod?.ITEM_NO] + prod?.ITEM_TOTAL + counter[prod?.ITEM_NO]
        }));
        setCartQtyAfterShipping((prevState) => ({
          ...prevState,
          [prod?.ITEM_NO]: cartQty[prod?.ITEM_NO] + prod?.ITEM_TOTAL + counter[prod?.ITEM_NO]
        }));
        console.log("===", cartQty)
      })
      const arr = addresses.filter((item) => {
        return item !== val
      })

      setAddresses(arr)
    } else {
      const arr = addresses.filter((item) => {
        return item !== val
      })

      setAddresses(arr)
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const [counter, setcounter] = useState({})
  console.log("🚀 ~ file: index.jsx ~ line 76 ~ Index ~ counter", counter)

  const handleIncrement = (event, itemno, row) => {
    console.log("🚀 ~ file: index.jsx ~ line 219 ~ handleIncrement ~ row", row?.ITEM_TOTAL)
    if (!counter[event.target.name]) {
      if (row?.ITEM_TOTAL === 0) {
      } else {
        setcounter({
          ...counter,
          [event.target.name]: 1
        })
        setCartQty((prevState) => ({
          ...prevState,
          [itemno]: cartQty[itemno] - 1
        }));

      }
    }
    else if (counter[event.target.name] < row?.ITEM_TOTAL) {
      setcounter({
        ...counter,
        [event.target.name]: counter[event.target.name] + 1
      });
      setCartQty((prevState) => ({
        ...prevState,
        [itemno]: cartQty[itemno] - 1
      }));
    }
  };

  const handleDecrement = (event, itemno) => {
    if (counter[event.target.name]) {
      if (counter[event.target.name] !== 0) {
        setcounter({
          ...counter,
          [event.target.name]: counter[event.target.name] - 1
        });
        setCartQty((prevState) => ({
          ...prevState,
          [itemno]: cartQty[itemno] + 1
        }));
      }
      else if (counter[event.target.name] === 0) {
        setCartQty((prevState) => ({
          ...prevState,
          [itemno]: cartQty[itemno] + 1
        }));
      }
    }
  };

  const handleInputQty = (event, row, value) => {
    console.log("🚀 ~ file: index.jsx ~ line 393 ~ handleInputQty ~ event", event)
    if (value !== "") {
      setcounter({
        ...counter,
        [event?.target?.name]: parseInt(value)
      });
    }
  }

  const handleOnBlur = (event, row, value) => {
    if (parseInt(cartQtyAfterShipping[row?.ITEM_NO]) - parseInt(event.target.value) >= 0) {
      setCartQty((prevState) => ({
        ...prevState,
        [row?.ITEM_NO]: parseInt(cartQtyAfterShipping[row?.ITEM_NO]) - parseInt(event.target.value)
      }));
    } else {
      setCartQty((prevState) => ({
        ...prevState,
        [row?.ITEM_NO]: parseInt(cartQtyAfterShipping[row?.ITEM_NO])
      }));
      setcounter({
        ...counter,
        [event?.target?.name]: 0
      });
    }
  }

  const handleClick = (id, val) => {
    // setOpenShip(!openShip);
    setOpenShip({ ...openShip, [id]: val });
  };

  const handleChangeAddress = (event) => {
    setSelectedAddress(event.target.value)
  }

  const handleAddShipping = () => {
    if (Object.keys(selectedAddress).length !== 0) {
      if (checkAllProduct === true) {
        const obj = { ...selectedAddress }
        const objProduct = JSON.parse(JSON.stringify(cartList));
        obj['PRODUCTS'] = objProduct
        setAddresses([
          ...addresses,
          obj
        ])
        setSelectedAddress({})

      } else {
        if (Object.values(counter).every(value => value === 0)) {
          setMessageSB("There is no item on the cart!")
          setOpenSB(true)
        } else {
          const isBelowThreshold = (currentValue) => currentValue.ITEM_TOTAL !== 0;
          console.log("🚀 ~ file: index.jsx ~ line 219 ~ handleAddShipping ~ isBelowThreshold", cartList?.every(isBelowThreshold))
          if (cartList?.find(isBelowThreshold)) {

            const obj = { ...selectedAddress }

            const objProduct = JSON.parse(JSON.stringify(cartList));
            objProduct?.map((item) => {
              const objCart = Object.keys(counter)
              objCart.map((property) => {
                if (item['ITEM_NO'] === property) {
                  item[`ITEM_TOTAL`] = counter[property]
                }
              })
            })
            const arr = objProduct.filter((item) => {
              return item?.ITEM_TOTAL !== 0
            })

            obj['PRODUCTS'] = [...arr]
            rows.map((item) => {
              const objCart2 = Object.keys(cartQty)
              objCart2.map((property) => {
                if (item['ITEM_NO'] === property) {
                  item[`ITEM_TOTAL`] = cartQty[property]
                }
              })
            })

            setAddresses([
              ...addresses,
              obj
            ])
            setSelectedAddress({})
            setcounter({})
            setCartQtyAfterShipping({
              ...cartQty
            });
          } else {
            setMessageSB("There is no item on the cart!")
            setOpenSB(true)
          }
        }
      }
    } else {
      setMessageSB("Add Address First!")
      setOpenSB(true)
    }
  }

  useEffect(() => {
    // if (queryParse.act === 'Shipping') {
      cartList.map((item) => {
        setCartQty((prevState) => ({
          ...prevState,
          [item?.ITEM_NO]: item?.ITEM_TOTAL
        }));
        setCartQtyAfterShipping((prevState) => ({
          ...prevState,
          [item?.ITEM_NO]: item?.ITEM_TOTAL
        }));
      })
    // }
  }, [cartList])

  useEffect(() => {
    cartList.map((item) => {
      setcounter((prevState) => ({
        ...prevState,
        [item?.ITEM_NO]: 0
      }));
    })
  }, [cartList, addresses])

  useEffect(() => {
    if (Object.keys(cartQty).length !== 0) {
      Object.values(cartQty).every(value => {
        if (value === 0) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      });

    }
  }, [cartQty])
  const bodyModal = () => {
    return (
      <div >
      </div>
    )
  }
  const [language, lagDic, setLang] = useLanguage()

  const handleClose2 = () => {
    setOpen2(false);
  };
  const [label, setLabel] = useState({
    positive: 'Close',
    negative: 'Close',
  })
  const [onAction, setOnAction] = useState({
    onClose: () => handleClose2(),
    positive: (body, type) => handleClose2(body, type),
    negative: () => handleClose2()
  })

  const [openValidate, setOpenValidate] = useState(false)
  return (
    <React.Fragment>
      <Snackbar
        open={openSB}
        setOpen={setOpenSB}
        message={messageSB}
        severity='warning'
      />
      <HandlerModal
        open={open2}
        body={bodyModal}
        onClose={handleClose2}
        dialogType={dialogType}
        message={message}
        title={title}
        label={label}
        action={onAction}
        error={error}
        loading={isLoading}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openValidate}
        onClose={() => { setOpenValidate(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign="center" >
          <strong>
            {language.COMMON.CART.DIALOG_TITLE_CONFIRM}
          </strong>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">
            {language.COMMON.CART.DIALOG_BODY_CONFIRM}
          </Typography>
          <Button sx={{ marginTop: 2 }} fullWidth size='small' color='info' variant='contained' onClick={() => { setOpenValidate(false) }} autoFocus>
            Back To Cart
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong>
            {language.COMMON.MY_SHIPPING_ADDRESS.CREATE_NEW_ADDRESS}
          </strong>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleCreate}>
            <InputComponent
              structure={DefaultStructure(values, handleFieldChange, provinsiOptions, kabupatenOptions)}
              isLoading={isLoading}
              error={props?.error}
              divider={1}
              padding={1}
              minScreen="md"
            // readOnlyAll
            />

            <Button type='submit' sx={{ marginTop: 2 }} fullWidth size='small' color='info' variant='contained' autoFocus>
              {language.COMMON.MY_SHIPPING_ADDRESS.CREATE_NEW_ADDRESS}
            </Button>
          </form>
        </DialogContent>
      </Dialog>


      {/* Content */}
      <Grid
        container spacing={2} >
        <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
          <Box
            sx={{
              py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
            }}
          >
            <Typography variant="body1" color="initial">{language.COMMON.CART.SELECT_ADDRESS}</Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 5 }}>
              <div>
                <Select
                  sx={{ width: '560px' }}
                  id="city"
                  disabled={addresses.length !== 0 && checkAllProduct ? true : false}
                  // value={age}
                  color='info'
                  name='cityEdit'
                  value={selectedAddress ?? ''}
                  size='small'
                  onChange={handleChangeAddress}
                >
                  {actualAddress?.map((item) => {
                    if (item?.IS_CURRENT === true) {
                      return (
                        <MenuItem value={item}>
                          <Grid container sx={{ width: '100%', justifyContent: 'space-between' }}>
                            <Grid item>
                              <Typography variant="body1" color="initial">
                                {item?.ADDRESS_NAME}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Box component="span" sx={{ py: 0.5, px: 1, border: '1px solid #1070ca', color: '#1070ca', borderRadius: 1 }}>
                                {language.COMMON.MY_SHIPPING_ADDRESS.MAIN_ADDRESS}
                              </Box>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      )
                    } else {
                      return (
                        <MenuItem value={item}>
                          {item?.ADDRESS_NAME}
                        </MenuItem>
                      )
                    }
                  })}
                </Select>
              </div>
              <div>
                <Button sx={{ mx: 2 }} color='info' onClick={() => setOpen(true)} variant="outlined" >
                  {language.COMMON.CART.ADD_ADDRESS}
                </Button>
              </div>
            </div>
            {Object.keys(selectedAddress).length === 0 && selectedAddress.constructor === Object ?
              null
              : <Box
                sx={{
                  py: 1, px: 2, my: 1, border: 'dashed 1px gray', borderRadius: '5px', width: '560px', backgroundColor: '#EBF2FF'
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, width: '100%' }}>
                  <div style={{ width: '35%' }}>
                    <Typography variant="body1" color="initial">{language.COMMON.CART.PROVINCE}</Typography>
                    <Typography variant="body1" color="initial">{language.COMMON.CART.DISTRICT_CITY}</Typography>
                    <Typography variant="body1" color="initial">{language.COMMON.CART.FULL_ADDRESS}</Typography>
                  </div>
                  <div>
                    <Typography variant="body1" color="initial">{selectedAddress?.ADDRESS_PROVINSI}</Typography>
                    <Typography variant="body1" color="initial">{selectedAddress?.ADDRESS_KABUPATEN}</Typography>
                    <Typography variant="body1" color="initial">{selectedAddress?.ADDRESS}</Typography>
                  </div>
                </div>
              </Box>
            }
          </Box>
          <FormControlLabel color='clarity' control={
            <Checkbox
              checked={checkAllProduct}
              disabled={addresses.length !== 0 ? true : false}
              onChange={(event) => { setCheckAllProduct(event.target.checked) }}
            />
          }
            label={language.COMMON.CART.SEND_ALL_ITEMS} />
          <TableContainer component={Grid} sx={{ opacity: checkAllProduct ? 0.5 : 1 }}>
            <Table size='small' sx={{ width: '100%', border: 'solid 2px #EBF2FF' }} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="center"><strong>{language.COMMON.CART.PRODUCT}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.PRICE}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.IN_CART}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.TOTAL}</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartList.map((row) => (
                  <TableRow
                    className={classes.table}
                    key={row?.ITEM_NO}
                    sx={{ width: '100%' }}
                  >
                    <TableCell sx={{ width: '40%' }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div>
                          <img
                            alt=''
                            className={classes.otherImageContent}
                            onError={event => {
                              event.target.src = noImage
                              event.onerror = null
                            }}
                            src={`${baseUrlImage}`} />
                        </div>
                        <div>
                          <Typography>
                            {row?.PRODUCT_INFO?.[0]?.ITEM_NAME}
                          </Typography>
                        </div>
                      </div>
                      <div style={{ paddingLeft: '60px' }}>
                        <TextField
                          disabled
                          fullWidth
                          multiline
                          value={row?.ADDITIONAL_INFO ?? ''}
                          size='small'
                          id="outlined-textarea"
                          InputProps={{ className: classes.input }}
                        />
                      </div>
                    </TableCell>
                    <TableCell variant='body' sx={{ alignItems: "flex-start", verticalAlign: 'top' }} align="center">{rupiahFormat(JSON.stringify(row?.ITEM_PRICE), 'Rp')}</TableCell>
                    <TableCell variant='body' sx={{ alignItems: "flex-start", verticalAlign: 'top' }} align="center">{cartQty[row?.ITEM_NO]}</TableCell>
                    <TableCell variant='body' sx={{ alignItems: "flex-start", verticalAlign: 'top' }} align="center">
                      <ButtonGroup style={{ color: '#1070ca', border: 'solid 1px #aeb7cb' }} color='clarity' size="small" aria-label="small outlined button group">
                        <Button disabled={checkAllProduct ? true : false} style={{ color: '#1070ca', border: '0px' }} name={row?.ITEM_NO} onClick={(event) => (handleDecrement(event, row?.ITEM_NO))}>-</Button>
                        <NumberFormat
                          style={{ width: 40, fontSize: 11, border: '0px' }}
                          value={counter[row?.ITEM_NO]}
                          isAllowed={(inputObj) => {
                            const { value } = inputObj;
                            if (value <= cartQty[row?.ITEM_NO]) return true;
                            return false
                          }}
                          onValueChange={(values, sourceInfo) => {
                            const { formattedValue, value } = values;
                            const { event, source } = sourceInfo;
                            handleInputQty(event, row, value)
                          }}
                          onBlur={(event, value) => {handleOnBlur(event, row, value)}}
                          name={row?.ITEM_NO}
                          customInput={TextField}
                          inputProps={{ min: 0, style: { textAlign: 'center' } }}
                          InputProps={{ disableUnderline: true }}
                          margin='dense'
                          size='small'
                          variant='standard'
                          displayType="input"
                          type="text"

                          allowNegative={false} />
                        {/* <Button style={{ width: '50px', color: 'black', border: 'solid 1px #aeb7cb' }} disabled>{counter[row?.ITEM_NO]}</Button> */}
                        <Button disabled={checkAllProduct ? true : cartQty[row?.ITEM_NO] === 0} style={{ color: '#1070ca', border: '0px' }} name={row?.ITEM_NO} onClick={(event) => { handleIncrement(event, row?.ITEM_NO, row) }} >+</Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            disabled={Object.values(cartQtyAfterShipping).every(value => value === 0)}
            sx={{ marginTop: 2, width: '310px', float: 'right' }} onClick={handleAddShipping} size='small' color='info' variant='contained' autoFocus>
            {language.COMMON.CART.ADD_SHIPPING}
          </Button>
        </Grid>


        <Grid item xl lg md sm xs>
          <Box
            sx={{
              py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
            }}
          >
            <Typography variant="body1" color="initial">{language.COMMON.CART.SHIPPING}</Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: 5, flexDirection: 'column' }}>
              {
                addresses.length > 0 ?
                  addresses.map((item, index) => {
                    return (
                      <List
                        sx={{ marginBottom: 1, width: '100%', bgcolor: '#F1F5F8', borderRadius: 1, border: 'solid 1px #B3BDCC' }}
                        component="nav"

                      >
                        <ListItemButton onClick={() => {

                          handleClick(index, !openShip?.[index]);
                        }} sx={{ height: 25 }}>
                          <ListItemText primary={item?.ADDRESS_NAME} />
                          {openShip[index] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openShip[index]} timeout="auto" unmountOnExit>
                          <List disablePadding>
                            <Box
                              sx={{
                                py: 1, px: 2, my: 1, width: '100%', bgcolor: '#FFFFFF'
                              }}
                            >
                              <table style={{ width: '100%' }}>
                                <tr>
                                  <td style={{ width: '20%' }}>
                                    <Typography variant="body1" color="initial">{language.COMMON.CART.PROVINCE}</Typography>
                                  </td>
                                  <td>
                                    <Typography variant="body1" color="initial">{item?.ADDRESS_PROVINSI}</Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography variant="body1" color="initial">{language.COMMON.CART.DISTRICT_CITY}</Typography>
                                  </td>
                                  <td>
                                    <Typography variant="body1" color="initial">{item?.ADDRESS_KABUPATEN}</Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography variant="body1" color="initial">{language.COMMON.CART.FULL_ADDRESS}</Typography>
                                  </td>
                                  <td>
                                    <Typography variant="body1" color="initial">{item?.ADDRESS}</Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography variant="body1" color="initial">{language.COMMON.CART.SHIPPING_COST}</Typography>
                                  </td>
                                  <td>
                                    <Typography variant="body1" color="initial"><strong>{sumQubic(index)} Kubik x {rupiahFormat(`${shippingLocation(item?.ADDRESS_KABUPATEN, index)}`, 'Rp')} = {totalShipment(index, sumQubic(index), shippingLocation(item?.ADDRESS_KABUPATEN), item?.ADDRESS_KABUPATEN)} </strong></Typography>
                                  </td>
                                </tr>
                              </table>
                            </Box>
                            <table style={{ width: '100%' }}>
                              <tr style={{ backgroundColor: '#F1F5F8' }}>
                                <th align='left' style={{ width: '50%' }}>
                                  <Typography sx={{ paddingLeft: 2 }} variant="body1" color="initial"><strong> {language.COMMON.CART.PRODUCT}</strong></Typography>
                                </th>
                                <th>
                                  <Typography variant="body1" color="initial"><strong> {language.COMMON.CART.PRICE}</strong></Typography>
                                </th>
                                <th>
                                  <Typography variant="body1" color="initial"><strong> {language.COMMON.CART.QUANTITY}</strong></Typography>
                                </th>
                                <th>
                                  <Typography variant="body1" color="initial"><strong> {language.COMMON.CART.TOTAL}</strong></Typography>
                                </th>
                              </tr>
                              {item?.PRODUCTS.map((prod) => {
                                console.log(prod)
                                return (
                                  <tr style={{ backgroundColor: '#FFFFFF' }}>
                                    <td >
                                      <Typography sx={{ paddingLeft: 2 }} variant="body1" color="initial">{prod?.PRODUCT_INFO?.[0]?.ITEM_NAME}</Typography>
                                    </td>
                                    <td align='center'>
                                      <Typography variant="body1" color="initial">{rupiahFormat(`${prod?.ITEM_PRICE}`, 'Rp')}</Typography>
                                    </td>
                                    <td align='center'>
                                      <Typography variant="body1" color="initial">{prod?.ITEM_TOTAL}</Typography>
                                    </td>
                                    <td align='center'>
                                      <Typography variant="body1" color="initial">{rupiahFormat(`${prod?.ITEM_TOTAL * prod?.ITEM_PRICE}  `, 'Rp')}</Typography>
                                    </td>
                                  </tr>

                                )
                              })}
                            </table>
                            <Button variant="contained" onClick={() => { handleDeleteShipping(item) }} size='small' sx={{ fontSize: 9, float: 'right', marginRight: 2 }} color="error">
                              {language.COMMON.CART.DELETE}
                            </Button>
                          </List>
                        </Collapse>
                      </List>
                    )
                  })
                  : null
              }
            </div>
          </Box>
          <Box
            sx={{
              py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
            }}
          >
            <Typography variant="body1" color="initial">{language.COMMON.CART.BUYER_INFORMATION}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
              <div>
                <Typography variant="body2" color="secondary">{language.COMMON.CART.BUYER_NAME}</Typography>
                <Typography variant="body2" color="secondary">{language.COMMON.CART.ROLE}</Typography>
              </div>
              <div>
                <Typography variant="body2" align='right' color="secondary">{getAccessLog('username')}</Typography>
                <Typography variant="body2" align='right' color="secondary">{getAccessLog('role')}</Typography>
              </div>
            </div>
          </Box>
          <Box
            sx={{
              py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
            }}
          >
            <Typography variant="body1" color="initial">{language.COMMON.CART.PRODUCT}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
              <div>
                <Typography variant="body2" color="secondary">{language.COMMON.CART.PRODUCT_QUANTITY}</Typography>
              </div>
              <div>
                <Typography variant="body2" align='right' color="secondary">{cartTotalQty}</Typography>
              </div>
            </div>
            <Divider sx={{ paddingTop: 1, marginBottom: 1 }} />
            <Typography variant="body1" color="initial">{language.COMMON.CART.CART_TOTAL}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
              <div>
                <Typography variant="body2" color="secondary">{language.COMMON.CART.SUB_TOTAL}</Typography>
                <Typography variant="body2" color="secondary">{language.COMMON.CART.SHIPPING_TOTAL}</Typography>
              </div>
              <div>
                <Typography variant="body2" align='right' color="secondary"> {rupiahFormat(`${cartTotalPrice}`, 'Rp')}</Typography>
                <Typography variant="body2" align='right' color="secondary">{rupiahFormat(`${cartTotalShipment}`, 'Rp')}</Typography>
              </div>
            </div>
            <Divider sx={{ paddingTop: 1, marginBottom: 1 }} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography variant="body2" color="secondary"><strong>{language.COMMON.CART.TOTAL_PAYMENT}</strong></Typography>
              </div>
              <div>
                <Typography variant="h5" color="secondary"><strong>{rupiahFormat(`${(cartTotalShipment + cartTotalPrice)}`, 'Rp')}</strong></Typography>
              </div>
            </div>
            {checkAllProduct === true
              ? <Button
                disabled={addresses.length === 0 ? true : false}
                sx={{ marginTop: 2, marginBottom: 2 }} onClick={() => { handleConfirmShipping() }} size='small' color='info' variant="contained" fullWidth >
                {language.COMMON.CART.SHIPPING_CONFIRMATION}
              </Button>
              : <Button
                disabled={ cartTotalQty !== cartListItemTotalQty}
                sx={{ marginTop: 2, marginBottom: 2 }} onClick={() => { handleConfirmShipping() }} size='small' color='info' variant="contained" fullWidth >
                {language.COMMON.CART.SHIPPING_CONFIRMATION}
              </Button>
            }

          </Box>
        </Grid>
      </Grid>

    </React.Fragment>
  )
}

export default Index
