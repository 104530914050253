import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {
  useNavigate,
} from "react-router-dom";

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function BasicBreadcrumbs(props) {
  const { breadcrumb,
    current } = props
  const navigate = useNavigate()
  console.log("🚀 ~ file: index.jsx ~ line 35 ~ BasicBreadcrumbs ~ breadcrumbs", props)
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumb?.map((item) => 
          <Link underline="hover" color="inherit" href={item?.to} onClick={() => { navigate(item?.to) }}>
            {item?.title}
          </Link>

        )}
        {current ?
          <Typography color="text.primary">{current}</Typography> :
          <Typography color="text.primary">Breadcrumbs</Typography>
        }
      </Breadcrumbs>
    </div>
  );
}
