export const addressConstants = {
    REGISTER_REQUEST: 'ADDRESS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'ADDRESS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'ADDRESS_REGISTER_FAILURE',

    GETALL_REQUEST: 'ADDRESS_GETALL_REQUEST',
    GETALL_SUCCESS: 'ADDRESS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ADDRESS_GETALL_FAILURE',

    DELETE_REQUEST: 'ADDRESS_DELETE_REQUEST',
    DELETE_SUCCESS: 'ADDRESS_DELETE_SUCCESS',
    DELETE_FAILURE: 'ADDRESS_DELETE_FAILURE',

    UPDATE_REQUEST: 'ADDRESS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ADDRESS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ADDRESS_UPDATE_FAILURE'
};
