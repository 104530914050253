import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Tabs,
  Button,
  Tab,
  Typography,
  Box,
  MenuItem,
  Select,
  ToggleButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAddress, useInput, useLanguage, useLookup, useProfileRetail } from "hooks";
import { HandlerModal, CustomBackdrop, InputComponent } from 'components';
import { validateSign } from "controllers/_validate";
import DefaultStructure from "./components/add/defaultStructure"
import DefaultStructureEdit from "./components/edit/defaultStructure"
import { getAccessLog, getDayNow } from 'helpers'
export default function BasicTabs(props) {
  const { handleClickOpen } = props
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datas, setDatas, makeDatas] = useAddress(setIsLoading, setError, setOpenDialog, setDialogType, setTitle, setMessage);
  const [selectedData, setSelectedData] = useState({})
  console.log("🚀 ~ file: index.jsx ~ line 45 ~ BasicTabs ~ selectedData", selectedData)
  const [handleFieldChange, values] = useInput(validateSign, {})
  console.log("🚀 ~ file: index.jsx ~ line 47 ~ BasicTabs ~ values", values)
  const [lookup, setLookup] = useLookup(setIsLoading, setError, setOpenDialog, setDialogType, setTitle, setMessage);
  console.log("🚀 ~ file: index.jsx ~ line 46 ~ BasicTabs ~ lookup", lookup)
  const [datatables2, setDatatables2, getData] = useProfileRetail(setIsLoading, setError, setOpenDialog, setDialogType, setTitle, setMessage)
  console.log("🚀 ~ file: index.jsx ~ line 44 ~ BasicTabs ~ datatables2", datatables2)
  const [state, setState] = useState({ text: '' })

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [provinsiOptions, setProvinsiOptions] = useState([])
  const [kabupatenOptions, setKabupatenOptions] = useState([])
  const [zipcodeOptions, setZipcodeOptions] = useState([])
  const [label, setLabel] = useState({
    positive: 'Close',
    negative: 'Close',
  })
  const [onAction, setOnAction] = useState({
    onClose: () => handleClose(),
    positive: (body, type) => handleClose(body, type),
    negative: () => handleClose()
  })

  function getZipcode() {
    let numbers = [];
    if (arguments.length < 2) {
      return numbers;
    }
    Array.prototype.sort.call(parseInt(arguments));
    let x = parseInt(arguments[0]);
    let y = parseInt(arguments[arguments.length - 1]);
    if (x === y) {
      numbers.push(x)
    } else {
      for (let i = x; i < y + 1; i++) {
        numbers.push(i.toString());
      }
    }
    return numbers;
  }

  // useEffect(() => {
  //   if(Object.keys(selectedData).length !== 0) {
  //     values['ADDRESS_NAME'] = selectedData?.ADDRESS_NAME
  //     values['ADDRESS_PROVINSI'] = selectedData?.ADDRESS_PROVINSI
  //     values['ADDRESS_KABUPATEN'] = selectedData?.ADDRESS_KABUPATEN
  //     values['ZIPCODE'] = selectedData?.ZIPCODE
  //     values['ADDRESS'] = selectedData?.ADDRESS
  //     values['IS_CURRENT'] = selectedData?.IS_CURRENT === true ? 'YES' : "NO"
  //     setOpenEdit(true)
  //   }
  // },[selectedData])
  useEffect(() => {
    if (localStorage.getItem('Retail') === "true") {
      const param = {
        CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
        CUSTOMER_NO: datatables2?.[0]?.CUSTOMER_NO,
        ADDRESS_NAME: state?.text ? state?.text : undefined,
        // CUSTOMER_NAME: "Maudy A",
        // CUSTOMER_NO: "00000009",
        USERNAME: getAccessLog('username'),
        page: 1,
        limit: 10
      }
      setDatatables2(param)
    }
  }, [state])

  useEffect(() => {
    const param = {
      page: 1,
      limit: 1000
    }
    // setDatas(param)
    setLookup(param, 'provinsi')
  }, [setDatas])
  
  useEffect(() => {
    if (values?.ADDRESS_PROVINSI) {
      const param = {
        "ADDRESS_PROVINSI": values?.ADDRESS_PROVINSI
      }
      setLookup(param, 'kabupaten')
    }
  }, [values?.ADDRESS_PROVINSI])

  useEffect(() => {
    if (values?.ADDRESS_KABUPATEN) {
      const param = {
        "ADDRESS_KABUPATEN": values?.ADDRESS_KABUPATEN
      }
      setLookup(param, 'zipcode')
    }
  }, [values?.ADDRESS_KABUPATEN])

  useEffect(() => {
    if (lookup?.provinsi) {
      setProvinsiOptions(lookup?.provinsi?.data ?? [])
    }
    if (lookup?.kabupaten) {
      setKabupatenOptions(lookup?.kabupaten?.data ?? [])
    }
    if (lookup?.zipcode) {
      setZipcodeOptions(getZipcode(lookup?.zipcode?.data?.[0]?.ZIPCODE_FROM, lookup?.zipcode?.data?.[0]?.ZIPCODE_TO)?? [])
    }
  }, [lookup])

  const handleSubmitDelete = () => {
    const param = {
      "ID": selectedData?.ID,
      "ACTION_BY": "sadmin"
    }
    makeDatas(param, 'delete')
    setOpen(false)
  }
  
  useEffect(() => {
    if (dialogType === 'ConfirmationDelete') {
      setLabel({
        positive: "Yes",
        negative: "Close",
      })
      setOnAction({
        onClose: () => handleClose(),
        positive: (e, value) => handleSubmitDelete(),
        negative: () => handleClose(),
        // onChange: (name, value) => setValues(name, value),
        // setValidation: (val) => setValidation(val)
      })
    } else {
      setLabel({
        positive: "Close",
        negative: "Close",
      })
      setOnAction({
        onClose: () => handleClose(),
        positive: () => handleClose(),
        negative: () => handleClose(),
        // onChange: (name, value) => setValues(name, value)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogType, setDialogType])

  const handleDelete = (e, type) => {
    console.log("🚀 ~ file: index.jsx ~ line 86 ~ handleDelete ~ e", e)
    setSelectedData(e)
    setDialogType("ConfirmationDelete")
    setTitle("Delete Data")
    setOpenDialog(true)
    setMessage("Are you sure want to delete this data ?")
  }
  const bodyModal = () => {
    return (
      <div >
      </div>
    )
  }

  const handleClose = () => {
    const param = {
      CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
      // CUSTOMER_NAME: "Maudy A",
      // CUSTOMER_NO: "00000009",
      USERNAME: getAccessLog('username'),
      page: 1,
      limit: 10
    }
    setDatatables2(param)
    setSelectedData({})
    setOpenDialog(false)
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setSelectedData({})
    setOpenEdit(false);
  };

  const handleCreateAddress = (e) => {

    e.preventDefault()
    if (localStorage.getItem('Retail') === "true") {
      const param = {
        "CUSTOMER_NO": datatables2?.[0]?.CUSTOMER_NO,
        "REFERENCE_OF": "CUSTOMER",

        "ADDRESS_NAME": values?.ADDRESS_NAME,

        "ADDRESS_PROVINSI": values?.ADDRESS_PROVINSI,
        "ADDRESS_KABUPATEN": values?.ADDRESS_KABUPATEN,
        "ADDRESS_KECAMATAN": "",
        "ADDRESS_KELURAHAN": "",
        "ADDRESS": values?.ADDRESS,
        "ZIPCODE": values?.ZIPCODE,
        "IS_CURRENT": values?.IS_CURRENT ? values?.IS_CURRENT === 'YES' ? true : false : false,

        "ACTION_BY": "admin",
        "ACTION_DATE": getDayNow('complate'),
        "ADDITIONAL_INFO": "",
        "IS_DUMP": false,
        "ACTIVE_STATUS": true
      }
      console.log("🚀 ~ file: index.jsx ~ line 141 ~ handleCreateAddress ~ param", param)
      makeDatas(param, 'create')
      setOpen(false)
    }

  }
  const handleEditAddress = (e) => {
    e.preventDefault()
    const param = {
      "ID": selectedData?.ID,
      "CUSTOMER_NO": datatables2?.[0]?.CUSTOMER_NO,
      "REFERENCE_OF": selectedData?.REFERENCE_OF,

      "ADDRESS_NAME": values?.ADDRESS_NAME,

      "ADDRESS_PROVINSI": values?.ADDRESS_PROVINSI,
      "ADDRESS_KABUPATEN": values?.ADDRESS_KABUPATEN,
      "ADDRESS_KECAMATAN": selectedData?.ADDRESS_KECAMATAN,
      "ADDRESS_KELURAHAN": selectedData?.ADDRESS_KELURAHAN,
      "ADDRESS": values?.ADDRESS,
      "ZIPCODE": values?.ZIPCODE,
      "IS_CURRENT": values?.IS_CURRENT ? values?.IS_CURRENT === 'YES' ? true : false : false,

      "ACTION_BY": "admin",
      "ACTION_DATE": getDayNow('complate'),
      "ADDITIONAL_INFO": "",
      "IS_DUMP": false,
      "ACTIVE_STATUS": true
    }
    console.log("🚀 ~ file: index.jsx ~ line 141 ~ handleCreateAddress ~ param", param)
    makeDatas(param, 'update')
    setSelectedData({})
    setOpenEdit(false);

  }
  const handleEditTrigger = (e) => {
    setSelectedData(e)
    values['ADDRESS_NAME'] = e?.ADDRESS_NAME
    values['ADDRESS_PROVINSI'] = e?.ADDRESS_PROVINSI
    values['ADDRESS_KABUPATEN'] = e?.ADDRESS_KABUPATEN
    values['ZIPCODE'] = e?.ZIPCODE
    values['ADDRESS'] = e?.ADDRESS
    values['IS_CURRENT'] = e?.IS_CURRENT === true ? 'YES' : "NO"
    setOpenEdit(true)
  }
  const [language, lagDic, setLang] = useLanguage()
  return (
    <React.Fragment>

      {/* CREATE NEW ADDRESS */}
      <HandlerModal
        open={openDialog}
        body={bodyModal}
        onClose={handleClose}
        dialogType={dialogType}
        message={message}
        title={title}
        label={label}
        action={onAction}
        error={error}
        loading={isLoading}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <CustomBackdrop loading={isLoading} label={'Collecting Resource'} />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <strong>
            {"Create New Address"}
          </strong>
          <Button onClick={() => { setOpen(false) }} endIcon={<CloseIcon color="error"></CloseIcon>}></Button>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleCreateAddress}>
            <InputComponent
              structure={DefaultStructure(values, handleFieldChange, provinsiOptions, kabupatenOptions, zipcodeOptions, language)}
              isLoading={isLoading}
              error={props?.error}
              divider={1}
              padding={1}
              minScreen="md"
            // readOnlyAll
            />
            <Button type='submit' sx={{ marginTop: 2 }} fullWidth size='small' color='info' variant='contained' autoFocus>
              Create
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      {/* EDIT ADDRESS */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <strong>
            {"Edit Address"}
          </strong>
          <Button onClick={handleCloseEdit} endIcon={<CloseIcon color="error"></CloseIcon>}></Button>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleEditAddress}>
            <InputComponent
              structure={DefaultStructureEdit(values, handleFieldChange, provinsiOptions, kabupatenOptions, zipcodeOptions, language)}
              isLoading={isLoading}
              error={props?.error}
              divider={1}
              padding={1}
              minScreen="md"
            // readOnlyAll
            />
            <Button type='submit' sx={{ marginTop: 2 }} fullWidth size='small' color='info' variant='contained' autoFocus>
              update
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          mt:2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
        }}
      >
        <Typography variant="body1" color="initial"><strong>{language.COMMON.MY_ACCOUNT.MY_ADDRESS}</strong></Typography>
        <Grid container style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Grid item >
            <TextField
              sx={{ width: 300, paddingTop: 2, paddingRight: 3 }}
              id="address"
              color='info'
              onChange={(e) => {
                setState({ ...state, text: e.target.value })
              }}
              value={state.text}
              name='Address'
              placeholder={language.COMMON.MY_ACCOUNT.SEARCH_ADDRESS}
              size='small'
            />

          </Grid>

          <Grid item  >
            <Button sx={{ marginTop: 2, width: 200, whiteSpace: 'nowrap' }} color='info' onClick={() => setOpen(true)} variant="contained" >
            {language.COMMON.MY_SHIPPING_ADDRESS.ADD_NEW_ADDRESS}
            </Button>
          </Grid>
        </Grid>
        {
          datatables2?.[0]?.ADDRESS?.map((val) =>
            <Box
              sx={{
                p: 2, my: 2, border: 'solid 1px gray', borderRadius: '5px', width: '100%',
                "&:hover": {
                  border: 'solid 2px #4A84AA'
                },
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div><Typography variant="h5" color="initial">{val?.ADDRESS_NAME}</Typography></div>
                <div style={{ display: 'flex' }}>
                  <div>
                    {
                      val?.IS_CURRENT ?
                        <Button sx={{ fontSize: 10 }} size="small" variant="outlined" color="info">
                          {language.COMMON.MY_SHIPPING_ADDRESS.MAIN_ADDRESS}
                        </Button> :
                        null
                    }
                  </div>
                  <div>
                    <DeleteIcon onClick={() => { handleDelete(val) }} fontSize="small" />
                    <EditIcon onClick={() => { handleEditTrigger(val) }} fontSize="small" />
                  </div>
                </div>
              </div>
              <Grid container sx={{ mt: 1 }}>
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}>
                    <strong>{language.COMMON.MY_SHIPPING_ADDRESS.PROVINCE}</strong>
                  </Grid>
                  <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}>
                    {val?.ADDRESS_PROVINSI ?? '-'}
                  </Grid>
                </Grid>
                <Divider sx={{width: '100%', marginBottom: "15px"}}></Divider>
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}>
                    <strong>{language.COMMON.MY_SHIPPING_ADDRESS.CITY_DISTRICT}</strong>
                  </Grid>
                  <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}>
                    {val?.ADDRESS_KABUPATEN}
                  </Grid>
                </Grid>
                <Divider sx={{width: '100%', marginBottom: "15px"}}></Divider>
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}>
                    <strong>{language.COMMON.MY_ACCOUNT.POSTAL_CODE}</strong>
                  </Grid>
                  <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}>
                    <td>{val?.ZIPCODE}</td>
                  </Grid>

                </Grid>
                <Divider sx={{width: '100%', marginBottom: "15px"}}></Divider>
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}>
                    <strong>{language.COMMON.MY_ACCOUNT.FULL_ADDRESS}</strong>
                  </Grid>
                  <Grid
                    item
                    xl={10}
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}>
                    <td>{val?.ADDRESS}</td>
                  </Grid>

                </Grid>
              </Grid>
            </Box>
          )
        }
      </Box>
    </React.Fragment>
  );
}
