export default function isAccountRetailEmpty (data) {
    if(data) {
        const datalist = data?.[0]
        if(!datalist?.CUSTOMER_NAME) {
            return true
        } else if(!datalist?.CP?.[0]?.CP_CONTACT) {
            return true
        } else if(!datalist?.ADDRESS) {
            return true
        } else {
            return false
        }
    }
}