import React, { useState, useEffect } from 'react'
import { Carousel } from 'components'
import { CustomBackdrop } from 'components';
import queryString from 'query-string'
import { ProductPopular } from './components'
import { useProduct, useSession } from 'hooks'
import { useNavigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import roleValidation from 'helpers/roleValidation'
import { Box } from '@mui/material';


function Home() {
    window.scrollTo(0, 0);                                         // Auto Scroll to Top

    const location = useLocation()
    const queryParse = queryString.parse(location.search)

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [dialogType, setDialogType] = useState("error");
    const [datatables, setDatatables, getDetail] = useProduct(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
    const [session, setSession] = useSession(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
    const homeBanner = useSelector(state => state.homeBanner);
    const [rows, setRows] = React.useState([])
    const [rowsProduct, setRowsProduct] = React.useState([])
    const popularProduct = useSelector(state => state.popularProduct);

    console.log("🚀 ~ file: index.jsx ~ line 83 ~ useEffect ~ queryParse?.nonce", queryParse?.nonce)
    useEffect(() => {
        if (queryParse?.nonce) {
            setSession(queryParse?.nonce)
            // roleValidation(queryParse?.nonce)
        }
    }, [])

    useEffect(() => {
        const param = {
            page: 1,
            limit: 12
        }
        getDetail(param, 'getBanner')

    }, [session])

    useEffect(() => {
        const param = {
            page: 1,
            limit: 12
        }
        getDetail(param, 'getPopularProduct')

    }, [session, localStorage?.getItem('Retail')])

    React.useEffect(() => {
        const rowData = homeBanner?.items?.data ?? []
        if (rowData.length > 0) setRows(rowData)
        else setRows([])
    }, [homeBanner])

    React.useEffect(() => {
        const rowData = popularProduct?.items?.data ?? []
        if (rowData.length > 0) setRowsProduct(rowData)
        else setRowsProduct([])
    }, [popularProduct])

    return (
        <React.Fragment>
            <CustomBackdrop loading={isLoading} label={'Collecting Resource'} />
            <div>
                <Carousel rows={rows.length > 0 ? rows : []}></Carousel>
                <Box sx={{overflowX: 'scroll',  scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                display: "none"
                }}}>
                <ProductPopular rows={rowsProduct.length > 0 ? rowsProduct : []} />
                </Box>
            </div>
        </React.Fragment>
    )
}

export default Home
