import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { userActions } from 'controllers/_actions'
import { useCleanRestData } from 'hooks'

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export default function useLogout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [setClean] = useCleanRestData()

  const authentication = useSelector(state => state.authentication)
  const selecteds = useSelector(state => state.selected);

  const initFetch = useCallback(() => {
    const isEmptyObj = isEmpty(!selecteds ? {} : selecteds)
    const isEmptyAuth = isEmpty(!authentication ? {} : authentication)

    if (!isEmptyObj && !isEmptyAuth) {
      dispatch(userActions.logout())
      navigate('/furnitur')
    } else if (isEmptyObj && !isEmptyAuth) {
      setClean()
      dispatch(userActions.logout())
      navigate('/furnitur')
    } else {
      navigate('/furnitur')
    }
  }, [dispatch, authentication, navigate, setClean, selecteds]);

  return [initFetch]
}
