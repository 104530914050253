import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box

} from '@mui/material'
import { BreadCrumbs } from 'components'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useLanguage, useOfficeFitsOut } from 'hooks'
import moment from 'moment'
import parse from 'html-react-parser';
import Slide from '@mui/material/Slide';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Index() {
  window.scrollTo(0, 0);                                         // Auto Scroll to Top

  const classes = useStyles()
  const breadcrumb = [
    {
      title: "Home", to: "/furnitur"
    }
  ]
  const [isLoading, setIsLoading] = useState(false);
  const [form, setform] = useState({});
  const [language, lagDic, setLang] = useLanguage()
  console.log("🚀 ~ file: index.jsx ~ line 35 ~ Index ~ form", form)
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [openFailed, setOpenFailed] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, createData] = useOfficeFitsOut(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  console.log("🚀 ~ file: index.jsx ~ line 34 ~ Index ~ datatables", datatables)
  const [isSubmitted, setisSubmitted] = useState(false);
  const today = moment().format('YYYY-MM-DD');


  const handleChange = (event) => {
    console.log("🚀 ~ file: index.jsx ~ line 48 ~ handleChange ~ event", event)
    setisSubmitted(false)
    setform({
      ...form,
      [event.target.name]: event.target.value
    })
  }
  const handleChangePhone = (value, data, event, formattedValue) => {
    const number = formattedValue.replace(/\s/g, '')
    let phone = number
    // if (number.charAt(3) === '0') {
    //   phone = replaceWith(number, 3)
    // }
    setisSubmitted(false)
    setform({
      ...form,
      [event.target.name]: phone
    })
  }

  // const replaceWith = (value, index) => {
  //   const phone = value.substring(0, index) + '' + value.substring(index + 1)
  //   return phone
  // }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (form?.phone.length >= 10) {
      setisSubmitted(true)
      const param = {
        ...form,
        ACTION_DATE: today
      }
      createData(param, 'create')
      Object.keys(form).forEach(function (key) {
        form[key] = '';
        form['phone'] = '+62'
      })
    } else {
      setOpenFailed(true)
    }
  }

  useEffect(() => {

    const param = {
      page: 1,
      limit: 10
    }
    setDatatables(param)
  }, [setDatatables])

  useEffect(() => {
    if (dialogType === 'Success') {
      setisSubmitted(false)
    }

  }, [dialogType])


  // useEffect(() => {
  //   if (isSubmitted) {
  //     const param = {
  //       ...form,
  //       ACTION_DATE: today
  //     }
  //     createData(param, 'create')
  //   }
  //   // else {
  //   //   setisSubmitted(false)
  //   // }

  // }, [isSubmitted])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setOpenFailed(false);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  return (
    <div className={classes.container}>
      <Snackbar TransitionComponent={SlideTransition} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Send Inquiry Successful
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openFailed} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Send Message Failed
        </Alert>
      </Snackbar>
      <div style={{ paddingBottom: 20 }}>
        <BreadCrumbs
          breadcrumb={breadcrumb}
          current={language.COMMON.MENU.OFFICE_FITS_OUT}
        />
      </div>
      <Typography variant="body1" color="initial"><strong>{language.COMMON.MENU.OFFICE_FITS_OUT}</strong></Typography>
      <Grid container spacing={10}>
        <Grid item xl={7} lg={7} md={6} sm={12}>
          {/* <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
            <strong>
              Make us your project people.
            </strong>
          </Typography> */}
          {datatables ?
            parse(`${datatables?.[0]?.VALUE_02}`)
            :
            <React.Fragment>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                If you need to fit out your office – you’ve come to the right place!
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                As one of the largest suppliers of office furniture in the country, Epic Office Furniture is your one-stop commercial fitout company, priding ourselves on high quality furniture at great prices with outstanding customer service.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                As well as being a huge online store, we specialise in custom-built furniture and large-scale projects, where we offer on-site consultations to assess and identify your needs, plus an inhouse design and installation team to make the process as smooth and simple as possible.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                We have years of experience offering furniture fitouts to both corporate clients and contractual building companies.
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                If you need quality furniture to fit out a new office or your current workplace, you can rely on our huge range of chairs, desks, workstations, storage, tables, lounges, accessories and more!
              </Typography>
              <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
                So, if you have an upcoming office fitout project or your current office, café or space is in need of a revamp, pop your details in the form and our project team will be in touch very soon to see what we can do for you!
              </Typography>
            </React.Fragment>
          }
        </Grid>
        <Grid item xl lg sm md direction='column'>
          <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
            <strong>
              {language.COMMON.OFFICE_FITOUT.MAKE_US_PROJECT}
            </strong>
          </Typography>
          <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
            {language.COMMON.OFFICE_FITOUT.OUR_FRIEND}
          </Typography>
          <form onSubmit={handleSubmit}>
            {/* <MuiPhoneNumber defaultCountry={'id'} disableDropdown onlyCountries={['id']} /> */}
            <div>
              {/* <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">Your Name <strong style={{ color: 'red' }}>*</strong></Typography> */}
              <TextField
               sx={{ width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"}, paddingTop: 2 }}
               id="firstname"
                required
                value={form.firstname || ''}
                color='info'
                placeholder={language.COMMON.FIELDTEXT.FIRST}
                name='firstname'
                size='small'
                onChange={handleChange}
              />
              {/* <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">Email <strong style={{ color: 'red' }}>*</strong></Typography> */}
              <TextField
                sx={{ width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"}, paddingTop: 2 }}
                id="lastname"
                required
                value={form.lastname || ''}
                color='info'
                name='lastname'
                placeholder={language.COMMON.FIELDTEXT.LAST}
                size='small'
                onChange={handleChange}
              />
              {/* <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">Phone Number <strong style={{ color: 'red' }}>*</strong></Typography> */}
              <Box sx={{ width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"} }}>
                <PhoneInput
                  country={'id'}
                  disableDropdown
                  onlyCountries={['id']}
                  countryCodeEditable={false}
                  inputStyle={{ width: '100%' }}
                  inputProps={{
                    required: true,
                    autoFocus: true,
                    value: form.phone || '+62',
                    name: 'phone',
                    id: "phone"
                  }}
                  onChange={handleChangePhone}
                  isValid={(value) => {
                    if (value.length <= 10) {
                      return 'Phone number contain at least 10 digit';
                    } else {
                      return true;
                    }
                  }}
                />
            </Box>
              <TextField
                sx={{ width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"}, paddingTop: 2 }}
                id="email"
                required
                type={'email'}
                color='info'
                value={form.email || ''}
                name='email'
                placeholder={language.COMMON.FIELDTEXT.EMAIL}
                size='small'
                onChange={handleChange}
              />
              <TextField
                sx={{ width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"}, paddingTop: 2 }}
                id="comment"
                required
                color='info'
                value={form.comment || ''}
                name='comment'
                multiline
                placeholder={language.COMMON.FIELDTEXT.COMMENT}
                size='small'
                onChange={handleChange}
              />
              <Button type='submit' sx={{ marginTop: 2, width: {xl:400 ,lg:400 ,md:400 ,sm: '100%', xs: "100%"} }} color='info' variant="contained" >
                {language.COMMON.OFFICE_FITOUT.SEND_INQUIRY}
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>

    </div>
  )
}

export default Index
