import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectActions } from '../../controllers/_actions'

export default function useSelected() {
    const [data, setData] = useState([])
    const selected = useSelector(state => state.selected);
    const dispatch = useDispatch()

    const initFetch = async (param) => {
        await dispatch(selectActions.selectData(param));
    };

    useEffect(() => {
        const DataLoaded = !selected ? null : selected
        setData(DataLoaded)
    }, [selected])

    return [data, initFetch]
}
