import {
  // generateKey,
  // encrypt,
  decrypt
} from './hashing';
// import ImageUrl from '../config/base/image.config'

export default function getAccessLog(field) {
  // const state = store.getState()
  // const key = localStorage.getItem('user_terminal');
  // const encrypted = localStorage.getItem('user_load_point');
  // const accessLog = !encrypted ? null : decrypt(encrypted, key);
  // // console.log("🚀 ~ file: getAccessLog.js ~ line 13 ~ getAccessLog ~ accessLog", accessLog)

  // const permissions = localStorage.getItem('app_config');
  // const accessPermissionsLog = (permissions && key) ? decrypt(permissions, key) : {};

  const loader = localStorage.getItem('click_point');
  const loadEnc = localStorage.getItem('history_load');
  const accessDataLog = (loader && loadEnc) ? decrypt(loadEnc, loader) : {};
  console.log("🚀 ~ file: getAccessLog.js ~ line 21 ~ getAccessLog ~ accessDataLog", accessDataLog)
  const accessLog = accessDataLog?.user?.data
  console.log("🚀 ~ file: getAccessLog.js ~ line 23 ~ getAccessLog ~ accessLog", accessLog)
  // const accessDataLog = state?.authentication
  const accessPermissionsLog = accessDataLog?.user?.data?.role?.role_permissions
  const permissions = accessDataLog
  // const loadEnc = accessDataLog
  const encrypted = accessLog
  // console.log("🚀 ~ file: getAccessLog.js ~ line 21 ~ getAccessLog ~ accessDataLog", accessDataLog)

  // const accessLog = JSON.parse(localStorage.getItem('access_log'));

  switch (field) {
    case "app_config":
      return !permissions ? {} : accessPermissionsLog;
    case "isAuthentication":
      return !loadEnc ? {} : accessDataLog?.loggedIn;
    case "data_log":
      return !loadEnc ? {} : accessDataLog;
    // case "key":
    //   return !key ? null : key;
    case "user_data":
      return !encrypted ? null : accessLog;
    case "language_config":
      return !encrypted ? null : accessLog?.language_config;
    // case "company_type":
    //   return !encrypted ? null : accessLog.user_company.company_type;
    // case "company_type_name":
    //   return !encrypted ? null : accessLog.user_company.company_type_name;
    // case "company_type_code":
    //   return !encrypted ? null : accessLog.user_company.company_type_code;
    // case "company_name":
    //   return !encrypted ? null : accessLog.user_company.company_name;
    // case "company_code":
    //   return !encrypted ? null : accessLog.user_company.company_code;
    // case "role_code":
    //   return !encrypted ? null : accessLog.user_role.role_code;
    // case "image":
    //   return !encrypted ? null : accessLog.image;
    case "role":
      return !encrypted ? null : accessLog?.role?.code;
    case "role_name":
      return !encrypted ? null : accessLog?.role?.description;
    // case "limited_access":
    //   return !encrypted ? null : accessLog.user_role.limited_access;
    case "branch":
      return !encrypted ? null : accessLog?.region_access?._key;
    case "branch_name":
      return !encrypted ? null : accessLog?.region_access?.NAME;
    case "org_id":
      return !encrypted ? null : accessLog?.region_access?.ORGANIZATION_ID;
    case "region_access":
      return !encrypted ? null : accessLog?.region_access;
    case "user_name":
      return !encrypted ? null : accessLog?.name;
    case "super_admin":
      return !encrypted ? null : accessLog?.super_admin ?? false;
    case "username":
      return !encrypted ? null : accessLog?.username;
    // case "image":
    //   return !encrypted ? null : `${ImageUrl}${accessLog.image}`;
    case "user_id":
      return !encrypted ? null : accessLog?._key;
    case "user_trx_id":
      return !encrypted ? null : accessLog?.user_trx_identifier_code;
    case "email":
      return !encrypted ? null : accessLog?.email;
    case "lkpp_token":
      return !encrypted ? null : accessDataLog?.user?.data?.token ?? null;
    case "access_token":
      return !encrypted ? null : accessDataLog?.user?.token?.access_token ?? null;
    case "refresh_token":
      return !encrypted ? null : accessDataLog?.user?.token?.refresh_token ?? null;
    case "expires_in":
      return !encrypted ? null : accessDataLog?.user?.token?.expires_in ?? 0;
    case "refresh_expires_in":
      return !encrypted ? null : accessDataLog?.user?.token?.refresh_expires_in ?? 0;
    case "lkpp_identifier":
      return !encrypted ? null : accessDataLog?.user?.data?.lkpp_identifier ?? 0;
    case "lkpp_identifier_token":
      return !encrypted ? null : accessDataLog?.user?.data?.lkpp_identifier?.token ?? 0;
    case "lkpp_identifier_token":
      return !encrypted ? null : accessDataLog?.user?.data?.lkpp_identifier?.token ?? 0;
    case "lkpp_identifier_client_id":
      return !encrypted ? null : accessDataLog?.user?.data?.lkpp_identifier?.client_id ?? 0;
    case "lkpp_identifier_client_secret":
      return !encrypted ? null : accessDataLog?.user?.data?.lkpp_identifier?.client_secret ?? 0;  
    case "lkpp_identifier_vertical_type":
      return !encrypted ? null : accessDataLog?.user?.data?.lkpp_identifier?.vertical_type ?? 0;    
    case "lkpp_payload":
      return !encrypted ? null : accessDataLog?.user?.data?.lkpp_payload ?? 0;
    default:
      return false
  }
}