import { useState, useCallback, useEffect } from 'react'

export default function useInput(validate, initialVal) {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})
  // const [submitting, setSubmitting] = useState(false)
  useEffect(() => {
    if (initialVal) {
      setValues(initialVal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = useCallback((field, value, reset) => {
    if (!reset) {
      setValues(prevValues => ({
        ...prevValues,
        [field]: value
      }));
      setErrors({})
    } else {
      setValues({})
      setErrors({})
    }
  }, []);

  const handleValidation = (val) => {
    const newValues = { ...val }
    if (validate) {
      const validateErr = validate(newValues)
      setErrors(validateErr)
      // setSubmitting(true)
    }
  }

  return [handleChange, values, handleValidation, errors]
}
