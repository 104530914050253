import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Alert from '@mui/lab/Alert'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function SetupAccount(props) {
  const {
    classes,
    onAction,
    values,
    fieldError,
  } = props
  const [isShow, setIsShow] = useState(false)
  const [isConfirmShow, setIsConfirmShow] = useState(false)

  const handleClickShowPassword = () => {
    setIsShow(!isShow);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setIsConfirmShow(!isConfirmShow);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };


  return (
    <React.Fragment>
      <Box borderColor="primary.main" border={1} sx={{borderStyle: 'dashed', background: '#FEF7EA'}} borderRadius={2} padding="16px" marginBottom="8px">
        <Typography variant="body-2" color='textPrimary'>
          In order to protect your account, make sure that your new password has:<br />
          <ul>
            <li>at least 8 characters;</li>
            <li>a mixture of both UPPERCASE and lowercase letters;</li>
            <li>a mixture of letters (abcde...) and numbers (0-9);</li>
            <li>at least one special character ("'!@#$%^&*()_-+=:?...)</li>
          </ul>
        </Typography>
      </Box>
      <form onSubmit={onAction?.positive ?? onAction?.onClose} className={classes.formField} autoComplete="off">
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <TextField
            id="outlined-read-only-inputasdf"
            value={values.newPassword}
            // className={classes.currency}
            name="newPassword"
            onChange={(event) => {
              onAction.onChange("newPassword", event.target.value)
            }}
            onBlur={(e) => onAction.setValidation(values)}
            // autoComplete="password"
            required
            error={fieldError.newPassword}
            helperText={fieldError.newPassword}
            label="New Password"
            margin="dense"
            variant="outlined"
            autoFocus={props.dialogType === 'NewUser' ? true : false}
            type={isShow ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.adornment}>
                  <IconButton
                    className={classes.adornment}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    tabIndex='-1'
                  >
                    {!isShow ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff"
              },
              autoComplete: 'new-password',
            }}
          />
        </FormControl>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <TextField
            // error={!props.statusPassword}
            id="outlined-read-only-inputasdf"
            value={values.confirmPassword}
            name="confirmPassword"
            required
            // className={classes.currency}  
            onChange={(event) => {
              onAction.onChange("confirmPassword", event.target.value)
            }}
            error={fieldError.confirmPassword}
            onBlur={(e) => onAction.setValidation(values)}
            // autoComplete="confirm-password"
            label="Confirmation Password"
            margin="dense"
            // helperText={props.statusPassword === false ? "Confirmation Password is empty or not match" : null}
            variant="outlined"
            type={isConfirmShow ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.adornment}>
                  <IconButton
                    className={classes.adornment}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                    tabIndex='-1'
                  >
                    {!isConfirmShow ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                backgroundColor: "#fff"
              },
              autoComplete: 'new-password',
            }}
          />
        </FormControl>
        {!fieldError.confirmPassword ? null : (
          <Alert
            severity='error'
          >
            {fieldError.confirmPassword}
          </Alert>
        )}
      </form>
    </React.Fragment >
  )
}
