import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Tabs,
  Button,
  Tab,
  Typography,
  Box,
  MenuItem,
  Select,
  ToggleButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ManageAccounts, ReceiptLong, Apartment, Person, InsertDriveFile } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  LocalizationProvider,
  DatePicker
} from '@mui/lab';
import useStyles from './styles';
import { useRetailOrder, useSelected, useProfile, useProfileRetail } from "hooks";
import { useLocation, useNavigate } from "react-router";
import queryString from 'query-string';
import getDayPicker from "helpers/getDayPickerNew";
import { getAccessLog } from "helpers";
import { CustomBackdrop } from "components";
import { useLanguage} from 'hooks';
import TableCustom from './components/TableComponent';

export default function BasicTabs(props) {
  const {
    date,
    setdate,
    toggleBtn,
    settoggleBtn,
    dummy,
    handlePageType
  } = props
  const classes = useStyles()

  const navigate = useNavigate()
  const [language, lagDic, setLang] = useLanguage()
  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  const [selected, setSelected] = useSelected()
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datas, setDatas] = useRetailOrder(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage);
  const [datatables2, setDatatables2, getData] = useProfileRetail(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  const [step, setStep] = React.useState('transactionList')
  const [state, setState] = useState({ text: '', date: '' })
  const [toggle, setToggle] = useState({})

  useEffect(() => {
    if (localStorage.getItem('Retail') === "true") {
      const param = {
        CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
        // CUSTOMER_NAME: "Maudy A",
        // CUSTOMER_NO: "00000009",
        USERNAME: getAccessLog('username'),
        page: 1,
        limit: 100
      }
      setDatatables2(param)
    }
  }, [])
  useEffect(() => {
    if(datatables2?.length > 0) {
      if (step === 'transactionList') {
        let status = ''
        if (toggle?.all) status = undefined
        if (toggle?.New) status = 'New'
        if (toggle?.Pending) status = 'Pending'
        if (toggle?.Paid) status = 'Paid'
        if (toggle?.Delivered) status = 'Delivered'
        if (toggle?.Canceled) status = 'Cancelled'
  
        const param = {
          "INVOICE_NO": state?.text?.length > 3 ? state?.text : undefined,
          // "CUSTOMER_NAME": getAccessLog('username'),
          // "CUSTOMER_NO": datatables2?.[0]?.CUSTOMER_NO,
          "USERNAME": getAccessLog('username'),
          "PURCHASE_DATE": getDayPicker(state?.date) ?? undefined,
          // "START_DATE": getDayPicker(state?.date) ??  undefined,
          // "END_DATE": filter?.RANGE_DATE?.[1],
          "PURCHASE_STATUS": status?.length > 0 ? status : undefined,
          page: 1,
          limit: 1000000
        }
        setDatas(param)
      }
    }
  }, [setDatas, state, toggle, datatables2 ])
  useEffect(() => {
    if (query) {
      setStep(query || "transactionList")
    }
  }, [query])

  const handleChangeStep = (val) => {
    handlePush(val)
    setStep(val)
  }

  const handlePush = (type) => {
    navigate({
      search: `?act=${type}`
    })
  }

  const handleFlow = async (submit, type) => {
    if (type === 'transactionDetail') {
      await setSelected(submit)
      handleChangeStep('transactionDetail')
    } else if (type === 'transactionInfo') {
      await setSelected(submit)
      handleChangeStep('transactionInfo')
    } else if (type === 'list') {
      //   await initFetch();
      handleChangeStep('transactionList')
    }
  }
  const toggleArr = [
    {
      id: 1,
      name: 'all',
      label: `${language.COMMON.MY_TRANSACTION.ALL_STATUS}`
    },
    {
      id: 2,
      name: 'New',
      label: `${language.COMMON.MY_TRANSACTION.WAITING_FOR_PAYMENT}`
    },
    {
      id: 3,
      name: 'Pending',
      label: `${language.COMMON.MY_TRANSACTION.ON_PROGRESS}`
    },
    {
      id: 4,
      name: 'Paid',
      label: `${language.COMMON.MY_TRANSACTION.ON_SHIPPING}`
    },
    {
      id: 5,
      name: 'Delivered',
      label: `${language.COMMON.MY_TRANSACTION.COMPLATED}`
    },
    {
      id: 6,
      name: 'Canceled',
      label: `${language.COMMON.MY_TRANSACTION.CANCELED}`
    },
  ]

  return (
    <React.Fragment>
      <CustomBackdrop loading={isLoading}></CustomBackdrop>
      <Box
        sx={{
          mt:2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
        }}
      >

        <Typography variant="body1" color="initial"><strong>{language.COMMON.MY_ACCOUNT.MY_TRANSACTION}</strong></Typography>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <TextField
            sx={{ width: 300, paddingTop: 2, paddingRight: 3 }}
            id="transaction"
            color='info'
            onChange={(e) => {
              setState({ ...state, text: e.target.value })
              setPage(0)
            }}
            value={state.text}
            name='transaction'
            placeholder={language.COMMON.MY_TRANSACTION.SEARCH_TRANSACTION_CODE}
            size='small'
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={state.date}
              onChange={(newValue) => {
                setState({ ...state, date: newValue });
                setPage(0)
              }}
              renderInput={(params) => <TextField size='small' sx={{ width: 300, paddingTop: 2 }} {...params} />}
            />
          </LocalizationProvider>
        </div>

        {/* Button Toggle */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', overflowX: "auto", '&::-webkit-scrollbar': {
        display: 'none'
        },}}>
          <Typography sx={{ paddingTop: 4, paddingRight: 2, paddingLeft: 2 }} align='inherit'><strong>Status</strong></Typography>
          {toggleArr?.map((val, idx) =>
            <ToggleButton
              sx={{ borderRadius: 25, px: 2, mx: 1, my: 2,  minWidth: "200px" }}
              value={val?.name}
              color='info'
              size="small"
              selected={toggle[val?.name]}
              name={val?.name}
              onChange={(event) => {
                setToggle({
                  [event.target.name]: !toggleBtn[event.target.name]
                });
                setPage(0)
              }}
            >
              {val?.label}
            </ToggleButton>
          )}
          {/* <ToggleButton
            sx={{ borderRadius: 25, px: 2, mx: 1, my: 2 }}
            value="waiting"
            name="waiting"
            color='info'
            selected={toggleBtn.waiting}
            onChange={(event) => {
              settoggleBtn({
                [event.target.name]: !toggleBtn[event.target.name]
              });
            }}
          >
            Waiting for Payment
          </ToggleButton>
          <ToggleButton
            sx={{ borderRadius: 25, px: 2, mx: 1, my: 2 }}
            value="shipping"
            name="shipping"
            color='info'
            selected={toggleBtn.shipping}
            onChange={(event) => {
              settoggleBtn({
                [event.target.name]: !toggleBtn[event.target.name]
              });
            }}
          >
            On Shipping
          </ToggleButton>
          <ToggleButton
            sx={{ borderRadius: 25, px: 2, mx: 1, my: 2 }}
            value="complete"
            name="complete"
            color='info'
            selected={toggleBtn.complete}
            onChange={(event) => {
              settoggleBtn({
                [event.target.name]: !toggleBtn[event.target.name]
              });
            }}
          >
            Completed
          </ToggleButton>
          <ToggleButton
            sx={{ borderRadius: 25, px: 2, mx: 1, my: 2 }}
            value="cancel"
            name="cancel"
            color='info'
            selected={toggleBtn.cancel}
            onChange={(event) => {
              settoggleBtn({
                [event.target.name]: !toggleBtn[event.target.name]
              });
            }}
          >
            Cancelled
          </ToggleButton> */}
        </Box>
        <TableCustom
          changeStep={handleChangeStep}
          data={datas}
          loading={isLoading}
          error={error}
          step={step}
          selected={selected}
          onSelect={setSelected}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          checkingApprove={(body, type) => handleFlow(body, type)}>
        </TableCustom>
        {/* <TableContainer component={Paper} sx={{ marginTop: 3, marginBottom: 3 }}>
          <Table size='small' sx={{ width: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell><strong>ORDER ID</strong></TableCell>
                <TableCell align="center"><strong>ORDER DATE</strong></TableCell>
                <TableCell align="center"><strong>TOTAL PRODUCT</strong></TableCell>
                <TableCell align="center"><strong>QUANTITY</strong></TableCell>
                <TableCell align="center"><strong>TOTAL AMOUNT</strong></TableCell>
                <TableCell align="center"><strong>STATUS</strong></TableCell>
                <TableCell align="center"><strong>ACTION</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dummy.map((row) => (
                <TableRow
                  className={classes.table}
                  key={row.product}
                >
                  <TableCell>{row.order_id}</TableCell>
                  <TableCell align="center"> {row.order_date}</TableCell>
                  <TableCell align="center">{row.total_prd}</TableCell>
                  <TableCell align="center">{row.qty}</TableCell>
                  <TableCell align="center">{row.total_amount}</TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center"><InsertDriveFile fontSize='small' /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Box>
    </React.Fragment>
  );
}
