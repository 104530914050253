import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { aboutUsActions } from '../../controllers/_actions'

export default function useAboutUs(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const aboutUs = useSelector(state => state.aboutUs);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(aboutUsActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  // const crudAction = useCallback((param, type) => {
  //   if (type === 'create') {
  //     dispatch(aboutActions.createData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
  //   }
  // }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);


  useEffect(() => {
    const DataLoaded = aboutUs?.items?.data || []
    setData(DataLoaded)
  }, [aboutUs])

  return [data, initFetch]
}
