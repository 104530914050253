import React from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography';

export default function CustomDatefield(props) {
  const {
    classes,
    param,
  } = props

  const initialDate = !param ? "" : !param.value ? "" : `${param.value}`
  const subsVal = (val) => {
    const zeroString = val.substr(0, 1)
    const codeString = val.substr(0, 3)
    if (zeroString === '0') return val.substr(1)
    else if (codeString === '+62') return val.substr(3)
    else return val
  }
  const result = subsVal(initialDate)
  const [value, setValue] = React.useState(result)

  const handleChange = (e) => {
    const result = `${subsVal(e.target.value)}`
    param.onChange(e.target.name, result)
    setValue(result)
  }

  React.useEffect(() => {
    const checkInit = !param ? "" : !param.value ? "" : `${param.value}`
    setValue(checkInit)
  }, [param])

  return (
    <React.Fragment>
      <TextField
        className={classes.itemContent}
        fullWidth
        margin="dense"
        // label={param.label}
        onChange={handleChange}
        id={param.id}
        value={value}
        disabled={param.disabled}
        placeholder={param.placeholder}
        readOnly={props.readOnlyAll}
        name={param.id}
        helperText={param.helper}
        type={param.type}
        variant="outlined"
        multiline={param.multiline}
        required={param.required}
        rows={param.rows}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography>+62</Typography>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
      <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
    </React.Fragment>
  )
}
