// 
import { getAccessLog } from 'helpers';
import { authHeader } from '../_helpers'
import service from '../_helpers/axiosCommon'

export const negotiationService = {
    getAll,
    createData,
    updateData,
    cancelData,
    deactivateData,
};

async function getAll(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "INVOICE_NO": param?.INVOICE_NO
                // "IS_DUMP": false,
            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/shipping/negotiation/read`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            service.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function createData(param) {
    const bodyOptions = {
        // "Client Secret": "f1a33f58-d7a3-4522-b60f-81d0c69f91f8",
        // "ClientId": "bela-system",
        // "vertical" : "furnitur",
        "NEGOTIATION_NOTES": param?.NEGOTIATION_NOTES,

        "REVISION_CODE": param?.REVISION_CODE,
        "INVOICE_NO": param?.INVOICE_NO,

        "PRICE_LAST": param?.PRICE_LAST,
        "PRICE_PROPOSED": param?.PRICE_PROPOSED,

        "CONTRACT_INFO": param?.CONTRACT_INFO,

        "h_REQUEST": param?.h_REQUEST,
        
        "LIST_COMPONENT": param?.LIST_COMPONENT,
        "ACTION_BY":  param?.ACTION_BY,
        "ACTION_DATE": param?.ACTION_DATE,
        "IS_DUMP": param?.IS_DUMP,
        "ACTIVE_STATUS": param?.ACTIVE_STATUS
    };
    const lang = param?.lang ?? "en";
    const requestUrl = `clarity-be/shipping/negotiation/create`
    const checkHeader = await authHeader(requestUrl, bodyOptions, "not mandatory")
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        service.post(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response save area adalah : ', response);
                // if (response.data.response_code === 'S1') {
                return {
                    data: response.data?.DATA,
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
                // Error 😨
                console.log(error?.response);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };

            });
    return response;
}

async function updateData(param) {
    const bodyOptions = {
        "Client Secret": getAccessLog('lkpp_identifier_client_secret'),
        "ClientId": getAccessLog('lkpp_identifier_client_id'),
        "vertical" : getAccessLog('lkpp_identifier_vertical_type'),
        // "Client Secret": "f1a33f58-d7a3-4522-b60f-81d0c69f91f8",
        // "ClientId": "bela-system",
        // "vertical" : "furnitur",
        "NEGOTIATION_CODE": param?.NEGOTIATION_CODE,

        "REVISION_CODE": param?.REVISION_CODE,
        "INVOICE_NO": param?.INVOICE_NO,
    
        "PRICE_LAST": param?.PRICE_LAST,
        "PRICE_PROPOSED": param?.PRICE_PROPOSED,
    
        "h_REQUEST": param?.h_REQUEST,
    
        "CONTRACT_INFO": param?.CONTRACT_INFO,
    
        "LIST_COMPONENT": param?.LIST_COMPONENT,
        "ACTION_BY":  param?.ACTION_BY,
        "ACTION_DATE": param?.ACTION_DATE,
        "IS_DUMP": false,
        "ACTIVE_STATUS": param?.ACTIVE_STATUS
    };;
    const lang = param?.lang ?? "en";
    const requestUrl = `/clarity-be/shipping/negotiation/approve`
    const checkHeader = await authHeader(requestUrl, bodyOptions)
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        service.put(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response update data area adalah : ', response);
                return {
                    data: response.data?.DATA,
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
                // Error 😨
                console.log(error);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };
            });
    return response;
}

async function cancelData(param) {
    const bodyOptions = {
        // "Client Secret": "f1a33f58-d7a3-4522-b60f-81d0c69f91f8",
        // "ClientId": "bela-system",
        // "vertical" : "furnitur",
        "INVOICE_NO": param?.INVOICE_NO,
        
        "ACTION_BY":  param?.ACTION_BY,
       
    };;
    const lang = param?.lang ?? "en";
    const requestUrl = `/clarity-be/shipping/retail/cancel`
    const checkHeader = await authHeader(requestUrl, bodyOptions)
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        service.put(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response update data area adalah : ', response);
                return {
                    data: response.data?.DATA,
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
                // Error 😨
                console.log(error);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };
            });
    return response;
}

async function deactivateData(param) {
    const newParam = { ...param }
    const bodyOptions = {
        "ID": param?.ID,
        "WEBDATA_CODE": param?.WEBDATA_CODE,
        "VALUE_KEY": param?.VALUE_KEY,
        "VALUE_ORDER": param?.VALUE_ORDER,
    
        "VALUE_01": param?.VALUE_01,
        "VALUE_02": param?.VALUE_02,
        "VALUE_03": param?.VALUE_03,
        "VALUE_04" : param?.VALUE_04,
        "ACTION_BY":  param?.ACTION_BY,
        "ACTION_DATE": param?.ACTION_DATE,
        "IS_DUMP": param?.IS_DUMP,
        "ACTIVE_STATUS": param?.ACTIVE_STATUS
    }
    const requestUrl = `/clarity-be/web/cms/update`
    const checkHeader = await authHeader(requestUrl, bodyOptions)
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        service.put(`${requestUrl}`,bodyOptions, requestOptions)
            .then(response => {
                console.log('Response deactivate user adalah : ', response);
                if (response.status === 200) {
                    return {
                        // data: response.data.data,
                        status_code: response.status,
                        message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                    };
                }
                else {
                    return {
                        // data: response.data.data,
                        status_code: response.status,
                        message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                    };
                }
            })
            .catch(error => {
                // Error 😨
                console.log('Response Error', error.response);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                } else if (error?.response?.status === 404) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Endpoint is not exist.'
                    };
                } else if (error) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.status,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                    };
                } else if (!error.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.response_code ?? error.status,
                        message: error?.data?.message ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                } else {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.response.code,
                        message: error.response.mesaage
                    };
                }
            });
    return response;
}

