import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import { useProduct, useSelected } from 'hooks'
import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router'
import FlowComponent from './flow'
import { useSelector } from 'react-redux'
import { CustomBackdrop } from 'components';
import { Box } from '@mui/system'

function Index(props) {
  window.scrollTo(0, 0);                                         // Auto Scroll to Top

  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getData] = useProduct(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  console.log("🚀 ~ file: index.jsx ~ line 18 ~ Index ~ datatables", datatables)
  const product = useSelector(state => state.product);
  const totalPage = product?.items?.completed_response?.data?.INFO_PAGE?.PAGE_TOTAL

  const [filter, setFilter] = useState({});
  console.log("🚀 ~ file: index.jsx ~  Index ~ filter", filter)
  const [filterSpec, setFilterSpec] = useState([]);
  console.log("🚀 ~ file: index.jsx ~ line 27 ~ Index ~ filterSpec", filterSpec)
  const [filterPrice, setFilterPrice] = useState({});
  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  console.log("🚀 ~ Index ~ queryParse:", queryParse, query)
  const [step, setStep] = React.useState('List')
  const [selected, setSelected] = useSelected()
  const [rows, setRows] = useState([])
  const getLimit = useSelector(state => state.getLimit);

  useEffect(() => {
    if (step === "List") {
      const param = {
        MIN: filterPrice?.min ?? 0,
        MAX: filterPrice?.max,
        REF_ID: filter?.CATEGORY,
        RANGE: queryParse?.subCode ? [queryParse?.subCode] : [],
        TYPE: filter?.sorting ?? "ITM.CREATED_DATE DESC",
        SPECIFICATION: filterSpec ?? [],
        page: page,
        limit: 24
      }
      setDatatables(param)
    }
  }, [step, page, filter, filterSpec, filterPrice, queryParse?.subCode])

  useEffect(() => {
    setFilterPrice({
      min: rows?.[0]?.MIN,
      max: rows?.[0]?.MAX
    })
  }, [rows, queryParse?.subCode, queryParse?.category])
  useEffect(() => {
    if (step === "List") {

      const param = {
        MODE: localStorage.getItem('Retail') === "false" ? 'CONTRACT' : 'RETAIL',
     }
      getData(param, 'getLimit')
    }
  }, [step])

  React.useEffect(() => {
    const rowData = getLimit?.items?.data ?? []
    if (rowData.length > 0) setRows(rowData)
    else setRows([])
  }, [getLimit?.items?.data])

  useEffect(() => {
    if (query) {
      setStep(query || "List")
    }
  }, [query])

  const handleChangeStep = (val, submit) => {
    handlePush(val, submit)
    setStep(val)
  }

  const handlePush = (type, submit) => {
    if (type !== "List") {
      const query = {
        ...queryParse,
        act: type,
        PRODUCT_NAME: submit?.PRODUCT_NAME,
        PRODUCT_CODE: submit?.PRODUCT_CODE
      };
      const searchString = queryString.stringify(query);
      navigate({
        search: searchString
      })
    } else {
      navigate({
        search: `?act=${type}`
      })
    }
  }

  const handleFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    })
  }

  const handleFlow = async (submit, type) => {
    if (type === 'detail') {
      await setSelected(submit)
      handleChangeStep('Detail', submit)
    } else if (type === 'list') {
       handleChangeStep('List')
    }
  }

  return (
    <Box sx={{ overflowX: 'scroll'
    , scrollbarWidth: "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none"
    }
    }}>
      <CustomBackdrop loading={isLoading} label={'Collecting Resource'} />
      <FlowComponent
        changeStep={handleChangeStep}
        data={datatables ?? []}
        loading={isLoading}
        error={error}
        step={step}
        handleSubmit={handleFlow}
       selected={selected}
        onSelect={setSelected}
        checkingApprove={(body, type) => handleFlow(body, type)}
        pageType={props.type ?? ""}
        totalPage={totalPage}
        setPage={setPage}
        selectedType={queryParse?.selectedType}
        subCategory={queryParse?.subCategory}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
        setFilterSpec={setFilterSpec}
        filterSpec={filterSpec}
        setFilterPrice={setFilterPrice}
        filterPrice={filterPrice}
        limit={rows}
      />
    </Box>
  )
}

export default Index
