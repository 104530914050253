import React, { useState } from 'react'
import useStyles from './styles'
import { Call, ArrowDropDown } from '@mui/icons-material';
import {
    Link,
    useLocation,
    matchRoutes,
    useResolvedPath,
} from "react-router-dom";
import { privateRoute } from 'common/router/private';
import { Typography } from '@mui/material';
import { SubMenu } from './components';
import { useNavigate } from 'react-router'
import { useLanguage } from 'hooks';
function NavLink({
    to,
    exact,
    className,
    activeClassName,
    inactiveClassName,
    nested,
    title,
    open,
    multiple,
    openMultiple,
    ...rest
}) {
    let location = useLocation();
    let resolvedLocation = useResolvedPath(to);
    let routeMatches = matchRoutes(privateRoute, location);

    let isActive;
    if (exact) {
        isActive = location.pathname === resolvedLocation.pathname;
    } else {
        isActive = routeMatches.some(
            (match) => match.pathname === resolvedLocation.pathname
        );
    }

    let allClassNames =
        className + (isActive ? ` ${activeClassName}` : ` ${inactiveClassName}`);

    return (
        !nested ?
            <Link className={allClassNames} to={to} {...rest} />
            :
            <div
                className={allClassNames}
                onClick={!multiple ? open : openMultiple}>
                <Typography variant='subtitle2' color={'white'}>{title}</Typography>
                <ArrowDropDown></ArrowDropDown>
            </div>
    )
}


function Navigation() {
    const [language, lagDic, setLang] = useLanguage()
    const classes = useStyles()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [multiple, setMultiple] = useState(false)

    let navStructure = []
    if (localStorage.getItem('Retail') === 'false') {
        navStructure = [
            { to: '/furnitur', title: language.COMMON.MENU.HOME },
            { to: '/furnitur/product?act=List', title: language.COMMON.MENU.SHOP_BY_PRODUCT, nested: true, multiple: true },
            { to: '/furnitur/range', title: language.COMMON.MENU.SHOP_BY_RANGE, nested: true },
            { to: '/furnitur/office-fits-out', title: language.COMMON.MENU.OFFICE_FITS_OUT },
            { to: '/furnitur/our-project?act=List', title: language.COMMON.MENU.OUR_PROJECT },
        ]
    } else {
        navStructure = [
            { to: '/furnitur', title: language.COMMON.MENU.HOME },
            { to: '/furnitur/product?act=List', title: language.COMMON.MENU.SHOP_BY_PRODUCT, nested: true, multiple: true },
            { to: '/furnitur/range', title: language.COMMON.MENU.SHOP_BY_RANGE, nested: true },
            { to: '/furnitur/sale?act=List', title: language.COMMON.MENU.SALE },
            { to: '/furnitur/office-fits-out', title: language.COMMON.MENU.OFFICE_FITS_OUT },
            { to: '/furnitur/our-project?act=List', title: language.COMMON.MENU.OUR_PROJECT },
        ]
    }

    const handleOpenSubMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setMultiple(false)
    }
    const hanldeOpenMultiple = (event) => {
        setMultiple(true)
        setAnchorEl(event.currentTarget);
    }
    return (
        <>
            <div className={classes.container}>
                <div className={classes.menu}>
                    {
                        navStructure?.map((item, idx) =>
                            <NavLink
                                key={idx}
                                activeClassName={classes?.active}
                                inactiveClassName={classes?.inactive}
                                className={classes?.default}
                                to={item?.to}
                                nested={item?.nested}
                                title={item.title}
                                open={handleOpenSubMenu}
                                multiple={item?.multiple}
                                openMultiple={hanldeOpenMultiple}
                            >
                                <Typography variant='subtitle2' color={'white'}> {item?.title}</Typography>
                            </NavLink>
                        )
                    }
                </div>
                <div className={classes.contact} onClick={() => navigate('/furnitur/contact-us')}>
                    <Call />
                    <Typography variant='subtitle2' color={'white'}>{language.COMMON.MENU.CONTACT_US}</Typography>
                </div>
            </div>
            <SubMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} close={() => setAnchorEl(null)} multiple={multiple} setMultiple={setMultiple} />
        </>
    )
}

export default Navigation
