import ListStep from './list'
import ViewStep from './view'

export default function ConditionalRender(props) {
    const {
        changeStep,
        data,
        loading,
        error,
        step,
        selected,
        onSelect,
        checkingApprove,
        initFetch,
        filter,
        language,
        setFilter,
        handleSubmit
    } = props
    if (step === 'Detail') {
        return <ViewStep
            data={data}
            loading={loading}
            selected={selected}
            error={error}
            step={step}
            initFetch={initFetch}
            changeStep={changeStep}
            onSelect={onSelect}
            language={language}
        />
    }
    return <ListStep
        changeStep={changeStep}
        handleSubmit={handleSubmit}
        data={data}
        loading={loading}
        error={error}
        step={step}
        selected={selected}
        onSelect={onSelect}
        checkingApprove={(body, type) => checkingApprove(body, type)}
        setFilter={setFilter}
        filter={filter}
        language={language}
    />
}
