import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { ManageAccounts, ReceiptLong } from '@mui/icons-material';
import useStyles from './styles'
import {
  TextField,
  Tabs,
  Button,
  Tab,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  FormControl,
} from '@mui/material';
import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router'
import { useInput, useLanguage } from 'hooks';
import { validateSign } from 'controllers/_validate'
import MainInformation from "./components/MainInformation"
import BuyerPpk from "./components/BuyerPpk"
import VendorDistributor from "./components/VendorDistributor"
import FundSource from "./components/FundSource"
import Pengiriman from "./components/Pengiriman"
import BuyerInformation from "./components/BuyerInformation"
import Product from "./components/Product"
import { useSelector } from 'react-redux';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
        // sx={{
        //   m: 2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px',
        // }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function Index() {
  const selected = useSelector(state => state.selected)
  const [language, lagDic, setLang] = useLanguage()
  window.scrollTo(0, 0);                                         // Auto Scroll to Top
  const navigate = useNavigate()
  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  const classes = useStyles()
  const [value, setValue] = React.useState(0);
  const [date, setdate] = React.useState(new Date());
  const [toggleBtn, settoggleBtn] = React.useState({});
  const [isLKPPAcc, setisLKPPAcc] = React.useState(false);
  const initValue = {
    PRODUCT_SHOWCASE: selected?.CONTRACT_INFO?.[0]?.PRODUCT_SHOWCASE,
    PACKAGE_ID: selected?.INVOICE_NO,
    RUP_ID: selected?.CONTRACT_INFO?.[0]?.RUP_ID,
    PACKAGE_NAME: selected?.CONTRACT_INFO?.[0]?.PACKAGE_NAME,
    WORK_UNIT: selected?.CONTRACT_INFO?.[0]?.WORK_UNIT,
    WORK_UNIT_ADDRESS: selected?.CONTRACT_INFO?.[0]?.WORK_UNIT_ADDRESS,
    WORK_UNIT_NPWP: selected?.CONTRACT_INFO?.[0]?.WORK_UNIT_NPWP,
    CREATED_DATE: selected?.META?.CREATED_DATE,
    MODIFIED_DATE: selected?.META?.MODIFIED_DATE,
    INSTANCE: selected?.COMPANY_NAME,
    BUDGET_YEAR: selected?.CONTRACT_INFO?.[0]?.BUDGET_YEAR,
    ITEM_TOTAL: selected?.PURCHASE_ITEM?.reduce((n, {ITEM_TOTAL}) => n + ITEM_TOTAL, 0) ?? 0,
    PRICE_FULL: selected?.PRICE_FULL,
    PP_NAME: selected?.CONTRACT_INFO?.[0]?.BUYER_INFO?.[0]?.ENTITY_NAME,
    PP_POSITION: selected?.CONTRACT_INFO?.[0]?.BUYER_INFO?.[0]?.ENTITY_POSITION,
    PP_NIP: selected?.CONTRACT_INFO?.[0]?.BUYER_INFO?.[0]?.ENTITY_NIP,
    PP_EMAIL: selected?.CONTRACT_INFO?.[0]?.BUYER_INFO?.[0]?.CONTACT_EMAIL,
    PP_PHONE: selected?.CONTRACT_INFO?.[0]?.BUYER_INFO?.[0]?.CONTACT_PHONE,
    PP_PBJ: selected?.CONTRACT_INFO?.[0]?.BUYER_INFO?.[0]?.PJB_CERTIFICATE,
    PPK_NAME: selected?.CONTRACT_INFO?.[0]?.PPK_INFO?.[0]?.ENTITY_NAME,
    PPK_POSITION: selected?.CONTRACT_INFO?.[0]?.PPK_INFO?.[0]?.ENTITY_POSITION,
    PPK_NIP: selected?.CONTRACT_INFO?.[0]?.PPK_INFO?.[0]?.ENTITY_NIP,
    PPK_EMAIL: selected?.CONTRACT_INFO?.[0]?.PPK_INFO?.[0]?.CONTACT_EMAIL,
    PPK_PHONE: selected?.CONTRACT_INFO?.[0]?.PPK_INFO?.[0]?.CONTACT_PHONE,
    PPK_PBJ: selected?.CONTRACT_INFO?.[0]?.PPK_INFO?.[0]?.PJB_CERTIFICATE,
    VENDOR_NAME: selected?.CONTRACT_INFO?.[0]?.VENDOR_INFO?.[0]?.ENTITY_NAME,
    VENDOR_NPWP: selected?.CONTRACT_INFO?.[0]?.VENDOR_INFO?.[0]?.NPWP_NO,
    VENDOR_ADDRESS: selected?.CONTRACT_INFO?.[0]?.VENDOR_INFO?.[0]?.CONTACT_ADDRESS,
    VENDOR_WEBSITE: selected?.CONTRACT_INFO?.[0]?.VENDOR_INFO?.[0]?.WEBSITE_URL,
    VENDOR_EMAIL: selected?.CONTRACT_INFO?.[0]?.VENDOR_INFO?.[0]?.CONTACT_EMAIL,
    VENDOR_PHONE: selected?.CONTRACT_INFO?.[0]?.VENDOR_INFO?.[0]?.CONTACT_PHONE,
    VENDOR_FAX: selected?.CONTRACT_INFO?.[0]?.VENDOR_INFO?.[0]?.CONTACT_FAX,
    DISTRIBUTOR_NAME: selected?.CONTRACT_INFO?.[0]?.DISTRIBUTOR_INFO?.[0]?.ENTITY_NAME,
    DISTRIBUTOR_NPWP: selected?.CONTRACT_INFO?.[0]?.DISTRIBUTOR_INFO?.[0]?.NPWP_NO,
    DISTRIBUTOR_ADDRESS: selected?.CONTRACT_INFO?.[0]?.DISTRIBUTOR_INFO?.[0]?.CONTACT_ADDRESS,
    DISTRIBUTOR_WEBSITE: selected?.CONTRACT_INFO?.[0]?.DISTRIBUTOR_INFO?.[0]?.WEBSITE_URL,
    DISTRIBUTOR_EMAIL: selected?.CONTRACT_INFO?.[0]?.DISTRIBUTOR_INFO?.[0]?.CONTACT_EMAIL,
    DISTRIBUTOR_PHONE: selected?.CONTRACT_INFO?.[0]?.DISTRIBUTOR_INFO?.[0]?.CONTACT_PHONE,
    DISTRIBUTOR_FAX: selected?.CONTRACT_INFO?.[0]?.DISTRIBUTOR_INFO?.[0]?.CONTACT_FAX,
    BUDGET_CODE: selected?.CONTRACT_INFO?.[0]?.BUDGET_CODE,
    BUDGET_SOURCE: selected?.CONTRACT_INFO?.[0]?.BUDGET_SOURCE,
  }
  const [handleFieldChange, values] = useInput(validateSign, initValue ?? {})

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageType = (type, submit) => {
    navigate({
      search: `?act=transaction${type}`
    })
  }

  const dummy = [
    {
      order_id: 'ORD-123',
      order_date: "21-01-2022",
      total_prd: '1',
      qty: '4',
      shippingfee: 100000,
      total_amount: 100000,
      status: 'Waiting for Payment'
    },
    {
      order_id: 'ORD-124',
      order_date: "21-02-2022",
      total_prd: '2',
      qty: '3',
      shippingfee: 100000,
      total_amount: 100000,
      status: 'Shipping'
    },
    {
      order_id: 'ORD-125',
      order_date: "21-03-2022",
      total_prd: '3',
      qty: '2',
      shippingfee: 100000,
      total_amount: 100000,
      status: 'Completed'
    },
    {
      order_id: 'ORD-126',
      order_date: "21-04-2022",
      total_prd: '4',
      qty: '1',
      shippingfee: 100000,
      total_amount: 100000,
      status: 'Cancelled'
    }

  ]

  return (
    <div className={classes.container}>
      <Grid
        container spacing={2}
      >
        <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}>
            <Paper variant="outlined" >
                <Tabs
                orientation="horizontal"
                indicatorColor='primary'
                textColor='primary'
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                <Tab sx={{ mt:2, color: '#4D4F5C' }} label={<span className={value === 0 ? classes.activeTab : ""}>Main Information</span>} {...a11yProps(0)} />
                <Tab sx={{ mt:2, color: '#4D4F5C' }} label={<span className={value === 1 ? classes.activeTab : ""}>{`Buyer & PPK`}</span>} {...a11yProps(1)} />
                <Tab sx={{ mt:2, color: '#4D4F5C' }} label={<span className={value === 2 ? classes.activeTab : ""}>Vendor/Distributor</span>} {...a11yProps(2)} />
                <Tab sx={{ mt:2, color: '#4D4F5C' }} label={<span className={value === 3 ? classes.activeTab : ""}>Fund Source</span>} {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={value} index={0} style={{ width: '100%' }} >
                    <MainInformation
                        values={values}
                        handleFieldChange={handleFieldChange}
                    />
                    <Box sx={{ display: 'flex',justifyContent: 'flex-end', width: '100%'}}>
                        <Button onClick={()=> {setValue(1)}} sx={{mb: 2, mr: 6}} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.NEXT}</Button>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1} style={{ width: '100%' }}>
                    <BuyerPpk
                        values={values}
                        handleFieldChange={handleFieldChange}
                    />
                    <Box sx={{ display: 'flex',justifyContent: 'flex-end', width: '100%'}}>
                        <Button onClick={()=> {setValue(0)}} sx={{mb: 2, mr: 2, color: '#2966C4', background: 'white', border: '1px solid #2966C4'}} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.BACK}</Button>
                        <Button onClick={()=> {setValue(2)}} sx={{mb: 2, mr: 6}} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.NEXT}</Button>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2} style={{ width: '100%' }}>
                <VendorDistributor
                        values={values}
                        handleFieldChange={handleFieldChange}
                    />
                    <Box sx={{ display: 'flex',justifyContent: 'flex-end', width: '100%'}}>
                        <Button onClick={()=> {setValue(1)}} sx={{mb: 2, mr: 2, color: '#2966C4', background: 'white', border: '1px solid #2966C4'}} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.BACK}</Button>
                        <Button onClick={()=> {setValue(3)}} sx={{mb: 2, mr: 6}} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.NEXT}</Button>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={3} style={{ width: '100%' }}>
                <FundSource
                        values={values}
                        handleFieldChange={handleFieldChange}
                    />
                    <Box sx={{ display: 'flex',justifyContent: 'flex-end', width: '100%'}}>
                        <Button onClick={()=> {setValue(2)}} sx={{mb: 2, mr: 6, color: '#2966C4', background: 'white', border: '1px solid #2966C4'}} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.BACK}</Button>
                    </Box>
                </TabPanel>
            </Paper>
        </Grid>
        <Grid
        container
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}>
             <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}>
                <Paper variant='outlined' sx={{mb: 3}}>
                    <Pengiriman data={selected?.SHIPPING}></Pengiriman>
                </Paper>
                <Paper variant='outlined' sx={{mb: 3}}>
                    <BuyerInformation data={selected}></BuyerInformation>
                </Paper>
                <Paper variant='outlined'>
                    <Product data={selected}></Product>
                </Paper>
            </Grid>
        </Grid>
      </Grid>
    </div >
  )
}

export default Index
