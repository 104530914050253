import { aboutUsConstants } from '../_constants';
import { aboutUsService } from '../_services';

export const aboutUsActions = {
    getAll,
};

function getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await aboutUsService.getAll(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: aboutUsConstants.GETALL_REQUEST, } }
    function success(aboutUs) { return { type: aboutUsConstants.GETALL_SUCCESS, aboutUs } }
    function failure(error) { return { type: aboutUsConstants.GETALL_FAILURE, error } }
}