import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cartActions } from '../../controllers/_actions'

export default function useCart(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(cartActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'create') {
      dispatch(cartActions.createData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'delete') {
      dispatch(cartActions.deactivateData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'update') {
      dispatch(cartActions.updateData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);


  useEffect(() => {
    const DataLoaded = cart?.items?.data || []
    setData(DataLoaded)
  }, [cart])

  return [data, initFetch, crudAction]
}
