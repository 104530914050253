import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Typography,
  Grid
} from '@mui/material'
import { BreadCrumbs } from 'components'
import { useLanguage, useTerms } from 'hooks'
import parse from 'html-react-parser';



function Index() {
  window.scrollTo(0, 0);                                         // Auto Scroll to Top

  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false);
  const [language, lagDic, setLang] = useLanguage()
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables] = useTerms(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)

  const breadcrumb = [
    {
      title: "Home", to: "/furnitur"
    }
  ]

  useEffect(() => {

    const param = {
      page: 1,
      limit: 10
    }
    setDatatables(param)
  }, [])

  return (
    <div className={classes.container}>
      <div style={{ paddingBottom: 20 }}>
        <BreadCrumbs
          breadcrumb={breadcrumb}
          current={language.COMMON.FOOTER.TERM_CONDITION}
        />
      </div>
      <Typography variant="body1" color="initial"><strong>{language.COMMON.FOOTER.TERM_CONDITION}</strong></Typography>
      <Grid >

        {datatables ?
          parse(`${datatables?.[0]?.VALUE_02}`)
          :
          <React.Fragment>
            <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
              he use of our online ordering service (“Online Ordering Service”) is governed by the terms and conditions outlined below (“Terms and Conditions”), together with our website terms of use (“Terms of Use”), and privacy policy (“Privacy Policy”).
            </Typography>
            <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
              The expressions “we”, “us” and “our” are a reference to FDB Commercial Pty Ltd ACN 608 647 605 (“FDB Commercial”), except where otherwise indicated. The term “you” and “your” refers to the user of our Online Ordering Service.

            </Typography>
            <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
              Please carefully read these Terms and Conditions, together with our Terms of Use and our Privacy Policy, before placing an order through our website. These Terms and Conditions contain important information about the ordering, processing, delivery, and installation of goods (where applicable), as well as our claims and returns policy.

            </Typography>
            <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
              Your use of our ‘Smart Quote Function’ or placement of an order through our Online Ordering Service indicates your acceptance of these Terms and Conditions.

            </Typography>
            <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
              If you do not accept any part of these Terms and Conditions, you are not authorised to use our Online Ordering Service.

            </Typography>
            <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
              Application of Credit Terms

            </Typography>
            <Typography variant="body1" color="initial">
              Where you have an approved commercial credit account with us, our terms and conditions of credit (as varied from time to time) (“Credit Terms”) apply. In the event of any conflict, ambiguity, or inconsistency between these Terms and Conditions and our Credit Terms, our Credit Terms prevail to the extent of the conflict, ambiguity, or inconsistency.

            </Typography>
            <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
              General

            </Typography>
            <Typography variant="body1" color="initial" >
              The content of the pages of our website are for your general information and use only. The pages, including these Terms and Conditions, are subject to change without notice.

            </Typography>
            <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
              Amendments will be effective immediately upon each time you view our website. It is your responsibility to check these Terms and Conditions periodically for any changes. Your continued use of our website will represent an agreement by you to be bound by these Terms and Conditions (as varied from time to time).

            </Typography>
            <Typography variant="body1" color="initial" sx={{ paddingTop: 2 }}>
              Orders generally

            </Typography>
            <Typography variant="body1" color="initial" >
              You may offer to purchase goods from us by using our Online Ordering Service.
            </Typography>
            <Typography variant="body1" color="initial" >.</Typography>
            <Typography variant="body1" color="initial" >.</Typography>
            <Typography variant="body1" color="initial" >.</Typography>
            <Typography variant="body1" color="initial" >.</Typography>
          </React.Fragment>
        }
      </Grid>

    </div >
  )
}

export default Index
