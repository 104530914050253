export const statusHistoryConstants = {
    GETALL_REQUEST: 'STATUS_HISTORY_GETALL_REQUEST',
    GETALL_SUCCESS: 'STATUS_HISTORY_GETALL_SUCCESS',
    GETALL_FAILURE: 'STATUS_HISTORY_GETALL_FAILURE',

    CREATE_REQUEST: 'STATUS_HISTORY_CREATE_REQUEST',
    CREATE_SUCCESS: 'STATUS_HISTORY_CREATE_SUCCESS',
    CREATE_FAILURE: 'STATUS_HISTORY_CREATE_FAILURE',

    DELETE_REQUEST: 'STATUS_HISTORY_DELETE_REQUEST',
    DELETE_SUCCESS: 'STATUS_HISTORY_DELETE_SUCCESS',
    DELETE_FAILURE: 'STATUS_HISTORY_DELETE_FAILURE',

    UPDATE_REQUEST: 'STATUS_HISTORY_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'STATUS_HISTORY_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'STATUS_HISTORY_UPDATE_FAILURE'
};