import { selectConstants } from '../_constants';
// import { selectService } from '../_services';

export const selectActions = {
    selectData,
    selectDataDetail
};
function selectData(param) {
    return dispatch => {
        dispatch(success(param));
    };

    // function request() { return { type: selectConstants.SELECT_ITEM_REQUEST } }
    function success(item) { return { type: selectConstants.SELECT_ITEM_SUCCESS, item } }
    // function failure(error) { return { type: selectConstants.SELECT_ITEM_FAILURE, error } }
}

function selectDataDetail(param) {
    return dispatch => {
        dispatch(success(param));
    };

    // function request() { return { type: selectDetailConstants.SELECT_ITEM_REQUEST } }
    function success(item) { return { type: selectConstants.SELECT_DETAIL_ITEM_SUCCESS, item } }
    // function failure(error) { return { type: selectConstants.SELECT_ITEM_FAILURE, error } }
}

