import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userActions } from '../../controllers/_actions'
import { useNavigate } from 'react-router'
export default function useUsers(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const users = useSelector(state => state.users);
  const dispatch = useDispatch()
  const history = useNavigate()
  const initFetch = useCallback((param) => {
    dispatch(userActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage,param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'create') {
      dispatch(userActions.createData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, history));
    } else if (type === 'update') {
      dispatch(userActions.updateData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    } else if (type === 'reset') {
      dispatch(userActions.resetData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    } else if (type === 'deactivate') {
      dispatch(userActions.deactivateData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    } else if (type === 'inputotp') {
      dispatch(userActions.confirmData(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    } else if (type === 'change') {
      dispatch(userActions.changePassword(param, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  useEffect(() => {
    const DataLoaded = users?.items?.data || []
    setData(DataLoaded)
    return () => setData({})
  }, [users])

  return [data, initFetch, crudAction]
}
