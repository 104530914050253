import dateFormat, { masks } from "dateformat";
export default function getDayNow(type) {
  // console.log("date", date)
  const convertdate = new Date()
  if(type === 'year') {
    const app_date = dateFormat(convertdate, "yyyy")
    return app_date
  } else if(type === 'complate') {
    const app_date = dateFormat(convertdate, "yyyy-mm-dd HH:MM:ss")
    return app_date
  }else {
    const app_date = dateFormat(convertdate, "yyyy-mm-dd")
    return app_date
  }
}