/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/lab/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog(props) {
  const {
    classes,
    param,
  } = props

  // const selectedValue = param.option.filter((data) => data.value === param.value)
  // const [value, setValue] = React.useState(selectedValue);
  const [open, toggleOpen] = React.useState(false);
  // const [disabled, setDisabled] = React.useState(param.disabled)

  const handleClose = () => {
    setDialogValue({
      value: ''
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    value: ''
  });

  const handleChange = (id, val) => {
    // setValue(val)
    param.onChange(id, !val ? {} : val)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // setValue({
    //   value: dialogValue.value
    // });
    param.onChange(param.id, {
      value: dialogValue.value
    })
    handleClose();
  };

  return (
    <React.Fragment>
      <div>

      <Autocomplete
        value={param.value}
        className={classes.itemContent}
        disabled={param.disabled}
        options={param.option || []}
        id={param.id}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                value: newValue,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              value: newValue.inputValue,
            });
            handleChange(param.id, newValue);
          } else {
            handleChange(param.id, newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              value: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        // options={top100Films}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.label}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            // label={param.label}
            fullWidth
            margin='dense'
            variant="outlined"
            helperText={param.helper}
            placeholder={param.placeholder}
            disabled={param.disabled}
            required={param?.required}
            inputProps={{
              ...params.inputProps,
              // autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {param.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {param.dialogMessage}
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              margin="dense"
              id="name"
              value={dialogValue.value}
              onChange={(event) => setDialogValue({ ...dialogValue, value: event.target.value })}
              label="title"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
