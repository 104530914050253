// Palette
import palette from '../palette';

const MuiTableRow = {
  root: {
    height: '15px',
    borderBottom: `2px solid ${palette.divider}`,
    '&:nth-of-type(odd)': {
      // backgroundColor: grey[100],
    },
    '&$selected': {
      backgroundColor: palette.primary.light
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.primary.light + '4A'
      }
    }
  }
};

export default MuiTableRow
