import {TableRow, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import {InputComponent, Tab} from 'components'
import TableCustom from './components/TableComponent';
import DefaultStructure from './Schema/default'
import { validateSign } from 'controllers/_validate'
import {
    useInput,
  } from 'hooks'
  import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/styles';
function AddComponent(props) {
    const [handleFieldChange, values] = useInput(validateSign, {})
    const [state, setState] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(0)
    const handleConfirm = () => {
        props.setList(values)
        props.close(false)
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F1F5F8",
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(even)': {
        backgroundColor: '#F1F5F8',
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));
  return (
    <Box>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h4'>Product Detail</Typography>
            {/* <Button onClick={()=> {props?.close(false)}} endIcon={<CloseIcon></CloseIcon>}></Button>         */}
           </Box>
          <Box>
          
          <TableCustom
          data={props?.PURCHASE_STATUS ?? []}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          setPurchaseItem={((val)=> {props?.setPurchaseItem(val)})}
          PURCHASE_STATUS={props?.PURCHASE_STATUS}
          isBergain={props?.isBergain}
          >
            
        </TableCustom>
        </Box>
      {/* <FormControl sx={{mt: 2}}>
        <Button variant='contained' color='primary' onClick={handleConfirm}>Close</Button>
      </FormControl> */}

    </Box>
  )
}

export default AddComponent