import React, { useState, useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Grid,
  Dialog,
  DialogContent, Fab
} from '@mui/material'
import { BreadCrumbs } from 'components'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useLanguage, useOurProject } from 'hooks'
import { useSelector } from 'react-redux'
import parse from 'html-react-parser';
import queryString from 'query-string'

import { useNavigate, useLocation } from 'react-router'



const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}))

export default function ViewStep(props) {
  window.scrollTo(0, 0);                                         // Auto Scroll to Top
  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  const {
    type,
    changeStep,
    step,
    onSelect,
    selected
  } = props
  const classes = useStyles()
  const [language, lagDic, setLang] = useLanguage()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getDetail] = useOurProject(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  const ourProjectDetail = useSelector(state => state.ourProjectDetail);
  const [rows, setRows] = React.useState([])
  console.log("🚀 ~ file: view.jsx ~ line 51 ~ ViewStep ~ rows", rows)

  useEffect(() => {

    const param = {
      ID: queryParse?.ID,
      page: 1,
      limit: 10
    }
    getDetail(param, 'detail')
  }, [])


  React.useEffect(() => {
    const rowData = ourProjectDetail?.items?.data ?? []
    if (rowData.length > 0) setRows(rowData)
    else setRows([])
  }, [ourProjectDetail])

  const [openImage, setopenImage] = useState(false);
  const [selectedImage, setselectedImage] = useState('');
  const [selectedImageIndex, setselectedImageIndex] = useState();

  const handleopenImage = (index) => {
    setopenImage(!openImage)
    // setselectedImage(image)
    setselectedImageIndex(index)
  };

  const handleClose = () => {
    setopenImage(false);
  };
  const baseUrlImage = window._env_?.BASEURL_IMAGE

  const breadcrumb = [
    {
      title: "Home", to: "/furnitur"
    },
    {
      title: language.COMMON.MENU.OUR_PROJECT, to: "/our-project?act=List"
    }
  ]
  // const image = [
  //   { image: 'https://d3p0bla3numw14.cloudfront.net/news-content/img/2021/04/30041007/Coworking-Space-Jakarta.jpg' },
  //   { image: 'https://www.rukita.co/stories/wp-content/uploads/2020/01/coworking-space-in-jakarta-1360x907.jpg' },
  //   { image: 'https://asset.kompas.com/crops/Y5bjj9t5KN2Vo_KMkL0VJMJ2LFs=/55x0:1471x944/750x500/data/photo/2019/09/28/5d8f70f49c107.jpg' },
  //   { image: 'https://arvahub.com/wp-content/uploads/2018/12/keistimewaan-bekerja-di-coworking-space-dan-virtual-office.jpg' },
  // ]

  const handlingNextImage = (index) => {

    if (index + 1 < rows?.[0]?.EXTRA_IMAGES.length) {
      setselectedImageIndex(index + 1)
    }
    // if (index + 1 < image.length) {
    //   setselectedImageIndex(index + 1)
    // }
  }
  const handlingPrevImage = (index) => {
    if (index > 0) {
      setselectedImageIndex(index - 1)
    }
  }
  return (
    <React.Fragment>
      <div className={classes.container}>
        <Dialog
          open={openImage}
          onClose={handleClose}
          fullWidth
          maxWidth={'md'}
        >
          <img
            style={{ width: '100%', height: '100%' }}
            src={`${baseUrlImage}${rows?.[0]?.EXTRA_IMAGES?.[selectedImageIndex]?.IMG_PATH}${rows?.[0]?.EXTRA_IMAGES?.[selectedImageIndex]?.IMG_MAIN}`}
            // src={rows?.[0]?.EXTRA_IMAGES?.[selectedImageIndex]?.image}
            alt=""
          />
          {selectedImageIndex + 1 !== rows?.[0]?.EXTRA_IMAGES.length
            ? <Fab
              color="secondary"
              aria-label=""
              sx={{
                position: 'fixed',
                bottom: '50%',
                right: 100,
              }}
              onClick={() => handlingNextImage(selectedImageIndex)}
            >
              <NavigateNextIcon />
            </Fab>

            : <Fab
              color="grey"
              aria-label=""
              sx={{
                position: 'fixed',
                bottom: '50%',
                right: 100,
              }}
            >
              <NavigateNextIcon />
            </Fab>
          }

          {selectedImageIndex !== 0
            ? <Fab
              color="secondary"
              aria-label=""
              sx={{
                position: 'fixed',
                bottom: '50%',
                left: 100,
              }}
              onClick={() => handlingPrevImage(selectedImageIndex)}
            >
              <NavigateBeforeIcon />
            </Fab>

            : <Fab
              color="grey"
              aria-label=""
              sx={{
                position: 'fixed',
                bottom: '50%',
                left: 100,
              }}
            >
              <NavigateBeforeIcon />
            </Fab>
          }

        </Dialog>

        <div style={{ paddingBottom: 20 }}>
          <BreadCrumbs
            breadcrumb={breadcrumb}
            current={rows?.[0]?.VALUE_02}
          />
        </div>
        {/* <Typography variant="body1" color="initial"><strong>{rows?.[0]?.VALUE_02}</strong></Typography> */}
        <Typography sx={{ paddingTop: 3 }} variant="body1" color="initial">
          {parse(`${rows?.[0]?.VALUE_03}`) ??
            " This fit out was for a modern, multi-space business centre located in the heart of Brisbane’s CBD. We had the opportunity to be apart of fitting out this multi-functional workplace, creating a large office space filled with workstations as well as some smaller offices designed for the single user. This project was carried out over a number of levels, each one having its own design elements that we aligned the products we used to."
          }
        </Typography>
        <Grid sx={{ paddingTop: 3, px: 25, paddingTop: 4 }} container spacing={2}>
          {rows?.[0]?.EXTRA_IMAGES.map((item, index) => {
            console.log("🚀 ~ file: view.jsx ~ line 196 ~ {rows?.[0]?.EXTRA_IMAGES.map ~ item", item)

            return (

              <Grid item lg={6} sm={12} >
                <img
                  style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
                  src={`${baseUrlImage}${item?.IMG_PATH}${item?.IMG_THUMB}`}
                  alt=""
                  onClick={() => handleopenImage(index)}
                />
              </Grid>
            )
          }
          )}

        </Grid>

      </div >
    </React.Fragment>
  );
}