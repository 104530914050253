/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography'
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/lab/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { CustomTable } from '../../../components'
import { FindInPage } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog(props) {
  const {
    classes,
    param,
    isLoading,
    // onFetch,
  } = props

  const [values, setValues] = React.useState({});

  React.useEffect(() => {
    const selectedValue = param?.option?.find((data) => data.value === param?.value?.value) ?? param?.value ?? undefined
    setValues(prevState => ({
      ...prevState,
      value: selectedValue?.value,
      label: selectedValue?.label,
      data: selectedValue
    }))
  }, [param.option, param.value])

  const [open, toggleOpen] = React.useState(false);
  // const [isLoading] = useState(false);
  const [error] = useState(null);

  const customersData = useSelector(state => state.customers);
  const countLoaded = customersData?.items?.total_count ?? 0;
  const [selected, setSelected] = useState({})

  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelect = async (row) => {
    console.log("🚀 ~ file: SearchField.jsx ~ line 51 ~ handleSelect ~ row", row)
    setSelected(row)
    setValues({
      value: row.additional_info?.CUSTOMER_NAME ?? row.main_customer?.additional_info?.CUSTOMER_NAME,
      data: row,
    });
    param.onChange(param?.id, {
      label: row?.additional_info?.CUSTOMER_NAME ?? row.main_customer?.additional_info?.CUSTOMER_NAME,
      value: row._key,
      data: row,
    })
    handleClose();
  }

  const moveStep = (row) => {
    console.log("🚀 ~ file: SearchField.jsx ~ line 62 ~ moveStep ~ row", row)
    setValues({
      label: row?.additional_info?.CUSTOMER_NAME ?? row.main_customer?.additional_info?.CUSTOMER_NAME,
      value: row._key,
      data: row,
    });
    param.onChange(param?.id, {
      label: row?.additional_info?.CUSTOMER_NAME ?? row.main_customer?.additional_info?.CUSTOMER_NAME,
      value: row._key,
      data: row,
    })
    handleClose();
  }

  const handleClose = () => {
    // setDialogValue({
    //   value: '',
    //   data: '',
    // });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    value: null,
    data: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValues({
      value: dialogValue.value,
      data: dialogValue.data,
    });
    param.onChange(param?.id, {
      label: selected?.additional_info?.CUSTOMER_NAME ?? selected.main_customer?.additional_info?.CUSTOMER_NAME,
      value: selected._key,
      data: selected
    })

    handleClose();

  };

  React.useEffect(() => {
    setPage(0);
    setRowsPerPage(10)
  }, [open])

  React.useEffect(() => {
    const dataparam = {}
    dataparam.data = {
      "customer_type": param.customer_type,
    };
    if (dialogValue?.value?.length > 0) {
      dataparam.data["additional_info"] = {
        "LOCATION": dialogValue?.value
      }
    }
    dataparam.skip = page * rowsPerPage;
    dataparam.limit = rowsPerPage;
    console.log('dataparam', dataparam)
    param.fetchData(dataparam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.customer_type, page, rowsPerPage, dialogValue?.value])



  function StatusHandler(stat) {
    if (stat === "Active") {
      return (
        <Typography className={classes.statusSuccess}>ACTIVE</Typography>
      )
    } else if (stat === "Inactive") {
      return (
        <Typography className={classes.statusError}>INACTIVE</Typography>
      )
    }
    return (
      <Typography className={classes.statusUndefined}>UNDEFINED</Typography>
    )
  }

  const columns = [
    { id: 'name', value: 'name', label: 'Name', minWidth: 100 },
    // { id: 'adminEmail', value: 'adminEmail', label: 'Email', minWidth: 100 },
    { id: 'LOCATION_ID', value: 'LOCATION_ID', parentId: "additional_info", label: 'Location ID', minWidth: 100 },
    { id: 'LOCATION', value: 'LOCATION', parentId: "additional_info", label: 'Location Name', minWidth: 100 },
    // { id: 'clientId', value: 'clientId', label: 'CIF', minWidth: 100 },
    // { id: 'clientSecret', value: 'clientSecret', label: 'Client Secret', minWidth: 100 },
    // { id: 'signKey', value: 'signKey', label: 'Signature Key', minWidth: 100 },
    {
      id: 'status', value: 'status', label: 'Status', minWidth: 100,
      format: (value) => StatusHandler(value),
      StringFormat: (value) => StatusHandler(value),
    }
  ];

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  return (
    <React.Fragment>
      <Autocomplete
        className={classes?.itemContent ?? "new"}
        value={param.value ?? values}
        // inputValue={values?.label ?? ''}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !values) {
            toggleOpen(true);
          }
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {  // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                value: newValue
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              value: "",
              title: newValue,
            });
          } else {
            setDialogValue({
              value: newValue,
              title: newValue,
            });
            setValues(newValue);
            param.onChange(param.id, newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              value: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        id={param.id}
        options={param?.option || []}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        selectOnFocus
        fullWidth
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.label}
        style={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          < TextField
            {...params}
            variant="outlined"
            margin='dense'
            helperText={param.helper}
            placeholder={param.placeholder}
            disabled={param.disabled}
            inputProps={{
              ...params.inputProps,
              // autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    title={param?.title ?? "search more"}
                  >
                    <IconButton
                      aria-label="search more"
                      onClick={(e) => {
                        toggleOpen(true);
                        setDialogValue({
                          value: "",
                          title: "",
                        });
                      }}
                      onMouseDown={(e) => {
                        toggleOpen(true);
                        setDialogValue({
                          value: "",
                          title: "",
                        });
                      }}
                    >
                      <FindInPage />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {!isEmpty(param?.value ?? {}) && param?.value
        ? <Box className={classes.root} borderColor="secondary.main" border={1} marginTop={2} borderRadius={16} padding="16px" marginBottom="8px">
          <Typography variant="caption" color='textPrimary'>
            <b>
              {values?.label ?? 'Unkonwn Name'}
            </b>
            <br />
            <ul>
              <li>Address : {param.value?.data?.additional_info?.ADDRESS ?? 'Unkonwn Address'}</li>
              <li>Email : {param.value?.data?.additional_info?.EMAIL_ADDRESS ?? 'Unkonwn Email'}</li>
              <li>Location : {param.value?.data?.additional_info?.LOCATION ?? "Unknown Location"}</li>
              {/* <li>no special characters;</li> */}
            </ul>
          </Typography>
        </Box>
        : null
      }
      <Dialog open={open} onClose={() => toggleOpen(false)} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Search Customer <b>"{dialogValue?.value}"</b></DialogTitle>
          <DialogContent>
            <CustomTable
              rows={customersData?.items?.data ?? []}
              loading={isLoading}
              error={error}
              selectableOnly={true}
              onSelect={handleSelect}
              changeStep={moveStep}
              selected={selected}
              // multiAction
              // actionStructure={actionStructure}
              // action={(body, type) => props.checkingApprove(body, type)}
              columns={columns}
              rowsPerPage={rowsPerPage}
              dbPagination={true}
              totalCount={countLoaded ?? 0}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleOpen(false)} variant="outlined" color="secondary">
              Close
            </Button>
            {/* <Button type="submit" variant="contained" color="primary">
              Select
            </Button> */}
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}