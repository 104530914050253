
import { authHeader } from '../_helpers'
import service from '../_helpers/interceptor'

export const lookupService = {
    getLookupProvinsi,
    getLookupKabupaten,
    getLookupZipcode,
    getHistoryNego, 
    getPdfTransaction 
};

async function getLookupProvinsi(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "ACTIVE_STATUS":true, 
                "IS_DUMP": false,
                "CATEGORY_TYPE": 'CATEGORY',       
                "GROUP_INFO":"PARENT", 
            },
            "PAGINATION": {
                "PAGE_NO": param?.page?.toString() ?? "1",
                "PAGE_MAX": param?.limit?.toString()
            }
        }
        const requestUrl = `/clarity-be/customer/address/read/province`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            service.get(`${requestUrl}`, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        data: [],
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getLookupKabupaten(param) {
    try {
        const bodyOptions = {
            "ADDRESS_PROVINSI": param?.ADDRESS_PROVINSI
        }
        const requestUrl = `/clarity-be/customer/address/read/city`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            service.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        data: [],
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getLookupZipcode(param) {
    try {
        const bodyOptions = {
            "ADDRESS_KABUPATEN": param?.ADDRESS_KABUPATEN
        }
        const requestUrl = `/clarity-be/customer/address/read/zipcode`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            service.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        data: [],
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getHistoryNego(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "INVOICE_NO": param?.INVOICE_NO
            },
            "PAGINATION": {
                "PAGE_NO": param?.page?.toString() ?? "1",
                "PAGE_MAX": param?.limit?.toString()
            }
        }
        const requestUrl = `/clarity-be/shipping/negotiation/history`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            service.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        data: [],
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getPdfTransaction(param) {
    try {
        const bodyOptions = {
            "FILTER":{
            "INVOICE_NO": param?.INVOICE_NO
            }
        }
        const requestUrl = `/clarity-be/shipping/retail/export-pdf`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            service.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            data: [],
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        data: [],
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}