import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    container : {
        background : '#20376D',
        height: theme.spacing(5),
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0,5),
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            height: theme.spacing(7),
            overflowX: 'scroll'
          },
    },
    active: {
        color: 'white',
        background : '#112552',
    },
    inactive: {
        color: 'white',
        // background: 'green'
    },
    default : {
        display: 'flex',
        padding: theme.spacing(1),
        textAlign: 'center',
        justifyContent:'center',
        marginLeft: theme.spacing(3),
        textDecoration: 'none',
        whiteSpace: 'noWrap',
        cursor: 'pointer',
        "&:hover": {
            background : '#112552',
          },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
            marginLeft: theme.spacing(3),
          },
    },
    contact: {
        color: 'white',
        display: 'flex',
        textAlign: 'center',
        justifyContent:'center',
        whiteSpace: 'noWrap',
        "&:hover": {
            background : '#112552',
          },
        cursor: 'pointer',
        padding: theme.spacing(1,2),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
          },
        
    },
    menu: {
        display: 'flex',
        textAlign: 'center',
    }
  }));
