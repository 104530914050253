const table = {
   TABLE_ACCOUNT_STATEMENT: {
      TIME: "Date / Time",
      NO_REF: "Ref Number",
      DESC: "Description",
      AMOUNT: "Amount",
      BALANCE: "Balance",
      START_BALANCE: "Starting Balance",
      END_BALANCE: "Ending Balance",
   },
   TABLE_TRANSFER_HIS_EBANKING: {
      NO_REF: "Ref Number",
      DESTINATION_NAME: "Destination Name",
      SOURCE_OF_FUND: "Source of Fund",
      DESTINATION: "Destination",
      STATUS: "Status",
      RESULT_CODE: "Result Code",
      AMOUNT: "Amount",
      TRX_TYPE: "Transaction Type",
      TRX_DATE: "Transaction Time",
   },
   TABLE_PAYROLL_HIS_EBANKING: {
      PAYROLL_DATE: "Payroll Date",
      PAYROLL_NAME: "Payroll Name",
      TOTAL_RECORD: "Total Record",
      SUCCESS_PAYROLL: "Success Payroll",
      PAYROLL_TIME: "Payroll Time",
      DESC: "Deskripsi",
      ACTION: "Action",
   },
   TABLE_SCHEDULE_TRANSFER: {
      NO_REF: "Ref Number",
      ACC_NAME: "Account Name",
      ACC_NUMBER: "Account Number",
      TRX_METHOD: "Metode Tranfer",
      BANK: "Bank",
      AMOUNT: "Amount",
      SCHEDULED_DATE: "Scheduled Date",
      END_DATE: "End Date",
      START_DATE: "Start Date",
      ACTION: 'Action'
   },
   TABLE_SCHEDULE_PAYROLL: {
      NO_REF: "Ref Number",
      PAYROLL_NAME: "Payroll Name",
      TOTAL_AMOUNT: "Total Amount",
      TOTAL_RECORD: "Total Record",
      PAYROLL_NEWS: "Payroll News",
      TRX_TIME: "Waktu TransAction",
      SCHEDULED_DATE: "Scheduled Date",
      END_DATE: "End Date",
      START_DATE: "Start Date",
      ACTION: "Action",
   },
   TABLE_ONGOING_TRANSFER: {
      NO_REF: "Ref Number",
      TASK_TYPE: "Task Type",
      ACC_NAME: "Account Name",
      ACC_NUMBER: "Account Number",
      CREATED_DATE: "Created Date",
      TRX_METHOD: "Transfer Method",
      TRX_TIME: "Transaction Time",
      AMOUNT: "Amount",
      STATUS: "Status",
   },
   TABLE_ONGOING_PAYROLL: {
      NO_REF: "Ref Number",
      TASK_TYPE: "Task Type",
      PAYROLL_NAME: "Payroll Name",
      TOTAL_AMOUNT: "Total Amount",
      TOTAL_RECORD: "Total Record",
      PAYROLL_NEWS: "Payroll News",
      TRX_TIME: "Transaction Time",
      CREATED_DATE: "Created Date",
      STATUS: "Status",
   },
   TABLE_PASTTASK_TRANSFER: {
      NO_REF: "No.Ref",
      TASK_TYPE: "Task Type",
      ACC_NAME: "Account Name",
      ACC_NUMBER: "Account Number",
      CREATED_DATE: "Created Date",
      TRX_METHOD: "Tranfer Method",
      AMOUNT: "Amount",
      TRX_TIME: "Transaction Time",
      STATUS: "Status",
   },
   TABLE_PASTTASK_PAYROLL: {
      NO_REF: "No.Ref",
      TASK_TYPE: "Task Type",
      PAYROLL_NAME: "Payroll Name",
      PAYROLL_NEWS: "Payroll news",
      CREATED_DATE: "Created Date",
      AMOUNT: "Amount",
      TRX_TIME: "Transaction Time",
      STATUS: "Status",
   },
   TABLE_BENEF_LIST: {
      ACCOUNT_NAME: "Account Number",
      ACCOUNT_NUMBER: "Account Number",
      BANK_NAME: "Bank Name",
      ALIAS: "Alias",
      ACTION: "Action",
   },
   TABLE_PAYROLL_LIST: {
      PAYROLL_DATE: "Payroll Date",
      PAYROLL_NAME: "Payroll Name",
      TOTAL_RECORD: "Total Record",
      AMOUNT: "Total Amount",
      SUCCESS_PAYROLL: "Success Payroll",
      PAYROLL_TIME: "Payroll Time",
      DESC: "Deskripsi",
      MODIFIED_DATE: 'Modified Date',
      STATUS: 'Status',
      ACTION: "Action",
   },
   TABLE_EMPLOYEE_LIST: {
      ACCOUNT_NAME: "Account Name",
      BANK: "Bank",
      ACCOUNT_NUMBER: "Account Number",
      EMPLOYEE_ID: "NIP",
      AMOUNT: "Amount",
      AUTO_DEBIT: "Auto Debit",
      ACTION: "Action",
   },
   TABLE_AUTO_DEBIT_LIST: {
      START_DATE: "Start Date",
      END_DATE: "End Date",
      AUTO_DEBIT_NAME: "Auto Debit Name",
      AUTO_DEBIT_CODE: "Auto Debit Code",
      ACCOUNT_DEST: "Akun Tujuan Pribadi",
      PRIM_AMOUNT: "Primary Amount",
      SEC_AMOUNT: "Secondary Amount",
      ACTION: "Action",
   },
   TABLE_STATUS_UPLOAD: {
      ACCOUNT_NAME: "Account Name",
      BANK: "Bank",
      ACCOUNT_NUMBER: "Account Number",
      EMPLOYEE_ID: "Employee ID",
      AMOUNT: "Amount",
      AUTO_DEBIT: "Auto Debit",
      STATUS: "Status",
      ACTION: "Action",
   },
   TABLE_BRANCH_LOCATION: {
      DESC: "Deskripsi",
      ADDRESS: "Address",
      CHECK_LOCATION: "Check Location",
   },
   TABLE_BULK_TRANSFER_LIST: {
      DATE: "Date",
      NAME: "Bulk Transfer Name",
      TOTAL_RECORD: "Total Record",
      AMOUNT: "Total Amount",
      SUCCESS_PAYROLL: "Success Bulk Transfer",
      TIME: "Time",
      DESC: "Deskripsi",
      MODIFIED_DATE: 'Modified Date',
      STATUS: 'Status',
      ACTION: "Action",
      NEWS: "Bulk Transfer News",
   },
   TABLE_RDN_BALANCE: {
      FT_REF: "ExtRef",
      PE_CODE: "PE Code",
      ACC_NUMBER: "Account Number",
      CCY: "Currency",
      VAL_DATE: "Date",
      BALANCE: "Balance",
   },
   TABLE_RDN_STATEMENT: {
      FT_REF: "ExtRef",
      SEQ_NO: "SeqNum",
      TRANS_CODE: "TrxType",
      DESCRIPT: "Description",
      VAL_DATE: "Date",
      AMOUNT: "CashVal",
      DRCR_SIGN: "DC",
      CLOSE_BAL: "Closing Balance",
      CCY: "Currency",
      ACC_NUMBER: "Account Number",
      OPEN_BAL: "Opening Balance",
      STAT_LINE: "StatLineExtRef",
      NOTES: "Notes",
   },
}

export default table

