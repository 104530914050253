// import { userConstants } from '../_constants';
import { userService } from "../_services";

// External Libs
import nacl from "tweetnacl";
import naclUtil from "tweetnacl-util";

// internal import
import { getAccessLog } from "../../helpers";

function getSignature(request_key, request_id, stringToHash) {
  try {
    //encrypt hashed string
    const key = naclUtil.decodeBase64(request_key);
    const formattedStringToHash = naclUtil.decodeUTF8(stringToHash);
    const hashedStringToHash = nacl.hash(formattedStringToHash);
    const keys = nacl.box.keyPair();
    const nonce = nacl.randomBytes(nacl.box.nonceLength);
    const encryptedStringToHash = nacl.box(
      hashedStringToHash,
      nonce,
      key,
      keys.secretKey
    );
    const endcodedStringToHash = naclUtil.encodeBase64(encryptedStringToHash);

    const RequestId = request_id;
    const RequestNonce = naclUtil.encodeBase64(nonce);
    const RequestKey = naclUtil.encodeBase64(keys.publicKey);

    //generate signature: base64 JSON stringify of requestId, key, nounce, encrtypted data
    const prepareSignature = {
      RequestId: RequestId,
      RequestKey: RequestKey,
      RequestNonce: RequestNonce,
      RequestData: endcodedStringToHash,
    };
    const decodeUTF8Signature = naclUtil.decodeUTF8(
      JSON.stringify(prepareSignature)
    );
    const Signature = naclUtil.encodeBase64(decodeUTF8Signature);
    return Signature;
  } catch (error) {
    console.log(
      "Error to generate signature key",
      request_key,
      request_key,
      stringToHash
    );
    return "no-signature";
  }
}

async function takeSignature() {
  const res = await userService.getSignature();
  if (res.status_code === "S1") {
    return res;
  } else if (res.status_code === 500) {
    return res;
  } else {
    return res;
  }
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export async function authHeader(requestUrl, requestBody, type) {
  if (type === "not mandatory") {
    const user = getAccessLog("access_token");
    return {
      // Authorization: "Bearer " + user,
    };
  } else {
    const isEmptyObj = isEmpty(requestBody);
    const stringToHash = !requestUrl
      ? ""
      : requestUrl.concat(isEmptyObj ? "" : JSON.stringify(requestBody));
    // const keyData = await takeSignature();
    const keyData = {};
    // console.log("authHeader -> stringToHash", stringToHash)
    // console.log("authHeader -> keyData", keyData)
    const keyValue = keyData?.request_key || "";
    // console.log("authHeader -> keyValue", keyValue, keyData.request_key)
    const keyID = keyData?.request_id || "";
    // console.log("authHeader -> keyID", keyID)

    const signature = getSignature(keyValue, keyID, stringToHash);
    console.log("authHeader -> signature", signature);

    // return authorization header with jwt token
    const user = getAccessLog("access_token");
    // console.log("🚀 ~ file: auth-header.js ~ line 73 ~ authHeader ~ user", user)

    if (user && keyData.request_key) {
      return {
        Authorization: "Bearer " + user,
        "X-honda-signature": signature ?? "null-signature",
      };
    } else if (!user && keyData.request_key) {
      return {
        Authorization: "Bearer " + user,
        "X-honda-signature": signature ?? "null-signature",
      };
    } else if (user && !keyData.request_key) {
      return {
        Authorization: "Bearer " + user,
        "X-honda-signature": signature ?? "null-signature",
      };
    }

    if (type === 'multipart') {
      return {
        "Content-Type": "multipart/form-data"
      }
    }
  }
}
