import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import { useExternal } from 'hooks'


function Index() {
  const classes = useStyles()


  const [value, setValue] = useState({});
  console.log("🚀 ~ file: index.jsx ~ line 11 ~ Index ~ value", value)

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getData] = useExternal(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)

  useEffect(() => {

    const param = {
      page: 1,
      limit: 24
    }
    setDatatables(param)

  }, [])

  return (
    <div className={classes.container}>
      TEST
    </div>
  )
}

export default Index
