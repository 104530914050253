const common = {
  SYSTEM: {
    NAME: 'Warehouse Management System',
    DESCRIPTION: 'WMS for Honda Indonesia',
    VERSION: '0.1.0',
    WELCOME: 'Welcome to',
    WELCOME1: 'Welcome',
    COMPANY: 'Honda Indonesia',
    PUBLISHER: 'Inovasi DIgital Ritel',
    ADVANCE_FILTER: 'Advance Filter'
  },
  MENU: {
    PROFILE: 'Hi',
    HOME: 'Home',
    SHOP_BY_PRODUCT: 'Shop by Product',
    SHOP_BY_RANGE: 'Shop by Range',
    SALE: 'SALE',
    OFFICE_FITS_OUT: 'Office Fits Out',
    OUR_PROJECT: 'Our Project',
    CONTACT_US: 'Contact Us',
    CHAT_WITH_US: 'Chat with Us',
    LOGOUT: 'Logout'
  },
  CONTENT: {
    SEARCH: 'Search',
    SIGN_IN: 'Sign In',
    ACCOUNT: 'Account',
    ITEMS: 'Items',
    POPULAR_PRODUCT: 'Popular Product',
    EMPTY_PRODUCT: 'No Popular Product are Listed',
  },
  FIELDTEXT: {
    FIRST: 'First Name',
    LAST: 'Last Name',
    EMAIL: 'Email',
    COMMENT: 'Comment'
  },
  FOOTER: {
    CONTACT_INFORMATION: 'Contact Information',
    SEE_WHY: 'See why our customer service really sets us apart. Our team are ready to help with any enquiry you may have.',
    CONSUMER_COMPLAINT: 'Consumer Complaint Service',
    HELP_SUPPORT: 'Help & Support',
    TERM_CONDITION: 'Terms & Condition',
    CONTACT_US: 'Contact Us',
    MENU: 'Menu',
    HOME: 'Home',
    MY_ACCOUNT: 'My Account',
    ABOUT_US: 'About Us',
    OFFICE_FITS_OUT: 'Office Fits Out',
    OUR_PROJECT: 'Our Project',
    PAYMENT: 'Payment'
  },
  REGISTER: {
    REGISTRATION: 'Registration',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    RE_TYPE_PASSWORD: 'Re-Type Password',
    REGISTER: 'Register',
    ALREADY_HAVE_ACCOUNT: 'Already Have Account?',
    LOGIN_HERE: 'Login Here',
    VERIFY: 'Verify your email address in order to access your Clarity Account, you need to confirm your email address'

  },
  LOGIN: {
    MASUK: 'Login',
    EMAIL: 'Email',
    FORGOT: 'Forgot Password',
    PASSWORD: 'Password',
    LOGIN: 'Login',
    DONT_HAVE_ACCOUNT: 'Don’t Have an Account?',
    REGISTER_HERE: 'Register Here'
  },
  PRODUCT_LIST: {
    FILTER: 'Filter',
    CLEAR_FILTER: 'Clear Filter',
    SHOW: 'Show',
    ITEMS: 'items',
    FILTER_BY_PRICE: 'Filter By Price',
    FILTER_BY_FUNCTIONALITY: 'Filter By Functionality',
    RANGE: 'Range',
    FILTER_BY_SPECIFICATION: 'Filter By Specification',
    FILTER_BY_CATEGORY: 'Filter By Category',
    PRICE_ASC: 'Cheapest Price',
    PRICE_DESC: 'Most Expensive Price',
    ALPHA: 'Alphabetical',
    DEFAULT: 'Default Sorting'
  },
  PRODUCT_INFORMATION_DETAIL: {
    SIZE: 'Size',
    QUANTITY: 'Quantity',
    ADD_TO_CHART: 'Add to Cart',
    OPTIONAL_EXTRAS: 'Optional Extras',
    DESCRIPTION: 'Description'
  },
  SUBMITTED_PRODUCT_TO_CART: {
    SUCCESSFULLY_ADD_ITEM: 'Successfully Add Item to Cart',
    HAS_BEEN_ADDED: 'has been added to your cart',
    SHOP_MORE: 'Shop More',
    VIEW_CHART: 'View Cart'
  },
  CART: {
    PRODUCT: 'Product',
    DIALOG_TITLE_CONFIRM: "Can't Continue Order",
    DIALOG_BODY_CONFIRM: 'Maximum transaction for role PP is Rp 200.000.000,- . Please review your cart.',
    PRICE: 'Price',
    QUANTITY: 'Quantity',
    SUBTOTAL: 'Subtotal',
    SHIPPING_ADDRESS: 'Shipping Address',
    CHANGE_ADDRESS: 'Change Address',
    CART_TOTAL: 'Cart Total',
    SUB_TOTAL: 'Sub Total',
    SHIPPING: 'Shipping',
    TOTAL_PAYMENT: 'Total Payment',
    PROCEED_TO_CHECK: 'Proceed to Check Out',
    PROCEED_TO_TRANSACTION: 'Proceed to Transaction',
    BUYER_INFORMATION: 'Buyer Information',
    BUYER_NAME: 'Buyer Name',
    ROLE: 'Role',
    ORDER_CONFIRMATION: 'Order Confirmation',
    TRANSACTION_CONFIRMATION: 'Transaction Confirmation',
    SELECT_ADDRESS: 'Select Address',
    ADD_ADDRESS: 'Add Address',
    PROVINCE: 'Province',
    DISTRICT_CITY: 'District / City',
    FULL_ADDRESS: 'Full Address',
    SEND_ALL_ITEMS: 'Send all items to one address',
    IN_CART: 'In Cart',
    ADD_SHIPPING: 'Add Shipping',
    SHIPPING: 'Shipping',
    LABEL_ADDRESS: 'Label Address',
    DISTRICT: 'District',
    SHIPPING_COST: 'Shipping Cost',
    TOTAL: 'Total',
    DELETE: 'Delete',
    PRODUCT_QUANTITY: 'Product Quantity',
    SHIPPING_TOTAL: 'Shipping Total',
    SHIPPING_CONFIRMATION: 'Shipping Confirmation'
  },
  CHANGE_ADDRESS: {
    UPDATE_ADDRESS: 'Update Address',
    PROVINCE: 'Province',
    CITY: 'City',
    POSTAL_CODE: 'Postal Code',
    FULL_ADDRESS: 'Full Address',
    UPDATE: 'Update'
  },
  CHECK_OUT: {
    YOUR_ORDER: 'Your Order',
    PRODUCT: 'Product',
    QUANTITY: 'Quantity',
    PRODUCT_PRICE: 'Product Price',
    YOUR_SHIPPING_ADDRESS: 'Your Shipping Address',
    SHIPPING_COST: 'Estimated Shipping Cost',
    PAYMENT_SUMMARY: 'Payment Summary',
    TOTAL_PRODUCT_PRICE: 'Total Product Price',
    TOTAL_PAYMENT: 'Total Payment',
    PAYMENT_CONFIRMATION: 'Payment Confirmation',
    BACK_TO_HOME: 'Back to Home',
  },
  PAYMENT_CONFIRMATION_MIDTRANS: {
    PAYMENT: 'Payment',
    AMOUNT: 'Amount',
    ORDER_ID: 'Order ID',
    ORDER_DETAILS: 'Order Details',
    ITEM: 'Item(s)',
    AMMOUNT: 'Ammount',
    SHIPPING_DETAILS: 'Shipping Details',
    CONTINUE: 'Continue',
    TRANFER_BANK: 'Transfer Bank (Verifikasi Manual)',
    TRANFER_BANK_BCA: 'Transfer Bank BCA',
    TRANFER_BANK_MANDIRI: 'Transfer Bank Mandiri',
    TRANFER_BANK_BNI: 'Transfer Bank BNI',
    TRANFER_BANK_BRI: 'Transfer Bank BRI',
    TRANFER_VIRTUAL_ACCOUNT: 'Transfer Virtual Account',
    VIRTUAL_ACCOUNT_BANK_BCA: 'Virtual Account Bank BCA',
    VIRTUAL_ACCOUNT_BANK_MANDIRI: 'Virtual Account Bank Mandiri',
    BCA_VITUAL_ACCOUNT: 'BCA Virtual Account',
    VIRTUAL_ACCOUNT_NUMBER: 'Virtual Account Number',
    COPY: 'Copy',
    TOTAL_PAYMENT: 'Total Payment',
    ATM_BCA: 'ATM BCA',
    M_BCA: 'm-BCA (BCA Mobile)',
    INTERNET_BANKING_BCA: 'Internet Banking BCA',
    BCA_BANK_OFFICE: 'BCA BANK OFFICE',
    PLEASE_COMPLATE_PAYMENT: 'Please Complete Payment'
  },
  MY_ACCOUNT: {
    NAVIGATION: 'Navigation',
    MY_ACCOUNT: 'My Account',
    MY_ADDRESS: 'My Address',
    MY_TRANSACTION: 'My Transaction',
    SEARCH_ADDRESS: 'Search Address',
    YOUR_NAME: 'Your Name',
    EMAIL: 'Email',
    PHONE_NO: 'Phone No',
    PREFERRED_LANGUAGE: 'Preferred Language',
    ENGLISH: 'English',
    CHANGE_PASSWORD: 'Change Password',
    PROVINCE: 'Province',
    CITY: 'City',
    POSTAL_CODE: 'Postal Code',
    FULL_ADDRESS: 'Full Address',
    SAVE_CHANGES: 'Save Changes',
    GENERAL_INFORMATION: 'General Information',
    COMPANY_NAME: 'Company Name',
    USERNAME: 'Username',
    FULL_NAME: 'Full Name',
    ROLE: 'Role',
    USER_ID: 'User ID',
    PERSONAL_INFORMATION: 'Personal Information',
    DIRECTORATE: 'Directorate',
    NIP: 'NIP',
    POSITION: 'Position',
    CERTIFICATE_PJB: 'Certificate PBJ No',
    WORK_UNIT_INFORMATION: 'Work Unit Information',
    WORK_UNIT: 'Work Unit',
    WORK_UNIT_NPWP: 'Work Unit NPWP',
    WORK_UNIT_ADDRESS: 'Work Unit Address',
    SHIPPING_ADDRESS: 'Shipping Address',
    SHIPPING_FULL_ADDRESS: 'Shipping Full Address'
  },
  MY_TRANSACTION: {
    NAVIGATION: 'Navigation',
    MY_ACCOUNT: 'My Account',
    MY_TRANSACTION: 'My Transaction',
    SEARCH_TRANSACTION_CODE: 'Search Transaction Code',
    SELECT_TRANSACTION_DATE: 'Select Trancastion Date',
    STATUS: 'Status',
    ALL_STATUS: 'All Status',
    WAITING_FOR_PAYMENT: 'Waiting for Payment',
    ON_PROGRESS: 'On Progress',
    ON_SHIPPING: 'On Shipping',
    COMPLATED: 'Completed',
    CANCELED: 'Canceled',
    ORDER_ID: 'Order ID',
    ORDER_DATE: 'Order Date',
    TOTAL_PRODUCT: 'Total Product',
    QUANTITY: 'Quantity',
    TOTAL_PAYMENT: 'Total Ammount',
    ACTION: 'Action',
    MY_SHIPPING_ADDRESS: 'My Shipping Address',
    PROGGRESS: 'Progress',
    ALL_PROGRESS: 'All Progress',
    NEGOTIATION_PROGGRESS: 'Negotiation Progress',
    NEGOTIATED: 'Negotiated',
    SHIPPING_FEE: 'Shipping Fee',
    TOTAL_AMOUNT: 'Total Ammount',
    TRANSACTION_DETAIL: 'Transaction Detail',
    TRANSACTION_SUMMARY: 'Transaction Summary',
    PRINT: 'Print'
  },
  TABLE_MY_TRANSACTION: {
    ORDERID: 'Order ID',
    PURCHASED_DATE: 'Purchased Date Time',
    INSTANCE: 'Instance',
    PIC: 'PIC',
    TRANSACTION_AMOUNT: 'Transaction Amount',
    LAST_STATUS: 'Last Status Date Time',
    STATUS: 'Status',
    ACTION: 'Action'
  },
  MY_TRANSACTION_DETAIL: {
    NAVIGATION: 'Navigation',
    MY_ACCOUNT: 'My Account',
    MY_TRANSACTION: 'My Transaction',
    TRANSACTION: 'Transaction',
    ORDER_ID: 'Order ID',
    PURCHASED_DATE: 'Purchased Date/Time',
    RECEIPT_NO: 'Receipt No',
    STATUS: 'Status',
    YOUR_ORDER: 'Your Order',
    PRODUCT: 'Product',
    QUANTITY: 'Quantity',
    PRODUCT_PRICE: 'Product Price',
    TOTAL_PRICE: 'Total Price',
    YOUR_SHIPPING_ADDRESS: 'Your Shipping Address',
    PAYMENT_DETAILS: 'Payment Details',
    PAYMENT_METHOD: 'Payment Method',
    TOTAL_PRODUCT_PRICE: 'Total Product Price',
    SHIPPING_COST: 'Shipping Cost',
    TOTAL_PAYMENT: 'Total Payment',
    MAIN_INFORMATION: 'Main Information',
    PRODUCT_SHOWCASE: 'Product Showcase',
    PACKAGE_ID: 'Package ID',
    RUP_ID: 'RUP ID',
    PACKAGE_NAME: 'Package Name',
    INSTANCE: 'Instance',
    WORK_UNIT: 'Work Unit',
    WORK_UNIT_NPWP: 'Work Unit NPWP',
    WORK_UNIT_ADDRESS: 'Work Unit Address',
    CREATED_DATE: 'Created Date',
    MODIFIED_DATE: 'Modified Date',
    BUDGET_YEAR: 'Budget Year',
    TOTAL_PRODUCT: 'Total Product',
    NEGOTIAION_PRICE: 'Negotiation Price',
    NEGOTIAION_HISTORY: 'Negotiation History',
    REVISION: 'Revision',
    BY: 'By',
    NEXT: 'Next',
    BUYER_INFORMATION: 'Buyer Information',
    BUYER_NAME: 'Buyer Name',
    ROLE: 'Role',
    PRODUCT_QUANTITY: 'Product Quantity',
    CART_TOTAL: 'Cart Total',
    SUB_TOTAL: 'Sub Total',
    SHIPPING: 'Shipping',
    BACK_TO_TRANSACTION: 'Back to Transaction',
    BUYER_PPK: 'Buyer & PPK',
    PP_COMMITTEE: 'PP/ Comittee',
    NAME: 'Name',
    POSITION: 'Position',
    NIP: 'NIP',
    EMAIL: 'Email',
    PHONE_NO: 'Phone Number',
    CERTIFICATE_PJB: 'Certificate PBJ Number',
    BACK: 'Back',
    VENDOR_DISTRIBUTOR: 'Vendor/Distributor',
    VENDOR: 'Vendor',
    NPWP: 'NPWP',
    FAX: 'FAX',
    DISTRIBUTOR_RESELLER: 'Distrubutor/Reseller/Job Implementer/Courier',
    ADDRESS: 'Address',
    WEBSITE: 'Website',
    MY_SHIPPING_ADDRESS: 'My Shipping Address',
    REV_ID: 'Rev ID',
    WAITING_ADMIN_ADD: 'Waiting Admin Add Delivery Time',
    DELIVERY_TIME: 'Delivery Time',
    PRODUCT_DETAIL: 'Product Detail',
    NOTE: 'Note',
    WORK_UNIT_ADDRESS: 'Work Unit Address',
    PPK: 'PPK',
    LABEL_ADDRESS: 'Label Address',
    SHIPPING_TOTAL: 'Shipping Total',
    NEGOTIAION_FEE_HISTORY: 'Negotiation Fee History',
    BARGAIN_PRICE: 'Bargain Price',
    APPROVE: 'Approve'
  },
  MY_SHIPPING_ADDRESS: {
    SEARCH_ADDRESS: 'Search Address',
    ADD_NEW_ADDRESS: 'Add New Address',
    CREATE_NEW_ADDRESS: 'Create New Address',
    MAIN_ADDRESS: 'Main Address',
    SET_MAIN_ADDRESS: 'Set Main Address',
    DELETE: 'Delete',
    EDIT: 'Edit',
    LABEL_ADDRESS: 'Label Address',
    PROVINCE: 'Province',
    CITY_DISTRICT: 'City / District',
    FULL_ADDRESS: 'Full Address',
    STATUS: 'Status',
    ADD_EDIT: 'Add New / Edit',
    FUND_SOURCE: 'Fund Source',
    BUDGET_SOURCE: 'Budget Source',
    BUDGET_CODE: 'Budget Code',
    NEGOTIAION_PRICE: 'Negotiation Price',
    NEGOTIAION_HISTORY: 'Negotiation History',
    REVISION: 'Revision',
    BY: 'By',
    TOTAL_PRICE: 'Total Price',
    BACK: 'Back',
    YOUR_ORDER: 'Your Order',
    PRODUCT: 'Product',
    QUANTITY: 'Quantity',
    PRODUCT_PRICE: 'Product Price',
    BUYER_INFORMATION: 'Buyer Information',
    BUYER_NAME: 'Buyer Name',
    ROLE: 'Role',
    PRODUCT_QUANTITY: 'Product Quantity',
    CART_TOTAL: 'Cart Total',
    SUB_TOTAL: 'Sub Total',
    SHIPPING: 'Shipping',
    TOTAL_PAYMENT: 'Total Payment',
    BACK_TO_TRANSACTION: 'Back to Transaction'
  },
  MY_TRANSACTION_TRACKING: {
    SHIPPING_HISTORY: 'Shipping History',
    UPDATE_DATE_TIME: 'Update Date Time',
    LOCATION: 'Location',
    MESSAGE_FROM_LOGISTIC: 'Message From Logistic',
    CLOSE: 'Close'
  },
  MY_TRANSACTION_DONE: {
    NAVIGATION: 'Navigation',
    MY_ACCOUNT: 'My Account',
    MY_TRANSACTION: 'My Transaction',
    TRANSACTION: 'Transaction',
    ORDER_ID: 'Order ID',
    PURCHASED_DATE: 'Purchased Date/Time',
    RECEIPT_NO: 'Receipt No',
    STATUS: 'Status',
    YOUR_ORDER: 'Your Order',
    PRODUCT: 'Product',
    QUANTITY: 'Quantity',
    PRODUCT_PRICE: 'Product Price',
    TOTAL_PRICE: 'Total Price',
    YOUR_SHIPPING_ADDRESS: 'Your Shipping Address',
    BACK: 'Back'
  },
  MY_TRANSACTION_CANCELED: {
    NAVIGATION: 'Navigation',
    MY_ACCOUNT: 'My Account',
    MY_TRANSACTION: 'My Transaction',
    TRANSACTION: 'Transaction',
    ORDER_ID: 'Order ID',
    PURCHASED_DATE: 'Purchased Date/Time',
    RECEIPT_NO: 'Receipt No',
    STATUS: 'Status',
    YOUR_ORDER: 'Your Order',
    PRODUCT: 'Product',
    QUANTITY: 'Quantity',
    PRODUCT_PRICE: 'Product Price',
    TOTAL_PRICE: 'Total Price',
    YOUR_SHIPPING_ADDRESS: 'Your Shipping Address',
    BACK: 'Back',
    LKPP_TRANSACTION: 'LKPP Trancastion',
    SEARCH_TRANSACTION_CODE: 'Search Transaction Code',
    SELECT_TRANSACTION_DATE: 'Select Trancastion Date',
    ALL_PROGRESS: 'All Progress',
    PROGGRESS: 'Progress',
    NEGOTIATION_PROGGRESS: 'Negotiation Progress',
    NEGOTIATED: 'Negotiated',
    CANCELED: 'Canceled',
    ORDER_DATE: 'Order Date',
    TOTAL_PRODUCT: 'Total Product',
    TOTAL_AMOUNT: 'Total Ammount',
    ACTION: 'Action',
    SHIPPING_COST: 'Shipping Cost',
  },
  MY_TRANSACTION_APPROVE: {
    APPROVE_NEGOTIATION: 'Approve Negotiation',
    TYPE_TEXT: 'Type “SETUJU” then click Approve button, to approve negotiation',
    TYPE_TEXT_BUTTON: 'Type “SETUJU” to confirm',
    APPROVE: 'Approve'
  },
  MY_TRANSACTION_NEGOTIATION: {
    NAVIGATION: 'Navigation',
    MY_ACCOUNT: 'My Account',
    MY_TRANSACTION: 'My Transaction',
    LKPP_TRANSACTION: 'LKPP Trancastion',
    SEARCH_TRANSACTION_CODE: 'Search Transaction Code',
    SELECT_TRANSACTION_DATE: 'Select Trancastion Date',
    PROGGRESS: 'Progress',
    NEGOTIATION_PROGGRESS: 'Negotiation Progress',
    NEGOTIATED: 'Negotiated',
    STATUS: 'Status',
    ALL_STATUS: 'All Status',
    WAITING_FOR_PAYMENT: 'Waiting for Payment',
    ON_SHIPPING: 'On Shipping',
    COMPLATED: 'Completed',
    CANCELED: 'Canceled',
    ORDER_ID: 'Order ID',
    ORDER_DATE: 'Order Date',
    TOTAL_PRODUCT: 'Total Product',
    QUANTITY: 'Quantity',
    TOTAL_AMOUNT: 'Total Ammount',
    ACTION: 'Action',
    SHIPPING_FEE: 'Shipping Fee',
  },
  MY_TRANSACTION_NEGOTIATION_DETAIL: {
    NAVIGATION: 'Navigation',
    MY_ACCOUNT: 'My Account',
    MY_TRANSACTION: 'My Transaction',
    LKPP_TRANSACTION: 'LKPP Trancastion',
    TRANSACTION: 'Transaction',
    REV_ID: 'Rev ID',
    PURCHASED_DATE: 'Purchased Date/Time',
    WAITING_ADMIN_ADD: 'Waiting Admin Add Fee',
    DELIVERY_TIME: 'Delivery Time',
    YOUR_ORDER: 'Your Order',
    PRODUCT: 'Product',
    PRODUCT_NAME: 'Product Name',
    PRODUCT_PRICE: 'Product Price',
    PROGGRESS: 'Progress',
    NEGOTIATION_PROGGRESS: 'Negotiation Progress',
    NEGOTIATED: 'Negotiated',
    STATUS: 'Status',
    ALL_STATUS: 'All Status',
    WAITING_FOR_PAYMENT: 'Waiting for Payment',
    ON_SHIPPING: 'On Shipping',
    COMPLATED: 'Completed',
    CANCELED: 'Canceled',
    ORDER_ID: 'Order ID',
    ORDER_DATE: 'Order Date',
    TOTAL_PRODUCT: 'Total Product',
    QUANTITY: 'Quantity',
    TOTAL_AMOUNT: 'Total Ammount',
    ACTION: 'Action',
    SHIPPING_FEE: 'Shipping Fee',
    SHIPPING_COST: 'Shipping Cost',
    TOTAL_PAYMENT: 'Total Payment',
    BARGAIN_PRICE: 'Bargain Price',
    APPROVE: 'Approve',
    TOTAL_PRICE: 'Total Price',
    NOTE: 'Note',
    YOUR_SHIPPING_ADDRES: 'Your Shipping Address',
    PAYMENT_DETAILS: 'Payment Details',
    NEGOTIATION_FEE_HISTORY: 'Negotiation Fee History',
    TOTAL_PRODUCT_PRICE: 'Total Product Price',
    CANCEL_BERGAIN: 'Cancel Bargain',
    SUBMIT_BARGAIN_PRICE: 'Submit Bargain Price'
  },
  MY_TRANSACTION_NEGOTIATED: {
    NAVIGATION: 'Navigation',
    MY_ACCOUNT: 'My Account',
    MY_TRANSACTION: 'My Transaction',
    LKPP_TRANSACTION: 'LKPP Trancastion',
    SEARCH_TRANSACTION_CODE: 'Search Transaction Code',
    SELECT_TRANSACTION_DATE: 'Select Trancastion Date',
    ALL_PROGRESS: 'All Progress',
    TRANSACTION: 'Transaction',
    REV_ID: 'Rev ID',
    PURCHASED_DATE: 'Purchased Date/Time',
    WAITING_ADMIN_ADD: 'Waiting Admin Add Fee',
    DELIVERY_TIME: 'Delivery Time',
    YOUR_ORDER: 'Your Order',
    PRODUCT: 'Product',
    PRODUCT_NAME: 'Product Name',
    PRODUCT_PRICE: 'Product Price',
    PROGGRESS: 'Progress',
    NEGOTIATION_PROGGRESS: 'Negotiation Progress',
    NEGOTIATED: 'Negotiated',
    STATUS: 'Status',
    ALL_STATUS: 'All Status',
    WAITING_FOR_PAYMENT: 'Waiting for Payment',
    ON_SHIPPING: 'On Shipping',
    COMPLATED: 'Completed',
    CANCELED: 'Canceled',
    ORDER_ID: 'Order ID',
    ORDER_DATE: 'Order Date',
    TOTAL_PRODUCT: 'Total Product',
    QUANTITY: 'Quantity',
    TOTAL_AMOUNT: 'Total Ammount',
    ACTION: 'Action',
    SHIPPING_FEE: 'Shipping Fee',
    SHIPPING_COST: 'Shipping Cost',
    TOTAL_PAYMENT: 'Total Payment',
    BARGAIN_PRICE: 'Bargain Price',
    APPROVE: 'Approve',
    TOTAL_PRICE: 'Total Price',
    NOTE: 'Note',
    YOUR_SHIPPING_ADDRES: 'Your Shipping Address',
    PAYMENT_DETAILS: 'Payment Details',
    NEGOTIATION_FEE_HISTORY: 'Negotiation Fee History',
    TOTAL_PRODUCT_PRICE: 'Total Product Price',
    CANCEL_BERGAIN: 'Cancel Bargain',
    SUBMIT_BARGAIN_PRICE: 'Submit Bargain Price'
  },
  OFFICE_FITOUT: {
    HOME: 'Home',
    SHOP_BY_PRODUCT: 'Shop by Product',
    SHOP_BY_RANGE: 'Shop by Range',
    SALE: 'SALE',
    OFFICE_FITS_OUT: 'Office Fits Out',
    OUR_PROJECT: 'Our Project',
    CONTACT_US: 'Contact Us',
    MAKE_US_PROJECT: 'Make Us Your Project People',
    OUR_FRIEND: 'Our friendly team will get back to you.',
    SEND_INQUIRY: 'Send Inquiry',
    CHAT_WITH_US: 'Chat with Us',
  },
  PROJECT: {
    HOME: 'Home',
    SHOP_BY_PRODUCT: 'Shop by Product',
    SHOP_BY_RANGE: 'Shop by Range',
    SALE: 'SALE',
    OFFICE_FITS_OUT: 'Office Fits Out',
    OUR_PROJECT: 'Our Project',
    READ: 'Read More...',
    CONTACT_US: 'Contact Us',
    OUR_PROJECT: 'Our Project',
    CHAT_WITH_US: 'Chat with Us',
    CORPORATE_OFFICE_SPACE: 'Corporate Office Space'
  },
  ABOUT_US: {
    HOME: 'Home',
    SHOP_BY_PRODUCT: 'Shop by Product',
    SHOP_BY_RANGE: 'Shop by Range',
    SALE: 'SALE',
    OFFICE_FITS_OUT: 'Office Fits Out',
    CONTACT_US: 'Contact Us',
    OUR_PROJECT: 'Our Project',
    CHAT_WITH_US: 'Chat with Us',
    CORPORATE_OFFICE_SPACE: 'Corporate Office Space'
  },
  CONTACT_US: {
    HOME: 'Home',
    SHOP_BY_PRODUCT: 'Shop by Product',
    SHOP_BY_RANGE: 'Shop by Range',
    SALE: 'SALE',
    OFFICE_FITS_OUT: 'Office Fits Out',
    CONTACT_US: 'Contact Us',
    OUR_PROJECT: 'Our Project',
    CHAT_WITH_US: 'Chat with Us',
    HEAD_OFFICE: 'Head Office',
    EMAIL: 'Email',
    PHONE_NO: 'Phone No',
    ADDRESS: 'Address',
    SEND_US_MEDDAGE: 'Send Us A Message',
    SEND_MESSAGE: 'Send Message',
    SEE_WHY: 'See why our customer service really sets us apart. Our team are ready to help with any enquiry you may have!'
  },

  DAYS: {
    DAY1: 'Sunday',
    DAY2: 'Monday',
    DAY3: 'Tuesday',
    DAY4: 'Wednesday',
    DAY5: 'Thursday',
    DAY6: 'Friday',
    DAY7: 'Saturday'
  },
  MESSAGE_TITLE: {
    CONFIRMATION: 'Confirmation',
  },
  MESSAGE: {
    DELETE: 'Are you sure you want to Delete?',
    DELETE_FAILED: 'Failed to Delete',
    DELETE_SUCCESS: 'Success Delete',
    CANCEL: 'Are you sure you want to Cancel?',
    CANCEL_FAILED: 'Failed to Cancel',
    CANCEL_SUCCESS: 'Success to Cancel',
    LOGOUT: "Are you sure want to Logout?",
    TOKEN_FAILED: "Token is numeric combination with 8 characters.",
    NO_FILES: "",
    PRINT: 'Make sure your printer device is already connected as default print. This setting will be your setting for printing in this session.',
    PRINT_OPTION: 'PRINT OPTION',
    CREATE_LOCATION: 'Create New Location',
    ADD_LOCATION: 'Add Location',
    DETAIL_LOCATION: 'Detail Location',
    EDIT_LOCATION: 'Edit Location',
    NEW_SUPPLIER: 'New Supplier',
    ADD_SUPPLIER: 'Add Supplier',
    DETAIL_SUPPLIER: 'Detail Supplier',
    EDIT_SUPPLIER: 'Edit Supplier',
    ADD_PACKTYPE: 'Add Packing Type',
    DETAIL_PACKTYPE: 'Detail Packing Type',
    EDIT_PACKTYPE: 'Edit Packing Type',
    IMAGE_NOTFOUND: 'Sorry, image not found!',
    IMAGE_PREVIEW: 'Image Preview',
    ADD_AREAGROUP: 'Add Area Grouping',
    DETAIL_AREAGROUP: 'Detail Area Grouping',
    EDIT_AREAGROUP: 'Edit Area Grouping',
    NEW_PRODUCT: 'New Product',
    ADD_PRODUCT: 'Add Product',
    DETAIL_PRODUCT: 'Detail Product',
    EDIT_PRODUCT: 'Edit Product',
    CATEGORY_PRODUCT: 'Product Category',
    SELECT_PRODUCT: 'Select Product',
    SELECT_PRODUCT_TYPE: 'Select Product Type',
    ADD_PARTPOINT: 'Add Part Point',
    NEW_PARTPOINT: 'New Part Point',
    ADD_PALLET: 'Add Pallet Id',
    ADD_BUSSINESS: 'Add Bussiness Closure',
    NEW_BUSSINESS: 'New Bussiness Closure',
    WORKING_DAY: 'Working Day',
    ADD_EVENT: 'Add Event',
    NEW_EVENT: 'New Event',
    APPROVE_MSG: 'Are you sure want to approve this data?',
    REJECT_MSG: 'Are you sure want to reject this data?',
    APPROVE_CONF: 'Approval Confirmation',
    REJECT_CONF: 'Reject Confirmation'
  },
  COMMON_BUTTON: {
    BACK: "Back",
    SUBMIT: "Submit",
    OK: "OK",
    NO: "NO",
    YES: "YES",
    LOGOUT: 'Log Out',
    FILTER: 'FILTER',
    RESET: 'RESET',
    SAVE: 'SAVE',
    CLOSE: 'CLOSE',
    CREATE: 'Create',
    UPDATE: "Update",
    DELETE: 'DELETE',
    CONFIRM: 'Confirm',
    SEARCH: "Search",
    SETTING: 'Setting'
  },
  TABLE: {
    DATA_NOT_FOUND: 'No data has been found'
  },
  PAGETITLE: {
    DASHBOARD: {
      ROOT: 'Dashboard',
      TASK_ONGOING: {
        TRANSFER: 'Transfer Ongoing',
        PAYROLL: 'Payroll Ongoing',
        REVIEW: 'Review Ongoing',
        APPROVE: 'Approve Ongoing',
        BULK_TRANSFER: 'Bulk Transfer Ongoing',
      },
      TASK_PAST: {
        TRANSFER: 'Past Transfer',
        PAYROLL: 'Past Payroll',
        REVIEW: 'Past Review',
        APPROVE: 'Past Approve',
        BULK_TRANSFER: 'Past Bulk Transfer',
      }
    },
    ACCOUNT: {
      DETAIL: "Account Detail",
      HISTORY: {
        ROOT: 'HISTORY E-BANKING',
        TRANSFER: 'Transfer',
        PAYROLL: 'PAYROLL',
        PAYROLL_DETAIL: 'Detail Payroll History',
        PAYROLL_TARGET: 'Target Payroll',
      },
      STATEMENT: "Account Statement",
      SCHEDULED: {
        ROOT: "Scheduled Transaction",
        TRANSFER: "Scheduled Transfer",
        PAYROLL: "Scheduled Payroll",
        CANCEL: "Scheduled Cancel",
      },
    },
    TRANSFER: {
      ROOT: 'Transfer',
      BENEFICIARY: {
        ROOT: 'Beneficiary List',
        EDIT: "Edit Beneficiary",
        DETAIL: "Detail Beneficiary",
        ADD: "Add Beneficiary",
        CONFIRM: 'Beneficiary Confirmation',
        RESULT: 'Beneficiary Result',

      },
      MAKE_TRANSFER: {
        ADD: "Add Transfer",
        NOTE: "Note",
        CONFIRM: "Transfer Confirmation",
        RESULT: "Result Confirmation",
        DETAIL: 'Transfer Detail'
      },
    },
    BULK_TRANSFER: {
      ROOT: "Bulk Transfer",
      LIST: 'Bulk Transfer List',
      ADD: 'Add Bulk Transfer List',
      EDIT: 'Edit Bulk Transfer List',
      DETAIL: 'Detail Bulk Transfer',
      STATUS: 'Status Upload Bulk Transfer',
      STATUS_LIST: 'Status List',
      EMPLOYEE: 'Make Bulk Transfer Confirmation',
      EMPLOYEE_LIST: 'Beneficiary List',
      EMPLOYEE_DETAIL: 'Detail Beneficiary',
      EMPLOYEE_ADD: 'Add Beneficiary',
      EMPLOYEE_EDIT: 'Edit Beneficiary',
      AUTODEBIT: 'Auto Debit',
      AUTODEBIT_LIST: 'Autodebit List',
      AUTODEBIT_ADD: 'Add Auto Debit',
      AUTODEBIT_EDIT: 'Edit Auto Debit',
      AUTODEBIT_DETAIL: 'Detail Auto Debit',
      BULK: 'Bulk Add',
      BULK_NOTE: 'Note'
    },
    PAYROLL: {
      ROOT: "Payroll",
      LIST: 'Payroll List',
      ADD: 'Add Payroll List',
      EDIT: 'Edit Payroll List',
      DETAIL: 'Detail Payroll',
      STATUS: 'Status Upload Payroll',
      STATUS_LIST: 'Status List',
      EMPLOYEE: 'Payroll Disbursement Confirmation',
      EMPLOYEE_LIST: 'Employee List',
      EMPLOYEE_DETAIL: 'Detail Employee',
      EMPLOYEE_ADD: 'Add Employee',
      EMPLOYEE_EDIT: 'Edit Employee',
      AUTODEBIT: 'Auto Debit',
      AUTODEBIT_LIST: 'Autodebit List',
      AUTODEBIT_ADD: 'Add Auto Debit',
      AUTODEBIT_EDIT: 'Edit Auto Debit',
      AUTODEBIT_DETAIL: 'Detail Auto Debit',
      BULK: 'Bulk Add',
      BULK_NOTE: 'Note'
    },
    DISBURSEMENT: {
      ROOT: "Payroll Disbursement",
      CONFIRM: 'Payroll Disbursement Confirmation',
      RESULT: 'Payroll Disbursement Confirmation'
    },
    SETTING: {
      CHANGE_PASSWORD: "Change Password",
      BRANCH_LOCATION: "Branch LOcation",
      TERM_CONDITION: "Term & Condition",
      FAQ: "Frequently Ask and Question (FAQ)",
      CONTACT_US: "Contact Us",
    },
    KSEI: {
      RDN_BALANCE: "RDN Balance",
      RDN_STATEMENT: "RDN Statement",
    },
  },
  LOADING: {
    TITLE: "Collecting Resources.",
    WAIT: "Please wait."
  },
  VERIFY: {
    TITLE_SUCCESS: "Akun Anda telah Terverifikasi",
    BODY_SUCCESS: "Anda sudah bisa masuk dengan akun Anda",
    TITLE_FAILED: "Your account is not verified yet",
    BODY_FAILED: "Please contact Administrator",
    HOME: 'Go to Home'
  }
}

export default common