const payroll = {
  TITLE: 'LOGIN',
  DESC: 'Masuk dengan akun Anda yang terdaftar',
  HEADER: {
    HEADER_1: 'Status Transaksi Penggajian',
    HEADER_2: 'Daftar Gaji - Generate Checksum',
    HEADER_3: 'Penggajian - Unggah',
    HEADER_4: 'Penggajian - Konfirmasi Unggah',
    HEADER_5: 'Penggajian - Hasil Unggah',
    TARGET_LIST: 'Daftar Tujuan Penggajian Anda',
    INQUIRY_ADD_TARGET_LIST: 'Tambah Daftar Penggajian',
    INQUIRY_ADD_TARGET: 'Tambah Data Karyawan',
    INQUIRY_EDIT_TARGET: 'Ubah Data Karyawan',
    INQUIRY_ADD_BUNDLE: 'Tambah Data Paket',
    INQUIRY_EDIT_BUNDLE: 'Ubah Data Paket',
    INQUIRY_DETAIL_BUNDLE: 'Detail Data Paket',
    PAYROLL_DETAIL: 'Detail Daftar Penggajian',
    PAYROLL_TARGET: 'Tujuan Penggajian',
    EDIT_TARGET_LIST: 'Edit Daftar Tujuan Penggajian',
    NOTE: 'Catatan : ',
    TRANSFER_PAYROLL: 'Pembayaran gaji',
    TRANSFER_PAYROLL_CONFIRM: 'Konfirmasi Pembayaran gaji',
    TRANSFER_PAYROLL_RESULT: 'Hasil Pembayaran Penggajian',
    PAYROLL_HISTORY: 'Riwayat Penggajian',
    PAYROLL_SCHEDULE: 'Penggajian Terjadwal',
    CANCEL_SCHEDULE: 'Batalkan Penggajian Terjadwal',
    DELETE_GROUP: 'Hapus Daftar Penggajian',
    PROGRAM_DETAIL: 'Daftar Auto Debit',
  },
  LABEL: {
    EXPORT_FILE_STATUS_EMPLOYEE: 'Unduh daftar status',
    ACCOUNT_NUMBER: 'Nomor Rekening',
    ACTION_HISTORY: 'Riwayat Aksi',
    BUTTON_DOWNLOAD: 'Unduh Contoh File',
    DESCRIPTION: 'Deskripsi',
    EFFECTIVE_DATE: 'Tanggal Efektif',
    RECURRING_START_DATE: 'Tanggal Mulai',
    RECORD: 'Total Karyawan',
    RESULT_CODE: 'Result Code',
    TOTAL_AMOUNT: 'Total Nominal',
    STATUS: 'Status',
    AUTODEBET: 'Auto Debit',
    DOWNLOAD: 'Unduh',
    FILE_PAYROLL: 'File Daftar Penggajian',
    BUTTON_GENERATE: 'Generate',
    CHECKSUM: 'Checksum',
    COPY: 'Salin',
    BUTTON_SUBMIT: 'Kirim',
    SOURCE_FUND: 'Sumber Dana',
    RECURRING_NEXT_DATE: 'Tanggal Berulang Berikutnya',
    TOTAL_RECORD: 'Total Karyawan',
    REF_NUMBER: 'No. Ref',
    BUTTON_BACK: 'Kembali',
    PAYROLL_NAME: 'Nama Penggajian',
    CREATED_TIME: 'Tgl Pembuatan',
    PAYROLL_TYPE: 'Jenis Penggajian',
    MEMBERS: 'Anggota',
    ACTIONS: 'Aksi',
    BUTTON_ADD_TARGET_LIST: 'Tambahkan Daftar Penggajian',
    BUTTON_ADD_TRANSFER_LIST: 'Tambahkan Pembayaran gaji',
    BUTTON_ADD_TRANSFER: 'Tambahkan Pembayaran Penggajian',
    TARGET_LIST: 'Daftar Tujuan Penggajian',
    TARGET: 'Tujuan Penggajian',
    PAYROLL_DETAIL: 'Detail Penggajian',
    ACCOUNT_BANK: 'Akun Bank',
    ACCOUNT_NAME: 'Nama Akun',
    CUSTOMER_ID: 'ID Nasabah',
    UPLOAD_STATUS: 'Status Upload Terakhir',
    AMOUNT: 'Nominal',
    ADD_NEW_TARGET: 'Tambah Karyawan',
    EXPORT_FILE_EMPLOYEE: 'Download Data Karyawan',
    UPLOAD_FILE: 'Unggah Data Karyawan',
    BUTTON_BACK2: 'Kembali',
    PAYROLL_UPLOAD: 'Unggah Tujuan Penggajian.',
    EXAMPLE_FILE: 'Contoh File',
    LAST_MODIFIED: 'Waktu Update Terakhir',
    SUCCESS_PAYROLL: 'Penggajian Sukses',
    PAYROLL_DATE: 'Tanggal Penggajian',
    ADMIN_FEE: 'Biaya admin ke',
    TOTAL_ADMIN_FEE: "Total Biaya Admin",
    DATE_TIME: 'Tanggal / Waktu',
    MAKER: 'Maker',
    REVIEWER: 'Reviewer',
    APPROVER: 'Approver',
    TRANSFER_TYPE: 'Jenis Transfer',
    SCHEDULED_TYPE: 'Jenis Jadwal',
    NEAREST_SCHEDULED_DATE: 'Tanggal Transaksi Terdekat',
    BUNDLE_ACTIVE: 'Auto Debit Aktif',
    ADD_NEW_PROGRAM: 'Tambah Auto Debit',
  },
  PLACEHOLDER: {
    FILE_PAYROLL_1: 'Klik Atau Geser File Kesini Untuk Upload File Daftar Gaji',
    FILE_PAYROLL_2: 'Hanya menerima format file .xlsx',
    SOURCE_FUND: 'Pilih sumber dana anda',
    DESCRIPTION: 'Masukan Deskripsi',
    CHECKSUM: 'Masukan File Checksum',
    RECURRING_NEXT_DATE: 'Tanggal Berulang Berikutnya',
    TOTAL_RECORD: 'Masukan Total Karyawan',
    TOTAL_AMOUNT: 'Nominal harus lebih dari 0',
    PAYROLL_NAME: 'Masukan nama Penggajian',
  },
  INFO: {
    NO_DATA: `Tidak ada data untuk ditampilkan!`,
    NOTE1: 'Maksimal penggajian adalah 1000, jika sudah terdapat 3 data terdaftar, maka sisa yag dapat ditambahkan adalah 997.',
    NOTE2: 'Format dokumen yang digunakan adalah xlsx.',
    NOTE3: 'Penambahan massal akan mengganti daftar karyawan sebelumnya.',
    ADMIN_FEE: ' * Per Rekening',
  },
  MESSAGE: {
    DELETE: 'Apakah anda yakin akan menghapus'
  }
}

export default payroll