const payroll = {
  TITLE: 'LOGIN',
  DESC: 'Masuk dengan akun Anda yang terdaftar',
  HEADER: {
    BENEF_LIST: 'Daftar Penerima Transfer Anda',
    INQUIRY_ADD_BENEF: 'Tambahkan Daftar Penerima Transfer Baru',
    CONFIRM_ADD_BENEF: 'Konfirmasi Tambah Daftar Penerima Transfer',
    INQUIRY_EDIT_BENEF: 'Edit Daftar Penerima Transfer',
    CONFIRM_EDIT_BENEF: 'Konfirmasi Edit Daftar Penerima Transfer',
    INQUIRY_EDIT_SFT: 'Edit Transfer masa depan',
    CONFIRM_EDIT_SFT: 'Konfirmasi Edit Transfer masa depan',
    DELETE_BENEF: 'Hapus Daftar Penerima',
    CANCEL_TRANSFER: 'Batalkan Transfer',
    CANCEL_PAYROLL: 'Batalkan Penggajian',
    TRANSFER_HISTORY: 'Riwayat Transfer',
    LAST_TRANSFER: 'Transfer Terakhir',
    TRANSFER_CONFIRMATION: 'Konfirmasi Transfer',
    TRANSFER_RESULT: 'Hasil Transfer',
    SCHEDULED_LIST: "Daftar Transfer Dana Terjadwal",
    CONFIRM_DELETE_BENEF: 'Konfirmasi Menghapus Daftar Penerima Transfer',
  },
  LABEL: {
    DESCRIPTION: 'Deskripsi',
    ADMIN_FEE: 'Biaya Admin',
    ADMIN_FEE_AMOUNT: 'Nominal Biaya',
    BUTTON_ADD_BENEF: 'Tambahkan Daftar Penerima',
    ACCOUNT_NAME: 'Nama Rekening',
    ACCOUNT_NUMBER: 'Nomor Rekening',
    TRANSFER_TYPE: 'Metode Transfer',
    TRANSFER_TIME: 'Waktu Transfer',
    BANK: 'Bank',
    RECURRING_START_DATE: 'Tanggal Mulai',
    ALIAS: 'Alias',
    ACTION: 'Aksi',
    EDIT: 'Edit',
    DELETE: 'Hapus',
    BUTTON_SUBMIT: 'Kirim',
    BENEF_BANK_CODE: 'Kode Bank',
    DATE_CREATE: "Tanggal Pembuatan",
    BENEF_BANK_ACC: 'Rekening Bank Penerima',
    BENEF_ACC_NUM: 'Nomor Rekening Penerima',
    BENEF_ACC_NAME: 'Nama Akun Penerima',
    BENEF_CITY: 'Kota Penerima',
    SELECT_BANK_BRANCH: 'Kantor Cabang Bank',
    BENEF_PROVINCE: 'Provinsi Penerima',
    BENEF_ID_NUM: 'Nomor ID Penerima',
    CUSTOMER_TYPE: 'Tipe Nasabah',
    CITIZENSHIP: 'Kewarganegaraan',
    ALIAS_NAME: 'Nama Alias',
    REF_NUMBER: 'No. Ref',
    TOKEN: 'Token',
    AUTODEBET: 'Auto Debit',
    BENEF_DATA: 'Data Penerima',
    NO: 'Tidak',
    YES: 'Ya',
    BACK: 'Kembali',
    SOURCE_FUND: 'Sumber Dana',
    TRANSFER_BENEFICIARY: 'Penerima Transfer',
    TRANSFER_SERVICE: 'Layanan Transfer',
    TRANSFER_DESC: "Deskripsi",
    TRANSFER_PAYROLL_LIST: "Daftar Penggajian",
    TRANSFER_FEE_TO: "Biaya pada",
    AMOUNT: 'Nominal',
    DEBIT_AMOUNT: 'Jumlah Debit',
    NEWS: 'Berita Transfer',
    BENEFICIARY_EMAIL: 'Email Penerima',
    RECURRING_TYPE: 'Jenis Berulang',
    RECURRING_INTERVAL: 'Jangka Berulang',
    RECURRING_NEXT_DATE: 'Tanggal Berulang Berikutnya',
    RECURRING_END_DATE: 'Tanggal Berakhir',
    RECURRING_DATE: 'Waktu Berulang',
    TRANSFER_DATE: 'Tanggal Transfer',
    SOURCE_ACCOUNT: 'Sumber Akun',
    TO: 'Tujuan',
    CHARGE: 'Biaya Admin',
    TRANSFER_FREQUENCY: 'Frekuensi transfer',
    TRANSFER_STATUS: 'Status Transfer',
    BUTTON_BACK: 'Kembali ke Transfer',
    START_DATE: 'Tanggal Dimulai',
    END_DATE: 'Tanggal Berakhir',
    BUTTON_SEARCH: 'Cari',
    STATUS: 'Status',
    EMPLOYEE_ID_NUMBER: `NIP`,
    EMPLOYEE_SALARY_AMOUNT: `Nominal Gaji`,
    EMPLOYEE_ACC_NUMBER: 'No Rekening',
    EMPLOYEE_ACC_BANK: 'Bank',
    EMPLOYEE_EMAIL_ADDRESS: `Email`,
    EMPLOYEE_BUNDLE_STATUS: 'Status',
    EMPLOYEE_BUNDLE_END: 'Tanggal Berakhir',
    EMPLOYEE_BUNDLE_START: 'Tanggal Mulai',
    EMPLOYEE_BUNDLE_AMOUNT: 'Nominal',
    EMPLOYEE_BUNDLE_AMOUNT_PRIMARY: 'Nominal Utama',
    EMPLOYEE_BUNDLE_AMOUNT_SECONDARY: 'Nominal Tambahan',
    EMPLOYEE_BUNDLE_ACC_DEST: 'Tujuan Rekening',
    EMPLOYEE_BUNDLE_DESTINATION: 'Akun Tujuan Pribadi',
    EMPLOYEE_BUNDLE_TYPE: 'Jenis Auto Debit',
  },
  PLACEHOLDER: {
    TRANSFER_TYPE: 'Jenis Transfer',
    TRANSFER_TIME: 'Pilih waktu transfer',
    BENEF_BANK_ACC: 'Pilih Bank Penerima',
    BENEF_ACC_NUM: 'Masukkan nomor akun penerima',
    BENEF_ACC_NAME: 'Masukkan nama penerima',
    BENEF_CITY: 'Pilih Kota',
    SELECT_BANK_BRANCH: 'Pilih Kantor Cabang Bank',
    BENEF_PROVINCE: 'Pilih Provinsi',
    BENEF_ID_NUM: 'Masukan Nomor ID Penerima',
    CUSTOMER_TYPE: 'Pilih Tipe Nasabah',
    CITIZENSHIP: 'cth: Indonesia',
    ALIAS_NAME: 'Masukan Nama Alias',
    TOKEN: 'Masukan Token',
    SOURCE_FUND: 'Pilih sumber dana anda',
    TRANSFER_BENEFICIARY: 'Pilih penerima transfer',
    TRANSFER_PAYROLL_LIST: 'Pilih daftar penggajian',
    TRANSFER_SERVICE: 'Pilih layanan transfer',
    TRANSFER_DESC: "Masukkan deskripsi",
    AMOUNT: 'Jumlah harus lebih dari 0',
    NEWS: 'Berita Transfer (Tidak wajib)',
    BENEFICIARY_EMAIL: 'Email Penerima (Tidak wajib)',
    RECURRING_TYPE: 'Pilih Jenis Berulang',
    RECURRING_INTERVAL: 'Pilih Jangkia Berulang',
    RECURRING_NEXT_DATE: 'Tanggal Berulang Berikutnya',
    RECURRING_END_DATE: 'Tanggal Berakhir',
    TRANSFER_DATE: 'Tanggal Transfer',
    EMPLOYEE_ID_NUMBER: `Masukkan NIK`,
    EMPLOYEE_SALARY_AMOUNT: `Jumlah harus lebih dari 0`,
    EMPLOYEE_EMAIL_ADDRESS: `Masukkan alamat email`,
  },
  INFO: {
    NO_LAST_TRANSFER_DATA: 'Belum ada data transfer terakhir..',
    NO_DATA: 'Tidak ada data untuk ditampilkan!',
  },
}

export default payroll