import dateformat from 'dateformat';
export default function getDayPickerNew(date) {
    if(date) {
        const convertdate = new Date(date)
        const app_date = dateformat(convertdate, "yyyy-mm-dd")
    
        return app_date
    }
    // console.log("date", date)
    // const dateformat = require('dateformat')
    // const year = date.substr(6, 4);
    // const month = date.substr(3, 2);
    // const day = date.substr(0, 2);
    // const year = date?.substr(0, 4);
    // const month = date?.substr(4, 2);
    // const day = date?.substr(6, 2);
    // console.log(year, month, day)
    // const convertdate = new Date(date)
}