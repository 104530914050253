import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Grid,
  TextField,
  Typography,
  ButtonGroup,
  Button,
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  IconButton
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import noImage from "assets/images/noImage.jpeg"
import { rupiahFormat } from 'helpers'
import DeleteIcon from '@mui/icons-material/Delete';
import NumberFormat from 'react-number-format';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getAccessLog } from 'helpers';


import moment from 'moment'
import { useCart } from 'hooks'
import { useSelector } from 'react-redux';
import index from 'pages/General/CommonDashboard';
import { useLanguage } from 'hooks'


function Index(props) {
  const {
    setisCheckedout,
    handleSubmit,
    data,
    setafterUpdate,
    afterUpdate,
    dataProfile
  } = props
  const classes = useStyles()
  const [language, lagDic, setLang] = useLanguage()
  const [openValidate, setOpenValidate] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getDetail] = useCart(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [list, setList] = useState([])
  console.log("🚀 ~ file: index.jsx ~ line 203 ~ Index ~ list", list)
  const [valueNote, setValueNote] = useState({});
  console.log("🚀 ~ file: index.jsx ~ line 57 ~ Index ~ valueNote", valueNote)
  const [subTotal, setSubTotal] = useState({});
  useEffect(() => {
    data.map((item) => {
      setSubTotal((prevState) => ({
        ...prevState,
        [item?.ITEM_NO]: item?.ITEM_PRICE * item?.ITEM_TOTAL
      }));
    })
  },[data])
  useEffect(() => {
    data.map((item) => {
      setValueNote((prevState) => ({
        ...prevState,
        [item?.ITEM_NO]: item?.ADDITIONAL_INFO ?? ''
      }));
    })
  },[data])
  useEffect(() => {
    data.map((item) => {
      setcounter((prevState) => ({
        ...prevState,
        [item?.ITEM_NO]: item?.ITEM_TOTAL
      }));
    })
    if (data?.length > 0) {
      let result = []
      if (list?.length > 0) {
        list?.map((val) => {
          const index = data.findIndex(object => {
            return object.ITEM_NO === val?.ITEM_NO;
          });
          const insert = (arr, index, newItem) => [
            // part of the array before the specified index
            ...arr.slice(0, index),
            // inserted item
            newItem,
            // part of the array after the specified index
            ...arr.slice(index)
          ]
          let arr = data?.filter(function (el) {
            return el?.ITEM_NO !== val?.ITEM_NO;
          });
          result = insert(arr, index, val)
        })
        setList(data.sort((a, b) => a.PRODUCT_INFO?.[0]?.ITEM_NAME.localeCompare(b.PRODUCT_INFO?.[0]?.ITEM_NAME)))
        setCartTotal(data?.sumQty('ITEM_TOTAL') ?? 0)
        setCartTotalPrice(data?.sum('ITEM_PRICE', 'ITEM_TOTAL') ?? 0)
      } else {
        setList(data)
        setCartTotal(data?.sumQty('ITEM_TOTAL') ?? 0)
        setCartTotalPrice(data?.sum('ITEM_PRICE', 'ITEM_TOTAL') ?? 0)
      }
    } else {
      if (list?.length === 1) {
        let result = []
        list?.map((val) => {
          const index = data.findIndex(object => {
            return object.ITEM_NO === val?.ITEM_NO;
          });
          const insert = (arr, index, newItem) => [
            // part of the array before the specified index
            ...arr.slice(0, index),
            // inserted item
            newItem,
            // part of the array after the specified index
            ...arr.slice(index)
          ]
          let arr = data?.filter(function (el) {
            return el?.ITEM_NO !== val?.ITEM_NO;
          });
          result = insert(arr, index, val)
        })
        setList(result)
        setCartTotal(data?.sumQty('ITEM_TOTAL') ?? 0)
        setCartTotalPrice(data?.sum('ITEM_PRICE', 'ITEM_TOTAL') ?? 0)
      }
    }
  }, [data])
  const cartList = useSelector(state => state.cart);
  console.log("🚀 ~ file: index.jsx ~ line 100 ~ Index ~ data", data, cartList?.items?.data)
  const [cartTotalQty, setCartTotal] = useState(0)
  const [cartTotalPrice, setCartTotalPrice] = useState(0)

  // const cartTotalPrice = datatables.length > 0 ? datatables?.sum('ITEM_PRICE', 'ITEM_TOTAL') : 0
  // const cartTotalQty = datatables.length > 0 ? datatables?.sumQty('ITEM_TOTAL') : 0

  Array.prototype.sum = function (prop, ast) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += (this[i][prop] * this[i][ast])
    }
    return total
  }
  Array.prototype.sumQty = function (prop) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop]
    }
    return total
  }

  const handleClose = () => {
    setOpenValidate(false)
  }

  const handleDelete = (id) => {
    const param = {
      ID: id,
      ACTION_BY: getAccessLog('username')
    }
    getDetail(param, 'delete')
    if (list?.length === 1) {
      setList([])
      setCartTotal(0)
      setCartTotalPrice(0)
    }
    setafterUpdate(!afterUpdate)
  }

  const today = moment().format('YYYY-MM-DD HH:mm:ss.ms');

  const [counter, setcounter] = useState({})
  console.log("🚀 ~ file: index.jsx ~ line 76 ~ Index ~ counter", counter)

  const handleIncrement = (event, row) => {
    if (counter[event.target.name] < 999) {
      if (!counter[event.target.name]) {
        setcounter({
          ...counter,
          [event.target.name]: row?.ITEM_TOTAL + 1
        })
        const param = {
          ...row
        }
        param['ITEM_TOTAL'] = row?.ITEM_TOTAL + 1
        param['ADDITIONAL_INFO'] = valueNote[`${param['ITEM_NO']}`]
        param['ACTION_DATE'] = today
        param['ACTION_BY'] = getAccessLog('username')
        console.log("🚀 ~ file: index.jsx ~ line 85 ~ handleIncrement ~ param", param)
        getDetail(param, 'update')
        setafterUpdate(!afterUpdate)
      }
      if (counter[event.target.name] >= 1) {
        setcounter({
          ...counter,
          [event.target.name]: counter[event.target.name] + 1
        });
        const param = {
          ...row
        }
        param['ITEM_TOTAL'] = counter[event.target.name] + 1
        param['ADDITIONAL_INFO'] = valueNote[`${param['ITEM_NO']}`]
        param['ACTION_DATE'] = today
        param['ACTION_BY'] = getAccessLog('username')
        console.log("🚀 ~ file: index.jsx ~ line 85 ~ handleIncrement ~ param", param)
        getDetail(param, 'update')
        setafterUpdate(!afterUpdate)
      }
    }
  };

  const handleDecrement = (event, row) => {
    if (!counter.hasOwnProperty(event.target.name)) {
      console.log('kalogaada')
      setcounter({
        ...counter,
        [event.target.name]: row?.ITEM_TOTAL - 1
      })
      const param = {
        ...row
      }
      param['ITEM_TOTAL'] = row?.ITEM_TOTAL - 1
      param['ADDITIONAL_INFO'] = valueNote[`${param['ITEM_NO']}`]
      param['ACTION_DATE'] = today
      param['ACTION_BY'] = getAccessLog('username')
      console.log("🚀 ~ file: index.jsx ~ line 85 ~ handleIncrement ~ param", param)
      getDetail(param, 'update')
      setafterUpdate(!afterUpdate)
    }
    else if (counter[event.target.name] != 0) {
      console.log('kalo bukan 0')
      setcounter({
        ...counter,
        [event.target.name]: counter[event.target.name] - 1
      });
      const param = {
        ...row
      }
      param['ITEM_TOTAL'] = counter[event.target.name] - 1
      param['ADDITIONAL_INFO'] = valueNote[`${param['ITEM_NO']}`]
      param['ACTION_DATE'] = today
      param['ACTION_BY'] = getAccessLog('username')
      getDetail(param, 'update')
      setafterUpdate(!afterUpdate)
    }
  };

  const handleNote = (event, row) => {
    if (datatables.length > 0){
      const param = {
        ...row
      }
      param['ITEM_TOTAL'] = counter[`${param['ITEM_NO']}`]
      param['ADDITIONAL_INFO'] = event.target.value
      param['ACTION_DATE'] = today
      param['ACTION_BY'] = getAccessLog('username')
      console.log("🚀 ~ file: index.jsx ~ line 143 ~ handleNote ~ param", param.ADDITIONAL_INFO)
      getDetail(param, 'update')
      setafterUpdate(!afterUpdate)
    }
  }
  const handleInputQty = (value, row) => {
    if (value !== "") {
      if (value > 999) {
        value = 999
      }
      else {
        setcounter((prevState) => ({
          ...prevState,
          [row?.ITEM_NO]: parseInt(value)
        }));
      }
      const param = {
        ...row
      }
      param['ITEM_TOTAL'] = parseInt(value)
      param['ADDITIONAL_INFO'] = valueNote[`${param['ITEM_NO']}`]
      param['ACTION_DATE'] = today
      param['ACTION_BY'] = getAccessLog('username')
      getDetail(param, 'update')
      setafterUpdate(!afterUpdate)
    }
  }

  const handleConfirmOrder = () => {
    const totalPrice = parseInt(cartTotalPrice)
    if (totalPrice >= 200000000 && getAccessLog('role') === 'PP') {
      setOpenValidate(true)
    } else {
      handleSubmit('shipping')
    }
  }



  const baseUrlImage = window._env_?.BASEURL_IMAGE

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openValidate}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign="center" >
          <strong>
            {language.COMMON.CART.DIALOG_TITLE_CONFIRM}
          </strong>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">
            {language.COMMON.CART.DIALOG_BODY_CONFIRM}
          </Typography>
          <Button sx={{ marginTop: 2 }} fullWidth size='small' color='info' variant='contained' onClick={handleClose} autoFocus>
            Back To Cart
          </Button>
        </DialogContent>
      </Dialog>
      {/* Content */}
      <Grid
        container spacing={2} >
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <TableContainer component={Grid}>
            <Table size='small' sx={{ width: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="center"><strong>{language.COMMON.CART.PRODUCT}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.PRICE}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.QUANTITY}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.SUBTOTAL}</strong></TableCell>
                  <TableCell sx={{ width: '5%' }} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row, index) => (
                  <TableRow
                    className={classes.table}
                    key={row?.ITEM_NO}
                  >
                    <TableCell sx={{ width: '40%' }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div>
                          <img
                            alt=''
                            className={classes.otherImageContent}
                            onError={event => {
                              event.target.src = noImage
                              event.onerror = null
                            }}
                            src={`${baseUrlImage}`} />
                        </div>
                        <div>
                          <Typography>
                            {row?.PRODUCT_INFO?.[0]?.ITEM_NAME}
                          </Typography>
                        </div>
                      </div>

                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingLeft: '60px' }}>
                          <Typography variant="body2" color="secondary">Write a note</Typography>
                        </div>
                      </div>
                      <div style={{ paddingLeft: '60px' }}>
                        <TextField
                          fullWidth
                          onBlur={(event) => { handleNote(event, row) }}
                          defaultValue={row?.ADDITIONAL_INFO ?? ''}
                          value={valueNote[row?.ITEM_NO]}
                          onChange={(event) => { setValueNote({ [row?.ITEM_NO]: event.target.value }) }}
                          name={`note${index + 1}`}
                          size='small'
                          sx={{ my: 1 }}
                          id="outlined-textarea"
                          placeholder='Insert description'
                          multiline
                        />
                      </div>
                    </TableCell>
                    <TableCell variant='body' sx={{ alignItems: "flex-start", verticalAlign: 'top' }} align="center">{rupiahFormat(`${row?.ITEM_PRICE ?? 0}`, 'Rp')}</TableCell>
                    <TableCell variant='body' sx={{ alignItems: "flex-start", verticalAlign: 'top' }} align="center">
                      <ButtonGroup style={{ color: '#1070ca', border: 'solid 1px #aeb7cb' }} size="small" aria-label="small outlined button group">
                        <Button style={{ color: '#1070ca', border: '0px' }} name={row?.ITEM_NO} onClick={(event) => { handleDecrement(event, row) }}>-</Button>
                        <NumberFormat
                          style={{ width: 40, fontSize: 11, border: '0px' }}
                          value={counter[row?.ITEM_NO]}

                          // onValueChange={(values) => {
                          //   const { formattedValue, value } = values;
                          //   handleInputQty(value, row)
                          // }}
                          onChange={(event) => {
                            console.log("🚀 ~ file: index.jsx ~ line 310 ~ Index ~ value", event.target.value)
                            handleInputQty(event.target.value, row)
                          }}
                          onFocus={event => {
                            event.target.select();
                          }}
                          customInput={TextField}
                          inputProps={{ min: 0, style: { textAlign: 'center' } }}
                          InputProps={{ disableUnderline: true }}
                          margin='dense'
                          size='small'
                          variant='standard'
                          displayType="input"
                          type="text"

                          allowNegative={false} />
                        {/* <Button style={{ width: '50px', color: 'black', border: 'solid 1px #aeb7cb' }} disabled>{counter[row?.ITEM_NO] ?? row?.ITEM_TOTAL}</Button> */}
                        <Button style={{ color: '#1070ca', border: '0px' }} name={row?.ITEM_NO} onClick={(event) => { handleIncrement(event, row) }} >+</Button>
                      </ButtonGroup>
                    </TableCell>
                    <TableCell variant='body' sx={{ alignItems: "flex-start", verticalAlign: 'top' }} align="center">{rupiahFormat(`${row?.ITEM_PRICE && row?.ITEM_TOTAL ? subTotal[row?.ITEM_NO] : 0}`, 'Rp')}</TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => { handleDelete(row?.ID) }} aria-label="delete">
                        <DeleteIcon fontSize='small' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* } */}
        </Grid>
        <Grid item xl lg md sm xs>
          <Box
            sx={{
              py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
            }}
          >
            <Typography variant="body1" color="initial">{language.COMMON.CART.BUYER_INFORMATION}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
              <div>
                <Typography variant="body2" color="secondary">{language.COMMON.CART.BUYER_NAME}</Typography>
                <Typography variant="body2" color="secondary">{language.COMMON.CART.ROLE}</Typography>
              </div>
              <div>
                <Typography variant="body2" align='right' color="secondary">{getAccessLog('username')}</Typography>
                <Typography variant="body2" align='right' color="secondary">{getAccessLog('role')}</Typography>
              </div>
            </div>
          </Box>
          <Box
            sx={{
              py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
            }}
          >
            <Typography variant="body1" color="initial">{language.COMMON.CART.PRODUCT}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
              <div>
                <Typography variant="body2" color="secondary">{language.COMMON.CART.PRODUCT_QUANTITY}</Typography>
              </div>
              <div>
                <Typography variant="body2" color="secondary">{cartTotalQty}</Typography>
              </div>
            </div>
            <Divider sx={{ paddingTop: 1, marginBottom: 1 }} />
            {/* <Typography variant="body1" color="initial">{language.COMMON.CART.BUYER_INFORMATION}</Typography> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
              <div>
                <Typography variant="body1" color="secondary">{language.COMMON.CART.CART_TOTAL}</Typography>
              </div>
              <div>
                <Typography variant="body1" color="secondary"> <strong> {rupiahFormat(`${cartTotalPrice}`, 'Rp')} </strong></Typography>
              </div>
            </div>
            <Divider sx={{ paddingTop: 1, marginBottom: 1 }} />
            <Button
              disabled={list?.length === 0}
              sx={{ marginTop: 2, marginBottom: 2 }} onClick={() => { handleConfirmOrder() }} size='small' color='info' variant="contained" fullWidth >
              Confirm Order
            </Button>

          </Box>
        </Grid>
      </Grid>

    </React.Fragment>
  )
}

export default Index
