import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { profileActions } from '../../controllers/_actions'

export default function useProfile(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const profile = useSelector(state => state.profile);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(profileActions.getMyAccount(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'create') {
      dispatch(profileActions.createData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'myTransaction') {
      dispatch(profileActions.getMyTransaction(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'changePass') {
      dispatch(profileActions.changePass(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'instance') {
      dispatch(profileActions.getInstance(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } if (type === 'update') {
      dispatch(profileActions.updateData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);


  useEffect(() => {
    const DataLoaded = profile?.items?.data || []
    setData(DataLoaded)
  }, [profile])

  return [data, initFetch, crudAction]
}
