import { getFormattedDay, rupiahFormat } from 'helpers';

const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export default function structuredefault(values, handleFieldChange,open,categoryOption, language) {
  const data = [
    {
      id: 'PRODUCT_SHOWCASE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.PRODUCT_SHOWCASE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Product Showcase',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PRODUCT_SHOWCASE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PACKAGE_ID',
      label: language.COMMON.MY_TRANSACTION_DETAIL.PACKAGE_ID,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Package ID',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PACKAGE_ID"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'RUP_ID',
      label: language.COMMON.MY_TRANSACTION_DETAIL.RUP_ID,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'RUP ID',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["RUP_ID"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PACKAGE_NAME',
      label: language.COMMON.MY_TRANSACTION_DETAIL.PACKAGE_NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Package Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PACKAGE_NAME"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'INSTANCE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.INSTANCE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Instance',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["INSTANCE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'WORK_UNIT',
      label: language.COMMON.MY_TRANSACTION_DETAIL.WORK_UNIT,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'work Unit',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["WORK_UNIT"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'WORK_UNIT_NPWP',
      label: language.COMMON.MY_TRANSACTION_DETAIL.WORK_UNIT_NPWP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Work Unit NPWP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["WORK_UNIT_NPWP"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'WORK_UNIT_ADDRESS',
      label: language.COMMON.MY_TRANSACTION_DETAIL.WORK_UNIT_ADDRESS,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Work Unit Address',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["WORK_UNIT_ADDRESS"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'CREATED_DATE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.CREATED_DATE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Created Date',
      onChange: (field, value) => handleFieldChange(field, value),
      value: `${getFormattedDay(values["CREATED_DATE"], 'normal')}` || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'MODIFIED_DATE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.MODIFIED_DATE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Modified Date',
      onChange: (field, value) => handleFieldChange(field, value),
      value: `${getFormattedDay(values["MODIFIED_DATE"], 'normal')}` || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'BUDGET_YEAR',
      label: language.COMMON.MY_TRANSACTION_DETAIL.BUDGET_YEAR,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Budget Year',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["BUDGET_YEAR"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'ITEM_TOTAL',
      label: language.COMMON.MY_TRANSACTION_DETAIL.TOTAL_PRODUCT,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Total Product',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["ITEM_TOTAL"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PRICE_FULL',
      label: language.COMMON.MY_TRANSACTION_DETAIL.TOTAL_PRICE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Total Price',
      onChange: (field, value) => handleFieldChange(field, value),
      value: rupiahFormat(`${values["PRICE_FULL"]}`, 'Rp') || '',
      minWidth: 170,
      // hide: isHide
    },
  ]

  return data
};