const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]

export function structuredefault(values, handleFieldChange, lang) {
  const data = [
    {
      id: 'CUSTOMER_NAME',
      label: lang.COMMON.MY_ACCOUNT.YOUR_NAME,
      type: 'text',
      fieldtype: 'textfield',
      required: true,
      placeholder: lang.COMMON.MY_ACCOUNT.YOUR_NAME,
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["CUSTOMER_NAME"] || '',
      minWidth: 170,
      // hide: isHide
    },
    // {
    //   id: 'PROVINCE',
    //   label: lang.COMMON.MY_ACCOUNT.PROVINCE,
    //   type: 'select',
    //   fieldtype: 'suggestfield',
    //   onChange: (field, value) => handleFieldChange(field, value),
    //   value: values["PROVINCE"] || '',
    //   minWidth: 170,
    //   hide: false,
    //   option: rows?.length === 0 ? [] : rows?.map(data => {
    //     return {
    //       id: data.INSTANCE_CODE,
    //       value: data.INSTANCE_CODE,
    //       label: data.INSTANCE_NAME,
    //       data: data
    //     }
    //   }),
    //   placeholder: lang.COMMON.MY_ACCOUNT.PROVINCE,
    // },
    {
      id: 'EMAIL',
      label: lang.COMMON.MY_ACCOUNT.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      required: true,
      placeholder: lang.COMMON.MY_ACCOUNT.EMAIL,
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["EMAIL"] || '',
      minWidth: 170,
      // hide: isHide
    },
    // {
    //   id: 'CITY',
    //   label: lang.COMMON.MY_ACCOUNT.CITY,
    //   type: 'select',
    //   fieldtype: 'suggestfield',
    //   onChange: (field, value) => handleFieldChange(field, value),
    //   value: values["CITY"] || '',
    //   minWidth: 170,
    //   hide: false,
    //   option: rows?.length === 0 ? [] : rows?.map(data => {
    //     return {
    //       id: data.INSTANCE_CODE,
    //       value: data.INSTANCE_CODE,
    //       label: data.INSTANCE_NAME,
    //       data: data
    //     }
    //   }),
    //   placeholder: lang.COMMON.MY_ACCOUNT.CITY,
    // },
    {
      id: 'CP_CONTACT',
      label: lang.COMMON.MY_ACCOUNT.PHONE_NO,
      type: 'text',
      fieldtype: 'numberfield',
      required: true,
      placeholder: lang.COMMON.MY_ACCOUNT.PHONE_NO,
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["CP_CONTACT"] || '',
      minWidth: 170,
      // hide: isHide
    },
    // {
    //   id: 'POSTAL_CODE',
    //   label: lang.COMMON.MY_ACCOUNT.POSTAL_CODE,
    //   type: 'number',
    //   fieldtype: 'textfield',
    //   placeholder: lang.COMMON.MY_ACCOUNT.POSTAL_CODE,
    //   onChange: (field, value) => handleFieldChange(field, value),
    //   value: values["POSTAL_CODE"] || '',
    //   minWidth: 170,
    //   // hide: isHide
    // },
    {
      id: 'CONF_LANGUAGE',
      label: lang.COMMON.MY_ACCOUNT.PREFERRED_LANGUAGE,
      type: 'select',
      fieldtype: 'select',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["CONF_LANGUAGE"] || '',
      minWidth: 170,
      hide: false,
      option: [
        {
        id: 1,
        value: "id",
        label: "Bahasa Indonesia",
        },
        {
        id: 2,
        value: "en",
        label: "English",
        },
      ]
      //  rows?.length === 0 ? [] : rows?.map(data => {
      //   return {
      //     // id: data.INSTANCE_CODE,
      //     // value: data.INSTANCE_CODE,
      //     // label: data.INSTANCE_NAME,
      //     // data: data
      //   }
      // })
      ,
      placeholder: lang.COMMON.MY_ACCOUNT.PREFERRED_LANGUAGE,
    },
    // {
    //   id: 'FULL_ADDRESS',
    //   label: lang.COMMON.MY_ACCOUNT.FULL_ADDRESS,
    //   type: 'TEXT',
    //   fieldtype: 'textArea',
    //   placeholder: lang.COMMON.MY_ACCOUNT.FULL_ADDRESS,
    //   onChange: (field, value) => handleFieldChange(field, value),
    //   value: values["FULL_ADDRESS"] || '',
    //   minWidth: 170,
    //   // hide: isHide
    // },

  ]

  return data
};