import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Box,
  ToggleButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper, IconButton,
} from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PrintIcon from '@mui/icons-material/Print';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TableCustom from './components/TableComponent';
import {
  LocalizationProvider,
  DatePicker
} from '@mui/lab';
import useStyles from './styles'
import { useLkppOrder, useSelected, useProfile } from "hooks";
import { useLocation, useNavigate } from "react-router";
import queryString from 'query-string'
import getDayPicker from "helpers/getDayPickerNew";
import { getAccessLog } from "helpers";
import { CustomBackdrop } from "components";
import { useLanguage} from 'hooks'


export default function BasicTabs(props) {
  const {
    date,
    setdate,
    toggleBtn,
    settoggleBtn,
    dummy,
    handlePageType
  } = props
  const navigate = useNavigate()
  const [language, lagDic, setLang] = useLanguage()
  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  const classes = useStyles()
  const [selected, setSelected] = useSelected()
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datas, setDatas] = useLkppOrder(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage);
  const [datatables2, setDatatables2, getData] = useProfile(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  const [step, setStep] = React.useState('transactionList')
  const [state, setState] = useState({ text: '', date: '' })
  const [toggle, setToggle] = useState({})
  
  console.log("🚀 ~ file: index.jsx ~ line 57 ~ BasicTabs ~ toggle", toggle)
  useEffect(() => {
    const param = {
      CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
      // CUSTOMER_NAME: "Maudy A",
      // CUSTOMER_NO: "00000009",
      USERNAME: getAccessLog('username'),
      page: 1,
      limit: 100
    }
    setDatatables2(param)
  }, [])
  useEffect(() => {
    // if(datatables2?.[0]?.CUSTOMER_NO) {
      if (step === 'transactionList') {
        let status = ''
        if (toggle?.all) status = undefined
        if (toggle?.waiting_admin_delivery) status = '1:Admin Initiate'
        if (toggle?.waiting_customer_approve) status = '2:Customer Approval'
        if (toggle?.waiting_admin_approve) status = '3:Admin Approval'
        if (toggle?.deal) status = '4:Deal'
        if (toggle?.cancel) status = '5:Cancelled'
  
        const param = {
          "INVOICE_NO": state?.text?.length > 0 ? state?.text : undefined,
          // "CUSTOMER_NAME": getAccessLog('username'),
          // "CUSTOMER_NO": datatables2?.[0]?.CUSTOMER_NO,
          "USERNAME": getAccessLog('username'),
          "PURCHASE_DATE": getDayPicker(state?.date) ?? undefined,
          // "START_DATE": getDayPicker(state?.date) ??  undefined,
          // "END_DATE": filter?.RANGE_DATE?.[1],
          "PURCHASE_STATUS": status?.length > 0 ? status : undefined,
          page: 1,
          limit: 1000000
        }
        setDatas(param)
      }
    // }
  }, [setDatas, state, toggle, datatables2 ])
  useEffect(() => {
    if (query) {
      setStep(query || "transactionList")
    }
  }, [query])

  const handleChangeStep = (val) => {
    handlePush(val)
    setStep(val)
  }

  const handlePush = (type) => {
    navigate({
      search: `?act=${type}`
    })
  }

  const handleFlow = async (submit, type) => {
    if (type === 'transactionDetail') {
      await setSelected(submit)
      handleChangeStep('transactionDetail')
    } else if (type === 'transactionInfo') {
      await setSelected(submit)
      handleChangeStep('transactionInfo')
    } else if (type === 'list') {
      //   await initFetch();
      handleChangeStep('transactionList')
    }
  }
  const toggleArr = [
    {
      id: 1,
      name: 'all',
      label: 'All Progress'
    },
    {
      id: 2,
      name: 'waiting_admin_delivery',
      label: 'Waiting Admin add Delivery Time'
    },
    {
      id: 3,
      name: 'waiting_customer_approve',
      label: 'Waiting for Customer Approval'
    },
    {
      id: 4,
      name: 'waiting_admin_approve',
      label: 'Waiting for Admin Approval'
    },
    {
      id: 5,
      name: 'deal',
      label: 'Deal'
    },
    {
      id: 6,
      name: 'cancel',
      label: 'Canceled'
    }
  ]
  return (
    <React.Fragment>
      <CustomBackdrop loading={isLoading}></CustomBackdrop>
      <Box
        sx={{
          m: 2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px'
        }}
      >
        <Typography variant="body1" color="initial"><strong>{language.COMMON.MY_TRANSACTION.MY_TRANSACTION}</strong></Typography>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <TextField
            sx={{ width: 300, paddingTop: 2, paddingRight: 3 }}
            id="transaction"
            color='info'
            onChange={(e) => {
              setState({ ...state, text: e.target.value })
              setPage(0)
            }}
            value={state.text}
            name='transaction'
            placeholder={language.COMMON.MY_TRANSACTION.SEARCH_TRANSACTION_CODE}
            size='small'
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={state.date}
              onChange={(newValue) => {
                setState({ ...state, date: newValue });
                setPage(0)
              }}
              renderInput={(params) => <TextField size='small' sx={{ width: 300, paddingTop: 2 }} {...params} inputProps={{
                ...params.inputProps,
                placeholder: "Cari Kode Transaksi - mm/dd/yyyy"
              }}/>}
            />
          </LocalizationProvider>
        </div>

        {/* Button Toggle */}
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography sx={{ paddingTop: 4, paddingRight: 2, paddingLeft: 2 }} align='inherit'><strong>Progress</strong></Typography>
          {
            toggleArr?.map((val, idx) =>
              <ToggleButton key={idx}
                sx={{ borderRadius: 25, px: 2, mx: 1, my: 2 }}
                value={val?.name}
                color='info'
                selected={toggle[val?.name]}
                name={val?.name}
                onChange={(event) => {
                  setToggle({
                    [event.target.name]: !toggleBtn[event.target.name]
                  });
                  setPage(0)
                }}
              >
                {val?.label}
              </ToggleButton>
            )
          }

        </div>
        <TableCustom
          changeStep={handleChangeStep}
          data={datas}
          loading={isLoading}
          error={error}
          step={step}
          selected={selected}
          onSelect={setSelected}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          checkingApprove={(body, type) => handleFlow(body, type)}>
        </TableCustom>
      </Box>
    </React.Fragment>
  );
}
