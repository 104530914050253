import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `Rp.${value}`;
}


export default function MinimumDistanceSlider(props) {
  const {
    minDistance,
    minRange, 
    maxRange, 
    value, 
    setvalue 

  } = props
  // const [value, setvalue] = React.useState([0, 300000]);
  const distance = minDistance ?? 25000;
  
  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setvalue([Math.min(newValue[0], value[1] - distance), value[1]]);
    } else {
      setvalue([value[0], Math.max(newValue[1], parseInt(value[0]) + distance)]);
    }
  };

  return (
    <Box sx={{ width: "100%", display:'flex', justifyContent:'center'}}>
      <Slider
      sx={{color:"#20376D", width: "75%" }}
        min={minRange}
        max={maxRange}
        step={1}
        getAriaLabel={() => "Minimum distance"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        valueLabelFormat={valuetext}
        size = "small"
        disableSwap
      />
    </Box>
  );
}
