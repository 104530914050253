import * as RegExp from '../../helpers/regExp'

export default function validateSign(values) {

  let error = {}

  if (!values.email) {
    error.email = "Email required"
  } else if (!RegExp.EMAIL.test(values.email)) {
    error.email = "Email address is invalid"
  }

  if (!values.username) {
    error.username = "Username is required"
  } else if (!RegExp.NOWHITESPACE.test(values.username)) {
    error.username = "Username is contained space character"
  }

  if (!values.password) {
    error.password = "Password is required"
  } else if (!RegExp.NOWHITESPACE.test(values.password)) {
    error.password = "Password is contained space character"
  }

  if (!values?.newPassword) {
    error.newPassword = "New password is required"
  } else if (values?.newPassword?.length < 8) {
    error.newPassword = "Password contain minimum 8 character"
  } else if (!RegExp.upperLowerValidator.test(values?.newPassword)) {
    error.newPassword = "Password must have lowercase and uppercase combination"
  } else if (!RegExp.NOWHITESPACE.test(values?.newPassword)) {
    error.newPassword = "Password cannot contain whitespace"
  } else if (!RegExp.numericValidator.test(values?.newPassword)) {
    error.newPassword = "Password must have numeric combination"
  } else if (!RegExp.PassValidate.test(values?.newPassword)) {
    error.newPassword = "Password must have minumum one special character"
  } else if (values?.newPassword !== values?.confirmPassword) {
    error.newPassword = "Password is not matched"
  }

  if (!values?.confirmPassword) {
    error.confirmPassword = "Confirmation password is required"
  } else if (values?.confirmPassword?.length < 8) {
    error.confirmPassword = "Password contain minimum 8 character"
  } else if (!RegExp.upperLowerValidator.test(values?.confirmPassword)) {
    error.confirmPassword = "Password must have lowercase and uppercase combination"
  } else if (!RegExp.NOWHITESPACE.test(values?.confirmPassword)) {
    error.confirmPassword = "Password cannot contain whitespace"
  } else if (!RegExp.numericValidator.test(values?.confirmPassword)) {
    error.confirmPassword = "Password must have numeric combination"
  } else if (!RegExp.PassValidate.test(values?.confirmPassword)) {
    error.confirmPassword = "Password must have minimum one special character"
  } else if (values?.newPassword !== values?.confirmPassword) {
    error.confirmPassword = "Password is not matched"
  }

  // if (!values.retype) {
  //   error.confirmPassword = "Confirmation password is required"
  // } else if (values.confirmPassword?.length < 8) {
  //   error.confirmPassword = "Password contain at least 8 character"
  // } else if (!RegExp.upperLowerValidator.test(values.confirmPassword)) {
  //   error.confirmPassword = "Password must have lowercase and uppercase combination"
  // } else if (!RegExp.NOWHITESPACE.test(values.confirmPassword)) {
  //   error.confirmPassword = "Password cannot contain whitespace"
  // } else if (!RegExp.PassValidate.test(values.confirmPassword)) {
  //   error.confirmPassword = "Password must have at least one special character"
  // } else if (!RegExp.numericValidator.test(values.confirmPassword)) {
  //   error.confirmPassword = "Password must have numeric combination"
  // } else if (values.newPassword !== values.confirmPassword) {
  //   error.confirmPassword = "Password is not matched"
  // }

  return error
}