import { getFormattedDay } from 'helpers';

const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export default function structuredefault(values, handleFieldChange, open, open2, open3, categoryOption, isBergain, language) {
  const data = [
    {
      id: 'INVOICE_NO',
      label: language.COMMON.MY_TRANSACTION_DETAIL.ORDER_ID,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Category Code',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["INVOICE_NO"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'RECEIPT_NO',
      label: language.COMMON.MY_TRANSACTION_DETAIL.RECEIPT_NO,
      type: 'textwithbutton',
      fieldtype: 'textwithbutton',
      // disabled: true,
      placeholder: '0',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["RECEIPT_NO"] || 'Recipe No',
      minWidth: 170,
      // hide: isHide
      open: open2
    },

    {
      id: 'PURCHASE_DATE',
      label: language.COMMON.MY_TRANSACTION_DETAIL.PURCHASED_DATE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Purchased Date/Time',
      onChange: (field, value) => handleFieldChange(field, value),
      value: `${getFormattedDay(values["PURCHASE_DATE"], 'withoutSecond')}` || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PAYMENT_METHOD',
      label: language.COMMON.MY_TRANSACTION_DETAIL.PAYMENT_METHOD,
      type: 'textwithbutton',
      fieldtype: 'textwithbutton',
      // disabled: true,
      value: 'Bank Transfer',
      minWidth: 170,
      // hide: isHide,
      open: open3
    },
    {
      id: 'PURCHASE_STATUS',
      label: language.COMMON.MY_TRANSACTION_DETAIL.STATUS,
      type: 'textwithbutton',
      fieldtype: 'textwithbutton',
      // disabled: true,
      placeholder: 'Insert Status',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PURCHASE_STATUS"] || 'No Status',
      minWidth: 170,
      // hide: isHide,
      open: open
    },
    {
      id: 'SHIPPING_ETD',
      label: language.COMMON.MY_TRANSACTION_DETAIL.DELIVERY_TIME,
      type: 'date',
      fieldtype: 'datepicker',
      disabled: isBergain ? false : true,
      required: true,
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["SHIPPING_ETD"] || '',
      minWidth: 170,
      minDate: true,
      // hide: isHide
      placeholder: 'Insert Delivery Time',
    },
  ]

  return data
};