export const selectConstants = {
    SELECT_ITEM_REQUEST: 'ITEM_SELECTED_REQUEST',
    SELECT_ITEM_SUCCESS: 'ITEM_SELECTED_SUCCESS',
    SELECT_ITEM_FAILURE: 'ITEM_SELECTED_FAILURE',

    DETAIL_REQUEST: 'ITEM_DETAIL_REQUEST',
    DETAIL_SUCCESS: 'ITEM_DETAIL_SUCCESS',
    DETAIL_FAILURE: 'ITEM_DETAIL_FAILURE',

    SELECT_NOITEM: 'ITEM_SELECTED_EMPTY',

    SELECT_DETAIL_ITEM_REQUEST: 'ITEM_DETAIL_SELECTED_REQUEST',
    SELECT_DETAIL_ITEM_SUCCESS: 'ITEM_DETAIL_SELECTED_SUCCESS',
    SELECT_DETAIL_ITEM_FAILURE: 'ITEM_DETAIL_SELECTED_FAILURE',

    SELECT_DETAIL_NOITEM: 'ITEM_DETAIL_SELECTED_EMPTY',
};
