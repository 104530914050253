import common from './common';
import login from './login.en';
import dashboard from './dashboard';
import logout from './logout';
import shortcut from './shortcut';
import table from './tabel';

export default function English() {
  const Lang = {
    COMMON: common,
    SHORTCUT: shortcut,
    LANDING_LOGIN: login,
    DASHBOARD_ROOT: dashboard,
    LANDING_LOGOUT: logout,
    TABLE: table,
  }
  return Lang
}