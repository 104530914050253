import { authHeader } from "../_helpers";
import axios from "axios";
import { generateKey, encrypt } from "../../helpers/hashing";
import { getAccessLog } from "../../helpers";
import { v4 as uuidv4 } from "uuid";
import { v5 as uuidv5 } from "uuid";

export const userService = {
  login,
  logout,
  getAll,
  getSignature,
  createData,
  updateData,
  changeUserName,
  changePassword,
  newPassword,
  forgotPassword,
  refreshToken,
  resetData,
  confirmData,
  deactivateData,
};

async function login(param) {
  try {
    console.log("Function login is started", [param]);
    const nacl = require("tweetnacl");
    const naclUtil = require("tweetnacl-util");

    const decodeUTF8Pass = naclUtil.decodeUTF8(param.password);
    const hashPass = nacl.hash(decodeUTF8Pass);
    const endcodedBase64Pass = naclUtil.encodeBase64(hashPass);
    console.log("login -> endcodedBase64Pass", endcodedBase64Pass);

    const bodyOptions = {};
    const requestUrl = `/usr/signin-customer`;
    const checkHeader = await authHeader(
      requestUrl,
      bodyOptions,
      "not mandatory"
    );

    console.log(
      "🚀 ~ file: user.service.js ~ line 45 ~ login ~ checkHeader",
      checkHeader
    );

    const requestOptions = {
      auth: {
        username: param.email,
        // password: param.password
        password: endcodedBase64Pass,
      },
      // headers: checkHeader,
    };
    const response = await axios
      .post(`${requestUrl}`, {}, requestOptions)
      .then((response) => {
        console.log(
          "🚀 ~ file: user.service.js ~ line 58 ~ login ~ response",
          response
        );

        const initResponse = {
          "_key": response?.data?.DATAS?._key,
          "username": response?.data?.DATAS?.username,
          "name": response?.data?.DATAS?.name,
          "email": response?.data?.DATAS?.email,
          "role": {
            "role_id": response?.data?.DATAS?.role?.code,
            "role_name": response?.data?.DATAS?.role?.name?.replace("_", " "),
            "role_permissions": {
              "menu": response?.data?.DATAS?.role?.role_permissions?.menu?.map(item => {
                const newMap = {
                  "feature_code": item.code,
                  // "name": item.MENU_NAME,
                  "view": item.view,
                }
                return newMap
              })
            }
          },
          "token": response?.data?.DATAS?.token
        }
        console.log("🚀 ~ file: user.service.js ~ line 85 ~ .then ~ initResponse", initResponse)
        if (response.status === 200) {
          console.log("Response fetch data loginnya adalah : ", response);
          const loginTime = new Date().getTime();
          const key = generateKey();
          // const permissions = response?.data?.data?.role?.role_permissions ?? [];
          const permissions = initResponse.role?.role_permissions ?? [];
          const userData = initResponse ?? {};
          const encryptedPermissions = encrypt(permissions, key);
          const encryptedUserData = encrypt(userData, key);

          localStorage.setItem("Retail", true);
          localStorage.setItem("isBigLoad", true);
          localStorage.setItem("isBigLoad", true);
          localStorage.setItem("login_time", loginTime);
          localStorage.setItem("app_config", encryptedPermissions); // role
          localStorage.setItem("user_terminal", key);
          localStorage.setItem("user_load_point", encryptedUserData); // data pribadi dll
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("web_map", 0);
          localStorage.setItem("token", response?.data?.DATAS?.token);
          localStorage.setItem("session", response?.data?.DATAS?.token?.access_token);

          return {
            user: { data: initResponse },
            token: response?.data?.DATAS?.token || {},
            data: initResponse || "",
            status_code: response?.status,
            status_user: response?.data?.DATAS?.status,
            message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
          };
        } else {
          return {
            status_code: response?.status,
            message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
          };
        }
      })
      .catch((error) => {
        // Error 😨

        console.log(error,'woooee');
        if (error?.response?.status === 401) {
          return {
            // data: !response.data ? [] : response.data.data,
            status_code: error?.response?.status,
            message: "Unauthorized login!",
          };
        } else if (error?.response) {
          return {
            status_code: error?.response?.data?.INFO_RESPONSE?.RESPONSE_CODE || error?.response?.data?.response?.status,
            message: error?.response?.data?.response?.message || error?.response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
          };
        } else if (!error.response) {
          return {
            // data: !response.data ? [] : response.data.data,
            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error.status,
            message:
              error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ??
              "There is a problem with your internal connection. Please try again later or call your provider",
          };
        } else {
          return {
            // data: !error.response ? {} : error.response,
            status_code: error?.response?.data?.response?.status,
            message: error.response?.data?.response?.message || error?.response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
          };
        }
      });


    return response;
  } catch (error) {
    return {
      status_code: "E400",
      message: "Error to call API",
    };
  }
}


async function logout() {
  try {
    const requestUrl = `/internaluser/logout`;
    // const user = getAccessLog("access_token")
    const checkHeader = await authHeader(requestUrl, {});
    const requestOptions = {
      headers: checkHeader,
    };
    const res = await axios.get(`${requestUrl}`, requestOptions);
    return res;
  } catch (error) {
    console.log(error);
  }
  localStorage.removeItem("user");
  localStorage.clear();
  window.location.reload()

}

async function getAll(param) {
  const bodyOptions = {};
  const skip = param?.skip || "0";
  const limit = param?.skip || "100000";
  const requestUrl = `/internaluser/filter/internal_user/${skip}/${limit}`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    headers: checkHeader,
  };

  const response = await axios
    .post(`${requestUrl}`, bodyOptions, requestOptions)
    .then((response) => {
      //  collectResponse(response, setIsLoading, setError)
      console.log("Response fetch data user adalah : ", response);
      return {
        data: response.data.data,
        status_code: response.data.response_code,
        message: response.data.message,
      };
    })
    .catch((error) => {
      // Error 😨
      console.log(error);
      if (error?.response?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Unauthorized login!",
        };
      } else if (error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.response_code,
          message: error.response.message,
        };
      }
    });
  return response;
}


async function createData(param) {
  const username = getAccessLog("username")

  const nacl = require("tweetnacl")
  const naclUtil = require('tweetnacl-util');

  const decodeUTF8OldPass = naclUtil.decodeUTF8(param?.password)
  const hashOldPass = nacl.hash(decodeUTF8OldPass);

  const endcodedBase64OldPass = naclUtil.encodeBase64(hashOldPass);

  const bodyOptions = {
  };

  const requestUrl = `/usr/register-customer`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    auth: {
      username: param.email,
      // password: param.password
      password: endcodedBase64OldPass,
    },
    // headers: checkHeader,
  };
  console.log("🚀 ~ file: user.service.js ~ line 505 ~ createData ~ requestOptions", requestOptions)

  const response = await axios
    .post(`${requestUrl}`, {}, requestOptions)
    .then((response) => {
      console.log(
        "🚀 ~ file: user.service.js ~ line 58 ~ login ~ response",
        response
      );
      return {
        // data: !response.data ? [] : response.data.data,
        status_code: response?.status,
        message: "Register Success!",
      };
    })
    .catch((error) => {
      console.log("🚀 ~ file: user.service.js ~ line 640 ~ createData ~ error", error)
      // Error 😨
      console.log("Response Error", error);
      if (error?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.status,
          message: "Unauthorized login!",
        };
      } else if (error?.status === 404) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.status,
          message: "Endpoint is not exist.",
        };
      } else if (error) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.status,
          message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
        };
      } else if (!error) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.response.code,
          message: error.response.mesaage,
        };
      }
    });
  return response;
}

// async function createData(param) {
//   const username = getAccessLog("username")

//   const nacl = require("tweetnacl")
//   const naclUtil = require('tweetnacl-util');

//   const decodeUTF8OldPass = naclUtil.decodeUTF8(param?.password)
//   const hashOldPass = nacl.hash(decodeUTF8OldPass);

//   const endcodedBase64OldPass = naclUtil.encodeBase64(hashOldPass);

//   const bodyOptions = {
//   };

//   const requestUrl = `/usr/register-customer`;
//   const checkHeader = await authHeader(requestUrl, bodyOptions);
//   const requestOptions = {
//     auth: {
//       username: param.email,
//       // password: param.password
//       password: endcodedBase64OldPass,
//     },
//     // headers: checkHeader,
//   };
//   console.log("🚀 ~ file: user.service.js ~ line 505 ~ createData ~ requestOptions", requestOptions)

//   const response = await axios
//     .post(`${requestUrl}`, {}, requestOptions)
//     .then((response) => {
//       console.log(
//         "🚀 ~ file: user.service.js ~ line 58 ~ login ~ response",
//         response
//       );

//       const initResponse = {
//         "_key": response?.data?.DATAS?._key,
//         "username": response?.data?.DATAS?.username,
//         "name": response?.data?.DATAS?.name,
//         "email": response?.data?.DATAS?.email,
//         "role": {
//           "role_id": response?.data?.DATAS?.role?.code,
//           "role_name": response?.data?.DATAS?.role?.name?.replace("_", " "),
//           "role_permissions": {
//             "menu": response?.data?.DATAS?.role?.role_permissions?.menu?.map(item => {
//               const newMap = {
//                 "feature_code": item.code,
//                 // "name": item.MENU_NAME,
//                 "view": item.view,
//               }
//               return newMap
//             })
//           }
//         },
//         "token": response?.data?.DATAS?.token
//       }
//       console.log("🚀 ~ file: user.service.js ~ line 85 ~ .then ~ initResponse", initResponse)
//       if (response.status === 200) {
//         console.log("Response fetch data loginnya adalah : ", response);
//         const loginTime = new Date().getTime();
//         const key = generateKey();
//         // const permissions = response?.data?.data?.role?.role_permissions ?? [];
//         const permissions = initResponse.role?.role_permissions ?? [];
//         const userData = initResponse ?? {};
//         const encryptedPermissions = encrypt(permissions, key);
//         const encryptedUserData = encrypt(userData, key);

//         localStorage.setItem("Retail", true);
//         localStorage.setItem("isBigLoad", true);
//         localStorage.setItem("isBigLoad", true);
//         localStorage.setItem("login_time", loginTime);
//         localStorage.setItem("app_config", encryptedPermissions); // role
//         localStorage.setItem("user_terminal", key);
//         localStorage.setItem("user_load_point", encryptedUserData); // data pribadi dll
//         localStorage.setItem("isAuthenticated", true);
//         localStorage.setItem("web_map", 0);
//         localStorage.setItem("token", response?.data?.DATAS?.token);
//         localStorage.setItem("session", response?.data?.DATAS?.token?.access_token);

//         return {
//           user: { data: initResponse },
//           token: response?.data?.DATAS?.token || {},
//           data: initResponse || "",
//           status_code: response?.status,
//           status_user: response?.data?.DATAS?.status,
//           message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
//         };
//       } else {
//         return {
//           status_code: response?.status,
//           message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
//         };
//       }
//     })
//     .catch((error) => {
//       // Error 😨
//       console.log("Response Error", error.response);
//       if (error?.response?.status === 401) {
//         return {
//           // data: !response.data ? [] : response.data.data,
//           status_code: error?.response?.status,
//           message: "Unauthorized login!",
//         };
//       } else if (error?.response?.status === 404) {
//         return {
//           // data: !response.data ? [] : response.data.data,
//           status_code: error?.response?.status,
//           message: "Endpoint is not exist.",
//         };
//       } else if (error.response) {
//         return {
//           // data: !response.data ? [] : response.data.data,
//           status_code: error.response.data.response_code,
//           message: error.response.data.message,
//         };
//       } else if (!error.response) {
//         return {
//           // data: !response.data ? [] : response.data.data,
//           status_code: error?.data?.response_code ?? error.status,
//           message:
//             error?.data?.message ??
//             "There is a problem with your internal connection. Please try again later or call your provider",
//         };
//       } else {
//         return {
//           // data: !response.data ? [] : response.data.data,
//           status_code: error.response.response.code,
//           message: error.response.mesaage,
//         };
//       }
//     });
//   return response;
// }

async function resetData(param) {
  console.log("🚀 ~ file: user.service.js ~ line 590 ~ resetData ~ param", param)
  // const nacl = require("tweetnacl")
  // const naclUtil = require('tweetnacl-util');
  // const password = param.password
  // const decodeUTF8Pass = naclUtil.decodeUTF8(password)
  // const hashPass = nacl.hash(decodeUTF8Pass);
  // const endcodedBase64Pass = naclUtil.encodeBase64(hashPass);
  // console.log("login -> endcodedBase64Pass", endcodedBase64Pass)
  // newParam.password = endcodedBase64Pass
  // newParam.status = "new"
  // newParam.branch = {
  //     _key: newParam.branch
  // }
  // newParam.role = {
  //     _key: newParam.role
  // }
  const bodyOptions = {
    target: param?.username
  };

  const requestUrl = `/usr/reset-password`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    headers: checkHeader,
  };
  const response = await axios
    .post(`${requestUrl}`, bodyOptions, requestOptions)
    .then((response) => {
      console.log("Response reset user adalah : ", response);
      if (response.status === 200) {
        return {
          // data: response.data.data,
          status_code: response.status,
          message: response.data.DATAS?.INFO_RESPONSE?.RESPONSE_MESSAGE,
        };
      } else {
        return {
          // data: response.data.data,
          status_code: response.status ?? 400,
          message: response.data.DATAS?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? "Endpoint is not exist",
        };
      }
    })
    .catch((error) => {
      // Error 😨
      console.log("Response Error", error.response);
      if (error?.messageError === 'Session Expired') {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: 401,
          message: "Session Expired Please Re-Login",
        };
      } else
        if (error) {
          return {
            // data: !response.data ? [] : response.data.data,
            status_code: error?.status,
            message: error?.data.DATAS?.INFO_RESPONSE?.RESPONSE_MESSAGE,
          };
        } else
          if (error?.response?.status === 401) {
            return {
              // data: !response.data ? [] : response.data.data,
              status_code: error?.response?.status,
              message: "Unauthorized login!",
            };
          } else if (error?.response?.status === 404) {
            return {
              // data: !response.data ? [] : response.data.data,
              status_code: error?.response?.status,
              message: "Endpoint is not exist.",
            };
          } else if (error.response) {
            return {
              // data: !response.data ? [] : response.data.data,
              status_code: error.response.data.response_code,
              message: error.response.data.message,
            };
          } else if (!error.response) {
            return {
              // data: !response.data ? [] : response.data.data,
              status_code: error?.data?.response_code ?? error.status,
              message:
                error?.data?.message ??
                "There is a problem with your internal connection. Please try again later or call your provider",
            };
          } else {
            return {
              // data: !response.data ? [] : response.data.data,
              status_code: error.response.response.code,
              message: error.response.mesaage,
            };
          }
    });
  return response;
}
async function deactivateData(param) {
  // const nacl = require("tweetnacl")
  // const naclUtil = require('tweetnacl-util');
  // const password = param.password
  // const decodeUTF8Pass = naclUtil.decodeUTF8(password)
  // const hashPass = nacl.hash(decodeUTF8Pass);
  // const endcodedBase64Pass = naclUtil.encodeBase64(hashPass);
  // console.log("login -> endcodedBase64Pass", endcodedBase64Pass)

  const newParam = { ...param };
  // newParam.password = endcodedBase64Pass
  // newParam.status = "new"
  // newParam.branch = {
  //     _key: newParam.branch
  // }
  // newParam.role = {
  //     _key: newParam.role
  // }
  const bodyOptions = newParam;
  console.log(
    "🚀 ~ file: user.service.js ~ line 263 ~ createData ~ newParam",
    newParam
  );

  const requestUrl = `/internaluser/deactivate`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    headers: checkHeader,
  };
  const response = await axios
    .post(`${requestUrl}`, bodyOptions, requestOptions)
    .then((response) => {
      console.log("Response deactivate user adalah : ", response);
      if (response.data.response_code === "S1") {
        return {
          // data: response.data.data,
          status_code: response.data.response_code,
          message: response.data.message,
        };
      } else {
        return {
          // data: response.data.data,
          status_code: response?.data?.response_code ?? 400,
          message: response?.data?.message ?? "Endpoint is not exist",
        };
      }
    })
    .catch((error) => {
      // Error 😨
      console.log("Response Error", error.response);
      if (error?.response?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Unauthorized login!",
        };
      } else if (error?.response?.status === 404) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Endpoint is not exist.",
        };
      } else if (error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.response.code,
          message: error.response.mesaage,
        };
      }
    });
  return response;
}

async function confirmData(param) {
  // const nacl = require("tweetnacl")
  // const naclUtil = require('tweetnacl-util');
  // const password = param.password
  // const decodeUTF8Pass = naclUtil.decodeUTF8(password)
  // const hashPass = nacl.hash(decodeUTF8Pass);
  // const endcodedBase64Pass = naclUtil.encodeBase64(hashPass);
  // console.log("login -> endcodedBase64Pass", endcodedBase64Pass)
  const bodyOptions = {
    username: param?.email,
    reset_code: param?.reset_code,
  };
  const requestUrl = `/internaluser/resetcode/verify`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    headers: checkHeader,
  };
  const response = await axios
    .post(`${requestUrl}`, bodyOptions, requestOptions)
    .then((response) => {
      console.log("Response reset verify data adalah : ", response);
      if (response.status === 200) {
        return {
          // data: response.data.data,
          status_code: response.data.response_code,
          message: response.data.message,
        };
      } else {
        return {
          // data: response.data.data,
          status_code: response?.data?.response_code ?? 400,
          message: response?.data?.message ?? "Endpoint is not exist",
        };
      }
    })
    .catch((error) => {
      // Error 😨
      console.log("Response Error", error.response);
      if (error?.response?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Unauthorized login!",
        };
      } else if (error?.response?.status === 404) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Endpoint is not exist.",
        };
      } else if (error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.response.code,
          message: error.response.mesaage,
        };
      }
    });
  return response;
}

async function updateData(param) {
  const bodyOptions = param ?? {};
  const requestUrl = `/internaluser/update-internal-user`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    headers: checkHeader,
  };
  const response = await axios
    .put(`${requestUrl}`, bodyOptions, requestOptions)
    .then((response) => {
      console.log("Response update data adalah : ", response);
      return {
        data: response.data.data,
        status_code: response.data.response_code,
        message: response.data.message,
      };
    })
    .catch((error) => {
      // Error 😨
      console.log(error.response);
      if (error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      }
    });
  return response;
}

async function changeUserName(setIsLoading, setError, param) {
  setError(false);
  setIsLoading(true);
  const userId = getAccessLog("user_id");
  const bodyOptions = {
    name: param.name,
    inputter: getAccessLog("user_name"),
    inputter_name: getAccessLog("user_id"),
  };

  const requestUrl = `/customer/nazhir/updatecustomer/${userId}`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    headers: checkHeader,
  };
  const response = await axios
    .put(
      `/customer/nazhir/updatecustomer/${userId}`,
      bodyOptions,
      requestOptions
    )
    .then((response) => {
      console.log("Response save data user adalah : ", response);
      setError(null);
      setIsLoading(false);
      return {
        // data: response.data.data,
        status_code: response.data.response_code,
        message: response.data.message,
      };
    })
    .catch((error) => {
      // Error 😨
      console.log(error.response);
      if (error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else if (error?.response?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Unauthorized login!",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.response_code,
          message: error.response.message,
        };
      }
    });
  return response;
}

async function changePassword(setIsLoading, setError, param) {
  setError(false);
  setIsLoading(true);
  // const userId = getAccessLog("user_id")

  // const nacl = require("tweetnacl")
  // const naclUtil = require('tweetnacl-util');

  // const decodeUTF8OldPass = naclUtil.decodeUTF8(param.old_password)
  // const decodeUTF8NewPass = naclUtil.decodeUTF8(param.new_password)

  // const hashOldPass = nacl.hash(decodeUTF8OldPass);
  // const hashNewPass = nacl.hash(decodeUTF8NewPass);

  // const endcodedBase64OldPass = naclUtil.encodeBase64(hashOldPass);
  // const endcodedBase64NewPass = naclUtil.encodeBase64(hashNewPass);

  // const bodyOptions = {
  //     "name": param.name,
  //     "user_id": getAccessLog("user_id"),
  //     "username": getAccessLog("username"),
  //     "old_password": endcodedBase64OldPass,
  //     "password": endcodedBase64NewPass,
  //     "request_code": "Update"
  // };

  // const email = getAccessLog("email")
  const bodyOptions = {
    password: param?.password,
  };
  const requestUrl = `/v1/reset-password`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    headers: checkHeader,
  };

  const response = await axios
    .post(`${requestUrl}`, bodyOptions, requestOptions)
    .then((response) => {
      console.log("Response save data user adalah : ", response);
      setError(null);
      setIsLoading(false);
      return {
        // data: response.data.data,
        status_code: response.data.response_code,
        message: response.data.response.message,
      };
    })
    .catch((error) => {
      // Error 😨
      console.log(error.response);
      if (error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else if (error?.response?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Unauthorized login!",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.response_code,
          message: error.response.message,
        };
      }
    });
  return response;
}

async function newPassword(param) {
  console.log("Function login is started", [param]);

  const nacl = require("tweetnacl");
  const naclUtil = require("tweetnacl-util");

  const decodeUTF8OldPass = naclUtil.decodeUTF8(param.oldPassword);
  const decodeUTF8NewPass = naclUtil.decodeUTF8(param.password);

  const hashOldPass = nacl.hash(decodeUTF8OldPass);
  const hashNewPass = nacl.hash(decodeUTF8NewPass);

  const endcodedBase64OldPass = naclUtil.encodeBase64(hashOldPass);
  const endcodedBase64NewPass = naclUtil.encodeBase64(hashNewPass);

  const bodyOptions = {
    password: endcodedBase64NewPass,
  };
  const requestUrl = `/usr/new-password`;
  const checkHeader = await authHeader(requestUrl, bodyOptions, "not mandatory")
  // const checkHeader = await authHeader(requestUrl, bodyOptions)
  // const auth = btoa(`${param.username}:${endcodedBase64OldPass}`)

  const requestOptions = {
    auth: {
      username: param.username,
      // password: password
      password: endcodedBase64OldPass,
    },
    headers: checkHeader,
    // headers: {
    //     'New-Authorization': `Basic ${auth}`
    // }
  };

  const response = await axios
    .post(`${requestUrl}`, bodyOptions, requestOptions)
    .then((response) => {
      console.log("Response save data new password adalah : ", response);
      const initResponse = {
        "_key": response?.data?.DATAS?._key,
        "username": response?.data?.DATAS?.username,
        "name": response?.data?.DATAS?.name,
        "email": response?.data?.DATAS?.email,
        "role": {
          "role_id": response?.data?.DATAS?.role?.code,
          "role_name": response?.data?.DATAS?.role?.name?.replace("_", " "),
          "role_permissions": {
            "menu": response?.data?.DATAS?.role?.role_permissions?.menu?.map(item => {
              const newMap = {
                "feature_code": item.code,
                // "name": item.MENU_NAME,
                "view": item.view,
              }
              return newMap
            })
          }
        },
        "token": response?.data?.DATAS?.token
      }
      console.log("🚀 ~ file: user.service.js ~ line 85 ~ .then ~ initResponse", initResponse)
      if (response.status === 200) {
        console.log("Response fetch data loginnya adalah : ", response);
        const loginTime = new Date().getTime();
        const key = generateKey();
        // const permissions = response?.data?.data?.role?.role_permissions ?? [];
        const permissions = initResponse.role?.role_permissions ?? [];
        const userData = initResponse ?? {};
        const encryptedPermissions = encrypt(permissions, key);
        const encryptedUserData = encrypt(userData, key);

        localStorage.setItem("Retail", true);
        localStorage.setItem("isBigLoad", true);
        localStorage.setItem("isBigLoad", true);
        localStorage.setItem("login_time", loginTime);
        localStorage.setItem("app_config", encryptedPermissions); // role
        localStorage.setItem("user_terminal", key);
        localStorage.setItem("user_load_point", encryptedUserData); // data pribadi dll
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("web_map", 0);
        localStorage.setItem("token", response?.data?.DATAS?.token);
        localStorage.setItem("session", response?.data?.DATAS?.token?.access_token);

        return {
          user: { data: initResponse },
          token: response?.data?.DATAS?.token || {},
          data: initResponse || "",
          status_code: response?.status,
          status_user: response?.data?.DATAS?.status,
          message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
        };
      } else {
        return {
          status_code: response?.data?.response_code ?? response.status,
          message:
            response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ??
            "Action is failed to be executed. May be ther's a problen with the system.",
        };
      }
    })
    .catch((error) => {
      // Error 😨
      console.log(error.response);
      if (error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else if (error?.response?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Unauthorized login!",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.response_code,
          message: error.response.message,
        };
      }
    });
  return response;
}

async function forgotPassword(username, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  const bodyOptions = {
    username: username,
  };

  const requestUrl = `/usr/reset-password`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    headers: checkHeader,
  };
  const response = await axios
    .post(`/usr/reset-password`, bodyOptions, requestOptions)
    .then((response) => {
      console.log("Response save data forgot password adalah : ", response);
      setError(null);
      setIsLoading(false);
      return {
        // data: response.data.data,
        status_code: response.data.response_code,
        message: response.data.response.message,
      };
    })
    .catch((error) => {
      // Error 😨
      console.log(error.response);
      if (error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else if (error?.response?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Unauthorized login!",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.response_code,
          message: error.response.message,
        };
      }
    });
  return response;
}

async function getSignature() {
  const NAMESPACE = uuidv4();
  const request_id = uuidv5("prodia", NAMESPACE);

  // const user = localStorage.getItem('token')
  const requestOptions = {
    // headers: {
    //     'Authorization': 'Bearer ' + user
    // }
  };

  const response = await axios
    .get(`/transaction/generate-key/${request_id}`, requestOptions)
    .then((response) => {
      //  collectResponse(response, setIsLoading, setError)
      console.log("Respon0se fetch data signature adalah : ", response);
      return {
        request_id: request_id,
        request_key: response.data.data,
        source_data: response.data.data,
        status_code: response.data.response_code,
        message: response.data.message,
      };
    })
    .catch((error) => {
      // Error 😨
      console.log(error.response);
      if (error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else if (error?.response?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Unauthorized login!",
        };
      } else {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error.response.response_code,
          message: error.response.message,
        };
      }
    });
  return response;
}

async function refreshToken(refreshOldToken) {
  const bodyOptions = {
    refresh_token: refreshOldToken,
  };
  const requestUrl = `/usr/refreshtoken`;
  const checkHeader = await authHeader(requestUrl, bodyOptions);
  const requestOptions = {
    headers: checkHeader,
  };
  const response = await axios
    .post(`/usr/refreshtoken`, bodyOptions, requestOptions)
    .then((response) => {
      if (response.status === 200) {
        console.log("Response fetch data loginnya adalah : ", response);
        const loginTime = new Date().getTime();

        sessionStorage.setItem("isBigLoad", true);
        sessionStorage.setItem("login_time", loginTime);
        sessionStorage.setItem("isAuthenticated", true);
        sessionStorage.setItem("token", response.data.access_token);
        sessionStorage.setItem("session", response.data.refresh_token);

        const dataToken = {
          AccessToken: response.data.access_token,
          ExpiresIn: response.data.expires_in,
          RefreshExpiresIn: response.data.refresh_expires_in,
          RefreshToken: response.data.refresh_token,
        };

        return {
          data: dataToken || "",
          status_code: response.status,
          message: "Succes to refresh token",
        };
      } else {
        console.log("Response fetch data loginnya adalah : ", response);
        sessionStorage.setItem("isAuthenticated", false);

        return {
          data: response.data,
          status_code: response.status,
          // message: response.data.message
        };
      }
    })
    .catch((error) => {
      // Error 😨

      console.log(error.response);

      if (
        error?.response?.status === 400 &&
        error.response.data.response_code === "E4"
      ) {
        console.log("Error E4", error.response);
        return {
          data: error.response.data,
          status_code: error.response.data.response_code,
          message: "Unauthorized login!",
        };
      } else if (error.response) {
        return {
          status_code: error.response.data.response_code,
          message: error.response.data.message,
        };
      } else if (!error.response) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.data?.response_code ?? error.status,
          message:
            error?.data?.message ??
            "There is a problem with your internal connection. Please try again later or call your provider",
        };
      } else if (error?.response?.status === 401) {
        return {
          // data: !response.data ? [] : response.data.data,
          status_code: error?.response?.status,
          message: "Unauthorized login!",
        };
      } else {
        return {
          // data: !error.response ? {} : error.response,
          status_code: error.response.response_code,
          message: error.response.message,
        };
      }
    });
  return response;
}
