// 
import { authHeader } from '../_helpers'
import axios from '../_helpers/axiosCommon'
import { generateKey, encrypt } from "../../helpers/hashing";
import { getAccessLog } from 'helpers';

export const sessionService = {
    getAll,

};

async function getAll(param) {
    try {
        const bodyOptions = {

        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/usr/introspec`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions =
        {
            // auth: {

            // }
            headers: {
                Authorization: `Bearer ${param}`
            }
            // checkHeader
        }

        console.log("🚀 ~ file: session.service.js ~ line 20 ~ getAll ~ requestOptions", requestOptions)
        const response = await
            axios.post(`${requestUrl}`, {}, requestOptions)
                .then(response => {
                    const resData = response?.data?.DATAS?.[0]
                    if (resData) {

                        console.log("🚀 ~ file: session.service.js ~ line 36 ~ getAll ~ response", response)
                        const initResponse = {
                            "_id": resData?._id,
                            "_key": resData?._key,
                            "_rev": resData?._rev,
                            "creator": resData?.creator,
                            "email": resData?.email,
                            "is_admin": resData?.is_admin,
                            "last_inputter": resData?.last_inputter,
                            "name": resData?.name,
                            "role": resData?.role,
                            "status": resData?.status,
                            "user_trx_identifier_code": resData?.user_trx_identifier_code,
                            "username": resData?.username,
                            "token" : param,
                            "lkpp_identifier" : resData?.lkpp_identifier,
                            "lkpp_payload" : resData?.lkpp_payload
                        }
                        console.log("🚀 ~ file: user.service.js ~ line 85 ~ .then ~ initResponse", initResponse)
                        if (response.status === 200) {
                            console.log("Response fetch data loginnya adalah : ", response);
                            const loginTime = new Date().getTime();
                            const key = generateKey();
                            // const permissions = response?.data?.data?.role?.role_permissions ?? [];
                            const permissions = initResponse.role?.role_permissions ?? [];
                            const userData = initResponse ?? {};
                            const encryptedPermissions = encrypt(permissions, key);
                            const encryptedUserData = encrypt(userData, key);

                            localStorage.setItem("isBigLoad", true);
                            localStorage.setItem("isBigLoad", true);
                            localStorage.setItem("login_time", loginTime);
                            localStorage.setItem("app_config", encryptedPermissions); // role
                            localStorage.setItem("user_terminal", key);
                            localStorage.setItem("user_load_point", encryptedUserData); // data pribadi dll
                            localStorage.setItem("isAuthenticated", true);
                            localStorage.setItem("web_map", 0);
                            localStorage.setItem("Retail", false);
                            // localStorage.setItem("token", response?.data?.DATAS?.token);
                            // localStorage.setItem("session", response?.data?.DATAS?.token?.access_token);

                            return {
                                user: { data: initResponse },
                                // token: response?.data?.DATAS?.token || {},
                                data: initResponse || "",
                                status_code: response?.status,
                                status_user: response?.data?.DATAS?.status,
                                message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
                            };
                        } else {
                            return {
                                status_code: response?.status,
                                message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
                            };
                        }
                    } else {
                        return {
                            status_code: response?.status,
                            message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        };
                    }
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

