import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Grid,
  TextField,
  Typography,
  ButtonGroup,
  Button,
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  IconButton
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { getAccessLog, rupiahFormat } from 'helpers'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useProfile, useAddress, useCart, useRetailOrder, useOrder, useLanguage } from 'hooks'
import { useNavigate } from 'react-router'
import noImage from "assets/images/noImage.jpeg"
import moment from 'moment'


function Index(props) {
  const {
    selected,
    dataProfile,
  } = props
  const classes = useStyles()
  const today = moment().format('YYYY-MM-DD');
  const [language, lagDic, setLang] = useLanguage()
  const baseUrlImage = window._env_?.BASEURL_IMAGE
  const [open, setOpen] = useState(false);
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);
  const [isCheckedout, setisCheckedout] = useState(false);
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [dialogTypeSB, setDialogTypeSB] = useState("error");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openSB, setOpenSB] = useState(false);
  const [messageSB, setMessageSB] = useState("");
  const [datas, setDatas, makeDatas] = useAddress(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage);
  const [datatables, setDatatables, getDetail] = useCart(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables2, setDatatables2, getData] = useProfile(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables3, setDatatables3, action] = useOrder(setIsLoading, setError, setOpenSB, setDialogTypeSB, setTitle, setMessageSB)
  const [rows, setRows] = useState([]);
  const [counter, setcounter] = useState({})
  const [makeOrder, setMakeOrder] = useState(true)

  const handleMakeOrder = async (event) => {
    event.preventDefault();
    window.location.assign('/');
    // if (makeOrder) {
    //   let purchasedItem = []
    //   let shipping = []
    //   datatables.map((item) => {
    //     const temp = {
    //       "ITEM_NO": item?.ITEM_NO,
    //       "ITEM_TOTAL": item?.ITEM_TOTAL,
    //       "ITEM_PRICE": item?.ITEM_PRICE,
    //       "PRICE_TOTAL": item?.ITEM_PRICE * item?.ITEM_TOTAL,
    //     }
    //     purchasedItem.push(temp)
    //   })

    //   selected.map((item, index) => {
    //     const temp = {
    //       "SHIPPING_PRICE": item?.TOTAL_SHIPMENT_COST,
    //       "SHIPPING_WEIGHT": "",
    //       "SHIPPING_COURIER": "GAIDO",
    //       "SHIPPING_WAYBILL": "",
    //       "SHIPPING_ADDRESS_ID": item?.ID,
    //       "SHIPPING_ITEM": []
    //     }
    //     item.PRODUCTS.map((item) => {
    //       const temp2 = {
    //         "ITEM_NO": item?.ITEM_NO,
    //         "ITEM_TOTAL": item?.ITEM_TOTAL
    //       }
    //       temp["SHIPPING_ITEM"].push(temp2)
    //     })
    //     shipping.push(temp)
    //   })

    //   console.log("🚀 ~ file: index.jsx ~ line 100 ~ handleMakeOrder ~ shipping", shipping)
    //   const param = {
    //     "PURCHASE_DATE": today,
    //     "CUSTOMER_NO": datatables2?.[0]?.CUSTOMER_NO,
    //     // "CUSTOMER_NO": param?.CUSTOMER_NO,
    //     "PRICE_ITEMS": cartTotalPrice,
    //     "PRICE_SHIPPING": cartTotalShipment,
    //     "PRICE_FULL": (cartTotalPrice + cartTotalShipment),
    //     "PURCHASE_ITEM": purchasedItem,
    //     "SHIPPING": shipping,
    //     "ACTION_BY": getAccessLog('username'),
    //     "ACTION_DATE": today,
    //   }
    //   console.log("🚀 ~ file: index.jsx ~ line 113 ~ handleMakeOrder ~ param", param)
    //   action(param, 'create')
    //   setMakeOrder(false)
    // }
  }
  useEffect(() => {
    if (localStorage.getItem('Retail') === "false" || localStorage.getItem('Retail') === "true") {
      const param = {
        CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
        // CUSTOMER_NAME: "Maudy A",
        // CUSTOMER_NO: "00000009",
        USERNAME: getAccessLog('username'),
        page: 1,
        limit: 100
      }
      setDatatables2(param)
    }
  }, [datas])
  useEffect(() => {
    if (datatables2.length > 0) {
      const param = {
        CUSTOMER_NO: datatables2?.[0]?.CUSTOMER_NO,
        USERNAME: getAccessLog('username'),
        IS_CONTRACT: false,
        page: 1,
        limit: 100
      }
      setDatatables(param)
    }
  }, [datatables2])

  const sumPrice = () => {
    if (selected.length > 0) {
      var total = 0
      for (var i = 0, _len = selected.length; i < _len; i++) {
        for (var j = 0, _lenp = selected[i]['PRODUCTS'].length; j < _lenp; j++) {
          total += (selected[i]['PRODUCTS'][j]['ITEM_TOTAL'] * selected[i]['PRODUCTS'][j]['ITEM_PRICE'])
        }
      }
      return total
    }
  }
  const sumQty = () => {
    if (selected.length > 0) {
      var total = 0
      for (var i = 0, _len = selected.length; i < _len; i++) {
        for (var j = 0, _lenp = selected[i]['PRODUCTS'].length; j < _lenp; j++) {
          total += selected[i]['PRODUCTS'][j]['ITEM_TOTAL']
        }
      }
      return total
    }
  }
  // const shippingLocation = (loc) => {
  //   if (costShipping.hasOwnProperty(loc)) {
  //     return costShipping[loc]
  //   }
  // }
  const sumShipment = () => {
    if (selected.length > 0) {
      let total = 0
      for (let i = 0, _len = selected.length; i < _len; i++) {
        total += parseInt(selected[i]['TOTAL_SHIPMENT_COST'])
      }
      return total
    }
  }
  const sumQubic = (i) => {
    if (selected.length > 0) {
      let total = 0
      for (let j = 0, _lenp = selected[i]['PRODUCTS'].length; j < _lenp; j++) {
        total += (selected[i]['PRODUCTS'][j]['ITEM_TOTAL'] * selected[i]['PRODUCTS'][j]['PRODUCT_INFO'][0]['INFO_DIMENSION'])
      }
      return total.toFixed(2)
    }
  }
  const cartTotalShipment = selected.length > 0 ? sumShipment() : 0
  const cartTotalQty = selected.length > 0 ? sumQty() : 0
  const cartTotalPrice = selected.length > 0 ? sumPrice() : 0
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePaymentConfirmation = () => {
    setOpenPaymentConfirmation(false);
  };

  const [isLKPPAcc, setisLKPPAcc] = React.useState(false);

  // const rows = [
  //   {
  //     product: 'Kursi Kerja Eselon 1 & 2/Kepala/Ketua/Pimpinan (Astro High Back)',
  //     price: 3400000,
  //     qty: 1,

  //   },
  //   {
  //     product: 'Kursi Kerja ',
  //     price: 2500000,
  //     qty: 2,

  //   }
  // ];
  const handleIncrement = (event) => {
    if (!counter[event.target.name]) {
      setcounter({
        ...counter,
        [event.target.name]: 1
      })
    }
    if (counter[event.target.name] >= 1) {
      setcounter({
        ...counter,
        [event.target.name]: counter[event.target.name] + 1
      });

    }
  };

  const handleDecrement = (event) => {
    if (counter[event.target.name] !== 0) {
      setcounter({
        ...counter,
        [event.target.name]: counter[event.target.name] - 1
      });
    }
  };

  const handleCheckout = (event) => {
    setisCheckedout(true)
  };

  const dummy = [
    {
      province: "Jawa Barat",
      city: "Cimahi",
      postCode: "40521"
    },
    {
      province: "Banten",
      city: 'Tangerang',
      postCode: '15820'
    }
  ]

  useEffect(() => {
    if (dialogTypeSB === "Success") {
      setOpenConfirmation(true)
    } else if (dialogTypeSB === "Error") {
      setDialogTypeSB("error")
    }
  }, [dialogTypeSB])
  return (
    <React.Fragment>


      {/* Dialog Payment Confirmation */}
      {/* <Dialog                                                                       //Dialog Confirmation
        fullWidth
        maxWidth="xs"
        open={openPaymentConfirmation}
        onClose={handleClosePaymentConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong>
            {"Payment"}
          </strong>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TableContainer sx={{ marginBottom: 2 }}>
            <Table size='small' sx={{ width: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell sx={{ fontSize: 28 }} align="right"><strong>Rp. 3.800.000</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ backgroundColor: '#e8edf3' }}
                >
                  <TableCell>Order ID</TableCell>
                  <TableCell align="right">ORD-012345</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Tab />

          <Button fullWidth size='small' color='info' variant='contained' onClick={handleClose} autoFocus>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', width: '100%' }}>
              <div>Continue</div>
              <div><ArrowForwardIosIcon fontSize='12' /></div>
            </div>
          </Button>
        </DialogContent>
      </Dialog> */}
      {/* Confirmation */}
      <Dialog
        open={openConfirmation}
        // onClose={handleCloseConfirmation} 
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          <strong>Pesanan Dalam Proses</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Silahkan lakukan pembayaran pada nomor rekening di bawah.
          </DialogContentText>
          <Box
              sx={{
                py: 1, px: 2, my: 1, border: 'dashed 1px gray', borderRadius: '5px', width: '100%', backgroundColor: '#EBF2FF'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, width: '100%' }}>
                <div style={{ width: '35%' }}>
                  <Typography variant="body1" color="initial">Payment Method</Typography>
                  <Typography variant="body1" color="initial">Bank Name</Typography>
                  <Typography variant="body1" color="initial">Account Name</Typography>
                  <Typography variant="body1" color="initial">Account Number</Typography>
                </div>
                <div>
                  <Typography variant="body1" color="initial"><strong>Transfer Bank</strong></Typography>
                  <Typography variant="body1" color="initial"><strong>BRI</strong></Typography>
                  <Typography variant="body1" color="initial"><strong>FURNI KARYA MANDARI</strong></Typography>
                  <Typography variant="body1" color="initial"><strong>1145-01-000858</strong></Typography>
                </div>
              </div>
            </Box>
          <DialogContentText id="alert-dialog-description">
            Setelah melakukan pembayaran, harap menghubungi kami untuk melakukan konfirmasi pembayaran.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-evenly', paddingBottom: 2 }} >
          <Button sx={{width: '100%'}} size='small' color='info' variant='contained' onClick={() => { navigate('/furnitur/profile?act=transactionList') }} autoFocus>
            {language.COMMON.MY_TRANSACTION_TRACKING.CLOSE}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Content */}
      <form onSubmit={handleMakeOrder}>
        <Grid
          container spacing={2} >
          {selected.length > 0 ?
            selected.map((item, index) => {
              return (
                <>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                    <Paper className={classes.paperContent} >
                      <Typography>Your Order</Typography>
                        <TableContainer component={Paper} sx={{ marginTop: 3, marginBottom: 3 }}>
                          <Table size='small' sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                              <TableRow className={classes.tableHeader}>
                                <TableCell align="center"><strong>{language.COMMON.CHECK_OUT.PRODUCT}</strong></TableCell>
                                <TableCell align="center"><strong>{language.COMMON.CHECK_OUT.QUANTITY}</strong></TableCell>
                                <TableCell align="center"><strong>{language.COMMON.CHECK_OUT.PRODUCT_PRICE}</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item?.PRODUCTS.map((prod) => {
                                return (
                                  <TableRow
                                    className={classes.table}
                                    key={prod?.ITEM_NO}
                                  >
                                    <TableCell sx={{ width: '40%' }}>
                                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <div>
                                          <img
                                            alt=''
                                            className={classes.otherImageContent}
                                            onError={event => {
                                              event.target.src = noImage
                                              event.onerror = null
                                            }}
                                            src={`${baseUrlImage}/product/${prod?.PRODUCT_INFO?.[0]?.IMG_MAIN}`} />
                                        </div>
                                        <div>
                                          <Typography>
                                          {prod?.PRODUCT_INFO?.[0]?.ITEM_NAME}
                                          </Typography>
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell align="center"> {prod?.ITEM_TOTAL ?? 0}</TableCell>
                                    <TableCell align="center">{rupiahFormat(JSON.stringify(prod?.ITEM_PRICE * prod?.ITEM_TOTAL ?? 1), 'Rp')}</TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>

                      <Divider sx={{ paddingTop: 2, marginBottom: 2 }} />
                      <Grid container sx={{ justifyContent: 'flex-start', py: 3, width: '100%' }}>
                        <Grid item sx={{ width: '50%' }}>
                          <Typography sx={{ paddingBottom: 2 }}>{language.COMMON.CHECK_OUT.YOUR_SHIPPING_ADDRESS}</Typography>
                          <Typography variant="body1" color="initial"><strong>{datatables2?.[0]?.CUSTOMER_NAME}</strong></Typography>
                          <Typography>{datatables2?.[0]?.CP?.[0]?.CP_CONTACT}</Typography>
                          <Typography variant="body1" color="initial">
                            {`${item?.ADDRESS}, ${item?.ADDRESS_KABUPATEN}, ${item?.ADDRESS_PROVINSI} ${item?.ZIPCODE}`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ paddingBottom: 2 }} >{language.COMMON.CHECK_OUT.SHIPPING_COST}</Typography>
                          <Typography>{rupiahFormat(`${cartTotalShipment}`, 'Rp')}</Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xl lg md sm xs>
                    <Paper className={classes.paperContent}>
                      <Typography>
                      {language.COMMON.CHECK_OUT.PAYMENT_SUMMARY}
                      </Typography>
                      <Grid container sx={{ justifyContent: 'space-between', paddingTop: 3 }}>
                        <Grid item>
                          <Typography>{language.COMMON.CHECK_OUT.TOTAL_PRODUCT_PRICE}</Typography>
                          <Typography>{language.COMMON.CHECK_OUT.SHIPPING_COST}</Typography>
                        </Grid>
                        <Grid item sx={{ textAlign: 'right' }}>
                          <Typography>{rupiahFormat(`${cartTotalPrice}`, 'Rp')}</Typography>
                          <Typography>{rupiahFormat(`${cartTotalShipment}`, 'Rp')}</Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ paddingTop: 2, marginBottom: 2 }} />
                      <Grid container sx={{ justifyContent: 'space-between' }}>
                        <Grid item>
                          <Typography>
                            <strong>
                            {language.COMMON.CHECK_OUT.TOTAL_PAYMENT}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={18}>
                            <strong>
                              {rupiahFormat(`${(cartTotalShipment + cartTotalPrice)}`, 'Rp')}
                            </strong>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ paddingTop: 2, marginBottom: 2 }} />
                      <Grid>
                        <Typography>
                          Pesanan Anda sudah diterima oleh kami, kami akan segera menghubungi Anda
                        </Typography>
                        <Button
                          disabled={ item?.PRODUCTS?.length <= 0 }
                          sx={{ marginTop: 2, marginBottom: 2 }}
                          //  onClick={() => setOpenPaymentConfirmation(true)}
                          type='submit' size='small' color='info' variant="contained" fullWidth >
                          {language.COMMON.CHECK_OUT.BACK_TO_HOME}
                        </Button>
                      </Grid>
                    </Paper>
                  </Grid>
                </>
              )
            })
            : null
          }
        </Grid>
      </form>
    </React.Fragment>
  )
}

export default Index
