// Material helpers
import { createTheme } from '@mui/material';
import '@fontsource/roboto'
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Roboto';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
    MuiButton : {
      styleOverrides : overrides?.MuiButton,
      defaultProps: {
        size: 'small',
      },
    },
    MuiFilledInput: {
      styleOverrides : overrides?.MuiFilledInput,
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputBase: {
      styleOverrides: overrides.MuiInputBase,
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiChip: {
      styleOverrides: overrides?.MuiChip
    },
    MuiFab: {
      styleOverrides: overrides?.MuiFab,
    },
    MuiIconButton: {
      styleOverrides: overrides?.MuiIconButton
    },
    MuiLinearProgress: {
      styleOverrides: overrides?.MuiLinearProgress
    },
    MuiListItemIcon: {
      styleOverrides: overrides?.MuiListItemIcon
    },
    MuiOutlinedInput: {
      styleOverrides: overrides.MuiOutlinedInput
    },
    MuiSvgIcon: {
      styleOverrides: overrides.MuiSvgIcon
    },
    MuiTab: {
      styleOverrides: overrides?.MuiTab
    },
    MuiTableCell: {
      styleOverrides: overrides.MuiTableCell
    },
    MuiTableRow: {
      styleOverrides: overrides.MuiTableRow
    },
    MuiTabs: {
      styleOverrides: overrides?.MuiTabs
    },
    MuiTextField : {
      styleOverrides : {
        root : {
          margin: 'dense',
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#378BCC',
            },
            '&.Mui-disabled': {
              // color: palette.common.muted,
              background: '#efefef',
            },
          },
        }
      }, 
      defaultProps: {
        margin: 'dense',
        size: "small"
      },
    },
    MuiListItem: {
      styleOverrides: {
        root:{
          "&.Mui-selected": {
              // backgroundColor: "red",
              backgroundColor: palette.common.neutral,
              borderRight : '3px solid #181339',
              "&:hover": {
                backgroundColor: palette.common.neutral,
              }
        },
        '&:hover':{
          backgroundColor: palette.common.neutral,
          borderRight : '3px solid #181339'
        }
      },
      selected:{}
      }
    }
  },
  // overrides,
  // spacing: factor => `${0.25 * factor}rem`,
  props: {
    MuiButton: {
      size: 'small',
    },
    MuiFilledInput: {
      margin: 'dense',
    },
    // MuiFormControl: {
    //   margin: 'dense',
    // },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    // MuiInputBase: {
    //   margin: 'dense',
    // },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiFab: {
      size: 'small',
    },
    MuiTable: {
      size: 'small',
    },
    // MuiTextField: {
    //   margin: 'dense',
    // },
    MuiToolbar: {
      variant: 'dense',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      mid: 1366,
    },
  },
});

export default theme;
