// Palette
import palette from '../palette';

const MuiFab = {
  root: {
    backgroundColor: palette.common.black,
    color: palette.text.secondary,
    '&:hover': {
      backgroundColor: palette.common.neutral
    }
  }
};

export default MuiFab