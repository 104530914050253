import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    container: {
        paddingLeft: theme.spacing(10),
		paddingRight: theme.spacing(10),
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(5),
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(5),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    image: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: 5

    },
    imageContent: {
        width: 400,
        height: 400,
        borderRadius: '10px',
        [theme.breakpoints.down('lg')]: {
            width: 220,
            height: 220,
        },
        [theme.breakpoints.down('md')]: {
            width: 150,
            height: 150,
        },

    },
    otherImageContent: {
        borderRadius: '10px',
        marginLeft: 3,
        marginRight: 3,
        width: "70px",
        height: "auto",
        cursor: 'pointer',
        "&:hover": {
            border: 'solid 1px #20376D'
        },
    },
    content: {
        paddingTop: '10px'
    },
    textContent: {
        textAlign: 'left'
    }
}));
