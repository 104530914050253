export const styleCommon = {
      titleDark: {
        // backgroundColor: theme.palette.primary.main,
        color: '#0C2146',
        fontSize: '20px',
        textAlign: 'left',
        marginLeft: '10px',
        fontWeight: 'bold'
      },
      titleSuccess: {
        // backgroundColor: theme.palette.primary.main,
        color: '#329C53',
        fontSize: '20px',
        textAlign: 'left',
        marginLeft: '10px',
        fontWeight: 'bold'
      },
      titleError: {
        // backgroundColor: theme.palette.primary.main,
        color: '#74312D',
        fontSize: '20px',
        textAlign: 'left',
        marginLeft: '10px',
        fontWeight: 'bold'
      },
      iconInfo: {
        fontSize: '15px',
        color: '#0C2146',
        marginLeft: '20px',
        width: 30,
        height: 30
        // backgroundColor: theme.palette.primary.main
      },
      iconSuccess: {
        fontSize: '15px',
        color: '#329C53',
        marginLeft: '20px',
        width: 30,
        height: 30
        // backgroundColor: theme.palette.primary.main
      },
      iconError: {
        fontSize: '15px',
        color: '#74312D',
        marginLeft: '20px',
        width: 30,
        height: 30
        // backgroundColor: theme.palette.primary.main
      },
      messageBox: {
        width: '100%',
        // display: 'flex',
        // justifyContent: 'center',
        // alignContent: 'center',
        // textAlign: 'center',
        color: 'black',
        
      },
      message: {
        // border: '2px solid #000000',
        margin: '30px 50px',
        fontSize: '17px',
        // display: 'flex',
        // justifyContent: 'center',
        // alignContent: 'center',
        // // fontSize: '16px',
        // textAlign: 'center',
        // fontWeight: 'bold',
        color: 'black',
        // padding: theme.spacing(3)
      },
}