import React, { useState, useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import {
  List,
  ListItemButton,
  ListItemIcon,
  Collapse,
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import queryString from 'query-string'
import { useFunctionality, useRange, useSpecification, useCategory, useLanguage } from 'hooks'
import { useNavigate, useLocation } from 'react-router'

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    paddingTop: '10px'
  },
  filter: {
    width: '100%',
    height: "auto",
    paddingLeft: '10px',

  },
  filterPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingRight: theme.spacing(10)
  },
  breadcrumb: {
    fontSize: '24px'
  },
  card: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    '&:hover': {
      border: '1px solid #E2E2E2'
    },
    [theme.breakpoints.down('md')]: {
      width: 180,
    },
    [theme.breakpoints.down('sm')]: {
      width: 150,
      padding: theme.spacing(1, 0),
    },
  },
  image: {
    width: 150,
    height: 150,
    [theme.breakpoints.down('md')]: {
      width: 120,
      height: 120,
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
      height: 100,
    },
  },
  activeItem: {
    backgroundColor: '#4A84AA',
    fontWeight: 'bold'
  }

}))

function Index(props) {
  const classes = useStyles()
  const {
    handleFilter,
    setFilterSpec,
    filterSpec,
    open,
    setOpen,
    selectedItem, 
    setselectedItem
  } = props

  // const [open, setOpen] = React.useState({});
  console.log("🚀 ~ file: index.jsx ~ line 89 ~ Index ~ open", open)
  const [language, lagDic, setLang] = useLanguage()
  // const [selectedItem, setselectedItem] = useState({});
  console.log("🚀 ~ file: index.jsx ~ line 90 ~ Index ~ selectedItem", selectedItem)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [error, setError] = useState(null);
  const [openHooks, setOpenHooks] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables] = useCategory(setIsLoading, setError, setOpenHooks, setDialogType, setTitle, setMessage)
  console.log("🚀 ~ file: index.jsx ~ line 111 ~ Index ~ datatables", datatables)
  const [datatables2, setDatatables2] = useRange(setIsLoading2, setError, setOpenHooks, setDialogType, setTitle, setMessage)
  const [datatables3, setDatatables3] = useSpecification(setIsLoading3, setError, setOpenHooks, setDialogType, setTitle, setMessage)


  const location = useLocation()
  const queryParse = queryString.parse(location.search)


  useEffect(() => {
    const param = {
      REF_ID: selectedItem?.category,
      page: 1,
      limit: 100
    }
    setDatatables3(param)

  }, [selectedItem?.category])

  useEffect(() => {
    const param = {
      page: 1,
      limit: 100
    }
    setDatatables(param)

  }, [])

  const handleClick = (id, val) => {
    setOpen({ ...open, [id]: val });
  };

  const handleMultipleSelect = (val) => {
    if (filterSpec.length > 0) {

      if (filterSpec.includes(val)) {
        const arr = filterSpec.filter((item) => {
          return item !== val
        })
        setFilterSpec(arr)
      } else {
        setFilterSpec([...filterSpec, val])
      }
      // if(filterSpec.includes(val)){
      //   const wordIndex = filterSpec.indexOf(val)
      //   delete filterSpec[wordIndex]
      //   console.log("🚀 ~ file: index.jsx ~ line 151 ~ handleMultipleSelect ~ filterSpec", filterSpec)
      //   console.log("🚀 ~ file: index.jsx ~ line 152 ~ handleMultipleSelect ~ wordIndex", wordIndex) 
      // }else {
      //   setFilterSpec([...filterSpec, val])
      // }
    } else {
      setFilterSpec([...filterSpec, val])
    }
  }

  return (
    <React.Fragment>
      <br />
      <strong>{language.COMMON.PRODUCT_LIST.FILTER_BY_CATEGORY}</strong>

      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {datatables.length > 0
          ?
          datatables?.map((list, idx) => (
            <div style={{ width: "100%" }}>

              <ListItemButton
                sx={{ height: 40 }}
                onClick={() => {
                  setselectedItem({
                    ...selectedItem,
                    ['category']: list?.CATEGORY_CODE
                  })
                  // handleClick(list?.CATEGORY_CODE, !open?.[list?.CATEGORY_CODE]);
                  handleFilter('CATEGORY', list?.CATEGORY_CODE)
                }}
                className={list?.CATEGORY_CODE === selectedItem.category ? classes.activeItem : ''}
              >
                {!list?.child ? null :
                  <ListItemIcon >
                    {open?.[list?.id] ? <ExpandLess sx={{ fontSize: "16px", }} /> : <ExpandMore sx={{ fontSize: "16px" }} />}
                  </ListItemIcon>
                }
                <p style={{ fontSize: "12px" }} >{list?.CATEGORY_NAME}</p>
              </ListItemButton>
              {!list?.child ? null :
                <Collapse in={open?.[list?.id]} timeout="auto" unmountOnExit>
                  {list?.child.map(child =>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <p style={{ fontSize: "11px" }} >{child.label}</p>
                        {/* <ListItemText primary={child.label} /> */}
                      </ListItemButton>
                    </List>
                  )
                  }
                </Collapse>
              }
            </div>
          ))
          : null
        }
      </List>



      <strong>{language.COMMON.PRODUCT_LIST.FILTER_BY_SPECIFICATION}</strong>

      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {datatables3?.map((list, idx) => (
          <div style={{ width: "100%" }}>

            <ListItemButton
              className={classes.list}
              sx={{ height: 40 }}
              onClick={() => {
                setselectedItem({
                  ...selectedItem,
                  ['spec']: list?.CATEGORY_CODE
                })
                handleClick(list?.CATEGORY_CODE, !open?.[list?.CATEGORY_CODE]);
                // handleFilter('functionality', list?.CATEGORY_CODE)
              }}
            // className={list?.CATEGORY_CODE === selectedItem.functionality ? classes.activeItem : ''}
            >
              {!list?.LIST_SPECIFICATION_DETAIL.length > 0 ? null :
                <ListItemIcon >
                  {open?.[list?.CATEGORY_CODE] ? <ExpandLess sx={{ fontSize: "16px", }} /> : <ExpandMore sx={{ fontSize: "16px" }} />}

                </ListItemIcon>
              }
              <p style={{ fontSize: "12px" }} >{list?.CATEGORY_NAME}</p>
            </ListItemButton>
            {!list?.LIST_SPECIFICATION_DETAIL.length > 0 ? null :
              <Collapse in={open?.[list?.CATEGORY_CODE]} timeout="auto" unmountOnExit>
                {list?.LIST_SPECIFICATION_DETAIL.map(child =>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{
                      pl: 4,
                      height: 40,
                      // '&$selected': {
                      //   backgroundColor: 'red',
                      //   fontWeight: 'bold',
                      //   '&:hover': {
                      //     backgroundColor: 'yellow',
                      //   }
                      // },

                    }}
                      selected={open?.[child?.CATEGORY_CODE]}
                      classes={{ selected: classes.activeItem }}
                      onClick={() => {
                        // setselectedItem({
                        //   ...selectedItem,
                        //   ['spec']: list?.CATEGORY_CODE
                        // })
                        handleClick(child?.CATEGORY_CODE, !open?.[child?.CATEGORY_CODE]);
                        handleMultipleSelect(child?.CATEGORY_CODE)
                        // setFilterSpec([...filterSpec, child?.CATEGORY_CODE])
                      }}
                    >
                      <ListItemIcon>
                      </ListItemIcon>
                      <p style={{ fontSize: "11px" }} >{child.CATEGORY_NAME}</p>
                      {/* <ListItemText primary={child.label} /> */}
                    </ListItemButton>
                  </List>
                )
                }
              </Collapse>
            }
          </div>
        ))}
      </List>
    </React.Fragment>
  )
}

export default Index
