import React from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import useStyles from './styles'
import { Skeleton } from '@mui/material'
import chairsExample2 from "assets/images/chair3.jpg"
import { rupiahFormat } from 'helpers'
import { useNavigate, useLocation } from 'react-router'
import queryString from 'query-string'
import noImage from "assets/images/noImage.jpeg"
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Tooltip
} from '@mui/material'
import { useLanguage } from 'hooks'


function PopularProducts(props) {

  const {
    rows
  } = props
  const [language, lagDic, setLang] = useLanguage()
  const classes = useStyles()
  const str = 'Kursi Kerja Eselon 1 & 2/kepala/Ketua/Pemimpin (Astro Hight)'
  const navigate = useNavigate()

  const location = useLocation()
  const queryParse = queryString.parse(location.search)

  const handlePush = (type, submit) => {
    const query = {

      act: type,
      PRODUCT_NAME: submit?.VALUE_04,
      PRODUCT_CODE: submit?.VALUE_02
    };
    const searchString = queryString.stringify(query);
    navigate({
      pathname: 'product',
      search: searchString
    })
  }
  const baseUrlImage = window._env_?.BASEURL_IMAGE
  return (
    <div className={classes.container}>
      <h1>{language.COMMON.CONTENT.POPULAR_PRODUCT}</h1>
      <Divider></Divider>
      {rows.length > 0 ?
      <Box sx={{overflowX: "auto", '&::-webkit-scrollbar': {
        display: 'none'
      },}}>
        <Grid container sx={{ marginTop: '15px', width: {xs: "1200px", sm:"1200px", md:"1800px"}}} rowSpacing={3} columnSpacing={{ xl: 2 }}>
          {rows.map((item) => {
            console.log("🚀 ~ file: index.jsx ~ line 42 ~ {rows.map ~ item", item?.PRODUCT_INFO?.[0])

            return (
              <Grid xl={2} lg={2} md={2} sm={2} xs={2} sx={{ display: 'flex', justifyContent: 'center', px: -5, mb: 5 }}>

                <Card sx={{ width: 180 }}>
                  <Tooltip title={item?.VALUE_04}>
                    <CardActionArea
                      onClick={(e) => handlePush('Detail', item)}
                    >
                      <CardMedia
                        component="img"
                        height={120}
                        width={60}
                        sx={{ objectFit: 'scale-down', mt: 3 }}
                        src={`${baseUrlImage}${item?.PRODUCT_INFO?.[0]?.EXTRA_IMAGES?.[0]?.IMG_PATH}${item?.PRODUCT_INFO?.[0]?.EXTRA_IMAGES?.[0]?.IMG_THUMB}`}
                        onError={event => {
                          event.target.src = noImage
                          event.onerror = null
                        }}
                        alt=""
                      />
                      <CardContent>
                        <Typography className={classes.multiLineEllipsis} variant="h5" >
                          {item?.VALUE_04}
                        </Typography>
                        {item?.PRODUCT_INFO?.[0]?.IS_DISCOUNT ?
                          <div style={{ textDecoration: 'line-through', lineHeight: 1.6 }}>
                            <Typography color={'red'}>{rupiahFormat(`${item?.PRODUCT_INFO?.[0]?.SELL_PRICE}`, 'Rp')}</Typography>
                          </div>
                          : null
                        }
                        {item?.PRODUCT_INFO?.[0]?.IS_DISCOUNT
                          ? <Typography fontSize={16} color={'#4a84aa'}> <strong>{rupiahFormat(`${item?.PRODUCT_INFO?.[0]?.SELL_PRICE_DISCOUNT}`, 'Rp')} </strong></Typography>
                          : <Typography fontSize={16} color={'#4a84aa'}> <strong>  {rupiahFormat(`${item?.PRODUCT_INFO?.[0]?.SELL_PRICE}`, 'Rp')}  </strong></Typography>
                        }
                      </CardContent>
                    </CardActionArea>
                  </Tooltip>
                </Card>
              </Grid>
            )
          }
          )}
        </Grid>
      </Box>
      :
        <Grid container style={{ marginTop: '15px' }} rowSpacing={3} columnSpacing={{ xl: 2 }}>
          <Typography>{language.COMMON.CONTENT.EMPTY_PRODUCT}</Typography>
        </Grid>
      }
    </div>
  )
}

export default PopularProducts
