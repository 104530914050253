import { getAccessLog } from "helpers"

const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export function structuredefault(values, handleFieldChange, open, categoryOption, lang) {
  const data = [
    {
      id: 'Name',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Name"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Position',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.POSITION,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Position',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Position"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'NIP',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.NIP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert NIP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["NIP"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Email',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Email',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Email"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Phone Number',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.PHONE_NO,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Phone Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Phone Number"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Cerificate PBJ Number',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.CERTIFICATE_PJB,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Cerificate PBJ Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Cerificate PBJ Number"] || '',
      minWidth: 170,
      // hide: isHide
    },

  ]

  return data
};
export function structuredefault2(values, handleFieldChange, open, categoryOption, lang) {
  const data = [
    {
      id: 'NamePPK',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: getAccessLog('role') === 'PPK' ? true : false,
      placeholder: 'Insert Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["NamePPK"] || '-',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PositionPPK',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.POSITION,
      type: 'text',
      fieldtype: 'textfield',
      disabled: getAccessLog('role') === 'PPK' ? true : false,
      placeholder: 'Insert Position',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PositionPPK"] || '-',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'NIPPPK',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.NIP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: getAccessLog('role') === 'PPK' ? true : false,
      placeholder: 'Insert NIP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["NIPPPK"] || '-',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'EmailPPK',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      disabled: getAccessLog('role') === 'PPK' ? true : false,
      placeholder: 'Insert Email',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["EmailPPK"] || '-',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Phone NumberPPK',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.PHONE_NO,
      type: 'text',
      fieldtype: 'textfield',
      disabled: getAccessLog('role') === 'PPK' ? true : false,
      placeholder: 'Insert Phone Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Phone NumberPPK"] || '-',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Cerificate PBJ NumberPPK',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.CERTIFICATE_PJB,
      type: 'text',
      fieldtype: 'textfield',
      disabled: getAccessLog('role') === 'PPK' ? true : false,
      placeholder: 'Insert Cerificate PBJ Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Cerificate PBJ NumberPPK"] || '-',
      minWidth: 170,
      // hide: isHide
    },
  ]

  return data
};