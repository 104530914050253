import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Grid,
  TextField,
  Typography,
  ButtonGroup,
  Button,
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  IconButton,
  Select,
  MenuItem
} from '@mui/material'
import NumberFormat from 'react-number-format';
import SearchIcon from '@mui/icons-material/Search';
import noImage from "assets/images/noImage.jpeg"
import { rupiahFormat, getDayNow, getAccessLog } from 'helpers'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import moment from 'moment'
import { useCart, useProfile, useAddress, useLookup, useInput, useLanguage } from 'hooks'
import { useSelector } from 'react-redux';
import { validateSign } from 'controllers/_validate';
import DefaultStructure from "./defaultStructure"
import { InputComponent } from 'components';


function Index(props) {
  const {
    setisCheckedout,
    handleSubmit,
    data,
    setafterUpdate,
    afterUpdate,
    open,
    setOpen,
  } = props
  const classes = useStyles();
  const [language, lagDic, setLang] = useLanguage();
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [handleFieldChange, values] = useInput(validateSign, {})
  const [datatables, setDatatables, getDetail] = useCart(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables2, setDatatables2, getData] = useProfile(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [lookup, setLookup] = useLookup(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage);
  const [datas, setDatas, makeDatas] = useAddress(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage);
  const [list, setList] = useState([])
  const [subTotal, setSubTotal] = useState({});
  const [provinsiOptions, setProvinsiOptions] = useState([])
  const [kabupatenOptions, setKabupatenOptions] = useState([])
  const [addresses, setAddresses] = useState([]);
  const [actualAddress, setActualAddress] = useState([])
  const [selectedAddress, setSelectedAddress] = useState({});
  const [onCreateAddress, setOnCreateAddress] = useState(false)
  const [cartTotalQty, setCartTotal] = useState(0)
  const [cartTotalShipment, setCartTotalShipment] = useState(0)
  const [cartTotalPrice, setCartTotalPrice] = useState(0)
  
  // useEffect(() => {                             // <-------- dummy
  //   setCartTotalShipment(300000)
  // },[data])
  Array.prototype.sum = function (prop, ast) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += (this[i][prop] * this[i][ast])
    }
    return total
  }
  Array.prototype.sumQty = function (prop) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop]
    }
    return total
  }
  useEffect(() => {
    data.map((item) => {
      setSubTotal((prevState) => ({
        ...prevState,
        [item?.ITEM_NO]: item?.ITEM_PRICE * item?.ITEM_TOTAL
      }));
    })
  },[data])
  // useEffect(() => {
  //   const param = {
  //     page: 1,
  //     limit: 1000
  //   }
  //   setLookup(param, 'provinsi')
  // }, [setLookup])

  // useEffect(() => {
  //   if (values?.ADDRESS_PROVINSI) {
  //     const param = {
  //       "ADDRESS_PROVINSI": values?.ADDRESS_PROVINSI
  //     }
  //     setLookup(param, 'kabupaten')
  //   }
  // }, [values?.ADDRESS_PROVINSI])
  // useEffect(() => {
  //   if (lookup?.provinsi) {
  //     setProvinsiOptions(lookup?.provinsi?.data ?? [])
  //   }
  //   if (lookup?.kabupaten) {
  //     setKabupatenOptions(lookup?.kabupaten?.data ?? [])
  //   }
  // }, [lookup])
  // useEffect(() => {
  //   // const arr = dataProfile?.[0]?.ADDRESS.filter((item) => {
  //   //   return item?.ID !== addresses?.find((val) => val?.ID)
  //   // })
  //   if (addresses?.length > 0) {
  //     let result = []
  //     addresses?.map((val) => {
  //       datatables2?.[0]?.ADDRESS?.map((item) => {
  //         if (val?.ID !== item?.ID) {
  //           result?.push(item)
  //         }
  //       })
  //     })
  //     setActualAddress(result)
  //   } else {
  //     setActualAddress(datatables2?.[0]?.ADDRESS)
  //   }
  // }, [addresses, datatables2])
  // useEffect(() => {
  //   actualAddress?.map((item) => {
  //     if (item.IS_CURRENT === true) {
  //       setSelectedAddress(item)
  //     }
  //   })
  // }, [actualAddress])

  // const handleCreate = (e) => {
  //   e.preventDefault()

  //   setOnCreateAddress(true)
  //   const param = {
  //     "CUSTOMER_NO": datatables2?.[0]?.CUSTOMER_NO,
  //     "REFERENCE_OF": "CUSTOMER",

  //     "ADDRESS_NAME": values?.ADDRESS_NAME,

  //     "ADDRESS_PROVINSI": values?.ADDRESS_PROVINSI,
  //     "ADDRESS_KABUPATEN": values?.ADDRESS_KABUPATEN,
  //     "ADDRESS_KECAMATAN": "",
  //     "ADDRESS_KELURAHAN": "",
  //     "ADDRESS": values?.ADDRESS,
  //     "ZIPCODE": values?.ZIPCODE,
  //     "IS_CURRENT": values?.IS_CURRENT ? values?.IS_CURRENT === 'YES' ? true : false : false,

  //     "ACTION_BY": getAccessLog('username'),
  //     "ACTION_DATE": getDayNow('complate'),
  //     "ADDITIONAL_INFO": "",
  //     "IS_DUMP": false,
  //     "ACTIVE_STATUS": true
  //   }
  //   console.log("🚀 ~ file: index.jsx ~ line 141 ~ handleCreateAddress ~ param", param)
  //   makeDatas(param, 'create')
  //   const param2 = {
  //     CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
  //     // CUSTOMER_NAME: "Maudy A",
  //     // CUSTOMER_NO: "00000009",
  //     USERNAME: getAccessLog('username'),
  //     page: 1,
  //     limit: 100
  //   }
  //   setDatatables2(param2)
  // }

  // const handleChangeAddress = (event) => {
  //   setSelectedAddress(event.target.value)
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePaymentConfirmation = () => {
    setOpenPaymentConfirmation(false);
  };

  const [isLKPPAcc, setisLKPPAcc] = React.useState(false);

  const today = moment().format('YYYY-MM-DD HH:mm:ss.ms');


  const dummy = [
    {
      ACTIVE_STATUS: true,
      ADDRESS: "One Pm Building, Jl. Gading Serpong Boulevard No.18, Pakulonan Bar., Kec. Klp. Dua",
      ADDRESS_KABUPATEN: "Kabupaten Tangerang",
      ADDRESS_NAME: "Kantor",
      ADDRESS_PROVINSI: "Banten",
      ID: 1,
      IS_CURRENT: true,
      IS_DUMP: false,
      META: {},
      REFERENCE_OF: "CUSTOMER",
      ZIPCODE: "15810"
    },
    {
      ACTIVE_STATUS: true,
      ADDRESS: "Jalan Sangkuriang, Cimahi, Jawa Barat, 14520. \n\nLorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi saepe consectetur consequatur eaque! Quidem laboriosam provident exercitationem architecto repudiandae? Consequatur, assumenda. Obcaecati deleniti, laudantium molestias officia nam sit nostrum est?",
      ADDRESS_KABUPATEN: "Cimahi",
      ADDRESS_NAME: "Kantor",
      ADDRESS_PROVINSI: "Jawa Barat",
      ID: 2,
      IS_CURRENT: true,
      IS_DUMP: false,
      META: {},
      REFERENCE_OF: "CUSTOMER",
      ZIPCODE: "14520"
    }
  ]
  const [counter, setcounter] = useState({})
  console.log("🚀 ~ file: index.jsx ~ line 76 ~ Index ~ counter", counter)
  const handleDelete = (id) => {

    const param = {
      ID: id,
      ACTION_BY: getAccessLog('username')
    }
    getDetail(param, 'delete')
    if (list?.length === 1) {
      setList([])
      setCartTotal(0)
      setCartTotalPrice(0)
    }

    setafterUpdate(!afterUpdate)

  }
  const handleInputQty = (value, row) => {
    if (value !== "") {
      if (value > 999) {
        value = 999
      }
      else {
        setcounter((prevState) => ({
          ...prevState,
          [row?.ITEM_NO]: parseInt(value)
        }));
      }
      const param = {
        ...row
      }
      param['ITEM_TOTAL'] = parseInt(value)
      param['ACTION_DATE'] = today
      param['ACTION_BY'] = getAccessLog('username')
      getDetail(param, 'update')
      setafterUpdate(!afterUpdate)
    }
  }
  const handleIncrement = (event, row) => {
    if (counter[event.target.name] < 999) {
      if (!counter[event.target.name]) {
        setcounter({
          ...counter,
          [event.target.name]: row?.ITEM_TOTAL + 1
        })
        const param = {
          ...row
        }
        param['ITEM_TOTAL'] = row?.ITEM_TOTAL + 1
        param['ACTION_DATE'] = today
        param['ACTION_BY'] = getAccessLog('username')
        console.log("🚀 ~ file: index.jsx ~ line 85 ~ handleIncrement ~ param", param)
        getDetail(param, 'update')
        setafterUpdate(!afterUpdate)
      }
      if (counter[event.target.name] >= 1) {
        setcounter({
          ...counter,
          [event.target.name]: counter[event.target.name] + 1
        });
        const param = {
          ...row
        }
        param['ITEM_TOTAL'] = counter[event.target.name] + 1
        param['ACTION_DATE'] = today
        param['ACTION_BY'] = getAccessLog('username')
        console.log("🚀 ~ file: index.jsx ~ line 85 ~ handleIncrement ~ param", param)
        getDetail(param, 'update')
        setafterUpdate(!afterUpdate)

      }
    }
  };


  const handleDecrement = (event, row) => {
    if  (counter[event.target.name] >= 0) {
      if (!counter.hasOwnProperty(event.target.name)) {
        console.log('kalogaada')
        setcounter({
          ...counter,
          [event.target.name]: row?.ITEM_TOTAL - 1
        })
        const param = {
          ...row
        }
        param['ITEM_TOTAL'] = row?.ITEM_TOTAL - 1
        param['ACTION_DATE'] = today
        param['ACTION_BY'] = getAccessLog('username')
        console.log("🚀 ~ file: index.jsx ~ line 85 ~ handleIncrement ~ param", param)
        getDetail(param, 'update')
        setafterUpdate(!afterUpdate)
      }
      else if (counter[event.target.name] != 0) {
        console.log('kalo bukan 0')
        setcounter({
          ...counter,
          [event.target.name]: counter[event.target.name] - 1
        });
        const param = {
          ...row
        }
        param['ITEM_TOTAL'] = counter[event.target.name] - 1
        param['ACTION_DATE'] = today
        param['ACTION_BY'] = getAccessLog('username')
        console.log("🚀 ~ file: index.jsx ~ line 85 ~ handleIncrement ~ param", param)
        getDetail(param, 'update')
        setafterUpdate(!afterUpdate)
      }
    }
  };

  const handleCheckout = (event) => {
    setisCheckedout(true)
  };

  useEffect(() => {
    if (data.length > 0) {
      data?.map((item) => {
        console.log("🚀 ~ file: index.jsx ~ line 110 ~ data?.map ~ item", item.ITEM_NO)
        setcounter({
          ...counter,
          [item?.ITEM_NO]: item?.ITEM_TOTAL
        })
      })
    }
  }, [data])
  useEffect(() => {
    data.map((item) => {
      setcounter((prevState) => ({
        ...prevState,
        [item?.ITEM_NO]: item?.ITEM_TOTAL
      }));
    })
    if (data?.length > 0) {
      let result = []
      if (list?.length > 0) {
        list?.map((val) => {
          const index = data.findIndex(object => {
            return object.ITEM_NO === val?.ITEM_NO;
          });
          const insert = (arr, index, newItem) => [
            // part of the array before the specified index
            ...arr.slice(0, index),
            // inserted item
            newItem,
            // part of the array after the specified index
            ...arr.slice(index)
          ]
          let arr = data?.filter(function (el) {
            return el?.ITEM_NO !== val?.ITEM_NO;
          });
          result = insert(arr, index, val)
        })
        setList(data.sort((a, b) => a.ITEM_NO.localeCompare(b.ITEM_NO)))
        setCartTotal(data?.sumQty('ITEM_TOTAL') ?? 0)
        setCartTotalPrice(data?.sum('ITEM_PRICE', 'ITEM_TOTAL') ?? 0)
      } else {
        setList(data)
        setCartTotal(data?.sumQty('ITEM_TOTAL') ?? 0)
        setCartTotalPrice(data?.sum('ITEM_PRICE', 'ITEM_TOTAL') ?? 0)
      }
    } else {
      if (list?.length === 1) {
        let result = []
        list?.map((val) => {
          const index = data.findIndex(object => {
            return object.ITEM_NO === val?.ITEM_NO;
          });
          const insert = (arr, index, newItem) => [
            // part of the array before the specified index
            ...arr.slice(0, index),
            // inserted item
            newItem,
            // part of the array after the specified index
            ...arr.slice(index)
          ]
          let arr = data?.filter(function (el) {
            return el?.ITEM_NO !== val?.ITEM_NO;
          });
          result = insert(arr, index, val)
        })
        setCartTotal(data?.sumQty('ITEM_TOTAL') ?? 0)
        setCartTotalPrice(data?.sum('ITEM_PRICE', 'ITEM_TOTAL') ?? 0)
      }
    }
  }, [data])


  const baseUrlImage = window._env_?.BASEURL_IMAGE
  return (
    <React.Fragment>
      {/* Content */}
      <Grid
        container spacing={2} >
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <TableContainer component={Paper}>
            <Table size='small' sx={{ width: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="center"><strong>{language.COMMON.CART.PRODUCT}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.PRICE}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.QUANTITY}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.SUBTOTAL}</strong></TableCell>
                  <TableCell sx={{ width: '5%' }} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row) => (
                  <TableRow
                    className={classes.table}
                    key={row?.ITEM_NO}
                  >
                    <TableCell sx={{ width: '40%' }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div>
                          <img
                            alt=''
                            className={classes.otherImageContent}
                            onError={event => {
                              event.target.src = noImage
                              event.onerror = null
                            }}
                            src={`${baseUrlImage}/product/${row?.PRODUCT_INFO?.[0]?.IMG_MAIN}`} />
                        </div>
                        <div>
                          <Typography>
                            {row?.PRODUCT_INFO?.[0]?.ITEM_NAME}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">{rupiahFormat(JSON.stringify(row?.ITEM_PRICE), 'Rp')}</TableCell>
                    <TableCell variant='body' sx={{ alignItems: "flex-start", verticalAlign: 'top' }} align="center">
                      <ButtonGroup style={{ color: 'clarity', border: 'solid 1px #aeb7cb' }} size="small" aria-label="small outlined button group">
                        <Button style={{ color: 'clarity', border: '0px' }} name={row?.ITEM_NO} onClick={(event) => { handleDecrement(event, row) }}>-</Button>
                          <NumberFormat
                            style={{ width: 40, fontSize: 11, border: '0px' }}
                            value={counter[row?.ITEM_NO]}
                            onChange={(event) => {
                              console.log("🚀 ~ file: index.jsx ~ line 310 ~ Index ~ value", event.target.value)
                              handleInputQty(event.target.value, row)
                            }}
                            onFocus={event => {
                              event.target.select();
                            }}
                            customInput={TextField}
                            inputProps={{ min: 0, style: { textAlign: 'center' } }}
                            InputProps={{ disableUnderline: true }}
                            margin='dense'
                            size='small'
                            variant='standard'
                            displayType="input"
                            type="text"

                            allowNegative={false} />
                        <Button style={{ color: 'clarity', border: '0px' }} name={row?.ITEM_NO} onClick={(event) => { handleIncrement(event, row) }} >+</Button>
                      </ButtonGroup></TableCell>
                    <TableCell align="center">{rupiahFormat(`${row?.ITEM_PRICE && row?.ITEM_TOTAL ? subTotal[row?.ITEM_NO] : 0}`, 'Rp')}</TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => { handleDelete(row?.ID) }} aria-label="delete">
                        <DeleteIcon fontSize='small' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xl lg md sm xs>
          <Paper className={classes.paperContent}>
            <React.Fragment>
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <Typography>{language.COMMON.CART.CART_TOTAL}</Typography>
                </Grid>
                <Grid item sx={{ textAlign: 'right' }}>
                  <Typography>{cartTotalQty}</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item>
                  {/* <Typography>{language.COMMON.CHECK_OUT.SHIPPING_COST}</Typography> */}
                  <Typography>{language.COMMON.CHECK_OUT.TOTAL_PRODUCT_PRICE}</Typography>
                </Grid>
                <Grid item sx={{ textAlign: 'right' }}>
                  {/* <Typography>{rupiahFormat(`${cartTotalShipment}`, 'Rp')}</Typography> */}
                  <Typography>{rupiahFormat(`${cartTotalPrice}`, 'Rp')}</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ paddingTop: 2, marginBottom: 2 }} />
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <Typography>
                    <strong>
                    {language.COMMON.CHECK_OUT.TOTAL_PAYMENT}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize={18} sx={{ textAlign: 'right' }}>
                    <strong>
                    {rupiahFormat(`${cartTotalPrice}`, 'Rp')}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
              <Button
                disabled={ list.length <= 0}
                sx={{ marginTop: 2, marginBottom: 2 }} onClick={() => { handleSubmit('shippingAddress') }} size='small' color='info' variant="contained" fullWidth >
              {language.COMMON.CART.PROCEED_TO_TRANSACTION}
              </Button>
            </React.Fragment>



          </Paper>
        </Grid>
      </Grid>

    </React.Fragment>
  )
}

export default Index
