import React from 'react'
import useStyles from './styles'
import { Divider, Grid, Typography } from '@mui/material'
import { Call, Mail, WhatsApp } from '@mui/icons-material';
import mandiriIcon from 'assets/images/bank-mandiri.svg'
import briIcon from 'assets/images/logo_bri.png'
import bcaIcon from 'assets/images/bca-bank.svg'
import { getAccessLog, getDayNow } from 'helpers';
import { useNavigate } from 'react-router'
import { useLanguage } from 'hooks';
import footerLogo from 'assets/images/footer-logo.webp'
import { styled } from '@mui/styles';
console.log("🚀 ~ file: index.jsx ~ line 8 ~ getDayNow", getDayNow())

function Footer(props) {
  console.log("🚀 ~ file: index.jsx ~ line 11 ~ Footer ~ props", props)
  const classes = useStyles()
  const [language, lagDic, setLang] = useLanguage()
  const navigate = useNavigate()
  const LogoFooter = styled('img')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      position: "absolute", 
      right: 60, 
      bottom: 80,
      width: "50%"
    },
    [theme.breakpoints.down('md')]: {
      margin: "20px 0",
      position :'relative',
      width: "100%",
      alignSelf: "center"
    },
  }));
  return (
    <div className={classes.container} style={{position:'relative'}}>
      <Grid container >
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12} >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
            <p className={classes.title}>{language.COMMON.FOOTER.CONTACT_INFORMATION}</p>
            <p className={classes.body}>{language.COMMON.FOOTER.SEE_WHY}</p>
            {/* <p className={classes.body}>Our team are ready to help with any enquiry you may have</p> */}
            <p className={classes.bodyIcon}> <Call /> {props?.contact?.VALUE_04}</p>
            <p className={classes.bodyIcon}> <Mail /> {props?.contact?.VALUE_06}</p>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
            <p className={classes.title}>{language.COMMON.FOOTER.CONSUMER_COMPLAINT}</p>
            <p className={classes.body}>Direktorat Jenderal perlindungan Konsumen dan</p>
            <p className={classes.body}>Tertib Niaga Kementerian Perdagangan RI</p>
            <p className={classes.bodyIcon}> <WhatsApp /> 0853-1111-1010</p>
          </Grid>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12} className={classes.subContain} >
          <div>
            <p className={classes.title}>{language.COMMON.FOOTER.MENU}</p>
            <p className={classes.link} onClick={() => { navigate('/furnitur') }}> {language.COMMON.FOOTER.HOME} </p>
            {
              getAccessLog('isAuthentication') === true ?
                <p className={classes.link} onClick={() => { navigate('/furnitur/profile') }}> {language.COMMON.FOOTER.MY_ACCOUNT}</p>
                : null
            }
            <p className={classes.link} onClick={() => { navigate('/furnitur/about-us') }}> {language.COMMON.FOOTER.ABOUT_US}</p>
            <p className={classes.link} onClick={() => { navigate('/furnitur/office-fits-out') }}> {language.COMMON.FOOTER.OFFICE_FITS_OUT}</p>
            <p className={classes.link} onClick={() => { navigate('/furnitur/our-project?act=List') }}> {language.COMMON.FOOTER.OUR_PROJECT}</p>
          </div>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12} className={classes.subContain2} >
          <div>
            <p className={classes.title}>{language.COMMON.FOOTER.HELP_SUPPORT}</p>
            <p className={classes.link} onClick={() => { navigate('/furnitur/terms') }} >{language.COMMON.FOOTER.TERM_CONDITION}</p>
            <p className={classes.link} onClick={() => { navigate('/furnitur/contact-us') }} >{language.COMMON.FOOTER.CONTACT_US}</p>
          </div>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12} className={classes.subContain2}>
          <div>
            <p className={classes.title}>{language.COMMON.FOOTER.PAYMENT}</p>
            <img src={briIcon} width={'100px'} alt="" />
          </div>
        </Grid>
        <LogoFooter src={footerLogo} alt="">
        </LogoFooter>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
          <Divider></Divider>
          <p className={classes.copyRight}>&copy; by PT. Furni Karya Mandiri {getDayNow('year')} All Right Reserved</p>
        </Grid>

      </Grid>
    </div>
  )
}

export default Footer
