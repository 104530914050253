import React, { useState, useEffect } from 'react';
import { InputComponent } from 'components'
import DefaultStructure from './schema/default'
import { useLanguage } from 'hooks';
function General(props) {
  const [openModal, setOpenModal] = useState(false)
  const [list, setList] = useState([])

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const arrayImage = ['image1']
  const [language, lagDic, setLang] = useLanguage()
  return (
    <div style={{ margin: '20px 50px 20px 30px' }}>
      <InputComponent
        structure={DefaultStructure(props?.values, props?.handleFieldChange, handleOpenModal, props?.categoryOption, language)}
        isLoading={props?.isLoading}
        error={props?.error}
        divider={1}
        padding={1}
        minScreen="md"
      // readOnlyAll
      />
    </div>
  );
}

export default General;
