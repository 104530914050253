import { profileConstants } from '../_constants';

export function profile(state = {}, action) {
    switch (action.type) {
        case profileConstants.GETALL_PROFILE_REQUEST:
            return {
                loading: true
            };
        case profileConstants.GETALL_PROFILE_SUCCESS:
            return {
                items: action.profile
            };
        case profileConstants.GETALL_PROFILE_FAILURE:
            return {
                error: action.error
            };
        case profileConstants.CREATE_REQUEST:
            return {
                loading: true
            };
        case profileConstants.CREATE_SUCCESS:
            return {
                items: action.profile
            };
        case profileConstants.CREATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function transaction(state = {}, action) {
    switch (action.type) {
        case profileConstants.GETALL_TRANSACTION_REQUEST:
            return {
                loading: true
            };
        case profileConstants.GETALL_TRANSACTION_SUCCESS:
            return {
                items: action.transaction
            };
        case profileConstants.GETALL_TRANSACTION_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function instance(state = {}, action) {
    switch (action.type) {
        case profileConstants.GETALL_INSTANCE_REQUEST:
            return {
                loading: true
            };
        case profileConstants.GETALL_INSTANCE_SUCCESS:
            return {
                items: action.instance
            };
        case profileConstants.GETALL_INSTANCE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}