import { subCategoryConstants } from '../_constants';

export function subCategory(state = {}, action) {
    switch (action.type) {
        case subCategoryConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case subCategoryConstants.GETALL_SUCCESS:
            return {
                items: action.subCategory
            };
        case subCategoryConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}