import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { functionalityActions } from '../../controllers/_actions'

export default function useFunctionality(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const functionality = useSelector(state => state.functionality);
  const dispatch = useDispatch()

  const initFetch = useCallback((param) => {
    dispatch(functionalityActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  const crudAction = useCallback((param, type) => {
    if (type === 'create') {
      dispatch(functionalityActions.createData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage,param));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);


  useEffect(() => {
    const DataLoaded = functionality?.items?.data || []
    setData(DataLoaded)
  }, [functionality])

  return [data, initFetch, crudAction]
}
