import React, { useState, useEffect } from 'react'
import { BreadCrumbs, RangeSlider } from 'components'
import {
  Paper,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Pagination,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography
} from '@mui/material'
import { rupiahFormat } from 'helpers'
import chairsExample2 from "assets/images/chair3.jpg"
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router'
import useStyles from './styles'

function Storage() {
  const classes = useStyles()
  const navigate = useNavigate()
  const breadcrumb = [
    {
      title: "Home", to: "/furnitur"
    }]
  const [isTyped, setisTyped] = useState(false)
  const [filter, setfilter] = useState({
    min: '',
    max: ''
  })
  const [valueRange, setValueRange] = React.useState([0, 300000]);

  const [open, setOpen] = React.useState({});

  const handleClick = (id, val) => {
    setOpen({ ...open, [id]: val });
  };
  const param = [{
    id: "Spesification",
    label: "[Spesification] Cover Material",
    child: [
      {
        label: '[Subspesification] Leather'
      },
      {
        label: '[Subspesification] Fabric / Oscar'
      }
    ]
  },
  {
    id: "Base",
    label: "Base",
    child: [
      {
        label: 'Nylon'
      },
      {
        label: 'Metal'
      }, {
        label: 'Wood'
      }
    ]
  },
  {
    id: "Wheel",
    label: "Wheel",
    child: [
      {
        label: 'Yes'
      },
      {
        label: 'No'
      }
    ]

  }
  ];

  useEffect(() => {
    setfilter({
      min: valueRange[0],
      max: valueRange[1]
    })
  }, [valueRange])

  useEffect(() => {
    if (isTyped === true) {
      setValueRange([
        filter?.min,
        filter?.max
      ])
      setisTyped(false)
    }
  }, [isTyped])



  return (
    <div className={classes.container}>
      <div >
        <BreadCrumbs
          breadcrumb={breadcrumb}
          current={"Storage"}
        />
      </div>
      <Grid
        container>
        <Grid
          item xs={3} md={3} sx={{ display: 'flex', alignSelf: 'flex-end' }}>
          <strong>Filter</strong>
        </Grid>
        <Grid
          item xs={3} md={3} sx={{ display: 'flex', alignSelf: 'flex-end' }} >
          Clear Filter
        </Grid>
        <Grid
          item xs={3} md={3} sx={{ display: 'flex', alignSelf: 'flex-end' }}>
          <strong>Show 123 items</strong>
        </Grid>
        <Grid
          item xs={3} md={3} sx={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'center', textAlign: 'center' }} >
          <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="sorting-label">Default Sorting</InputLabel>
            <Select
              labelId="sorting"
              id="sorting"
              // value={age}
              // onChange={handleChange}
              autoWidth
              label="Sorting"
            >
              <MenuItem value="default">
                Default Sorting
              </MenuItem>
              <MenuItem value="Alphabetical">
                <ListItemIcon>
                  <SortByAlphaIcon />
                </ListItemIcon>
                Alphabetical
              </MenuItem>
              <MenuItem value="Price Asc">
                <ListItemIcon>
                  <ArrowDownwardIcon />
                </ListItemIcon>
                Price
              </MenuItem>
              <MenuItem value="Price Desc">
                <ListItemIcon>
                  <ArrowUpwardIcon />
                </ListItemIcon>
                Price
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

      </Grid>
      {/* Filter */}
      <Grid
        container
        spacing={2}
        className={classes.content}
      >
        <Grid
          item
          xl={3} lg={3} md={3} sm={3} xs={3}
        >
          <Paper className={classes.filter}>
            <strong>Filter by Price</strong>
            <div className={classes.filterPrice}>
              <div>
                <NumberFormat
                  style={{ width: "100px" }}
                  thousandsGroupStyle="thousand"
                  value={filter.min}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    setfilter({ ...filter, min: value });
                    setisTyped(!isTyped)
                  }}
                  prefix="Rp."
                  decimalSeparator="."
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  allowNegative={false} />
              </div>
              <div>
                <strong>-</strong>
              </div>
              <div>
                <NumberFormat
                  style={{ width: "100px" }}
                  thousandsGroupStyle="thousand"
                  value={filter.max}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    setfilter({ ...filter, max: value });
                    setisTyped(!isTyped)
                  }}
                  prefix="Rp."
                  decimalSeparator="."
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  allowNegative={false} />
              </div>
            </div>
            <RangeSlider
              minDistance={1000000}
              minRange={0}
              maxRange={10000000}
              value={valueRange}
              setvalue={setValueRange}
            />
            <strong>Filter by Specification</strong>

            <List
              sx={{ width: "100%", bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {param?.map((list, idx) => (
                <div style={{ width: "100%" }}>

                  <ListItemButton
                    onClick={() => {
                      handleClick(list.id, !open?.[list.id]);
                    }}
                  >
                    <ListItemIcon >
                      {!list.child ? null :
                        open?.[list.id] ? <ExpandLess sx={{ fontSize: "16px", }} /> : <ExpandMore sx={{ fontSize: "16px" }} />

                      }
                    </ListItemIcon>
                    <p style={{ fontSize: "12px" }} >{list.label}</p>
                    {/* <ListItemText  primary={list.label} /> */}
                  </ListItemButton>
                  {!list.child ? null :
                    <Collapse in={open?.[list.id]} timeout="auto" unmountOnExit>
                      {list.child.map(child =>
                        <List component="div" disablePadding>
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <p style={{ fontSize: "11px" }} >{child.label}</p>
                            {/* <ListItemText primary={child.label} /> */}
                          </ListItemButton>
                        </List>
                      )
                      }
                    </Collapse>
                  }
                </div>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Content */}
        <Grid
          item
          xl={9} lg={9} md={9} sm={9} xs={9}
        >
          <Grid container spacing={1}>
            {[...new Array(12)].map(() => (
              <Grid item xl={2} lg={2} md={4} sm={6} xs={6}>
                <Card sx={{ maxWidth: 250 }}>
                  <CardActionArea onClick={()=> {navigate('/product-detail')}} >
                    <CardMedia
                      component="img"
                      height="200"
                      image={chairsExample2}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography noWrap gutterBottom variant="h5" component="div">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil cum rerum praesentium odio quae consectetur aperiam, enim cupiditate, iusto accusamus dolore in, ullam quis amet deserunt omnis earum veritatis beatae.
                      </Typography>
                      <div style={{ textDecoration: 'line-through', lineHeight: 1.6 }}>
                        <Typography color={'red'}>{rupiahFormat('3300000', 'Rp')}</Typography>
                      </div>
                      <Typography color={'blue'}>{rupiahFormat('1300000', 'Rp')}</Typography>
                      {/* <Typography variant="body2" color="text.secondary">
                          Lizards are a widespread group of squamate reptiles, with over
                          6,000 species, ranging across all continents except Antarctica
                        </Typography> */}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>

          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <Stack spacing={2}>
              <Pagination shape="rounded" color="clarity" count={10} />
            </Stack>
          </div>
        </Grid>
      </Grid>

    </div>
  )
}

export default Storage
