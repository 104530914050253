import React, { useState, useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import {
  Grid,
  TextField,
  Typography,
  ButtonGroup,
  Button,
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  IconButton
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Tab, Cart, Checkout, CartLKPP } from '../component';
import { rupiahFormat } from 'helpers'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useCart } from 'hooks'

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  }

}))


function Index(props) {
  const classes = useStyles()

  const {
    data,
    setPage,
    setFilter,
    handleSubmit,
    setafterUpdate,
    afterUpdate,
    dataProfile
  } = props
  const [open, setOpen] = useState(false);
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);
  const [isCheckedout, setisCheckedout] = useState(false);


  const [isLKPPAcc, setisLKPPAcc] = React.useState(false);


  return (
    <div className={classes.container}>
      {/* <Button variant="contained" color="primary" onClick={() => setisLKPPAcc(!isLKPPAcc)}>
        LKPP {isLKPPAcc ? 'ON' : 'OFF'}
      </Button> */}
      {
        localStorage.getItem('Retail') === 'false'
          ? <CartLKPP
            setisCheckedout={setisCheckedout}
            handleSubmit={handleSubmit}
            data={data}
            setafterUpdate={setafterUpdate}
            afterUpdate={afterUpdate}
            dataProfile={dataProfile} />
          : <Cart
            open={open}
            setOpen={setOpen}
            setisCheckedout={setisCheckedout}
            handleSubmit={handleSubmit}
            data={data}
            setafterUpdate={setafterUpdate}
            afterUpdate={afterUpdate}
            dataProfile={dataProfile} />
      }
    </div>
  )
}

export default Index
