import { makeStyles } from "@mui/styles";
// import bgFile from '../../assets/images/3139154.jpg'
// import palette from "../../assets/";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxWidth: '100vw',
    maxHeight: '100vh',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: theme.palette.common.black,
    overflow: 'hidden',
    background: theme.palette.customGradient.background,
  },
  background: {
    opacity: '0.5',
    background: theme.palette.customGradient.background,
    position: 'absolute',
    height: '100%',
    width: '70%',
    left: 0,
    top: 0,
    clipPath: 'polygon(0 0, 26% 0, 74% 48%, 86% 100%, 0 100%, 0 45%)',
  },
  forgotPassword: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  buttonForgot: {
    textTransform: 'none',
    color: 'blue',
    fontWeight: 'bold',
    fontSize: '12px'
  },
  boxGrid: {
    position: 'absolute',
    // background: theme.palette.secondary.light,
    padding: theme.spacing(3),
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)'
  },
  contentGrid: {
    position: 'relative',
    right: '0',
    
  },
  dropShadow: {
    // filter: 'drop-shadow(5px 10px 4px grey)',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  masterBox: {
    // padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '2',
    // backgroundColor: '#ffffff',
    // borderRadius: '5px',

  },
  paperCustom: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '90%',
    padding: theme.spacing(3,0,5,0)
  },
  imageContainer: {
    position: 'relative',
    height: '100vh',
    width: '100%',
    overflow: 'hidden'
  },
  contentBox: {
    width: '180%',
    
  },
  contentBody: {
    width: '100%',
    
  },
  absoluteContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  upperImage: {
    width: '350px',
    // filter: 'brightness(1000%)',
    position: 'absolute',
    bottom: '0%',
    left: '0%',
    filter: `drop-shadow(5px 5px 5px ${theme.palette.common.black})`,
    transform: 'translate(20%,-30%)',
    padding: theme.spacing(4),
  },
  titleProdia: {
    fontSize: '7ch',
    fontWeight: 'bold',
    letterSpacing: '-3px',
    color: theme.palette.common.black
    // textIndent: '20px'
  },
  lowerImage: {
    width: '90%',
    opacity: '10%',
    filter: 'brightness(500%)',
    position: 'absolute',
    top: '30%',
    left: '-10%',
    // transform: 'translate(50%,50%)',
    padding: theme.spacing(4),
  },
  signInButton: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  judul: {
    fontSize: '5ch',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textShadow: "0px 5px 5px #000000"
  },
  white: {
    color: theme.palette.common.black,
    fontSize: '20px',
    position: 'absolute',
    bottom: '0%',
    left: '0%',
    transform: 'translate(20%,-30%)',
    padding: theme.spacing(4),
  },
  bgContainer: {
    position: "absolute",
    // zIndex: '1',
    height: '100vh',
    width: '100vw'
  },
  bgImage: {
    // filter: "brightness(100%)",
    opacity: '20%',
    // position: 'absolute',
    height: "100vh",
    width: "100vw"
  },
  imageBox: {
    // position: 'absolute',
    // left: '0%',
    // bottom: '10%',
    // transform: 'translate(-30%,0%)',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logoTM: {
    width: '65px',
    height: 'auto',
    paddingLeft: theme.spacing(2)
  },
  logoOvero: {
    width: '50px',
    height: 'auto',
    paddingLeft: theme.spacing(2),
    marginTop: '0px'
  },
  errorGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    padding: theme.spacing(3)
  },
  ErrorBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    width: '20rem',
    border: `2px solid ${theme.palette.danger.main}`,
    backgroundColor: theme.palette.danger.main,
    borderRadius: '10px',
    padding: theme.spacing(2)
  },
  errorText: {
    color: theme.palette.common.black
  },
  fieldError: {
    color: theme.palette.danger.main,
  },
  validateError: {
    width: '355px',
    paddingBottom: 10
  },
  judulSpan: {
    color: theme.palette.common.black,
    fontSize: '20px',
    // fontWeight: 'bold'
  },
  fields: {
    width: '100%'
  },
  form: {
    width: '100%'
  },
  containerFlag: {
    position: 'absolute',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    top: '1%',
    right: '1%',
    transform: 'translate(0,0)',
    zIndex: '6'
  }

}));


export default useStyles