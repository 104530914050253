import { useState, useEffect } from 'react'
import { English, Indonesia } from '../../config/language'

export default function useLanguage(langId) {
  const languageMode = !langId ? localStorage.getItem('languageId') : langId
  const defaultLang = 'id';
  const defaultDictionary = English();
  const [language, setLanguage] = useState(!languageMode ? defaultLang : languageMode)
  const [dictionary, setDictionary] = useState(defaultDictionary)

  const switchLanguage = (lang) => {
    // console.log("🚀 ~ file: useLanguage.jsx ~ line 12 ~ switchLanguage ~ lang", lang)
    localStorage.setItem('languageId', lang)
    setLanguage(lang)
  }

  useEffect(() => {
    if (!languageMode) {
      switchLanguage(defaultLang)
    } else {
      switchLanguage(languageMode)
    }
  }, [languageMode])

  useEffect(() => {
    const EnglishLang = English()
    const IndonesiaLang = Indonesia()
    if (language === 'id') {
      setDictionary(IndonesiaLang)
    } else if (language === 'en') {
      setDictionary(EnglishLang)
    } else {
      setDictionary(EnglishLang)
    }
  }, [language])

  return [
    dictionary,
    language,
    switchLanguage
  ]
}
