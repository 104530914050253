import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';

export default function RadioButtonsGroup(props) {
  const {
    classes,
    param
  } = props
  const [value, setValue] = React.useState(param?.initVal);

  const handleChange = (event) => {
    const { name, value } = event.target
    setValue(value);
    param?.onChange(name, value)
  };

  return (
    <div>
    <RadioGroup
      aria-label="radio"
      name={param.id}
      value={param.value}
      onChange={handleChange}
      helperText={param.helper}
    >
      {param?.option?.map(opt => {
        return <FormControlLabel value={opt.value} control={< Radio />} label={opt.label} />
      })}
    </RadioGroup >
    <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
    </div>
    
  );
}
