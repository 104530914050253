import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography } from '@mui/material';

export default function InputAdornments(props) {
    const {
        classes,
        param,
      } = props
      const initialDate = !param ? "" : !param.value ? "" : param.value
    const [values, setValues] = React.useState(initialDate);
    const [isShow, setIsShow] = React.useState(false)
    const handleChange = (e) => {
        param.onChange(e.target?.name, e.target.value)
        setValues(e.target.value)
    };
  
    const handleClickShowPassword = () => {
      setIsShow(!isShow);
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    return (
        <div>
            {/* <InputLabel htmlFor="outlined-adornment-password">{param?.label}</InputLabel> */}
            <TextField
            sx={{width: '100%'}}
              id="outlined-adornment-password"
              type={isShow ? 'text' : 'password'}
              value={values}
              onChange={handleChange}
              disabled={param.disabled}
              placeholder={param.placeholder}
              required={param.required}
              // helperText={param.helper}
              name={param?.id}
              InputProps={{
                  endAdornment:(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {!isShow ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )

              }}
            //   label="Password"
            />
            <Typography
              color={'error'}
              // className={classes.fieldError}
              variant="caption"
            >
              {param?.helper}
            </Typography>
        </div>
    );
  }