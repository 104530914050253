import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // padding: theme.spacing(3),
    borderRadius: '20px',
    minWidth: 400,
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      minWidth: 0
    },
    background: theme.palette.customGradient.lightWhite
  },
  circleBox: {
    // position: 'absolute',
  },
  icontitle: {
    // background: 'linear-gradient(180deg, rgba(247,148,29,1) 0%, rgba(255,255,255,0) 53%, rgba(251,251,251,0) 100%)',
    // background: theme.palette.customGradient.main,
    overflow: 'hidden',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'rig',
    '&:after': {
      content: '""',
      borderRadius: '50%',
      position: 'absolute',
      top: '0%',
      left: '50%',
      // transform: 'rotate(90deg)',
      // background: 'radial-gradient(circle, rgba(251,251,251,0.9) 0%, rgba(253,253,253,0.2827906162464986) 40%, rgba(255,255,255,0) 70%)',
      opacity: '0.2',
      // left: 50,
      width: '100%',
      height: '100%',
      // display: 'block',
      // background: theme.palette.common.black
    }
    // color: PalleteTheme.common.black
  },
  icontitleSuccess: {
    // background: 'linear-gradient(180deg, rgba(247,148,29,1) 0%, rgba(255,255,255,0) 53%, rgba(251,251,251,0) 100%)',
    background: '#EAF7EE',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    '&:after': {
      content: '""',
      borderRadius: '50%',
      position: 'absolute',
      top: '0%',
      left: '50%',
      // transform: 'rotate(90deg)',
      // background: 'radial-gradient(circle, rgba(251,251,251,0.9) 0%, rgba(253,253,253,0.2827906162464986) 40%, rgba(255,255,255,0) 70%)',
      opacity: '0.2',
      // left: 50,
      width: '100%',
      height: '100%',
      // display: 'block',
      // background: theme.palette.common.black
    }
    // color: PalleteTheme.common.black
  },
  icontitleError: {
    // background: 'linear-gradient(180deg, rgba(247,148,29,1) 0%, rgba(255,255,255,0) 53%, rgba(251,251,251,0) 100%)',
    background: '#FAA9A3',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    '&:after': {
      content: '""',
      borderRadius: '50%',
      position: 'absolute',
      top: '0%',
      left: '50%',
      // transform: 'rotate(90deg)',
      // background: 'radial-gradient(circle, rgba(251,251,251,0.9) 0%, rgba(253,253,253,0.2827906162464986) 40%, rgba(255,255,255,0) 70%)',
      opacity: '0.2',
      // left: 50,
      width: '100%',
      height: '100%',
      // display: 'block',
      // background: theme.palette.common.black
    }
    // color: PalleteTheme.common.black
  },
  icontitleInfo: {
    // background: 'linear-gradient(180deg, rgba(247,148,29,1) 0%, rgba(255,255,255,0) 53%, rgba(251,251,251,0) 100%)',
    background: "#9DC2FF",
    overflow: 'hidden',
    justifyContent: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    '&:after': {
      content: '""',
      borderRadius: '50%',
      position: 'absolute',
      top: '0%',
      left: '50%',
      // transform: 'rotate(90deg)',
      // background: 'radial-gradient(circle, rgba(251,251,251,0.9) 0%, rgba(253,253,253,0.2827906162464986) 40%, rgba(255,255,255,0) 70%)',
      opacity: '0.2',
      // left: 50,
      width: '100%',
      height: '100%',
      // display: 'block',
      // background: theme.palette.common.black
    }
    // color: PalleteTheme.common.black
  },
  IconContainer: {
    borderRadius: '50%',
    marginTop: '5px',
    marginBottom: '5px',
    fontSize: '9px',
    // height: 50,
    // width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: "center",
    // transform: 'rotate(10deg)',
    // boxShadow: '14px 13px 24px 2px rgba(0,0,0,0.76)',
    padding: '10px',
    background: "transparent"
  },
  icon: {
    fontSize: '9px',
    color: theme.palette.common.black,
    position: 'absolute',
    zIndex: 3,
    opacity: '40%',
    left: '0%',
    top: '50%',
    transform: 'translate(0%, -50%)',
    width: 130,
    height: 130
  },
  // iconError: {
  //   fontSize: '9px',
  //   color: theme.palette.common.black,
  //   position: 'absolute',
  //   zIndex: 3,
  //   opacity: '40%',
  //   left: '0%',
  //   top: '50%',
  //   transform: 'translate(0%, -50%)',
  //   width: 130,
  //   height: 130
  //   // backgroundColor: theme.palette.primary.main
  // },
  // iconInfo: {
  //   fontSize: '15px',
  //   color: '#0C2146',
  //   position: 'absolute',
  //   opacity: '40%',
  //   left: '0%',
  //   top: '50%',
  //   transform: 'translate(0%, -50%)',
  //   width: 130,
  //   height: 130
  //   // backgroundColor: theme.palette.primary.main
  // },
  // iconSuccess: {
  //   fontSize: '9px',
  //   color: theme.palette.common.white,
  //   position: 'absolute',
  //   opacity: '40%',
  //   left: '0%',
  //   top: '50%',
  //   transform: 'translate(0%, -50%)',
  //   width: 130,
  //   height: 130
  //   // backgroundColor: theme.palette.primary.main
  // },
  // title: {
  //     // backgroundColor: theme.palette.primary.main,
  //     color: theme.palette.secondary.main,
  //     // paddingTop: theme.spacing(3),
  //     paddingLeft: theme.spacing(2),
  //     textAlign: 'center',
  //     fontSize: '24px',
  //     fontWeight: 'bold'
  // },
  titleDark: {
    // backgroundColor: theme.palette.primary.main,
    color: '#0C2146',
    // paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 'bold'
  },
  titleWhite: {
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    // paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 'bold'
  },
  messageBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    color: theme.palette.common.black,
  },
  message: {
    // border: '2px solid #000000',
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    // fontSize: '16px',
    textAlign: 'center',
    // fontWeight: 'bold',
    color: theme.palette.common.black,
    // padding: theme.spacing(3)
  },
  contentBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  content: {
    width: '100%',
    // background: 'transparent'
    // display: 'flex',
    // textAlign: 'center',
    // justifyContent: 'center',
    // alignContent: 'center',
    background: theme.palette.common.white
  },
  formControl: {
    paddingTop: theme.spacing(1),
    margin: theme.spacing(1),
    width: '100%',
  },
  formField: {

  },
  logoContainer: {
    // height: 'auto',
    // width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4)
  },
  headerlogo: {
    height: 'auto',
    width: '50px'
  },
  action: {
    background: theme.palette.common.white,
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleRoot: {
    position: 'relative',
    // width: '100%',
    overflow: 'hidden',
  },
  footerRoot: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    height: '40px',
    // background: theme.palette.customGradient.mainReverse
  },
  iconHead: {
    position: 'absolute',
    zIndex: 2,
    top: '-60%',
    left: '5%',
    transform: 'translate(-30%, -10%)',
    opacity: '0.2',
    color: theme.palette.secondary.main,
    height: '300px',
    width: '200px',
    // [theme.breakpoints.only('lg')]: {
    //   height: '50px',
    //   width: '50px',
    // }
  },
  footerIcon: {
    position: 'absolute',
    zIndex: 2,
    top: '-100%',
    left: '5%',
    transform: 'translate(-30%, -20%)',
    opacity: '0.3',
    color: `${theme.palette.primary.dark}`,
    height: '100px',
    width: '200px',
    // [theme.breakpoints.only('lg')]: {
    //   height: '50px',
    //   width: '50px',
    // }
  },
  paddingUp: {
    width: "100%",
    padding: theme.spacing(3)
  }
}))

export default useStyles