import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { ManageAccounts, ReceiptLong } from '@mui/icons-material';
import useStyles from './styles'
import {
  TextField,
  Tabs,
  Button,
  Tab,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { MyAccount, MyTransaction, DetailRetailOrder, MyAddress, MyAccountLKPP, MyTransactionLKPP, MyAddressLKPP, DetailOrder, TransactionInfoLkpp, HistoryDetailLkpp } from "./component";
import queryString from 'query-string'
import BallotIcon from '@mui/icons-material/Ballot';
import { useNavigate, useLocation } from 'react-router'
import { useLanguage } from 'hooks';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
        // sx={{
        //   m: 2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px',
        // }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function Index() {
  window.scrollTo(0, 0);                                         // Auto Scroll to Top



  const navigate = useNavigate()
  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  const classes = useStyles()
  const [value, setValue] = React.useState(0);
  const [date, setdate] = React.useState(new Date());
  const [toggleBtn, settoggleBtn] = React.useState({});
  const [isLKPPAcc, setisLKPPAcc] = React.useState(false);
  const [language, lagDic, setLang] = useLanguage()


  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageType = (type, submit) => {
    navigate({
      search: `?act=transaction${type}`
    })
  }

  const dummy = [
    {
      order_id: 'ORD-123',
      order_date: "21-01-2022",
      total_prd: '1',
      qty: '4',
      shippingfee: 100000,
      total_amount: 100000,
      status: 'Waiting for Payment'
    },
    {
      order_id: 'ORD-124',
      order_date: "21-02-2022",
      total_prd: '2',
      qty: '3',
      shippingfee: 100000,
      total_amount: 100000,
      status: 'Shipping'
    },
    {
      order_id: 'ORD-125',
      order_date: "21-03-2022",
      total_prd: '3',
      qty: '2',
      shippingfee: 100000,
      total_amount: 100000,
      status: 'Completed'
    },
    {
      order_id: 'ORD-126',
      order_date: "21-04-2022",
      total_prd: '4',
      qty: '1',
      shippingfee: 100000,
      total_amount: 100000,
      status: 'Cancelled'
    }

  ]

  return (
    <div className={classes.container}>
      {/* <Button variant="contained" color="primary" onClick={() => setisLKPPAcc(!isLKPPAcc)}>
        LKPP {isLKPPAcc ? 'ON' : 'OFF'}
      </Button> */}

      <Box
        sx={{ height: 'auto' }}
      >
        <Tabs
          orientation="horizontal"
          indicatorColor='secondary'
          textColor='secondary'
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab fullWidth sx={{ justifyContent: 'flex-start' }} icon={<ManageAccounts />} iconPosition='start' label={<span className={value === 0 ? classes.activeTab : ""}>{language.COMMON.MY_ACCOUNT.MY_ACCOUNT}</span>} {...a11yProps(0)} />
          <Tab onClick={() => handlePageType('List')} sx={{ justifyContent: 'flex-start' }} icon={<ReceiptLong />} iconPosition='start' label={<span className={value === 1 ? classes.activeTab : ""}>{language.COMMON.MY_ACCOUNT.MY_TRANSACTION}</span>} {...a11yProps(1)} />
          <Tab sx={{ justifyContent: 'flex-start' }} iconPosition='start'icon={<BallotIcon />}  label={<span className={value === 2 ? classes.activeTab : ""}>{language.COMMON.MY_ACCOUNT.MY_ADDRESS}</span>} {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0} style={{ width: '100%' }} >
          {localStorage.getItem('Retail') === 'false'
            ? <MyAccountLKPP />
            : <MyAccount />
          }
        </TabPanel>
        <TabPanel value={value} index={1} style={{ width: '100%' }}>

          {localStorage.getItem('Retail') === 'false'
            ?
            (() => {
              switch (query) {
                case "transactionDetail": return <DetailOrder changeStep={handlePageType} />;
                case "transactionHistoryDetail": return <HistoryDetailLkpp changeStep={handlePageType} />;
                case "transactionInfo": return <TransactionInfoLkpp />;
                default: return <MyTransactionLKPP handlePageType={handlePageType} date={date} setdate={setdate} toggleBtn={toggleBtn} settoggleBtn={settoggleBtn} dummy={dummy} />;
              }
            })()
            :
            (() => {
              switch (query) {
                case "transactionDetail": return <DetailRetailOrder changeStep={handlePageType} />;
                default: return <MyTransaction handlePageType={handlePageType} date={date} setdate={setdate} toggleBtn={toggleBtn} settoggleBtn={settoggleBtn} dummy={dummy} />;
              }
            })()
          }
          {/* {isLKPPAcc
            ? <MyTransactionLKPP handlePageType={handlePageType} date={date} setdate={setdate} toggleBtn={toggleBtn} settoggleBtn={settoggleBtn} dummy={dummy} />
            : <MyTransaction date={date} setdate={setdate} toggleBtn={toggleBtn} settoggleBtn={settoggleBtn} dummy={dummy} />
          } */}

        </TabPanel>
        <TabPanel value={value} index={2} style={{ width: '100%' }}>
          {localStorage.getItem('Retail') === 'false'
            ? <MyAddressLKPP />
            : <MyAddress />
          }
        </TabPanel>
      </Box>
    </div >
  )
}

export default Index
