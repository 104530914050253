const bundle = {
  TITLE: 'LOGIN',
  DESC: 'Masuk dengan akun Anda yang terdaftar',
  HEADER: {
    HEADER_1: 'Status Transaksi Penggajian',
    HEADER_2: 'Penggajian - Hasilkan Checksum',
    HEADER_3: 'Penggajian - Unggah',
    HEADER_4: 'Penggajian - Konfirmasi Penggungahan',
    HEADER_5: 'Penggajian - Hasil Penggungahan',
    TARGET_LIST: 'Daftar Tujuan Penggajian Anda',
    INQUIRY_ADD_TARGET_LIST: 'Tambah Daftar Penggajian',
    INQUIRY_ADD_TARGET: 'Tambah Karyawan',
    INQUIRY_EDIT_TARGET: 'Edit Karyawan',
    INQUIRY_ADD_BUNDLE: 'Tambah Auto Debit',
    INQUIRY_EDIT_BUNDLE: 'Edit Auto Debit',
    INQUIRY_DETAIL_BUNDLE: 'Detail Auto Debit',
    PAYROLL_DETAIL: 'Detail Penggajian',
    PAYROLL_TARGET: 'Target Penggajian',
    EDIT_TARGET_LIST: 'Edit target Daftar Penggajian',
    NOTE: 'Catatan: ',
    TRANSFER_PAYROLL: 'Pembayaran Gaji',
    TRANSFER_PAYROLL_CONFIRM: 'Konfirmasi Pembayaran Penggajian',
    TRANSFER_PAYROLL_RESULT: 'Hasil Pembayaran Penggajian',
    PAYROLL_HISTORY: 'Riwayat Penggajian',
    PAYROLL_SCHEDULE: 'Penggajian Terjadwal',
    CANCEL_SCHEDULE: 'Batalkan Penggajian Terjadwal',
    PROGRAM_DETAIL: 'Daftar Auto Debit Program',
    DELETE_GROUP: 'Hapus Grup Penggajian',
  },
  LABEL: {
    BUNDLE_TYPE: 'Jenis Auto Debit',
    BUNDLE_ACCOUNT: 'Rekening Tujuan',
    START_DATE: 'Tanggal Mulai',
    END_DATE: 'Tanggal Berakhir',
    EMPLOYEE_BUNDLE_AMOUNT_PRIMARY: 'Nominal Utama',
    EMPLOYEE_BUNDLE_AMOUNT_SECONDARY: 'Nominal Tambahan',
    STATUS: 'Status',
    ACTIONS: 'Aksi'
  },
  PLACEHOLDER: {
    FILE_PAYROLL_1: 'Klik Atau Seret File Di Sini Untuk Mengunggah File Penggajian',
    FILE_PAYROLL_2: 'Hanya menerima file .xlsx',
    SOURCE_FUND: 'Pilih Sumber Dana Anda',
    DESCRIPTION: 'Masukkan Deskripsi',
    CHECKSUM: 'Input File Checksum',
    RECURRING_NEXT_DATE: 'Tanggal Berulang Berikutnya',
    TOTAL_RECORD: 'Jumlah Total Karyawan',
    TOTAL_AMOUNT: 'Jumlah harus lebih dari 0',
    PAYROLL_NAME: 'Masukkan nama penggajian',
  },
  INFO: {
    NO_DATA: `Tidak ada data yang dapat ditampilkan!`,
    NOTE1: 'Jumlah maksimal Daftar Penggajian adalah 1000, jika sudah ada 3 yang terdaftar, sisa yang bisa ditambahkan 997',
    NOTE2: 'Format File yang digunakan adalah xlsx.',
    ADMIN_FEE: ' * Per Rekening',
  },
  MESSAGE: {
    DELETE: 'Apakah anda yakin ingin menghapus',
	UPLOADING: `Ada proses pengunggahan yang sedang berlangsung. Silahkan tunggu hasil prosesnya muncul.`
  }
}

export default bundle