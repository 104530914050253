import React from 'react'
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // displayType={'text'}
      thousandSeparator={'.'}
      decimalSeparator={','}
      allowLeadingZeros={false}
      isNumericString
    // decimalScale={4}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default function CustomDatefield(props) {
  const {
    // classes,
    value,
    onChange,
    id,
    label,
    disabled,
    readOnlyAll,
    placeholder,
    helper,
    variant,
    type,
  } = props

  const initialDate = !value ? "" : value
  const [val, setVal] = React.useState(initialDate)

  const handleChange = (e) => {
    onChange(e.target.value)
    setVal(e.target.value)
  }

  React.useEffect(() => {
    const checkInit = !value ? "" : value
    setVal(checkInit)
  }, [value])

  return (
    <React.Fragment>
      <TextField
        // className={classes.itemContent || ""}
        fullWidth
        id={id}
        margin="dense"
        label={label}
        InputLabelProps={{
          margin: 'dense'
        }}
        value={val}
        onChange={handleChange}
        disabled={disabled}
        readOnly={readOnlyAll}
        placeholder={placeholder}
        helperText={helper}
        name={id}
        type={type}
        variant={variant}
        InputProps={{
          style: { fontSize: 14 },
          inputComponent: NumberFormatCustom,
        }}
      />
    </React.Fragment>
  )
}
