import React from 'react'
import TextField from '@mui/material/TextField';
import { MenuItem, Typography } from '@mui/material';

export default function CustomSelectfield(props) {
  const {
    classes,
    param
  } = props
  // const initialDate = !param ? "" : !param.value ? "" : param.value
  // const [value, setValue] = React.useState(initialDate)

  const handleChange = (e) => {
    param.onChange(e.target.name, e.target.value)
    // setValue(e.target.value)
  }

  // React.useEffect(() => {
  //   const checkInit = !param ? "" : !param.value ? "" : param.value
  //   setValue(checkInit)
  // }, [param])

  return (
    <React.Fragment>
      <TextField
        select
        // component="div"
        className={classes.itemContent}
        fullWidth
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }
        }}
        id={param.id}
        margin="dense"
        required={param.required}
        // label={param.label}
        disabled={param.disabled}
        value={param.value}
        name={param.id}
        variant="outlined"
        // helperText={param.helper}
        onChange={handleChange}
        inputProps={{
          name: `${param.id}`,
          id: `${param.id}`,
          // readOnly: `${props.readOnly || param.readOnly}`
        }}
      >
        <MenuItem value="" disabled />
        {param.option.map(opt => {
          return <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
        })}
      </TextField>
      <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
    </React.Fragment>
  )
}
