import React from 'react'
import Autocomplete from '@mui/lab/Autocomplete'
import { TextField, Box, Switch, Typography } from '@mui/material'
import { ControlPoint } from '@mui/icons-material'
export default function SuggestedFieldWithButton(props) {
    const {
        classes,
        param,
    } = props
    const selectedValue = param?.option?.find((data) => data.value === param.value) ?? null
    // const initialDate = !param ? "" : !param.value ? "" : param.value
    const [value, setValue] = React.useState(!selectedValue ? {} : selectedValue)
    // const [inputValue, setInputValue] = React.useState('');
    const [checked, setChecked] = React.useState(false);

    const handleChangeSwitch = (event) => {
        setChecked(event.target.checked);
      };
    
    const handleChange = (id, val) => {
        param.onChange(id, !val ? "" : val.value)
        setValue(val)
    }

    React.useEffect(() => {
        const checkInit = param?.option?.find((data) => data.value === param.value) ?? null
        setValue(checkInit)
    }, [param])

    const handleOpen = () => {
        param.open(param?.id)
    }

    if (!param?.options || param?.options?.length <= 0) {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                 <Switch
                    checked={checked}
                    onChange={handleChangeSwitch}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <div style={{width: '100%'}}>

                <Autocomplete
                    className={classes?.itemContent ?? "new"}
                    value={value}
                    autoComplete={false}
                    onChange={(event, newValue) => {
                        handleChange(param.id, newValue);
                    }}
                    // onInputChange={(event, newInputValue) => {
                    //   setInputValue(newInputValue);
                    // }}
                    autoHighlight
                    disabled={!checked}
                    id={param.id}
                    options={param.option || []}
                    getOptionLabel={(option) =>  option?.label ? option?.label : ""}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                      {option.label}
                    </Box>
                    )}
                    renderInput={(params) => <TextField
                        {...params}
                        // label={param.label}
                        fullWidth
                        margin='dense'
                        variant="outlined"
                        helperText={param.helper}
                        placeholder={param.placeholder}
                        disabled={param.disabled}
                        required={param.required}
                        inputProps={{
                            ...params.inputProps,
                            // autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                    }
                />
                 <Typography
                    color={'error'}
                    // className={classes.fieldError}
                    variant="caption"
                >
                    {param?.helper}
                </Typography>
                    </div>
                <ControlPoint onClick={checked ? handleOpen : null} style={{ color: 'red', marginLeft: '5px' }} />
            </div>
        )
    }
    return null
}
