import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sessionActions } from 'controllers/_actions'
import { permissionConstants } from 'controllers/_constants'
import { useNavigate } from 'react-router'
// import { permissionWebview } from 'controllers/services/webview/permissionWebview.service'
// import util, { encodeBase64 } from 'tweetnacl-util'
function permissionSubmit(permission) {
    return { type: permissionConstants.GETALL_SUCCESS, permission }
}
function permissionFailure() {
    return { type: permissionConstants.GETALL_FAILURE };
}
export default function useSession(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
    const history = useNavigate()
    const [data, setData] = useState([])
    const permission = useSelector(state => state.permission);
    const dispatch = useDispatch()

    const initFetch = useCallback((param) => {
        dispatch(sessionActions.getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param, history));
    }, [dispatch]);

    // const initFetch = useCallback(async (param) => {
    //     try {
    //         if (param) {
    //             // const endcodedBase64Pass = util.decodeBase64(param);
    //             console.log(param, 'ni cuyy')
    //             const allData = window?.atob(param || "") || ""
    //             let result = []
    //             let tempObj
    //             let temp
    //             let obj
    //             if (allData?.substr(0, 14) === 'hondaindonesia') {
    //                 temp = allData?.substr(14, allData?.length - 1)
    //                 obj = JSON.parse(temp)
    //                 if (obj?.KD_DEALER && obj?.KD_SALES) {
    //                     // const permissionResponse = await permissionWebview.getRole(obj.KD_SALES, obj.access_token)
    //                     const userPermission = obj?.KD_SALES?.substr(4, 4)
    //                     // let response
    //                     // let encryptedroles
    //                     // let roleDealer
    //                     let role
    //                     switch (userPermission) {
    //                         case '1010':
    //                             role = 'Manager'
    //                             tempObj = {
    //                                 KD_DEALER: obj.KD_DEALER,
    //                                 KD_SALES: obj.KD_SALES,
    //                                 NAMA_SALES: obj.NAMA_SALES,
    //                                 COMPANYNAME: obj.COMPANYNAME,
    //                                 role: role
    //                             }
    //                             result.push(tempObj)
    //                             // console.log(result, 'ini result')
    //                             dispatch(permissionSubmit(result));
    //                             break;
    //                         case '1020':
    //                             role = 'Manager'
    //                             tempObj = {
    //                                 KD_DEALER: obj.KD_DEALER,
    //                                 KD_SALES: obj.KD_SALES,
    //                                 NAMA_SALES: obj.NAMA_SALES,
    //                                 COMPANYNAME: obj.COMPANYNAME,
    //                                 role: role
    //                             }
    //                             result.push(tempObj)
    //                             // console.log(result, 'ini result')
    //                             dispatch(permissionSubmit(result));
    //                             break;
    //                         case '1030':
    //                             role = 'Manager'
    //                             tempObj = {
    //                                 KD_DEALER: obj.KD_DEALER,
    //                                 KD_SALES: obj.KD_SALES,
    //                                 NAMA_SALES: obj.NAMA_SALES,
    //                                 COMPANYNAME: obj.COMPANYNAME,
    //                                 role: role
    //                             }
    //                             result.push(tempObj)
    //                             // console.log(result, 'ini result')
    //                             dispatch(permissionSubmit(result));
    //                             break;
    //                         case '1040':
    //                             role = 'Manager'
    //                             tempObj = {
    //                                 KD_DEALER: obj.KD_DEALER,
    //                                 KD_SALES: obj.KD_SALES,
    //                                 NAMA_SALES: obj.NAMA_SALES,
    //                                 COMPANYNAME: obj.COMPANYNAME,
    //                                 role: role
    //                             }
    //                             result.push(tempObj)
    //                             // console.log(result, 'ini result')
    //                             dispatch(permissionSubmit(result));
    //                             break;
    //                         case '1050':
    //                             role = 'Manager'
    //                             tempObj = {
    //                                 KD_DEALER: obj.KD_DEALER,
    //                                 KD_SALES: obj.KD_SALES,
    //                                 NAMA_SALES: obj.NAMA_SALES,
    //                                 COMPANYNAME: obj.COMPANYNAME,
    //                                 role: role
    //                             }
    //                             result.push(tempObj)
    //                             // console.log(result, 'ini result')
    //                             dispatch(permissionSubmit(result));
    //                             break;
    //                         case '1060':
    //                             role = 'Manager'
    //                             tempObj = {
    //                                 KD_DEALER: obj.KD_DEALER,
    //                                 KD_SALES: obj.KD_SALES,
    //                                 NAMA_SALES: obj.NAMA_SALES,
    //                                 COMPANYNAME: obj.COMPANYNAME,
    //                                 role: role
    //                             }
    //                             result.push(tempObj)
    //                             // console.log(result, 'ini result')
    //                             dispatch(permissionSubmit(result));
    //                             break;
    //                         case '1070':
    //                             role = 'Supervisor'
    //                             tempObj = {
    //                                 KD_DEALER: obj.KD_DEALER,
    //                                 KD_SALES: obj.KD_SALES,
    //                                 NAMA_SALES: obj.NAMA_SALES,
    //                                 COMPANYNAME: obj.COMPANYNAME,
    //                                 role: role
    //                             }
    //                             result.push(tempObj)
    //                             // console.log(result, 'ini result')
    //                             dispatch(permissionSubmit(result));
    //                             break;
    //                         case '1080':
    //                             role = 'Sales'
    //                             tempObj = {
    //                                 KD_DEALER: obj.KD_DEALER,
    //                                 KD_SALES: obj.KD_SALES,
    //                                 NAMA_SALES: obj.NAMA_SALES,
    //                                 COMPANYNAME: obj.COMPANYNAME,
    //                                 role: role
    //                             }
    //                             result.push(tempObj)
    //                             // console.log(result, 'ini result')
    //                             dispatch(permissionSubmit(result));
    //                             break;
    //                         case '1090':
    //                             role = 'Sales'
    //                             tempObj = {
    //                                 KD_DEALER: obj.KD_DEALER,
    //                                 KD_SALES: obj.KD_SALES,
    //                                 NAMA_SALES: obj.NAMA_SALES,
    //                                 COMPANYNAME: obj.COMPANYNAME,
    //                                 role: role
    //                             }
    //                             result.push(tempObj)
    //                             // console.log(result, 'ini result')
    //                             dispatch(permissionSubmit(result));
    //                             break;
    //                         default:
    //                             window.alert("Unauthorized access. There's something problem with your session, Please contact administrator to give you the access.")
    //                             dispatch(permissionSubmit({ message: "Not Authorize", status: 404 }));
    //                             window.location.assign(window?._env_?.APP_REDIRECT_LINK)
    //                             break;
    //                     }
    //                     // if(!permissionResponse?.message) {
    //                     //     tempObj = {
    //                     //         KD_DEALER: obj.KD_DEALER,
    //                     //         KD_SALES: obj.KD_SALES,
    //                     //         NAMA_SALES: obj.NAMA_SALES,
    //                     //         COMPANYNAME: obj.COMPANYNAME,
    //                     //         role: permissionResponse
    //                     //     }
    //                     //     result.push(tempObj)
    //                     //     // console.log(result, 'ini result')
    //                     //     dispatch(permissionSubmit(result));
    //                     // } else {
    //                     //     window.alert("Unauthorized access. There's something problem with your session, Please contact administrator to give you the access.")
    //                     //     dispatch(permissionSubmit({message: "Not Authorize", status: 404}));
    //                     //     window.location.assign(window?._env_?.APP_REDIRECT_LINK)
    //                     // }
    //                 } else {
    //                     window.alert("Unauthorized access. There's something problem with your session, Please contact administrator to give you the access.")
    //                     dispatch(permissionSubmit({ message: "Not Authorize", status: 404 }));
    //                     window.location.assign(window?._env_?.APP_REDIRECT_LINK)
    //                     return { message: "Not Authorize", status: 404 };
    //                 }
    //             } else {
    //                 window.alert("Unauthorized access. There's something problem with your session, Please contact administrator to give you the access.")
    //                 dispatch(permissionSubmit({ message: "Not Authorize", status: 404 }));
    //                 window.location.assign(window?._env_?.APP_REDIRECT_LINK)
    //                 return { message: "Not Authorize", status: 404 };
    //             }
    //         } else {
    //             window.alert("Unauthorized access.There's something problem with your session, Please contact administrator to give you the access.")
    //             dispatch(permissionSubmit({ message: "Not Authorize", status: 404 }));
    //             window.location.assign(window?._env_?.APP_REDIRECT_LINK)
    //             return { message: "Not Authorize", status: 404 };
    //         }
    //     } catch (error) {
    //         window.alert("Unauthorized access. There's something problem with your session, Please contact administrator to give you the access.")
    //         dispatch(permissionSubmit({ message: "Not Authorize", status: 404 }));
    //         window.location.assign(window?._env_?.APP_REDIRECT_LINK)
    //         return { message: "Not Authorize", status: 404 };
    //     }
    // }, [dispatch]);

    useEffect(() => {
        const DataLoaded = permission || []
        setData(DataLoaded)
    }, [permission])

    return [data, initFetch]
}
