import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import queryString from 'query-string'
import { useCart, useSelected, useProfile, useContactUs } from 'hooks'
import { CustomBackdrop } from 'components';
import FlowComponent from './flow'
import { useNavigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { getAccessLog } from 'helpers';

function Index() {
  window.scrollTo(0, 0);                                         // Auto Scroll to Top
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  const [step, setStep] = React.useState('List')
  const [selected, setSelected] = useSelected()

  const [open, setOpen] = useState(false);
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);
  const [isCheckedout, setisCheckedout] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getDetail] = useCart(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables2, setDatatables2, getData] = useProfile(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables3, setDatatables3, createData] = useContactUs(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)

  const [list, setList] = useState([]);

  const [afterUpdate, setafterUpdate] = useState(false);
  console.log("🚀 ~ file: index.jsx ~ line 33 ~ Index ~ afterUpdate", afterUpdate)
  console.log("🚀 ~ file: index.jsx ~ line 47 ~ Index ~ datatables", datatables)

  useEffect(() => {
    if (localStorage.getItem('Retail') === "true" || localStorage.getItem('Retail') === "true") {
      const param = {
        CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
        // CUSTOMER_NAME: "Maudy A",
        // CUSTOMER_NO: "00000009",
        USERNAME: getAccessLog('username'),
        page: 1,
        limit: 100
      }
      setDatatables2(param)
    }
  }, [afterUpdate])

  useEffect(() => {
    if (datatables2.length > 0) {
      const param = {
        CUSTOMER_NO: datatables2?.[0]?.CUSTOMER_NO,
        USERNAME: getAccessLog('username'),
        IS_CONTRACT: localStorage.getItem('Retail') === "false" ? true : false,
        page: 1,
        limit: 100
      }
      setDatatables(param)
    }
  }, [afterUpdate, datatables2, query])

  useEffect(() => {
    setList(datatables)
  }, [datatables])


  useEffect(() => {
    if (queryParse === "" || !queryParse.act) {
      const query = {
        ...queryParse,
        act: 'List',
      };
      const searchString = queryString.stringify(query);
      navigate({
        search: searchString
      })
    }

  }, [location.search])

  useEffect(() => {
    if (query) {
      setStep(query || "List")
    }
  }, [query])

  const handleChangeStep = (val, submit) => {
    handlePush(val, submit)
    setStep(val)
  }

  const handlePush = (type, submit) => {
    if (type !== "List") {
      const query = {
        ...queryParse,
        act: type,
        PRODUCT_NAME: submit?.PRODUCT_NAME,
        PRODUCT_CODE: submit?.PRODUCT_CODE
      };
      const searchString = queryString.stringify(query);
      navigate({
        search: searchString
      })
    } else {
      navigate({
        search: `?act=${type}`
      })
    }
  }


  const handleFlow = async (type, submit = []) => {
    if (type === 'checkout') {
      await setSelected(submit)
      handleChangeStep('Checkout', submit)
    } else if (type === 'shippingAddress') {
      await setSelected(submit)
      handleChangeStep('ShippingAddress', submit)
    } else if (type === 'shipping') {
      await setSelected(submit)
      handleChangeStep('Shipping', submit)
    } else if (type === 'shippinginfo') {
      await setSelected(submit)
      handleChangeStep('ShippingInfo', submit)
    } else if (type === 'list') {
      //   await initFetch();
      handleChangeStep('List')
    }
  }

  return (
    <React.Fragment>

      {/* <CustomBackdrop loading={isLoading} label={'Collecting Resource'} /> */}

      <FlowComponent
        changeStep={handleChangeStep}
        // data={props.type ? getSale?.items?.data : !props.type ? datatables : [] }
        data={list ?? []}
        loading={isLoading}
        error={error}
        step={step}
        handleSubmit={handleFlow}
        // param={param}
        selected={selected}
        onSelect={setSelected}
        checkingApprove={(body, type) => handleFlow(body, type)}
        setPage={setPage}
        setafterUpdate={setafterUpdate}
        afterUpdate={afterUpdate}
        dataProfile={datatables2}
        dataVendor={datatables3}
      />
    </React.Fragment>
  )
}

export default Index
