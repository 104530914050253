import React from 'react';
import { withStyles, makeStyles, styled } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Hidden from '@mui/material/Hidden';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Button from '@mui/material/Button';
import Alert from '@mui/lab/Alert';
import { IconButton, Tooltip, Checkbox, FormControlLabel, Switch, Typography } from '@mui/material';
import { useSelected, useSelectedDetail } from 'hooks';
import ImageIcon from '@mui/icons-material/Image';
import {
  CurrencyField, TextField
} from '../../components'
import moment from 'moment';
import { HandlerModal } from '../../components'
import Edit from '@mui/icons-material/Edit';
import { useLanguage } from 'hooks';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import addImage from 'assets/images/notfoud.jpg'
// import bulkTransfer from 'config/language/id/bulkTransfer';
// import { getAccessLog } from 'helpers'
// import { DeleteForever } from '@material-ui/icons';
// const getSomething = getAccessLog("user_id")

// const StyledTableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.common.black,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F1F5F8',
    color: theme.palette.common.black,
    fontWeight: 'bold',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F2F5F8',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    // border: '1px solid gray',
  },
  margin: 0,
  padding: 0
}));
// const StyledTableRow = withStyles((theme) => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.extended.half,
//     },
//     '&$hover:hover': {
//       // Set hover color
//       backgroundColor: theme.palette.primary.light,
//     },
//   },
// }))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.common.white,
    maxHeight: 8000,
    maxWidth: '100%',
    [theme.breakpoints.only('xl')]: {
      maxHeight: 700,
      maxWidth: '100%',
    },
    [theme.breakpoints.only('lg')]: {
      maxHeight: 400,
      maxWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 'none',
      maxWidth: '100%',
    }
  },
  gridItem: {
    width: '100px'
  },
  // table: {
  //   minWidth: '100%',
  //   maxHeight: '500px',
  // },
  hoverTable: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  // Button: {
  //   color: theme.palette.error.main
  // },
  IconButton: {
    height: '20px',
    width: '20px',
    fontSize: '12px',
    padding: theme.spacing(2)
  },
  Button: {
    fontSize: '12px',
    textTransform: 'none',
  },
  containerIndex: {
    maxWidth: '35px',
    padding: 0,
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1)
  },
  padding: {
    padding: theme.spacing(2)
  },
  spantitle: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: '14px',
    paddingLeft: '5px'
  }
}));

export default function CustomizedTables(props) {
  const {
    rows,
    // error,
    dbPagination,
    totalCount,
    loading,
    columns,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    actionStructure,
    multiAction,
    noPagination,
    selectable,
    selectableDetail,
    selectableOnly,
    actionDisabled,
    noIndex,
    actionFirst,
    checkbox,
    actionType,
    handleSelect,
    disabled,
    disabledAll,
    isBulk,
    actionBulk,
    bulkIconOption,
    noEmpty,
    imageField,
    largeActionColumn,
    folderImage,
    newDate,
    setisSave
  } = props
  // console.log("🚀 ~ file: index.jsx ~ line 144 ~ CustomizedTables ~ props", props)

  const classes = useStyles();
  const [selectedData, setSelectedData] = useSelected()
  const [selectedImage, setSelectedImage] = React.useState()
  // console.log("🚀 ~ file: index.jsx ~ line 176 ~ CustomizedTables ~ selectedImage", selectedImage)
  const [data, setData] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const countLength = !dbPagination ? rows.length : totalCount
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, countLength - page * rowsPerPage);
  const [isAll, setIsAll] = React.useState(false)
  const [check, setCheck] = React.useState([])
  const [selectedRow, setSelectedRow] = React.useState()
  const [values, setValues] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [title, setTitle] = React.useState(null)
  const [message, setMessage] = React.useState(null)
  const [openInput, setOpenInput] = React.useState(false)
  const [language, langDict, setLanguage] = useLanguage()
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [selectedDetail, setSelectedDetail] = useSelectedDetail();

  React.useEffect(() => {
    if (isAll) {
      setCheck(rows)
    } else {
      setCheck([])
    }
  }, [isAll, rows])

  React.useEffect(() => {
    // console.log("🚀 ~ file: index.jsx ~ line 154 ~ React.useEffect ~ rows", rows)
    setData(rows ?? null)
  }, [rows])


  const handleCheckAll = (e) => {
    const targetVal = e.target.checked
    setIsAll(targetVal)
    if (targetVal) {
      handleSelect(rows)
    } else {
      handleSelect(null)
    }
  }

  const handleClose = () => {
    setOpen(!open)
  }

  const handleCloseInput = () => {
    setOpenInput(!openInput)
  }


  const handleCheck = (event, rowData) => {
    const selectedIndex = check.indexOf(rowData);
    let newSelected = [];

    if (selectedIndex === -1) {
      // rowData[checkedIdentifier] = `${event.target.checked}`
      newSelected = newSelected.concat(check, rowData);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(check.slice(1));
    } else if (selectedIndex === check.length - 1) {
      newSelected = newSelected.concat(check.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        check.slice(0, selectedIndex),
        check.slice(selectedIndex + 1),
      );
    }

    setCheck(newSelected);
    handleSelect(newSelected)
  };

  let nomor = 1

  const handleValue = (column, row, val) => {
    return (
      column.customFormat
        ? column.customFormat(row)
        : column.format && typeof val === 'number'
          ? column.format(val)
          : column.StringFormat && typeof val !== 'number'
            ? column.StringFormat(val)
            : column.default
              ? column.default
              : val
    )
  }
  const switchTableCell = (column, row) => {
    const val = !column?.parentId
      ? row[column.value]
      : !row[column.parentId]
        ? "-"
        : row[column.parentId][column.value]

    const stateValue = handleValue(column, row, val)
    switch (column.type) {
      case "textfield":
        return <TextField
          // margin="dense"
          // value={stateValue}
          // fullWidth
          // onChange={(e) => column.onChange(row, e.target.value, column.id)}
          value={stateValue}
          onChange={(value) => column.onChange(row, value, column.id)}
          id={column.id}
          label={column.inputLabel}
          disabled={column.inputDisabled}
          readOnlyAll={column.readOnlyAll}
          placeholder={column.placeholder}
          selectedData={selectedData}
          helper={column.helper}
          type={column.inputType}
          multiline={column.multiline}
          rows={column.rows}
        />
      case "datefield":
        const date = moment(val).format(column.format || 'DD MMM YYYY HH:mm:ss');
        return <StyledTableCell
          align={column.align}
          style={{ minWidth: column.minWidth }}
          onClick={() => {
            if (selectable) {
              setSelectedData(row);
            }
          }}>
          {date === "Invalid date" ? '-' : date}
        </StyledTableCell>
      case "image":
        return <StyledTableCell
          align={column.align}
          style={{ minWidth: column.minWidth }}
        // onClick={() => {
        //   if (selectable) {
        //     setSelectedData(row);
        //   }
        // }}
        >
          <IconButton
            onClick={(e) => {
              setSelectedImage(row);
              handleClose()
            }}
          >
            <ImageIcon />
          </IconButton>
        </StyledTableCell>
      case "currencyfield":
        return <CurrencyField
          value={stateValue}
          onChange={(value) => column.onChange(row, value, column.id)}
          id={column.id}
          label={column.inputLabel}
          disabled={column.inputDisabled}
          readOnlyAll={column.readOnlyAll}
          placeholder={column.placeholder}
          selectedData={selectedData}
          helper={column.helper}
          type={column.inputType}
          multiline={column.multiline}
          rows={column.rows}
        />
      case "switch":
        return <FormControlLabel
          control={
            <Switch
              checked={stateValue}
              onChange={(e) => column.onChange(row, e.target.checked, column.id)}
              id={column.id}
              disabled={column.inputDisabled}
              helper={column.helper}
              name={column.name}
              color={column.color}
            />
          }
          label={stateValue === true ? 'ACTIVE' : "INACTIVE"}
        />
      case "clickable":
        return <Typography
          style={{ cursor: 'pointer' }}
          variant='h6'
          // color='primary'
          sx={{ color: '#1569B2' }}
          fullWidth
          onClick={() => {
            if (selectable) {
              setSelectedData(row);
            }
            column.onAction(row)
          }}
        >{stateValue}</Typography>
      case "editable":
        return <StyledTableCell className={classes.gridItem}>
          <Typography
            component={'span'}
            style={{ cursor: 'pointer' }}
            variant='h6'
            color='primary'
          // fullWidth
          >
            {stateValue}
          </Typography>
          &nbsp;&nbsp;
          <IconButton
            onClick={() => {
              setSelectedData(
                {
                  ...row,
                  column
                }
              )
              setisSave(false)
              callDialogEdit(row, column)
            }}
          >
            <Edit />
          </IconButton>
        </StyledTableCell >

      default:
        return (
          <StyledTableCell
            align={column.numeric ? 'right' : 'left'}
            style={{ minWidth: column.minWidth }}
            onClick={() => {
              if (selectable) {
                setSelectedData(row);
                // props.changeStep(row)
              }

              // if (selectableOnly) {
              //   props.changeStep(row)
              // }
            }}>
            {
              column.customFormat
                ? column.customFormat(row)
                : column.format && typeof val === 'number'
                  ? column.format(val)
                  : column.StringFormat && typeof val !== 'number'
                    ? column.StringFormat(val)
                    : column.default
                      ? column.default
                      : val
            }
          </StyledTableCell>
        )
    }
  }

  const callDialogEdit = (row) => {
    setTitle("Edit Data")
    setMessage("Complete by filling input")
    setOpenInput(!openInput)
  }

  const renderImage = (val) => {
    // console.log("🚀 ~ file: index.jsx ~ line 395 ~ renderImage ~ val", val)
    const noSpace = val?.replace(/\s/g, '') ?? null;
    // const baseUrl = "https://10.18.5.12:8081/img-part"
    const baseUrl = `${window._env_?.BASEURL_IMAGE}`
    let folder = ""
    if (folderImage === "CarPart") {
      folder = 'img-part'
    } else if (folderImage === "PackingType") {
      folder = 'img-packing'
    }
    return (
      <React.Fragment>
        {val
          ? <img width={'100%'} src={`${baseUrl}/${folderImage}/${val}`} alt={`pic-${noSpace}`} onError={event => {
            event.target.src = addImage
            event.onerror = null
          }} />
          : <img src={addImage}></img>
          // <Typography variant="h5">{language.COMMON.MESSAGE.IMAGE_NOTFOUND}</Typography>
        }
      </React.Fragment>
    )
  }

  const renderInput = (val) => {
    // console.log("🚀 ~ file: index.jsx ~ line 400 ~ renderInput ~ selectedRow", selectedRow)
    return (
      <React.Fragment>
        <TextField
          type={selectedData?.column?.field || 'text'}
          variant="outlined"
          color='primary'
          onChange={handleChangeDate}
        />
      </React.Fragment>
    )
  }

  const handleChangeDate = (e) => {
    newDate(e.target.value)
  }

  const handleSaveDate = (e) => {
    setisSave(true)
    setOpenInput(!openInput)
  }

  const isSelected = (rowData) => {
    const checkData = check?.find(item => item === rowData)
    if (checkData) return true
    return false
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {!checkbox ? null
            : <StyledTableCell
              align="center"
              className={classes.containerIndex}
            >
              <Checkbox
                // indeterminate={ }
                className={classes.checkbox}
                checked={isAll}
                size="small"
                color="secondary"
                onChange={handleCheckAll}
                disabled={disabledAll}
              />
            </StyledTableCell>
          }

          {noIndex ? null
            : <StyledTableCell
              align="center"
            // className={classes.containerIndex}
            >
              NO
            </StyledTableCell>
          }

          {actionFirst
            ? <StyledTableCell
              align="center"
              style={{ minWidth: 50 }}
            >

            </StyledTableCell>
            : null
          }

          {columns.map((column) => (
            <StyledTableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}

              padding={column.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}

          {!multiAction
            ? null
            : <StyledTableCell
              align="center"
              // component="td"
              style={{
                minWidth: !largeActionColumn ? '100px' : largeActionColumn,
                background: '#F1F5F8',
                fontWeight: 'bold'
              }}
            >
              {language.COMMON.TABLE_MY_TRANSACTION.ACTION.toUpperCase()}
            </StyledTableCell>
          }
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  let filteredData = []

  if (noPagination || dbPagination) filteredData = data
  if (!data) filteredData = []
  if (data.length <= 0) filteredData = []
  if (data.length > 0 && !dbPagination) filteredData = data

  // console.log("🚀 ~ file: index.jsx ~ line 614 ~ CustomizedTables ~ filteredData", filteredData)
  const isSelectedNew = (name) => selected.indexOf(name) !== -1;
  return (
    <React.Fragment>
      <HandlerModal
        id="modalImage"
        open={open}
        onClose={handleClose}
        dialogType={"image"}
        message={""}
        title={language.COMMON.MESSAGE.IMAGE_PREVIEW}
        label={{
          positive: language.COMMON.COMMON_BUTTON.CLOSE,
          negative: language.COMMON.COMMON_BUTTON.CLOSE,
        }}
        body={(val) => renderImage(val)}
        action={{
          onClose: () => handleClose(),
          positive: (body, type) => handleClose(body, type),
          negative: () => handleClose()
        }}
        values={selectedImage?.IMG_MAIN ?? selectedImage?.VALUE_04}
        error={""}
        loading={false}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <HandlerModal
        id="modalEdit"
        open={openInput}
        onClose={handleClose}
        dialogType={"input"}
        message={message}
        title={title}
        label={{
          positive: language.COMMON.COMMON_BUTTON.SAVE,
          negative: language.COMMON.COMMON_BUTTON.CLOSE,
        }}
        body={(val) => renderInput(val)}
        action={{
          onClose: () => handleCloseInput(),
          positive: (body, type) => handleSaveDate(body, type),
          negative: () => handleCloseInput()
        }}
        values={selectedRow}
        error={error}
        loading={false}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <TableContainer className={classes.container}>
        {isBulk &&
          <div className={classes.padding}>
            <Tooltip title={bulkIconOption.label}>
              <Button
                // fullWidth
                small
                className={classes.Button}
                variant={bulkIconOption.variant}
                color={bulkIconOption.color}
                onClick={() => actionBulk()}>
                {bulkIconOption.iconBulk} <span className={classes.spantitle}>{bulkIconOption.title}</span>
              </Button>
            </Tooltip>
          </div>
        }
        <Table stickyHeader className={classes.table} aria-label="customized table" size={'small'}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          {loading
            ? <TableBody>
              <TableRow >
                <TableCell align="center" colSpan={columns.length}>
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            </TableBody>
            : data.length === 0
              ? <TableBody>
                <TableRow >
                  <TableCell align="center" colSpan={!actionDisabled ? columns.length + 2 : columns.length + 1}>
                    <Alert severity="warning">{language.COMMON.TABLE.DATA_NOT_FOUND}</Alert>
                  </TableCell>
                </TableRow>
              </TableBody>
              : <TableBody>
                {stableSort(filteredData, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const no = noPagination ? nomor++ : ((nomor++) + (page * rowsPerPage))
                  return (
                    <StyledTableRow
                      classes={classes.hoverTable}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}

                    >
                      {!checkbox ? null :
                        <StyledTableCell
                          className={classes.containerIndex}
                          align="center"
                          onClick={() => {
                            if (selectable) {
                              setSelectedData(row);
                              props.changeStep(row)
                            }

                            if (selectableOnly) {
                              props.changeStep(row)
                            }
                          }}
                        >
                          <Checkbox
                            // indeterminate={ }
                            className={classes.checkbox}
                            checked={isSelected(row, index)}
                            size="small"
                            color="secondary"
                            onChange={(e) => handleCheck(e, row)}
                            disabled={disabled}
                          />
                        </StyledTableCell>
                      }

                      {noIndex ? null :
                        <StyledTableCell
                          // className={classes.containerIndex}
                          align="center"
                          onClick={() => {
                            if (selectable) {
                              setSelectedData(row);
                              props.changeStep(row)
                            }

                            if (selectableOnly) {
                              props.changeStep(row)
                            }
                          }}
                        >
                          {no}
                        </StyledTableCell>
                      }
                      {actionFirst
                        ? <StyledTableCell align="center"

                          onClick={(e) => {
                            if (selectable) {
                              setSelectedData(row);
                            }

                            if (selectableOnly) {
                              props.changeStep(row)
                            }
                          }}
                        >
                          {actionStructure.map((act) => {
                            const { disabled } = act
                            const disabledAct = !disabled ? false : disabled(row);
                            if (disabledAct) return null
                            else return (
                              <Tooltip title={act.label}>
                                <IconButton
                                  // fullWidth
                                  small
                                  className={classes.IconButton}
                                  disabled={disabledAct}
                                  // variant={act.variant}
                                  // color={act.color}
                                  onClick={() => act.onAction(row)}>
                                  {act.icon}
                                </IconButton>
                              </Tooltip>
                            )
                          })}
                        </StyledTableCell>
                        : null
                      }
                      {columns.map((column) => {
                        return (
                          <React.Fragment key={column.value}>
                            {switchTableCell(column, row)}
                          </React.Fragment>
                        );
                      })}
                      {multiAction
                        ? <StyledTableCell align="center"
                          onClick={() => {
                            if (selectable) {
                              setSelectedData(row);
                            }
                            if (selectableDetail) {
                              setSelectedDetail(row);
                            }

                            if (selectableOnly) {
                              props.changeStep(row)
                            }
                          }}
                        >
                          {actionType === 'Button'
                            ? actionStructure.map((act) => {
                              const { disabled } = act
                              const disabledAct = !disabled ? false : disabled(row);
                              if (disabledAct) return null
                              else return (
                                <Tooltip title={act.label}>
                                  <Button
                                    // fullWidth
                                    small
                                    className={classes.Button}
                                    variant={act.variant}
                                    color={act.color}
                                    onClick={() => act.onAction(row)}>
                                    {act.icon}
                                  </Button>
                                </Tooltip>
                              )
                            })
                            : actionStructure.map((act) => {
                              const { disabled } = act
                              const disabledAct = !disabled ? false : disabled(row);
                              if (disabledAct) return null
                              else return (
                                <Tooltip title={act.label}>
                                  <IconButton
                                    // fullWidth
                                    style={{ marginRight: '5px' }}
                                    small
                                    className={classes.IconButton}
                                    // variant={act.variant}
                                    // color={act.color}
                                    onClick={() => act.onAction(row)}>
                                    {act.icon}
                                  </IconButton>
                                </Tooltip>
                              )
                            })}
                        </StyledTableCell>
                        : null
                      }
                    </StyledTableRow>
                  );
                })}
                {noEmpty
                  ? null
                  : <Hidden mdDown>
                    {(emptyRows > 0 && emptyRows > 0) && (
                      <TableRow style={{ height: 33 * emptyRows }}>
                        <TableCell colSpan={!actionDisabled ? columns.length + 2 : columns.length + 1} />
                      </TableRow>
                    )}
                  </Hidden>
                }
              </TableBody>
          }
        </Table>
      </TableContainer>
      {noPagination ? null :
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={!dbPagination ? rows.length : totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </React.Fragment >
  );
}