const common = {
  TITLE: {
    ACCOUNT: "My Account",
    TRANSFER: "Transfer",
    PAYROLL: "Payroll",
    SETTING: "Help & Settings",
    BULK_TRANSFER: "Bulk Transfer",
    KSEI: "KSEI"
  },
  DESC: {
    ACCOUNT: "Select one of the categories below to continue your activity",
    TRANSFER: "Select one of the categories below to continue your activity",
    PAYROLL: "Select one of the categories below to continue your activity",
    BULK_TRANSFER: "Select one of the categories below to continue your activity",
    SETTING: "Select one of the categories below to continue your activity",
    KSEI: "Select one of the categories below to continue your activity",
  },
  CARD: {
    ACCOUNT: {
      CARD1: "Account Details",
      CARD2: "History E-Banking",
      CARD3: "Account Statement",
      CARD4: "Scheduled Transaction",
    },
    TRANSFER: {
      CARD1: "Transfer Beneficiary List",
      CARD2: "Make Transfer",
    },
    PAYROLL: {
      CARD1: "Payroll List",
      CARD2: "Payroll Disbursement",
    },
    BULK_TRANSFER: {
      CARD1: "Bulk Transfer List",
      CARD2: "Make Bulk Transfer",
    },
    KSEI: {
      CARD1: "KSEI Transfer Beneficiary List",
      CARD2: "Transfer to KSEI",
      CARD3: "RDN Balance",
      CARD4: "RDN Statement",
    },
    SETTING: {
      CARD1: "Change Password",
      CARD2: "Branch Location",
      CARD3: "Terms & Conditions",
      CARD4: "FAQ",
      CARD5: "Contact Us",
    },
  },
  CAPTION: {
    ACCOUNT: {
      CARD1: "All the information about your Account",
      CARD2: "Check your previous transactions on Internet Business Banking",
      CARD3: "Check all your transactions info on selected account",
      CARD4: "Manage your scheduled transactions",
    },
    TRANSFER: {
      CARD1: "Add, edit, or delete your beneficiary list",
      CARD2: "Same bank or other bank, real-time or scheduled",
    },
    PAYROLL: {
      CARD1: "Add, edit, or delete payroll list",
      CARD2: "Transfer your payroll real-time or scheduledl",
    },
    BULK_TRANSFER: {
      CARD1: "Add, edit, or delete Bulk Transfer list",
      CARD2: "Transfer your Bulk Transfer real-time or scheduledl",
    },
    KSEI: {
      CARD1: "Add, edit, or delete your KSEI beneficiary list",
      CARD2: "Make transfer to KSEI",
      CARD3: "Check last RDN balance",
      CARD4: "Check all your transactions RDN",
    },
    SETTING: {
      CARD1: "Change your old Password",
      CARD2: "See all of Bank Capital's branches",
      CARD3: "Term & Conditions about Internet Banking Business",
      CARD4: "See all information about Internet Banking Business",
      CARD5: "Get in touch with our customer support team",
    },
  },
  BREADCRUMBS: {
    '/bussiness': '',
    '/setting': 'Setting',
    '/setting/faq': 'FAQ',
    '/setting/branch': 'Branch Location',
    '/setting/term': 'Terms & Conditions',
    '/setting/change-password': 'Change Password',
    '/setting/contact': 'Contact Us',
    '/account': 'Account',
    '/account/detail': 'Detail',
    '/account/history': 'History E-Banking',
    '/account/statement': 'Statement',
    '/account/scheduled': 'Scheduled Transaction',
    '/account/scheduled/detail': 'Detail',
    '/dashboard': 'Dashboard',
    '/dashboard/ongoing': 'Ongoing Task',
    '/dashboard/ongoing/detail': 'Detail',
    '/dashboard/ongoing/detail-task': 'Detail',
    '/dashboard/past-task': 'Past Task',
    '/dashboard/past-task/detail': 'Detail',
    '/transfer': 'Transfer',
    '/transfer/detail': 'Detail',
    '/transfer/add': 'Add',
    '/transfer/confirm': 'Confirm',
    '/transfer/result': 'Result',
    '/transfer/beneficiary': 'Beneficiary List',
    '/transfer/beneficiary/add': 'Add',
    '/transfer/beneficiary/edit': 'Edit',
    '/transfer/beneficiary/confirm': 'Confirmation',
    '/transfer/past-task': 'Past Task',
    '/transfer/past-task/detail': 'Detail',
    '/payroll': 'Payroll',
    '/payroll/disbursement': 'Disbursement',
    '/payroll/list': 'Payroll List',
    '/payroll/list/detail': 'Detail',
    '/payroll/list/detail/employee-detail': 'Employee Detail',
    '/payroll/list/detail/employee-add': 'Employee Add',
    '/payroll/list/detail/employee-edit': "Employee Edit",
    '/payroll/list/detail/employee-detail/group': 'Add Autodebet',
    '/payroll/list/detail/employee-detail/group/edit': 'Edit Autodebet',
    '/payroll/list/add': 'Add Payroll',
    '/payroll/list/detail/edit': 'Edit',
    '/payroll/list/bulk': 'Bulk Add',
    '/payroll/list/employee': 'Employee Detail',
    '/payroll/list/employee-add': 'Employee Add',
    '/payroll/list/employee-edit': 'Employee Edit',
    '/payroll/list/upload-status': 'Last Upload Status',
    '/ksei': 'Ksei',
    '/ksei/beneficiary': 'Beneficiary List',
    '/ksei/beneficiary/add': 'Add',
    '/ksei/add': 'Add',
  }
}

export default common