import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import { useOurProject, useSelected } from 'hooks'
import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router'
import FlowComponent from './flow'

function Index() {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables] = useOurProject(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)

  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  const [step, setStep] = React.useState('List')
  const [selected, setSelected] = useSelected()
  const classes = useStyles()

  useEffect(() => {
    if (step === "List") {
      const param = {
        page: 1,
        limit: 10
      }
      setDatatables(param)
    }

  }, [step])

  // const breadcrumb = [
  //   {
  //     title: "Home", to: "/"
  //   }
  // ]

  useEffect(() => {
    if (query) {
      setStep(query || "List")
    }
  }, [query])

  const handleChangeStep = (val, data) => {
    handlePush(val, data)
    setStep(val)
  }

  const handlePush = (type, data) => {
    navigate({
      search: `?act=${type}&ID=${data.ID}`
    })
  }

  const handleFlow = async (submit, type) => {
    if (type === 'detail') {
      await setSelected(submit)
      handleChangeStep('Detail', submit)
    } else if (type === 'list') {
      //   await initFetch();
      handleChangeStep('List')
    }
  }

  return (
    <FlowComponent
      changeStep={handleChangeStep}
      data={datatables ?? []}
      loading={isLoading}
      error={error}
      step={step}
      handleSubmit={handleFlow}
      // param={param}
      selected={selected}
      onSelect={setSelected}
      checkingApprove={(body, type) => handleFlow(body, type)}

    />
  )
}

export default Index
