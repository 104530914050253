import * as React from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Typography } from '@mui/material';

export default function MaxHeightTextarea(props) {
    const {
        classes,
        param,
      } = props
    const initialDate = !param ? "" : !param.value ? "" : param.value
    const [value, setValue] = React.useState(initialDate)
    // console.log("🚀 ~ file: TextAreaField.jsx ~ line 11 ~ MaxHeightTextarea ~ value", value)
    const handleChange = (e) => {
        param.onChange(param?.id, e?.target?.value)
        setValue(e.target.value)
        // console.log("🚀 ~ file: TextAreaField.jsx ~ line 16 ~ handleChange ~ e", e)
    }
  return (
    <div style={{marginTop: '7px'}}>
      <TextareaAutosize
      className={classes?.itemContent}
      minRows={4}
        maxRows={5}
        aria-label="maximum height"
        placeholder= {param.placeholder ?? ''}
        defaultValue={param?.value || ''}
        style={{ width: '100%', fontSize: '13px', padding: '2px', fontFamily: 'Roboto' }}
        onChange={handleChange}
        id={param.id}
        value={value || param?.value}
        required={param.required}
        disabled={param.disabled}
      />
      <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
    </div>
          
  );
}