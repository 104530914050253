const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export default function structuredefault(values, handleFieldChange,open,categoryOption) {
  const data = [
    {
      id: 'INVOICE_NO',
      label: 'Order ID',
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Category Code',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["INVOICE_NO"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PURCHASE_DATE',
      label: 'Purchased Date/Time',
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Purchased Date/Time',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PURCHASE_DATE"] || '',
      minWidth: 170,
      // hide: isHide
    },
   
    {
      id: 'LATEST_NEGOTIATION',
      label: 'Rev ID',
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Rev ID',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["LATEST_NEGOTIATION"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'PURCHASE_STATUS',
      label: 'Status',
      type: 'textwithbutton',
      fieldtype: 'textwithbutton',
      // disabled: true,
      placeholder: 'Insert Status',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["PURCHASE_STATUS"] || 'No Status',
      minWidth: 170,
      // hide: isHide,
      open: open
    },
    {},
    {
      id: 'SHIPPING_ETD',
      label: 'Delivery Time',
      type: 'date',
      fieldtype: 'datepicker',
      disabled: true ,
      required: true,
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["SHIPPING_ETD"] || '2022-04-20 17:37:45.728',
      minWidth: 170,
      minDate: true,
      // hide: isHide
      placeholder: 'Insert Delivery Time',
    },
  ]

  return data
};