const prod = [
    {
      id: 1,
      _key: 'YES',
      name: 'YES'
    },
    {
      id: 2,
      _key: 'NO',
      name: 'NO'
    }
  ]
  export default function structuredefault(values, handleFieldChange, provinsiOptions, kabupatenOptions, zipcodeOptions) {
    const data = [
      {
        id: 'ADDRESS_NAME',
        label: 'Nama Alamat',
        type: 'text',
        fieldtype: 'textfield',
        required: true,
        // disabled: true,
        placeholder: 'Insert Nama Alamat',
        onChange: (field, value) => handleFieldChange(field, value),
        value: values["ADDRESS_NAME"] || '',
        minWidth: 170,
        // hide: isHide
      },
      {
        id: 'ADDRESS_PROVINSI',
        label: 'Provinsi',
        type: 'select',
        fieldtype: 'suggestfield',
        onChange: (field, value) => handleFieldChange(field, value),
        value: values["ADDRESS_PROVINSI"] ?? '',
        minWidth: 170,
        hide: false,
        option: provinsiOptions?.length === 0 ? [] : provinsiOptions?.map((data,idx) => {
          return {
            id: idx,
            value: data,
            label: data,
            data: data
          }
        }),
        placeholder: 'Select Provinsi',
        required: true,
      },
      {
        id: 'ADDRESS_KABUPATEN',
        label: 'Kota/Kabupaten',
        type: 'select',
        fieldtype: 'suggestfield',
        onChange: (field, value) => handleFieldChange(field, value),
        value: values["ADDRESS_KABUPATEN"] ?? '',
        minWidth: 170,
        hide: false,
        option: kabupatenOptions?.length === 0 ? [] : kabupatenOptions?.map((data,idx) => {
          return {
            id: idx,
            value: data,
            label: data,
            data: data
          }
        }),
        placeholder: 'Select Kota/Kabupaten',
        required: true,
      },
      // {
      //   id: 'ZIPCODE',
      //   label: 'Zipcode',
      //   type: 'select',
      //   fieldtype: 'suggestfield',
      //   onChange: (field, value) => handleFieldChange(field, value),
      //   value: values["ZIPCODE"] ?? '',
      //   minWidth: 170,
      //   hide: false,
      //   option: zipcodeOptions?.length === 0 ? [] : zipcodeOptions?.map((data,idx) => {
      //     return {
      //       id: idx,
      //       value: data,
      //       label: data,
      //       data: data
      //     }
      //   }),
      //   placeholder: 'Select Zipcode',
      //   required: true,
      // },
      {
        id: 'ZIPCODE',
        label: 'Zipcode',
        type: 'text',
        fieldtype: 'numberfield',
        required: true,
        // disabled: true,
        placeholder: 'Insert Zipcode',
        onChange: (field, value) => handleFieldChange(field, value),
        value: values["ZIPCODE"] || '',
        minWidth: 170,
        // hide: isHide
      },
      {
        id: 'ADDRESS',
        label: 'Address',
        type: 'text',
        fieldtype: 'textArea',
        // disabled: true,
        required: true,
        onChange: (field, value) => handleFieldChange(field, value),
        value: values["ADDRESS"] || '',
        minWidth: 170,
        // hide: isHide
        placeholder: 'Insert Address',
        required: true,
      },
      
      {
        id: 'IS_CURRENT',
        label: 'Jadikan Alamat Utama',
        type: 'select',
        fieldtype: 'suggestfield',
        onChange: (field, value) => handleFieldChange(field, value),
        value: values["IS_CURRENT"] ?? 'NO',
        minWidth: 170,
        hide: false,
        option: prod?.length === 0 ? [] : prod?.map(data => {
          return {
            id: data.id,
            value: data._key,
            label: data.name,
            data: data
          }
        }),
        placeholder: 'Select Jadikan Alamat Utama',
      },
    ]
  
    return data
  };