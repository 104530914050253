import React, { useState, useEffect } from 'react'
import { makeStyles } from "@mui/styles";
import { BreadCrumbs, RangeSlider } from 'components'
import {
  Paper,
  Grid,
  ListItemIcon,
  Pagination,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  TextField,
  Divider, Tooltip, useMediaQuery, Box, Drawer
} from '@mui/material'
import { rupiahFormat } from 'helpers'
import noImage from "assets/images/noImage.jpeg"
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router'
import { Filter } from '../component'
import { useSelector } from 'react-redux'
import { useLanguage } from 'hooks';
import { CloseOutlined, FilterAltOutlined } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    height: 'auto',
    minWidth: '1700px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  contentSorter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    width: '300px',
    height: "auto",
    paddingTop: 10,
    paddingLeft: 18,
    [theme.breakpoints.up('xl')]: {
      width: '1400px',
    },
    width: '1200px',

  },
  contentBar: {
    paddingTop: '10px',
    [theme.breakpoints.up('xl')]: {
      width: '1400px',
    },
    width: '1200px',

  },
  content: {
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-end',
    width: '300px',
    height: "auto",
    paddingTop: 10,
    paddingLeft: 10
  },
  filterBar: {
    width: '300px',
    height: "70vh",
    marginTop: "20px",
    paddingTop: 10,
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  filter: {
    width: '100%',
    height: "auto",
    paddingLeft: 10,
    paddingRight: 10,

  },
  filterPrice: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingLeft: 5,
  },
  breadcrumb: {
    fontSize: '24px'
  },
  card: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    '&:hover': {
      border: '1px solid #E2E2E2'
    },
    [theme.breakpoints.down('md')]: {
      width: 180,
    },
    [theme.breakpoints.down('sm')]: {
      width: 150,
      padding: theme.spacing(1, 0),
    },
  },
  image: {
    width: 150,
    height: 150,
    [theme.breakpoints.down('md')]: {
      width: 120,
      height: 120,
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
      height: 100,
    },
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    height:45
  }

}))

function ListStep(props) {

  const {
    data,
    setPage,
    setFilter,
    handleSubmit,
    pageType,
    totalPage,
    selectedType,
    subCategory,
    handleFilter,
    setFilterSpec,
    filterSpec,
    setFilterPrice,
    filterPrice,
    limit
  } = props
  console.log("🚀 ~ file: list.jsx ~ line 123 ~ ListStep ~ props", props)
  const classes = useStyles()
  const navigate = useNavigate()
  const [language, lagDic, setLang] = useLanguage()
  const breadcrumb = [
    {
      title: "Home", to: "/furnitur"
    }]
  const [isTyped, setisTyped] = useState(false)
  const [valueRange, setValueRange] = React.useState([]);
  const [selectedItem, setselectedItem] = useState({});
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openMobileFIlter, setOpenMobileFIlter] = useState(false)
  const [open, setOpen] = React.useState({});
  console.log("🚀 ~ file: list.jsx ~ line 141 ~ ListStep ~ filterPrice", filterPrice)


  const totalItems = useSelector(state => state.product?.items?.completed_response?.data?.INFO_PAGE?.TOTAL_RECORD);

  useEffect(() => {
    setFilterPrice({
      min: valueRange[0],
      max: valueRange[1]
    })
  }, [valueRange])

  useEffect(() => {
    setValueRange([
      limit?.[0]?.MIN,
      limit?.[0]?.MAX
    ])
  }, [limit])

  useEffect(() => {
    if (isTyped === true) {
      setValueRange([
        filterPrice?.min,
        filterPrice?.max
      ])
      setisTyped(false)
    }
  }, [isTyped])
  const baseUrlImage = window._env_?.BASEURL_IMAGE

  const clearFilter = () => {
    setselectedItem({})
    setFilter({})
    setValueRange([
      limit?.[0]?.MIN,
      limit?.[0]?.MAX
    ])
    setFilterSpec([])
    setOpen({})

  }
  const webPage = () => {
    return (
      <div className={classes.container}>
        <div >
          <BreadCrumbs
            breadcrumb={breadcrumb}
            current={'Shop By Range'}
          />
        </div>
        <Grid
          container
          spacing={3}
          className={classes.content}>
          <div className={classes.filterHeader}>
            <Grid
              item sx={{ display: 'flex', alignSelf: 'flex-end' }}>
              <strong>{language.COMMON.PRODUCT_LIST.FILTER}</strong>
            </Grid>
            <Grid
              item sx={{ display: 'flex', alignSelf: 'flex-end' }} >
              <Typography sx={{ cursor: 'pointer', color: '#22577E' }} onClick={clearFilter} variant="body1" color="initial">
                {language.COMMON.PRODUCT_LIST.CLEAR_FILTER}
              </Typography>
            </Grid>
          </div>
          <div className={classes.contentSorter}>
            <Grid
              item xs={4} md={6} sx={{ display: 'flex', alignSelf: 'flex-end' }}>
              <strong>{language.COMMON.PRODUCT_LIST.SHOW} {totalItems ?? '0'} {language.COMMON.PRODUCT_LIST.ITEMS}</strong>
            </Grid>
            <Grid
              item xs md sx={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end', }} >
              <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
                <Select
                  labelId="sorting"
                  id="sorting"
                  name='sorting'
                  defaultValue={"ITM.CREATED_DATE DESC"}
                  // value={age}
                  onChange={(event) => { handleFilter(event.target.name, event.target.value) }}
                  autoWidth
                >
                  <MenuItem selected value="ITM.CREATED_DATE DESC">
                    {language.COMMON.PRODUCT_LIST.DEFAULT}
                  </MenuItem>
                  <MenuItem value="ITM.ITEM_NAME ASC">
                    <ListItemIcon>
                      <SortByAlphaIcon />
                    </ListItemIcon>
                    {language.COMMON.PRODUCT_LIST.ALPHA}
                  </MenuItem>
                  <MenuItem value="SELL_PRICE DESC">
                    <ListItemIcon>
                      <ArrowDownwardIcon />
                    </ListItemIcon>
                    {language.COMMON.PRODUCT_LIST.PRICE_DESC}
                  </MenuItem>
                  <MenuItem value="SELL_PRICE ASC">
                    <ListItemIcon>
                      <ArrowUpwardIcon />
                    </ListItemIcon>
                    {language.COMMON.PRODUCT_LIST.PRICE_ASC}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </div>

        </Grid>
        {/* Filter */}
        <Grid
          container
          spacing={2}
          className={classes.content}
        >
          <div className={classes.filterBar}>
            <Paper className={classes.filter}>
              <strong>{language.COMMON.PRODUCT_LIST.FILTER_BY_PRICE}</strong>
              <div className={classes.filterPrice}>
                <div style={{ paddingRight: 5 }}>
                  <NumberFormat
                    style={{ width: 100, fontSize: 11, border: '0px' }}
                    thousandsGroupStyle="thousand"
                    value={filterPrice.min || 0}
                    onFocus={event => {
                      event.target.select();
                    }}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      {
                        value !== 0
                          &&
                          setFilterPrice({ ...filterPrice, min: value });
                      }
                      setisTyped(!isTyped)
                    }}
                    prefix="Rp."
                    decimalSeparator="."
                    customInput={TextField}
                    variant='standard'
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false} />
                </div>
                <div style={{ float: 'right', paddingBottom: 5 }}>
                  <NumberFormat
                    style={{ width: 100, fontSize: 11, border: '0px' }}
                    thousandsGroupStyle="thousand"
                    value={filterPrice.max || 0}
                    onFocus={event => {
                      event.target.select();
                    }}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      {
                        value != 0
                          &&
                          setFilterPrice({ ...filterPrice, max: value });
                      }
                      setisTyped(!isTyped)
                    }}
                    prefix="Rp."
                    decimalSeparator="."
                    customInput={TextField}
                    variant='standard'
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false} />
                </div>
              </div>
              <RangeSlider
                minDistance={10000}
                minRange={limit?.[0]?.MIN}
                maxRange={limit?.[0]?.MAX}
                value={valueRange.length > 0 ? valueRange : [0, 400000]}
                setvalue={setValueRange}
              />

              <Filter
                type={'Functionality'}
                selectedType={selectedType}
                subCategory={subCategory}
                handleFilter={handleFilter}
                setFilterSpec={setFilterSpec}
                filterSpec={filterSpec}
                open={open}
                setOpen={setOpen}
                selectedItem={selectedItem}
                setselectedItem={setselectedItem}
              />

            </Paper>
          </div>

          {/* Content */}
          <div className={classes.contentBar}>
            <Divider variant="middle" sx={{ marginBottom: 3 }} />
            <Grid container rowSpacing={3} columnSpacing={{ xl: 2 }}  >
              {data.length > 0
                ? data.map((item) => (
                  <Grid item xl={2} lg={2} md={2} sm={2} xs={2} sx={{ display: 'flex', justifyContent: 'center', px: -5 }}>
                    <Card sx={{ width: 180 }}>
                      <Tooltip title={item?.PRODUCT_NAME}>
                        <CardActionArea
                          onClick={(e) => handleSubmit(item, 'detail')}
                        >
                          <CardMedia
                            component="img"
                            height={120}
                            width={60}
                            sx={{ objectFit: 'scale-down', mt: 3 }}
                            src={`${baseUrlImage}${item?.EXTRA_IMAGES?.[0]?.IMG_PATH}${item?.EXTRA_IMAGES?.[0]?.IMG_THUMB}`}
                            onError={event => {
                              event.target.src = noImage
                              event.onerror = null
                            }}
                            alt=""
                          />
                          <CardContent sx={{ display: 'flex', flexDirection: 'column', }}>
                            <Typography className={classes.multiLineEllipsis} variant="h5" >
                              {item?.PRODUCT_NAME}
                            </Typography>
                            {item?.IS_DISCOUNT ?
                              <div style={{ textDecoration: 'line-through', lineHeight: 1.6 }}>
                                <Typography color={'red'}>{rupiahFormat(`${item?.SELL_PRICE}`, 'Rp')}</Typography>
                              </div>
                              : null
                            }
                            {item?.IS_DISCOUNT
                              ? <Typography sx={{ display: 'flex', alignItems: 'flex-end' }} fontSize={16} color={'#4a84aa'}> <strong> {rupiahFormat(`${item?.SELL_PRICE_DISCOUNT}`, 'Rp')} </strong></Typography>
                              : <Typography sx={{ display: 'flex', alignItems: 'flex-end' }} fontSize={16} color={'#4a84aa'}> <strong> {rupiahFormat(`${item?.SELL_PRICE}`, 'Rp')} </strong></Typography>
                            }
                          </CardContent>
                        </CardActionArea>
                      </Tooltip>
                    </Card>
                  </Grid>
                ))

                : <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography align='center' variant="body1" color="initial"> <strong>Product not found</strong> </Typography>
                </Grid>
              }
            </Grid>

            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
              <Stack spacing={2}>
                <Pagination shape="rounded" color="clarity" count={totalPage} onChange={(event, value) => setPage(value)} />
              </Stack>
            </div>
          </div>
        </Grid>

      </div>
    )
  }

  const mobilePage = () => {
    return (
      <Box>
          <Box sx={{zIndex: '10',display: "flex", justifyContent: "space-between", position: "fixed", padding: "25px 10px", width : "100%", backgroundColor: "#ffffff"}}>
            <Box sx={{display: "flex", alignItems: 'center'}} onClick={()=> setOpenMobileFIlter(true)}>
              <FilterAltOutlined color="secondary"></FilterAltOutlined>
              <Typography fontWeight={"bold"}>Filter</Typography>
            </Box>
            <Grid
              item xs md sx={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end', }} >
              <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
                {/* <InputLabel id="sorting-label">Default Sorting</InputLabel> */}
                <Select
                  labelId="sorting"
                  id="sorting"
                  name='sorting'
                  defaultValue={"ITM.CREATED_DATE DESC"}
                  // value={age}
                  onChange={(event) => { handleFilter(event.target.name, event.target.value) }}
                  autoWidth
                >
                  <MenuItem selected value="ITM.CREATED_DATE DESC">
                    {language.COMMON.PRODUCT_LIST.DEFAULT}
                  </MenuItem>
                  <MenuItem value="ITM.ITEM_NAME ASC">
                    <ListItemIcon>
                      <SortByAlphaIcon />
                    </ListItemIcon>
                    {language.COMMON.PRODUCT_LIST.ALPHA}
                  </MenuItem>
                  <MenuItem value="SELL_PRICE DESC">
                    <ListItemIcon>
                      <ArrowDownwardIcon />
                    </ListItemIcon>
                    {language.COMMON.PRODUCT_LIST.PRICE_DESC}
                  </MenuItem>
                  <MenuItem value="SELL_PRICE ASC">
                    <ListItemIcon>
                      <ArrowUpwardIcon />
                    </ListItemIcon>
                    {language.COMMON.PRODUCT_LIST.PRICE_ASC}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Box>
          <Grid container rowSpacing={3} columnSpacing={{ xl: 2 }} sx={{paddingTop:"70px"}} >
              {data.length > 0
                ? data.map((item) => (
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6} sx={{ display: 'flex', justifyContent: 'center', px: -5 }}>
                    <Card sx={{ width: 180 }}>
                      <Tooltip title={item?.PRODUCT_NAME}>
                        <CardActionArea
                          //  onClick={()=> {navigate('/product-detail')}} 
                          onClick={(e) => handleSubmit(item, 'detail')}
                        >
                          <CardMedia
                            component="img"
                            height={120}
                            width={60}
                            sx={{ objectFit: 'scale-down', mt: 3 }}
                            src={`${baseUrlImage}${item?.EXTRA_IMAGES?.[0]?.IMG_PATH}${item?.EXTRA_IMAGES?.[0]?.IMG_THUMB}`}
                            onError={event => {
                              event.target.src = noImage
                              event.onerror = null
                            }}
                            alt=""
                          />
                          <CardContent>
                            {/* <Box
                              fontSize="h5.fontSize"
                              component="div"
                              classes={{ root: classes.customBox }}
                            >
                              {item?.PRODUCT_NAME}
                            </Box> */}
                            <Typography className={classes.multiLineEllipsis} variant="h5">
                              {item?.PRODUCT_NAME}
                            </Typography>
                            {item?.IS_DISCOUNT ?
                              <div style={{ textDecoration: 'line-through', lineHeight: 1.6 }}>
                                <Typography color={'red'}>{rupiahFormat(`${item?.SELL_PRICE}`, 'Rp')}</Typography>
                              </div>
                              : null
                            }
                            {item?.IS_DISCOUNT
                              ? <Typography fontSize={16} color={'#4a84aa'}> <strong> {rupiahFormat(`${item?.SELL_PRICE_DISCOUNT}`, 'Rp')} </strong></Typography>
                              : <Typography fontSize={16} color={'#4a84aa'}> <strong> {rupiahFormat(`${item?.SELL_PRICE}`, 'Rp')} </strong></Typography>
                            }
                          </CardContent>
                        </CardActionArea>
                      </Tooltip>
                    </Card>
                  </Grid>
                ))

                : <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{marginTop: "20px"}}>
                  <Typography align='center' variant="body1" color="initial"> <strong>Product not found</strong> </Typography>
                </Grid>
              }
            </Grid>

            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0px 20px 0px' }}>
              <Stack spacing={2}>
                <Pagination shape="rounded" color="clarity" count={totalPage} onChange={(event, value) => setPage(value)} />
              </Stack>
            </div>
      </Box>
    )
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenMobileFIlter(open)
  };

  const mobileFilter = () => {
    return ( 
      <Drawer
      anchor={"bottom"}
      open={openMobileFIlter}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      >
          <Paper sx={{height: "70vh", padding: "20px",  borderTopLeftRadius: 20,
            borderTopRightRadius: 20,overflowY: "auto", '&::-webkit-scrollbar': {
              display: 'none'
            }}} >
              <Box  sx={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                <Box sx={{display: "flex", justifyItems: "center", alignItems: "center"}} onClick={()=> setOpenMobileFIlter(false)}>
                  <CloseOutlined></CloseOutlined>
                  <Typography>FILTER</Typography>
                </Box>
                <Grid
                  item sx={{ display: 'flex', alignSelf: 'flex-end' }} >
                  <Typography noWrap sx={{ cursor: 'pointer', color: '#22577E' }} onClick={clearFilter} variant="body1" color="initial">
                    {language.COMMON.PRODUCT_LIST.CLEAR_FILTER}
                  </Typography>
                </Grid>
              </Box>
              <strong>{language.COMMON.PRODUCT_LIST.FILTER_BY_PRICE}</strong>
              <div className={classes.filterPrice}>
                <div style={{ paddingRight: 5 }}>
                  <NumberFormat
                    style={{ width: 100, fontSize: 11, border: '0px' }}
                    thousandsGroupStyle="thousand"
                    value={filterPrice.min || 0}
                    onFocus={event => {
                      event.target.select();
                    }}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      {
                        value !== 0
                          &&
                          setFilterPrice({ ...filterPrice, min: value });
                      }
                      setisTyped(!isTyped)
                    }}
                    prefix="Rp."
                    decimalSeparator="."
                    customInput={TextField}
                    variant='standard'
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false} />
                </div>
                <div style={{ float: 'right', paddingBottom: 5 }}>
                  <NumberFormat
                    style={{ width: 100, fontSize: 11, border: '0px' }}
                    thousandsGroupStyle="thousand"
                    value={filterPrice.max || 0}
                    onFocus={event => {
                      event.target.select();
                    }}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      {
                        value !== 0
                          &&
                          setFilterPrice({ ...filterPrice, max: value });
                      }
                      setisTyped(!isTyped)
                    }}
                    prefix="Rp."
                    decimalSeparator="."
                    customInput={TextField}
                    variant='standard'
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={false} />
                </div>
              </div>
              <RangeSlider
                minDistance={10000}
                minRange={limit?.[0]?.MIN}
                maxRange={limit?.[0]?.MAX}
                value={valueRange.length > 0 ? valueRange : [0, 400000]}
                setvalue={setValueRange}
              />

              <Filter
                type={'Functionality'}
                selectedType={selectedType}
                subCategory={subCategory}
                handleFilter={handleFilter}
                setFilterSpec={setFilterSpec}
                filterSpec={filterSpec}
                open={open}
                setOpen={setOpen}
                selectedItem={selectedItem}
                setselectedItem={setselectedItem}
              />  

            </Paper>
          </Drawer>
    )
  }
  return (
    <React.Fragment>
      {
        matches ? mobilePage() : webPage()
      }
      {
        mobileFilter()
      }
    </React.Fragment >

  );
}

export default ListStep;
