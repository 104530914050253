const common = {
  TITLE: {
    ACCOUNT: "Akun Saya",
    TRANSFER: "Transfer",
    PAYROLL: "Penggajian",
    SETTING: "Bantuan & Pengaturan",
  },
  DESC: {
    ACCOUNT: "Pilih salah satu kategori di bawah ini untuk melanjutkan aktivitas Anda",
    TRANSFER: "Pilih salah satu kategori di bawah ini untuk melanjutkan aktivitas Anda",
    PAYROLL: "Pilih salah satu kategori di bawah ini untuk melanjutkan aktivitas Anda",
    SETTING: "Pilih salah satu kategori di bawah ini untuk melanjutkan aktivitas Anda",
  },
  CARD: {
    ACCOUNT: {
      CARD1: "Detail Rekening",
      CARD2: "Riwayat Transaksi Elektronik",
      CARD3: "Mutasi Rekening",
      CARD4: "Transaksi Terjadwal",
    },
    TRANSFER: {
      CARD1: "Daftar Rekening Tujuan",
      CARD2: "Buat Transfer",
    },
    PAYROLL: {
      CARD1: "Daftar Penggajian",
      CARD2: "Pembayaran Gaji",
    },
    SETTING: {
      CARD1: "Ganti Sandi",
      CARD2: "Lokasi Cabang",
      CARD3: "Syarat & Ketentuan",
      CARD4: "FAQ",
      CARD5: "Hubungi Kami",
    },
  },
  CAPTION: {
    ACCOUNT: {
      CARD1: "Semua informasi tentang Rekening Anda",
      CARD2: "Periksa transaksi Elektronik Anda di Internet Business Banking",
      CARD3: "Periksa semua info transaksi Anda pada rekening yang dipilih",
      CARD4: "Kelola transaksi terjadwal Anda",
    },
    TRANSFER: {
      CARD1: "Tambah, Edit atau Hapus Daftar Rekening Tujuan",
      CARD2: "ke sesama bank atau ke bank lain, langsung atau terjadwal",
    },
    PAYROLL: {
      CARD1: "Tambah, Edit atau Hapus Daftar Penggajian",
      CARD2: "Transfer Penggajian secara langsung atau terjadwal",
    },
    SETTING: {
      CARD1: "Ganti Sandi Lama Anda",
      CARD2: "Lihat semua cabang Bank Capital",
      CARD3: "Syarat dan Ketentuan terkait Internet Business Banking",
      CARD4: "Lihat semua informasi terkait Internet Business Banking",
      CARD5: "Hubungi tim dukungan pelanggan kami",
    },
  }
}

export default common