import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Button, FormControl, Box } from '@mui/material';
import {
  CustomBackdrop,
  HandlerModal,
} from 'components'
import { getAccessLog, getDayNow, getFormattedDay } from 'helpers'
import getDayPicker from 'helpers/getDayPickerNew';
import {
  useInput, useLkppOrder, useNegotiation, useRetailOrder,
} from 'hooks'
import { validateSign } from 'controllers/_validate'
import GeneralInformation from './components/General'
import ShippingInformation from './components/Shipping'
import PaymentInformation from './components/PaymentDetail'
import ProductInformation from './components/ProductDetail'
import ShippingAddress from './components/ShippingAddress'
import { useCategory, useLookup } from 'hooks';
import { CustomModal } from 'components';
import AddComponent from './components/ModalApprove';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    // paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(10)
    // alignContent: 'flex-end'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(3),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: '100%'
    }
  },
  header: {
    backgroundColor: '#fff'
  },
  container: {
    maxHeight: '100%',
  },
  inputLeft: {
    paddingRight: theme.spacing(1.5)
  },
  inputRight: {
    paddingLeft: theme.spacing(1.5)
  },
  buttonCenter: {
    marginTop: theme.spacing(5)
  },
  trailCenter: {
    marginTop: theme.spacing(3)
  }
}));

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const {
    changeStep,
    // step,
    // selected,
    // loading
  } = props
  const selected = useSelector(state => state.selected)
  const readStatus = (stat) => {
    if (stat === '1:Admin Initiate') {
      return 'Waiting Admin add Delivery Time'
    } else if (stat === '2:Customer Approval') {
      return 'Waiting for Customer Approval'
    } else if (stat === '3:Admin Approval') {
      return 'Waiting for Admin Approval'
    } else if (stat === '4:Deal') {
      return 'Deal'
    } else if (stat === '5:Cancelled') {
      return 'Cancelled'
    } else {
      return 'Null'
    }
  }
  const initValue = {
    INVOICE_NO: selected?.INVOICE_NO,
    CUSTOMER_NAME: selected?.CUSTOMER_NAME,
    PURCHASE_DATE: selected?.PURCHASE_DATE,
    PRICE_FULL: selected?.PRICE_FULL,
    PURCHASE_STATUS: readStatus(selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS ?? 'null'),
    PAYMENT_DATE: selected?.PAYMENT_DATE,
    PHONE_NO: selected?.SHIPPING?.[0]?.PHONE ?? '',
    ADDRESS: selected?.SHIPPING?.[0]?.ADDRESS ?? '',
    SHIPPING_COURIER: selected?.SHIPPING?.[0]?.SHIPPING_COURIER ?? '',
    SHIPPING_ETD: selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS === '1:Admin Initiate' ? '' : (selected?.SHIPPING?.[0]?.SHIPPING_ETD ?? ''),
    COMPANY_NAME: selected?.COMPANY_NAME,
    SHIPPING_NO: selected?.SHIPPING?.[0]?.SHIPPING_NO ?? '',
    SHIPPING_PRICE: selected?.SHIPPING?.[0]?.SHIPPING_PRICE ?? '',
    ADDRESS_KABUPATEN: selected?.SHIPPING?.[0]?.ADDRESS_KABUPATEN ?? '',
    ADDRESS_PROVINSI: selected?.SHIPPING?.[0]?.ADDRESS_PROVINSI ?? '',
    LATEST_NEGOTIATION: selected?.LATEST_NEGOTIATION,
    // PRICE_ITEMS: Number(selected?.PRICE_ITEMS),
    PRICE_ITEMS: selected?.PURCHASE_ITEM?.length > 0 ? selected?.PURCHASE_ITEM?.reduce((n, { SUB_TOTAL_NEGO }) => n + SUB_TOTAL_NEGO, 0) : 0,
    // PRICE_SHIPPING: Number(selected?.PRICE_SHIPPING),
    PRICE_SHIPPING: selected?.SHIPPING?.length > 0 ? selected?.SHIPPING?.reduce((n, { SHIPPING_NEGO }) => n + SHIPPING_NEGO, 0) : 0,
    PRICE_FULL: selected?.PURCHASE_ITEM?.length > 0 ? selected?.PURCHASE_ITEM?.reduce((n, { SUB_TOTAL_NEGO }) => n + SUB_TOTAL_NEGO, 0) + selected?.SHIPPING.reduce((n, { SHIPPING_NEGO }) => n + SHIPPING_NEGO, 0) : 0,
    BERGAIN_SHIPPING: 0
  }

  // const [shippValue, setShipValue] = useState(initValue)
  // console.log("🚀 ~ file: index.jsx ~ line 88 ~ StickyHeadTable ~ shippValue", shippValue)
  const [handleFieldChange, values] = useInput(validateSign, initValue ?? {})
  // console.log("🚀 ~ file: index.jsx ~ line 90 ~ StickyHeadTable ~ values", values)
  const [imageUrl, setImageUrl] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("Error");
  const username = getAccessLog('username')
  const [data, setData] = useLookup(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage);
  const [negotiation, setNegotiation, makeNegotiation] = useNegotiation(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage);
  const [datas, setDatas, makeDatas] = useLkppOrder(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage);
  const [isSubmit, setIsSubmit] = useState(false)
  const [isBergain, setIsBergain] = useState(false)
  const [emptyImage, setEmptyImage] = useState({
    image1: false,
    image2: false,
    image3: false,
    image4: false,
    image5: false,
  })
  const [label, setLabel] = useState({
    positive: 'Close',
    negative: 'Close',
  })
  const [purchaseItem, setPurchaseItem] = useState(selected?.PURCHASE_ITEM ?? [])
  const [deliveryDate, setDeliveryDate] = useState([])
  const [shippingBergain, setShippingBergain] = useState([])
  const [productBergain, setProductBergain] = useState([])
  const [bergainPrice, setBergainPrice] = useState([])
  const [shippingDeal, setShippingDeal] = useState([])
  const [productDeal, setProductDeal] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [list, setList] = useState({})
  const [confirmType, setConfirmType] = useState()
  useEffect(() => {
    if (selected?.SHIPPING?.length > 0 && values?.SHIPPING_ETD) {
      let result = []
      selected?.SHIPPING?.map((val) => {
        let temp = {
          "SHIPPING_NO": val?.SHIPPING_NO,
          "SHIPPING_ETD": getDayPicker(values?.SHIPPING_ETD)
        }
        result.push(temp)
      })
      setDeliveryDate(result)
    }
  }, [values?.SHIPPING_ETD])
  useEffect(() => {
    if (bergainPrice?.length > 0 && selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS === '2:Customer Approval') {
      let result = []
      let result2 = []
      bergainPrice?.map((val) => {
        let temp = {
          "COMPONENT_ID": val?.SHIPPING_NO,
          "COMPONENT_TYPE": "SHIPPING",
          "NEGOTIATION_NOTES": "",
          "IS_APPROVED": false,
          "PRICE_LAST": Number(val?.SHIPPING_NEGO),
          "PRICE_PROPOSED": Number(val?.BERGAIN_SHIPPING)
        }
        result?.push(temp)
        let temp2 = {
          "COMPONENT_ID": val?.SHIPPING_NO,
          "COMPONENT_TYPE": "SHIPPING",
          "NEGOTIATION_NOTES": "",
          "IS_APPROVED": true,
          "PRICE_LAST": Number(val?.SHIPPING_NEGO),
          "PRICE_PROPOSED": Number(val?.BERGAIN_SHIPPING)
        }
        result2?.push(temp2)
      })
      setShippingBergain(result)
      setShippingDeal(result2)
      values["PRICE_SHIPPING"] = bergainPrice.reduce((n, { BERGAIN_SHIPPING }) => n + BERGAIN_SHIPPING, 0)
      values["PRICE_FULL"] = Number(values["PRICE_ITEMS"]) + Number(values["PRICE_SHIPPING"])
    }
  }, [bergainPrice])
  useEffect(() => {
    if (purchaseItem?.length > 0 && selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS === '2:Customer Approval') {
      let result = []
      let result2 = []
      purchaseItem?.map((val) => {
        const priceLast = selected?.PURCHASE_ITEM?.find(({ ITEM_NO }) => ITEM_NO === val?.ITEM_NO)
        let temp = {
          "COMPONENT_TYPE": "PRODUCT",
          "COMPONENT_ID": val?.ITEM_NO,
          "IS_APPROVED": false,
          "NEGOTIATION_NOTES": val?.ADDITIONAL_INFO,
          "PRICE_LAST": Number(priceLast?.PRICE_NEGO),
          "PRICE_PROPOSED": Number(val?.PRICE_NEGO)
        }
        result?.push(temp)
        let temp2 = {
          "COMPONENT_TYPE": "PRODUCT",
          "COMPONENT_ID": val?.ITEM_NO,
          "IS_APPROVED": true,
          "NEGOTIATION_NOTES": val?.ADDITIONAL_INFO,
          "PRICE_LAST": Number(priceLast?.PRICE_NEGO),
          "PRICE_PROPOSED": Number(val?.PRICE_NEGO)
        }
        result2?.push(temp2)
      })
      setProductBergain(result)
      setProductDeal(result2)
      values["PRICE_ITEMS"] = purchaseItem.reduce((n, { SUB_TOTAL_NEGO }) => n + SUB_TOTAL_NEGO, 0)
      values["PRICE_FULL"] = Number(values["PRICE_ITEMS"]) + Number(values["PRICE_SHIPPING"])
    }
  }, [purchaseItem])
  useEffect(() => {
    const param = {
      "INVOICE_NO": selected?.INVOICE_NO,
      page: 1,
      limit: 100,

    }
    setNegotiation(param)
    setData(param, 'historynego')
  }, [selected?.INVOICE_NO])

  const [onAction, setOnAction] = useState({
    onClose: () => handleClose(),
    positive: (body, type) => handleClose(body, type),
    negative: () => handleClose()
  })

  useEffect(() => {
    if (Object.keys(selected)?.length === 0) {
      changeStep('transactionList')
    }
  }, [selected])

  useEffect(() => {
    if (isSubmit === true) {
      if (dialogType === 'Success') {
        changeStep('transactionList')
        setIsSubmit(false)

        setOpen(false)
      } else {
        setIsSubmit(false)
        setOpen(false)
      }
    }
  }, [isSubmit])
  const handleClose = () => {
    setIsSubmit(true)
  }
  useEffect(() => {
    if (dialogType === 'ConfirmationApprove') {
      setLabel({
        positive: "Yes",
        negative: "Close",
      })
      setOnAction({
        onClose: () => handleClose(),
        positive: (e, value) => confirmType === 'canceled' ? handleCancelOrder() :
          confirmType === 'request' ? handleRequesApproval() :
            confirmType === 'bergain' ? handleNegotiation() :
              handleClose(),
        negative: () => handleClose(),
        // onChange: (name, value) => setValues(name, value),
        // setValidation: (val) => setValidation(val)
      })
    } else {
      setLabel({
        positive: "Close",
        negative: "Close",
      })
      setOnAction({
        onClose: () => handleClose(),
        positive: () => handleClose(),
        negative: () => handleClose(),
        // onChange: (name, value) => setValues(name, value)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogType, setDialogType])

  const handleCancelOrder = () => {
    const param = {
      "INVOICE_NO": selected?.INVOICE_NO,
      "ACTION_BY": username,
    }
    setIsSubmit(false)
    makeDatas(param, 'cancel')
  }
  const handleBergainOpen = () => {
    setIsBergain(!isBergain)
    setPurchaseItem(selected?.PURCHASE_ITEM)
    values["PRICE_ITEMS"] = selected?.PURCHASE_ITEM?.reduce((n, { SUB_TOTAL_NEGO }) => n + SUB_TOTAL_NEGO, 0)
    values["PRICE_SHIPPING"] = selected?.SHIPPING?.reduce((n, { SHIPPING_NEGO }) => n + SHIPPING_NEGO, 0)
    values["PRICE_FULL"] = values["PRICE_ITEMS"] + values["PRICE_SHIPPING"]
  }
  const handleRequesApproval = () => {
    const param = {
      "INVOICE_NO": selected?.INVOICE_NO,
      "CUSTOMER_NO": selected?.CUSTOMER_NO,
      "CONTRACT_INFO": {
        "PURCHASE_STATUS": "3:Admin Approval",
        "DELIVERY_TIME": getDayPicker(values?.SHIPPING_ETD)
      },

      "PURCHASE_ITEM": purchaseItem,

      "SHIPPING": deliveryDate,
      "ACTION_BY": username,
      "ACTION_DATE": getDayNow('complate'),
      "ADDITIONAL_INFO": selected?.ADDITIONAL_INFO ?? "",
      "IS_DUMP": false,
      "ACTIVE_STATUS": values?.ACTIVE_STATUS ? values?.ACTIVE_STATUS === "ACTIVE" ? true : false : selected?.ACTIVE_STATUS
    }
    setIsSubmit(false)
    makeDatas(param, 'update')
  }
  const bodyModal = () => {
    return (
      <div className={classes.fields}>
      </div>
    )
  }

  const handleOpenDialogCancel = () => {
    setConfirmType('canceled')
    setDialogType("ConfirmationApprove")
    setTitle("Cancel Order")
    setOpen(true)
    setMessage("Are you sure want to cancel this order ?")
  }

  const handleOpenDialogRequest = () => {
    setConfirmType('request')
    setDialogType("ConfirmationApprove")
    setTitle("Submit Delivery Date")
    setOpen(true)
    setMessage("Are you sure want to submit delivery date? Submited data can not be changed")
  }

  const handleOpenDialogSubmitBergain = () => {
    setConfirmType('bergain')
    setDialogType("ConfirmationApprove")
    setTitle("Submit Negotiation")
    setOpen(true)
    setMessage(" Are you sure want to submit negotiation? Submited data can not be changed")
  }


  const handleNegotiation = () => {
    const param = {
      "NEGOTIATION_NOTES": "1th",

      "REVISION_CODE": negotiation?.length + 1,
      "INVOICE_NO": selected?.INVOICE_NO,

      "PRICE_LAST": initValue?.PRICE_FULL,
      "PRICE_PROPOSED": values?.PRICE_FULL,

      "CONTRACT_INFO": {
        // "PURCHASE_STATUS":"2:Customer Approval"
        "PURCHASE_STATUS": "3:Admin Approval",
        "DELIVERY_TIME": getDayPicker(values?.SHIPPING_ETD)
      },

      "h_REQUEST": {
        "REQ_CODE": "LKPP:NEG:Request Purchase Negotiation",
        "REQUEST_STATUS": "0:New",
        "REQUEST_BY": username,
        "CREATED_BY": username
      },

      "LIST_COMPONENT": [...productBergain, ...shippingBergain],

      "ACTION_BY": username,
      "ACTION_DATE": getDayNow('complate'),
      "ADDITIONAL_INFO": "",
      "IS_DUMP": false,
      "ACTIVE_STATUS": true
    }
    makeNegotiation(param, 'create')
  }

  useEffect(() => {
    if (list?.SETUJU === 'SETUJU' && list?.WAKTU_PEMBAYARAN) {
      const param = {
        "NEGOTIATION_CODE": negotiation?.[0]?.NEGOTIATION_CODE,

        "REVISION_CODE": negotiation?.[0]?.REVISION_CODE,
        "INVOICE_NO": selected?.INVOICE_NO,

        "PRICE_LAST": initValue?.PRICE_FULL,
        "PRICE_PROPOSED": values?.PRICE_FULL,

        "CONTRACT_INFO": {
          "PURCHASE_STATUS": "4:Deal",
          "PAYMENT_OPTION": list?.WAKTU_PEMBAYARAN,
          "PAYMENT_METHOD": "Bank Transfer",
          "PAYMENT_BANK": "BRI",
          "PAYMENT_BANK_ACCOUNT": "1145-01-000858",
          "PAYMENT_BANK_HOLDER": "FURNI KARYA MANDARI",
          "IS_AGREE": true
        },

        "h_REQUEST": {
          "REQ_CODE": "LKPP:NEG:Request Purchase Negotiation",
          "REQUEST_STATUS": "0:New",
          "REQUEST_BY": username,
          "CREATED_BY": username
        },

        "LIST_COMPONENT": [...productDeal, ...shippingDeal],

        "ACTION_BY": username,
        "ACTION_DATE": getDayNow('complate'),
        "ADDITIONAL_INFO": "",
        "IS_DUMP": false,
        "ACTIVE_STATUS": true
      }
      makeNegotiation(param, 'update')
      console.log("🚀 ~ file: index.jsx ~ line 404 ~ useEffect ~ param", param)
      setList({})
    }
  }, [list])
  return (

    <Grid
      sx={{
        m: 2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px'
      }}
      container
      className={classes.root}
    >
      <CustomModal open={openModal} body={<AddComponent close={setOpenModal} setList={setList} list={list} />}></CustomModal>
      <HandlerModal
        open={open}
        body={bodyModal}
        onClose={handleClose}
        dialogType={dialogType}
        message={message}
        title={title}
        label={label}
        action={onAction}
        error={error}
        loading={isLoading}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <CustomBackdrop loading={isLoading} label={'Collecting Resources'} />

      <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>

          <GeneralInformation
            values={values}
            handleFieldChange={handleFieldChange}
            isLoading={isLoading}
            error={error}
            IMG_MAIN={selected?.IMG_MAIN}
            IMG_PATH={selected?.IMG_PATH}
            setImageUrl={setImageUrl}
            isBergain={isBergain}
          ></GeneralInformation>
        </Paper>
      </Grid>
      {/* <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>
        
        <ShippingInformation
         values={values}
         handleFieldChange={handleFieldChange}
         isLoading={isLoading}
         error={error}
       
        ></ShippingInformation>
        </Paper>
      </Grid> */}
      <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>

          <ProductInformation
            values={values ?? {}}
            handleFieldChange={handleFieldChange}
            isLoading={isLoading}
            error={error}
            data={purchaseItem}
            setPurchaseItem={setPurchaseItem}
            PURCHASE_STATUS={selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS}
            isBergain={isBergain}
          ></ProductInformation>
        </Paper>
      </Grid>
      <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>

          <ShippingAddress
            values={values ?? {}}
            handleFieldChange={handleFieldChange}
            isLoading={isLoading}
            error={error}
            setBergainPrice={setBergainPrice}
            isBergain={isBergain}
          ></ShippingAddress>
        </Paper>
      </Grid>
      <Grid
        className={classes.inputLeft}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Paper className={classes.paper}>

          <PaymentInformation
            values={values}
            handleFieldChange={handleFieldChange}
            isLoading={isLoading}
            error={error}
            data={values}
            negotiation={negotiation ?? []}
            historyNego={data?.historyNego?.data ?? []}
            changeStep={changeStep}
          ></PaymentInformation>
        </Paper>
      </Grid>
      {selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS === '1:Admin Initiate' ?
        <Box sx={{ position: 'fixed', bottom: 1, right: 1, background: 'white', width: '100%', textAlign: 'right', p: 2, zIndex: '100' }}>
          {/* <Button onClick={handleOpenDialogCancel} sx={{color: '#E51010', border: '1px solid #E51010', m:1}}> Cancel Order </Button> */}
          {/* <Button onClick={handleRequesApproval}  variant='contained' color='primary'>{'Request Customer Approval'}</Button> */}
          {/* <Button onClick={handleNegotiation} variant='contained' color='primary'>{'Submit Bergain Price'}</Button> */}
        </Box> :
        null
      }
      {selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS === '3:Admin Approval' ?
        <Box sx={{ position: 'fixed', bottom: 1, right: 1, background: 'white', width: '100%', textAlign: 'right', p: 2, zIndex: '100' }}>
          {/* <Button onClick={handleOpenDialogCancel} sx={{color: '#E51010', border: '1px solid #E51010', m:1}}> Cancel Order </Button> */}
        </Box> :
        null
      }
      {selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS === '2:Customer Approval' && isBergain === false ?
        <Box sx={{ position: 'fixed', bottom: 1, right: 1, background: 'white', width: '100%', textAlign: 'right', p: 2, zIndex: '100' }}>
          {/* <Button onClick={handleOpenDialogCancel} sx={{color: '#E51010', border: '1px solid #E51010', m:1}}> Cancel Order </Button> */}
          <Button onClick={handleBergainOpen} variant='contained' color='primary' sx={{ m: 1 }}>{'Bargain Price'}</Button>
          <Button onClick={() => { setOpenModal(true) }} variant='contained' color='primary'>{'Approve'}</Button>
        </Box> :
        null
      }
      {
        selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS === '2:Customer Approval' && isBergain === true ?
          <Box sx={{ position: 'fixed', bottom: 1, right: 1, background: 'white', width: '100%', textAlign: 'right', p: 2, zIndex: '100' }}>
            <Button onClick={handleBergainOpen} sx={{ color: '#E51010', border: '1px solid #E51010', m: 1 }}> Cancel </Button>
            <Button onClick={handleOpenDialogSubmitBergain} variant='contained' color='primary'>{'Submit Bergain Price'}</Button>
          </Box> :
          null
      }
      {
        // selected?.CONTRACT_INFO?.[0]?.PURCHASE_STATUS === '4:Deal'? 
        // <Box sx={{position: 'fixed', bottom: 1, right: 1, background: 'white', width: '100%', textAlign: 'right', p: 2, zIndex: '100' }}>
        //     <Button onClick={handleOpenDialogCancel} sx={{color: '#E51010', border: '1px solid #E51010', m:1}}> Cancel Order </Button>
        // </Box> :
        // null
      }
    </Grid >
  );
}