import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectActions } from '../../controllers/_actions'

export default function useSelectedDetail() {
    const [data, setData] = useState([])
    const selectedDetail = useSelector(state => state.selectedDetail);
    const dispatch = useDispatch()

    const initFetch = async (param) => {
        await dispatch(selectActions.selectDataDetail(param));
    };

    useEffect(() => {
        const DataLoaded = !selectedDetail ? null : selectedDetail
        setData(DataLoaded)
    }, [selectedDetail])

    return [data, initFetch]
}
