import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  Tabs,
  Button,
  Tab,
  Typography,
  Box,
  MenuItem,
  Select,
  ToggleButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from '@mui/material';

import { useProfileRetail, useLanguage, useInput } from 'hooks';
import { useSelector } from 'react-redux';
import { getAccessLog } from "helpers";
import moment from 'moment';
import { HandlerModal, InputComponent } from 'components';
import { validateSign } from 'controllers/_validate';
import { structuredefault } from './Schema/default';
import { useNavigate } from 'react-router'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';


export default function BasicTabs(props) {

  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [lang, langDic, setLang] = useLanguage()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [setValues2, values2, setValidation, errors2] = useInput(validateSign)
  const [datatables, setDatatables, getData] = useProfileRetail(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [reTypeVisible, setReTypeVisible] = useState(false);
  console.log("🚀 ~ file: index.jsx ~ line 41 ~ BasicTabs ~ datatables", datatables?.[0])
  const today = moment().format('YYYY-MM-DD');
  const [language, setLanguage] = useState(langDic ?? 'id')

  const initValue = {
    // ROLE: getAccessLog('role'),
    USERNAME_ID: getAccessLog('username'),
    // USER_ID: getAccessLog('user_id'),
    // FULL_NAME: getAccessLog('user_name'),
    EMAIL: getAccessLog('email')
  }
  const [values, setValues] = useState(initValue)
  const [values3, setValues3] = useState({})
  const [errors, setErrors] = useState('')

  const handleFieldChange = useCallback((field, value) => {
      setValues(prevValues => ({
          ...prevValues,
          [field]: value ? value !== '' ? value : undefined : undefined
      }));
      setErrors()
      // setErrors(prevErrors => ({
      //     ...prevErrors,
      //     [field]: null
      // }));
  }, []);

  const handlePassChange = useCallback((e) => {
      setValues3(prevValues => ({
          ...prevValues,
          [e.target.name]: e.target.value
      }));
      setErrors()
      // setErrors(prevErrors => ({
      //     ...prevErrors,
      //     [field]: null
      // }));
  }, []);

  const handleSave = (event) => {
    event.preventDefault()
    if (localStorage.getItem('Retail') === "true") {
      const param = {
        "CUSTOMER_NO": values?.CUSTOMER_NO,
        "CUSTOMER_NAME": values?.CUSTOMER_NAME,
        "CONF_LANGUAGE": values?.CONF_LANGUAGE,
        "USERNAME_ID": getAccessLog('username'),
        "PHONE": values?.CP_CONTACT,
        "EMAIL": values?.EMAIL,
        "ADDRESS": datatables?.[0]?.ADDRESS,
        "ACTION_BY": getAccessLog('username'),
        "ACTION_DATE": today,
        "ADDITIONAL_INFO": '',
      }
      getData(param, 'create')
      setLang(values?.CONF_LANGUAGE)
    } else {navigate('/signin')}
  }

  const handlePassword = (event) => {
    // event.preventDefault()
    // const param = {
    //   "CURRENT_PASS": values?.CURRENT_PASS,
    //   "NEW_PASS": values?.NEW_PASS,
    // }
    // getData(param, 'changePass')
    // console.log('changePass');
    event.preventDefault()
    const param = {
      newPassword: values3?.newPass,
      password: values3?.currentPass
    }
    // setIsSubmit(false)
    // if(!errors2?.confirmPassword && !errors2?.newPassword && !errors2?.retype) {
    // }
    getData(param,'changePass')
    console.log("🚀 ~ file: index.jsx ~ line 117 ~ handleSubmit ~ param", param)
  }
  console.log('errors', errors2);

  useEffect(() => {
    if (localStorage.getItem('Retail') === "true") {
      const param = {
        CUSTOMER_FILTER: "IS NULL",
        // CUSTOMER_NAME: "Maudy A",
        // CUSTOMER_NO: "00000008",
        USERNAME: getAccessLog('username'),
        page: 1,
        limit: 100
      }
      setDatatables(param)
    }
  }, [])

  useEffect(() => {
    if (datatables?.[0]) {
      setValues({
        "CUSTOMER_NO" : datatables?.[0]?.CUSTOMER_NO,
        "CUSTOMER_NAME" : datatables?.[0]?.CUSTOMER_NAME,
        "CONF_LANGUAGE" : datatables?.[0]?.CONF_LANGUAGE ?? "",
        "CP_CONTACT" : datatables?.[0]?.CP?.find(v => v?.CP_MODE === 'PHONE')?.CP_CONTACT ?? "",
        "EMAIL" : datatables?.[0]?.CP?.find(v => v?.CP_MODE === 'EMAIL')?.CP_CONTACT ?? ""

      })
      // values["CUSTOMER_NO"] = datatables?.[0]?.CUSTOMER_NO
      // values["CUSTOMER_NAME"] = datatables?.[0]?.CUSTOMER_NAME ?? ''
      // values["CONF_LANGUAGE"] = datatables?.[0]?.CONF_LANGUAGE ?? ""
      // values["CP_CONTACT"] = datatables?.[0]?.CP?.find(v => v?.CP_MODE === 'PHONE')?.CP_CONTACT ?? ""
      // values["EMAIL"] = datatables?.[0]?.CP?.find(v => v?.CP_MODE === 'EMAIL')?.CP_CONTACT ?? ""
    }
  }, [datatables])
  
  const bodyModal = () => {
    return (
      <div >
      </div>
    )
  }
  const handleClose2 = () => {
    setOpen2(false);
    window.location.reload()
  };
  const [label, setLabel] = useState({
    positive: 'Close',
    negative: 'Close',
  })
  const [onAction, setOnAction] = useState({
    onClose: () => handleClose2(),
    positive: (body, type) => handleClose2(body, type),
    negative: () => handleClose2()
  })
  function handlePasswordVisible() {
    setPasswordVisible(!passwordVisible)
  };
  function handleNewPasswordVisible() {
    setNewPasswordVisible(!newPasswordVisible)
  };
  function handleReTypeVisible() {
    setReTypeVisible(!reTypeVisible)
  };
  
  console.log("🚀 ~ file: index.jsx ~ line 117 ~ handleSubmit ~ parammm", values3)
  return (
    <React.Fragment>
      <HandlerModal
        open={open2}
        body={bodyModal}
        onClose={handleClose2}
        dialogType={dialogType}
        message={message}
        title={title}
        label={label}
        action={onAction}
        error={error}
        loading={isLoading}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />
      <Dialog                                                                       //Change Pass
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong>
            {"Change Password"}
          </strong>
        </DialogTitle>
        <form onSubmit={handlePassword}>
          <DialogContent>
            <table width={'100%'}>
              <tr>
                <td style={{ width: '30%', paddingRight: 10 }} align='right'>Current Password</td>
                <td>
                  <TextField
                    fullWidth
                    id="currentPass"
                    type={!passwordVisible ? 'password' : 'text'}
                    required
                    color='info'
                    name='currentPass'
                    placeholder="Current Password"
                    size='small'
                    onBlur={(e) => setValidation(values)}
                    onChange={(e) => handlePassChange(e)}
                    helperText={!errors2.confirmPassword ? null : errors2.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={handlePasswordVisible} sx={{ cursor: 'pointer' }}>
                          {!passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </InputAdornment>
                      )
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: '30%', paddingRight: 10 }} align='right'>New Password</td>
                <td>
                  <TextField
                    fullWidth
                    id="newPass"
                    type={!newPasswordVisible ? 'password' : 'text'}
                    required
                    color='info'
                    name='newPass'
                    placeholder="New Password"
                    size='small'
                    onBlur={(e) => setValidation(values)}
                    onChange={(e) => handlePassChange(e)}
                    helperText={!errors2.newPassword ? null : errors2.newPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={handleNewPasswordVisible} sx={{ cursor: 'pointer' }}>
                          {!newPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </InputAdornment>
                      )
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ width: '30%', paddingRight: 10 }} align='right'>Re-enter New Password</td>
                <td>
                  <TextField
                    fullWidth
                    id="newPass"
                    type={!reTypeVisible ? 'password' : 'text'}
                    required
                    color='info'
                    name='newPass'
                    placeholder="Re-type New Password"
                    size='small'
                    onBlur={(e) => setValidation(values)}
                    onChange={(e) => handlePassChange(e)}
                    helperText={!errors2.retype ? null : errors2.retype}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={handleReTypeVisible} sx={{ cursor: 'pointer' }}>
                          {!reTypeVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </InputAdornment>
                      )
                    }}
                  />
                </td>
              </tr>
            </table>
            <Button sx={{ marginTop: 2 }} type="submit" fullWidth size='small' color='info' variant='contained' onClick={handleClose} autoFocus>
              {lang.COMMON.MY_ACCOUNT.CHANGE_PASSWORD}
            </Button>
          </DialogContent>
        </form>
      </Dialog>
      <form onSubmit={handleSave}>
        <Box
          sx={{
            m: 2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px'
          }}
        >
          <Typography variant="body1" color="initial" style={{ marginBottom: '5px' }}><strong>{lang.COMMON.MY_ACCOUNT.MY_ACCOUNT}</strong></Typography>
          <Grid container style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
            <InputComponent
              structure={structuredefault(values, handleFieldChange, lang)}
              isLoading={''}
              error={''}
              divider={2}
              padding={1}
              minScreen="md"
            />
          </Grid>
          <Grid container style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            
              <Button sx={{ marginTop: 3, width: 400 }} onClick={() => setOpen(true)} color='info' variant="outlined" >
                {lang.COMMON.MY_ACCOUNT.CHANGE_PASSWORD}
              </Button>
              <Button type="submit" sx={{ marginTop: 3, width: 400 }} color='info' variant="contained" >
              {lang.COMMON.MY_ACCOUNT.SAVE_CHANGES}
              </Button>
          </Grid>
        </Box>
      </form>
    </React.Fragment>
  );
}
