import React, { useState, useEffect } from 'react'
import {
  Grid,
  Box,
  FormLabel,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
  Typography,
  ButtonGroup,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  TextField
} from '@mui/material'
import NumberFormat from 'react-number-format';

import noImage from "assets/images/noImage.jpeg"
import { getAccessLog, rupiahFormat } from 'helpers'
import { BreadCrumbs, CustomModal } from 'components'
import { makeStyles } from "@mui/styles";
import { useProduct, useCart, useProfile, useLanguage } from 'hooks'
import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import parse from 'html-react-parser';
import isAccountEmpty from 'helpers/isAccountEmpty';
import isAccountRetailEmpty from 'helpers/isAccountRetailEmpty';
import { Login, Register } from 'components/GeneralLayout/Header/component';

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(5),
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  image: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 5,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  imageContent: {
    width: 400,
    height: 400,
    borderRadius: '10px',
    [theme.breakpoints.down('lg')]: {
      width: 220,
      height: 220,
    },
    [theme.breakpoints.down('md')]: {
      width: 150,
      height: 150,
    },

  },
  otherImageContent: {
    borderRadius: '10px',
    marginLeft: 3,
    marginRight: 3,
    width: 70,
    height: "auto",
    cursor: 'pointer',
    "&:hover": {
      border: 'solid 1px #20376D'
    },
    [theme.breakpoints.down('lg')]: {
      width: 40,
      height: "auto",
    },
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: "auto",
    },
  },
  content: {
    paddingTop: '30px'
  },
  textContent: {
    textAlign: 'left'
  }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ViewStep(props) {
 const {
    handleSubmit,
  } = props

  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  console.log("🚀 ~ file: view.jsx ~ line 106 ~ ViewStep ~ queryParse", queryParse.act)

  const [isLoading, setIsLoading] = useState(false);
  const [sizeSelected, setSizeSelected] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getDetail] = useProduct(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  const [datatables2, setDatatables2, addCart] = useCart(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  const [datatables3, setDatatables3, getData] = useProfile(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  const [openLogin, setOpenLogin] = React.useState(false);
  const [openRegister, setOpenRegister] = React.useState(false);

  useEffect(() => {
    const param = {
      CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
      USERNAME: getAccessLog('username'),
      page: 1,
      limit: 100
    }
    setDatatables3(param)
  }, [])

  const detailProduct = useSelector(state => state.detailProduct);
  const [rows, setRows] = React.useState([])
  const getSize = useSelector(state => state.getSize);
  const [rowSize, setRowSize] = React.useState([])
  const today = moment().format('YYYY-MM-DD');


  useEffect(() => {
    if (queryParse?.act === 'Detail') {
      const param = {
        PRODUCT_CODE: queryParse?.PRODUCT_CODE,
        PRODUCT_NAME: queryParse?.PRODUCT_NAME,
        page: 1,
        limit: 10
      }
      getDetail(param, 'getDetail')

    }

  }, [queryParse?.PRODUCT_CODE, queryParse?.PRODUCT_NAME])

  useEffect(() => {
    if (rows.length > 0) {

      const param2 = {
        GROUP_INFO: rows?.[0]?.PRODUCT_GROUPS?.[0],
      }
      getDetail(param2, 'getSize')

    }

  }, [rows])

  useEffect(() => {
    if (rowSize.length > 0) {
      rowSize.map((item) => {
        if (item?.ITEM_NO === queryParse?.PRODUCT_CODE) {
          setSizeSelected(item?.ITEM_NO)
        }
      })
    }
  }, [rowSize])


  React.useEffect(() => {
    const rowData = detailProduct?.items?.data ?? []
    if (rowData.length > 0) setRows(rowData)
    else setRows([])
  }, [detailProduct])

  React.useEffect(() => {
    const rowData = getSize?.items?.data ?? []
    if (rowData.length > 0) setRowSize(rowData)
    else setRowSize([])
  }, [getSize])

  const [counter, setcounter] = useState({
    product: 1,
  })
  const [counterExtras, setcounterExtras] = useState({})
  useEffect(() => {
    if (rows.length > 0) {
      if (rows?.[0]?.PRODUCT_RELATIONS.length > 0) {
        rows?.[0]?.PRODUCT_RELATIONS.map((item) => {
          setcounterExtras((prevState) => ({
            ...prevState,
            [`${item?.PRODUCT_CODE}`]: 0
          }))
        })
      }
    }
  }, [rows])

  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false);
  const [currentImage, setcurrentImage] = useState('')
  const image = {
    image1: 'https://media.wired.com/photos/61895f17936b3fd5e3e2dedf/master/w_320,c_limit/Gear-Branch-Ergonomic-Chair-SOURCE-Branch.jpg',
    image2: 'https://ii2.pepperfry.com/media/catalog/product/n/e/494x544/new-york-high-back-ergonomic-chair-with-headrest-in-black-colour-by-green-soul-new-york-high-back-er-fmfcwj.jpg'

  }

  const [openEmptyModal, setOpenEmtyModal] = useState(false)
  const BodyEmptyAccount = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography sx={{ p: 1 }} variant='h5'>Profile Data is Not Complete</Typography>
        <Typography sx={{ p: 2 }}>Please complete your profile data before continue your shopping.</Typography>
        <div>
          <Button sx={{ mr: 2 }} variant='contained' color='secondary' onClick={() => { setOpenEmtyModal(false) }}>Cancel</Button>
          <Button onClick={() => { navigate('/furnitur/profile') }} variant='contained' color='primary'>Go to Profile</Button>
        </div>
      </div>
    )
  }

  const handleLogin = (event) => {
    if (openRegister) {
      setOpenRegister(false)
    }
    setOpenLogin(!openLogin)
  };

  const handleRegister = (event) => {
    if (openLogin) {
      setOpenLogin(false)
    }
    setOpenRegister(!openRegister)
  };

  const handleClickMainSubmit = async () => {
    setOpen(false)
    if(!getAccessLog('isAuthentication')) {
      handleLogin()
      return
    }
    const gotoProfile = await localStorage.getItem('Retail') === 'false' ? isAccountEmpty(datatables3) : isAccountRetailEmpty(datatables3)
    console.log("🚀 ~ handleClickMainSubmit ~ false:", gotoProfile, datatables3, localStorage.getItem('Retail') === 'false')
    if (gotoProfile) {
      setOpenEmtyModal(true)
    } else {
      if (counter.product != 0) {
        const data = {
          "CUSTOMER_NO": datatables3?.[0]?.CUSTOMER_NO,
          "ITEM_NO": queryParse?.PRODUCT_CODE,

          "ITEM_TOTAL": counter?.product,
          "ITEM_PRICE": rows[0]?.SELL_PRICE,

          "ACTION_BY": getAccessLog('username'),
          "ACTION_DATE": today,
        }
        addCart(data, 'create')
        setOpenModal(true);
        setOpenToast(false)
      } else {
        setOpenToast(true)
      }
    }
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleClickExtrasSubmit = () => {
    setOpen(false)
    const isQtyEmpty = Object.values(counterExtras).every(value => {
      if (value === 0) {
        return true
      }
      return false
    })
    if (isQtyEmpty === false) {
      for (const key in counterExtras) {
        if (counterExtras[key] > 0) {
          let data = {}
          rows?.[0]?.PRODUCT_RELATIONS.map((item) => {
            if (key === item?.PRODUCT_CODE) {
              data = {
                "CUSTOMER_NO": datatables3?.[0]?.CUSTOMER_NO,
                "ITEM_NO": key,

                "ITEM_TOTAL": counterExtras[key],
                "ITEM_PRICE": item?.SELL_PRICE,

                "ACTION_BY": getAccessLog('username'),
                "ACTION_DATE": today,
              }
            }
          })
          console.log("🚀 ~ file: view.jsx ~ line 216 ~ handleClickExtrasSubmit ~ data", data)
          addCart(data, 'create')
          setOpenModal(true);
          setOpenToast(false)
        }
      }
    } else if (isQtyEmpty === true) {
      setOpenToast(true)
    }

  };

  useEffect(() => {
    if (rowSize.length > 0) {
      rowSize.map((item) => {
        if (sizeSelected === item?.ITEM_NO) {
          const obj = {
            PRODUCT_NAME: item?.ITEM_NAME,
            PRODUCT_CODE: item?.ITEM_NO
          }
          console.log("🚀 ~ file: view.jsx ~ line 199 ~ rowSize.map ~ obj", obj)
          handleSubmit(obj, 'detail')
        }
      })
    }
  }, [sizeSelected])

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };
  const handleChange = (event) => {
    setExtras({
      ...extras,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChangeImage = (event) => {
    if (currentImage !== event.target.src) {
      setcurrentImage(event.target.src);
    }
  };

  const handleIncrement = (event) => {
    if (counter[event.target.name] < 999) {
      setcounter({
        ...counter,
        [event.target.name]: parseInt(counter[event.target.name]) + 1
      });
    }
  };

  const handleIncrementExtras = (event) => {
    if (counterExtras[event.target.name] < 999) {
      setcounterExtras({
        ...counterExtras,
        [event.target.name]: parseInt(counterExtras[event.target.name]) + 1
      });
    }
  };

  const handleDecrementExtras = (event) => {
    if (counterExtras[event.target.name] > 0) {
      setcounterExtras({
        ...counterExtras,
        [event.target.name]: parseInt(counterExtras[event.target.name]) - 1
      });
    }
  };
  const handleDecrement = (event) => {
    if (counter[event.target.name] > 0) {
      setcounter({
        ...counter,
        [event.target.name]: parseInt(counter[event.target.name]) - 1
      });
    }
  };

  const breadcrumb = [
    {
      title: "Home", to: "/furnitur"
    },
    {
      title: "Shop by Range", to: `?act=List`
    }
  ]
  const [extras, setExtras] = useState({
    optional1: false,
    optional2: false,
    optional3: false,
  });
  const baseUrlImage = window._env_?.BASEURL_IMAGE

  useEffect(() => {
    if (rows) {
      setcurrentImage(`${baseUrlImage}${rows?.[0]?.EXTRA_IMAGES?.[0]?.IMG_PATH}${rows?.[0]?.EXTRA_IMAGES?.[0]?.IMG_MAIN}`)

    }
  }, [rows])

  const handleInputQty = (event) => {
    if (event.target.value !== "") {
      setcounter({
        ...counter,
        [event.target.name]: event.target.value
      });
    }
    if (event.target.value > 999) {
      setcounter({
        ...counter,
        [event.target.value]: 999
      });
    }
  }
  const handleInputQtyExtras = (event) => {
    if (event.target.value !== "") {
      setcounterExtras({
        ...counterExtras,
        [event.target.name]: event.target.value
      });
    }
    if (event.target.value > 999) {
      setcounterExtras({
        ...counterExtras,
        [event.target.value]: 999
      });
    }
  }
  const [language, lagDic, setLang] = useLanguage()


  const navigate = useNavigate()
  return (
    <React.Fragment>
       <Login isAddToChart={true} open={openLogin} setOpen={setOpenLogin} handleRegister={handleRegister} />
      <Register isAddToChart={true} open={openRegister} setOpen={setOpenRegister} handleLogin={handleLogin} />
      <CustomModal open={openEmptyModal} body={<BodyEmptyAccount />}></CustomModal>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openToast} autoHideDuration={3000} onClose={handleCloseToast}>
        <Alert onClose={() => { setOpenToast(false) }} severity="warning" sx={{ width: '100%' }}>
          Add Quantity!
        </Alert>
      </Snackbar>
      <div className={classes.container}>
        {/* Dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <strong>{language.COMMON.SUBMITTED_PRODUCT_TO_CART.SUCCESSFULLY_ADD_ITEM}</strong>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {rows[0]?.PRODUCT_NAME} {language.COMMON.SUBMITTED_PRODUCT_TO_CART.HAS_BEEN_ADDED}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-evenly', paddingBottom: 2 }} >
            <Button size='small' color='info' variant='outlined' onClick={handleClose}>{language.COMMON.SUBMITTED_PRODUCT_TO_CART.SHOP_MORE}</Button>
            <Button size='small' color='info' variant='contained' onClick={() => { navigate('/furnitur/cart') }} autoFocus>
              {language.COMMON.SUBMITTED_PRODUCT_TO_CART.VIEW_CHART}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Breadcrumb */}
        <div >
          <BreadCrumbs
            breadcrumb={breadcrumb}
            current={rows[0]?.PRODUCT_NAME}
          />
        </div>

        {/* Content */}
        <Grid container spacing={3} className={classes.content}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.image}>
              <img
                className={classes.imageContent}
                style={{ objectFit: 'contain' }}
                onError={event => {
                  event.target.src = noImage
                  event.onerror = null
                }}
                src={currentImage}
                alt="" />
            </div>
            <div className={classes.image}>
              {rows?.[0]?.EXTRA_IMAGES.map((item, index) => {
                return (
                  <img
                    onClick={handleChangeImage}
                    style={{ objectFit: 'scale-down' }}
                    className={classes.otherImageContent}
                    onError={event => {
                      event.target.src = noImage
                      event.onerror = null
                    }}
                    src={`${baseUrlImage}${item?.IMG_PATH}${item?.IMG_THUMB}`}
                    alt=''
                  />
                )
              })}
              
            </div>
          </Grid>
          <Grid item xl lg md sm xs className={classes.textContent}>
            <Typography sx={{ fontSize: 24, fontWeight: 'bold' }} >{rows[0]?.PRODUCT_NAME}</Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 'bold' }} >{rows[0]?.PRODUCT_CODE}</Typography>
            <Typography sx={{ fontSize: 22, fontWeight: 'bold' }} color={'#3978a2'}> <strong> {rupiahFormat(`${rows[0]?.SELL_PRICE}`, 'Rp')}</strong> </Typography>
            {rows[0]?.IS_DISCOUNT ?
              <div style={{ textDecoration: 'line-through', lineHeight: 1.6 }}>
                <Typography color={'red'}>{rupiahFormat(`${rows[0]?.SELL_PRICE_ACTUAL}`, 'Rp')}</Typography>
              </div>
              : null
            }
            <FormControl>
              <Typography>{language.COMMON.PRODUCT_INFORMATION_DETAIL.SIZE}</Typography>
              <Select
                value={sizeSelected}
                sx={{ width: 150 }}
                disabled={rows[0]?.PRODUCT_GROUPS?.length > 0 ? false : true}
                onChange={(event) => { setSizeSelected(event.target.value) }}
                name='size'
                size='small'
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {rowSize.map((item) => {
                  console.log("🚀 ~ file: view.jsx ~ line 314 ~ {rowSize.map ~ item", item)
                  return (
                    <MenuItem value={item?.ITEM_NO}>{item?.SIZE}</MenuItem>
                  )
                })}
              </Select>
              <br />
              <Typography>{language.COMMON.PRODUCT_INFORMATION_DETAIL.QUANTITY}</Typography>
              <ButtonGroup
                sx={{ width: 150, color: '#1070ca', border: 'solid 1px #aeb7cb' }}
                color='clarity' size="small" aria-label="small outlined button group">
                <Button style={{ color: '#1070ca', border: '0px' }} name='product' onClick={handleDecrement}>-</Button>
                <NumberFormat
                  style={{ fontSize: 11, border: '0px' }}
                  value={counter.product}
                  onChange={(event) => {
                    handleInputQty(event)
                  }}

                  customInput={TextField}
                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                  InputProps={{ disableUnderline: true }}
                  onFocus={event => {
                    event.target.select();
                  }}
                  margin='dense'
                  name='product'
                  size='small'
                  variant='standard'
                  displayType="input"
                  type="text"

                  allowNegative={false} />
                <Button style={{ color: '#1070ca', border: '0px' }} name='product' onClick={handleIncrement}>+</Button>
              </ButtonGroup>
              <br />
              <Button size="small" onClick={handleClickMainSubmit} color='info' variant="contained">{language.COMMON.PRODUCT_INFORMATION_DETAIL.ADD_TO_CHART}</Button>
            </FormControl>
            <div>
              <Box sx={{ display: 'flex', paddingTop: 4 }}>
                <FormControl sx={{ paddingLeft: 1, marginTop: 2, paddingTop: 2 }} component="fieldset" variant="standard">
                  {rows?.[0]?.PRODUCT_RELATIONS.length > 0 ?

                    <FormLabel component="legend">{language.COMMON.PRODUCT_INFORMATION_DETAIL.OPTIONAL_EXTRAS}</FormLabel>
                    : null}
                  <FormGroup>
                    {rows?.[0]?.PRODUCT_RELATIONS.length > 0 ?
                      rows?.[0]?.PRODUCT_RELATIONS.map((item, index) => {
                        console.log("🚀 ~ file: view.jsx ~ line 308 ~ rows?.[0]?.PRODUCT_RELATIONS.map ~ item", item)
                        return (
                          <React.Fragment>
                            <div style={{ alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>
                              <div>
                                <img
                                  alt=''
                                  onError={event => {
                                    event.target.src = noImage
                                    event.onerror = null
                                  }}
                                  src={`${baseUrlImage}${item?.IMG_PATH}${item?.IMG_THUMB}`}
                                  key='optional1' width="40px" height="auto" style={{ marginRight: "5px" }} />
                              </div>
                              <div style={{ paddingRight: 20 }}>
                                <Tooltip title={item?.PRODUCT_NAME}>
                                  <Typography sx={{ width: 180 }} noWrap>
                                    {item?.PRODUCT_NAME}
                                  </Typography>

                                </Tooltip>
                                <Typography sx={{ fontSize: 10 }} noWrap>
                                  {item?.PRODUCT_CODE}
                                </Typography>
                                <Typography>
                                  {item?.SELL_PRICE}
                                </Typography>
                              </div>
                              <div style={{ paddingleft: 100, paddingRight: 20 }}>
                                <ButtonGroup style={{ color: '#1070ca', border: 'solid 1px #aeb7cb' }} color='clarity' size="small" aria-label="small outlined button group">
                                  <Button style={{ color: '#1070ca', border: '0px' }} name={item?.PRODUCT_CODE} onClick={handleDecrementExtras}>-</Button>
                                  <NumberFormat
                                    style={{ width: 40, fontSize: 11, border: '0px' }}
                                    value={counterExtras[`${item?.PRODUCT_CODE}`]}
                                  onChange={(event) => {
                                      handleInputQtyExtras(event)
                                    }}

                                    customInput={TextField}
                                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                    InputProps={{ disableUnderline: true }}
                                    onFocus={event => {
                                      event.target.select();
                                    }}
                                    margin='dense'
                                    name={item?.PRODUCT_CODE}
                                    size='small'
                                    variant='standard'
                                    displayType="input"
                                    type="text"

                                    allowNegative={false} />
                                   <Button style={{ color: '#1070ca', border: '0px' }} name={item?.PRODUCT_CODE} onClick={handleIncrementExtras}>+</Button>
                                </ButtonGroup>
                              </div>
                              <div>
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      })
                      : null}
                  </FormGroup>
                </FormControl>
              </Box>
              {rows?.[0]?.PRODUCT_RELATIONS.length > 0 ?
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '386px' }}>
                  <Button size="small" onClick={() => { handleClickExtrasSubmit() }} color='info' variant="contained">{language.COMMON.PRODUCT_INFORMATION_DETAIL.ADD_TO_CHART}</Button>
                </div>
              : null}
            </div>
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ paddingTop: 3 }} />
        <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
            <div>
              <h3 textAlign='left'>
                {language.COMMON.PRODUCT_INFORMATION_DETAIL.DESCRIPTION}
              </h3>
              <Typography align='justify'>
                {parse(`${rows[0]?.PRODUCT_DESC}`)}
              </Typography>

            </div>

          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6} >

          </Grid>
        </Grid>
      </div >
    </React.Fragment >
  );
}