// 
import { authHeader } from '../_helpers'
import axios from '../_helpers/interceptor'
import { getAccessLog } from 'helpers';

export const productService = {
    getAll,
    getDetail,
    getPopularProduct,
    getBanner,
    getSale,
    getSize,
    getLimit
};

async function getAll(param) {
    try {
        const bodyOptions = {
            // "MODE": "CONTRACT",
            "FILTER": {
                "KEYWORD": "WebCom",
                "MODE": localStorage?.getItem('Retail') === 'false' ? "CONTRACT" : "RETAIL",
                "PRODUCT_CODE": param?.PRODUCT_CODE,
                "PRODUCT_NAME": param?.PRODUCT_NAME,
                "PRODUCT_DESC": param?.PRODUCT_DESC,
                "REF_ID": param?.REF_ID,
                "TYPE": param?.TYPE,
                "BRAND_CODE": param?.BRAND_CODE,
                "CATEGORY_CODE": param?.CATEGORY_CODE,
                "MIN": param?.MIN,
                "MAX": param?.MAX,

                "MULTIPLE_FILTER": [
                    {
                        "LIST_CATEGORY_CODE": param?.RANGE ?? [],
                        "CATEGORY_TYPE": "RANGE"
                    },
                    {
                        "LIST_CATEGORY_CODE": param?.FUNCTIONALITY ?? [],
                        "CATEGORY_TYPE": "FUNCTIONALITY"
                    },
                    {
                        "LIST_CATEGORY_CODE": param?.SPECIFICATION ?? [],
                        "CATEGORY_TYPE": "SPECIFICATION"
                    }
                ],
                "IS_DUMP": false,
                "ACTIVE_STATUS": true
            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/product/product/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getDetail(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "KEYWORD": "WebCom",
                "MODE": localStorage?.getItem('Retail') === 'false' ? "CONTRACT" : undefined,
                "PRODUCT_CODE": param?.PRODUCT_CODE,
                // "PRODUCT_NAME": param?.PRODUCT_NAME,
                "PRODUCT_DESC": param?.PRODUCT_DESC,

                "BRAND_CODE": param?.BRAND_CODE,
                "CATEGORY_CODE": param?.CATEGORY_CODE,

                "IS_DUMP": false,
                "ACTIVE_STATUS": true

            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/product/product/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getPopularProduct(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "KEYWORD": "WebCom",
                "MODE": localStorage?.getItem('Retail') === 'false' ? "CONTRACT" : undefined,
                "VALUE_KEY": "PAGE-POPULAR_PRODUCT",
                "KEYWORD": "ECommerce",
                "IS_DUMP": false,
                "ACTIVE_STATUS": true

            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/web/cms/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getBanner(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "VALUE_KEY": "HOME-BANNER",
                "ACTIVE_STATUS": true,
                "IS_DUMP": false,
                "ACTIVE_STATUS": true

            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/web/cms/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getSale(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "PRODUCT_CODE": param?.PRODUCT_CODE,
                "PRODUCT_NAME": param?.PRODUCT_NAME,
                "TYPE": param?.TYPE,
                "REF_ID": param?.REF_ID,
                "MIN": param?.MIN,
                "MAX": param?.MAX,
                "MULTIPLE_FILTER": [
                    {
                        "LIST_CATEGORY_CODE": param?.RANGE ?? [],
                        "CATEGORY_TYPE": "RANGE"
                    },
                    {
                        "LIST_CATEGORY_CODE": param?.FUNCTIONALITY ?? [],
                        "CATEGORY_TYPE": "FUNCTIONALITY"
                    },
                    {
                        "LIST_CATEGORY_CODE": param?.SPEC ?? [],
                        "CATEGORY_TYPE": "SPECIFICATION"
                    }
                ],
                "BRAND_CODE": param?.BRAND_CODE,
                "CATEGORY_CODE": param?.CATEGORY_CODE,
                "IS_DUMP": false,
                "ACTIVE_STATUS": true
                // "IS_DUMP": false

            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/others/sale/read?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function getSize(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                "GROUP_INFO": param?.GROUP_INFO
            }
            // "FILTER": {
            //     "CATEGORY_CODE": param?.CATEGORY_CODE,
            //     "CATEGORY_DESC": param?.CATEGORY_DESC,
            //     "CATEGORY_NAME": param?.CATEGORY_NAME,

            //     "CATEGORY_TYPE": "SIZE",

            //     "GROUP_INFO": "CHILD",
            //     "REF_ID": param?.REF_ID,

            //     "IS_DUMP": false,
            //     "ACTIVE_STATUS": true
            // },
            // "PAGINATION": {
            //     "PAGE_NO": `${param.page}`,
            //     "PAGE_MAX": `${param.limit}`
            // }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/product/category/read-list-size?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}
async function getLimit(param) {
    try {
        const bodyOptions = {

            "FILTER": {
                "MODE": param?.MODE,
                "CATEGORY_CODE": param?.CATEGORY_CODE,

                "MULTIPLE_FILTER": [
                    {
                        "LIST_CATEGORY_CODE": param?.FUNCTIONALITY ?? [],
                        "CATEGORY_TYPE": "FUNCTIONALITY"
                    }
                ]
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/product/product/price-min-max?lang=${lang}`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.service.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}
