import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import DateAdapter from '@mui/lab/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider, util } from '@mui/lab';
import App from './config/init/App';
import { store } from './controllers/_helpers'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {
  generateKey,
  encrypt
} from './helpers/hashing';
import theme from './assets/themes';
import './index.css';

store.subscribe(() => {
  const loader = generateKey();
  const data_load = store.getState();
  console.log("🚀 ~ file: index.js ~ line 26 ~ store.subscribe ~ data_load", data_load)
  const data_subs = encrypt(data_load?.authentication, loader);

  // localStorage.setItem('deleted-soon', JSON.stringify(data_load))
  localStorage.setItem('history_load', data_subs)
  localStorage.setItem('click_point', loader)
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
