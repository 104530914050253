export const shippingConstants = {
    GETALL_REQUEST: 'SHIPPING_GETALL_REQUEST',
    GETALL_SUCCESS: 'SHIPPING_GETALL_SUCCESS',
    GETALL_FAILURE: 'SHIPPING_GETALL_FAILURE',

    CREATE_REQUEST: 'SHIPPING_CREATE_REQUEST',
    CREATE_SUCCESS: 'SHIPPING_CREATE_SUCCESS',
    CREATE_FAILURE: 'SHIPPING_CREATE_FAILURE',

    DELETE_REQUEST: 'SHIPPING_DELETE_REQUEST',
    DELETE_SUCCESS: 'SHIPPING_DELETE_SUCCESS',
    DELETE_FAILURE: 'SHIPPING_DELETE_FAILURE',

    UPDATE_REQUEST: 'SHIPPING_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SHIPPING_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SHIPPING_UPDATE_FAILURE'
};