// Palette
import palette from '../palette';
// import MuiButton from './MuiButton';

const MuiChip = {
  root: {
    backgroundColor: '#fff',
    color: '#000',
    border: '2px solid #0767DB',
  },
  clickable: {
    '&:hover, &:focus, &:active': {
      backgroundColor: '#EDF0F2'
    }
  },
  deletable: {
    '&:focus': {
      backgroundColor: palette.primary
    }
  },
  outlined: {
    '&:hover': {
      backgroundColor: '#0767DB'
    }
  }
};

export default MuiChip