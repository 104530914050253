import { autocompleteClasses } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  container: {
    padding: theme.spacing(10),
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f1f5f8',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  tableHeader: {
    backgroundColor: '#f1f5f8',
  },
  paperContent: {
    paddingLeft: 20,
    paddingRight: 20
  },
  otherImageContent: {
    borderRadius: '7px',
    marginLeft: 10,
    marginRight: 10,
    width: "40px",
    height: "auto",
  },
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      // default paddingRight was 39px since clear icon was positioned absolute
      paddingRight: "9px",

      // Search icon
      "& button": {
        order: 3, // order 3 means the search icon will appear after the clear icon which has an order of 2
      },

      // Clear icon
      "& > div.MuiAutocomplete-endAdornment": {
        position: "relative", // default was absolute. we make it relative so that it is now within the flow of the other two elements
        order: 2,
      },
    },
  },
}));
