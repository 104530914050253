const logout = {
  TITLE: 'LOGIN',
  DESC: 'Masuk dengan akun terdaftar Anda',
  HEADER: {
    HEADER_1: 'Terima kasih telah menggunakan internet Banking Bank Capital Indonesia',
  },
  LABEL: {
    BUTTON_LOGIN: 'Masuk',
  },
  PLACEHOLDER: {},
  INFO: {},
}

export default logout