import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiTextField-root': {
      paddingTop: theme.spacing(3),
      width: '100%',
    },
  },
  rootPhone: {
    width: '100%',
    // '& .MuiTextField-root': {
    //   paddingTop: theme.spacing(3),
    //   width: '100%',
    // },
  },
  labelItem: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  labelPhone: {
    paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  button: {
    marginTop: theme.spacing(3)
  },
  itemContent: {

  }
}));

export default function Customfield(props) {
  const {
    // classes,
    param,
    // inputClassnames,
  } = props
  const styles = useStyles()

  const initialDate = !param ? "" : !param.value ? "" : param.value
  const [value, setValue] = React.useState(initialDate)

  const handleChange = (e) => {
    param.onChange(e.target.name, e.target.value)
    setValue(e.target.value)
  }

  React.useEffect(() => {
    const checkInit = !param ? "" : !param.value ? "" : param.value
    setValue(checkInit)
  }, [param])

  return (
    <FormControl key={param.id} className={styles.root} >
      <FormLabel component="legend" className={styles.labelItem}>{param.label}</FormLabel>
      <OutlinedInput
        className={param.inputClassnames}
        fullWidth
        onChange={handleChange}
        id={param.id}
        value={value}
        disabled={param.disabled}
        readOnly={props.readOnlyAll}
        placeholder={param.placeholder}
        name={param.id}
        type={param.type}
        variant="outlined"
        multiline={param.multiline}
        rows={param.rows}
      />
    </FormControl>
  )
}
