import { ourProjectConstants } from '../_constants';

export function ourProject(state = {}, action) {
    switch (action.type) {
        case ourProjectConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case ourProjectConstants.GETALL_SUCCESS:
            return {
                items: action.ourProject
            };
        case ourProjectConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function ourProjectDetail(state = {}, action) {
    switch (action.type) {
        case ourProjectConstants.GETALL_DETAIL_REQUEST:
            return {
                loading: true
            };
        case ourProjectConstants.GETALL_DETAIL_SUCCESS:
            return {
                items: action.ourProject
            };
        case ourProjectConstants.GETALL_DETAIL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}