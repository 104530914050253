const loginEn = {
  TITLE: 'LOGIN',
  DESC: 'Sign with your registred account',
  MODULE: {
    username: 'ID pengguna',
    password: 'Sandi'
  },
  HEADER: {
    TITLE: 'Selamat datang di Bank Capital!',
    DESC: 'Silahkan Login ke Akun Internet Banking Bisnis Anda',
  },
  LABEL: {
    CORPORATE_ID: 'ID Perusahaan',
    USER_ID: 'ID Pengguna',
    PASSWORD: 'Sandi',
    BUTTON_LOGIN: 'Masuk',
    HELP: "Lupa Password",
    SPONSOR: "Powered by :"
  },
  PLACEHOLDER: {
    CORPORATE_ID: 'Termasuk Karakter dan Nomor',
    USER_ID: 'cth: John Doe',
    PASSWORD: 'Sandi'
  },
}

export default loginEn