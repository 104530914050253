import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomTable } from 'components'
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { formatCurrency } from 'helpers';
import { useLanguage } from 'hooks';

const useStyles = makeStyles(theme => ({
  root: {},
  statusError: {
    color: theme.palette.danger.main,
    fontWeight: 'bold'
  },
  statusSuccess: {
    color: theme.palette.success.main,
    fontWeight: 'bold'
  },
  statusUndefined: {
    color: theme.palette.info.main,
    fontWeight: 'bold'
  },
  iconBlue: {
    color: theme.palette.info.dark
  },
  iconRed: {
    color: theme.palette.warning.dark
  }
}))


export default function TableComponent(props) {
  const {
    data,
    error,
    loading,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    onSelect,
    selected,
    // step,
    changeStep,
    // handleDelete,
    checkingApprove,
    setPurchaseItem
  } = props
  const classes = useStyles()
  const areaGroups = useSelector(state => state.spesification);
  const [language, lagDic, setLang] = useLanguage()
  const countLoaded = Number(areaGroups?.items?.completed_response?.data?.INFO_PAGE?.TOTAL_RECORD ?? 0) ?? 0
  const [datas, setDatas] = useState( data ?? [])
  const [bergain, setBergain] = useState(false)
  // console.log("🚀 ~ file: index.jsx ~ line 55 ~ TableComponent ~ bergain", data)
  useEffect(()=> {
    setBergain(props?.isBergain)
  },[props?.isBergain])
  // useEffect(() => {
  //   if(data?.length > 0) setDatas(data)
  // },[data])

  function StatusHandler(stat) {
    if (stat === true) {
      return (
        <Typography className={classes.statusSuccess}>ACTIVE</Typography>
      )
    } else {
      return (
        <Typography className={classes.statusError}>INACTIVE</Typography>
      )
    }
  }
  function nameHandle(value, type) {
    let stat = ''
    if(type === 'PRICE_NEGO') {
      stat = `Rp. ${formatCurrency(Number(value?.PRICE_NEGO) ?? 0)}`
    }
    if(type === 'PRICE_TOTAL') {
      stat = `Rp. ${formatCurrency(value?.SUB_TOTAL_NEGO ?? 0)}`
    }
    return stat
  }

  const handleChangeNote = (row,val,id) => {
    let param = {}
    if(id === "PRICE_NEGO") {
      param = {...row, "PRICE_NEGO" : Number(val), 'SUB_TOTAL_NEGO': Number(val)*row?.ITEM_TOTAL}
    } if(id === "SUB_TOTAL_NEGO") {
      param = {...row, "SUB_TOTAL_NEGO" : Number(val), 'PRICE_NEGO': Number(val)/row?.ITEM_TOTAL}
    } if(id === "ADDITIONAL_INFO") {
      param = {...row, [id] : val}
    }
    // console.log("🚀 ~ file: index.jsx ~ line 87 ~ handleChangeNote ~ param", param)
    // // let arr = data?.filter(function(el){
    //   return el?.ITEM_NO !== param?.ITEM_NO;
    //   });
    const index = data.findIndex(object => {
      return object.ITEM_NO === row?.ITEM_NO;
    });
      const insert = (arr, index, newItem) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index)
      ]
       let arr = data?.filter(function(el){
      return el?.ITEM_NO !== param?.ITEM_NO;
      });
      const result = insert(arr, index, param)
      // setDatas(result)
      setPurchaseItem(result)
    // setDatas([...arr, param])
      // datas.find(v => v?.ITEM_NO === row?.ITEM_NO)?.ADDITIONAL_INFO = val
    // if (test) {
    //    data.find(v => v.ITEM_NO === data?.ITEM_NO).ADDITIONAL_INFO = param?.ADDITIONAL_INFO;
    //    setPurchaseItem([...data,param])
    // } else {
    // }
  }

  const columns = [
    { id: 'IMG_MAIN', value: 'IMG_MAIN', label: 'PICTURE', minWidth: 100, type: 'image' },
    { id: 'ITEM_NAME', value: 'ITEM_NAME', label: 'PRODUCT NAME', minWidth: 100 },
    { id: 'PRICE_NEGO', value: 'PRICE_NEGO', label: 'PRODUCT PRICE', minWidth: 100, customFormat: (value) => nameHandle(value, 'PRICE_NEGO'),
      numeric: true},
    { id: 'ITEM_TOTAL', value: 'ITEM_TOTAL', label: 'QUANTITY ORDER', minWidth: 100 },
    { id: 'SUB_TOTAL_NEGO', value: 'SUB_TOTAL_NEGO', label: 'TOTAL PRODUCT PRICE', minWidth: 100,customFormat: (value) => nameHandle(value, 'PRICE_TOTAL'),  numeric: true }

  ];
  const moveStep = () => {
    changeStep('edit-store')
  }

  const actionStructure = [
    {
      id: '1', value: '_key', label: 'Update data', icon: <EditIcon className={classes.iconRed} />, variant: 'default', color: 'primary', type: 'detail',
      onAction: (body) => checkingApprove(body, "update")
    },
    // {
    //   id: '2', value: 'name', label: 'View detail', icon: <VisibilityIcon className={classes.iconBlue} />, variant: 'default', color: 'primary',
    //   onAction: (body) => checkingApprove(body, "view")
    // }
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <CustomTable
      rows={data}
      loading={loading}
      error={error}
      selectableDetail
      onSelect={onSelect}
      changeStep={moveStep}
      selected={selected}
      // multiAction
      actionStructure={actionStructure}
      action={(body, type) => props.checkingApprove(body, type)}
      columns={columns}
      rowsPerPage={rowsPerPage || 1000}
      dbPagination
      totalCount={data?.length}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      noIndex
      noPagination
      folderImage={'product'}
    />
  );
}
