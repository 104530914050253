import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
	container: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		height: 'auto',
		width: '100%',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(5),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		},
	},
	activeTab: {
		fontWeight: 'bold',
		justifyContent: 'flex-start'
	},
	table: {
		'&:nth-of-type(even)': {
			backgroundColor: '#f1f5f8',
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	},
	tableHeader: {
		backgroundColor: '#f1f5f8',
	}
}));
