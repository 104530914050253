import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import { useExternal, useLanguage } from 'hooks'
import { Typography, Grid, Button } from '@mui/material'
import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router'


function Index() {
  const classes = useStyles()


  const [value, setValue] = useState({});
  console.log("🚀 ~ file: index.jsx ~ line 11 ~ Index ~ value", value)
  const navigate = useNavigate()

  const [language, lagDic, setLang] = useLanguage()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getData] = useExternal(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)

  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const status = queryParse.status

  useEffect(() => {

    const param = {
      page: 1,
      limit: 24
    }
    setDatatables(param)

  }, [])

  const homeHandler = () => {
    navigate('/furnitur')
  }



  return (
    <div className={classes.container}>
      <Grid
        xl={12}
        lg={12}
        md={12}
        xm={12}
        xs={12}
        className={classes.centered}
        container
      >
        {status === 'success' ?
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontWeight: 'bold', paddingBottom: 2 }} variant="h2" color="initial">{language.COMMON.VERIFY.TITLE_SUCCESS}</Typography>
            <Typography variant="h4" color="initial">{language.COMMON.VERIFY.BODY_SUCCESS}</Typography>
            <Button onClick={homeHandler} sx={{ marginTop: 5 }} variant='contained'>{language.COMMON.VERIFY.HOME}</Button>
          </Grid>

          : status === 'failed'
            ?
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontWeight: 'bold', paddingBottom: 2 }} variant="h2" color="initial">{language.COMMON.VERIFY.TITLE_FAILED}</Typography>
              <Typography variant="h4" color="initial">{language.COMMON.VERIFY.BODY_FAILED}</Typography>
              <Button onClick={homeHandler} sx={{ marginTop: 5 }} variant='contained'>{language.COMMON.VERIFY.HOME}</Button>
            </Grid>
            :
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontWeight: 'bold', paddingBottom: 2 }} variant="h2" color="initial">404</Typography>
              <Typography variant="h4" color="initial">Not Found</Typography>
              <Button onClick={homeHandler} sx={{ marginTop: 5 }} variant='contained'>{language.COMMON.VERIFY.HOME}</Button>
            </Grid>
        }
      </Grid>
    </div >
  )
}

export default Index
