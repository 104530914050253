const table = {
   TABLE_ACCOUNT_STATEMENT: {
      TIME: "Tanggal / Waktu",
      NO_REF: "No. Ref",
      DESC: "Deskripsi",
      AMOUNT: "Jumlah",
      BALANCE: "Saldo",
      START_BALANCE: "Saldo Awal",
      END_BALANCE: "Saldo Akhir",
   },
   TABLE_TRANSFER_HIS_EBANKING: {
      NO_REF: "No. Ref",
      DESTINATION_NAME: "Nama Tujuan",
      SOURCE_OF_FUND: "Sumber Dana",
      DESTINATION: "Tujuan",
      STATUS: "Status",
      AMOUNT: "Nominal",
      TRX_TYPE: "Jenis Transaksi",
      TRX_DATE: "Waktu Transaksi",
   },
   TABLE_PAYROLL_HIS_EBANKING: {
      PAYROLL_DATE: "Waktu Penggajian",
      PAYROLL_NAME: "Nama Daftar Penggajian",
      TOTAL_RECORD: "Total Penggajian",
      SUCCESS_PAYROLL: "Penggajian Berhasil",
      RESULT_CODE: "Result Code",
      PAYROLL_TIME: "Waktu Pembayaran",
      DESC: "Deskripsi",
      ACTION: "Aksi",
   },
   TABLE_SCHEDULE_TRANSFER: {
      NO_REF: "No.Ref",
      ACC_NAME: "Nama Rekening",
      ACC_NUMBER: "No Rekening",
      TRX_METHOD: "Metode Tranfer",
      BANK: "Bank",
      AMOUNT: "Nominal",
      TRANSFER_TIME: "Waktu Transfer",
      SCHEDULED_DATE: "Waktu Pembuatan",
      END_DATE: "Waktu berakhir",
      START_DATE: "Tanggal Mulai",
      ACTION: "Aksi",
   },
   TABLE_SCHEDULE_PAYROLL: {
      NO_REF: "No.Ref",
      PAYROLL_NAME: "Nama Penggajian",
      TOTAL_AMOUNT: "Total Nominal",
      TOTAL_RECORD: "Total Penggajian",
      PAYROLL_NEWS: "Berita Penggajian",
      TRX_TIME: "Waktu Transaksi",
      SCHEDULED_DATE: "Waktu Pembuatan",
      END_DATE: "Waktu berakhir",
      START_DATE: "Tanggal mulai",
      ACTION: "Aksi",
   },
   TABLE_ONGOING_TRANSFER: {
      NO_REF: "No.Ref",
      TASK_TYPE: "Jenis Tugas",
      ACC_NAME: "Nama Rekening",
      ACC_NUMBER: "No Rekening",
      CREATED_DATE: "Waktu Pembuatan",
      TRX_METHOD: "Metode Tranfer",
      TRX_TIME: "Waktu Transaksi",
      AMOUNT: "Nominal",
      STATUS: "Status",
   },
   TABLE_ONGOING_PAYROLL: {
      NO_REF: "No.Ref",
      TASK_TYPE: "Jenis Tugas",
      PAYROLL_NAME: "Nama Penggajian",
      TOTAL_AMOUNT: "Total Nominal",
      TOTAL_RECORD: "Total Penggajian",
      PAYROLL_NEWS: "Berita Penggajian",
      TRX_TIME: "Waktu Transaksi",
      CREATED_DATE: "Waktu Pembuatan",
      STATUS: "Status",
   },
   TABLE_PASTTASK_TRANSFER: {
      NO_REF: "No.Ref",
      TASK_TYPE: "Jenis Tugas",
      ACC_NAME: "Nama Rekening",
      ACC_NUMBER: "No Rekening",
      CREATED_DATE: "Waktu Pembuatan",
      TRX_METHOD: "Metode Tranfer",
      AMOUNT: "Nominal",
      TRX_TIME: "Waktu Transaksi",
      STATUS: "Status",
   },
   TABLE_PASTTASK_PAYROLL: {
      NO_REF: "No.Ref",
      TASK_TYPE: "Jenis Tugas",
      PAYROLL_NAME: "Nama Penggajian",
      PAYROLL_NEWS: "Berita Penggajian",
      CREATED_DATE: "Waktu Pembuatan",
      AMOUNT: "Nominal",
      TRX_TIME: "Waktu Transaksi",
      STATUS: "Status",
   },
   TABLE_BENEF_LIST: {
      ACCOUNT_NAME: "Nama Rekening",
      ACCOUNT_NUMBER: "Nomor Rekening",
      BANK_NAME: "Nama Bank",
      ALIAS: "Alias",
      ACTION: "Aksi",
   },
   TABLE_PAYROLL_LIST: {
      PAYROLL_DATE: "Tanggal Penggajian",
      PAYROLL_NAME: "Nama Penggajian",
      TOTAL_RECORD: "Total ",
      AMOUNT: "Total Nominal",
      SUCCESS_PAYROLL: "Penggajian Berhasil",
      PAYROLL_TIME: "Waktu Penggajian",
      DESC: "Deskripsi",
      MODIFIED_DATE: 'Tanggal Perubahan',
      STATUS: 'Last Upload Status',
      ACTION: "Action",
   },
   TABLE_EMPLOYEE_LIST: {
      ACCOUNT_NAME: "Nama Rekening",
      BANK: "Bank",
      ACCOUNT_NUMBER: "No Rekening",
      EMPLOYEE_ID: "NIP",
      AMOUNT: "Nominal",
      AUTO_DEBIT: "Auto Debit",
      ACTION: "Aksi",
   },
   TABLE_AUTO_DEBIT_LIST: {
      START_DATE: "Tanggal Mulai",
      END_DATE: "Tanggal berakhir",
      AUTO_DEBIT_NAME: "Nama Auto Debit",
      AUTO_DEBIT_CODE: "Kode Auto Debit",
      ACCOUNT_DEST: "Akun Tujuan Pribadi",
      PRIM_AMOUNT: "Nominal Utama",
      SEC_AMOUNT: "Nominal Tambahan",
      ACTION: "Aksi",
   },
   TABLE_STATUS_UPLOAD: {
      ACCOUNT_NAME: "Nama Rekening",
      BANK: "Bank",
      ACCOUNT_NUMBER: "No Rekening",
      EMPLOYEE_ID: "NIP",
      AMOUNT: "Nominal",
      AUTO_DEBIT: "Auto Debit",
      STATUS: "Status",
      ACTION: "Aksi",
   },
   TABLE_BRANCH_LOCATION: {
      DESC: "Deskripsi",
      ADDRESS: "Alamat",
      CHECK_LOCATION: "Cek Lokasi",
   },
   TABLE_BULK_TRANSFER_LIST: {
      DATE: "Tanggal",
      NAME: "Nama Bulk Transfer",
      TOTAL_RECORD: "Total ",
      AMOUNT: "Total Nominal",
      SUCCESS_BULK_TRANSFER: "Bulk Transfer Berhasil",
      TIME: "Waktu Penggajian",
      DESC: "Deskripsi",
      MODIFIED_DATE: 'Tanggal Perubahan',
      STATUS: 'Last Upload Status',
      ACTION: "Action",
      NEWS: "Berita Bulk Transfer",
   },
}

export default table

