const dashboard = {
  TITLE: 'TOKEN',
  DESC: 'Masuk dengan akun Anda yang terdaftar',
  HEADER: {
    STATEMENT_HEADER: 'Mutasi Rekening',
    EBANKING: 'Riwayat Transaksi Elektronik',
    TRANSACTION_TASK_HEADER: 'Tugas transaksi',
    BRANCH_HEADER: 'Lokasi Cabang',
    CHANGE_PASSWORD: 'Ubah Sandi',
    SFT_HEADER: 'Transfer Masa yang akan datang',
    INQUIRY_UPDATE_SFT: 'Perbarui Data masa yang akan datang',
    CONFIRM_UPDATE_SFT: 'Konfirmasi Perbarui Data masa yang akan datang',
    PENDING_FOR: 'Tertunda untuk',
    TRANSACTION_RESULT: 'Transaksi telah',
    REVIEWED: 'Ditinjau',
    APPROVED: 'Disetujui',
  },
  LABEL: {
    FAILED: 'Gagal',
    WARNING: 'Peringatan',
    ALERT_INFO: 'Informasi',
    EMPLOYEE_DETAIL: 'Detail Karyawan: ',
    BUTTON_DOWNLOAD: 'Unduh',
    CREATED_DATE: 'Tanggal pembuatan',
    NEW_DATE: 'Tanggal baru',
    SOURCE_FUND: 'Sumber dana',
    START_DATE: 'Tanggal Mulai',
    END_DATE: 'Tanggal Berakhir',
    BUTTON_SEARCH: 'Cari',
    REF_NUMBER: 'No. Ref',
    TRANSACTION_DATE: 'Tanggal Transaksi',
    ADMIN_FEE_AMOUNT: 'Nominal Biaya',
    TRANSACTION_TYPE: 'Jenis Transaksi',
    AMOUNT: 'Jumlah',
    CREDIT: 'Kredit',
    DEBIT: 'Debit',
    ADMIN_FEE: 'Biaya Admin',
    TOTAL_AMOUNT: 'Jumlah Total',
    BALANCE: 'Saldo',
    DESCRIPTION: 'Deskripsi',
    ADDRESS: 'Alamat',
    CITY: 'Kota',
    BRANCH_CODE: 'Kode Cabang',
    CHECK_LOCATION: 'Cek Lokasi',
    REFRESH_DATA: 'Perbarui Data',
    TARGET_NAME: 'Nama Tujuan',
    TARGET_ACCOUNT: 'Rekening Tujuan',
    TRANSFER_METHOD: 'Metode Transfer',
    TARGET_BANK: 'Bank Tujuan',
    FREQUENCY: 'Frekuensi',
    NEXT_TRANSFER: 'Transfer Selanjutnya',
    ACTION: 'Aksi',
    UPDATE: 'Perbarui',
    DELETE: 'Hapus',
    SOURCE_ACCOUNT: 'Sumber Rekening',
    TO: 'Ke',
    RECURRING_TYPE: 'Tipe Berulang',
    RECURRING_NEXT_DATE: 'Tanggal Berulang Selanjutnya',
    RECURRING_END_DATE: 'Tanggal Berakhir',
    TRANSFER_DATE: 'Tanggal Transfer',
    TOKEN: 'Token',
    BUTTON_SUBMIT: 'Kirim',
    SELECT_TASK_TYPE: 'Pilih Tipe Transaksi',
    TRANSFER_TASK_LIST: 'Daftar Tugas Transfer',
    PAYROLL_TASK_LIST: 'Daftar Tugas Penggajian',
    PAYMENT_TASK_LIST: 'Daftar Tugas Pembayaran',
    PURCHASE_TASK_LIST: 'Daftar Tugas Pembelian',
    VA_TASK_LIST: 'Daftar Tugas Virtual Account',
    STATUS: 'Status',
    CUSTOMER_NUMBER: 'Nomor Nasabah',
    CUSTOMER_NAME: 'Nama Nasabah',
    SERVICE_TYPE: 'Jenis Layanan',
    TRANSFER_TYPE: 'Jenis Transfer',
    PAYMENT_TYPE: 'Jenis Pembayaran',
    PURCHASE_TYPE: 'Jenis Pembelian',
    WAITING_FOR: 'Menunggu dari',
    REVIEW: 'Peninjau',
    APPROVAL: 'Penyetuju',
    REVIEW_2: 'Peninjauan',
    APPROVAL_2: 'Persetujuan',
    SELECT_PENDING_TYPE: 'Pilih Jenis Tertunda',
    TRANSFER_PENDING: 'Transfer Tertunda',
    PAYROLL_PENDING: 'Penggajian Tertunda',
    PAYMENT_PENDING: 'Pembayaran Tertunda',
    PURCHASE_PENDING: 'Pembelian Tertunda',
    FILE_PAYROLL: 'File Penggajian',
    CHECKSUM: 'Checksum',
    EFFECTIVE_DATE: 'Tanggal Efektif',
    TOTAL_RECORD: 'Jumlah Total',
    BILL_PERIOD: 'Periode Tagihan',
    BILL_REF_NO: 'No. Ref Tagihan',
    CUST_REF_NO: 'No. Ref Pelanggan',
    FARE_POWER: 'Fare/Power',
    STROOM_TOKEN: 'Stroom/Token',
    METER_STAND: 'Meter Stand',
    BILLING_AMOUNT: 'Jumlah Tagihan',
    PRODUCT: 'Produk',
    BUTTON_REJECT: 'Tolak',
    BUTTON_CONFIRM_REVIEW: 'Konfirmasi Peninjauan',
    BUTTON_CONFIRM_APPROVE: 'Konfirmasi Persetujuan',
    BUTTON_CONFIRM: 'Konfirmasi',
    BUTTON_BACK: 'Kembali',
    PARENT_VA: 'Parent VA',
    VA_NUMBER: 'VA Number',
    VA_LEVEL: 'VA Level',
    VA_TYPE: 'VA Type',
    VA_STATUS: 'VA Status',
    FILE_NAME: 'File Name',
    RECORD_COUNT: 'Record Count',
    VA_PENDING: 'Pending VA',
    PHONE: 'Nomor Telepon',
    OLD_PASSWORD: 'Sandi Lama',
    NEW_PASSWORD: 'Sandi Baru',
    CONFIRM_NEW_PASSWORD: 'Konfirmasi Sandi Baru',
    BUTTON_SAVE: 'Simpan',
    CANCEL_SFT: 'Batalkan',
    CANCEL_SFT_TASK_LIST: 'Batalkan Daftar Transfer Mendatang',
    CANCEL_SCH_PYR_LIST: 'Batalkan Daftar Gaji Mendatang',
    CANCEL_SFT_PENDING: 'Pembatalan Transaksi Terjadwal yang tertunda',
    EDIT_SFT: 'Edit SFT',
    EDIT_SFT_TASK_LIST: 'Edit Transaksi Terjadwal',
    EDIT_SFT_PENDING: 'Edit Transaksi Terjadwa lyang tertunda',
    UPLOAD_VA: 'Upload VA',
    ADD_VA: 'Tambah VA',
    EDIT_VA: 'Edit VA',
    DELETE_VA: 'Hapus VA',
    CHALLENGE_CODE: 'Challenge Code',
    INPUT_TOKEN: 'Masukkan token respons AAPLI 2 (8 Digit)',
    CANCEL_SCHEDULED_PAYROLL: 'Batalkan Penggajian Mendatang',
    CANCEL_SCHEDULED_PAYROLL_PENDING: 'Batalkan Penggajian Mendatang',
    PARAMETER_LEGEND: 'Catatan: Harap ketik filter Anda sesuai keinginan',
    PARAMETER_TITLE: 'Parameter Tambahan'
  },
  PLACEHOLDER: {
    SOURCE_FUND: 'Pilih Sumber Dana Anda',
    TOKEN: 'Masukkan Token',
    SELECT_FIELD: 'Pilih bidang untuk mencari',
    SEARCH: "Cari",
    TRX_TYPE: "Input berdasarkan tipe transaksi",
    STATUS: "Input berdasarkan status",
    ACCOUNT: "Input berdasarkan Rekening",
  },
  INFO: {
    NO_DATA: 'Tidak ada data yang dapat ditampilkan!',
    PASS_NOT_MATCH: 'Sandi dan Konfirmasi Sandi tidak cocok',
    NOTE: 'Catatan :',
    NOTE1: '* Jangka waktu mutasi yang bisa dipilih adalah 31 hari.',
    NOTE2: '* Mutasi Rekening maksimal 365 hari.',
    SEARCH_NOT_FOUND: 'Pencarian Tidak Ditemukan',
  },
}

export default dashboard