import React from 'react'
import TextField from '@mui/material/TextField';
import * as RegExp from '../../../helpers/regExp'
import { Typography } from '@mui/material';

export default function CustomDatefield(props) {
  const {
    classes,
    param,
  } = props

  const initialDate = !param ? "" : !param.value ? "" : param.value
  const [value, setValue] = React.useState(initialDate)
  const [error, setError] = React.useState(null)

  const handleChange = (e) => {
    setError(null)
    const checkString = e.target.value.length > 0 ? RegExp.CharSet.test(e.target.value) : true
    const checkNumber = e.target.value.length > 0 ? RegExp.NAME.test(e.target.value) : true
    if(param?.isNPWP){
      if(e.target.value.length <= 15) {
        param.onChange(e.target.name, e.target.value)
      }
    } else if(param?.isPhone) {
      if(e.target.value.length <= 15) {
        param.onChange(e.target.name, e.target.value)
      }
    } else {
      console.log("🚀 ~ file: WordField.jsx ~ line 16 ~ handleChange ~ checkString", checkString)
      if (param.noValidation || param.type === 'datetime-local') {
        param.onChange(e.target.name, e.target.value)
        setValue(e.target.value)
      } else if (param?.noNumber && checkNumber) {
        param.onChange(e.target.name, e.target.value)
        setValue(e.target.value)
      } else if (param?.codeValidation && !param?.noNumber) {
        const checkInit = e.target.value
        const checkUppercase = param?.upperCase ? checkInit?.toUpperCase() : checkInit;
        const checkSpace = param?.noSpace ? checkUppercase.replace(/\s/g, '') : checkUppercase;
        param.onChange(e.target.name, checkSpace)
        setValue(checkSpace)
      } else if (checkString && !param.noValidation && !param.codeValidation && !param?.noNumber) {
        param.onChange(e.target.name, e.target.value)
        setValue(e.target.value)
      } else setError('Special characters are not allowed')
    }
  }

  React.useEffect(() => {
    const checkInit = !param ? "" : !param.value ? "" : param.value
    const checkUppercase = param?.upperCase ? checkInit?.toUpperCase() : checkInit;
    const checkSpace = param?.noSpace ? checkUppercase.replace(/\s/g, '') : checkUppercase;
    setValue(checkSpace)
  }, [param])


  if (param?.mustHide) return null
  else return (
    <React.Fragment>
      <TextField
        // style={param?.disabled ? { background: '#DEE2E5' } : {}}
        className={classes.itemContent}
        fullWidth
        // label={param.label}
        InputLabelProps={{
          margin: 'dense'
        }}
        inputProps={{
          maxLength: 100,
        }}
        onChange={handleChange}
        id={param.id}
        margin="dense"
        value={value}
        required={param.required}
        disabled={param.disabled}
        readOnly={props.readOnlyAll}
        placeholder={param.placeholder}
        // helperText={param.helper}
        name={param.id}
        type={param.type}
        variant="outlined"
        multiline={param.multiline}
        rows={param.rows}
      />
      <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
    </React.Fragment>
  )
}
