import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Grid,
  TextField,
  Typography,
  ButtonGroup,
  Button,
  Box,
  Divider,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  IconButton,
  Select,
  Stack
} from '@mui/material'
import { useNavigate, useLocation } from 'react-router'
import SearchIcon from '@mui/icons-material/Search';
import noImage from "assets/images/noImage.jpeg"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { rupiahFormat, getAccessLog } from 'helpers'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import { Snackbar } from "components";
import Tab from '@mui/material/Tab';
import MainInformation from "./components/MainInformation"
import BuyerPpk from "./components/BuyerPpk"
import VendorDistributor from "./components/VendorDistributor"
import FundSource from "./components/FundSource"
import { useInput, useLanguage } from 'hooks';
import { validateSign } from 'controllers/_validate'
import moment from 'moment'


import { useCart, useProfile, useOrder, useContactUs } from 'hooks'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function Index(props) {                                       // Auto Scroll to Top

  const {
    setisCheckedout,
    handleSubmit,
    data,
    selected,
    dataProfile,
    dataVendor
  } = props

  // console.log("🚀 ~ file: index.jsx ~ line 101 ~ Index ~ dataProfile",getAccessLog("role"),' service order')
  console.log("🚀 ~ file: index.jsx ~ line 98 ~ Index ~ selected", selected)
  console.log("🚀 ~ file: index.jsx ~ line 97 ~ Index ~ props", props)
  const today = moment().format('YYYY-MM-DD');
  const classes = useStyles()
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);

  const navigate = useNavigate()

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [dialogTypeSB, setDialogTypeSB] = useState("error");
  const [openSB, setOpenSB] = useState(false);
  const [messageSB, setMessageSB] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getDetail] = useCart(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables3, setDatatables3, action] = useOrder(setIsLoading, setError, setOpenSB, setDialogTypeSB, setTitle, setMessageSB)
  const [datatables2, setDatatables2, getData] = useProfile(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)

  const [open, setOpen] = useState(false);
  const [openShip, setOpenShip] = useState(false);
  const [value, setValue] = React.useState(0);
  const [makeOrder, setMakeOrder] = useState(true);

  useEffect(() => {
    const param = {
      CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
      // CUSTOMER_NAME: "Maudy A",
      // CUSTOMER_NO: "00000009",
      USERNAME: getAccessLog('username'),
      page: 1,
      limit: 100
    }
    setDatatables2(param)
  }, [])

  useEffect(() => {
    if (datatables2?.length > 0) {
      const param = {
        CUSTOMER_NO: datatables2?.[0]?.CUSTOMER_NO,
        USERNAME: getAccessLog('username'),
        IS_CONTRACT: true,
        page: 1,
        limit: 100
      }
      setDatatables(param)
    }
  }, [datatables2])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleClick = (id, val) => {
    // setOpenShip(!openShip);
    setOpenShip({ ...openShip, [id]: val });
  };

  const baseUrlImage = window._env_?.BASEURL_IMAGE

  const sumPrice = () => {
    if (selected.length > 0) {
      var total = 0
      for (var i = 0, _len = selected.length; i < _len; i++) {
        for (var j = 0, _lenp = selected[i]['PRODUCTS'].length; j < _lenp; j++) {
          total += (selected[i]['PRODUCTS'][j]['ITEM_TOTAL'] * selected[i]['PRODUCTS'][j]['ITEM_PRICE'])
        }
      }
      return total
    }
  }
  const sumQty = () => {
    if (selected.length > 0) {
      var total = 0
      for (var i = 0, _len = selected.length; i < _len; i++) {
        for (var j = 0, _lenp = selected[i]['PRODUCTS'].length; j < _lenp; j++) {
          total += selected[i]['PRODUCTS'][j]['ITEM_TOTAL']
        }
      }
      return total
    }
  }
  // const shippingLocation = (loc) => {
  //   if (costShipping.hasOwnProperty(loc)) {
  //     return costShipping[loc]
  //   }
  // }
  const sumShipment = () => {
    if (selected.length > 0) {
      let total = 0
      for (let i = 0, _len = selected.length; i < _len; i++) {
        total += selected[i]['TOTAL_SHIPMENT_COST']
      }
      return total
    }
  }
  const sumQubic = (i) => {
    if (selected.length > 0) {
      let total = 0
      for (let j = 0, _lenp = selected[i]['PRODUCTS'].length; j < _lenp; j++) {
        total += (selected[i]['PRODUCTS'][j]['ITEM_TOTAL'] * selected[i]['PRODUCTS'][j]['PRODUCT_INFO'][0]['INFO_DIMENSION'])
      }
      return total.toFixed(2)
    }
  }
  const cartTotalShipment = selected.length > 0 ? sumShipment() : 0
  const cartTotalQty = selected.length > 0 ? sumQty() : 0
  const cartTotalPrice = selected.length > 0 ? sumPrice() : 0
  const initValue = {
    "Instance": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_CODE,
    "Created Date": today,
    "Modified Date": today,
    "Total Product": sumQty(),
    "Total Price": (cartTotalShipment + cartTotalPrice),
    "Name": getAccessLog('user_name'),
    "Position": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_POSITION,
    "NIP": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_NIP,
    "Email": getAccessLog('email'),
    "Phone Number": dataProfile?.[0]?.CP?.[0]?.CP_CONTACT,
    "Cerificate PBJ Number": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.CERTIFICATE_NO,
    "NameVendor": dataVendor?.[0]?.VALUE_01 ?? "",
    "NPWPVendor": dataVendor?.[0]?.VALUE_03,
    "AddressVendor": dataVendor?.[0]?.VALUE_09,
    "WebsiteVendor": dataVendor?.[0]?.VALUE_02,
    "EmailVendor": dataVendor?.[0]?.VALUE_06,
    "Phone NumberVendor": dataVendor?.[0]?.VALUE_04,
    "FaxVendor": dataVendor?.[0]?.VALUE_07,
    "NameDistributor": dataVendor?.[0]?.VALUE_01,
    "NPWPDistributor": dataVendor?.[0]?.VALUE_03,
    "AddressDistributor": dataVendor?.[0]?.VALUE_09,
    "WebsiteDistributor": dataVendor?.[0]?.VALUE_02,
    "EmailDistributor": dataVendor?.[0]?.VALUE_06,
    "Phone NumberDistributor": dataVendor?.[0]?.VALUE_04,
    "FaxDistributor": dataVendor?.[0]?.VALUE_07,
    "Work Unit": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.WORK_UNIT,
    "Work Unit NPWP": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.WORK_NPWP,
    "Work Unit Address": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.WORK_ADDRESS,
    "NamePPK": getAccessLog('role') === 'PPK' ? dataProfile?.[0]?.CUSTOMER_NAME : '',
    "PositionPPK": getAccessLog('role') === 'PPK' ? dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_POSITION : '',
    "NIPPPK": getAccessLog('role') === 'PPK' ? dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_NIP : '',
    "EmailPPK": getAccessLog('role') === 'PPK' ? dataProfile?.[0]?.CP?.[1]?.CP_CONTACT : '',
    "Phone NumberPPK": getAccessLog('role') === 'PPK' ? dataProfile?.[0]?.CP?.[0]?.CP_CONTACT : '',
    "Cerificate PBJ NumberPPK": getAccessLog('role') === 'PPK' ? dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.CERTIFICATE_NO : '',
  }
  const [handleFieldChange, values] = useInput(validateSign, initValue ?? {})
  console.log("🚀 ~ file: index.jsx ~ line 104 ~ Index ~ values", values)

  const totalShipment = (index, qubic, cost) => {
    console.log("🚀 ~ file: index.jsx ~ line 132 ~ totalShipment ~ cost", cost)
    console.log("🚀 ~ file: index.jsx ~ line 132 ~ totalShipment ~ qubic", qubic)
    let total = rupiahFormat(`${qubic * cost}`, 'Rp')
    console.log("🚀 ~ file: index.jsx ~ line 135 ~ totalShipment ~ total", total)

    return total

  }

  const [language, lagDic, setLang] = useLanguage()

  console.log("🚀 ~ file: index.jsx ~ line 256 ~ handleMakeOrder ~ purchasedItem", datatables)
  const handleMakeOrder = async (event) => {
    event.preventDefault();
    if (values["Budget Year"]) {
      if (makeOrder) {
        let purchasedItem = []
        let shipping = []
        datatables.map((item) => {
          const temp = {
            "ITEM_NO": item?.ITEM_NO,
            "ITEM_TOTAL": item?.ITEM_TOTAL,
            "ITEM_PRICE": item?.ITEM_PRICE,
            "PRICE_TOTAL": item?.ITEM_PRICE * item?.ITEM_TOTAL,
            "ADDITIONAL_INFO": item?.ADDITIONAL_INFO ?? ""
          }
          purchasedItem.push(temp)
        })

        selected.map((item, index) => {
          const temp = {
            "SHIPPING_PRICE": item?.TOTAL_SHIPMENT_COST,
            "SHIPPING_WEIGHT": "",
            "SHIPPING_COURIER": "GAIDO",
            "SHIPPING_WAYBILL": "",
            "SHIPPING_ADDRESS_ID": item?.ID,
            "SHIPPING_ITEM": []
          }
          item.PRODUCTS.map((item) => {
            const temp2 = {
              "ITEM_NO": item?.ITEM_NO,
              "ITEM_TOTAL": item?.ITEM_TOTAL
            }
            temp["SHIPPING_ITEM"].push(temp2)
          })
          shipping.push(temp)
        })

        console.log("🚀 ~ file: index.jsx ~ line 170 ~ handleMakeOrder ~ shipping", shipping)
        const param = {
          "PURCHASE_DATE": today,
          "TOKEN": getAccessLog('lkpp_token'),
          "CUSTOMER_NO": dataProfile?.[0]?.CUSTOMER_NO,
          "CONTRACT_INFO": {
            // "COMPANY_CODE":"CTC-0001",
            // "LATEST_NEGOTIATION":0.0,
            "CLIENT_ID":  getAccessLog('lkpp_identifier_client_id'),
            "CLIENT_SECRET": getAccessLog('lkpp_identifier_client_secret'),
            "VERTICAL_TYPE": getAccessLog('lkpp_identifier_vertical_type'),

            "PRODUCT_SHOWCASE": 'Peralatan Perkantoran',
            "PACKAGE_ID": values["Package ID"],
            "PACKAGE_NAME": values["Package Name"],
            "RUP_ID": values["RUP ID"],
            "INSTANCE_CODE": values["Instance"],

            "WORK_UNIT": values["Work Unit"],
            "WORK_UNIT_NPWP": values["Work Unit NPWP"],
            "WORK_UNIT_ADDRESS": values["Work Unit Address"],
            "TOTAL_PRODUCT": cartTotalQty,
            "TOTAL_PRICE": (cartTotalShipment + cartTotalPrice),

            "BUDGET_YEAR": values["Budget Year"],
            "BUDGET_SOURCE": values["Budget Source"],
            "BUDGET_CODE": values["Budget Code"],
            "BUYER_INFO": {
              "ENTITY_NAME": dataProfile?.[0]?.CUSTOMER_NAME,
              "ENTITY_POSITION": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_POSITION,
              "ENTITY_NIP": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_NIP,
              "CONTACT_EMAIL": dataProfile?.[0]?.CP?.[1]?.CP_CONTACT,
              "CONTACT_PHONE": dataProfile?.[0]?.CP?.[0]?.CP_CONTACT,
              "PJB_CERTIFICATE": dataProfile?.[0]?.CUSTOMERCOMPANY?.[0]?.CERTIFICATE_NO
            },
            "PPK_INFO": {
              "ENTITY_NAME": values["NamePPK"],
              "ENTITY_POSITION": values["PositionPPK"],
              "ENTITY_NIP": values["NIPPPK"],
              "CONTACT_EMAIL": values["EmailPPK"],
              "CONTACT_PHONE": values["Phone NumberPPK"],
              "PJB_CERTIFICATE": values["Cerificate PBJ NumberPPK"]
            },
            "VENDOR_INFO": {
              "ENTITY_NAME": values["NameVendor"],
              "NPWP_NO": values["NPWPVendor"],
              "CONTACT_ADDRESS": values["AddressVendor"],
              "WEBSITE_URL": values["WebsiteVendor"],
              "CONTACT_EMAIL": values["EmailVendor"],
              "CONTACT_PHONE": values["Phone NumberVendor"],
              "CONTACT_FAX": values["FaxVendor"]
            },

            "DISTRIBUTOR_INFO": {}
          },
          // "CUSTOMER_NO": param?.CUSTOMER_NO,
          "PRICE_ITEMS": cartTotalPrice,
          "PRICE_SHIPPING": cartTotalShipment,
          "PRICE_FULL": (cartTotalPrice + cartTotalShipment),
          "PURCHASE_ITEM": purchasedItem,
          "SHIPPING": shipping,
          "ACTION_BY": getAccessLog('username'),
          "ACTION_DATE": today,
        }
        console.log("🚀 ~ file: index.jsx ~ line 161 ~ handleMakeOrder ~ param", param)
        action(param, 'create')
        setMakeOrder(false)
      }
    } else {
      setOpenError(true)
    }
  }
  useEffect(() => {
    if (dialogTypeSB === "Success") {
      setOpenConfirmation(true)
    }
  }, [dialogTypeSB])

  const handleHome = () => {
    navigate('/furnitur')
    window.location.reload()
  }

  return (
    <React.Fragment>
      <Snackbar
        open={openSB}
        setOpen={setOpenSB}
        message={messageSB}
        severity={dialogTypeSB === "Success" ? 'success' : 'warning'}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong>
            {"Create New Address"}
          </strong>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">Label Address</Typography>
          <TextField
            fullWidth
            id="email"
            required
            color='info'
            name='label'
            placeholder="Label Address"
            size='small'
          />
          <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">Province </Typography>
          <Select
            fullWidth
            id="province"
            // value={age}
            color='info'
            size='small'
          // onChange={handleChange}
          >
            <MenuItem value={'Banten'}>Banten</MenuItem>
            <MenuItem value={'Jawa Barat'}>Jawa Barat</MenuItem>
          </Select>
          <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">City </Typography>
          <Select
            fullWidth
            id="city"
            // value={age}
            color='info'
            size='small'
          // onChange={handleChange}
          >
            <MenuItem value={'Tangerang'}>Tangerang</MenuItem>
            <MenuItem value={'Cimahi'}>Cimahi</MenuItem>
          </Select>
          <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">Full Address </Typography>
          <TextField
            sx={{ marginTop: 2, marginBottom: 1 }}
            fullWidth
            id="outlined-textarea"
            multiline
            rows={5}
          />
          <Typography sx={{ paddingTop: 2 }} variant="body1" color="initial">Status </Typography>
          <Select
            fullWidth
            id="city"
            // value={age}
            color='info'
            size='small'
            defaultValue={'Active'}
          // onChange={handleChange}
          >
            <MenuItem value={'Active'}>Active</MenuItem>
            <MenuItem value={'Inactive'}>Inactive</MenuItem>
          </Select>
          <Button sx={{ marginTop: 2 }} fullWidth size='small' color='info' variant='contained' onClick={handleClose} autoFocus>
            Create
          </Button>
        </DialogContent>
      </Dialog>

      {/* Confirmation */}
      <Dialog
        open={openConfirmation}
        // onClose={handleCloseConfirmation} 
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          <strong>Pesanan Dalam Proses</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pesanan Anda akan segera diproses, untuk melihat detail Pesanan, Anda dapat mengakses menu transaksi pada halaman profile
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-evenly', paddingBottom: 2 }} >
          <Button size='small' color='info' variant='outlined' onClick={handleHome}>Ke Halaman Utama</Button>
          <Button size='small' color='info' variant='contained' onClick={() => { navigate('/furnitur/profile') }} autoFocus>
            Menuju Profile
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openError}
        // onClose={handleCloseConfirmation} 
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          <strong>Data Tidak Lengkap</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Mohon untuk melengkapi data
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-evenly', paddingBottom: 2 }} >
          <Button size='small' color='info' variant='outlined' onClick={() => { setOpenError(false) }}>Kembali</Button>
        </DialogActions>
      </Dialog>


      {/* Content */}
      <form onSubmit={handleMakeOrder}>
        <Grid
          container spacing={2} >
          <Grid item xl={7} lg={7} md={7} sm={7} xs={7}>
            <Box
              sx={{
                py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  orientation="horizontal"
                  indicatorColor='primary'
                  textColor='primary'
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                >
                  <Tab sx={{ mt: 2, color: '#4D4F5C' }} label={<span className={value === 0 ? classes.activeTab : ""}>{language.COMMON.MY_TRANSACTION_DETAIL.MAIN_INFORMATION}</span>} {...a11yProps(0)} />
                  <Tab sx={{ mt: 2, color: '#4D4F5C' }} label={<span className={value === 1 ? classes.activeTab : ""}>{language.COMMON.MY_TRANSACTION_DETAIL.BUYER_PPK}</span>} {...a11yProps(1)} />
                  <Tab sx={{ mt: 2, color: '#4D4F5C' }} label={<span className={value === 2 ? classes.activeTab : ""}>{language.COMMON.MY_TRANSACTION_DETAIL.VENDOR_DISTRIBUTOR}</span>} {...a11yProps(2)} />
                  <Tab sx={{ mt: 2, color: '#4D4F5C' }} label={<span className={value === 3 ? classes.activeTab : ""}>{language.COMMON.MY_SHIPPING_ADDRESS.FUND_SOURCE}</span>} {...a11yProps(3)} />
                </Tabs>
              </Box>
                <TabPanel value={value} index={0} style={{ width: '100%' }} >
                  <MainInformation
                    values={values}
                    handleFieldChange={handleFieldChange}
                    dataProfile={dataProfile}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Button onClick={() => { setValue(1) }} sx={{ mb: 2, mr: 6 }} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.NEXT}</Button>
                  </Box>
                </TabPanel>
              <TabPanel value={value} index={1} style={{ width: '100%' }}>
                <BuyerPpk
                  values={values}
                  handleFieldChange={handleFieldChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Button onClick={() => { setValue(0) }} sx={{ mb: 2, mr: 2, color: '#2966C4', background: 'white', border: '1px solid #2966C4' }} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.BACK}</Button>
                  <Button onClick={() => { setValue(2) }} sx={{ mb: 2, mr: 6 }} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.NEXT}</Button>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2} style={{ width: '100%' }}>
                <VendorDistributor
                  values={values}
                  handleFieldChange={handleFieldChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Button onClick={() => { setValue(1) }} sx={{ mb: 2, mr: 2, color: '#2966C4', background: 'white', border: '1px solid #2966C4' }} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.BACK}</Button>
                  <Button onClick={() => { setValue(3) }} sx={{ mb: 2, mr: 6 }} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.NEXT}</Button>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={3} style={{ width: '100%' }}>
                <FundSource
                  values={values}
                  handleFieldChange={handleFieldChange}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Button onClick={() => { setValue(2) }} sx={{ mb: 2, mr: 6, color: '#2966C4', background: 'white', border: '1px solid #2966C4' }} color='primary' variant='contained'>{language.COMMON.MY_TRANSACTION_DETAIL.BACK}</Button>
                </Box>
              </TabPanel>
              {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
              {/* <Tabs
                  value={value}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={handleChange}
                  aria-label="basic tabs example">
                  <Tab label={<span className={value === 0 ? classes.activeTab : ""}>Main Information</span>} {...a11yProps(0)} />
                  <Tab label={<span className={value === 1 ? classes.activeTab : ""}>Buyer & PPK</span>} {...a11yProps(1)} />
                  <Tab label={<span className={value === 2 ? classes.activeTab : ""}>Vendor/Distributor</span>} {...a11yProps(2)} />
                  <Tab label={<span className={value === 3 ? classes.activeTab : ""}>Fund Source</span>} {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {window.scrollTo(0, 0)}
                <Grid container sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Product Showcase</Typography>
                    <TextField
                      id="procase"
                      label="Product Showcase"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Package ID</Typography>
                    <TextField
                      id="package"
                      label="Package ID"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">RUP ID</Typography>
                    <TextField
                      id="rup"
                      label="RUP ID"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Package Name</Typography>
                    <TextField
                      id="packname"
                      label="Package Name"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Instance</Typography>
                    <TextField
                      id="instance"
                      label="Instance"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Work Unit</Typography>
                    <TextField
                      id="wu"
                      label="Work Unit"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Work Unit NPWP</Typography>
                    <TextField
                      id="wunpwp"
                      label="Work Unit NPWP"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Work Unit Address</Typography>
                    <TextField
                      id="wuaddress"
                      label="Work Unit Address"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Created Date</Typography>
                    <TextField
                      id="created"
                      label="Created Date"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Modified Date</Typography>
                    <TextField
                      id="modified"
                      label="Modified Date"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Budget Year</Typography>
                    <TextField
                      id="budget"
                      label="Budget Year"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Total Product</Typography>
                    <TextField
                      id="topro"
                      label="Total Product"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                  <Stack sx={{ paddingTop: 1, paddingBottom: 1 }} direction="row" alignItems="center" gap={1} width='100%'>
                    <Typography width={'20%'} variant="body1" color="initial">Total Price</Typography>
                    <TextField
                      id="topri"
                      label="Total Price"
                      // value={}
                      // onChange={}
                      fullWidth
                      size='small'
                    />
                  </Stack>
                </Grid>
                <Button sx={{ marginTop: 2, width: '200px' }} size='small' color='info' variant='contained' autoFocus>
                  Next
                </Button>
              </TabPanel>
              <TabPanel value={value} index={1}>
                Item Two
              </TabPanel>
              <TabPanel value={value} index={2}>
                Item Three
              </TabPanel>
              <TabPanel value={value} index={3}>
                Item 4
              </TabPanel> */}
            </Box>


          </Grid>


          <Grid item xl lg md sm xs>
            <Box
              sx={{
                py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
              }}
            >
              <Typography variant="body1" color="initial">Pengiriman</Typography>
              <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', paddingTop: 5 }}>
                {
                  selected.length > 0 ?
                    selected.map((item, index) => {
                      return (
                        <List
                          sx={{ marginBottom: 1, width: '100%', bgcolor: '#F1F5F8', borderRadius: 1, border: 'solid 1px #B3BDCC' }}
                          component="nav"
                        >
                          <ListItemButton onClick={() => { handleClick(index, !openShip?.[index]) }} sx={{ height: 25 }}>
                            <ListItemText primary={item?.ADDRESS_NAME} />
                            {openShip[index] ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={openShip[index]} timeout="auto" unmountOnExit>
                            <List disablePadding>
                              <Box
                                sx={{
                                  py: 1, px: 2, my: 1, width: '100%', bgcolor: '#FFFFFF'
                                }}
                              >
                                <table style={{ width: '100%' }}>
                                  <tr>
                                    <td style={{ width: '20%' }}>
                                      <Typography variant="body1" color="initial">Province</Typography>
                                    </td>
                                    <td>
                                      <Typography variant="body1" color="initial">{item?.ADDRESS_PROVINSI}</Typography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <Typography variant="body1" color="initial">City</Typography>
                                    </td>
                                    <td>
                                      <Typography variant="body1" color="initial">{item?.ADDRESS_KABUPATEN}</Typography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <Typography variant="body1" color="initial">Full Address</Typography>
                                    </td>
                                    <td>
                                      <Typography variant="body1" color="initial">{item?.ADDRESS}</Typography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <Typography variant="body1" color="initial">Shipping Cost</Typography>
                                    </td>
                                    <td>
                                      <Typography variant="body1" color="initial"><strong>{sumQubic(index)} Kubik x {rupiahFormat(`${item?.SHIPMENT_COST}`, 'Rp')} = {totalShipment(index, sumQubic(index), item?.SHIPMENT_COST)} </strong></Typography>
                                    </td>
                                  </tr>
                                </table>
                              </Box>
                              <table style={{ width: '100%' }}>
                                <tr style={{ backgroundColor: '#F1F5F8' }}>
                                  <th align='left' style={{ width: '50%' }}>
                                    <Typography sx={{ paddingLeft: 2 }} variant="body1" color="initial"><strong> PRODUCT</strong></Typography>
                                  </th>
                                  <th>
                                    <Typography variant="body1" color="initial"><strong> PRICE</strong></Typography>
                                  </th>
                                  <th>
                                    <Typography variant="body1" color="initial"><strong> QTY</strong></Typography>
                                  </th>
                                  <th>
                                    <Typography variant="body1" color="initial"><strong> TOTAL</strong></Typography>
                                  </th>
                                </tr>
                                {item?.PRODUCTS.map((prod) => {
                                  console.log(prod)
                                  return (
                                    <tr style={{ backgroundColor: '#FFFFFF' }}>
                                      <td >
                                        <Typography sx={{ paddingLeft: 2 }} variant="body1" color="initial">{prod?.PRODUCT_INFO?.[0]?.ITEM_NAME}</Typography>
                                      </td>
                                      <td align='center'>
                                        <Typography variant="body1" color="initial">{rupiahFormat(`${prod?.ITEM_PRICE}`, 'Rp')}</Typography>
                                      </td>
                                      <td align='center'>
                                        <Typography variant="body1" color="initial">{prod?.ITEM_TOTAL}</Typography>
                                      </td>
                                      <td align='center'>
                                        <Typography variant="body1" color="initial">{rupiahFormat(`${prod?.ITEM_TOTAL * prod?.ITEM_PRICE}  `, 'Rp')}</Typography>
                                      </td>
                                    </tr>

                                  )
                                })}
                              </table>
                              {/* <Button variant="contained" onClick={() => { handleDeleteShipping(item) }} size='small' sx={{ fontSize: 9, float: 'right', marginRight: 2 }} color="error">
                                Delete
                              </Button> */}
                            </List>
                          </Collapse>
                        </List>
                      )
                    })
                    : null
                }
              </div>
            </Box>
            <Box
              sx={{
                py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
              }}
            >
              <Typography variant="body1" color="initial">{language.COMMON.MY_SHIPPING_ADDRESS.BUYER_INFORMATION}</Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
                <div>
                  <Typography variant="body2" color="secondary">{language.COMMON.MY_SHIPPING_ADDRESS.BUYER_NAME}</Typography>
                  <Typography variant="body2" color="secondary">{language.COMMON.MY_SHIPPING_ADDRESS.ROLE}</Typography>
                </div>
                <div>
                  <Typography variant="body2" align='right' color="secondary">{getAccessLog('username')}</Typography>
                  <Typography variant="body2" align='right' color="secondary">{getAccessLog('role')}</Typography>
                </div>
              </div>
            </Box>
            <Box
              sx={{
                py: 1, px: 2, my: 1, border: 'solid 1px gray', borderRadius: '5px', width: '100%'
              }}
            >
              <Typography variant="body1" color="initial">{language.COMMON.MY_SHIPPING_ADDRESS.PRODUCT}</Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
                <div>
                  <Typography variant="body2" color="secondary">{language.COMMON.MY_SHIPPING_ADDRESS.PRODUCT_QUANTITY}</Typography>
                </div>
                <div>
                  <Typography variant="body2" color="secondary">{cartTotalQty}</Typography>
                </div>
              </div>
              <Divider sx={{ paddingTop: 1, marginBottom: 1 }} />
              <Typography variant="body1" color="initial">{language.COMMON.MY_SHIPPING_ADDRESS.CART_TOTAL}</Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5 }}>
                <div>
                  <Typography variant="body2" color="secondary">{language.COMMON.MY_SHIPPING_ADDRESS.SUB_TOTAL}</Typography>
                  <Typography variant="body2" color="secondary">{language.COMMON.MY_TRANSACTION_DETAIL.SHIPPING_TOTAL}</Typography>
                </div>
                <div>
                  <Typography variant="body2" align='right' color="secondary"> {rupiahFormat(`${cartTotalPrice}`, 'Rp')}</Typography>
                  <Typography align='right' variant="body2" color="secondary">{rupiahFormat(`${cartTotalShipment}`, 'Rp')}</Typography>
                </div>
              </div>
              <Divider sx={{ paddingTop: 1, marginBottom: 1 }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography variant="body2" color="secondary"><strong>{language.COMMON.MY_TRANSACTION_DETAIL.TOTAL_PAYMENT}</strong></Typography>
                </div>
                <div>
                  <Typography variant="h5" color="secondary"><strong>{rupiahFormat(`${(cartTotalShipment + cartTotalPrice)}`, 'Rp')}</strong></Typography>
                </div>
              </div>
              <Button sx={{ marginTop: 2, marginBottom: 2 }} type='submit' size='small' color='info' variant="contained" fullWidth >
              {language.COMMON.CART.ORDER_CONFIRMATION}
              </Button>

            </Box>
          </Grid>
        </Grid >
      </form>

    </React.Fragment >
  )
}

export default Index
