const rows1 = [
  {id: 1, name: 'Executive Working Chair'},
  {id: 2, name: 'Manager Working Chair'},
  {id: 3, name: 'Visitor Chair'},
  {id: 4, name: 'Meeting WorkingChair'},
];
export default function structuredefault(values, handleFieldChange) {
  const data = [
    {
      id: 'SETUJU',
      label: '',
      type: 'text',
      fieldtype: 'textfield',
      // disabled: true,
      required:true,
      noSign: true,
      placeholder: 'insert "SETUJU"',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["SETUJU"] || '',
      minWidth: 170,
      // hide: isHide
    },
  ]

  return data
};