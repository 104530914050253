import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { lookupActions } from '../../controllers/_actions'

export default function useLookup(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage) {
  const [data, setData] = useState([])
  const lookups = useSelector(state => state.lookups);
  const dispatch = useDispatch()

  const setAction = useCallback((param, type) => {
    if (type === 'provinsi') {
      dispatch(lookupActions.getLookupProvinsi(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'kabupaten') {
      dispatch(lookupActions.getLookupKabupaten(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'zipcode') {
      dispatch(lookupActions.getLookupZipcode(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'historynego') {
      dispatch(lookupActions.getHistoryNego(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    } else if (type === 'pdfTransaction') {
      dispatch(lookupActions.getPdfTransaction(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage]);

  useEffect(() => {
    const DataLoaded = lookups || []
    setData(DataLoaded)
  }, [lookups])

  return [data, setAction]
}
