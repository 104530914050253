import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import { CustomBackdrop } from 'components';
import { useProduct, useSelected } from 'hooks'
import queryString from 'query-string'
import { useNavigate, useLocation } from 'react-router'
import FlowComponent from './flow'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material';

function Index(props) {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  console.log("🚀 ~ file: index.jsx ~ line 13 ~ Index ~ page", page)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getData] = useProduct(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)

  const [filter, setFilter] = useState({});
  const [filterSpec, setFilterSpec] = useState([]);
  const [filterPrice, setFilterPrice] = useState({});
  console.log("🚀 ~ file: index.jsx ~ line 25 ~ Index ~ filterPrice", filterPrice)
  const location = useLocation()
  const queryParse = queryString.parse(location.search)
  const query = queryParse.act
  const [step, setStep] = React.useState('List')
  const [selected, setSelected] = useSelected()
  const classes = useStyles()
  const product = useSelector(state => state.product);
  const totalPage = product?.items?.completed_response?.data?.INFO_PAGE?.PAGE_TOTAL
  const [rows, setRows] = useState([])
  const getLimit = useSelector(state => state.getLimit);
  console.log("🚀 ~ file: index.jsx ~ line 35 ~ Index ~ rows", rows)

  useEffect(() => {
    if (step === "List") {

      const param = {
        MODE: localStorage.getItem('Retail') === "false" ? 'CONTRACT' : 'RETAIL',
        CATEGORY_CODE: queryParse?.subCategory,
        FUNCTIONALITY: queryParse?.functionality ? [queryParse?.functionality] : filter?.functionality ? [filter?.functionality] : []
      }
      getData(param, 'getLimit')
    }
  }, [step, queryParse?.subCategory, queryParse?.functionality, filter?.functionality])

  React.useEffect(() => {
    const rowData = getLimit?.items?.data ?? []
    if (rowData.length > 0) setRows(rowData)
    else setRows([])
  }, [getLimit?.items?.data])


  // useEffect(() => {
  //   if (step === "List") {
  //     const param = {
  //       CATEGORY_CODE: queryParse?.subCategory,
  //       MIN: filterPrice?.min ?? 0,
  //       MAX: filterPrice?.max,
  //       FUNCTIONALITY: queryParse?.functionality ? [queryParse?.functionality] : [],
  //       SPECIFICATION: [],
  //       page: page,
  //       limit: 24
  //     }
  //     setDatatables(param)

  //   }

  // }, [step, queryParse?.subCategory, queryParse?.functionality, page, filterPrice])

  // useEffect(() => {
  //   if (step === "List") {
  //     if (filter || filterSpec.length > 0) {

  //       const param = {
  //         CATEGORY_CODE: queryParse?.subCategory,
  //         MIN: filterPrice?.min ?? 0,
  //         MAX: filterPrice?.max,
  //         RANGE: filter?.range ? [filter?.range] : [],
  //         FUNCTIONALITY: filter?.functionality ? [filter?.functionality] : [],
  //         SPECIFICATION: filterSpec ?? [],
  //         page: page,
  //         limit: 24
  //       }
  //       setDatatables(param)
  //     }

  //   }

  // }, [step, queryParse?.subCategory, page, filter, filterSpec,filterPrice])

  useEffect(() => {
    if (step === "List") {

      const param = {
        CATEGORY_CODE: queryParse?.subCategory,
        PRODUCT_NAME: queryParse?.PRODUCT_NAME,
        MIN: filterPrice?.min ?? 0,
        MAX: filterPrice?.max !== '0' && filterPrice?.max,
        RANGE: filter?.range ? [filter?.range] : [],
        FUNCTIONALITY: queryParse?.functionality ? [queryParse?.functionality] : filter?.functionality ? [filter?.functionality] : [],
        SPECIFICATION: filterSpec ?? [],
        TYPE: filter?.sorting ?? "ITM.CREATED_DATE DESC",
        page: page,
        limit: 24
      }
      setDatatables(param)

    }

  }, [step, queryParse?.subCategory, page, filter, filterSpec, filterPrice, queryParse?.functionality, queryParse?.PRODUCT_NAME])




  useEffect(() => {
    if (queryParse === "" || !queryParse.act) {
      const query = {
        ...queryParse,
        act: 'List',
      };
      const searchString = queryString.stringify(query);
      navigate({
        search: searchString
      })
    }

  }, [location.search])

  useEffect(() => {
    if (query) {
      setStep(query || "List")
    }
  }, [query])

  const handleChangeStep = (val, submit) => {
    handlePush(val, submit)
    setStep(val)
  }

  const handlePush = (type, submit) => {
    if (type !== "List") {
      const query = {
        ...queryParse,
        act: type,
        PRODUCT_NAME: submit?.PRODUCT_NAME,
        PRODUCT_CODE: submit?.PRODUCT_CODE
      };
      const searchString = queryString.stringify(query);
      navigate({
        search: searchString
      })
    } else {
      navigate({
        search: `?act=${type}`
      })
    }
  }

  const handleFilter = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    })
  }

  const handleFlow = async (submit, type) => {
    if (type === 'detail') {
      await setSelected(submit)
      handleChangeStep('Detail', submit)
    } else if (type === 'list') {
      //   await initFetch();
      handleChangeStep('List')
    }
  }

  return (
    <Box sx={{ overflowX: 'scroll'
    , scrollbarWidth: "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none"
    }
    }}>

      <CustomBackdrop loading={isLoading} label={'Collecting Resource'} />

      <FlowComponent
        changeStep={handleChangeStep}
        // data={props.type ? getSale?.items?.data : !props.type ? datatables : [] }
        data={datatables ?? []}
        loading={isLoading}
        error={error}
        step={step}
        handleSubmit={handleFlow}
        // param={param}
        selected={selected}
        onSelect={setSelected}
        checkingApprove={(body, type) => handleFlow(body, type)}
        pageType={queryParse?.category}
        totalPage={totalPage}
        setPage={setPage}
        selectedType={queryParse?.selectedType}
        subCategory={queryParse?.subCategory}
        handleFilter={handleFilter}
        setFilter={setFilter}
        filter={filter}
        setFilterSpec={setFilterSpec}
        filterSpec={filterSpec}
        setFilterPrice={setFilterPrice}
        filterPrice={filterPrice}
        catCode={queryParse?.ref}
        limit={rows}
      />
    </Box>
  )
}

export default Index
