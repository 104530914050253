export const profileRetailConstants = {
    GETALL_PROFILE_RETAIL_REQUEST: 'PROFILE_RETAIL_GETALL_REQUEST',
    GETALL_PROFILE_RETAIL_SUCCESS: 'PROFILE_RETAIL_GETALL_SUCCESS',
    GETALL_PROFILE_RETAIL_FAILURE: 'PROFILE_RETAIL_GETALL_FAILURE',

    GETALL_TRANSACTION_RETAIL_REQUEST: 'TRANSACTION_RETAIL_GETALL_REQUEST',
    GETALL_TRANSACTION_RETAIL_SUCCESS: 'TRANSACTION_RETAIL_GETALL_SUCCESS',
    GETALL_TRANSACTION_RETAIL_FAILURE: 'TRANSACTION_RETAIL_GETALL_FAILURE',

    UPDATE_REQUEST: 'ABOUT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ABOUT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ABOUT_UPDATE_FAILURE',

    CREATE_REQUEST: 'PROFILE_RETAIL_CREATE_REQUEST',
    CREATE_SUCCESS: 'PROFILE_RETAIL_CREATE_SUCCESS',
    CREATE_FAILURE: 'PROFILE_RETAIL_CREATE_FAILURE',
};
