import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import {
  Grid,
  TextField,
  Typography,
  ButtonGroup,
  Button,
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  IconButton,
  Select,
  MenuItem
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import noImage from "assets/images/noImage.jpeg"
import { rupiahFormat, getDayNow, getAccessLog } from 'helpers'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import moment from 'moment'
import { useCart, useShippingCost, useProfile, useAddress, useLookup, useInput, useLanguage, useGaido, useOrder } from 'hooks'
import { useSelector } from 'react-redux';
import { validateSign } from 'controllers/_validate';
import DefaultStructure from "./defaultStructure"
import { InputComponent } from 'components';

function Index(props) {
  const {
    setisCheckedout,
    handleSubmit,
    data,
    dataProfile,
    setafterUpdate,
    afterUpdate
  } = props
  const classes = useStyles();
  const [language, lagDic, setLang] = useLanguage();
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [handleFieldChange, values] = useInput(validateSign, {})
  const [open, setOpen] = useState(false); 
  const [openConfirmation, setOpenConfirmation] = useState(false); 
  const [datatables, setDatatables, getDetail] = useCart(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables2, setDatatables2, getData] = useProfile(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  // const [datatables3, setDatatables3] = useGaido(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables3, setDatatables3] = useShippingCost(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables4, setDatatables4, action] = useOrder(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)
  const [lookup, setLookup] = useLookup(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage);
  const [datas, setDatas, makeDatas] = useAddress(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage);
  const [cartList, setCartList] = useState([]);
  const [rows, setRows] = useState([]);
  const [counter, setcounter] = useState({})
  const [subTotal, setSubTotal] = useState({});
  const [provinsiOptions, setProvinsiOptions] = useState([])
  const [kabupatenOptions, setKabupatenOptions] = useState([])
  const [zipcodeOptions, setZipcodeOptions] = useState([])
  const [addresses, setAddresses] = useState([]);
  const [actualAddress, setActualAddress] = useState([])
  const [selectedAddress, setSelectedAddress] = useState({});
  const [onCreateAddress, setOnCreateAddress] = useState(false)
  const [counting, setCounting] = useState(true);
  const [itemShippingCost, setItemShippingCost] = useState([]);
  const [tempCartList, setTempCartList] = useState([]);
  const [makeOrder, setMakeOrder] = useState(true)
  
  // useEffect(() => {                             // <-------- dummy
  //   setCartTotalShipment(300000)
  // },[data])
  Array.prototype.sum = function (prop, ast) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += (this[i][prop] * this[i][ast])
    }
    return total
  }

  Array.prototype.sumQty = function (prop) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += this[i][prop]
    }
    return total
  }

  function getZipcode() {
    let numbers = [];
    if (arguments.length < 2) {
      return numbers;
    }
    Array.prototype.sort.call(parseInt(arguments));
    let x = parseInt(arguments[0]);
    let y = parseInt(arguments[arguments.length - 1]);
    if (x === y) {
      numbers.push(x)
    } else {
      for (let i = x; i < y + 1; i++) {
        numbers.push(i.toString());
      }
    }
    return numbers;
  }

  useEffect(() => {
    if (datatables?.length > 0) {
      setCartList(datatables)
    }
  }, [datatables])

  React.useEffect(() => {
    const rowData = cartList ?? []
    if (rowData.length > 0) setRows(rowData)
    else setRows([])
  }, [])

  useEffect(() => {
    cartList.map((item) => {
      setcounter((prevState) => ({
        ...prevState,
        [item?.ITEM_NO]: 0
      }));
    })
  }, [cartList])

  useEffect(() => {
    cartList.map((item) => {
      setSubTotal((prevState) => ({
        ...prevState,
        [item?.ITEM_NO]: item?.ITEM_PRICE * item?.ITEM_TOTAL
      }));
    })
  },[cartList])

  useEffect(() => {
    const param = {
      CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
      // CUSTOMER_NAME: "Maudy A",
      // CUSTOMER_NO: "00000009",
      USERNAME: getAccessLog('username'),
      page: 1,
      limit: 100
    }
    setDatatables2(param)
  }, [datas])

  useEffect(() => {
    const param = {
      page: 1,
      limit: 1000
    }
    setLookup(param, 'provinsi')
  }, [setLookup])

  useEffect(() => {
    if (values?.ADDRESS_PROVINSI) {
      const param = {
        "ADDRESS_PROVINSI": values?.ADDRESS_PROVINSI
      }
      setLookup(param, 'kabupaten')
    }
  }, [values?.ADDRESS_PROVINSI])

  useEffect(() => {
    if (values?.ADDRESS_KABUPATEN) {
      const param = {
        "ADDRESS_KABUPATEN": values?.ADDRESS_KABUPATEN
      }
      setLookup(param, 'zipcode')
    }
  }, [values?.ADDRESS_KABUPATEN])

  useEffect(() => {
    if (lookup?.provinsi) {
      setProvinsiOptions(lookup?.provinsi?.data ?? [])
    }
    if (lookup?.kabupaten) {
      setKabupatenOptions(lookup?.kabupaten?.data ?? [])
    }
    if (lookup?.zipcode) {
      setZipcodeOptions(getZipcode(lookup?.zipcode?.data?.[0]?.ZIPCODE_FROM, lookup?.zipcode?.data?.[0]?.ZIPCODE_TO)?? [])
    }
  }, [lookup])

  useEffect(() => {
      setActualAddress(datatables2?.[0]?.ADDRESS)
  }, [datatables2])

  useEffect(() => {
    actualAddress?.map((item) => {
      if (item.IS_CURRENT === true) {
        setSelectedAddress(item)
      }
    })
  }, [actualAddress])

  useEffect(() => {
    const obj = { ...selectedAddress }
    obj['PRODUCTS'] = cartList
    setAddresses([
      obj
    ])
  }, [selectedAddress])

  // const shippingLocation = (loc) => {
  //   let cost = costShipping?.COST_PERQUBIC
  //   return cost
  // }

  const sumShipment = () => {
    if (addresses.length > 0) {
      let total = 0
      for (let i = 0, _len = addresses.length; i < _len; i++) {
        total += addresses[i]['TOTAL_SHIPMENT_COST']
      }
      return parseInt(total)
    }
  }

  const sumPrice = () => {
    if (addresses.length > 0) {
      let total = 0
      for (let i = 0, _len = addresses.length; i < _len; i++) {
        for (let j = 0, _lenp = addresses[i]['PRODUCTS'].length; j < _lenp; j++) {
          total += (addresses[i]['PRODUCTS'][j]['ITEM_TOTAL'] * addresses[i]['PRODUCTS'][j]['ITEM_PRICE'])
        }
      }
      return parseInt(total)
    }
  }

  const sumQty = () => {
    if (addresses.length > 0) {
      let total = 0
      for (let i = 0, _len = addresses.length; i < _len; i++) {
        for (let j = 0, _lenp = addresses[i]['PRODUCTS'].length; j < _lenp; j++) {
          total += addresses[i]['PRODUCTS'][j]['ITEM_TOTAL']
        }
      }
      return parseInt(total)
    }
  }

  useEffect(() => {
    setCounting(true)
  }, [selectedAddress])
  useEffect(() => {
    setTimeout(() => {
      setCounting(false)
    }, 2000);
  }, [selectedAddress])

  const totalShipment = (item, index) => {
    let totalShipmentCost = 0
    for (let i = 0, _len = cartList.length; i < _len; i++) {
      totalShipmentCost += parseFloat(cartList?.[i]?.['PRODUCT_INFO'][0]['INFO_DIMENSION']) * parseFloat(cartList?.[i]?.ITEM_TOTAL) * parseFloat(itemShippingCost?.[i])
    }
    addresses[index]['TOTAL_SHIPMENT_COST'] = (Math.round(totalShipmentCost * 100) / 100).toFixed(0)
    let total = rupiahFormat(`${(Math.round(totalShipmentCost * 100) / 100).toFixed(0)}`, 'Rp')

    return total

  }
  // const totalShipment = (item, index) => {
  //   let totalShipmentCost = 0
  //   item?.PRODUCTS?.map((prod) => {
  //     totalShipmentCost += itemShippingCost[prod?.ITEM_NO]
  //   })
  //   addresses[index]['TOTAL_SHIPMENT_COST'] = totalShipmentCost
  //   let total = rupiahFormat(`${totalShipmentCost}`, 'Rp')

  //   return total

  // }

  const handleCreate = (e) => {
    e.preventDefault()

    setOnCreateAddress(true)
    const param = {
      "CUSTOMER_NO": datatables2?.[0]?.CUSTOMER_NO,
      "REFERENCE_OF": "CUSTOMER",

      "ADDRESS_NAME": values?.ADDRESS_NAME,

      "ADDRESS_PROVINSI": values?.ADDRESS_PROVINSI,
      "ADDRESS_KABUPATEN": values?.ADDRESS_KABUPATEN,
      "ADDRESS_KECAMATAN": "",
      "ADDRESS_KELURAHAN": "",
      "ADDRESS": values?.ADDRESS,
      "ZIPCODE": values?.ZIPCODE,
      "IS_CURRENT": values?.IS_CURRENT ? values?.IS_CURRENT === 'YES' ? true : false : false,

      "ACTION_BY": getAccessLog('username'),
      "ACTION_DATE": getDayNow('complate'),
      "ADDITIONAL_INFO": "",
      "IS_DUMP": false,
      "ACTIVE_STATUS": true
    }
    console.log("🚀 ~ file: index.jsx ~ line 273 ~ handleCreateAddress ~ param", param)
    makeDatas(param, 'create')
    const param2 = {
      CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
      // CUSTOMER_NAME: "Maudy A",
      // CUSTOMER_NO: "00000009",
      USERNAME: getAccessLog('username'),
      page: 1,
      limit: 100
    }
    setDatatables2(param2)
  }

  const handleMakeOrder = async () => {
    if (makeOrder) {
      console.log("🚀 ~ file: index.jsx ~ line 113 ~ handleMakeOrder ~ param", makeOrder);
      let purchasedItem = []
      let shipping = []

      shipping = {
        "SHIPPING_PRICE": cartTotalShipment,
        "SHIPPING_WEIGHT": "",
        "SHIPPING_COURIER": "RETAIL",
        "SHIPPING_WAYBILL": "",
        "SHIPPING_ADDRESS_ID": selectedAddress?.ID,
        "SHIPPING_ITEM": []
      }

      datatables.map((item) => {
        const temp = {
          "ITEM_NO": item?.ITEM_NO,
          "ITEM_TOTAL": item?.ITEM_TOTAL,
          "ITEM_PRICE": item?.ITEM_PRICE,
          "PRICE_TOTAL": item?.ITEM_PRICE * item?.ITEM_TOTAL,
        }
        purchasedItem.push(temp)
        const temp2 = {
          "ITEM_NO": item?.ITEM_NO,
          "ITEM_TOTAL": item?.ITEM_TOTAL,
        }
        shipping["SHIPPING_ITEM"].push(temp2)
      })

      console.log("🚀 ~ file: index.jsx ~ line 100 ~ handleMakeOrder ~ shipping", shipping)
      const param = {
        "PURCHASE_DATE": today,
        "CUSTOMER_NO": datatables2?.[0]?.CUSTOMER_NO,
        // "CUSTOMER_NO": param?.CUSTOMER_NO,
        "PRICE_ITEMS": cartTotalPrice,
        "PRICE_SHIPPING": cartTotalShipment,
        "PRICE_FULL": (cartTotalPrice + cartTotalShipment),
        "PURCHASE_ITEM": purchasedItem,
        "SHIPPING": shipping,
        "ACTION_BY": getAccessLog('username'),
        "ACTION_DATE": today,
      }
      console.log("🚀 ~ file: index.jsx ~ line 113 ~ handleMakeOrder ~ param", param)
      action(param, 'create')
      setMakeOrder(false)
      handleSubmit('checkout', addresses)
    }
  }

  const handleChangeAddress = (event) => {
    setSelectedAddress(event.target.value)

    const obj = { ...selectedAddress }
    const objProduct = JSON.parse(JSON.stringify(cartList));
    obj['PRODUCTS'] = objProduct
    setAddresses([
      obj
    ])
  }
  useEffect(() => {
    setItemShippingCost([]);
  }, [selectedAddress])
  useEffect(() => {
    if (cartList?.length > 1) {
      setTempCartList(cartList)
    }
  }, [selectedAddress])
  useEffect(() => {
    if (tempCartList?.length > 0) {
      let firstCart = tempCartList?.[0]
      const param = {
        KEYWORD: selectedAddress?.ADDRESS_KABUPATEN,
        page: 1,
        limit: 100
        // ZIPCODE: selectedAddress?.ZIPCODE,
        // INFO_WEIGHT: `${firstCart?.PRODUCT_INFO?.[0]?.INFO_WEIGHT * firstCart?.ITEM_TOTAL}`,
        // INFO_LENGHT: `${firstCart?.PRODUCT_INFO?.[0]?.INFO_LENGHT}`,
        // INFO_WIDTH: `${firstCart?.PRODUCT_INFO?.[0]?.INFO_WIDTH}`,
        // INFO_HEIGHT: `${firstCart?.PRODUCT_INFO?.[0]?.INFO_HEIGHT}`
      }
      setDatatables3(param)
    }
  },[tempCartList])
  useEffect(() => {
    if (cartList?.length === 1) {
      const param = {
        KEYWORD: selectedAddress?.ADDRESS_KABUPATEN,
        page: 1,
        limit: 100
        // ZIPCODE: selectedAddress?.ZIPCODE,
        // INFO_WEIGHT: `${cartList?.[0]?.PRODUCT_INFO?.[0]?.INFO_WEIGHT * cartList?.[0]?.ITEM_TOTAL}`,
        // INFO_LENGHT: `${cartList?.[0]?.PRODUCT_INFO?.[0]?.INFO_LENGHT}`,
        // INFO_WIDTH: `${cartList?.[0]?.PRODUCT_INFO?.[0]?.INFO_WIDTH}`,
        // INFO_HEIGHT: `${cartList?.[0]?.PRODUCT_INFO?.[0]?.INFO_HEIGHT}`
      }
      setDatatables3(param)
    }
  },[selectedAddress])
  useEffect(() => {
    let shippingCost = datatables3?.[0]?.COST_PERQUBIC_ALTERNATIVE
    if (cartList?.length > 1) {
      console.log("🚀 ~ file: index.jsx ~ line 332 ~ useEffect ~ shippingCost",shippingCost)
      if (shippingCost) {
        setItemShippingCost(itemShippingCost => ([...itemShippingCost,
          shippingCost]))
        setTempCartList(tempCartList.slice(1))
      }
    } else if (cartList?.length === 1) {
      setItemShippingCost([shippingCost])
    }
    console.log("🚀 ~ file: index.jsx ~ line 341 ~ useEffect ~ itemShippingCost",itemShippingCost)

    // GAIDO SHIPMENT
    // let normalPrice = datatables3?.filter(function (price) { return price.message.includes('NORMAL'); });
    // if (cartList?.length > 1) {
    //   console.log("🚀 ~ file: index.jsx ~ line 332 ~ useEffect ~ normalPrice",normalPrice)
    //   if (normalPrice?.[0]?.hargatotal) {
    //     setItemShippingCost(itemShippingCost => ([...itemShippingCost,
    //       normalPrice?.[0]?.hargatotal]))
    //     setTempCartList(tempCartList.slice(1))
    //   }
    // } else if (cartList?.length === 1) {
    //   setItemShippingCost([normalPrice?.[0]?.hargatotal])
    // }
    // console.log("🚀 ~ file: index.jsx ~ line 341 ~ useEffect ~ itemShippingCost",itemShippingCost)
  },[datatables3])
  const cartTotalShipment = addresses.length > 0 ? sumShipment() : 0
  const cartTotalQty = addresses.length > 0 ? sumQty() : 0
  const cartTotalPrice = addresses.length > 0 ? sumPrice() : 0
  // useEffect(() => {
  //   if (Object.keys(selectedAddress).length !== 0) {
  //     console.log("🚀 ~ file: index.jsx ~ line 122 ~ useEffect ~ Object.keys(selectedAddress).length", Object.keys(selectedAddress).length)
  //     const param = { 
  //       // KEYWORD: selectedAddress?.ADDRESS_KABUPATEN,
  //       // page: 1,
  //       // limit: 100
  //     }
  //     setDatatables3(param)
  
  //   }
  // }, [selectedAddress])

  // useEffect(() => {
  //   if (Object.keys(selectedAddress).length !== 0) {
  //     if (datatables3.length > 0) {
  //       setCostShipping({
  //           ...selectedAddress,
  //             LOC: datatables3?.[0]?.ADDRESS_KABUPATEN,
  //             COST_PERQUBIC: datatables3?.[0]?.COST_PERQUBIC,
  //             ID: datatables3?.[0]?.ID
  //         }
  //       )
  //     }
  //   }
  // }, [selectedAddress, datatables3])

  useEffect(() => {

    values["ADDRESS_NAME"] = ""
    values["ADDRESS_PROVINSI"] = ""
    values["ADDRESS_KABUPATEN"] = ""
    values["ADDRESS_KECAMATAN"] = ""
    values["ADDRESS_KELURAHAN"] = ""
    values["ADDRESS"] = ""
    values["ZIPCODE"] = ""

  }, [datatables2])

  useEffect(() => {
    if (dialogType == "Success") {
      setOpen(false)
    }
  }, [dialogType])

  const handleClose = () => {
    setOpen(false);
  };
  const today = moment().format('YYYY-MM-DD HH:mm:ss.ms');

  useEffect(() => {
    if (dialogType === "Success") {
      setOpenConfirmation(true)
    } else if (dialogType === "Error") {
      setDialogType("error")
    }
  }, [dialogType])
  
  const baseUrlImage = window._env_?.BASEURL_IMAGE
  return (
    <React.Fragment>
      {/* Dialog */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong>
            {"Create New Address"}
          </strong>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleCreate}>
            <InputComponent
              structure={DefaultStructure(values, handleFieldChange, provinsiOptions, kabupatenOptions, zipcodeOptions)}
              isLoading={isLoading}
              error={props?.error}
              divider={1}
              padding={1}
              minScreen="md"
            // readOnlyAll
            />

            <Button type='submit' sx={{ marginTop: 2 }} fullWidth size='small' color='info' variant='contained' autoFocus>
              Create
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      {/* Content */}
      <Grid
        container spacing={2} >
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <TableContainer component={Paper}>
            <Table size='small' sx={{ width: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell align="center"><strong>{language.COMMON.CART.PRODUCT}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.PRICE}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.QUANTITY}</strong></TableCell>
                  <TableCell align="center"><strong>{language.COMMON.CART.SUBTOTAL}</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartList.map((row) => (
                  <TableRow
                    className={classes.table}
                    key={row?.ITEM_NO}
                  >
                    <TableCell sx={{ width: '40%' }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div>
                          <img
                            alt=''
                            className={classes.otherImageContent}
                            onError={event => {
                              event.target.src = noImage
                              event.onerror = null
                            }}
                            src={`${baseUrlImage}/product/${row?.PRODUCT_INFO?.[0]?.IMG_MAIN}`} />
                        </div>
                        <div>
                          <Typography>
                            {row?.PRODUCT_INFO?.[0]?.ITEM_NAME}
                          </Typography>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">{rupiahFormat(JSON.stringify(row?.ITEM_PRICE), 'Rp')}</TableCell>
                    <TableCell align="center">{row?.ITEM_TOTAL}</TableCell>
                    <TableCell align="center">{rupiahFormat(`${row?.ITEM_PRICE && row?.ITEM_TOTAL ? subTotal[row?.ITEM_NO] : 0}`, 'Rp')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xl lg md sm xs>
          <Paper className={classes.paperContent}>
            <React.Fragment>
              <Typography>
              {language.COMMON.CART.SHIPPING_ADDRESS}
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between' , width:"100%"}}>
                  <div style={{width:"100%", marginRight: "10px" }}>
                    <Select
                      sx={{ width: '100%'}}
                      id="city"
                      // value={age}
                      color='info'
                      name='cityEdit'
                      value={selectedAddress ?? ''}
                      size='small'
                      onChange={handleChangeAddress}
                    >
                      {actualAddress?.map((item, idx) => {
                        if (item?.IS_CURRENT === true) {
                          return (
                            <MenuItem value={item}
                              key={idx}>
                              <Grid container sx={{ width: '100%', justifyContent: 'space-between' }}>
                                <Grid item>
                                  <Typography variant="body1" color="initial">
                                    {item?.ADDRESS_NAME}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Box component="span" sx={{ py: 0.5, px: 1, border: '1px solid #1070ca', color: '#1070ca', borderRadius: 1 }}>
                                    {language.COMMON.MY_SHIPPING_ADDRESS.MAIN_ADDRESS}
                                  </Box>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          )
                        } else {
                          return (
                            <MenuItem value={item}>
                              {item?.ADDRESS_NAME}
                            </MenuItem>
                          )
                        }
                      })}
                    </Select>
                  </div>
                  <div>
                    <Button color='info' onClick={() => setOpen(true)} variant="outlined" >
                      {language.COMMON.CART.ADD_ADDRESS}
                    </Button>
                  </div>
              </div>
                {Object.keys(selectedAddress).length === 0 && selectedAddress.constructor === Object ?
                  null
                  : <Box
                    sx={{
                      py: 1, px: 2, my: 1, border: 'dashed 1px gray', borderRadius: '5px', width: '100%', backgroundColor: '#EBF2FF'
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, width: '100%' }}>
                      <div style={{ width: '35%' }}>
                        <Typography variant="body1" color="initial">{language.COMMON.CART.PROVINCE}</Typography>
                        <Typography variant="body1" color="initial">{language.COMMON.CART.DISTRICT_CITY}</Typography>
                        <Typography variant="body1" color="initial">{language.COMMON.CART.FULL_ADDRESS}</Typography>
                      </div>
                      <div>
                        <Typography variant="body1" color="initial">{selectedAddress?.ADDRESS_PROVINSI}</Typography>
                        <Typography variant="body1" color="initial">{selectedAddress?.ADDRESS_KABUPATEN}</Typography>
                        <Typography variant="body1" color="initial">{selectedAddress?.ADDRESS}</Typography>
                      </div>
                    </div>
                  </Box>
                }
              <Divider sx={{ paddingTop: 2, marginBottom: 2 }} />
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <Typography>{language.COMMON.CHECK_OUT.SHIPPING_COST}</Typography>
                  <Typography>{language.COMMON.CHECK_OUT.TOTAL_PRODUCT_PRICE}</Typography>
                </Grid>
                <Grid item sx={{ textAlign: 'right' }}>
                {addresses.length > 0 ?
                  addresses.map((item, index) => {
                    return (
                  <Typography>{totalShipment(item, index)}</Typography>)})
                :
                <Typography>-</Typography>}
                  <Typography>{rupiahFormat(`${cartTotalPrice}`, 'Rp')}</Typography>
                </Grid>
              </Grid>
              <Divider sx={{ paddingTop: 2, marginBottom: 2 }} />
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item>
                  <Typography>
                    <strong>
                    {language.COMMON.CHECK_OUT.TOTAL_PAYMENT}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize={18} sx={{ textAlign: 'right' }}>
                    <strong>
                    {rupiahFormat(`${cartTotalShipment + cartTotalPrice}`, 'Rp')}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
              <Button
                disabled={ cartList?.length <= 0 || addresses?.length <= 0 || counting || itemShippingCost?.length !== cartList?.length}
                sx={{ marginTop: 2, marginBottom: 2 }} onClick={() => { handleMakeOrder() }} size='small' color='info' variant="contained" fullWidth >
              {language.COMMON.CART.TRANSACTION_CONFIRMATION}
              </Button>
            </React.Fragment>



          </Paper>
        </Grid>
      </Grid>

    </React.Fragment>
  )
}

export default Index
