const logout = {
  TITLE: 'LOGIN',
  DESC: 'Sign with your registred account',
  MODULE: {
    username: 'Username',
    password: 'Password'
  },
  HEADER: {
    TITLE: 'Welcome to Bank Capital!',
    DESC: 'Please Log in to Your Internet Banking Business Account',
  },
  LABEL: {
    CORPORATE_ID: 'Corporate ID',
    USER_ID: 'Username',
    PASSWORD: 'Password',
    BUTTON_LOGIN: 'Login',
    HELP: "Forget Password",
    SPONSOR: "Powered by :"
  },
  PLACEHOLDER: {
    CORPORATE_ID: 'Include Character & Numeric',
    USER_ID: 'e.g John Doe',
    PASSWORD: 'Password',
  },
  INFO: {},
}

export default logout