export const retailOrderConstants = {
    REGISTER_REQUEST: 'RETAILORDER_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'RETAILORDER_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'RETAILORDER_REGISTER_FAILURE',

    GETALL_REQUEST: 'RETAILORDER_GETALL_REQUEST',
    GETALL_SUCCESS: 'RETAILORDER_GETALL_SUCCESS',
    GETALL_FAILURE: 'RETAILORDER_GETALL_FAILURE',

    DELETE_REQUEST: 'RETAILORDER_DELETE_REQUEST',
    DELETE_SUCCESS: 'RETAILORDER_DELETE_SUCCESS',
    DELETE_FAILURE: 'RETAILORDER_DELETE_FAILURE',

    UPDATE_REQUEST: 'RETAILORDER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'RETAILORDER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'RETAILORDER_UPDATE_FAILURE'
};
