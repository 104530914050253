import React, { useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import clarityLogo from 'assets/images/logo.png'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import { validateSign } from 'controllers/_validate'
import { useNavigate } from 'react-router'
import { useUsers, useInput, useLanguage } from 'hooks'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, InputAdornment } from '@mui/material';
import { HandlerModal } from 'components'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  }
}));

export default function RegisterDialog(props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const [language, lagDic, setLang] = useLanguage()

  const [setValues, values, setValidation, errors] = useInput(validateSign)
const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [retypeVisible, setRetypeVisible] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [error2, setError2] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title2, setTitle2] = useState("");
  const [message2, setMessage2] = useState("");
  const [dialogType2, setDialogType2] = useState("Error");
  const [datas, setDatas, makeDatas] = useUsers(setIsLoading2, setError2, setOpen2, setDialogType2, setTitle2, setMessage2, navigate)

  const [open3, setOpen3] = useState(false);

  const {
    open,
    setOpen,
    handleLogin
  } = props

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  useEffect(() => {
    if (open2 === true && dialogType2 === 'success') {
      setOpen3(true)
    }
  }, [open2])


  function handleSubmit() {
    if (!errors.email && !errors.newPassword && !errors.confirmPassword) {
      let newValues ={
        ...values, 
        password : values?.newPassword, 
        retype: values.confirmPassword, 
        newPassword: undefined, 
        confirmPassword: undefined}
      console.log("🚀 ~ handleSubmit ~ newValues:", newValues)
      makeDatas(newValues, "create");
      handleClose();
    }
  }

  function handlePasswordVisible() {
    setPasswordVisible(!passwordVisible)
  }
  function handleRetypeVisible() {
    setRetypeVisible(!retypeVisible)
  }
  console.log('errors', errors);
  return (
    <div>
      <HandlerModal
        open={open3}
        onClose={handleClose3}
        dialogType={"Info"}
        message={language.COMMON.REGISTER.VERIFY}
        title={"Verify Your Email"}
        label={{
          positive: "OK",
          negative: language.COMMON.COMMON_BUTTON.CLOSE,
        }}
        action={{
          onClose: () => handleClose3(),
          positive: (body, type) => handleClose3(body, type),
          negative: () => handleClose3()
        }}
        // body={() => (body)}
        customWidth={"xs"}
        error={error}
        loading={isLoading}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        disablePortal={true}
      />
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open2} autoHideDuration={3000} onClose={() => { setOpen2(false) }}>
        <Alert onClose={handleClose2} severity={dialogType2} sx={{ width: '100%' }}>
          {message2}
        </Alert>
      </Snackbar>
   
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         {props?.isAddToChart ?
        <Box sx={{padding: "10px"}}>
          <Typography align='center' variant='h4' fontWeight={"bold"} color={"red"}>Penambahan Produk Tidak Dapat Dilakukan</Typography>
          <Typography align='center' variant='body1'>Masuklah kedalam account anda terlebih dahulu untuk dapat menambahkan produk kedalam keranjang</Typography>
        </Box>
        :
        <div style={{ display: 'flex', justifyContent: 'center', paddingLeft: 40, paddingRight: 40 }}>
          <div>
            <img style={{ width: '220px', paddingTop: 30, height: 'auto' }} src={clarityLogo} alt="" />

          </div>
        </div>
        }
        <DialogTitle id="alert-dialog-title">
          <strong>
            {language.COMMON.REGISTER.REGISTRATION}
          </strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ paddingBottom: 3 }} id="alert-dialog-description">
            <form noValidate autoComplete="false">
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">{language.COMMON.REGISTER.EMAIL}</FormLabel>
                <TextField
                  fullWidth
                  id="Email"
                  size='small'
                  onChange={event => {
                    setValues('email', event.target.value)
                  }}
                  onBlur={(e) => setValidation(values)}
                  required
                  disabled={isLoading}
                  value={values.email}
                  autoFocus
                  placeholder='Enter your email'
                  name='Email'
                  helperText={!errors.email ? null : errors.email}
                // onChange={}
                />
                <FormLabel sx={{ paddingTop: 1 }} component="legend">{language.COMMON.REGISTER.PASSWORD}</FormLabel>
                <TextField
                  fullWidth
                  id="Password"
                  type={!passwordVisible ? 'password' : 'text'}
                  onChange={event => {
                    setValues('newPassword', event.target.value)
                  }}
                  onBlur={(e) => setValidation(values)}
                  required
                  disabled={isLoading}
                  value={values?.newPassword}
                  error={errors?.newPassword}
                  autoFocus
                  size='small'
                  placeholder='Enter your password'
                  name='newPassword'
                  helperText={errors?.newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={handlePasswordVisible} sx={{ cursor: 'pointer' }}>
                        {!passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </InputAdornment>
                    )
                  }}
             
                />
                <FormLabel sx={{ paddingTop: 1 }} component="legend">Re-{language.COMMON.REGISTER.RE_TYPE_PASSWORD}</FormLabel>
                <TextField
                  fullWidth
                  id="confirmPassword"
                  type={!retypeVisible ? 'password' : 'text'}
                  onChange={event => {
                    setValues('confirmPassword', event.target.value)
                  }}
                  onBlur={(e) => setValidation(values)}
                  required
                  disabled={isLoading}
                  value={values?.confirmPassword}
                  autoFocus
                  size='small'
                  placeholder='Enter your password again'
                  name='confirmPassword'
                  error={errors?.confirmPassword}
                  helperText={errors?.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={handleRetypeVisible} sx={{ cursor: 'pointer' }}>
                        {!retypeVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </InputAdornment>
                    )
                  }}
                
                />
              </FormControl>
            </form>
          </DialogContentText>
          <Button fullWidth variant='contained' color='info' onClick={handleSubmit} autoFocus>
            {language.COMMON.REGISTER.REGISTER}
          </Button>
          <Typography variant="body1" color="initial">{language.COMMON.REGISTER.ALREADY_HAVE_ACCOUNT}
            <Link sx={{ cursor: 'pointer' }} color='#1070ca' onClick={handleLogin}> {language.COMMON.REGISTER.LOGIN_HERE}</Link>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
