import { Button, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import {InputComponent} from 'components'
import DefaultStructure from './Schema/default'
import { validateSign } from 'controllers/_validate'
import {
    useInput,
  } from 'hooks'
  import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/styles';
function AddComponent(props) {
    const [handleFieldChange, values] = useInput(validateSign, {})
    const [state, setState] = useState([]);
  
    const handleConfirm = () => {
        props.setList(values)
        props.close(false)
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F1F5F8",
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(even)': {
        backgroundColor: '#F1F5F8',
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));
  return (
    <Box sx={{width: 400}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h5'>Status History</Typography>
            <Button onClick={()=> {props?.close(false)}} endIcon={<CloseIcon></CloseIcon>}></Button>        
           </Box>
           <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h5'>{`Order ID: ${props?.values?.INVOICE_NO}`}</Typography>
            </Box>
          <Box>
          
        <TableContainer sx={{ height: 240, border: '1px solid #9ABBD0', borderRadius: '5px' }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                <TableRow>
                  <StyledTableCell sx={{textAlign: 'center'}}>STATUS</StyledTableCell>
                  <StyledTableCell sx={{textAlign: 'center'}}>UPDATE DATE</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {state?.map((row) => (
                    <StyledTableRow key={row.SPEC}>
                    <StyledTableCell component="th" scope="row">
                    {row?.SPEC}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                    {row?.VALUE}
                    </StyledTableCell>
                </StyledTableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </Box>
      {/* <FormControl sx={{mt: 2}}>
        <Button variant='contained' color='primary' onClick={handleConfirm}>Close</Button>
      </FormControl> */}

    </Box>
  )
}

export default AddComponent