import React from 'react'
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
// import {
//   getReformattedDay,
//   getFormattedDay,
//   // getDayNow,
// } from '../../../helpers'

export default function CustomDatefield(props) {
  const {
    classes,
    param
  } = props

  // const initialDate = !param?.value ? "" : getReformattedDay(param.value)
  const initialDate = param?.value || ""
  const [value, setValue] = React.useState(initialDate)

  const handleChange = (e) => {
    // param.onChange(e.target.name, getFormattedDay(e.target.value))
    param.onChange(e.target.name, e.target.value)
    setValue(e.target.value)
  }

  React.useEffect(() => {
    // const checkInit = !param ? "" : !param.value ? "" : getReformattedDay(param.value)
    const checkInit = param?.value || ""
    setValue(checkInit)
  }, [param])

  return (
    <React.Fragment>
      <TextField
        className={classes.itemContent}
        fullWidth
        margin="dense"
        // label={param.label}
        onChange={handleChange}
        id={param.id}
        // helperText={param.helper}
        value={value}
        disabled={param.disabled}
        readOnly={props.readOnlyAll}
        name={param.id}
        type={param.type}
        variant="outlined"
        multiline={param.multiline}
        rows={param.rows}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          format: 'dd/mm/yyyy'
        }}
      />
      <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
    </React.Fragment>
  )
}
