import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

function TextWithButton(props) {
    const {
        classes,
        param,
    } = props
    const handleOpen = () => {
        param.open(param?.id)
    }
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '7px' }}>
        <Box sx={{backgroundColor: '#E2ECFB', width: '100%', textAlign: 'center', padding: '8px 5px'}}>
            <Typography sx={{color: '#2966C4', fontWeight:'bold'}}>{param?.value}</Typography>
        </Box>
        <AccessTimeFilledIcon onClick={handleOpen} style={{ color: '#1569B2', marginLeft: '5px', cursor: 'pointer' }} />
    </div>
  )
}

export default TextWithButton