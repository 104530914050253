// 
import { authHeader } from '../_helpers'
import axios from '../_helpers/interceptor'

export const addressService = {
    getAll,
    createData,
    updateData,
    deactivateData,
};

async function getAll(param) {
    try {
        const bodyOptions = {
            "FILTER": {
                // "VALUE_02": param?.VALUE_02,
                // "VALUE_04": param?.VALUE_04,
                // "VALUE_KEY": "PAGE-POPULAR_PRODUCT",
                // "ACTIVE_STATUS": param?.ACTIVE_STATUS,
                "IS_DUMP": false,
            },
            "PAGINATION": {
                "PAGE_NO": `${param.page}`,
                "PAGE_MAX": `${param.limit}`
            }
        }
        const lang = param?.lang ?? "en";
        const requestUrl = `/clarity-be/customer/address/read`
        const checkHeader = await authHeader(requestUrl, bodyOptions)
        const requestOptions = {
            // headers: checkHeader
        }

        const response = await
            axios.post(`${requestUrl}`, bodyOptions, requestOptions)
                .then(response => {
                    console.log('Response save upload adalah : ', response);
                    // if (response.data.response_code === 'S1') {
                    return {
                        data: response.data.DATAS,
                        // status_code: response.data.INFO_RESPONSE?.RESPONSE_CODE,
                        status_code: response.status,
                        message: response.data.INFO_RESPONSE?.RESPONSE_MESSAGE,
                        completed_response: response
                    };
                })
                .catch(error => {
                    // Error 😨
                    console.log(error?.response);
                    if (error?.response?.status === 401) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.response?.status,
                            message: 'Unauthorized login!'
                        };
                    }

                    if (error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                            message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                        };
                    }

                    if (!error?.response) {
                        return {
                            // data: !response.data ? [] : response.data.data,
                            status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                            message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                        };
                    }
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                });
        return response;
    } catch (error) {
        console.log("🚀 ~ file: role.axios.js ~ line 68 ~ getAll ~ error", error)
        return error.response
    }
}

async function createData(param) {
    const bodyOptions = {
        "CUSTOMER_NO": param?.CUSTOMER_NO,
        "REFERENCE_OF": param?.REFERENCE_OF,
    
        "ADDRESS_NAME": param?.ADDRESS_NAME,
        
        "ADDRESS_PROVINSI": param?.ADDRESS_PROVINSI,
        "ADDRESS_KABUPATEN": param?.ADDRESS_KABUPATEN,
        "ADDRESS_KECAMATAN": param?.ADDRESS_KECAMATAN,
        "ADDRESS_KELURAHAN": param?.ADDRESS_KELURAHAN,
        "ADDRESS": param?.ADDRESS,
        "ZIPCODE": param?.ZIPCODE,
        "IS_CURRENT": param?.IS_CURRENT,
    
        "ACTION_BY": param?.ACTION_BY,
        "ACTION_DATE": param?.ACTION_DATE,
        "ADDITIONAL_INFO": param?.ADDITIONAL_INFO,
        "IS_DUMP": false,
        "ACTIVE_STATUS":true
    };
    const lang = param?.lang ?? "en";
    const requestUrl = `/clarity-be/customer/address/create`
    const checkHeader = await authHeader(requestUrl, bodyOptions, "not mandatory")
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        axios.post(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response save area adalah : ', response);
                // if (response.data.response_code === 'S1') {
                return {
                    data: response.data?.DATAS ?? [],
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
                // Error 😨
                console.log(error?.response);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };

            });
    return response;
}

async function updateData(param) {
    const bodyOptions = {
        "ID": param?.ID,
        "CUSTOMER_NO": param?.CUSTOMER_NO,
        "REFERENCE_OF": param?.REFERENCE_OF,
    
        "ADDRESS_NAME": param?.ADDRESS_NAME,
        
        "ADDRESS_PROVINSI": param?.ADDRESS_PROVINSI,
        "ADDRESS_KABUPATEN": param?.ADDRESS_KABUPATEN,
        "ADDRESS_KECAMATAN": param?.ADDRESS_KECAMATAN,
        "ADDRESS_KELURAHAN": param?.ADDRESS_KELURAHAN,
        "ADDRESS": param?.ADDRESS,
        "ZIPCODE": param?.ZIPCODE,
        "IS_CURRENT": param?.IS_CURRENT,
    
        "ACTION_BY": param?.ACTION_BY,
        "ACTION_DATE": param?.ACTION_DATE,
        "ADDITIONAL_INFO": param?.ADDITIONAL_INFO,
        "IS_DUMP": false,
        "ACTIVE_STATUS":true
    };;
    const lang = param?.lang ?? "en";
    const requestUrl = `/clarity-be/customer/address/update`
    const checkHeader = await authHeader(requestUrl, bodyOptions)
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        axios.put(`${requestUrl}`, bodyOptions, requestOptions)
            .then(response => {
                console.log('Response update data area adalah : ', response);
                return {
                    data: response.data?.DATA,
                    status_code: response.status,
                    message: response.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                };
            })
            .catch(error => {
                // Error 😨
                console.log(error);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                }

                if (error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || "400",
                        message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                    };
                }

                if (!error?.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.INFO_RESPONSE?.RESPONSE_CODE ?? error?.status ?? 400,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                }
                return {
                    // data: !response.data ? [] : response.data.data,
                    status_code: error.response.data.INFO_RESPONSE?.RESPONSE_CODE || 400,
                    message: error.response.data.INFO_RESPONSE?.RESPONSE_MESSAGE || "Error"
                };
            });
    return response;
}

async function deactivateData(param) {
    const newParam = { ...param }
    const bodyOptions = {
        "ID": param?.ID,
        "ACTION_BY": param?.ACTION_BY
    }
    const requestUrl = `/clarity-be/customer/address/delete`
    const checkHeader = await authHeader(requestUrl, bodyOptions)
    const requestOptions = {
        // headers: checkHeader
    };
    const response = await
        axios.put(`${requestUrl}`,bodyOptions, requestOptions)
            .then(response => {
                console.log('Response deactivate user adalah : ', response);
                if (response.status === 200) {
                    return {
                        // data: response.data.data,
                        status_code: response.status,
                        message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                    };
                }
                else {
                    return {
                        // data: response.data.data,
                        status_code: response.status,
                        message: response?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                    };
                }
            })
            .catch(error => {
                // Error 😨
                console.log('Response Error', error.response);
                if (error?.response?.status === 401) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Unauthorized login!'
                    };
                } else if (error?.response?.status === 404) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.response?.status,
                        message: 'Endpoint is not exist.'
                    };
                } else if (error) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.status,
                        message: error?.data?.INFO_RESPONSE?.RESPONSE_MESSAGE
                    };
                } else if (!error.response) {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error?.data?.response_code ?? error.status,
                        message: error?.data?.message ?? 'There is a problem with your internal connection. Please try again later or call your provider'
                    };
                } else {
                    return {
                        // data: !response.data ? [] : response.data.data,
                        status_code: error.response.response.code,
                        message: error.response.mesaage
                    };
                }
            });
    return response;
}

