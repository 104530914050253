import React, { useState, useEffect, useCallback } from 'react';
import {CurrencyField, InputComponent} from 'components'
import DefaultStructure from './Schema/default'
import { Typography, Grid, Button, Box , FormLabel} from '@mui/material';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
function General(props) {
  const selected = useSelector(state => state.selected)
  const initValue = {
    ADDRESS: selected?.SHIPPING?.[0]?.ADDRESS ?? '',
    SHIPPING_NO: selected?.SHIPPING?.[0]?.SHIPPING_NO ?? '',
    SHIPPING_NEGO: props?.values?.filter((val) => val.COMPONENT_ID === selected?.SHIPPING?.[0]?.SHIPPING_NO )?.[0]?.PRICE_PROPOSED ?? '',
    ADDRESS_KABUPATEN: selected?.SHIPPING?.[0]?.ADDRESS_KABUPATEN ?? '',
    ADDRESS_PROVINSI: selected?.SHIPPING?.[0]?.ADDRESS_PROVINSI ?? '',
    PHONE: selected?.SHIPPING?.[0]?.PHONE ?? '',
    SHIPPING_ITEM: selected?.SHIPPING?.[0]?.SHIPPING_ITEM ?? []

  }
  const [openModal, setOpenModal] = useState(false)
  const [list, setList] = useState([])
  const [values, setValues] = useState(initValue ?? {})
  const [puchaseAddress, setPurchaseAddress] = useState([])
  const [productItem, setProductItem] = useState(initValue?.SHIPPING_ITEM ?? [])
  // const [handleFieldChange, values] = useInput(validateSign, initValue ?? {})
  const [bergainValue, setBergainValue] = useState({})

  useEffect(() => {
    if(selected?.SHIPPING?.length > 0) {
      let result = []
      let temp2 = {}
      selected?.SHIPPING?.map((val)=>{
        let temp = {
          SHIPPING_NO : val?.SHIPPING_NO,
          ADDRESS : val?.ADDRESS,
          SHIPPING_NEGO : props?.values?.filter((val) => val.COMPONENT_ID === val?.SHIPPING_NO )?.[0]?.PRICE_PROPOSED,
          ADDRESS_KABUPATEN : val?.ADDRESS_KABUPATEN ,
          ADDRESS_PROVINSI : val?.ADDRESS_PROVINSI,
          BERGAIN_SHIPPING: val?.SHIPPING_NEGO,
          PHONE: val?.PHONE,       
          SHIPPING_ITEM: val?.SHIPPING_ITEM ?? []
        }
        temp2 = {...temp2, [val.SHIPPING_NO]: val?.SHIPPING_NEGO}
        result?.push(temp)
      })
      setBergainValue(temp2)
      setPurchaseAddress(result)
    }
  },[selected?.SHIPPING])
  useEffect(() => {
    if(puchaseAddress) {
      props?.setBergainPrice(puchaseAddress)
    }
  },[puchaseAddress])
  const handleChange = useCallback((field, value, reset) => {
    if (!reset) {
      if(field === 'SHIPPING_NO') {
        selected?.SHIPPING?.map((val) => {
            if(val?.SHIPPING_NO === value) {
              setValues({ 
                SHIPPING_NO : value,
                ADDRESS : val?.ADDRESS,
                SHIPPING_NEGO : props?.values?.filter((val) => val.COMPONENT_ID === value )?.[0]?.PRICE_PROPOSED,
                ADDRESS_KABUPATEN : val?.ADDRESS_KABUPATEN ,
                ADDRESS_PROVINSI : val?.ADDRESS_PROVINSI,
                PHONE: val?.PHONE
              })
              setProductItem( val?.SHIPPING_ITEM ?? [])
              } 
              // setBergainValue(0)
            })
          } 
       else {
      
      }
    }else {
      setValues({})
    }
  }, []);
  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleBergain = (e) => {
    // console.log("🚀 ~ file: index.jsx ~ line 122 ~ handleBergain ~ e", e)
    setBergainValue({...bergainValue, [values.SHIPPING_NO] : e})
     if(puchaseAddress?.length > 0){
          const test = puchaseAddress.find(v => v.SHIPPING_NO === values?.SHIPPING_NO)
          if (test) {
              puchaseAddress.find(v => v.SHIPPING_NO === values?.SHIPPING_NO).BERGAIN_SHIPPING = Number(e);
              setPurchaseAddress([...puchaseAddress])
          } else {
            setPurchaseAddress([...puchaseAddress,  {...values, BERGAIN_SHIPPING : Number(e) }])
          }
          }
        else {
          setPurchaseAddress([{...values, BERGAIN_SHIPPING : Number(e) }])
      }
  }
  useEffect(() => {
    if(props?.isBergain === false) {
      let temp2 = {}
      selected?.SHIPPING?.map((val)=>{
        temp2 = {...temp2, [val.SHIPPING_NO]: val?.SHIPPING_NEGO}
      })
      setBergainValue(temp2)
    }
  },[props?.isBergain])

  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F1F5F8",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: '#F1F5F8',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  return (
      <div>
        <Typography variant='h4' sx={{mb: 3}}>Shipping</Typography>
          <Grid container spacing={2} sx={{ml: 1}}>
            <Grid
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={12}>
              <InputComponent
              structure={DefaultStructure(values,handleChange, handleOpenModal, selected?.SHIPPING, props?.isBergain)}
              isLoading={props?.isLoading}
              error={props?.error}
              divider={1}
              padding={1}
              minScreen="md"
            // readOnlyAll
            />
            </Grid>
            <Grid
            xl={6}
            lg={6}
            md={12}
            sm={12}
            xs={12}
            sx={{ mt: 3, pl:5}}>
            <Typography variant='h6'>{values?.PHONE}</Typography>
            <Typography variant='h6'>{values?.ADDRESS_PROVINSI}</Typography>
            <Typography variant='h6'>{values?.ADDRESS_KABUPATEN}</Typography>
            <Typography variant='h6'>{values?.ADDRESS}</Typography>
          </Grid>
          </Grid>
          <Grid container sx={{pl:2}}>
          <Grid
           item
           lg={6}
           md={6}
           xl={6}
           sm={12}
           xs={12}>
          <TableContainer sx={{ height: 300, border: '1px solid #9ABBD0', borderRadius: '5px' }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                  <StyledTableCell>Product</StyledTableCell>
                  <StyledTableCell>Jumlah</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productItem?.map((row) => (
                    <StyledTableRow key={row?.ITEM_NAME}>
                    <StyledTableCell component="th" scope="row">
                      {row?.ITEM_NAME}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row?.ITEM_TOTAL}
                    </StyledTableCell>
                  </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
          {
          props?.isBergain ?
          <div>
          <FormLabel>Bergain Shipping</FormLabel>
          <CurrencyField
            value={bergainValue?.[values?.SHIPPING_NO]}
            onChange={handleBergain}
            // label={"Bergain Shipping"}
            // disabled={column.inputDisabled}
          ></CurrencyField>
          </div>
          :null
        }
      </div>
  );
}

export default General;
