import { rangeConstants } from '../_constants';
import { rangeService } from '../_services';

export const rangeActions = {
    getAll,
    createData
};

function getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await rangeService.getAll(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: rangeConstants.GETALL_REQUEST, } }
    function success(range) { return { type: rangeConstants.GETALL_SUCCESS, range } }
    function failure(error) { return { type: rangeConstants.GETALL_FAILURE, error } }
}

function createData(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request());
        const res = await rangeService.createData(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res))
            setIsLoading(false)
            setError(null)
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Success")
            setTitle("Completed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 500) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (res.status_code === 401) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Session is over!")
            setMessage(`${res.message}`)
            setOpen(true)
        } else if (!res.status_code) {
            dispatch(failure(res))
            setIsLoading(false)
            setError(`${res.message}`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(`${res.message}`)
            setOpen(true)
        } else {
            dispatch(failure(res))
            setIsLoading(false)
            setError(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setDialogType("Error")
            setTitle("Failed")
            setMessage(res?.message ?? `Error Unknown : Maybe there's something wrong with your connection!`)
            setOpen(true)
        }
    };

    function request() { return { type: rangeConstants.CREATE_REQUEST, } }
    function success(officeFitsOut) { return { type: rangeConstants.CREATE_SUCCESS, officeFitsOut } }
    function failure(error) { return { type: rangeConstants.CREATE_FAILURE, error } }
}