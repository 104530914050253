import React, { useState, useEffect } from 'react'
import Header from './Header'
import Navigation from './Navigation'
import Footer from './Footer'
import {Grid,Tooltip } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import iconWA from 'assets/images/icon_whatsapp.png';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from "@mui/material/Fab";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import { useLanguage } from 'hooks'
import Toolbar from '@mui/material/Toolbar';
import { useContactUs } from 'hooks'
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(5),
    width: '100vw',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(5),
    },
  }
}))


function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });


  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function GeneralLayout(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form, setform] = useState({});
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [language, lagDic, setLang] = useLanguage()

  const [dialogType, setDialogType] = useState("error");
  const [contact, setContact] = useContactUs(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage)

  useEffect(() => {

    const param = {
      page: 1,
      limit: 10
    }
    setContact(param)
  }, [])
  return (
    <div>
    <Grid container>
          <CssBaseline />
          <ElevationScroll {...props}>
            <AppBar>
              {/* <Toolbar> */}
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Header></Header>
                <Navigation></Navigation>
              </Grid>
              {/* </Toolbar> */}
            </AppBar>
          </ElevationScroll>
          <Toolbar />
          <div className={classes.container}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} minHeight={'80vh'} sx={{marginTop: 2}}>
              {props?.child}

            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
              <Footer contact={contact?.[0] ?? ''} />
            </Grid>
          </div>
          <Tooltip title={language.COMMON.MENU.CHAT_WITH_US}>
            <Fab
              aria-label="add"
              size="medium"
              target="_blank"
              href={`https://wa.me/${contact?.[0]?.VALUE_05}`}
              sx={{
                position: 'fixed',
                top: '88%',
                right: '2%',
                backgroundColor: '#ffffff',
                '&:hover': {
                  backgroundColor: '#13990a',
                }
              }} >
              {/* <AddIcon /> */}

              < img
                style={{ width: "130%", height: "auto", paddingTop: 20 }}
                src={iconWA}
                alt=""
              />
            </Fab>
          </Tooltip>
        </Grid >
    </div>
  )
}

export default GeneralLayout
