import React from 'react'
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowLeadingZeros={false}
      // thousandSeparator
      isNumericString
      // decimalSeparator="."
      // fixedDecimalScale
      // allowNegative
      decimalScale
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default function CustomDatefield(props) {
  const {
    classes,
    param,
  } = props

  const initialDate = !param ? "" : !param.value ? "" : param.value
  const [value, setValue] = React.useState(initialDate)

  const handleChange = (e) => {
    param.onChange(e.target.name, e.target.value)
    setValue(e.target.value)
  }

  React.useEffect(() => {
    const checkInit = !param ? "" : !param.value ? "" : param.value
    setValue(checkInit)
  }, [param])

  return (
    <React.Fragment>
      <TextField
        className={classes.itemContent}
        fullWidth
        id={param.id}
        margin="dense"
        // label={param.label}
        InputLabelProps={{
          margin: 'dense'
        }}
        value={value}
        onChange={handleChange}
        disabled={param.disabled}
        readOnly={props.readOnlyAll}
        placeholder={param.placeholder}
        required={param?.required}
        // helperText={param.helper}
        name={param.id}
        type={param.type}
        variant="outlined"
        multiline={param.multiline}
        rows={param.rows}
        prefix={param.prefix}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
      <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
    </React.Fragment>
  )
}
