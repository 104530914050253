import React from 'react'
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';

export default function CustomDatefield(props) {
  const {
    classes,
    param,
  } = props
  console.log("🚀 ~ file: TimeField.jsx ~ line 10 ~ CustomDatefield ~ param", param)
  const checkInit = param?.value
  const [value, setValue] = React.useState(checkInit)
  const [error, setError] = React.useState(null)
  const [hour, setHour] = React.useState()
  const [minute, setMinute] = React.useState()
  const [second, setSecond] = React.useState()

  function leftPad(number, targetLength) {
    var output = number + '';
    while (output.length < targetLength) {
      output = '0' + output;
    }

    if (output.length >= targetLength) {
      output = output.toString().slice(-2)
    }

    return output;
  }

  React.useEffect(() => {
    const initHour = param?.value?.substr(0, 2);
    console.log("🚀 ~ file: TimeField.jsx ~ line 34 ~ React.useEffect ~ initHour", initHour)
    const initMinute = param?.value?.substr(3, 2);
    console.log("🚀 ~ file: TimeField.jsx ~ line 36 ~ React.useEffect ~ initMinute", initMinute)
    const initSecond = param?.value?.substr(6, 2);
    console.log("🚀 ~ file: TimeField.jsx ~ line 38 ~ React.useEffect ~ initSecond", initSecond)
    setHour(initHour)
    setMinute(initMinute)
    setSecond(initSecond)
  }, [param.value])

  React.useEffect(() => {
    const newValue = `${hour}:${minute}:${second}`
    console.log("🚀 ~ file: TimeField.jsx ~ line 41 ~ React.useEffect ~ newValue", newValue)
    setValue(newValue)
  }, [hour, minute, second])

  const handleChange = (e) => {
    setError(null)
    const { name, value } = e.target
    if (name === 'hour') {
      const newVal = value <= 24 && leftPad(value, 2)
      setHour(newVal)
      handleSubmitChanges(name, newVal)
    }
    if (name === 'minute') {
      const newVal = value <= 60 && leftPad(value, 2)
      setMinute(newVal)
      handleSubmitChanges(name, newVal)
    }
    if (name === 'second') {
      const newVal = value <= 60 && leftPad(value, 2)
      setSecond(newVal)
      handleSubmitChanges(name, newVal)
    }
  }

  const handleSubmitChanges = (caseType, val) => {
    if (caseType === 'hour') {
      param.onChange(param.id, `${val}:${minute}:${second}`)
    }
    else if (caseType === 'minute') {
      param.onChange(param.id, `${hour}:${val}:${second}`)
    }
    else if (caseType === 'second') {
      param.onChange(param.id, `${hour}:${minute}:${val}`)
    }
  }

  if (param?.mustHide) return null
  else return (
    <React.Fragment>
      <div className={classes.timefield}>
        <TextField
          // style={param?.disabled ? { background: '#DEE2E5' } : {}}
          className={classes.itemContentTime}
          fullWidth
          // label={param.label}
          InputLabelProps={{
            margin: 'dense'
          }}
          inputProps={{
            maxLength: 100,
          }}
          onChange={handleChange}
          id={`hour-${param.id}`}
          margin="dense"
          value={hour}
          required={param.required}
          disabled={param.disabled}
          readOnly={props.readOnlyAll}
          placeholder={'hh'}
          helperText={param.helper}
          name={`hour`}
          type={'number'}
          label="hour"
          variant="outlined"
        />
        <TextField
          style={param?.disabled ? { background: '#DEE2E5' } : {}}
          className={classes.itemContentTime}
          fullWidth
          // label={param.label}
          InputLabelProps={{
            margin: 'dense'
          }}
          inputProps={{
            maxLength: 100,
          }}
          onChange={handleChange}
          id={`hour-${param.id}`}
          margin="dense"
          value={minute}
          required={param.required}
          disabled={param.disabled}
          readOnly={props.readOnlyAll}
          placeholder={'mm'}
          helperText={param.helper}
          name={'minute'}
          type={'number'}
          label="minute"
          variant="outlined"
        />
        <TextField
          style={param?.disabled ? { background: '#DEE2E5' } : {}}
          className={classes.itemContentTime}
          fullWidth
          // label={param.label}
          InputLabelProps={{
            margin: 'dense'
          }}
          inputProps={{
            maxLength: 100,
          }}
          onChange={handleChange}
          id={`hour-${param.id}`}
          margin="dense"
          value={second}
          label="second"
          required={param.required}
          disabled={param.disabled}
          readOnly={props.readOnlyAll}
          placeholder={'ss'}
          // helperText={param.helper}
          name={`second`}
          type={'number'}
          variant="outlined"
        />
         <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
      </div>
    </React.Fragment>
  )
}
