import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    button: {
        cursor: 'pointer',
        height: 70,
        "&:hover": {
            background : '#EEEEEE',
            cursor: 'pointer',
          },
        paddingLeft: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        '& > *' : {
            marginLeft: theme.spacing(2)
        },
        [theme.breakpoints.down('md')]: {
          paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
          paddingLeft: theme.spacing(0),
        },
    },
    subMenuMultiple: {
      height: 'fit-content',
      position: "relative",
      backgroundColor: "#ffffff",
      padding: "20px",
      [theme.breakpoints.down('md')]: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: -20,
        right: 0,
      },
      [theme.breakpoints.down('sm')]: {
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        left: -20,
        right: 0,
      },
  },
  arrowLeft: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  }
  }));
