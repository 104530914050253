import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import clarityLogo from "assets/images/logo.png"
import clarityLogoMin from "assets/images/logoMin.png"
import lkppLogo from "assets/images/logo_lkpp.png"
import { AccountCircle, ShoppingCart } from '@mui/icons-material';
import indonesiaIcon from 'assets/images/indonesia.png'
import unitedKingdomIcon from 'assets/images/united-kingdom.png'
import useStyles from './styles'
import formatRupiah from 'helpers/rupiahFormat';
import { useNavigate, useLocation } from 'react-router'
import queryString from 'query-string'
import { useCart, useLanguage } from 'hooks'
import {
  Login,
  Register
} from './component'
import getAccessLog from 'helpers/getAccessLog'
import { useLogout, useProfile, useProfileRetail } from 'hooks';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';

const Search = styled('div')(({ theme }) => ({
  border: '1px solid gray',
  borderRadius: theme.shape.borderRadius,
  color: '#20376D',
  display: 'flex',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 1),
  background: '#20376D',
  color: 'white',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [lang, langDic, setLang] = useLanguage()
  const [setLogout] = useLogout()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [openRegister, setOpenRegister] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [language, setLanguage] = useState(langDic ?? 'id')

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const cartList = useSelector(state => state.cart);
  const [datatables, setDatatables, getDetail] = useCart(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables2, setDatatables2, getData] = useProfile(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [datatables3, setDatatables3, getData3] = useProfileRetail(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  const [cartTotal, setCartTotal] = useState(0)
  console.log("🚀 ~ file: index.jsx ~ line 113 ~ PrimarySearchAppBar ~ cartTotal", cartTotal)
  const [cartTotalPrice, setCartTotalPrice] = useState(0)
 useEffect(() => {
    if (localStorage.getItem('Retail') === "false"){
      if (getAccessLog('username')) {
        const param = {
          CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
          USERNAME: getAccessLog('username'),
          page: 1,
          limit: 100
        }
        setDatatables2(param)
      }
    } else if (localStorage.getItem('Retail') === "true") {
      if (getAccessLog('username')) {
        const param = {
          CUSTOMER_FILTER: localStorage.getItem('Retail') === "false" ? "IS NOT NULL" : "IS NULL",
         USERNAME: getAccessLog('username'),
          page: 1,
          limit: 100
        }
        setDatatables3(param)
      }
    }
  }, [localStorage.getItem('Retail')])
  useEffect(() => {
    if (cartList?.items?.data) {
      const temp = cartList?.items?.data
      if (temp.length > 0) {
        setCartTotal(temp?.length ?? 0)
        setCartTotalPrice(temp?.sum('ITEM_PRICE', 'ITEM_TOTAL') ?? 0)
      }
    } else {
      if (cartTotal === 1) {
        setCartTotal(cartList?.items?.data?.length ?? 0)
        setCartTotalPrice(cartList?.items?.data?.sum('ITEM_PRICE', 'ITEM_TOTAL') ?? 0)
      }
    }
  }, [cartList])

  const [search, setSearch] = useState('')

  Array.prototype.sum = function (prop, ast) {
    var total = 0
    for (var i = 0, _len = this.length; i < _len; i++) {
      total += (this[i][prop] * this[i][ast])
    }
    return total
  }

  useEffect(() => {
    if (localStorage.getItem('Retail') === "false") {
      setLang("id")
    }
  }, [localStorage.getItem('Retail')])


  useEffect(() => {
    if (localStorage.getItem('Retail') === "false"){
      if (datatables2.length > 0) {
        const param = {
          CUSTOMER_NO: datatables2?.[0]?.CUSTOMER_NO,
          USERNAME: getAccessLog('username'),
          IS_CONTRACT: true,
          page: 1,
          limit: 100
        }
        setDatatables(param)
      }
    } else if (localStorage.getItem('Retail') === "true") {
      if (datatables3.length > 0) {
        const param = {
          CUSTOMER_NO: datatables3?.[0]?.CUSTOMER_NO,
          USERNAME: getAccessLog('username'),
          IS_CONTRACT: false,
          page: 1,
          limit: 100
        }
        setDatatables(param)
        if (localStorage.getItem('firstLogin') !== "true") {
          setLang(datatables3?.[0]?.CONF_LANGUAGE)
          localStorage.setItem('firstLogin', true)
          window.location.reload()

        }
      }
    }
  }, [datatables2, datatables3, localStorage.getItem('Retail')])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearch = (event) => {
    const query = {
      act: 'List',
      PRODUCT_NAME: search,
      category: 'Search'
    };
    const searchString = queryString.stringify(query);
    navigate({
      pathname: '/furnitur/product',
      search: searchString
    })
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleRegister = (event) => {
    if (openLogin) {
      setOpenLogin(false)
    }
    setOpenRegister(!openRegister)
  };

  function handleLogout() {
    setAnchorElProfile(null)
    setLogout()
  }

  const handleLogin = (event) => {
    if (openRegister) {
      setOpenRegister(false)
    }
    setOpenLogin(!openLogin)
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleChangeLanguageEG = () => {
    setLanguage('en')
    setLang("en")
    setAnchorEl(null);
    window.location.reload()
  }
  const handleChangeLanguageID = () => {
    setLanguage('id')
    setLang("id")
    setAnchorEl(null);
    window.location.reload()
  }

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const openProfile = Boolean(anchorElProfile);
  const handleClickProfile = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (

    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangeLanguageID}> <img width={'22px'} style={{ marginRight: '5px' }} src={indonesiaIcon} alt="" /> Indonesia</MenuItem>
      <MenuItem onClick={handleChangeLanguageEG}> <img width={'22px'} style={{ marginRight: '5px' }} src={unitedKingdomIcon} alt="" /> English</MenuItem>
    </Menu>

  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          {
            language === 'id' ?
              <div>
                <img src={indonesiaIcon} width={'22px'}></img>
              </div>
              :
              <div>
                <img src={unitedKingdomIcon} width={'22px'}></img>
              </div>
          }
        </IconButton>
        {
          language === 'id' ?
            <div>
              <p>Indonesia</p>
            </div>
            :
            <div>
              <p>English</p>
            </div>
        }
      </MenuItem>
      {getAccessLog('isAuthentication') === true
        ?
        <MenuItem onClick={(event) => handleClickProfile(event)}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <p>{getAccessLog('username')}</p>
        </MenuItem>
          :
        <MenuItem onClick={() => setOpenLogin(!openLogin)}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            sx={{ px: 1 }}
          >
            <AccountCircle />
          </IconButton>
          <p>{lang.COMMON.CONTENT.SIGN_IN}</p>
        </MenuItem>
      }
      {getAccessLog('isAuthentication') === true
        ?
        <MenuItem onClick={() => navigate('/furnitur/cart')}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            color="inherit"
          >
            <Badge badgeContent={cartTotal} color="error">
              <ShoppingCart />
            </Badge>
          </IconButton>
          <p style={{ fontSize: '11px', fontWeight: 'bold' }}>{formatRupiah(`${cartTotalPrice}`, 'Rp')}</p>
        </MenuItem>
          : null
        }
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
      <Login open={openLogin} setOpen={setOpenLogin} handleRegister={handleRegister} />
      <Register open={openRegister} setOpen={setOpenRegister} handleLogin={handleLogin} />
      <AppBar position="static" sx={{ background: '#ffffff' }} className={classes.container}>
        <Toolbar>
            <Grid container spacing={1} gridRow={3}>
              <Grid item display="flex" alignItems="center" xl={2} lg={2} md={2} sm={2} xs={2}>
                <Box
                  align='center'
                  noWrap
                  component="div"
                  sx={{ display: { xs: 'none', md: 'block' }, width: 200, marginRight: 2 }}
                >
                  <img style={{ cursor: 'pointer' }} onClick={() => navigate('/furnitur')} src={clarityLogo} width={'100%'}></img>
                </Box>
                {localStorage.getItem('Retail') === "false" ?
                  <Typography
                    variant="h6"
                    noWrap
                    align='center'
                    component="div"
                    sx={{ display: { xs: 'none', sm: 'block' }, marginRight: 2 }}
                  >
                    <img src={lkppLogo} width={'80%'}></img>
                  </Typography>
                  : null}
                <Box
                  noWrap
                  component="div"
                  sx={{ display: { sm: 'block', md: 'none' } }}
                >
                  <img src={clarityLogoMin} onClick={() => navigate('/furnitur')} width={'35px'}></img>
                </Box>
              </Grid>
              <Grid item display="flex" alignItems="center" xl={7} lg={7} md={7} sm={8} xs={8} >
                <Search>
                  <StyledInputBase
                    placeholder={lang.COMMON.CONTENT.SEARCH}
                    inputProps={{ 'aria-label': 'search' }}
                    value={search}
                    onChange={(event) => { setSearch(event.target.value) }}
                    onKeyPress={handleEnter} 
                  />
                  <SearchIconWrapper onClick={handleSearch} sx={{ cursor: 'pointer' }} >
                    <SearchIcon />
                  </SearchIconWrapper>
                </Search>
              </Grid>
              <Grid item display="flex" alignItems="center" justifyContent="end" xl={3} lg={3} md={3} sm={2} xs={2}>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {localStorage.getItem('Retail') === "false" ? null
                    :
                    <Button
                      size="small"
                      aria-label="show 4 new mails"
                      color="secondary"
                      onClick={handleProfileMenuOpen}
                      sx={{ px: 1 }}
                    >
                      {
                        language === 'id' ?
                          <div>
                            <img src={indonesiaIcon} style={{ marginTop: '15px' }} width={'20px'}></img>
                            <p style={{ fontSize: '11px', fontWeight: 'bold', marginTop: '-9px' }}>Indonesia</p>
                          </div>
                          :
                          <div>
                            <img src={unitedKingdomIcon} style={{ marginTop: '15px' }} width={'20px'}></img>
                            <p style={{ fontSize: '11px', fontWeight: 'bold', marginTop: '-9px', whiteSpace: 'nowrap' }}>English</p>
                          </div>
                      }
                    </Button>
                  }
                  {getAccessLog('isAuthentication') === true
                    ?
                    <>
                      <Button
                        size="small"
                        aria-label="show 17 new notifications"
                        color="secondary"
                        sx={{ px: 1 }}
                        onClick={(event) => handleClickProfile(event)}
                      >
                        <AccountCircle />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <p style={{ fontSize: '11px', marginBottom: -20, paddingTop: 9, textAlign: 'left' }}>{lang.COMMON.MENU.PROFILE},</p>
                          <p style={{ fontSize: '14px', fontWeight: 'bold' }}>{getAccessLog('username')}</p>
                        </Box>
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElProfile}
                        open={openProfile}
                        onClose={handleCloseProfile}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            navigate('/furnitur/profile')
                            setAnchorElProfile(null)
                          }}
                        >
                          {lang.COMMON.FOOTER.MY_ACCOUNT}
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                          {lang.COMMON.MENU.LOGOUT}
                        </MenuItem>
                      </Menu>
                    </>
                    :
                    <Button
                      size="small"
                      aria-label="show 17 new notifications"
                      color="secondary"
                      sx={{ px: 1 }}
                      onClick={() => setOpenLogin(!openLogin)}
                    >
                      <AccountCircle />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ fontSize: '11px', marginBottom: -20, paddingTop: 9 }}>{lang.COMMON.CONTENT.SIGN_IN}</p>
                        <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Profile</p>
                      </Box>
                    </Button>
                  }
                  {getAccessLog('isAuthentication') === true
                    &&
                    <Button
                      size="small"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      color="secondary"
                      onClick={() => navigate('/furnitur/cart')}
                    >
                      <ShoppingCart />
                      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '2px' }}>
                        <Typography fontSize='11px' variant="body1" color="initial">{cartTotal} {lang.COMMON.CONTENT.ITEMS}</Typography>
                        <Typography fontWeight={'bold'} noWrap variant="body1" color="initial">{formatRupiah(`${cartTotalPrice}`, 'Rp')}</Typography>
                      </Box>
                    </Button>
                  }
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="secondary"
                  >
                    <MoreIcon />
                  </IconButton>
                </Box>
              </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}