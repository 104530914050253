const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export function structuredefault(values, handleFieldChange, open, categoryOption, language) {
  const data = [
    {
      id: 'BUDGET_SOURCE',
      label: language.COMMON.MY_SHIPPING_ADDRESS.BUDGET_SOURCE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Budget Source',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["BUDGET_SOURCE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'BUDGET_CODE',
      label: language.COMMON.MY_SHIPPING_ADDRESS.BUDGET_CODE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Budget Code',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["BUDGET_CODE"] || '',
      minWidth: 170,
      // hide: isHide
    },

  ]

  return data
};