import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from '../../controllers/_actions'

export default function useLogin(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, history) {
  const dispatch = useDispatch()

  const initFetch = useCallback((values, type) => {
    if (type === 'login') {
      dispatch(userActions.login(values, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, history));
    } else if (type === 'NewUser') {
      dispatch(userActions.newPassword(values, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, history));
    }
  }, [dispatch, setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, history]);

  return [initFetch]
}
