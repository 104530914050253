const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export default function structuredefault(values, handleFieldChange,open,categoryOption, isBergain, language) {
  const data = [
    {
      id: 'SHIPPING_NO',
      label: language.COMMON.MY_TRANSACTION_DETAIL.LABEL_ADDRESS,
      type: 'select',
      fieldtype: 'suggestfield',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["SHIPPING_NO"] || '',
      minWidth: 170,
      hide: false,
      option: categoryOption?.length === 0 ? [] : categoryOption?.map(data => {
        return {
          id: data?.SHIPPING_NO,
          value: data?.SHIPPING_NO,
          label: data?.ADDRESS_NAME,
          data: data
        }
      }),
      placeholder: 'Select Address Name',
      // disabled: true
    },
    {},
    // {
    //   id: 'ADDRESS',
    //   label: 'Shipping Address',
    //   type: 'text',
    //   fieldtype: 'textArea',
    //   disabled: true,
    //   required: true,
    //   onChange: (field, value) => handleFieldChange(field, value),
    //   value: values["ADDRESS"] || '',
    //   minWidth: 170,
    //   // hide: isHide
    //   placeholder: 'Insert Shipping Address',
    // },
    {
      id: 'SHIPPING_NEGO',
      label: language.COMMON.MY_TRANSACTION_DETAIL.SHIPPING_COST,
      type: 'text',
      fieldtype: 'currencyfield',
      disabled: true,
      placeholder: 'Shipping Cost',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["SHIPPING_NEGO"] || '',
      minWidth: 170,
      // hide: isHide
    },
  ]

  return data
};