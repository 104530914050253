import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert'
import FormLabel from '@mui/material/FormLabel'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import Datefield from './component/Datefield'
import WordField from './component/WordField'
import SelectField from './component/SelectField'
import SuggestField from './component/SuggestField'
import NumberField from './component/NumberField'
import PhoneField from './component/PhoneField'
import CurrencyField from './component/CurrencyField'
import CreateField from './component/CreateSuggestField'
import SearchField from './component/SearchField'
import BasicDatePicker from './component/DatePickers';
// import KeyboardPicker from './component/keyBoardPicker'
import RadioField from './component/RadioField'
import TimeField from './component/TimeField'
import TextArea from './component/TextAreaField'
import PasswordField from './component/PasswordFiled'
import RangeDate from './component/RangeDate'
import TextWithButton from './component/TextWithButton'
import {
  AreaDropzone
} from '../../components'
import SuggestedFieldWithButton from './component/SuggestedFieldWithButton';
import SuggestFieldArray from './component/SuggestFieldArray'
import { FormControl } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: ({ padding, divider, minScreen }) => ({
    width: `calc(100% / ${!divider ? 1 : divider})`,
    paddingLeft: theme.spacing(!padding ? 0 : padding),
    [theme.breakpoints.down(!minScreen ? 'xs' : minScreen)]: {
      width: '100%'
    }
  }),
  root2: ({ padding, maxCol }) => ({
    width: `${maxCol ? maxCol : '100%'}`,
    paddingBottom: theme.spacing(!padding ? 0 : padding),
  }),
  rootPhone: {
    width: '100%'
  },
  labelOnly: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.textPrimary,
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  labelItem: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.textPrimary,
  },
  labelSelect: {
    marginTop: 0,
    color: theme.palette.text.textPrimary,
  },
  labelPhone: {
    paddingTop: theme.spacing(3),
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  button: {
    marginTop: theme.spacing(3)
  },
  selectContent: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  itemContent: {
    width: '100%'
  },
  itemContentTime: {
    width: '100px'
  },
  timefield: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}));

export default function FormPropsTextFields(props) {
  const {
    structure,
    padding,
    divider,
    minScreen,
    isLoading,
    maxCol
    // loading
  } = props
  const classes = useStyles({ padding, divider, minScreen, maxCol });
  const [rows, setRows] = React.useState(!structure ? [] : structure);

  useEffect(() => {
    setRows(!structure ? [] : structure)
  }, [structure]);

  const renderSwitch = (param) => {
    switch (param.fieldtype) {
      case 'textfield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <WordField
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid>
        );
        case 'rangedate':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <RangeDate
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid>
        );
        case 'passwordfield':
          return (
            <Grid item key={param.id} className={classes.root2} >
              <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
              <PasswordField
                classes={classes}
                param={param}
                readOnly={props.readOnlyAll || param.readOnly}
                config={props.config}
              />
            </Grid>
          );
      case 'timefield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <TimeField
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid>
        );
      case 'currencyfield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <CurrencyField
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid>
        );
      case 'numberfield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <NumberField
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid>
        );
      case 'phonefield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <PhoneField
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid>
        );
      case 'datefield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <Datefield
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
            />
          </Grid>
        );
      case 'select':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <SelectField
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid >
        );
      case 'radiofield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
              <RadioField
                classes={classes}
                param={param}
                readOnly={props.readOnlyAll || param.readOnly}
                config={props.config}
              />
            </FormControl>
          </Grid >
        );
      case 'suggestfield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <SuggestField
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid >
        );
        case 'suggestfieldArray':
          return (
            <Grid item key={param.id} className={classes.root2} >
              <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
              <SuggestFieldArray
                classes={classes}
                param={param}
                readOnly={props.readOnlyAll || param.readOnly}
                config={props.config}
              />
            </Grid >
          );
      case 'radiofield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormControl legend="fieldset">
              <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
              <RadioField
                classes={classes}
                param={param}
                readOnly={props.readOnlyAll || param.readOnly}
                config={props.config}
              />
            </FormControl>
          </Grid >
        );
      case 'searchfield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <SearchField
              classes={classes}
              param={param}
              isLoading={isLoading}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
              onFetch={() => param.fetchData()}
            />
          </Grid >
        );
      case 'createfield':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <CreateField
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid >
        );
      case 'boolean':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <FormControlLabel
              control={<Switch
                size="small"
                checked={param.value}
                disabled={param.disabled}
                onChange={(e) => param.onChange(e.target.name, e.target.checked)}
                name={param.id}

              />}
              label={!param.value ? "Inactive" : "Active"}
            />
          </Grid >
        );
      case 'switch':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <FormControlLabel
              control={<Switch
                size="small"
                checked={param.value}
                disabled={param.disabled}
                onChange={(e) => param.onChange(e.target.name, e.target.checked)}
                name={param.id}

              />}
              label={!param.value ? param.falseDesc : param.trueDesc}
            />
          </Grid >
        );
      case 'button':
        return (
          <Grid item key={param.id} className={classes.root2}>
            <Button
              id={param.id}
              fullWidth
              variant={param.type}
              color={param.color}
              onClick={(e) => param.onSubmit(e)}
              className={classes.button}
            >
              {param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}
            </Button>
          </Grid>
        );
      case 'dropzone':
        return (
          <Grid item key={param.id} className={classes.root2}>
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <AreaDropzone
              onChange={(name, value) => param.onChange(name, value)}
              id={param.id}
              variant={param.type}
              bottomLabel={param.label}
              label={param.label}
              documentFiles={param.value}
              onlyImage={param.onlyImage}
            />
          </Grid >
        );
        case 'datepicker':
          return (
            <Grid item key={param.id} className={classes.root2} >
              <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
              <BasicDatePicker
                // onChange={param?.onChange}
                classes={classes}
                onChange={(name, value) => param.onChange(name, value)}
                label={param?.label}
                value={param?.value}
                dateFormat={param?.dateFormat}
                id={param?.id}
                param={param}
              // readOnly={props.readOnlyAll || param.readOnly}
              />
            </Grid>
          );
      case 'suggestfieldwithbutton':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <SuggestedFieldWithButton
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid >
        );
      case 'textwithbutton':
        return (
          <Grid item key={param.id} className={classes.root2} >
            <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
            <TextWithButton
              classes={classes}
              param={param}
              readOnly={props.readOnlyAll || param.readOnly}
              config={props.config}
            />
          </Grid >
        );
      case 'textArea':
      return (
        <Grid item key={param.id} className={classes.root2} >
          <FormLabel component="legend" className={classes.labelItem}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</FormLabel>
          <TextArea
            classes={classes}
            param={param}
          />
        </Grid>
      );
      default:
        return (
          <Grid item key={param.id} className={classes.root2}>
            <Typography variant={param.variant} className={classes.labelOnly}>{param?.required ? <div>{param.label} <span style={{color: 'red'}}>*</span></div>: param?.label}</Typography>
          </Grid>
        )
    }
  }

  return (
    <Grid
      className={classes.container}
      item
      container
      justify="flex-start"
      spacing={props.horizontal ? 1 : 0}
      wrap="wrap"
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
    >
      {rows ?
        rows.map((data) => {
          return (
            <Grid
              className={classes.root}
              item
              container
              key={data.id}
              xl={props.divider ? 12 / props.divider : 12}
              lg={props.divider ? 12 / props.divider : 12}
              md={props.divider ? 12 / props.divider : 12}
              sm={12}
              xs={12}
            >
              {!data.hide ? renderSwitch(data) : null}
            </Grid>
          )
        })
        : <Alert severity="warning">No structure has been found!</Alert>
      }
    </Grid>
  );
}

FormPropsTextFields.propTypes = {
  className: PropTypes.string,
  structure: PropTypes.array.isRequired
};


FormPropsTextFields.defaultProps = {
  structure: [
    {
      id: '1',
      label: 'Unknown',
      type: 'text',
      fieldtype: '',
      onChange: () => { },
      value: ""
    },
  ]
};