import React, { useState, useEffect } from 'react';
import { InputComponent } from 'components'
import { structuredefault, structuredefault2 } from './schema/default'
import { Typography } from '@mui/material';
import { useLanguage } from 'hooks';
function General(props) {
  const [openModal, setOpenModal] = useState(false)
  const [list, setList] = useState([])

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const [language, lagDic, setLang] = useLanguage()
  const arrayImage = ['image1']
  return (
    <div style={{ margin: '20px 50px 20px 30px' }}>
      <Typography>PP/Comittee</Typography>
      <InputComponent
        structure={structuredefault(props?.values, props?.handleFieldChange, handleOpenModal, props?.categoryOption, language)}
        isLoading={props?.isLoading}
        error={props?.error}
        divider={1}
        padding={1}
        minScreen="md"
      // readOnlyAll
      />
      <Typography>PPK</Typography>
      <InputComponent
        structure={structuredefault2(props?.values, props?.handleFieldChange, handleOpenModal, props?.categoryOption, language)}
        isLoading={props?.isLoading}
        error={props?.error}
        divider={1}
        padding={1}
        minScreen="md"
      // readOnlyAll
      />
    </div>
  );
}

export default General;
