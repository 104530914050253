import React from 'react';
import Routes from "../../common/router/main.route"
function App() {
  return (
    <div style={{ margin: 0 }}>
    <Routes />
  </div>
  );
}

export default App;
