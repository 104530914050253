import { Button, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import {InputComponent} from 'components'
import DefaultStructure from '../Schema/default'
import { validateSign } from 'controllers/_validate'
import {
    useInput,
  } from 'hooks'
  import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/styles';
import { useLanguage} from 'hooks'
function PaymentMethod(props) {
  const [language, lagDic, setLang] = useLanguage()
    const [handleFieldChange, values] = useInput(validateSign, {})
    const [state, setState] = useState([]);
  
    const handleConfirm = () => {
        props.setList(values)
        props.close(false)
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F1F5F8",
        color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(even)': {
        backgroundColor: '#F1F5F8',
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));
  return (
    <Box sx={{width: 400}}>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant='h5'>{language.COMMON.MY_TRANSACTION_DETAIL.PAYMENT_METHOD}</Typography>    
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography>Silahkan lakukan pembayaran pada nomor rekening di bawah.</Typography>
      </Box>
      <Box>
        <Box
          sx={{
            py: 1, px: 2, my: 1, border: 'dashed 1px gray', borderRadius: '5px', width: '100%', backgroundColor: '#EBF2FF'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 5, width: '100%' }}>
            <div style={{ width: '35%' }}>
              <Typography variant="body1" color="initial">Payment Method</Typography>
              <Typography variant="body1" color="initial">Bank Name</Typography>
              <Typography variant="body1" color="initial">Account Name</Typography>
              <Typography variant="body1" color="initial">Account Number</Typography>
            </div>
            <div>
              <Typography variant="body1" color="initial"><strong>Transfer Bank</strong></Typography>
              <Typography variant="body1" color="initial"><strong>BRI</strong></Typography>
              <Typography variant="body1" color="initial"><strong>FURNI KARYA MANDARI</strong></Typography>
              <Typography variant="body1" color="initial"><strong>1145-01-000858</strong></Typography>
            </div>
          </div>
        </Box>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography>Setelah melakukan pembayaran, harap menghubungi kami untuk melakukan konfirmasi pembayaran.</Typography>
      </Box>
      <Box sx={{mt: 2, display: 'flex', justifyContent: 'center', width: '100%'}}>
        <Button sx={{width: '100%'}} variant='contained' color='primary' onClick={()=> {props?.close(false)}}>{language.COMMON.COMMON_BUTTON.CLOSE}</Button>
      </Box>
    </Box>
  )
}

export default PaymentMethod