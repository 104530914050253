// Palette
import palette from '../palette';

const MuiButton = {
  root: {
    borderRadius: '5px',
    '&:disabled': {
      color: palette.common.muted,
      background: '#f2f5fa',
    },
    size: 'small',
  },
  contained: {
    borderRadius: '5px',
    color: palette.common.white,
    backgroundColor: palette.common.blue,
    boxShadow: 'none',
    padding: '5px 20px 5px 20px',
    '&:hover': {
      borderRadius: '5px',
      boxShadow: 'none',
      backgroundColor: palette.common.blue,
      color: palette.common.white
    }
  },
  // disabled: {
  //   color: palette.common.white,
  //   background: palette.text.disabled,
  // },
  containedPrimary: {
    color: palette.common.white,
    // background: palette.customGradient.main,
    // backgroundImage: `linear-gradient(to right, rgba(159, 66, 75, 1), rgba(18, 9, 12, 1))`,
    '&:disabled': {
      color: palette.common.muted,
      background: '#f2f5fa',
    }
  },
  containedSecondary: {
    borderRadius: '5px',
    color: palette.common.black,
    backgroundColor: '#F1F5F8',
    '&:hover': {
      borderRadius: '5px',
      backgroundColor: '#F2F7FF',
      color: 'red'
    }
  },
  outlined: {
    color: palette.common.black
  }
};

export default MuiButton
