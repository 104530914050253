import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
	container: {
		paddingLeft: theme.spacing(10),
		paddingRight: theme.spacing(10),
		paddingTop: theme.spacing(7),
		paddingBottom: theme.spacing(5),
		height: 'auto',
		width: '100%',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(5),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		},
	},
}));
