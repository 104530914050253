import { Button, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormControl from '@mui/material/FormControl';
import {InputComponent} from 'components'
import DefaultStructure from './Schema/default'
import { validateSign } from 'controllers/_validate'
import {
    useInput,
  } from 'hooks'
  import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/styles';
function AddComponent(props) {
    const [handleFieldChange, values] = useInput(validateSign, {})
    const [state, setState] = useState([]);
  
    const handleConfirm = (e) => {
      e?.preventDefault()
      if(values?.SETUJU === 'SETUJU') {
        props.setList(values)
        props.close(false)
      }
    }
  return (
    <Box sx={{width: 400}}>
      <form onSubmit={handleConfirm}>

        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='h5'>Approve Negotiation</Typography>
            <Button onClick={()=> {props?.close(false)}} endIcon={<CloseIcon></CloseIcon>}></Button>        
           </Box>
           <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography >Type "SETUJU" then click Approve button, to approve negotiation </Typography>
            </Box>
          <Box>
          <InputComponent
            structure={DefaultStructure(values, handleFieldChange)}
            divider={1}
            padding={1}
            minScreen="md"
            // readOnlyAll
            />
        </Box>
      <FormControl fullWidth sx={{mt: 2}}>
        <Button variant='contained' color='primary' type='submit'>APPROVE</Button>
      </FormControl>
            </form>

    </Box>
  )
}

export default AddComponent