export default function isAccountEmpty (data) {
    if(data) {
        const datalist = data?.[0]?.CUSTOMERCOMPANY?.[0]
        if(!datalist?.INSTANCE_CODE) {
            return true
        } else if(!datalist?.INSTANCE_NIP) {
            return true
        } else if(!datalist?.INSTANCE_POSITION) {
            return true
        } else if(!data?.[0]?.CP?.[0]?.CP_CONTACT ) {
            return true
        } else if(!datalist?.CERTIFICATE_NO) {
            return true
        } else if(!datalist?.WORK_UNIT) {
            return true
        } else if(!datalist?.WORK_NPWP) {
            return true
        } else if(!datalist?.WORK_ADDRESS) {
            return true
        } else {
            return false
        }
    }
}