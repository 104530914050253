export const negotiationConstants = {
    REGISTER_REQUEST: 'NEGOTIATION_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'NEGOTIATION_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'NEGOTIATION_REGISTER_FAILURE',

    GETALL_REQUEST: 'NEGOTIATION_GETALL_REQUEST',
    GETALL_SUCCESS: 'NEGOTIATION_GETALL_SUCCESS',
    GETALL_FAILURE: 'NEGOTIATION_GETALL_FAILURE',

    DELETE_REQUEST: 'NEGOTIATION_DELETE_REQUEST',
    DELETE_SUCCESS: 'NEGOTIATION_DELETE_SUCCESS',
    DELETE_FAILURE: 'NEGOTIATION_DELETE_FAILURE',

    UPDATE_REQUEST: 'NEGOTIATION_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'NEGOTIATION_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'NEGOTIATION_UPDATE_FAILURE'
};
