import { selectConstants } from '../_constants';

export function selected(state = {}, action) {
  switch (action.type) {
    case selectConstants.SELECT_NOITEM:
      return {
        idle: true
      };
    case selectConstants.SELECT_ITEM_SUCCESS:
      return action.item;
    default:
      return state
  }
}

export function selectedDetail(state = {}, action) {
  switch (action.type) {
    case selectConstants.SELECT_DETAIL_NOITEM:
      return {
        idle: true
      };
    case selectConstants.SELECT_DETAIL_ITEM_SUCCESS:
      return action.item;
    default:
      return state
  }
}