import { permissionConstants } from "../_constants";

export function permission(state = null, action) {
  switch (action.type) {
    case permissionConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case permissionConstants.GETALL_SUCCESS:
      return action.permission;
    case permissionConstants.GETALL_FAILURE:
      return null;
    default:
      return state;
  }
}
