import React from 'react'
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

export default function CustomDatefield(props) {
  const {
    // classes,
    value,
    onChange,
    id,
    label,
    disabled,
    readOnlyAll,
    placeholder,
    helper,
    variant,
    type,
  } = props

  const initialDate = !value ? "" : value
  const [val, setVal] = React.useState(initialDate)

  const handleChange = (e) => {
    onChange(e.target.value)
    setVal(e.target.value)
  }

  React.useEffect(() => {
    const checkInit = !value ? "" : value
    setVal(checkInit)
  }, [value])

  return (
    <React.Fragment>
      <TextField
        // className={classes.itemContent || ""}
        fullWidth
        id={id}
        margin="dense"
        label={label}
        InputLabelProps={{
          margin: 'dense'
        }}
        value={val}
        onChange={handleChange}
        disabled={disabled}
        readOnly={readOnlyAll}
        placeholder={placeholder}
        helperText={helper}
        name={id}
        type={type}
        variant={variant}
      />
    </React.Fragment>
  )
}
