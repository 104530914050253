import { productConstants } from '../_constants';
import { productService } from '../_services';

export const productActions = {
    getAll,
    getDetail,
    getPopularProduct,
    getBanner,
    getSale,
    getSize,
    getLimit
};

function getAll(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await productService.getAll(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: productConstants.GETALL_PRODUCT_REQUEST, } }
    function success(product) { return { type: productConstants.GETALL_PRODUCT_SUCCESS, product } }
    function failure(error) { return { type: productConstants.GETALL_PRODUCT_FAILURE, error } }
}

function getDetail(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await productService.getDetail(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: productConstants.GETALL_DETAIL_PRODUCT_REQUEST, } }
    function success(product) { return { type: productConstants.GETALL_DETAIL_PRODUCT_SUCCESS, product } }
    function failure(error) { return { type: productConstants.GETALL_DETAIL_PRODUCT_FAILURE, error } }
}

function getSale(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await productService.getSale(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: productConstants.GETALL_SALE_REQUEST, } }
    function success(product) { return { type: productConstants.GETALL_SALE_SUCCESS, product } }
    function failure(error) { return { type: productConstants.GETALL_SALE_FAILURE, error } }
}

function getSize(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await productService.getSize(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: productConstants.GETALL_SIZE_REQUEST, } }
    function success(product) { return { type: productConstants.GETALL_SIZE_SUCCESS, product } }
    function failure(error) { return { type: productConstants.GETALL_SIZE_FAILURE, error } }
}

function getPopularProduct(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await productService.getPopularProduct(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: productConstants.GETALL_POPULAR_PRODUCT_REQUEST, } }
    function success(product) { return { type: productConstants.GETALL_POPULAR_PRODUCT_SUCCESS, product } }
    function failure(error) { return { type: productConstants.GETALL_POPULAR_PRODUCT_FAILURE, error } }
}

function getBanner(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await productService.getBanner(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: productConstants.GETALL_BANNER_REQUEST, } }
    function success(product) { return { type: productConstants.GETALL_BANNER_SUCCESS, product } }
    function failure(error) { return { type: productConstants.GETALL_BANNER_FAILURE, error } }
}

function getLimit(setIsLoading, setError, setOpen, setDialogType, setTitle, setMessage, param) {
    setIsLoading(true)
    setError(null)
    return async dispatch => {
        dispatch(request(param));
        const res = await productService.getLimit(param)
        console.log(res)
        if (res.status_code === 200) {
            dispatch(success(res));
            setIsLoading(false)
            setError(null)
        } else if (res.status_code === 500) {
            dispatch(failure(res));
            setIsLoading(false)
            setError('Request timeout!')
        } else {
            dispatch(failure(res));
            setIsLoading(false)
            setError(`${res.message}`)
        }

    };

    function request() { return { type: productConstants.GETALL_LIMIT_REQUEST, } }
    function success(product) { return { type: productConstants.GETALL_LIMIT_SUCCESS, product } }
    function failure(error) { return { type: productConstants.GETALL_LIMIT_FAILURE, error } }
}