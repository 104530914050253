import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import { TextField,Box, Typography } from '@mui/material'

export default function CustomDatefield(props) {
  const {
    classes,
    param,
  } = props
  const selectedValue = param?.option?.find((data) => data.value === param.value) ?? null
  // const initialDate = !param ? "" : !param.value ? "" : param.value
  const [value, setValue] = React.useState(param?.value ?? '')
  // const [inputValue, setInputValue] = React.useState('');
  
  const handleChange = (id, val) => {
    param.onChange(id, val)
    setValue(val)
  }
  
  React.useEffect(() => {
    const checkInit = param.value ?? null
    setValue(checkInit)
  }, [param])
  
  if (!param?.options || param?.options?.length <= 0) {
     return (
      <React.Fragment>
        <Autocomplete
          className={classes?.itemContent ?? "new"}
          value={value}
          autoComplete={false}
          onChange={(event, newValue) => {
            handleChange(param.id, newValue);
          }}
          // onInputChange={(event, newInputValue) => {
          //   setInputValue(newInputValue);
          // }}
          autoHighlight
          disabled={param.disabled ?? false}
          id={param.id}
          options={param.option || []}
          getOptionLabel={(option) =>  option ?? ""}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
            {option}
          </Box>
          )}
          renderInput={(params) => <TextField
            {...params}
            // label={param.label}
            fullWidth
            margin='dense'
            variant="outlined"
            required={param.required}
            // helperText={param.helper}
            placeholder={param.placeholder}
            disabled={param.disabled}
            inputProps={{
              ...params.inputProps,
              // autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />}
        />
         <Typography
        color={'error'}
        // className={classes.fieldError}
        variant="caption"
      >
        {param?.helper}
      </Typography>
      </React.Fragment>
    )
  }
  return null
}
