import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Tabs,
  Button,
  Tab,
  Typography,
  Box,
  MenuItem,
  Select,
  ToggleButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux'
import moment from 'moment'
import { structuredefault, structuredefault2, structuredefault3 } from './Schema/default'
import { HandlerModal, InputComponent } from 'components'

import { useLanguage, useProfile } from 'hooks'
import { getAccessLog } from "helpers";

import {
  useInput,
} from 'hooks'
import { validateSign } from 'controllers/_validate'


export default function BasicTabs(props) {
  const { handleClickOpen } = props
  const [form, setForm] = useState({});
  // console.log("🚀 ~ file: index.jsx ~ line 36 ~ BasicTabs ~ form", form)
  const instance = useSelector(state => state.instance);
  const [rows, setRows] = React.useState([])
  console.log("🚀 ~ file: index.jsx ~ line 46 ~ BasicTabs ~ rows", rows)
  const [language, lagDic, setLang] = useLanguage()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [dialogType, setDialogType] = useState("error");
  const [datatables, setDatatables, getData] = useProfile(setIsLoading, setError, setOpen2, setDialogType, setTitle, setMessage)
  console.log("🚀 ~ file: index.jsx ~ line 43 ~ BasicTabs ~ datatables", getAccessLog('user_data'))

  const initValue = {
    ROLE: getAccessLog('role'),
    USERNAME: getAccessLog('username'),
    USER_ID: getAccessLog('user_id'),
    FULL_NAME: getAccessLog('user_name'),
    EMAIL: getAccessLog('email')
  }
  const [handleFieldChange, values] = useInput(validateSign, initValue ?? {})

  useEffect(() => {
    const param = {
      CUSTOMER_FILTER: "IS NOT NULL",
      // CUSTOMER_NAME: "Maudy A",
      // CUSTOMER_NO: "00000009",
      USERNAME: getAccessLog('username'),
      page: 1,
      limit: 100
    }
    setDatatables(param)
    // setForm({
    //   instance: datatables?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_CODE,
    // })
  }, [])

  useEffect(() => {
    const param = {
      page: 1,
      limit: 1000
    }
    getData(param, 'instance')
  }, [])

  React.useEffect(() => {
    const rowData = instance?.items?.data ?? []
    if (rowData.length > 0) setRows(rowData)
    else setRows([])
  }, [instance])

  useEffect(() => {
    if (datatables?.[0]?.CUSTOMERCOMPANY) {
      values["INSTANCE_CODE"] = datatables?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_CODE ?? ''
      values["INSTANCE_NIP"] = datatables?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_NIP ?? ""
      values["INSTANCE_POSITION"] = datatables?.[0]?.CUSTOMERCOMPANY?.[0]?.INSTANCE_POSITION ?? ""
      values["CP_CONTACT"] = datatables?.[0]?.CP?.find(v => v?.CP_MODE === 'PHONE')?.CP_CONTACT ?? ""
      values["CERTIFICATE_NO"] = datatables?.[0]?.CUSTOMERCOMPANY?.[0]?.CERTIFICATE_NO ?? ""
      values["WORK_UNIT"] = datatables?.[0]?.CUSTOMERCOMPANY?.[0]?.WORK_UNIT ?? ""
      values["WORK_NPWP"] = datatables?.[0]?.CUSTOMERCOMPANY?.[0]?.WORK_NPWP ?? ""
      values["WORK_ADDRESS"] = datatables?.[0]?.CUSTOMERCOMPANY?.[0]?.WORK_ADDRESS ?? ""
      values["CUSTOMER_NO"] = datatables?.[0]?.CUSTOMER_NO
    }
  }, [datatables])

  const handleChange = (event) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }
  const today = moment().format('YYYY-MM-DD HH:mm:ss.ms');
  const [textValidation, setTextValidation] = useState({ npwp: '' })
  useEffect(() => {
    if (values?.WORK_NPWP?.length > 0) {
      if (values?.WORK_NPWP?.length !== 15) {
        setTextValidation({ ...textValidation, npwp: 'NPWP must have 15 digits' })
      } else {
        setTextValidation({ ...textValidation, npwp: '' })
      }
    } else {
      setTextValidation({ ...textValidation, npwp: '' })
    }
  }, [values?.WORK_NPWP])
  const handleSave = (event) => {
    event.preventDefault()
    if (textValidation?.npwp) {

    } else {
      const param = {
        "CUSTOMER_NO": values?.CUSTOMER_NO,
        "CUSTOMER_NAME": getAccessLog('user_name'),
        "USERNAME_ID": getAccessLog('username'),
        "PHONE": values?.CP_CONTACT,
        "ADDRESS": datatables?.[0]?.ADDRESS,
        "EMAIL": getAccessLog('email'),
        "COMPANY_CODE": "CTC-0001",
        "COMPANY_USERNAME": getAccessLog('username'),
        "COMPANY_ROLE": getAccessLog('role'),
        "_id":  getAccessLog('user_data')?._id,
        "_key":  getAccessLog('user_data')?._key,
        "_rev":  getAccessLog('user_data')?._rev,
        "TOKEN": getAccessLog('lkpp_token'),

        "INSTANCE_CODE": values?.INSTANCE_CODE,
        "INSTANCE_NIP": values?.INSTANCE_NIP,
        "INSTANCE_POSITION": values?.INSTANCE_POSITION,

        "CERTIFICATE_NO": values?.CERTIFICATE_NO,

        "WORK_UNIT": values?.WORK_UNIT,
        "WORK_NPWP": values?.WORK_NPWP,
        "WORK_ADDRESS": values?.WORK_ADDRESS,

        "ACTION_BY": getAccessLog('username'),
        "ACTION_DATE": today,
        "ADDITIONAL_INFO": '',
      }
      console.log("🚀 ~ file: index.jsx ~ line 163 ~ handleSave ~ param", param)
      getData(param, 'create')

    }
    // const param2 = {
    //   CUSTOMER_FILTER: "IS NOT NULL",
    //   // CUSTOMER_NAME: "Maudy A",
    //   // CUSTOMER_NO: "00000009",
    //   USERNAME: getAccessLog('username'),
    //   page: 1,
    //   limit: 100
    // }
    // setDatatables(param2)

  }

  const bodyModal = () => {
    return (
      <div >
      </div>
    )
  }
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [label, setLabel] = useState({
    positive: 'Close',
    negative: 'Close',
  })
  const [onAction, setOnAction] = useState({
    onClose: () => handleClose2(),
    positive: (body, type) => handleClose2(body, type),
    negative: () => handleClose2()
  })
  return (
    <React.Fragment>
      <HandlerModal
        open={open2}
        body={bodyModal}
        onClose={handleClose2}
        dialogType={dialogType}
        message={message}
        title={title}
        label={label}
        action={onAction}
        error={error}
        loading={isLoading}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        disablePortal={true}
      />

      {/* GENERAL INFORMATION */}
      <Box
        sx={{
          m: 2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px'
        }}
      >
        <Typography variant="body1" color="initial"><strong>{language.COMMON.MY_ACCOUNT.GENERAL_INFORMATION}</strong></Typography>
        <Grid container style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
          <InputComponent
            structure={structuredefault(values, handleFieldChange, language)}
            isLoading={''}
            error={''}
            divider={2}
            padding={1}
            minScreen="md"
          // readOnlyAll
          />
        </Grid>
      </Box>
      <form onSubmit={handleSave}>
        {/* PERSONAL INFORMATION */}
        <Box
          sx={{
            m: 2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px'
          }}
        >
          <Typography variant="body1" color="initial"><strong>{language.COMMON.MY_ACCOUNT.PERSONAL_INFORMATION}</strong></Typography>
          <InputComponent
            structure={structuredefault2(values, handleFieldChange, rows, language)}
            isLoading={''}
            error={''}
            divider={2}
            padding={1}
            minScreen="md"
          // readOnlyAll
          />

        </Box>

        {/* WORK UNIT INFORMATION */}
        <Box
          sx={{
            m: 2, px: 3, py: 2, border: 'solid 1px gray', borderRadius: '5px'
          }}
        >
          <Typography variant="body1" color="initial"><strong>{language.COMMON.MY_ACCOUNT.WORK_UNIT_INFORMATION}</strong></Typography>
          <Grid container style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
            <InputComponent
              structure={structuredefault3(values, handleFieldChange, textValidation, language)}
              isLoading={''}
              error={''}
              divider={2}
              padding={1}
              minScreen="md"
            // readOnlyAll
            />
          </Grid>
        </Box>
        <Button type="submit" sx={{ float: 'right' }} variant="contained" color="primary">
          Save
        </Button>
      </form>
    </React.Fragment >
  );
}
