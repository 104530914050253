import React from 'react'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { Grid, Button } from '@mui/material';
import { useNavigate} from 'react-router';
import bgImage from 'assets/images/underConstructions.svg'
import logoImage from 'assets/images/logoMinPng.png'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundImage: `url(${bgImage})`,
    // background: theme.palette.customGradient.background,
    height: '100vh',
    width: '100vw',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    [theme.breakpoints.down('sm')]: {
        top: '30%',
      },
    // width: 80px,
    // height: 80px,
  },
  imageContainer: {
    filter: 'birghtness(1000%)',
    height: '100px',
    width: 'auto',
    marginBottom: theme.spacing(2)
  },
  fontCheck: {
    fontWeight: 'bold',
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
},
  fontTitle: {
    // fontSize: '120px',
    // fontWeight: 'bold',
    // color: theme.palette.secondary.main,
    textShadow: `2px 2px 8px ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(3)
  }
}));

const NotFound = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img
          src={logoImage}
          className={classes.imageContainer}
          alt="error-cuy"
        />
        <Grid
          item
        >
            {
                props?.page ? null :
            <Typography variant="h1" className={classes.fontTitle}>404</Typography>
            }
        </Grid>
        <Grid
          item
        >
          <Typography variant="h2" align='center' sx={{fontWeight: '900'}} className={classes.fontCheck}>{props?.title? props?.title :'Looks like this page is missing or under construction'}</Typography>
        </Grid>
        <Grid
          item
        >
          {/* <Typography variant="h5" className={classes.fontCheck}>Don't worry though. Our best man is on the case</Typography> */}
        </Grid>
        <Grid
          item
          className={classes.button}
        >
          {props?.title ? null :
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate('/furnitur')}
            sx={{marginTop: '10px'}}
          >
            Back to Home
          </Button>
        }
        </Grid>
      </div>
    </div >
  )
}

export default NotFound