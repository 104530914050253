const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export function structuredefault(values, handleFieldChange, lang) {
  const data = [
    {
      id: 'COMPANY_NAME',
      label: lang.COMMON.MY_ACCOUNT.COMPANY_NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["COMPANY_NAME"] || 'LKPP',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'ROLE',
      label: lang.COMMON.MY_ACCOUNT.ROLE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Role',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["ROLE"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'USERNAME',
      label: lang.COMMON.MY_ACCOUNT.USERNAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Username',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["USERNAME"] || '',
      minWidth: 170,
      // hide: isHide
    },
    // {
    //   id: 'USER_ID',
    //   label: 'User ID',
    //   type: 'text',
    //   fieldtype: 'textfield',
    //   disabled: true,
    //   placeholder: 'Insert User ID',
    //   onChange: (field, value) => handleFieldChange(field, value),
    //   value: values["USER_ID"] || '',
    //   minWidth: 170,
    //   // hide: isHide
    // },
    {
      id: 'FULL_NAME',
      label: lang.COMMON.MY_ACCOUNT.FULL_NAME,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Fullname',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["FULL_NAME"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'EMAIL',
      label: lang.COMMON.MY_ACCOUNT.EMAIL,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Email',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["EMAIL"] || '',
      minWidth: 170,
      // hide: isHide
    },

  ]

  return data
};
export function structuredefault2(values, handleFieldChange, rows, lang) {
  const data = [
    {
      id: 'INSTANCE_CODE',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.INSTANCE,
      type: 'select',
      required: true,
      fieldtype: 'suggestfield',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["INSTANCE_CODE"] ?? '',
      minWidth: 170,
      hide: false,
      option: rows?.length === 0 ? [] : rows?.map(data => {
        return {
          id: data.INSTANCE_CODE,
          value: data.INSTANCE_CODE,
          label: data.INSTANCE_NAME,
          data: data
        }
      }),
      placeholder: 'Select Instance',
    },
    {
      id: 'CP_CONTACT',
      label: lang.COMMON.MY_ACCOUNT.PHONE_NO,
      type: 'text',
      fieldtype: 'numberfield',
      // disabled: true,
      required: true,
      isPhone: true,
      placeholder: 'Insert Phone Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["CP_CONTACT"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'INSTANCE_NIP',
      label: lang.COMMON.MY_ACCOUNT.NIP,
      type: 'text',
      fieldtype: 'numberfield',
      // disabled: true,
      required: true,
      placeholder: 'Insert NIP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["INSTANCE_NIP"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'CERTIFICATE_NO',
      label: lang.COMMON.MY_ACCOUNT.CERTIFICATE_PJB,
      type: 'text',
      fieldtype: 'textfield',
      // disabled: true,
      required: true,
      placeholder: 'Insert Cerificate PBJ Number',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["CERTIFICATE_NO"] || '',
      minWidth: 170,
      // hide: isHide
    },

    {
      id: 'INSTANCE_POSITION',
      label: lang.COMMON.MY_ACCOUNT.POSITION,
      type: 'text',
      fieldtype: 'textfield',
      // disabled: true,
      required: true,
      placeholder: 'Insert Postition',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["INSTANCE_POSITION"] || '',
      minWidth: 170,
      // hide: isHide
    },

  ]

  return data
};

export function structuredefault3(values, handleFieldChange, textValidation, lang) {
  const data = [
    {
      id: 'WORK_UNIT',
      label: lang.COMMON.MY_ACCOUNT.WORK_UNIT,
      type: 'text',
      fieldtype: 'textfield',
      // disabled: true,
      required: true,
      placeholder: 'Insert Work Unit',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["WORK_UNIT"] || '',
      minWidth: 170,
      // hide: isHide
    },

    {
      id: 'WORK_NPWP',
      label: lang.COMMON.MY_ACCOUNT.WORK_UNIT_NPWP,
      type: 'text',
      fieldtype: 'textfield',
      // disabled: true,
      required: true,
      placeholder: 'Insert Work NPWP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["WORK_NPWP"] || '',
      minWidth: 170,
      isNPWP: true,
      helper: textValidation?.npwp
      // hide: isHide
    },
    {
      id: 'WORK_ADDRESS',
      label: lang.COMMON.MY_ACCOUNT.WORK_UNIT_ADDRESS,
      type: 'text',
      fieldtype: 'textArea',
      // disabled: true,
      required: true,
      placeholder: 'Insert Work Unit Address',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["WORK_ADDRESS"] || '',
      minWidth: 170,
      // hide: isHide
    },
  ]

  return data
};
