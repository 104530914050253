import { getDayNow, rupiahFormat } from 'helpers';

const prod = [
  {
    id: 1,
    _key: 'ACTIVE',
    name: 'ACTIVE'
  },
  {
    id: 2,
    _key: 'INACTIVE',
    name: 'INACTIVE'
  }
]
export default function structuredefault(values, handleFieldChange, open, categoryOption, lang) {
 
  let range = (start, end) => Array.from(Array(end + 1).keys()).slice(start);

  const budgetYear = range((parseInt(getDayNow('year'))-10), (parseInt(getDayNow('year'))+10))

  const data = [
    {
      id: 'Product Showcase',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.PRODUCT_SHOWCASE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Product Showcase',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Product Showcase"] || 'Peralatan Perkantoran',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Package ID',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.PACKAGE_ID,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Package ID',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Package ID"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'RUP ID',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.RUP_ID,
      type: 'text',
      fieldtype: 'textfield',
      // disabled: true,
      placeholder: 'Insert RUP ID',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["RUP ID"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Package Name',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.PACKAGE_NAME,
      type: 'text',
      fieldtype: 'textfield',
      // disabled: true,
      placeholder: 'Insert Package Name',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Package Name"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Instance',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.INSTANCE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Instance',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Instance"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Work Unit',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.WORK_UNIT,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert work Unit',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Work Unit"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Work Unit NPWP',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.WORK_UNIT_NPWP,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Work Unit NPWP',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Work Unit NPWP"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Work Unit Address',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.WORK_UNIT_ADDRESS,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Work Unit Address',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Work Unit Address"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Created Date',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.CREATED_DATE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Created Date',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Created Date"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Modified Date',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.MODIFIED_DATE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Insert Modified Date',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Modified Date"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Budget Year',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.BUDGET_YEAR,
      type: 'select',
      fieldtype: 'select',
      // disabled: true,
      // placeholder: 'Insert Budget Year',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Budget Year"] || '',
      minWidth: 170,
      required: true,
      option: budgetYear?.length === 0 ? [] : budgetYear?.map((data, idx) => {
        return {
          id: idx,
          value: data,
          label: data,
          data: data
        }
      }),
      placeholder: 'Select Budget Year',
      // hide: isHide
    },
    {
      id: 'Total Product',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.TOTAL_PRODUCT,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Total Product',
      onChange: (field, value) => handleFieldChange(field, value),
      value: values["Total Product"] || '',
      minWidth: 170,
      // hide: isHide
    },
    {
      id: 'Total Price',
      label: lang.COMMON.MY_TRANSACTION_DETAIL.TOTAL_PRODUCT_PRICE,
      type: 'text',
      fieldtype: 'textfield',
      disabled: true,
      placeholder: 'Total Price',
      onChange: (field, value) => handleFieldChange(field, value),
      value: rupiahFormat(`${values["Total Price"]}`, 'Rp') || '',
      minWidth: 170,
      // hide: isHide
    },
  ]

  return data
};